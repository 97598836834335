import { Paper } from "@frontend/ui";
import { TopWallets } from "src/pages/ccar-lending-page/components/top-wallets";
import { AlertFragment } from "src/pages/ccar-lending-page/generated";

type Props = {
  alert: AlertFragment;
};

export const AlertDrawerCharts = ({ alert }: Props) => {
  switch (alert.title) {
    case "Wallets Borrow Concentration Risk": {
      if (alert.chain && alert.marketId && alert.assets?.length === 1) {
        return (
          <Paper variant="card">
            <TopWallets marketId={alert.marketId} tokenSymbol={alert.assets[0]} hideTopSuppliers />
          </Paper>
        );
      }
      break;
    }
    case "Wallets Supply Concentration Risk": {
      if (alert.chain && alert.marketId && alert.assets?.length === 1) {
        return (
          <Paper variant="card">
            <TopWallets marketId={alert.marketId} tokenSymbol={alert.assets[0]} hideTopBorrowers />
          </Paper>
        );
      }
      break;
    }
    default:
      break;
  }

  return null;
};
