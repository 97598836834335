import { PageTracker } from "src/components/page-tracker";
import { Box } from "@frontend/ui";
import { LpStrategyInput, useLpStrategyDataLazyQuery } from "src/pages/uniswap/generated";
import { Loader } from "@frontend/ui/loader";
import { FormValues, formatLpStrategyInputFromValues } from "./types";
import { CalculatedResultsTable } from "./components/output";
import { InputForm } from "./components/input";

type Props = {
  filterBestResultPerPool?: boolean;
};

const LpStrategyCalculatorPage = PageTracker(({ filterBestResultPerPool }: Props) => {
  const [loadLpStrategyData, lpStrategyData] = useLpStrategyDataLazyQuery();

  const onSubmit = async (values: FormValues, showSpecificAssetsExposure: boolean) => {
    const formattedInput: LpStrategyInput = formatLpStrategyInputFromValues(values, showSpecificAssetsExposure);
    await loadLpStrategyData({ variables: { input: formattedInput, filterBestResultPerPool } });
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <InputForm onSubmit={onSubmit} />
      {lpStrategyData.loading && <Loader />}
      {lpStrategyData.data && <CalculatedResultsTable data={lpStrategyData.data} />}
    </Box>
  );
});

export default LpStrategyCalculatorPage;
