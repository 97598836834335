import { Chain } from "../../generated";
import { CCARClientConfig } from "../types";

export const crvUSDConfig: CCARClientConfig = {
  chains: [Chain.Ethereum],
  isMultichain: false,
  isMultiMarket: true,
  marketType: "ChainAssetMarket",
  endpointsPrefix: "crv-usd",
  riskThreshold: 0.15,
  eligibleThreshold: 0,
  hasSoftLiquidations: true,
  hasCollateralLabel: true,
  isHealthPercent: true,
  overview: {
    cards: ["stablecoinPrice", "totalCollateralUsd", "totalBorrowUsd", "totalValueAtRiskUsd"],
    charts: ["stablecoinCollateralComposition", "stablecoinMintBurn", "poolsComposition", "topHolders"],
    chainTableConfig: {
      columns: [
        "chain",
        "totalSupplyUsd",
        "totalBorrowUsd",
        "totalValueLockedUsd",
        "totalValueAtRiskUsd",
        "activeUsers",
      ],
    },
    chainHistoryStats: {
      disabled: true,
    },
  },
  liquidations: {
    showLiquidationPie: false,
    hasSoftLiquidations: true,
    showLiquidationEvents: true,
  },
  markets: {
    isSingularMarket: true,
    borrowAsset: "crvUSD",
    hiddenColumns: {
      chain: true,
    },
    overview: {
      showCollateralChart: false,
      showEventsTable: true,
      showLiquidationBands: true,
      topBorrowers: {
        hideTopSuppliers: true,
        hideTopBorrowers: false,
      },
    },
    hasAmm: true,
  },
  marketDetails: {
    tables: ["wallets"],
    charts: ["historicalPrices", "borrowWalletsHealth", "topWallets"],
  },
  wallets: {
    hiddenRows: {
      liquidity: true,
      badDebt: true,
    },
  },
  walletDetails: {
    tables: ["recentEvents", "totalSupplyAndBorrow"],
    charts: ["wallet-history"],
  },
  riskExplorer: {
    liquidationThresholdLabel: "Liquidation Threshold",
    showAdvancedSettings: false,
    showLiquidationRisk: true,
  },
  stableCoin: {
    asset: "crvUSD",
    chain: Chain.Ethereum,
    beta: false,
    charts: [],
    collateral: { charts: ["assetBreakdown", "assetClassBreakdown", "collateralRatio"] },
    showInOverview: true,
    hasPools: true,
    hasPegKeepers: true,
  },
  alerts: {
    enabled: true,
  },
};
