import { FC, useEffect, useRef, useState } from "react";
import { Axis } from "@frontend/types";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Typography } from "../typography";
import { Box } from "../box";
import { IconButton } from "../icon-button";
import { HistogramChart, HistogramChartProps } from "./histogram-chart";
import { ChartFilter } from "./chart-filter";
import type { DataItem } from "./types";

type HistogramChartRowProps = Partial<Omit<HistogramChartProps, "legend">> & {
  data?: DataItem[][];
  title: string;
  legend?: Array<string>;
  description?: string;
  xAxis?: Axis;
  yAxis?: Axis;
  showTokenFilter?: boolean;
  hideXAxisLabels?: boolean;
  hideLegends?: boolean;
  colorScale?: string[];
  chartHeight?: number;
  disableAnimate?: boolean;
};

export const HistogramChartRow: FC<HistogramChartRowProps> = ({
  title,
  data,
  legend,
  description,
  xAxis,
  yAxis,
  showTokenFilter,
  hideXAxisLabels,
  hideLegends,
  colorScale,
  chartHeight,
  disableAnimate,
  ...restProps
}) => {
  const filterRef = useRef<HTMLButtonElement>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filteredLegend, setFilteredLegend] = useState(legend?.map(() => true));

  useEffect(() => setFilteredLegend(legend?.map(() => true)), [legend]);

  return (
    <>
      <Box mb={2} display="flex">
        <Box position="relative" flex="1">
          <Typography variant="h4" component="h2">
            {title}
          </Typography>
          {description && <Typography fontWeight={400}>{description}</Typography>}
        </Box>
        {showTokenFilter && (
          <IconButton
            ref={filterRef}
            onClick={() => setIsFilterOpen(true)}
            sx={{
              ml: "auto",
              flex: 0,
              p: 0,
              height: 40,
              width: 40,
              flexBasis: 40,
            }}
          >
            <FilterAltIcon />
          </IconButton>
        )}
      </Box>
      <Box sx={{ height: chartHeight || 376 }}>
        <HistogramChart
          data={data}
          legend={legend?.map((name, i) => ({ name, isVisible: filteredLegend?.[i] || true }))}
          xAxis={xAxis}
          yAxis={yAxis}
          hideXAxisLabels={hideXAxisLabels}
          hideLegends={hideLegends}
          colorScale={colorScale}
          disableAnimate={disableAnimate}
          {...restProps}
        />
      </Box>
      {legend && (
        <ChartFilter
          isOpen={isFilterOpen}
          anchorEl={filterRef.current}
          legend={legend}
          setFilteredLegend={setFilteredLegend}
          onClose={() => setIsFilterOpen(false)}
        />
      )}
    </>
  );
};
