import { FC } from "react";
import { Box, BoxProps, LinearProgress } from "@mui/material";
import { Typography } from "../typography";
import "./progress-bar.scss";

export type ProgressBarType = "small" | "common";

interface ProgressBarProps extends BoxProps {
  progress: number;
  leftText?: string;
  text?: string;
  bgBlack?: boolean;
  barType?: ProgressBarType;
  barWidth?: string;
  textPosition?: "top" | "right" | "bottom" | "left";
  textSize?: string;
  showText?: boolean;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  progress,
  barType,
  text,
  leftText,
  bgBlack,
  barWidth,
  textPosition = "top",
  showText = true,
  ...props
}) => {
  const isTextTop = textPosition === "top";
  const isTextRight = textPosition === "right";
  const isTextBottom = textPosition === "bottom";
  const isTextLeft = textPosition === "left";

  const isTextHorizontalAlign = isTextRight || isTextLeft;

  const flexDirection = (() => {
    if (isTextTop) return "column";
    if (isTextRight) return "row-reverse";
    if (isTextBottom) return "column-reverse";
    if (isTextLeft) return "row";

    return "row";
  })();

  return (
    <Box
      className={`progress-bar-container ${barType || ""}`}
      display="flex"
      flexDirection={flexDirection}
      alignItems={isTextHorizontalAlign ? "center" : undefined}
      {...props}
    >
      {leftText && (
        <Box
          className="progress-bar-leftText"
          sx={{
            position: "absolute",
            top: 0,
            left: 24,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            zIndex: 100,
          }}
        >
          <Typography variant="h5" color="greys.400">
            {leftText}
          </Typography>
        </Box>
      )}
      {showText && (
        <Box
          sx={{
            marginBottom: isTextTop ? "8px" : undefined,
            marginLeft: isTextRight ? "8px" : undefined,
            marginTop: isTextBottom ? "8px" : undefined,
            marginRight: isTextLeft ? "8px" : undefined,
          }}
        >
          <Typography variant="h5" color="greys.400">
            {`${Math.round(progress * 100)}%`}
            {text}
          </Typography>
        </Box>
      )}
      <LinearProgress variant="determinate" value={100 * progress} sx={{ width: "100%" }} />
    </Box>
  );
};
