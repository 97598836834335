import { Header, RenderData } from "@frontend/types";
import { ChaosTable, textCell, numberCell, currencyCell, Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useChainsListQuery } from "../../generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Chain",
  },
  {
    renderType: "TEXT",
    text: "# of Open Credit Accounts",
  },
  {
    renderType: "TEXT",
    text: "# of Closed Credit Accounts",
  },
  {
    renderType: "TEXT",
    text: "Total Supply",
  },
  {
    renderType: "TEXT",
    text: "Total Borrow",
  },
  {
    renderType: "TEXT",
    text: "TVL",
  },
  {
    renderType: "TEXT",
    text: "# of Credit Accounts At Risk",
  },
];

const Chains = () => {
  const { data, loading } = useChainsListQuery();
  const chains = data?.chainsList || [];
  const renderData: RenderData[][] = chains.map((c) => [
    textCell(c.chain, undefined, c.chain),
    numberCell(c.numberOfOpenAccounts),
    numberCell(c.numberOfCloseAccounts),
    currencyCell(c.totalSupply),
    currencyCell(c.totalBorrowed),
    currencyCell(c.tvl),
    numberCell(c.numberOfAccountAtRisk),
  ]);

  const getRowHref = (rowId: number) => {
    const chain = chains[rowId];

    return chain.chain;
  };

  return (
    <Box>
      <PageHeader showBackButton={false} pageTitle="Chains" />

      <ChaosTable
        title="Chains"
        isLoading={loading}
        headers={headers}
        data={renderData}
        rowHref={getRowHref}
        showRowChevron
      />
    </Box>
  );
};

export default Chains;
