import { Box, Typography } from "@frontend/ui";

type Option<T> = {
  value: T;
  label: string;
};

type Props<T = string> = {
  title?: string;
  options: readonly Option<T>[];
  selectedOption: T;
  onSelectOption: (value: T) => void;
  size?: "small" | "medium";
};

const OptionsSelect = <T = string>({ title, options, selectedOption, onSelectOption, size = "medium" }: Props<T>) => (
  <Box display="flex" gap={size === "small" ? 1 : 2} p={3}>
    {title && <Typography variant="h5" color="greys.400">{title}:</Typography>}
    {options.map((option) => (
      <Typography
        key={option.label}
        variant="label"
        fontSize={size === "small" ? 12 : undefined}
        color={selectedOption === option.value ? "blue.main" : "white.main"}
        onClick={() => onSelectOption(option.value)}
        style={{ cursor: "pointer" }}
      >
        {option.label}
      </Typography>
    ))}
  </Box>
);

export default OptionsSelect;
