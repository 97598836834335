import { Chain } from "../../generated";
import { CCARClientConfig } from "../types";

export const benqiConfig: CCARClientConfig = {
  chains: [Chain.Avalanche],
  isMultichain: false,
  isMultiMarket: true,
  marketType: "GeneralMarket",
  endpointsPrefix: "benqi",
  riskThreshold: 1.2,
  eligibleThreshold: 1,
  overview: {
    cards: [
      "totalSupplyUsd",
      "totalBorrowUsd",
      "totalCollateralUsd",
      "totalValueAtRiskUsd",
      "totalValueAvailableForLiquidationUsd",
      "activeUsers",
      "numberOfWalletsAtRisk",
      "totalPotentialBadDebtUsd",
    ],
    charts: ["chainHistoryStats", "mintHistogram"],
    chainHistoryStats: {
      disabled: true,
    },
  },
  wallets: {
    hiddenRows: {
      badDebt: true,
    },
  },
  walletDetails: {
    tables: ["totalSupplyAndBorrow", "recentEvents"],
  },
  marketDetails: {
    tables: ["wallets", "assetEvents"],
    charts: [
      // 'priceImpact',
      "historicalPrices",
      "collateralAtRisk",
      "walletsDistribution",
      "marketsDistribution",
      "topWallets",
    ],
    liquidationThresholdLabel: "Collateral Factor",
  },
  liquidations: {
    showLiquidationPie: true,
    showLiquidationEvents: true,
  },
  riskExplorer: {
    liquidationThresholdLabel: "Collateral Factor",
    showAdvancedSettings: true,
  },
  reserves: {
    charts: ["reserveDistribution", "reserveHistory"],
  },
  alerts: {
    enabled: true,
    notificationsChannels: [
      {
        channel: "telegram",
        link: "https://t.me/+TWP04nxZ1WQ3YmFh",
      },
    ],
  },
};
