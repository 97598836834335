import { Box, useBreakpointDown } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useFeatureFlag } from "src/utils/feature-flags";
import { useClientConfig } from "src/pages/ccar-perpetuals-page/clients-config";
import { TabsNavigation } from "src/components/layouts";
import { ReactElement } from "react-markdown/lib/react-markdown";
import { formatAddressCompact, formatAmount } from "@frontend/ui/utils/formatters";
import { getRankColors } from "../../../leaderboard/utils";
import { useAccountData } from "../hooks/use-account-data";

const Layout = ({ children }: { children: ReactElement }) => {
  const { subAccount } = useParams();
  const { clientName, positions: positionsConfig, accounts } = useClientConfig();

  const positionAnalysisEnabled = useFeatureFlag("perpetuals-positions-analysis") && accounts?.accountAnalysis;

  const { accountId, address, volumeRank, marketMakingRank } = useAccountData();

  const shouldShowCompactAddress = useBreakpointDown("md");

  const pageTitle = shouldShowCompactAddress ? formatAddressCompact(address) : address;
  const getHeaderBadge = () => {
    const ranking = volumeRank?.[0];

    if (ranking) {
      const colors = getRankColors(ranking.rank);

      if (ranking.rank <= 10) {
        return { text: "Top 10", ...colors };
      }

      if (ranking.rank <= 50) {
        return { text: "Top 50", ...colors };
      }

      if (ranking.rank <= 100) {
        return { text: "Top 100", ...colors };
      }
    }

    return undefined;
  };

  const replaceParams = (path: string) =>
    path
      .replace(RouteParams.ClientName, clientName)
      .replace(RouteParams.Address, address)
      .replace(RouteParams.SubAccount, subAccount || "0");

  return (
    <Box display="flex" flexDirection="column">
      <PageHeader
        pageTitle={shouldShowCompactAddress ? pageTitle : "Account"}
        extraData={
          shouldShowCompactAddress
            ? undefined
            : {
                text: "",
                link: {
                  text: `${address}`,
                  href: `${positionsConfig?.accountDetailsUrl || "https://debank.com/profile/"}${address}`,
                  isExternal: true,
                },
                allowCopy: true,
              }
        }
        identicon={accountId}
        underlyingText={
          volumeRank?.[0] || marketMakingRank?.[0] ? (
            <Box display="flex" gap={3} mt={1}>
              {!!volumeRank?.[0].incentivePoints && (
                <Box display="flex">
                  <Box bgcolor="green.opacity50" borderRadius={1} px={1} mr={1} height="max-content">
                    {formatAmount(volumeRank[0].incentivePoints)}
                  </Box>
                  Trading Activity Points
                </Box>
              )}
              {!!marketMakingRank?.[0].incentivePoints && (
                <Box display="flex">
                  <Box bgcolor="orange.opacity50" borderRadius={1} px={1} mr={1} height="max-content">
                    {formatAmount(marketMakingRank[0].incentivePoints)}
                  </Box>
                  Market Making Points
                </Box>
              )}
            </Box>
          ) : undefined
        }
        badge={getHeaderBadge()}
        suffixComponent={
          positionsConfig?.showPositionsAnalysis && positionAnalysisEnabled ? (
            <TabsNavigation
              variant="sub-nav"
              tabs={[
                {
                  label: "Overview",
                  path: replaceParams(
                    RoutePath.Risk[
                      positionsConfig.hasSubAccounts ? "SubAccountPositionsOverview" : "AccountPositionsOverview"
                    ],
                  ),
                },
                {
                  label: "Trading Analysis",
                  path: replaceParams(RoutePath.Risk.AccountPositionsAnalysis),
                },
              ]}
            />
          ) : undefined
        }
      />
      {children}
    </Box>
  );
};

export default Layout;
