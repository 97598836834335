import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import {
  CreditManagersTable,
  PoolDetailsCards,
  PoolDetailsHeader,
  PoolQuotedAssetsTable,
  QuotedAssetsChart,
} from "./components";
import { usePoolQuery } from "../../generated";
import { getPoolName } from "../../utils";

const PoolDetails = () => {
  const { poolId } = useParams();
  const { data, loading } = usePoolQuery({
    variables: {
      poolId: poolId!,
    },
  });

  const poolData = data?.poolData;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <PoolDetailsHeader title={getPoolName(poolData)} poolId={poolData?.id} />
      <PoolDetailsCards data={poolData} />
      <CreditManagersTable poolData={poolData} />
      <QuotedAssetsChart poolAddress={poolId!} chain={poolData?.chain} loadingPool={loading} />
      <PoolQuotedAssetsTable poolAddress={poolId!} chain={poolData?.chain} loadingPool={loading} />
    </Box>
  );
};

export default PoolDetails;
