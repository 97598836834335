import { Header, RenderData } from "@frontend/types";
import { Box, ChaosTable } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { Chain, useEModeCategoriesQuery } from "src/pages/ccar-lending-page/generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Category",
  },
  {
    renderType: "TEXT",
    text: "Total Borrow",
  },
  {
    renderType: "TEXT",
    text: "Collateral at Risk",
  },
  {
    renderType: "TEXT",
    text: "Wallets",
  },
  {
    renderType: "TEXT",
    text: "Wallets at Risk",
  },
  {
    renderType: "TEXT",
    text: "Assets",
  },
];

const EModeList = () => {
  const { clientName, chain } = useParams<{ clientName: string; chain: Chain }>();
  const { data, loading } = useEModeCategoriesQuery({ variables: { chain: chain! } });

  const handleOnClickRow = (rowIdx: number) =>
    RoutePath.Risk.EModeDetails.replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.Chain, chain!)
      .replace(RouteParams.EModeId, data?.eModeCategories[rowIdx].categoryId.toString() || "");

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <ChaosTable
        isLoading={loading}
        initialSortBy={0}
        headers={headers}
        data={
          data?.eModeCategories.map<RenderData[]>((eMode) => [
            {
              renderType: "TEXT",
              text: eMode.categoryLabel,
            },
            {
              renderType: "TEXT",
              text: formatAmount(eMode.totalBorrowUsd, { currency: "USD" }),
              value: eMode.totalBorrowUsd,
            },
            {
              renderType: "TEXT",
              text: formatAmount(eMode.totalCollateralAtRiskUsd, { currency: "USD" }),
              value: eMode.totalCollateralAtRiskUsd,
            },
            {
              renderType: "TEXT",
              text: formatAmount(eMode.totalWallets),
              value: eMode.totalWallets,
            },
            {
              renderType: "TEXT",
              text: formatAmount(eMode.totalWalletsAtRisk),
              value: eMode.totalWalletsAtRisk,
            },
            {
              renderType: "ICONS",
              text: "",
              icons: eMode.assetSymbols,
            },
          ]) || []
        }
        title="E-Mode"
        rowHref={handleOnClickRow}
        isFullHeight
      />
    </Box>
  );
};

export default EModeList;
