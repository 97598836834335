import React from "react";
import { Box } from "../box";
import { TextField } from "../text-field";
import { Typography } from "../typography";
import { NumberFormatInput } from "../number-format-input";

type Props = {
  name?: string;
  label?: React.ReactNode;
  isPrice?: boolean;
  isPercent?: boolean;
  disabled?: boolean;
  error?: string;
  value?: number;
  placeholder?: string;
  onChange?: (value: number) => Promise<void>;
};

const NumberInput = ({ name, label, isPrice, isPercent, disabled, error, value, placeholder, onChange }: Props) => {
  const inputProps = {
    startAdornment: isPrice ? (
      <Typography pl={2} fontSize={16} variant="caption">
        $
      </Typography>
    ) : undefined,
    endAdornment: isPercent ? <Typography variant="caption">%</Typography> : undefined,
    inputComponent: NumberFormatInput as never,
  };

  return (
    <Box flex={1} key={name}>
      <TextField
        label={label}
        error={!!error}
        helperText={error}
        fullWidth
        InputProps={inputProps}
        sx={{
          input: { pl: isPrice ? 0.5 : 2 },
        }}
        placeholder={placeholder ?? "Value"}
        autoComplete="off"
        value={value === undefined ? "" : value}
        onChange={(e) => {
          void onChange?.(Number(e.target.value));
        }}
        disabled={disabled}
      />
    </Box>
  );
};

export default NumberInput;
