import { ChaosTable } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { FullPoolFragment } from "src/pages/lp-dashboard-page/generated";

type Props = {
  poolDetails: FullPoolFragment;
};

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Parameter",
    width: "30%",
  },
  {
    renderType: "TEXT",
    text: "Current",
  },
  {
    renderType: "TEXT",
    text: "Recommended",
    icon: "hathor",
  },
  {
    renderType: "TEXT",
    text: "Change",
  },
];

const RecommendationsTable = ({ poolDetails }: Props) => {
  const data: RenderData[] = [
    {
      renderType: "TEXT",
      text: "Incentives - 14 Days Unbonding",
    },
    {
      renderType: "LABELS",
      token1: "osmo",
      text: poolDetails.incentives
        ? formatAmount(poolDetails.incentives.currentDailyIncentivesOSMO, { notation: "standard" })
        : "-",
    },
    {
      renderType: "LABELS",
      status: "RECOMMENDED",
      token1: "osmo",
      text: poolDetails.incentives
        ? formatAmount(poolDetails.incentives.targetDailyIncentivesOSMO, { notation: "standard" })
        : "-",
    },
    {
      renderType: "TEXT",
      text: poolDetails.incentives
        ? formatAmount(
            (poolDetails.incentives.targetDailyIncentivesOSMO - poolDetails.incentives.currentDailyIncentivesOSMO) /
              poolDetails.incentives.currentDailyIncentivesOSMO,
            { isPercent: true },
          )
        : "-",
    },
  ];

  return <ChaosTable headers={headers} data={[data]} title="Recommendations" isSettingsHidden isFilterHidden />;
};

export default RecommendationsTable;
