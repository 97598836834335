import { Paper, CompositionOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { TradingOverviewQuery, TokenHistoryDataFragment, TokenHistoryData } from "../../../../generated";

type Props = {
  data?: TokenHistoryData[];
  loading: boolean;
};

type TokenGroups = {
  [key: string]: TokenHistoryDataFragment[];
};

const getChartProps = (
  composition: TradingOverviewQuery["poolCompositionOverTime"],
): { series: NumberChartSeries[] } => {
  // Got some weird error about mutability
  const groups = [...composition]
    .sort((a, b) => {
      if (a.time > b.time) {
        return 1;
      }
      if (a.time < b.time) {
        return -1;
      }
      return 0;
    })
    .reduce((curr, next) => {
      const tokenGroup = curr[next.token] || [];
      tokenGroup.push(next);
      return {
        ...curr,
        [next.token]: tokenGroup,
      };
    }, {} as TokenGroups);

  const groupValues = Object.values(groups);
  if (!groupValues.length) {
    return { series: [] };
  }

  return {
    series: groupValues.map((group) => ({
      label: group[0].token,
      data: group.map((d) => [d.time, d.value]),
    })),
  };
};

export const PoolCompositionChart = ({ data, loading }: Props) => {
  const composition = data || [];
  const { series } = getChartProps(composition);

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart percentToggle isLoading={loading} title="Pool Composition" series={series} />
    </Paper>
  );
};
