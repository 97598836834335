import { useProtocolReservesHistoryQuery } from "../../generated";
import { clientsConfig } from "../../clients-config";
import { Client } from "../../types";
import { ReserveChartsStats } from "./types";

export const useReservesData = (
  client: Client,
): {
  isLoading: boolean;
  chartsStats?: ReserveChartsStats;
} => {
  const { reserves } = clientsConfig[client];

  const { loading: isLoadingReserveDistribution, data: reservesHistoryResponse } = useProtocolReservesHistoryQuery({
    variables: { daysAgo: 30 },
    skip: !reserves?.charts.includes("reserveDistribution"),
  });

  const reserveDistribution =
    reservesHistoryResponse && reservesHistoryResponse.protocolReservesHistory.length > 0
      ? Object.entries(
          reservesHistoryResponse.protocolReservesHistory[
            reservesHistoryResponse.protocolReservesHistory.length - 1
          ].reserves.reduce((acc: Record<string, number>, item) => {
            if (acc[item.assetSymbol]) {
              acc[item.assetSymbol] += item.reservesAmountInUsd;
            } else {
              acc[item.assetSymbol] = item.reservesAmountInUsd;
            }
            return acc;
          }, {}),
        ).map(([assetSymbol, reservesAmountInUsd]) => ({
          x: assetSymbol,
          y: reservesAmountInUsd,
        }))
      : undefined;

  const reserveHistory = (reservesHistoryResponse?.protocolReservesHistory || []).reduce(
    (chartsData: Record<string, [number, number][]>, protocolReserves) => {
      const newChartsData = protocolReserves.reserves.reduce((acc: Record<string, [number, number][]>, reserve) => {
        const { assetSymbol, reservesAmountInToken } = reserve;
        const currAssetData = acc[assetSymbol] || [];
        const newDataPoint = [protocolReserves.date * 1000, reservesAmountInToken];
        const newAssetData = [...currAssetData, newDataPoint] as [number, number][];
        return { ...acc, [assetSymbol]: newAssetData };
      }, chartsData);
      return newChartsData;
    },
    {},
  );

  const isLoading = isLoadingReserveDistribution;

  const chartsStats = {
    reserveDistribution,
    reserveHistory,
  };

  return {
    isLoading,
    chartsStats,
  };
};
