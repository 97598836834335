import { ChartDataSource, ChartData, ToolItemProps } from "@frontend/types";
import { DataQueries } from "./data-queries";
import listingImage from "../assets/img/tools/listing.png";
import chartsImage from "../assets/img/tools/charts.png";
import graphsImage from "../assets/img/tools/graphs.png";
import githubImage from "../assets/img/tools/github.png";
import liquidImage from "../assets/img/tools/liquid.png";
import calculatorImage from "../assets/img/tools/calculator.png";
import parameterRecommendationsImage from "../assets/img/tools/parameterRecommendations.png";
import rateImage from "../assets/img/tools/rate.png";
import pieImage from "../assets/img/tools/pie.png";
import youtubeImage from "../assets/img/tools/youtube.png";

async function fillDataForChart(baseUrl: string, chart: ChartData): Promise<any> {
  if (typeof chart.chartId !== "string") {
    console.log("Error : Please provide chartId for data fetcher. Received: ", chart);
    return chart;
  }
  switch (chart.chartDataSource) {
    case ChartDataSource.ONCHAIN: {
      // eslint-disable-next-line
      const dataPayload = await DataQueries(baseUrl, chart.chartId, chart.chartDataSource, chart.queryData);

      if (dataPayload === undefined) {
        throw new Error(`unsupported type ${chart.chartDataSource}`);
      }

      // eslint-disable-next-line
      const filledChart = { ...chart, dataPayload };
      return filledChart;
    }
    case ChartDataSource.OFFCHAIN: {
      // eslint-disable-next-line
      const dataPayload = await DataQueries(baseUrl, chart.chartId, chart.chartDataSource, chart.queryData);

      if (dataPayload === undefined) {
        throw new Error(`unsupported type ${chart.chartDataSource}`);
      }

      // eslint-disable-next-line
      const filledChart = { ...chart, dataPayload };
      return filledChart;
    }
    default:
      return chart;
  }
}

async function fillDataForSingleDashboard(baseUrl: string, dashboard: ChartData[][]): Promise<ChartData[][]> {
  const res = await Promise.all(
    dashboard.map(async (data): Promise<ChartData[]> => {
      try {
        const fetchedData = await Promise.all(data?.map((chart: ChartData) => fillDataForChart(baseUrl, chart)));
        // eslint-disable-next-line
        return fetchedData;
      } catch (e) {
        console.error(e);
        return [];
      }
    }),
  );
  return res;
}

export async function fillDashboardData(baseUrl: string, dashboard: ChartData[][]): Promise<ChartData[][]> {
  const filledChart = await fillDataForSingleDashboard(baseUrl, dashboard);
  return filledChart.filter((chart) => !!chart);
}

export const publicDashboards: Record<string, ToolItemProps[]> = {
  aave: [
    {
      id: "recommendations",
      icon: parameterRecommendationsImage,
      title: "Parameter Recommendations",
      subtitle: "Simulation-based recommendations for Aave V3 risk parameters.",
      link: "/aave/recommendations",
    },
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "V3 protocol health monitoring and liquidation risk analytics.",
      link: "/aave/risk",
    },
    {
      id: "steth-depeg",
      icon: youtubeImage,
      title: "stETH:ETH Depeg",
      subtitle: "Testing the effect of a stETH:ETH depeg.",
      link: "https://www.youtube.com/watch?v=nd2Hdb41aVw",
      externalLink: true,
    },
  ],
  "aave-v2": [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "V2 protocol health monitoring and liquidation risk analytics.",
      link: "/aave-v2/risk",
    },
  ],
  maker: [
    {
      id: "filp-auction",
      icon: youtubeImage,
      title: "Black Thursday & Flip Auctions",
      subtitle: 'Testing the collateral liquidation auctions ("Flip Auctions") under adverse market conditions.',
      link: "https://www.youtube.com/watch?v=tHIOcJNYK-c",
      externalLink: true,
    },
    {
      id: "flap-auction",
      icon: youtubeImage,
      title: "Flapper Surplus DAI Auctions",
      subtitle: "Creating the market conditions to trigger Flapper Surplus DAI Auctions.",
      link: "https://www.youtube.com/watch?v=ZDVs3pJpLR0",
      externalLink: true,
    },
    {
      id: "psm",
      icon: youtubeImage,
      title: "Peg Stability Module (PSM)",
      subtitle: "Testing the Peg Stability Module which allows users to swap collateral directly for DAI.",
      link: "https://www.youtube.com/watch?v=NGW10MgpZtQ",
      externalLink: true,
    },
    {
      id: "price-curve",
      icon: youtubeImage,
      title: "Keeper Gas Strategies",
      subtitle: "Simulating how keepers with competing gas strategies impact the Auction Price Curve for liquidations.",
      link: "https://www.youtube.com/watch?v=aNUHB39uISw",
      externalLink: true,
    },
  ],
  benqi: [
    {
      id: "recommendations",
      icon: parameterRecommendationsImage,
      title: "Parameter Recommendations",
      subtitle: "Simulation-based recommendations for BENQI risk parameters.",
      link: "/benqi/recommendations",
    },
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Protocol health and liquidation risk analytics",
      link: "/benqi/risk",
    },
    {
      id: "calculator",
      icon: calculatorImage,
      title: "veQI Calculator",
      subtitle: "Estimated staking profit for validators.",
      link: "/benqi/veqi-calculator",
    },
    {
      id: "liquid-staking",
      icon: liquidImage,
      title: "Liquid Staking Analytics",
      subtitle: "BENQI Liquid Staking (BLS) overview, and stakers, delegations, and validators information.",
      link: "/benqi/liquid-staking",
      data: [
        [
          {
            chartDataSource: "onchain",
            chain: "avalanche",
            chartId: "benqi.liquid_staking_overview_cards",
            chartTitle: "Overview",
            chartType: "TokenCards",
          },
        ],
        [
          {
            chartDataSource: "offchain",
            chartId: "benqi.liquid_staking_timeseries",
            chartType: "VictoryLineChart",
            hidePoints: true,
          },
        ],
        [
          {
            chartDataSource: "offchain",
            chartId: "benqi.liquid_staking_qi_timeseries",
            chartType: "VictoryLineChart",
            hidePoints: true,
          },
        ],
        [
          {
            chartDataSource: "offchain",
            chartId: "benqi.liquid_staking_delegates_overview",
            chartTitle: {
              key: "BLS Validators Overview",
              value: "BLS Validators Overview",
            },
            chartType: "TokenCards",
          },
        ],
        [
          {
            chartDataSource: "offchain",
            chartId: "benqi.liquid_staking_delegates_table",
            chartType: "Table",
          },
        ],
        [
          {
            chartDataSource: "offchain",
            chartId: "benqi.liquid_staking_holder_treemap",
            chartTitle: {
              key: "Top Stakers",
              value: "Top Stakers",
            },
            chartType: "TokenTreeMaps",
          },
        ],
        [
          {
            chartDataSource: "offchain",
            chartId: "benqi.liquid_staking_delegate_pie_chart",
            chartTitle: {
              key: "Total Delegation Distribution",
              value: "Total Delegation Distribution",
            },
            chartType: "HistogramChartRow",
            hideXAxisLabels: true,
          },
        ],
        [
          {
            chartDataSource: "offchain",
            chartId: "benqi.liquid_staking_all_delegations",
            chartType: "Table",
          },
        ],
      ],
    },
    {
      id: "reserves",
      icon: pieImage,
      title: "Reserves Dashboard",
      subtitle: "BENQI reserves broken down per asset.",
      link: "/benqi/reserves",
      data: [
        [
          {
            chartDataSource: "offchain",
            chartId: "benqi.reserves",
            chartType: "OverviewWithIconChart",
          },
        ],
      ],
    },
  ],
  dydx: [
    {
      id: "risk",
      icon: chartsImage,
      title: "V4 Risk Monitoring",
      subtitle: "Protocol health monitoring and analytics.",
      link: "/dydx-v4/risk",
    },
    {
      id: "asset-listing",
      icon: listingImage,
      title: "V3 Asset Listing Portal",
      subtitle: "Key market and risk data around listed and suggested assets.",
      link: "/dydx/asset-listing",
      assets: [
        {
          name: "Shiba Inu",
          id: "shiba-inu",
          tags: ["Pending"],
          symbol: "SHIB",
        },
        {
          name: "Celo",
          id: "celo",
          tags: ["Listed"],
          symbol: "CELO",
        },
        {
          name: "Optimism",
          id: "optimism",
          tags: ["Pending"],
          symbol: "OP",
        },
        {
          name: "Enjin Coin",
          id: "enjincoin",
          tags: ["Listed"],
          symbol: "ENJ",
        },
        {
          name: "Loopring (LRC)",
          id: "loopring",
          tags: ["Proposed"],
          symbol: "LRC",
        },
        {
          name: "Chiliz (CHZ)",
          id: "chiliz",
          tags: ["Proposed"],
          symbol: "CHZ",
        },
        {
          name: "The Sandbox",
          id: "the-sandbox",
          tags: ["Pending"],
          symbol: "SAND",
        },
        {
          name: "Theta Network",
          id: "theta-token",
          tags: ["Pending"],
          symbol: "THETA",
        },
        {
          name: "NEAR",
          id: "near",
          tags: ["Listed"],
          symbol: "NEAR",
        },
        {
          name: "Ethereum Name Service",
          id: "ethereum-name-service",
          tags: ["Pending"],
          symbol: "ENS",
        },
        {
          name: "Terra Luna Classic",
          id: "terra-luna",
          tags: ["Listed"],
          symbol: "LUNA",
        },
        {
          name: "Gala",
          id: "gala",
          tags: ["Pending"],
          symbol: "GALA",
        },
        {
          name: "Sushi",
          id: "sushi",
          tags: ["Listed"],
          symbol: "SUSHI",
        },
        {
          name: "Solana",
          id: "solana",
          tags: ["Listed"],
          symbol: "SOL",
        },
        {
          name: "1inch",
          id: "1inch",
          tags: ["Listed"],
          symbol: "1INCH",
        },
        {
          name: "ApeCoin",
          id: "apecoin",
          tags: ["Pending"],
          symbol: "APE",
        },
        {
          name: "Kava",
          id: "kava",
          tags: ["Proposed"],
          symbol: "KAVA",
        },
        {
          name: "UMA",
          id: "uma",
          tags: ["Listed"],
          symbol: "UMA",
        },
        {
          name: "Dogecoin",
          id: "dogecoin",
          tags: ["Listed"],
          symbol: "DOGE",
        },
        {
          name: "VeChain",
          id: "vechain",
          tags: ["Pending"],
          symbol: "VET",
        },
        {
          name: "Polkadot",
          id: "polkadot",
          tags: ["Listed"],
          symbol: "DOT",
        },
        {
          name: "Chromia (CHR)",
          id: "chromaway",
          tags: ["Proposed"],
          symbol: "CHR",
        },
        {
          name: "Filecoin",
          id: "filecoin",
          tags: ["Listed"],
          symbol: "FIL",
        },
        {
          name: "Fantom",
          id: "fantom",
          tags: ["Pending"],
          symbol: "FTM",
        },
        {
          name: "Monero",
          id: "monero",
          tags: ["Listed"],
          symbol: "XMR",
        },
        {
          name: "STEPN",
          id: "stepn",
          tags: ["Pending"],
          symbol: "GMT",
        },
        {
          name: "Cosmos Hub",
          id: "cosmos",
          tags: ["Listed"],
          symbol: "ATOM",
        },
        {
          name: "Waves",
          id: "waves",
          tags: ["Pending"],
          symbol: "WAVES",
        },
        {
          name: "Uniswap",
          id: "uniswap",
          tags: ["Listed"],
          symbol: "UNI",
        },
        {
          name: "Cardano",
          id: "cardano",
          tags: ["Listed"],
          symbol: "ADA",
        },
        {
          name: "Bitcoin Cash",
          id: "bitcoin-cash",
          tags: ["Listed"],
          symbol: "BCH",
        },
        {
          name: "Algorand",
          id: "algorand",
          tags: ["Listed"],
          symbol: "ALGO",
        },
        {
          name: "Zcash",
          id: "zcash",
          tags: ["Listed"],
          symbol: "ZCASH",
        },
        {
          name: "Stellar",
          id: "stellar",
          tags: ["Listed"],
          symbol: "XLM",
        },
        {
          name: "Axie Infinity",
          id: "axie-infinity",
          tags: ["Pending"],
          symbol: "AXS",
        },
        {
          name: "Zilliqa (ZIL)",
          id: "zilliqa",
          tags: ["Pending"],
          symbol: "ZIL",
        },
        {
          name: "Yearn",
          id: "yearn-finance",
          tags: ["Listed"],
          symbol: "YFI",
        },
        {
          name: "Litecoin",
          id: "litecoin",
          tags: ["Listed"],
          symbol: "LTC",
        },
        {
          name: "Internet Computer",
          id: "internet-computer",
          tags: ["Listed"],
          symbol: "ICP",
        },
        {
          name: "Polygon",
          id: "matic-network",
          tags: ["Listed"],
          symbol: "MATIC",
        },
        {
          name: "EOS",
          id: "eos",
          tags: ["Listed"],
          symbol: "EOS",
        },
        {
          name: "Reserve Rights",
          id: "reserve-rights-token",
          tags: ["Pending"],
          symbol: "RSR",
        },
        {
          name: "Ethereum Classic",
          id: "ethereum-classic",
          tags: ["Listed"],
          symbol: "ETC",
        },
        {
          name: "Avalanche",
          id: "avalanche-2",
          tags: ["Listed"],
          symbol: "AVAX",
        },
        {
          name: "Synthetix",
          id: "havven",
          tags: ["Listed"],
          symbol: "SNX",
        },
        {
          name: "Chainlink",
          id: "chainlink",
          tags: ["Listed"],
          symbol: "LINK",
        },
        {
          name: "Bitcoin",
          id: "bitcoin",
          tags: ["Listed"],
          symbol: "BTC",
        },
        {
          name: "0x",
          id: "0x",
          tags: ["Listed"],
          symbol: "ZRX",
        },
        {
          name: "Compound",
          id: "compound-governance-token",
          tags: ["Listed"],
          symbol: "COMP",
        },
        {
          name: "Curve",
          id: "curve-dao-token",
          tags: ["Listed"],
          symbol: "CRV",
        },
        {
          name: "THORChain",
          id: "thorchain",
          tags: ["Listed"],
          symbol: "RUNE",
        },
        {
          name: "Maker",
          id: "maker",
          tags: ["Listed"],
          symbol: "MKR",
        },
        {
          name: "Cronos",
          id: "crypto-com-chain",
          tags: ["Proposed"],
          symbol: "CRO",
        },
        {
          name: "Ethereum",
          id: "ethereum",
          tags: ["Listed"],
          symbol: "ETH",
        },
        {
          name: "Aave",
          id: "aave",
          tags: ["Listed"],
          symbol: "AAVE",
        },
        {
          name: "Decentraland (MANA)",
          id: "decentraland",
          tags: ["Listed"],
          symbol: "MANA",
        },
      ],
    },
    {
      id: "calculator",
      icon: calculatorImage,
      title: "V3 Trading Rewards Calculator",
      subtitle: "Utility to calculate and predict DYDX rewards to traders",
      link: "/dydx/calculator",
    },
    {
      id: "perpetual-funding-rate",
      icon: rateImage,
      title: "V3 Perpetual Funding Rate",
      subtitle: "Live look of all funding rates across the active markets.",
      link: "/dydx/perpetual-funding-rate",
    },
  ],
  chainlink: [
    {
      id: "hardhat",
      icon: githubImage,
      title: "Oracle Prices Hardhat Plugin",
      subtitle: "Open Source Hardhat plugin for configuring Chainlink oracle prices.",
      link: "https://github.com/ChaosLabsInc/chainlink-hardhat-plugin",
      externalLink: true,
    },
    {
      id: "oracle-prices",
      icon: githubImage,
      title: "Oracle Prices CLI",
      subtitle: "Open Source CLI utitlity for configuring Chainlink oracle prices.",
      link: "https://github.com/ChaosLabsInc/chainlink-oracle-cli",
      externalLink: true,
    },
    {
      id: "prices-feeds",
      icon: githubImage,
      title: "Chainlink Price Feeds",
      subtitle: "A simple open source package for fetching Chainlink price feeds of various networks.",
      link: "https://github.com/ChaosLabsInc/chainlink-price-feeds",
      externalLink: true,
    },
  ],
  uniswap: [
    {
      id: "lp-strategy-calculator",
      icon: listingImage,
      title: "Uniswap V3 LP Strategies",
      subtitle:
        " A simulation-based system, which helps LPs find relevant pools and strategies based on preferences and market sentiments.",
      link: "/uniswap/lp-strategy-calculator",
    },
    {
      id: "twap",
      icon: rateImage,
      title: "Uniswap V3 TWAP Oracle Risk",
      subtitle: " Cost of price manipulation for TWAP oracles",
      link: "/uniswap/twap",
    },
    {
      id: "hardhat",
      icon: githubImage,
      title: "Oracle Prices Hardhat Plugin",
      subtitle: "Open Source Hardhat plugin for configuring Uniswap V3 oracle prices.",
      link: "https://github.com/ChaosLabsInc/uniswap-v3-oracle-hardhat-plugin",
      externalLink: true,
    },
    {
      id: "oracle-prices",
      icon: githubImage,
      title: "Oracle Prices CLI",
      subtitle: "Open Source CLI utitlity for configuring Uniswap V3 oracle prices.",
      link: "https://github.com/ChaosLabsInc/uniswap-v3-oracle-cli",
      externalLink: true,
    },
    {
      id: "oracle-mocker",
      icon: githubImage,
      title: "Oracle Prices Mocker",
      subtitle:
        "A simple open source package for configuring Uniswap V3 oracle prices in a local hardhat mainnet fork.",
      link: "https://github.com/ChaosLabsInc/uniswap-v3-oracle-pool-mocker",
      externalLink: true,
    },
  ],
  e2etests: [
    {
      id: "test",
      icon: graphsImage,
      title: "E2E TEST Dashboard",
      subtitle: "Protocol health and liquidation risk analytics",
      link: "/aave/risk",
    },
  ],
  osmosis: [
    {
      id: "liquidity-incentives",
      icon: graphsImage,
      title: "Incentives Optimization",
      subtitle: "Liquidity incentives optimization recommendations",
      link: "/osmosis/incentives-optimization",
    },
    {
      id: "liquidity-incentives-lps",
      icon: chartsImage,
      title: "Incentive Providers Dashboard",
      subtitle: "Showing effects of liquidity incentives optimization recommendations on Incentive providers' APRs",
      link: "/osmosis/lp-dashboard",
    },
  ],
  "gmx-arbitrum": [
    {
      id: "risk",
      icon: graphsImage,
      title: "V1 Risk Monitoring",
      subtitle: "Protocol health monitoring and liquidation risk analytics.",
      link: "/gmx-arbitrum/risk",
    },
  ],
  "gmx-avalanche": [
    {
      id: "risk",
      icon: graphsImage,
      title: "V1 Risk Monitoring",
      subtitle: "Protocol health monitoring and liquidation risk analytics.",
      link: "/gmx-avalanche/risk",
    },
  ],
  "gmx-v2-arbitrum": [
    {
      id: "risk",
      icon: chartsImage,
      title: "V2 Risk Monitoring",
      subtitle: "Protocol health monitoring and liquidation risk analytics.",
      link: "/gmx-v2-arbitrum/risk",
    },
  ],
  "gmx-v2-avalanche": [
    {
      id: "risk",
      icon: chartsImage,
      title: "V2 Risk Monitoring",
      subtitle: "Protocol health monitoring and liquidation risk analytics.",
      link: "/gmx-v2-avalanche/risk",
    },
  ],
  radiant: [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Alerts for protocol V2",
      link: "/radiant/risk",
    },
  ],
  venus: [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Showing risk aggregation across all borrow markets.",
      link: "/venus/risk",
    },
  ],
  tapioca: [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Showing risk aggregation across Tapioca borrow markets.",
      link: "/tapioca/risk",
    },
  ],
  "crv-usd": [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Showing risk aggregation across crvUSD markets.",
      link: "/crv-usd/risk",
    },
  ],
  seamless: [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Showing risk aggregation across Seamless borrow markets.",
      link: "/seamless/risk",
    },
  ],
  zerolend: [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Showing risk aggregation across ZeroLend borrow markets.",
      link: "/zerolend/risk",
    },
  ],
  bluefin: [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Protocol health monitoring and liquidation risk analytics.",
      link: "/bluefin/risk",
    },
  ],
  nftperp: [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Protocol health monitoring and liquidation risk analytics.",
      link: "/nftperp/risk",
    },
  ],
  jupiter: [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Protocol health monitoring and liquidation risk analytics.",
      link: "/jupiter/risk",
    },
  ],
  synfutures: [
    {
      id: "risk",
      icon: graphsImage,
      title: "Risk Monitoring",
      subtitle: "Protocol health monitoring and liquidation risk analytics.",
      link: "/synfutures/risk",
    },
  ],
};
