import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "../../../config/routes";
import { replaceHomePath } from "./utils";
import { FactionLeaderboardTab, DegenLeaderboardTab } from "../tabs";
import { renderDegenPageLayout, renderFactionPageLayout, renderTabsLayout } from "./layout/layout";
import { DegenDetailsPage, FactionDetailsPage } from "../pages";

const DefiAppLeaderboardsRoutes = () => {
  const { clientName } = useParams();
  const defaultRoute = RoutePath.Risk.FactionLeaderboard.replace(RouteParams.ClientName, clientName!);

  return (
    <Routes>
      <Route index element={<Navigate to={defaultRoute} replace />} />
      <Route
        path={replaceHomePath(RoutePath.Risk.FactionLeaderboard)}
        element={renderTabsLayout(<FactionLeaderboardTab />)}
      />
      <Route
        path={replaceHomePath(RoutePath.Risk.DegenLeaderboard)}
        element={renderTabsLayout(<DegenLeaderboardTab />)}
      />
      <Route
        path={replaceHomePath(RoutePath.Risk.FactionDetails)}
        element={renderFactionPageLayout(<FactionDetailsPage />)}
      />
      <Route
        path={replaceHomePath(RoutePath.Risk.DegenDetails)}
        element={renderDegenPageLayout(<DegenDetailsPage />)}
      />
      <Route path="*" element={<Navigate to={defaultRoute} replace />} />
    </Routes>
  );
};

export default DefiAppLeaderboardsRoutes;
