import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { LiquidationsOverview } from "../../../generated";

type Props = {
  overviewData?: LiquidationsOverview;
  isLoading?: boolean;
};

const LiquidationsCards = ({ overviewData, isLoading }: Props) => {
  const {
    totalLiquidations,
    collateralSeized,
    debtRepaid,
    liquidationBonus,
    totalBadDebtValueUsd,
    totalBadDebtWallets,
  } = overviewData || {};
  const cards: ValueCardProps[] = [
    {
      title: "Number of Liquidations",
      value: isLoading ? undefined : totalLiquidations || 0,
    },
    {
      title: "Collateral Seized",
      value: isLoading ? undefined : collateralSeized || 0,
      currency: "USD",
    },
    {
      title: "Debt Repaid",
      value: isLoading ? undefined : debtRepaid || 0,
      currency: "USD",
    },
    {
      title: "Liquidation Bonus",
      value: isLoading ? undefined : liquidationBonus || 0,
      currency: "USD",
    },
    {
      title: "Bad Debt",
      value: totalBadDebtValueUsd,
      tooltip:
        "Total bad debt across the protocol, calculated as the sum of total borrow minus total collateral for wallets where collateral value is less than borrow value",
      currency: "USD",
    },
    {
      title: "Number of Wallets with Bad Debt",
      value: totalBadDebtWallets,
      tooltip: "The number of wallets with bad debt",
    },
  ];

  return (
    <Grid container>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default LiquidationsCards;
