import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ListedAssetsContext } from "../context/listed-assets-context";
import { AssetsRecommendationsContext } from "../context/assets-recommendations-context";

export const useAssetsDropdownOptionsByTab = () => {
  const location = useLocation();
  const { listedAssets, isLoading: isLoadingListedAssets } = useContext(ListedAssetsContext);
  const { assetsRecommendations, isLoading: isLoadingAssetsRecommendations } = useContext(AssetsRecommendationsContext);
  const isRecommendationsTab = location.pathname.includes("recommendations");

  const isLoading = isLoadingAssetsRecommendations || isLoadingListedAssets;

  const listedAssetsNames = listedAssets?.map((a) => a.asset);
  const recommendedAssetsNames = assetsRecommendations?.map((d) => d.asset);

  if (isRecommendationsTab) {
    return { isLoading, assets: recommendedAssetsNames };
  }

  return { isLoading, assets: listedAssetsNames };
};
