import { CryptoIcon } from "@frontend/ui";
import PoolTokenWrapper from "./pool-token-wrapper";

export type PoolTokenIconProps = {
  token: string;
  size?: number;
};

const PoolTokenIcon = ({ token, size = 40 }: PoolTokenIconProps) => (
  <PoolTokenWrapper size={size}>
    <CryptoIcon icon={token.toLowerCase()} sx={{ img: { width: size - 5, height: size - 5 } }} />
  </PoolTokenWrapper>
);

export default PoolTokenIcon;
