import { EChartsOption } from "echarts";
import { Theme } from "@mui/material";
import { echartsTooltipFormatter } from "../../tooltipFormatter";
import { StringChartProps } from "../../types";
import { formatEChartValue } from "../../utils";

export const getValueOverTimeChart = (
  theme: Theme,
  {
    series,
    isPercent,
    currency,
    showLegend = true,
    yAxisLabel,
    cryptoCurrency,
    formatXAxisLabel,
    tooltipHeaderFormatter,
  }: StringChartProps,
): EChartsOption => {
  const legend = showLegend ? series.map((serie) => serie.label || "") : [];

  return {
    tooltip: {
      trigger: "axis",
      formatter: echartsTooltipFormatter({
        theme,
        headerFormatter: (value) => tooltipHeaderFormatter?.(value) || value.toString(),
        valueFormatter: (v) =>
          formatEChartValue({ isPercent, currency, notation: "standard", cryptoCurrency })(Number(v)),
        currency,
        showTotal: false,
        sortValues: true,
      }),
      padding: 0,
      renderMode: "auto",
      verticalAlign: "middle",
    },
    legend: legend.length
      ? {
          data: legend,
          type: "scroll",
          padding: [0, 24, 0, 0],
          textStyle: {
            fontSize: 14,
          },
        }
      : undefined,
    xAxis: {
      boundaryGap: false,
      type: "category",
      axisLabel: {
        hideOverlap: true,
        fontSize: 12,
        formatter: (value: string) => formatXAxisLabel?.(value) || value,
      },
    },
    yAxis: {
      type: "value",
      name: yAxisLabel,
      nameLocation: "middle",
      position: "left",
      nameGap: 70,
      axisLabel: {
        fontSize: 12,
        formatter: (v: number) => {
          if (Math.abs(v) >= 1) {
            return formatEChartValue({
              isPercent,
              currency,
              cryptoCurrency,
            })(Number(v));
          }
          return formatEChartValue({ isPercent, currency, notation: "standard", cryptoCurrency })(Number(v));
        },
      },
    },
    series: series.map((serie) => ({
      id: serie.id,
      name: serie.label,
      type: "line",
      data: serie.data,
      symbol: "none",
      markLine: serie.markLine,
    })),
  };
};
