import { RenderData } from "@frontend/types";
import { diffDates, formatDateAndTime } from "@frontend/ui/utils/formatters";
import { PositionSide } from "../../generated";

export const dateDiffCell = (date0: number, date1: number | null): RenderData => {
  const startDate = new Date(date0);
  const endDate = date1 ? new Date(date1) : new Date();
  const diff = diffDates(startDate, endDate);

  return {
    text: diff,
    renderType: "TEXT",
    value: 0,
  };
};

export const dateCell = (timestamp: number): RenderData => {
  const date = new Date(timestamp);

  return {
    renderType: "TEXT",
    text: formatDateAndTime(date),
    tooltipText: formatDateAndTime(date),
    value: timestamp,
  };
};

export const positionSymbolCell = (assetSymbol: string, assetName: string): RenderData => ({
  renderType: "TEXT",
  text: assetName,
  token1: assetSymbol,
});

export const positionSideCell = (side: PositionSide): RenderData => ({
  renderType: "TEXT",
  text: side,
  customIcon: side === PositionSide.Long ? "line-up" : "line-down",
});
