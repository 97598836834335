import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { TimeSpan } from "src/pages/ccar-lending-page/generated";
import { MultiChartData } from "../types";

type Props = {
  isLoading?: boolean;
  timeSpan?: TimeSpan;
  data?: MultiChartData;
};

export const ValueAtRisk = ({ timeSpan, isLoading, data }: Props) => (
  <Paper variant="widget">
    <CompositionOverTimeChart
      isLoading={isLoading}
      title="Collateral at Risk"
      description="Value at risk of liquidation in positions with health score approaching 1"
      currency="USD"
      percentToggle
      series={Object.values(data || {}).map((d) => ({
        label: d.title,
        data: d.data.map((c) => [c.x.getTime(), c.y]),
      }))}
      timeSpan={timeSpan}
    />
  </Paper>
);
