import { FC, ReactNode, createContext, useMemo, useState, Dispatch, SetStateAction, useContext } from "react";
import { useLocation } from "react-router-dom";
import { UnderReviewPathSegment } from "src/config/risk-monitoring-routes";
import {
  useRecommendationsQuery,
  RecommendationsQueryInput,
  VisibilityState,
  Recommendation,
} from "src/pages/ccar-lending-page/generated";

export type MarketAssetsRecommendationsContextType = {
  assetsRecommendations?: Recommendation[];
  isLoading: boolean;
  isUnderReview: boolean;
  marketId?: string;
  setMarketId: Dispatch<SetStateAction<string | undefined>>;
};

const initialState: MarketAssetsRecommendationsContextType = {
  isLoading: false,
  setMarketId: () => {},
  isUnderReview: false,
};

export const MarketAssetsRecommendationsContext = createContext<MarketAssetsRecommendationsContextType>(initialState);

const MarketAssetsRecommendationsProvider: FC<ReactNode> = ({ children }) => {
  const [marketId, setMarketId] = useState<string>();
  const location = useLocation();
  const isUnderReview = location.pathname.includes(UnderReviewPathSegment);

  const recommendationsQuery: RecommendationsQueryInput = useMemo(
    () => ({
      marketId: marketId?.toLowerCase() || "",
      visibility: isUnderReview ? VisibilityState.Pending : VisibilityState.Published,
      assetName: null,
    }),
    [marketId, isUnderReview],
  );

  const { loading, data } = useRecommendationsQuery({
    fetchPolicy: "no-cache",
    variables: {
      query: recommendationsQuery,
    },
    skip: !marketId,
  });

  const contextState: MarketAssetsRecommendationsContextType = useMemo(
    () => ({
      assetsRecommendations: data?.recommendations,
      isLoading: loading,
      marketId,
      setMarketId,
      isUnderReview,
    }),
    [marketId, data, loading, isUnderReview],
  );

  return (
    <MarketAssetsRecommendationsContext.Provider value={contextState}>
      {children}
    </MarketAssetsRecommendationsContext.Provider>
  );
};

export const useMarketAssetsRecommendationsContext = () => useContext(MarketAssetsRecommendationsContext);

export default MarketAssetsRecommendationsProvider;
