import { FC } from "react";
import { PageTracker } from "src/components/page-tracker";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { Error } from "@frontend/ui/error";
import { RouteParams, RoutePath } from "src/config/routes";
import { ChaosApolloProvider } from "src/utils/graphql";
import { FeatureFlagsProvider, useFeatureFlag } from "src/utils/feature-flags";
import { CCARPerpsTabsLayout } from "./ccar-perpetuals-tabs-layout";
import { Client } from "./types";
import { clientsConfig, useClientConfig } from "./clients-config";
import {
  MarketsTab,
  OverviewTab,
  PositionsTab,
  AccountRoutes,
  LiquidationsTab,
  RiskExplorerTab,
  AlertsTab,
} from "./tabs";

const replaceHomePath = (path: string) => path.replace(RoutePath.CCARPerpetuals.Home, "");

const CCARPerpetualsPageInner: FC = PageTracker(() => {
  const { clientName } = useParams<{ clientName: Client }>();
  const { overview, markets, alerts } = useClientConfig();
  const getDefaultRoute = () => RoutePath.CCARPerpetuals.Overview;

  if (!Object.keys(clientsConfig).includes(clientName!)) {
    return <Error errorTitle="404" errorMessage="Page Not Found" />;
  }

  return (
    <Routes key={clientName}>
      <Route index element={<Navigate to={getDefaultRoute().replace(RouteParams.ClientName, clientName!)} replace />} />
      {overview && (
        <Route
          path={RoutePath.CCARPerpetuals.Overview.replace(RoutePath.CCARPerpetuals.Home, "")}
          element={
            <CCARPerpsTabsLayout>
              <OverviewTab />
            </CCARPerpsTabsLayout>
          }
        />
      )}
      {markets && <Route path={`${replaceHomePath(RoutePath.CCARPerpetuals.Markets)}/*`} element={<MarketsTab />} />}
      <Route
        path={RoutePath.CCARPerpetuals.Positions.replace(RoutePath.CCARPerpetuals.Home, "")}
        element={
          <CCARPerpsTabsLayout>
            <PositionsTab />
          </CCARPerpsTabsLayout>
        }
      />
      <Route
        path={RoutePath.CCARPerpetuals.SubAccountPositions.replace(RoutePath.CCARPerpetuals.Home, "")}
        element={
          <CCARPerpsTabsLayout>
            <AccountRoutes />
          </CCARPerpsTabsLayout>
        }
      />
      <Route
        path={`${RoutePath.CCARPerpetuals.AccountPositions.replace(RoutePath.CCARPerpetuals.Home, "")}/*`}
        element={
          <CCARPerpsTabsLayout>
            <AccountRoutes />
          </CCARPerpsTabsLayout>
        }
      />
      <Route
        path={RoutePath.CCARPerpetuals.Liquidations.replace(RoutePath.CCARPerpetuals.Home, "")}
        element={
          <CCARPerpsTabsLayout>
            <LiquidationsTab />
          </CCARPerpsTabsLayout>
        }
      />
      <Route
        path={RoutePath.CCARPerpetuals.RiskExplorer.replace(RoutePath.CCARPerpetuals.Home, "")}
        element={
          <CCARPerpsTabsLayout>
            <RiskExplorerTab />
          </CCARPerpsTabsLayout>
        }
      />
      {alerts?.enabled && (
        <Route
          path={RoutePath.CCARPerpetuals.Alerts.replace(RoutePath.CCARPerpetuals.Home, "")}
          element={
            <CCARPerpsTabsLayout>
              <AlertsTab />
            </CCARPerpsTabsLayout>
          }
        />
      )}
      <Route
        path="*"
        element={<Navigate to={getDefaultRoute().replace(RouteParams.ClientName, clientName!)} replace />}
      />
    </Routes>
  );
});

const useShouldPerformPerpetualsRedirect = () => {
  const isProductRedirectEnabled = useFeatureFlag("unified_product_redirect");
  return isProductRedirectEnabled && !!window.location.pathname.match("/ccar-perps/");
};

export const WithUnifiedPerpetualsRedirect: FC = ({ children }) => {
  const shouldRedirect = useShouldPerformPerpetualsRedirect();

  if (shouldRedirect) {
    return <Navigate to={window.location.pathname.replace("/ccar-perps", "/risk")} replace />;
  }

  return <div className="unified-product-redirect">{children}</div>;
};

export const CCARPerpetualsPage = () => (
  <ChaosApolloProvider product="ccar-perpetuals">
    <FeatureFlagsProvider>
      <WithUnifiedPerpetualsRedirect>
        <CCARPerpetualsPageInner />
      </WithUnifiedPerpetualsRedirect>
    </FeatureFlagsProvider>
  </ChaosApolloProvider>
);
