import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import AlertsTable from "../../../../components/alerts-table/alerts";
import { useClientConfig } from "../../clients-config";

export const AlertsTab = () => {
  const { alerts } = useClientConfig();

  return (
    <Box display="flex" flexDirection="column">
      <PageHeader showBackButton={false} pageTitle="Alerts" />
      <AlertsTable notificationsChannels={alerts?.notificationsChannels} />
    </Box>
  );
};
