import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { ChainData } from "../../generated";

type Props = {
  data: ChainData | undefined;
};

export const ChainDetailsCards = ({ data }: Props) => {
  const cards: ValueCardProps[] = [
    {
      title: "TVL",
      value: data?.tvl,
      currency: "USD",
    },
    {
      title: "Total Borrowed",
      value: data?.totalBorrowed,
      currency: "USD",
    },
    {
      title: "Total Supply",
      value: data?.totalSupply,
      currency: "USD",
    },
    {
      title: "Open Credit Accounts",
      value: data?.numberOfOpenAccounts,
    },
    {
      title: "Closed Credit Accounts",
      value: data?.numberOfCloseAccounts,
    },
    {
      title: "Credit Accounts at Risk",
      value: data?.numberOfAccountAtRisk,
    },
  ];

  return (
    <Grid container>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};
