import { SubNavTabs, TabProps, TitleTabs } from "@frontend/ui";
import { useTabsNavigations } from "./use-tabs-navigations";

export type NavigationTabProps = TabProps & {
  path?: string;
  isActive?: (pathname: string) => boolean;
};

type Props = {
  tabs: NavigationTabProps[];
  variant?: "title" | "sub-nav";
};

const TabsNavigation = ({ tabs, variant = "title" }: Props) => {
  const { activeTabIdx, handleTabChange } = useTabsNavigations(tabs);

  switch (variant) {
    case "sub-nav":
      return <SubNavTabs tabs={tabs} value={activeTabIdx} onChange={handleTabChange} />;
    default:
      return <TitleTabs tabs={tabs} value={activeTabIdx} onChange={handleTabChange} />;
  }
};

export default TabsNavigation;
