import { Box, CustomReactSelect } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useParams, useLocation } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useDefiAppApiRequest } from "../../api/use-api-request";
import { DefiAppApiEndpoint } from "../../api/types";

const FactionsDropdownHeader = () => {
  const { factionId: factionIdParam, clientName } = useParams();
  const location = useLocation();
  const { isLoading, response } = useDefiAppApiRequest(DefiAppApiEndpoint.Factions);

  const dropdownOptions = (response || [])
    .sort((a, b) => a.ranking - b.ranking)
    .map(({ factionId, factionName, image }) => ({
      value: factionId,
      label: factionName,
      link: location.pathname.replace(factionIdParam!, factionId),
      imgSrc: image || undefined,
    }));

  const selectedFaction = dropdownOptions.find((opt) => opt.value === factionIdParam);

  const backPath = RoutePath.Risk.FactionLeaderboard.replace(RouteParams.ClientName, clientName!);

  return (
    <PageHeader
      backLink={backPath}
      pageTitle={
        <Box sx={{ display: "inline-flex", alignItems: "center", gap: 2 }} data-testid="page-title">
          <CustomReactSelect
            options={dropdownOptions}
            value={selectedFaction}
            isSearchable={false}
            variant="h3"
            isLoading={isLoading}
          />
        </Box>
      }
      showBackButton
    />
  );
};

export default FactionsDropdownHeader;
