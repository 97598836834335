import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import CreditAccountsTable from "../../components/credit-accounts-table";

const CreditAccounts = () => (
  <Box>
    <PageHeader showBackButton={false} pageTitle="Credit Accounts" />
    <CreditAccountsTable />
  </Box>
);

export default CreditAccounts;
