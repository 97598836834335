import { useMemo, useState } from "react";
import { Paper, SubNavTabs, Typography, ValueOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { HistoricalPrices } from "src/pages/ccar-lending-page/generated";
import { formatAmount } from "@frontend/ui/utils/formatters";

type Props = {
  isLoading: boolean;
  assetHistoricalPrices?: HistoricalPrices[];
  isMultiAsset?: boolean;
  currentPrice?: number;
};

const getSourceHumanReadableName = (sourceName: string): string => {
  switch (sourceName.toLowerCase()) {
    case "coingecko":
      return "CoinGecko";
    case "chainlink":
      return "Chainlink";
    case "uniswapv3":
      return "Uniswap V3";
    case "crv_controller_oracle":
      return "Oracle Price";
    case "binance_oracle":
      return "Binance Oracle";
    case "ostium_api":
      return "Ostium";
    default:
      return sourceName;
  }
};

export const HistoricalPriceChart = ({ isLoading, assetHistoricalPrices, isMultiAsset, currentPrice }: Props) => {
  const historicalPricesPerSource = assetHistoricalPrices?.reduce<Record<string, HistoricalPrices[]>>(
    (acc, prices) => ({
      ...acc,
      [prices.sourceName]: [...(acc[prices.sourceName] || []), prices],
    }),
    {},
  );
  const sourceToggles = useMemo(
    () =>
      Object.keys(historicalPricesPerSource || {}).map((sourceName, i) => ({
        value: i,
        label: getSourceHumanReadableName(sourceName),
      })),
    [historicalPricesPerSource],
  );
  const [currentSource, setCurrentSource] = useState(0);
  const chartData =
    assetHistoricalPrices
      ?.filter(
        (source) =>
          !isMultiAsset || getSourceHumanReadableName(source.sourceName) === sourceToggles[currentSource].label,
      )
      .map<NumberChartSeries>((source) => ({
        type: "line",
        label: isMultiAsset ? source.mappedSymbol : getSourceHumanReadableName(source.sourceName),
        data: source.data.map((p) => [p.time, p.value]),
      })) || [];

  return (
    <ValueOverTimeChart
      key={isMultiAsset ? currentSource : undefined}
      isLoading={isLoading}
      emptyState={assetHistoricalPrices?.length === 0}
      title={isMultiAsset ? "Assets Price Over Time" : `${assetHistoricalPrices?.[0]?.mappedSymbol || ""} Price`}
      description={!isMultiAsset ? "Comparing asset price from different data sources" : undefined}
      series={chartData}
      yAxisMin
      yAxisMax
      headerSuffix={
        // eslint-disable-next-line no-nested-ternary
        isMultiAsset ? (
          <SubNavTabs tabs={sourceToggles} value={currentSource} onChange={(_, value) => setCurrentSource(value)} />
        ) : currentPrice ? (
          <Paper variant="card" sx={{ px: 2, py: 1, display: "flex", gap: 1, alignItems: "center" }}>
            <Typography variant="h6">Price:</Typography>
            <Typography variant="h5">
              {formatAmount(currentPrice, { currency: "usd", notation: "standard" })}
            </Typography>
          </Paper>
        ) : undefined
      }
    />
  );
};

export default HistoricalPriceChart;
