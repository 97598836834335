import { useParams } from "react-router-dom";
import { gearboxConfig } from "./clients.ts";
import { LeverageClientConfig, Client } from "./types";

export const clientsConfig: Record<Client, LeverageClientConfig> = {
  gearbox: gearboxConfig,
};
export const useClientConfig = (): LeverageClientConfig => {
  const { clientName } = useParams<{ clientName: Client }>();
  return clientsConfig[clientName!];
};
