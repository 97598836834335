import { useMemo } from "react";
import { ChaosTable, currencyCell, chainCell, numberCell } from "@frontend/ui";
import { formatAddressCompact, formatAmount, formatDateAndTime } from "@frontend/ui/utils/formatters";
import { Header, RenderData } from "@frontend/types";
import { RouteParams, RoutePath } from "src/config/routes";
import { getBlockDetailsUrl, getTxDetailsUrl } from "@frontend/ui/utils/chain-url-mapper";
import { LiquidationEventFragment } from "../../../generated";
import { positionSideCell } from "../../positions/cells";
import { useClientConfig } from "../../../clients-config";

type LiquidationsTableProps = {
  events?: LiquidationEventFragment[];
  loading?: boolean;
  title?: string;
  subtitle?: string;
  hideDate?: boolean;
  hideTransaction?: boolean;
  hideAddress?: boolean;
  hideLeverage?: boolean;
  chain: string;
};

const getTransactionUrl = (chain: string, txHash: string, blockNumber?: number) =>
  blockNumber ? getBlockDetailsUrl(chain, blockNumber.toString()) : getTxDetailsUrl(chain, txHash);

export const LiquidationsTable = ({
  events,
  loading,
  title,
  subtitle,
  hideDate,
  hideTransaction,
  hideAddress,
  hideLeverage,
  chain,
}: LiquidationsTableProps) => {
  const { clientName, liquidations, marketBaseAssetOverride } = useClientConfig();
  const tableHeader: Header[] = useMemo(
    () => [
      ...(!hideDate
        ? [
            {
              renderType: "TEXT",
              text: "Date",
              isDate: true,
              width: "15%",
            } as Header,
          ]
        : []),
      ...(!hideAddress ? [{ renderType: "TEXT", text: "Account" } as Header] : []),
      ...(!hideTransaction
        ? [{ renderType: "TEXT", text: liquidations?.showBlockNumber ? "Block" : "Tx" } as Header]
        : []),
      { renderType: "TEXT", text: "Market" },
      { renderType: "TEXT", text: "Position" },
      ...(!hideLeverage ? [{ renderType: "TEXT", text: "Leverage" } as Header] : []),
      { renderType: "TEXT", text: "Liquidated Value" },
      { renderType: "TEXT", text: "Liquidated Value USD" },
    ],
    [hideDate, hideTransaction, hideAddress, hideLeverage, liquidations],
  );
  // eslint-disable-next-line
  const tableData: RenderData[][] = useMemo(
    () =>
      events?.map((e) => {
        const overrideAsset = e.marketId && marketBaseAssetOverride?.[e.marketId];
        return [
          ...(!hideDate
            ? [
                {
                  renderType: "TEXT",
                  text: formatDateAndTime(new Date(e.timestamp)),
                  value: e.timestamp,
                } as RenderData,
              ]
            : []),
          ...(!hideAddress
            ? [
                {
                  renderType: "TEXT",
                  text: formatAddressCompact(e?.account || ""),
                  copyText: e.account,
                  tooltipText: e.account,
                  searchTerm: e.account,
                  exportText: e.account,
                } as RenderData,
              ]
            : []),
          ...(!hideTransaction
            ? [
                {
                  renderType: "LINK",
                  text: liquidations?.showBlockNumber ? e.blockNumber || "" : formatAddressCompact(e.txHash || "", 4),
                  copyText: e.txHash || "",
                  tooltipText: e.txHash || "",
                  href: getTransactionUrl(chain, e.txHash || "", e.blockNumber || undefined),
                  searchTerm: e.txHash,
                  exportText: e.txHash,
                } as RenderData,
              ]
            : []),
          chainCell(overrideAsset || e.asset, overrideAsset || e.assetName),
          positionSideCell(e.side),
          ...(!hideLeverage ? [numberCell(e.leverage)] : []),
          {
            renderType: "TEXT",
            text: formatAmount(e.collateralAmount || 0),
            token1: liquidations?.isLiquidationsInMarketToken ? e.asset : e.collateral,
            tooltipText: `${e.collateralAmount || 0} ${e.collateral}`,
            value: e.collateralAmount,
          },
          currencyCell(e.collateralAmountUSD),
        ];
      }) || [],
    [events, hideDate, hideTransaction, hideAddress, hideLeverage, chain, liquidations, marketBaseAssetOverride],
  );

  return (
    <ChaosTable
      rowHref={(rowId) => {
        const account = events?.[rowId]?.account;
        const subAccount = events?.[rowId]?.subAccount;

        if (subAccount !== undefined && subAccount !== null) {
          return RoutePath.Risk.SubAccountPositions.replace(RouteParams.ClientName, clientName)
            .replace(RouteParams.Address, account || "")
            .replace(RouteParams.SubAccount, subAccount.toString());
        }

        return RoutePath.Risk.AccountPositions.replace(RouteParams.ClientName, clientName).replace(
          RouteParams.Address,
          account || "",
        );
      }}
      title={title || "Recent Liquidation Events"}
      description={subtitle}
      emptyState={{ icon: "info", title: "No liquidations" }}
      pageSize={20}
      headers={tableHeader}
      isLoading={!events || loading}
      data={tableData}
    />
  );
};
