import { SeriesData } from "@frontend/ui/chart-widget";
import { useAggOrdersQuery } from "src/pages/ccar-perpetuals-page/generated";

type Series = {
  cumulatedVolume: SeriesData;
  cumulatedFees: SeriesData;
  totalVolume: SeriesData;
  makerFees: SeriesData;
  takerFees: SeriesData;
};
type ReturnType = Series & {
  isLoading: boolean;
};

export const useAggOrdersData = (daysAgo: number): ReturnType => {
  const { data, loading } = useAggOrdersQuery({
    variables: {
      daysAgo,
    },
  });

  const sortedHistoricData = [...(data?.aggOrders?.historicData || [])].sort((a, b) => b.timestamp - a.timestamp);

  const { cumulatedVolume, totalVolume, cumulatedFees, makerFees, takerFees } = sortedHistoricData.reduce(
    (acc, d) => {
      const currCumulatedVolume = acc.prevTotalVolume
        ? acc.prevCumulatedVolume - acc.prevTotalVolume
        : acc.prevCumulatedVolume;

      const currCumulatedFees = acc.prevTotalFees ? acc.prevCumulatedFees - acc.prevTotalFees : acc.prevCumulatedFees;

      const cumulatedVolumePoint = [d.timestamp, currCumulatedVolume] as [number, number];
      const cumulatedFeesPoint = [d.timestamp, currCumulatedFees] as [number, number];

      const newCumulatedVolume = [...acc.cumulatedVolume, cumulatedVolumePoint];
      const newCumulatedFees = [...acc.cumulatedFees, cumulatedFeesPoint];

      const getSeriesData = (field: "totalVolume" | "makerFees" | "takerFees") => {
        const point = [d.timestamp, d[field]] as [number, number];
        return [...acc[field], point];
      };

      const newTotalVolume = getSeriesData("totalVolume");
      const newMakerFees = getSeriesData("makerFees");
      const newTakerFees = getSeriesData("takerFees");

      return {
        cumulatedVolume: newCumulatedVolume,
        cumulatedFees: newCumulatedFees,
        totalVolume: newTotalVolume,
        makerFees: newMakerFees,
        takerFees: newTakerFees,
        prevCumulatedVolume: currCumulatedVolume,
        prevCumulatedFees: currCumulatedFees,
        prevTotalVolume: d.totalVolume,
        prevTotalFees: d.totalFees,
      };
    },
    {
      cumulatedVolume: [],
      cumulatedFees: [],
      totalVolume: [],
      makerFees: [],
      takerFees: [],
      prevCumulatedVolume: data?.aggOrders.volumeToDate || 0,
      prevCumulatedFees: data?.aggOrders.feesToDate || 0,
    } as Series & {
      prevCumulatedVolume: number;
      prevCumulatedFees: number;
      prevTotalVolume?: number;
      prevTotalFees?: number;
    },
  );

  return {
    isLoading: loading,
    cumulatedVolume,
    totalVolume,
    cumulatedFees,
    makerFees,
    takerFees,
  };
};
