import { Box, Typography } from "@frontend/ui";
import { DefaultComponentProps, OverridableTypeMap } from "@mui/material/OverridableComponent";

const FormulaTypography = ({ children, ...restProps }: DefaultComponentProps<OverridableTypeMap>) => (
  <Typography fontFamily="Times New Roman" {...restProps}>
    {children}
  </Typography>
);

const BenqiCalculatorFormula = () => (
  <Box mt={3}>
    <Box fontFamily="Times New Roman !important" fontStyle="italic" display="flex" mb={1} alignItems="center" gap={2}>
      <FormulaTypography fontWeight="bold">Annualized AVAX Returns</FormulaTypography>
      <FormulaTypography>=</FormulaTypography>
      <Box>
        <FormulaTypography borderBottom="1px solid white" textAlign="center" mb={0.5} pb={0.5}>
          Effective Staked QI*
        </FormulaTypography>
        <Box display="flex" alignItems="center" gap={1}>
          <FormulaTypography>Total Staked QI</FormulaTypography>
          <FormulaTypography>+</FormulaTypography>
          <FormulaTypography>Effective Staked QI*</FormulaTypography>
        </Box>
      </Box>
      <FormulaTypography>x</FormulaTypography>
      <FormulaTypography>Total AVAX delegated to QI Stakers**</FormulaTypography>
      <FormulaTypography>x</FormulaTypography>
      <FormulaTypography>Avalanche Staking APR</FormulaTypography>
      <FormulaTypography>x</FormulaTypography>
      <FormulaTypography>Validator Fee Rate</FormulaTypography>
    </Box>
    <Box
      fontFamily="Times New Roman !important"
      fontStyle="italic"
      display="flex"
      mt={4}
      mb={4}
      alignItems="center"
      gap={2}
    >
      <FormulaTypography fontWeight="bold">QI Staking APR</FormulaTypography>
      <FormulaTypography>=</FormulaTypography>
      <Box>
        <Box display="flex" alignItems="center" gap={1} borderBottom="1px solid white" mb={0.5} pb={0.5}>
          <FormulaTypography>Expected Profit</FormulaTypography>
          <FormulaTypography>x</FormulaTypography>
          <FormulaTypography>AVAX Price</FormulaTypography>
        </Box>
        <Box display="flex" alignItems="center" gap={1} width="100%" justifyContent="center">
          <FormulaTypography>Staked QI</FormulaTypography>
          <FormulaTypography>x</FormulaTypography>
          <FormulaTypography>QI Price</FormulaTypography>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default BenqiCalculatorFormula;
