import { Box, Typography } from "@frontend/ui";
import { PoolTokensPair } from "../pool-tokens-pair";

type Props = {
  token1: string;
  token2: string;
};

export const PoolBreadcrumb = ({ token1, token2 }: Props) => (
  <Box display="flex" gap={1}>
    <PoolTokensPair token1={token1} token2={token2} size={24} />
    <Typography variant="caption">{`${token1} / ${token2}`}</Typography>
  </Box>
);
