import { Box, Identicon, Paper, Typography } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { formatAddressCompact, formatAmount } from "@frontend/ui/utils/formatters";
import { getRankColors } from "../utils";

type Props = {
  address?: string;
  addressLabel?: string;
  values?: { label: string; value?: number; color?: string; currency?: string; isPercent?: boolean }[];
  rank?: number;
};

export const AccountCard = ({ address, addressLabel, values, rank }: Props) => (
  <Paper variant="card">
    <Box display="flex" alignItems="center">
      {address ? (
        <>
          <Identicon seed={address} diameter={48} />
          <Box ml={2} display="flex" flexDirection="column">
            <Typography>{addressLabel || formatAddressCompact(address)}</Typography>
            <Typography {...getRankColors(rank || 0)} borderRadius={1} width="fit-content" px={1}>
              Rank {rank}
            </Typography>
          </Box>
        </>
      ) : (
        <ShimmerLoader sx={{ height: "48px" }} />
      )}
    </Box>
    <Box mt={1} gap={1} display="flex">
      {values?.map(({ label, value, color, currency, isPercent }) => (
        <Box key={label} display="flex" flexDirection="column" flex={1}>
          <Typography variant="caption">{label}</Typography>
          {value !== undefined ? (
            <Typography variant="h3" bgcolor={color} borderRadius={1} px={color ? 1 : 0} width="fit-content">
              {formatAmount(value, { currency, isPercent })}
            </Typography>
          ) : (
            <ShimmerLoader sx={{ height: "32px" }} />
          )}
        </Box>
      ))}
    </Box>
  </Paper>
);
