import { useMemo } from "react";
import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { DegenCards, FactionPointsTable, ReferralsTable } from "./components";
import { useDefiAppApiRequest } from "../../api/use-api-request";
import { DefiAppApiEndpoint } from "../../api/types";

const DegenDetails = () => {
  const { degenId } = useParams();
  const { response: degens } = useDefiAppApiRequest(DefiAppApiEndpoint.Degens);

  const idParam = useMemo(
    () => ({
      id: degenId!,
    }),
    [degenId],
  );
  const { response: swapPoints, isLoading: isLoadingSwapPoints } = useDefiAppApiRequest(
    DefiAppApiEndpoint.DegenSwapPoints,
    idParam,
  );
  const { response: referralPoints, isLoading: isLoadingReferralPoints } = useDefiAppApiRequest(
    DefiAppApiEndpoint.DegenReferralPoints,
    idParam,
  );

  const degen = (degens || []).find((d) => d.userId === degenId);
  const totalSwapPoints = swapPoints?.reduce((total, s) => total + s.degenFactionPoints, 0);
  const totalReferralPoints = referralPoints?.reduce((total, r) => total + r.totalPoints, 0);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <DegenCards degen={degen} totalSwapPoints={totalSwapPoints} totalReferralPoints={totalReferralPoints} />
      <FactionPointsTable swapPoints={swapPoints} isLoading={isLoadingSwapPoints} />
      <ReferralsTable referralPoints={referralPoints} isLoading={isLoadingReferralPoints} />
    </Box>
  );
};

export default DegenDetails;
