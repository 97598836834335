import { Header, RenderData } from "@frontend/types";
import { assetCell, ChaosTable, numberCell, percentCell } from "@frontend/ui";
import { Chain, usePoolQuotedAssetsQuery } from "../../../generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Asset",
  },
  {
    renderType: "TEXT",
    text: "Rate",
    prefix: "%",
  },
  {
    renderType: "TEXT",
    text: "Quoted",
  },
  {
    renderType: "TEXT",
    text: "Limit",
  },
  {
    renderType: "TEXT",
    text: "Usage",
  },
];

type Props = {
  chain?: Chain;
  poolAddress: string;
  loadingPool: boolean;
};

const PoolQuotedAssetsTable = ({ chain, poolAddress, loadingPool }: Props) => {
  const { data, loading } = usePoolQuotedAssetsQuery({
    variables: {
      chain: chain!,
      poolAddress,
    },
    skip: !chain,
  });

  const renderedData: RenderData[][] =
    data?.poolQuotedAssets.map((a) => [
      assetCell(a.symbol),
      percentCell(a.rate),
      numberCell(a.totalQuoted),
      numberCell(a.limit),
      {
        renderType: "PROGRESS",
        progressValue: a.limit ? a.totalQuoted / a.limit : 0,
        value: a.limit ? a.totalQuoted / a.limit : 0,
        textPosition: "top",
        width: 150,
      } as RenderData,
    ]) || [];

  return (
    <ChaosTable
      title="Quoted Assets"
      headers={headers}
      data={renderedData}
      pageSize={10}
      isLoading={loadingPool || loading}
      isFullHeight
      initialSortBy={4}
      isInitialSortDesc
    />
  );
};

export default PoolQuotedAssetsTable;
