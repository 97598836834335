import { ValueCardProps } from "@frontend/ui/value-card";
import MultiValueCard from "src/components/multi-values-card/multi-values-card";
import { AssetStatsFragment } from "../../../generated";
import { useClientConfig } from "../../../clients-config";

type Props = {
  marketStats?: AssetStatsFragment;
  isLoading: boolean;
};

const AssetMultiValueStatsCard = ({ marketStats, isLoading }: Props) => {
  const {
    price: currentPrice,
    totalSupply: currentTotalSupply,
    totalBorrow: currentTotalBorrow,
    loanToValue,
  } = marketStats?.current || {};

  const { marketDetails } = useClientConfig();

  const {
    price: previousPrice,
    totalSupply: previousTotalSupply,
    totalBorrow: previousTotalBorrow,
  } = marketStats?.previous || {};

  const multiCardValues: ValueCardProps[] = [
    {
      title: "Price",
      value: currentPrice,
      currency: "USD",
      delta:
        currentPrice !== undefined && previousPrice !== undefined ? Math.abs(currentPrice - previousPrice) : undefined,
      deltaTrend:
        currentPrice !== undefined && previousPrice !== undefined ? Math.sign(currentPrice - previousPrice) : undefined,
      deltaColor:
        currentPrice !== undefined && previousPrice !== undefined ? Math.sign(currentPrice - previousPrice) : undefined,
    },
    {
      title: "Total Supply",
      value: currentTotalSupply,
      currency: "USD",
      delta:
        currentTotalSupply !== undefined && previousTotalSupply !== undefined
          ? Math.abs(currentTotalSupply - previousTotalSupply)
          : undefined,
      deltaTrend:
        currentTotalSupply !== undefined && previousTotalSupply !== undefined
          ? Math.sign(currentTotalSupply - previousTotalSupply)
          : undefined,
      deltaColor:
        currentTotalSupply !== undefined && previousTotalSupply !== undefined
          ? Math.sign(currentTotalSupply - previousTotalSupply)
          : undefined,
    },
    {
      title: "Total Borrow",
      value: currentTotalBorrow,
      currency: "USD",
      delta:
        currentTotalBorrow !== undefined && previousTotalBorrow !== undefined
          ? Math.abs(currentTotalBorrow - previousTotalBorrow)
          : undefined,
      deltaTrend:
        currentTotalBorrow !== undefined && previousTotalBorrow !== undefined
          ? Math.sign(currentTotalBorrow - previousTotalBorrow)
          : undefined,
      deltaColor:
        currentTotalBorrow !== undefined && previousTotalBorrow !== undefined
          ? Math.sign(currentTotalBorrow - previousTotalBorrow)
          : undefined,
    },
    {
      title: marketDetails?.liquidationThresholdLabel || "Collateral Factor",
      value: loanToValue || 0,
      isPercent: true,
    },
  ];

  return <MultiValueCard isLoading={isLoading} values={multiCardValues} />;
};

export default AssetMultiValueStatsCard;
