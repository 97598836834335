import { Box } from "@frontend/ui";
import { ReactNode } from "react";
import { Navigate, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { CCARPerpsTabsLayout } from "src/pages/ccar-perpetuals-page/ccar-perpetuals-tabs-layout";
import MarketDropdownHeader from "./market-dropdown-header";
import GlvDropdownHeader from "./glv-dropdown-header";

export const replaceHomePath = (path: string) => path.replace(RoutePath.CCARPerpetuals.Markets, "");

export const DefaultMarketPageTabRedirect = () => {
  const { clientName, marketId } = useParams();

  return (
    <Navigate
      to={RoutePath.CCARPerpetuals.MarketDetailsOverview.replace(RouteParams.ClientName, clientName!).replace(
        RouteParams.MarketId,
        marketId!,
      )}
      replace
    />
  );
};

export const renderMarketPageLayout = (children: ReactNode) => (
  <CCARPerpsTabsLayout>
    <Box display="flex" flexDirection="column">
      <MarketDropdownHeader />
      {children}
    </Box>
  </CCARPerpsTabsLayout>
);

export const renderGlvPageLayout = (children: ReactNode) => (
  <CCARPerpsTabsLayout>
    <Box display="flex" flexDirection="column">
      <GlvDropdownHeader />
      {children}
    </Box>
  </CCARPerpsTabsLayout>
);
