import { Grid } from "@frontend/ui";
import { getDeltaFields } from "@frontend/ui/trend";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { useOverviewQuery } from "../../../generated";

const OverviewCards = () => {
  const { data } = useOverviewQuery();

  const {
    tvlEth,
    prevTvlEth,
    liquidityPoolTvlEth,
    prevLiquidityPoolTvlEth,
    liquidityVaultTvlUsd,
    prevLiquidityVaultTvlUsd,
    apr,
    prevApr,
    eethPeg,
    prevEethPeg,
    tvrEth,
    prevTvrEth,
  } = data?.overview || {};

  const cards: ValueCardProps[] = [
    {
      title: "Total Value Locked",
      value: tvlEth,
      cryptoCurrency: "ETH",
      valueMaximumFractionDigits: 3,
      ...getDeltaFields(tvlEth, prevTvlEth || undefined),
    },
    {
      title: "APR",
      value: apr,
      ...getDeltaFields(apr, prevApr || undefined),
      isPercent: true,
    },
    {
      title: "Liquidity Pool TVL",
      value: liquidityPoolTvlEth,
      ...getDeltaFields(liquidityPoolTvlEth, prevLiquidityPoolTvlEth || undefined),
      cryptoCurrency: "ETH",
    },
    {
      title: "Liquid Vaults TVL",
      value: liquidityVaultTvlUsd,
      ...getDeltaFields(liquidityVaultTvlUsd, prevLiquidityVaultTvlUsd || undefined),
      currency: "USD",
    },
    {
      title: "eETH-ETH peg",
      value: eethPeg,
      ...getDeltaFields(eethPeg, prevEethPeg || undefined),
      cryptoCurrency: "ETH",
      valueMaximumFractionDigits: 4,
    },
    {
      title: "Total Value Restaked",
      value: tvrEth,
      ...getDeltaFields(tvrEth, prevTvrEth || undefined),
      cryptoCurrency: "ETH",
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OverviewCards;
