import { Accordion, Alert, JsonEditor, Typography } from "@frontend/ui";
import { RJSFSchema } from "@rjsf/utils";
import { FC, useMemo, useState } from "react";
import validator from "@rjsf/validator-ajv8";
import { AccordionDetails, AlertTitle, Stack } from "@mui/material";
import { JsonSchemaInput } from "./json-schema-input";

interface ObjectJsonSchemaProps {
  value: RJSFSchema;
  onChange: (val: RJSFSchema) => void;
  disabled?: boolean;
  title: string;
  description: string;
  height?: number | string;
  width?: number | string;
  onValidate?: (isValid: boolean) => void;
  showWarningMessage?: boolean;
}

interface Argument {
  argument: string;
  type: string;
  title: string;
  description: string;
  isRequired: boolean;
}

const schema: (a: string, b: string) => RJSFSchema = (title: string, description: string) => ({
  $schema: "http://json-schema.org/draft-07/schema#",
  type: "object",
  properties: {
    arguments: {
      type: "array",
      title,
      description,
      items: {
        type: "object",
        properties: {
          argument: {
            type: "string",
            title: "Argument",
            description: "The argument name.",
            pattern: "^[a-zA-Z_][a-zA-Z0-9_]*$",
          },
          type: {
            type: "string",
            title: "Type",
            description: "The type of the argument",
            enum: ["string", "number", "boolean", "array", "object"],
          },
          title: {
            type: "string",
            title: "Title",
            description: "The title for the UI form",
          },
          description: {
            type: "string",
            title: "Description",
            description: "The description for the UI form",
          },
          isRequired: {
            type: "boolean",
            title: "Required",
            description: "Whether the argument is required",
          },
        },
        required: ["argument", "type", "title", "description"],
      },
      default: [],
    },
  },
  required: ["arguments"],
  additionalProperties: false,
});

const schemaForValidation = {
  $schema: "http://json-schema.org/draft-07/schema#",
  type: "object",
  properties: {
    type: {
      type: "string",
      const: "object",
      description: "The schema must be of type 'object'.",
    },
    required: {
      type: "array",
      items: {
        type: "string",
      },
      uniqueItems: true,
      description: "Defines the required properties for the object schema.",
    },
    properties: {
      type: "object",
      description: "Defines the properties for the object schema.",
      propertyNames: {
        pattern: "^[a-zA-Z_][a-zA-Z0-9_]*$",
      },
      additionalProperties: {
        type: "object",
        properties: {
          type: {
            type: "string",
            enum: ["string", "number", "boolean", "array", "object"],
          },
          title: {
            type: "string",
            minLength: 1,
            maxLength: 50,
          },
          description: {
            type: "string",
            minLength: 1,
            maxLength: 100,
          },
          items: {
            type: "object",
            description: "Defines the schema of items in case of array type.",
          },
        },
        required: ["type", "title", "description"],
      },
      default: {}, // Allows "properties" to be an empty object by default
    },
  },
  required: ["type", "properties"],
  additionalProperties: false,
};

const schemaValidator = validator.ajv.compile(schemaForValidation);

export const ObjectJsonSchemaInput: FC<ObjectJsonSchemaProps> = ({
  value,
  onChange,
  disabled,
  title,
  description,
  height,
  width,
  onValidate,
  showWarningMessage,
}) => {
  const [schemaValue, setSchemaValue] = useState<RJSFSchema>(value);
  const [error, setError] = useState<string | undefined>(undefined);

  const validateSchema = (schemaToValidate: RJSFSchema) => {
    if (!schemaToValidate || Object.keys(schemaToValidate).length === 0) {
      setError("Evaluation parameters schema is required");
      return false;
    }

    if (schemaToValidate.type !== "object") {
      setError("Schema type must be object");
      return false;
    }
    // Check if all required arguments are in properties
    if (
      schemaToValidate.required &&
      schemaToValidate.properties &&
      schemaToValidate.required.some((arg) => !Object.keys(schemaToValidate.properties as object).includes(arg))
    ) {
      setError("Required arguments must be in properties");
      return false;
    }

    const isValidSchema = schemaValidator(schemaToValidate);

    if (!isValidSchema) {
      setError("Invalid object schema");
      return false;
    }

    setError(undefined);

    return true;
  };

  const handleSchemaChangeFromEditor = (newValue: object) => {
    const isValid = validateSchema(newValue);
    onValidate?.(isValid);
    if (isValid) {
      setSchemaValue(newValue);
      onChange(newValue);
    }
  };

  const handleSchemaChangeFromInput = (newValue: Argument[][]) => {
    if (!newValue || newValue.length === 0) {
      return;
    }

    const properties = newValue[0].reduce(
      (acc, argumentData) => ({
        ...acc,
        [argumentData.argument]: {
          type: argumentData.type,
          title: argumentData.title,
          description: argumentData.description,
        },
      }),
      {},
    );

    const required = newValue[0].filter((arg) => arg.isRequired).map((arg) => arg.argument);

    const isValid = validateSchema({ type: "object", properties, required });
    onValidate?.(isValid);
    if (isValid) {
      setSchemaValue({ type: "object", properties, required });
      onChange({ type: "object", properties, required });
    }
  };

  const objectSchemaValue = useMemo(() => {
    const { properties, required } = value ?? {};
    return [
      Object.entries(properties ?? {}).map(([key, propVal]) => ({
        argument: key,
        ...(propVal as object),
        isRequired: required?.includes(key) ?? false,
      })),
    ];
  }, [value]) as Argument[][];

  return (
    <Stack spacing={2}>
      {showWarningMessage && (
        <Alert severity="warning" sx={{ marginTop: 2 }}>
          <AlertTitle>Warning</AlertTitle>
          Editing the schema here may cause errors or unexpected behavior in alert processing. Please review changes
          carefully to ensure the arguments are correct, match the logic function's expected arguments, and are in the
          correct order.
        </Alert>
      )}
      <JsonSchemaInput
        value={objectSchemaValue}
        onChange={handleSchemaChangeFromInput}
        schema={schema(title, description)}
      />
      {error && <Typography color="error">{error}</Typography>}
      <Accordion
        header={
          <Typography color="error" variant="h6">
            Experimental Editor
          </Typography>
        }
      >
        <AccordionDetails>
          <Alert severity="error">
            <AlertTitle>Warning</AlertTitle>
            Using this editor to modify the arguments schema can lead to issues. Editing the schema here may result in
            unexpected behavior or errors in the system's processing of arguments. Please proceed with caution and
            ensure that all modifications are thoroughly reviewed to confirm that the arguments are correct, match the
            expected arguments of the logic function, and are in the correct order.
          </Alert>
          <JsonEditor
            value={schemaValue}
            onChange={handleSchemaChangeFromEditor}
            disabled={disabled}
            error={!!error}
            errorMsg={error}
            height={height}
            width={width}
          />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
