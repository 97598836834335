const eventStrategyToDisplayName: Record<string, string> = {
  EethEigenlayer: "eETH (Eigenlayer)",
  WeethsSymbioticVault: "weETHs (Symbiotic)",
  WeethkKarakVault: "weETHk (Karak)",
  LiquidVaultEth: "Liquid Vault (ETH)",
  LiquidVaultUsd: "Liquid Vault (USD)",
  EbtcSymbioticVault: "eBTC (Symbiotic)",
};

export const getEventStrategyDisplayName = (strategy: string) => eventStrategyToDisplayName[strategy] || strategy;
