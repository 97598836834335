import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { ChainDetailsCards } from "./cards";
import TvlChart from "../../components/tvl-chart";
import TopAccountsChart from "../../components/top-accounts-chart";
import { Chain, useChainDetailsQuery } from "../../generated";

const ChainDetails = () => {
  const { chain } = useParams<{ chain: Chain }>();
  const { data } = useChainDetailsQuery({ variables: { chain: chain as Chain } });

  return (
    <Box display="flex" flexDirection="column">
      <ChainDetailsCards data={data?.chainDetails} />
      <TvlChart chain={chain} />
      <TopAccountsChart chain={chain} />
    </Box>
  );
};

export default ChainDetails;
