import { Box, Typography, LinkButton, CryptoIcon } from "@frontend/ui";
import { Methodology } from "src/pages/methodology-page/types";

type Props = {
  methodology: Methodology;
};

const MethodologyIntro = ({ methodology }: Props) => (
  <Box
    p={3}
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    borderRadius={2}
    flexWrap="wrap"
    gap={3}
    bgcolor="darkGrey.main"
  >
    <Box maxWidth={800}>
      <Box display="flex" flexDirection="row" gap={1} paddingBottom={1}>
        {methodology.methodologyIcon && <CryptoIcon icon={methodology.methodologyIcon} />}
        <Typography fontSize={24} fontWeight={700}>
          {methodology.methodologyTitle}
        </Typography>
      </Box>
      <Typography fontSize={14} fontWeight={400}>
        {methodology.methodologyIntro}
      </Typography>
    </Box>
    <LinkButton link={methodology.link} isExternalLink={methodology.isExternalLink}>
      Discover Methodology
    </LinkButton>
  </Box>
);

export default MethodologyIntro;
