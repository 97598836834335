import { FC, ReactElement } from "react";
import { Nullable } from "@frontend/types";
import { Box } from "@frontend/ui/box";
import { ReactComponent as W } from "src/assets/img/equations/w.svg";
import { ReactComponent as Equal } from "src/assets/img/equations/equal.svg";
import { ReactComponent as Fa } from "src/assets/img/equations/fa.svg";
import { ReactComponent as Mult } from "src/assets/img/equations/mult.svg";
import { ReactComponent as R } from "src/assets/img/equations/r.svg";
import { ReactComponent as BigR } from "src/assets/img/equations/big-r.svg";
import { ReactComponent as Sum } from "src/assets/img/equations/sum.svg";
import type { DataTerm } from "./dydx-calculator-page";

interface FormulaProps {
  focusedElement: Nullable<DataTerm>;
}

interface FormulaData {
  name: string;
  element?: ReactElement;
  reference: DataTerm[];
}

const firstFormulaData: FormulaData[] = [
  { name: "w", element: <W />, reference: ["w"] },
  { name: "equal", element: <Equal />, reference: [] },
  { name: "f", element: <Fa />, reference: ["f"] },
];

const secondFormulaData: FormulaData[] = [
  { name: "r", element: <R />, reference: ["r"] },
  { name: "equal", element: <Equal />, reference: [] },
  { name: "R", element: <BigR />, reference: ["R"] },
  { name: "mult", element: <Mult />, reference: [] },
  {
    name: "Sum",
    reference: ["Sum", "w"],
  },
  { name: "comma", element: <span>,</span>, reference: [] },
  {
    name: "n",
    element: <span style={{ padding: "0 8px", borderRadius: "12px" }}>n = 1,2...k</span>,
    reference: [],
  },
];

export const Formula: FC<FormulaProps> = ({ focusedElement }) => {
  const setClassName = (reference: DataTerm[]) => {
    if (typeof reference === "string" && focusedElement === reference) return "activeBG";
    if (focusedElement && reference.includes(focusedElement)) return "activeBG";
    return undefined;
  };

  return (
    <Box
      sx={{
        fontFamily: "Cormorant Upright",
        fontSize: 24,
        "& svg rect": { fill: "none", stroke: "none" },
        "& .activeBG rect": { fill: "white", stroke: "#DF3183" },
        "& span": { border: "1px solid transparent" },
        "& .activeBG span": { background: "rgba(255, 255, 255, 0.1)", borderColor: "#DF3183" },
      }}
    >
      <Box gap={2} mb={5} flexWrap="wrap" display="flex" alignItems="center" justifyContent="center">
        {firstFormulaData.map(({ name, element, reference }, i) => (
          <Box key={`first-${name}-${i}`} display="flex" className={setClassName(reference)}>
            {element}
          </Box>
        ))}
      </Box>

      <Box gap={2} mb={5} flexWrap="wrap" display="flex" alignItems="center" justifyContent="center">
        {secondFormulaData.map(({ name, element, reference }, i) => (
          <Box key={`second-${name}-${i}`}>
            {element && (
              <Box display="flex" className={setClassName(reference)}>
                {element}
              </Box>
            )}

            {name === "Sum" && (
              <Box display="flex" flexDirection="column">
                <Box borderBottom="1px solid white" display="flex" justifyContent="center">
                  <W className={setClassName(["w"])} />
                </Box>
                <Sum className={setClassName(reference)} />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
