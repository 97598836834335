import { groupBy } from "lodash";
import { TraderStatsHistoricData } from "src/pages/ccar-perpetuals-page/generated";

export const getFilledTraderStats = (data: TraderStatsHistoricData[]) => {
  const sortedData = [...data].sort((a, b) => a.timestamp - b.timestamp);
  const groupedDataByMarket = groupBy(sortedData, "market");
  const markets = Object.keys(groupedDataByMarket);

  const filledData: TraderStatsHistoricData[] = [];
  let timestampBatch: TraderStatsHistoricData[] = [];

  sortedData.forEach((point, idx) => {
    timestampBatch.push(point);
    const batchTimestamp = timestampBatch[0]?.timestamp;
    const nextPoint = sortedData[idx + 1];

    if (nextPoint?.timestamp !== batchTimestamp) {
      const missingMarkets = markets.filter((m) => !timestampBatch.some((d) => d.market === m));
      missingMarkets.forEach((m) => {
        const marketData = groupedDataByMarket[m];
        const lastPoint = marketData[marketData.length - 1];
        const value = lastPoint.timestamp < batchTimestamp ? lastPoint.totalPnlUsd : 0;

        const newPoint = {
          ...lastPoint,
          timestamp: batchTimestamp,
          totalPnlUsd: value,
        };

        timestampBatch.push(newPoint);
      });
      filledData.push(...timestampBatch);
      timestampBatch = [];
    }
  });

  return filledData;
};
