import { ReactNode, useRef, useState } from "react";
import { useAuth } from "@frontend/ui/auth";
import { Box, Button, CustomIcon, Typography, Popover } from "@frontend/ui";
import { Avatar, CircularProgress, PopoverOrigin, Stack, Tooltip, TooltipProps } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { Theme, useMediaQuery } from "@frontend/ui/theme";

type UserInfoCardProps = {
  loginTooltipPlacement?: TooltipProps["placement"];
  showAvatarOnly?: boolean;
  children?: ReactNode;
  popoverOrigin?: PopoverOrigin;
};

export const UserInfoCard = ({ children, showAvatarOnly, loginTooltipPlacement, popoverOrigin }: UserInfoCardProps) => {
  const auth = useAuth();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const open = Boolean(anchorEl);
  const id = open ? "login-popover" : undefined;

  const handleLogin = () => {
    void auth.authenticate();
  };

  if (!auth.isAuthenticated) {
    return isMobile || showAvatarOnly ? (
      <Tooltip
        title={
          <Box display="flex" gap={1} alignItems="center">
            <CustomIcon icon="google" sx={{ svg: { width: 16, height: 16 } }} />
            <Typography variant="body1">Login with google</Typography>
          </Box>
        }
        placement={loginTooltipPlacement}
      >
        <Avatar
          onClick={handleLogin}
          sx={{
            cursor: "pointer",
            width: 32,
            height: 32,
          }}
        />
      </Tooltip>
    ) : (
      <Button
        color="inherit"
        onClick={handleLogin}
        startIcon={
          auth.isRefreshing ? (
            <CircularProgress color="inherit" size="14px" />
          ) : (
            <CustomIcon icon="google" sx={{ svg: { width: 16, height: 16 } }} />
          )
        }
        sx={{
          px: 1,
        }}
      >
        {auth.isRefreshing ? <ShimmerLoader sx={{ height: 24, width: 150 }} /> : "Login"}
      </Button>
    );
  }

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    void auth.logout();
    setAnchorEl(null);
  };

  if (auth.isAuthenticated && auth.user) {
    return (
      <Box>
        <Button
          aria-describedby={id}
          onClick={handleToggle}
          ref={anchorRef}
          sx={{
            padding: isMobile || showAvatarOnly ? "0" : "8px",
            minWidth: 0, // override default button 40px width to allow square button
            maxWidth: "140px",
          }}
          color="inherit"
        >
          {auth.user?.picture && <Avatar src={auth.user.picture} sx={{ width: 32, height: 32 }} />}
          {!isMobile && !showAvatarOnly && (
            <Typography
              variant="link"
              sx={{ ml: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {auth.user?.name?.split(" ")[0] ?? auth.user?.email}
            </Typography>
          )}
        </Button>
        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          role={undefined}
          anchorOrigin={
            popoverOrigin ?? {
              vertical: "bottom",
              horizontal: "center",
            }
          }
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            sx: {
              bgcolor: "transparent",
              p: 2,
              boxShadow: 2,
            },
          }}
          disablePortal
        >
          <Stack direction="column" alignItems="center" spacing={2}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
              {auth.user?.picture && <Avatar src={auth.user.picture} sx={{ width: 48, height: 48 }} />}
              {auth.user?.name && (
                <Typography
                  variant="h3"
                  sx={{
                    ml: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {auth.user?.name}
                </Typography>
              )}
              {auth.user?.email && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    ml: 1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {auth.user?.email}
                </Typography>
              )}
            </Box>

            <Stack direction="column" alignItems="center" spacing={1}>
              {children}

              <Button
                color="error"
                size="small"
                variant="link"
                onClick={handleLogout}
                startIcon={<ExitToAppIcon />}
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                }}
              >
                Logout
              </Button>
            </Stack>
          </Stack>
        </Popover>
      </Box>
    );
  }

  return null;
};
