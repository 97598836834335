import { Box, Paper, ValueByCategoryChart, useTheme } from "@frontend/ui";
import { LineChart, LineChartData } from "@frontend/ui/charts";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { dataFetchersURL } from "src/utils/data-fetchers-url";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { AssetTotalValueAtRisk } from "../../../generated";

type Props = {
  totalValueAtRisk?: AssetTotalValueAtRisk;
  asset: string;
};
const MarketTotalValueAtRiskChart = ({ totalValueAtRisk, asset }: Props) => {
  const theme = useTheme();

  return (
    <ValueByCategoryChart
      title="Collateral at Risk"
      description="Value at risk of liquidation in positions with health score approaching 1"
      formatXAxisLabel={(x: string) => formatAmount(Number(x), { currency: "USD", notation: "standard" })}
      series={[
        {
          label: "Value Liquidated (USD)",
          data: [...(totalValueAtRisk?.liquidationUsdValueByPrice || [])]
            .sort((a, b) => a.price - b.price)
            .map((c) => [`${c.price}`, c.liquidationAmount]),
          markLine: {
            symbol: "none",
            data: [{ xAxis: `${totalValueAtRisk?.currentAssetPrice || ""}`, name: "Threshold" }],
            lineStyle: {
              type: "solid",
              color: theme.palette.text.secondary,
              width: 1,
            },
            label: {
              show: true,
              position: "end",
              formatter: `Current ${asset} Price`,
              fontFamily: "Aeonik Fono",
              fontSize: 12,
              color: theme.palette.text.primary,
              lineHeight: 16,
              fontWeight: 500,
            },
          },
        },
      ]}
      isLoading={!totalValueAtRisk}
      tooltipHeaderFormatter={(x) =>
        `${asset} Price: ${formatAmount(Number(x), { currency: "USD", notation: "standard" })}`
      }
    />
  );
};

export default MarketTotalValueAtRiskChart;
