import { ChainAssetFragment, ChainHistoryStatsFragment, ChainResults } from "../../generated";
import { MultiChartData, MultiChartSingleData } from "./types";

const getSingleChartData = (name: string, data: { x: Date; y: number }[]): MultiChartSingleData => ({
  id: name,
  title: name,
  data: data.filter((d) => d.y !== undefined).sort((a, b) => (a.x > b.x ? 1 : -1)),
});

export const getHistoryStatsChartData = (
  historyStats: ChainHistoryStatsFragment,
  chainField?: keyof ChainResults,
  assetField?: keyof ChainAssetFragment,
  isMultiMarket?: boolean,
) =>
  historyStats.data.reduce<MultiChartData>((res, s) => {
    const newRes = { ...res };
    const allAssets = s.result.flatMap((r) => r.assets || []);
    const date = new Date(Number(s.date) * 1000);

    if (chainField) {
      s?.result.forEach((aggChain) => {
        const newPoint = {
          x: date,
          y: Number(aggChain[chainField]),
        };
        const data = [...(newRes[aggChain.chain]?.data || []), newPoint];
        newRes[aggChain.chain] = getSingleChartData(aggChain.chain, data);
      });

      return newRes;
    }

    if (!assetField) return newRes;

    if (!isMultiMarket) {
      allAssets.forEach((asset) => {
        const newPoint = {
          x: date,
          y: Number(asset[assetField]),
        };
        const data = [...(newRes[asset.symbol]?.data || []), newPoint];
        newRes[asset.symbol] = getSingleChartData(asset.symbol, data);
      });

      return newRes;
    }
    const assetsMarkets = allAssets.map((a) => a.marketId || "");
    const markets = Array.from(new Set(assetsMarkets));

    markets.forEach((marketId) => {
      const assets = allAssets.filter((a) => a.marketId === marketId);
      const { marketName } = assets[0] || {};

      if (marketName) {
        const totalAmount = assets.reduce((acc, a) => acc + Number(a[assetField]), 0);

        const newPoint = {
          x: date,
          y: totalAmount,
        };
        const data = [...(newRes[marketName]?.data || []), newPoint];
        newRes[marketName] = getSingleChartData(marketName, data);
      }
    });

    return newRes;
  }, {});
