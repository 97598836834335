import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { OverviewCards } from "./overview-cards";
import { OverviewCharts } from "./overview-charts";

const CCARPerpetualsOverviewTab = () => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
    <PageHeader
      showBackButton={false}
      pageTitle="Overview"
    />
    <OverviewCards />
    <OverviewCharts />
  </Box>
);
export default CCARPerpetualsOverviewTab;
