import { Filter } from "@frontend/ui";
import { useCallback, useEffect, useState } from "react";
import { SortChangeHandler } from "@frontend/ui/hooks/use-pagination";
import {
  SortOrder,
  SortWalletsEligibleForLiquidationBy,
  WalletEligibleForLiquidation,
  useMarketAssetsBreakdownQuery,
  useWalletsEligibleForLiquidationLazyQuery,
} from "../../../../generated";
import { getOptionsFilterValue, getRangeFilterValue, getWalletsFilters } from "./filters";
import { useMarketsContext } from "../../../markets-new/context/markets-context";
import { useClientConfig } from "../../../../clients-config";

type Props = {
  sort?: SortWalletsEligibleForLiquidationBy;
  pageSize?: number;
};

export const useWalletsEligibleForLiquidationData = ({ sort, pageSize = 10 }: Props) => {
  const { markets } = useMarketsContext();
  const [search, setSearch] = useState("");
  const { marketType } = useClientConfig();
  const { data: assetsBreakdownData } = useMarketAssetsBreakdownQuery();
  const [wallets, setWallets] = useState<WalletEligibleForLiquidation[]>([]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [query, setQuery] = useState({
    order: SortOrder.Descending,
    sort: sort || SortWalletsEligibleForLiquidationBy.ValueEligibleForLiquidation,
    skip: 0,
  });
  const [fetchWalletsEligibleForLiquidation, { loading }] = useWalletsEligibleForLiquidationLazyQuery();

  useEffect(() => {
    if (markets?.length && assetsBreakdownData?.assetsBreakdown) {
      const initialFilters = getWalletsFilters({
        markets: markets.map((m) => ({ id: m.id, name: m.name })),
        assets: Array.from(new Set(assetsBreakdownData.assetsBreakdown.map((a) => a.mappedSymbol))),
        clientMarketType: marketType,
      });
      setFilters(initialFilters);
    }
  }, [markets, assetsBreakdownData, marketType]);

  useEffect(() => {
    void (async () => {
      const { data } = await fetchWalletsEligibleForLiquidation({
        variables: {
          query: {
            limit: 100,
            skip: query.skip,
            sort: query.sort,
            order: query.order,
            filter: {
              healthFactor: getRangeFilterValue(filters, SortWalletsEligibleForLiquidationBy.HealthFactor),
              address: search || null,
              borrowAssets: getOptionsFilterValue(filters, SortWalletsEligibleForLiquidationBy.BorrowAssets),
              collateralAssets: getOptionsFilterValue(filters, SortWalletsEligibleForLiquidationBy.CollateralAssets),
              marketId: getOptionsFilterValue(filters, SortWalletsEligibleForLiquidationBy.MarketId),
            },
          },
        },
      });

      if (data?.walletsEligibleForLiquidation) {
        setWallets((p) =>
          query.skip ? [...p, ...data.walletsEligibleForLiquidation] : data.walletsEligibleForLiquidation,
        );
      }
    })();
  }, [fetchWalletsEligibleForLiquidation, filters, query.order, query.skip, query.sort, search]);

  const onPageChange = useCallback(
    (page: number) => {
      if (wallets && pageSize * page === wallets.length) {
        setQuery((q) => ({ ...q, skip: wallets.length }));
      }
    },
    [wallets, pageSize],
  );

  const onFiltersChange = useCallback((updatedFilter: Filter[]) => {
    setFilters([...updatedFilter]);
    setQuery((q) => ({ ...q, skip: 0 }));
  }, []);

  const onSortChange: SortChangeHandler = useCallback((sortBy, orderBy) => {
    setQuery({
      skip: 0,
      sort: sortBy as SortWalletsEligibleForLiquidationBy,
      order: orderBy === 1 ? SortOrder.Ascending : SortOrder.Descending,
    });
  }, []);
  const onSearch = useCallback((text: string) => setSearch(text.toLowerCase()), []);

  return {
    wallets,
    filters,
    loading,
    onPageChange,
    onFiltersChange,
    onSortChange,
    onSearch,
  };
};
