import { Box, ECharts, useMediaQuery, Theme } from "@frontend/ui";
import orderBy from "lodash/orderBy";
import { palette } from "@frontend/ui/theme/palette";
import { formatAxis } from "@frontend/ui/utils/formatters";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { ChartHeader } from "@frontend/ui/echarts/charts/chart-header";
import { ChartWrapper } from "@frontend/ui/echarts/charts/chart-wrapper";
import { BandFragment, BandsDistributionFragment } from "../../generated";
import { liquidationBandsTooltipFormatter } from "./liquidation-bands-tooltip";

type Props = {
  isLoading?: boolean;
  distribution?: BandsDistributionFragment;
  borrowAsset: string;
  collateralAsset: string;
};

const getBandMedianValue = ({ upperBoundPriceUSD, lowerBoundPriceUSD }: BandFragment) =>
  (upperBoundPriceUSD - lowerBoundPriceUSD) / 2 + lowerBoundPriceUSD;

export const LiquidationBandsChart = ({
  isLoading,
  distribution = { currentCollateralPriceUSD: 0, bands: [] },
  borrowAsset,
  collateralAsset,
}: Props) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const bands = orderBy(distribution.bands, "index", "desc");
  const currentPrice = distribution.currentCollateralPriceUSD;
  const currentPriceBand = bands.find(
    (b) => b.lowerBoundPriceUSD <= currentPrice && b.upperBoundPriceUSD >= currentPrice,
  );
  const currentBandIndex = currentPriceBand ? bands.indexOf(currentPriceBand) : -1;

  const bandsWithMedianValue = bands.map((b) => ({
    ...b,
    median: getBandMedianValue(b),
  }));

  const bandsByIndex: Record<string, BandFragment & { median: number }> = Object.fromEntries(
    bandsWithMedianValue.map((b, i) => [i, b]),
  );

  const collateralData = bands.map((b, i) => (currentBandIndex === -1 || i < currentBandIndex ? b.collateralUSD : 0));

  const borrowData = bands.map((b, i) => (i >= currentBandIndex ? b.stableCoin : 0));

  return (
    <ChartWrapper id="liquidation-bands">
      <ChartHeader title="Liquidation Bands" description="Value that will be soft-liquidated at any price point" />
      <Box height={420} position="relative">
        {isLoading ? (
          <ShimmerLoader />
        ) : (
          <ECharts
            option={{
              legend: {
                data: [{ name: collateralAsset }, { name: borrowAsset }],
              },
              dataZoom: {
                type: "slider",
                backgroundColor: "transparent",
                borderColor: "transparent",
                fillerColor: "rgba(0,0,0,0.2)",
                borderRadius: 0,
                right: 5,
                left: 0,
                moveHandleStyle: {
                  borderWidth: 0,
                },
                brushSelect: false,
                labelFormatter: (value: number) => {
                  const band = bandsByIndex[value];
                  return band ? String(band.index) : "";
                },
                dataBackground: {
                  areaStyle: {
                    color: "rgba(255, 255, 255, 0.2)",
                  },
                  lineStyle: {
                    color: "rgba(255, 255, 255, 0.2)",
                  },
                },
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
                formatter: liquidationBandsTooltipFormatter(
                  bandsByIndex,
                  borrowAsset,
                  collateralAsset,
                  currentPrice,
                  currentBandIndex,
                ),
                renderMode: "auto",
                verticalAlign: "middle",
              },
              xAxis: {
                type: "category",
                name: "Band median price (USD)",
                nameLocation: "middle",
                nameGap: 60,
                nameTextStyle: {
                  color: palette.white.main,
                },
                data: bands.map((_, i) => i),
                axisLabel: {
                  formatter: (value: string) => {
                    const band = bandsByIndex[String(value)];
                    return band ? formatAxis(band.median, 2, "USD") : "";
                  },
                  fontSize: 12,
                },
              },
              yAxis: {
                type: "value",
                nameTextStyle: {
                  color: palette.white.main,
                },
                axisLabel: {
                  formatter: (value: number) => formatAxis(value, 2, "USD"),
                  fontSize: 12,
                },
                ...(!isMobile && {
                  name: "Value Liquidated (USD)",
                  nameLocation: "middle",
                  nameGap: 80,
                }),
              },
              series: [
                {
                  name: collateralAsset,
                  data: collateralData,
                  type: "bar",
                  barWidth: 2,
                  barGap: 1,
                  stack: "total",
                  color: palette.aqua.main,
                  itemStyle: {
                    borderRadius: [50, 50, 0, 0],
                  },
                },
                {
                  name: borrowAsset,
                  data: borrowData,
                  type: "bar",
                  barWidth: 2,
                  barGap: 1,
                  stack: "total",
                  color: palette.blue.main,
                  itemStyle: {
                    borderRadius: [50, 50, 0, 0],
                  },
                },
                {
                  data: [],
                  stack: "total",
                  type: "bar",
                  markLine: {
                    type: "category",
                    symbol: "none",
                    label: {
                      show: false,
                    },
                    data: [{ xAxis: currentBandIndex }],
                    lineStyle: {
                      width: 2,
                      type: "solid",
                      color: palette.red.main,
                    },
                  },
                },
              ],
            }}
          />
        )}
      </Box>
    </ChartWrapper>
  );
};
