import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, numberCell, percentCell, textCell } from "@frontend/ui";
import { JlpPoolData } from "../../../generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Token",
  },
  {
    renderType: "TEXT",
    text: "Pool Size (USD)",
  },
  {
    renderType: "TEXT",
    text: "Pool Size (Token)",
  },
  {
    renderType: "TEXT",
    text: "Current Weightage",
  },
  {
    renderType: "TEXT",
    text: "Target Weightage",
  },
  {
    renderType: "TEXT",
    text: "Utilization",
  },
];

type Props = {
  data?: JlpPoolData;
  isLoading: boolean;
};

const LiquidityAllocationTable = ({ data, isLoading }: Props) => {
  const tableData: RenderData[][] = (data?.liquidityAllocations || []).map((allocation) => [
    textCell(allocation.tokenSymbol, undefined, allocation.tokenSymbol),
    currencyCell(allocation.sizeUsd),
    numberCell(allocation.sizeToken),
    percentCell(allocation.currentWeightage),
    percentCell(allocation.targetWeightage),
    percentCell(allocation.utilization),
  ]);

  return (
    <ChaosTable title="Liquidity Allocation" isLoading={isLoading} headers={headers} data={tableData} isFilterHidden />
  );
};

export default LiquidityAllocationTable;
