import { useMemo } from "react";
import { Header, RenderData } from "@frontend/types";
import { assetCell, ChaosTable, currencyCell, dateCell, ExternalLinkCell, textCell } from "@frontend/ui";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { getTxDetailsUrl } from "@frontend/ui/utils/chain-url-mapper";
import { Chain } from "../../../../../ccar-lending-page/generated";
import { EventsTableDataProps, useEventsTableData } from "./use-events-table-data";
import { getEventStrategyDisplayName } from "../../../../utils";
import { SortEventsBy } from "../../../../generated";

type Props = {
  title: string;
  hideStrategyColumn?: boolean;
} & EventsTableDataProps;

const EventsTable = ({ title, hideStrategyColumn, ...tableDataProps }: Props) => {
  const { events, loading, onPageChange, onSortChange, onSearch } = useEventsTableData(tableDataProps);

  const headers: Header[] = useMemo(
    () => [
      {
        renderType: "TEXT",
        text: "Date",
        width: "200px",
        field: SortEventsBy.Timestamp,
      },
      {
        renderType: "TEXT",
        text: "Block Number",
        nonSortable: true,
      },
      ...(hideStrategyColumn
        ? []
        : ([
            {
              renderType: "TEXT",
              text: "Strategy",
              nonSortable: true,
            },
          ] as Header[])),
      {
        renderType: "TEXT",
        text: "Asset",
        nonSortable: true,
      },
      {
        renderType: "TEXT",
        text: "Amount (ETH)",
        field: SortEventsBy.AmountEth,
        sortOpposite: true,
      },
      {
        renderType: "TEXT",
        text: "Amount (Token)",
        nonSortable: true,
      },
      {
        renderType: "TEXT",
        text: "Amount (USD)",
        field: SortEventsBy.AmountUsd,
        sortOpposite: true,
      },
      {
        renderType: "TEXT",
        text: "TX Hash",
        nonSortable: true,
      },
    ],
    [hideStrategyColumn],
  );

  const tableData: RenderData[][] = (events || []).map((e) => [
    dateCell(new Date(e.timestamp)),
    textCell(e.blockNumber.toString(), e.blockNumber),
    ...(hideStrategyColumn ? [] : [e.strategy ? textCell(getEventStrategyDisplayName(e.strategy)) : textCell("-")]),
    assetCell(e.token),
    e.amountEth ? currencyCell(Math.abs(e.amountEth), { cryptoCurrency: "ETH" }) : textCell("-"),
    e.tokenAmount ? currencyCell(Math.abs(e.tokenAmount), { cryptoCurrency: e.token }) : textCell("-"),
    e.amountUsd ? currencyCell(Math.abs(e.amountUsd)) : textCell("-"),
    {
      renderType: "CUSTOM",
      text: formatAddressCompact(e.txHash),
      copyText: e.txHash,
      searchTerm: e.txHash,
      exportText: e.txHash,
      component: (
        <ExternalLinkCell
          text={formatAddressCompact(e.txHash, 4)}
          tooltip={e.txHash}
          href={getTxDetailsUrl(Chain.Ethereum, e.txHash)}
        />
      ),
    },
  ]);

  return (
    <ChaosTable
      title={title}
      initialSortBy={0}
      isInitialSortDesc
      isFullHeight
      showSearch
      pageSize={10}
      headers={headers}
      data={tableData}
      isLoading={loading}
      onSortChange={onSortChange}
      onPageChange={onPageChange}
      onSearch={onSearch}
      serchbarPlaceholder="Tx Hash"
      isFilterHidden
      isSettingsHidden
    />
  );
};

export default EventsTable;
