import { Box, Button, CustomIcon, Theme, useMediaQuery, useTheme } from "@frontend/ui";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import { ReactComponent as ChaosLogo } from "../../assets/img/chaoslabs-colored.svg";
import { UserInfoCard } from "../user-info-card";
import { NavigationTabProps, useTabsNavigations } from "./use-tabs-navigations";

type Props = {
  homePath: string;
  tabs?: NavigationTabProps[];
};

export const SiderNavBar: FC<Props> = ({ homePath, tabs }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery<Theme>((th) => th.breakpoints.down("sm"));
  const { activeTabIdx, handleTabChange } = useTabsNavigations(tabs);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      alignItems="center"
      bgcolor="greys.900"
      width={isMobile ? "60px" : "232px"}
      height="100vh"
    >
      <Box display="flex" flexDirection="column" alignItems="center" flex={1} mt={2}>
        <Link to={homePath} style={{ width: "60%" }}>
          <ChaosLogo color={theme.palette.text.primary} style={{ width: "100%" }} />
        </Link>
        <Stack spacing={2} alignItems="center" justifyContent="center" width="100%" p={2}>
          {tabs?.map((tab, index) => (
            <Button
              key={tab.value}
              fullWidth
              color="primary"
              variant={activeTabIdx === index ? "contained" : "text"}
              onClick={(e) => handleTabChange(e, index)}
              startIcon={tab.icon ? <CustomIcon icon={tab.icon} /> : undefined}
              sx={{
                justifyContent: "flex-start",
                borderRadius: 0,
                width: "100%",
              }}
            >
              {!isMobile ? tab.label : null}
            </Button>
          ))}
        </Stack>
      </Box>

      <Box mb={4}>
        <UserInfoCard />
      </Box>
    </Box>
  );
};
