import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import template from "lodash/template";
import API from "./api";
import { Endpoints, Interpolation, Params, Response } from "./types";

export type ReturnType<T> = {
  isLoading: boolean;
  response?: T;
};

export const useApiRequest = <T extends Endpoints = Endpoints>(
  endpoint: T,
  params?: Params<T>,
  interpolations?: Interpolation<T>,
  shouldSendRequest = true,
): ReturnType<Response<T>> => {
  const [response, setResponse] = useState<Response<T>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void (async () => {
      setIsLoading(true);
      if (shouldSendRequest) {
        const res = await API.get<Response<T>>(
          `${template(endpoint, {
            interpolate: /{([\s\S]+?)}/g,
          })(interpolations)}${!params ? "/" : ""}`,
          {
            params,
            paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "comma" }),
            headers: {
              authkey: localStorage.getItem("authkey") || "",
            },
          },
        );
        setResponse(res.data);
      }
      setIsLoading(false);
    })();
  }, [endpoint, interpolations, params, shouldSendRequest]);

  const returnObj = useMemo(
    () => ({
      response,
      isLoading,
    }),
    [response, isLoading],
  );

  return returnObj;
};
