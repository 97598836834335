import { Controller, useFormContext } from "react-hook-form";
import { Grid } from "@frontend/ui";
import { DurationSelect } from "./duration-select";
import { AlertSettingsForm } from "./types";

interface TimingConfigurationSectionProps {
  submitting: boolean;
}

export const TimingConfigurationSection = ({ submitting }: TimingConfigurationSectionProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AlertSettingsForm>();

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="frequencyInMinutes"
          control={control}
          disabled={submitting}
          rules={{
            required: "Please enter a frequency for the alert",
            min: {
              value: 1,
              message: "Frequency must be at least 1 minute",
            },
          }}
          render={({ field }) => (
            <DurationSelect
              label="Evaluate Frequency"
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
              helperText="How often to evaluate the alert"
              error={!!errors.frequencyInMinutes}
              errorMsg={errors?.frequencyInMinutes?.message as string}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="keepAlertForInMinutes"
          control={control}
          disabled={submitting}
          rules={{
            required: "Please enter a duration for the alert",
            min: {
              value: 1,
              message: "Duration must be at least 1 minute",
            },
          }}
          render={({ field }) => (
            <DurationSelect
              label="Active Time"
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
              helperText="How long to keep the alert active after it has been triggered"
              error={!!errors?.keepAlertForInMinutes}
              errorMsg={errors?.keepAlertForInMinutes?.message as string}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
