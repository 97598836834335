import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "../box";
import { Typography } from "../typography";
import { CopyButton } from "../copy-button";
import { Alert } from "../alert";
import { Link } from "../link";

type Props = {
  text: string;
  href?: string;
};

const CopyableText = ({ text, href }: Props) => {
  const [snackbarText, setSnackbarText] = useState("");

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        {href ? (
          <Link
            component={RouterLink}
            to={href}
            target="_blank"
            color="inherit"
            underline="none"
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="caption">{text}</Typography>
          </Link>
        ) : (
          <Typography variant="caption">{text}</Typography>
        )}
        <CopyButton text={text} onCopy={setSnackbarText} color="almostWhite" />
      </Box>
      <Snackbar open={!!snackbarText} autoHideDuration={2000} onClose={() => setSnackbarText("")}>
        <Alert onClose={() => setSnackbarText("")} severity="info">
          {snackbarText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CopyableText;
