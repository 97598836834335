import Lottie from "lottie-react";
import { Box } from "../box";
import { Typography } from "../typography";
import ChaosLogoData from "./chaos-logo.json";

interface LoaderProps {
  title?: string;
}

export const Loader = (props: LoaderProps): JSX.Element => (
  <Box>
    <Box width={800} height={300} margin="auto" marginBottom={4}>
      <Lottie animationData={ChaosLogoData} loop autoplay renderer="svg" />
    </Box>
    <Typography
      sx={{
        textAlign: "center",
        fontWeight: 600,
        fontSize: "24px",
        color: "light.main",
        lineHeight: "40px",
        "@keyframes visibility": {
          "0%": {
            opacity: 1,
          },
          "65%": {
            opacity: 1,
          },
          "66%": {
            opacity: 0,
          },
          "100%": {
            opacity: 0,
          },
        },

        "@keyframes visibility2": {
          "0%": {
            opacity: 0,
          },
          "21%": {
            opacity: 0,
          },
          "22%": {
            opacity: 1,
          },
          "65%": {
            opacity: 1,
          },
          "66%": {
            opacity: 0,
          },
          "100%": {
            opacity: 0,
          },
        },

        "@keyframes visibility3": {
          "0%": {
            opacity: 0,
          },
          "43%": {
            opacity: 0,
          },
          "44%": {
            opacity: 1,
          },
          "65%": {
            opacity: 1,
          },
          "66%": {
            opacity: 0,
          },
          "100%": {
            opacity: 0,
          },
        },

        ".dot": {
          transition: "opacity 1s",
        },

        ".dot1": {
          animation: "visibility 3s linear infinite",
        },
        ".dot2": {
          animation: "visibility2 3s linear infinite",
        },
        ".dot3": {
          animation: "visibility3 3s linear infinite",
        },
      }}
    >
      {props.title ?? "Loading"}
      <span>
        <span className="dot dot1">.</span>
        <span className="dot dot2">.</span>
        <span className="dot dot3">.</span>
      </span>
    </Typography>
  </Box>
);
