import { useEffect, useState } from "react";
import { Loader } from "@frontend/ui/loader";
import { Box, CryptoIcon, CustomReactSelect, Typography } from "@frontend/ui";
import { WithUnifiedProductRedirect, useFeatureFlag } from "src/utils/feature-flags";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { TabsNavigation } from "src/components/layouts";
import { NavigationTabProps } from "src/components/layouts/tabs-navigation";
import { RouteParams, RoutePath } from "src/config/routes";
import { PageHeader } from "@frontend/ui/page-header";
import { getIconSrc } from "@frontend/ui/crypto-icon/get-icon-src";
import { StablecoinDataProvider, useStablecoinDataContext } from "./provider";
import { AssetPriceLiquidationChart } from "./components/asset-price-liquidation-chart";
import { FacilitatorsTable } from "./components/facilitators-table";
import { StablecoinMintBurnChart } from "./components/mint-burn-chart";
import { CollateralComposition } from "./stablecoin-overview";
import { StablecoinCards } from "./components/cards";
import { useClientConfig } from "../../clients-config";
import { PoolsTab } from "../pools";
import { MarketDetails } from "../markets";
import { MarketCapChart } from "./components/market-cap-chart";
import { TimeSpanPicker } from "../../components/time-span-picker";
import { TopTokenHolders } from "../overview/components/top-holders";
import { Chain, MarketData, useAllMarketsQuery } from "../../generated";
import GhoTotalSupplyChart from "./components/gho-total-supply-chart";
import { GhoVolumePerDexChart } from "./components/gho-volume-per-dex-chart";
import { GhoGsmAvailableLiquidityChart } from "./components/gho-gsm-available-liquidity-chart";

const StablecoinTabOverview = () => {
  const { stableCoin } = useClientConfig();
  const { timeSpan, setTimeSpan } = useStablecoinDataContext();
  const longHistoryEnabled = useFeatureFlag("stablecoin-long-history");

  const charts = stableCoin?.charts.map((c) => {
    switch (c) {
      case "assetPriceLiquidation":
        return <AssetPriceLiquidationChart key={c} />;
      case "collateralComposition":
        return <CollateralComposition key={c} />;
      case "facilitators":
        return <FacilitatorsTable key={c} />;
      case "mintBurn":
        return <StablecoinMintBurnChart key={c} />;
      case "marketCap":
        return <MarketCapChart key={c} />;
      case "topTokenHolders":
        return <TopTokenHolders key={c} />;
      case "ghoTotalSupply":
        return <GhoTotalSupplyChart key={c} />;
      case "ghoVolumePerDex":
        return <GhoVolumePerDexChart key={c} />;
      case "ghoGsmAvailableLiquidity":
        return <GhoGsmAvailableLiquidityChart key={c} />;
      default:
        return null;
    }
  });

  if (!charts?.length) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column">
      {longHistoryEnabled && (stableCoin?.timeSpanOptions === undefined || stableCoin?.timeSpanOptions?.length > 1) && (
        <Box display="flex" justifyContent="flex-end">
          <TimeSpanPicker
            selectedTimeSpan={timeSpan}
            onSelectTimeSpan={setTimeSpan}
            timeSpanOptions={stableCoin?.timeSpanOptions}
          />
        </Box>
      )}
      {stableCoin?.cards && <StablecoinCards />}
      {charts}
    </Box>
  );
};

const getRoutes = (asset: string) => ({
  home: RoutePath.Risk.StablecoinHome(asset),
  overview: RoutePath.Risk.StablecoinOverview(asset),
  risk: RoutePath.Risk.StablecoinRisk(asset),
  pools: RoutePath.Risk.StablecoinPools(asset),
  crossChain: RoutePath.Risk.StablecoinCrossChain(asset),
});

export const StablecoinTab = () => {
  const { pathname } = useLocation();
  const { stableCoin, showChainMarketsBreakdown } = useClientConfig();
  const { data: marketsData } = useAllMarketsQuery();
  const poolsEnabled = useFeatureFlag("stablecoin_pools");
  const { clientName } = useParams<"clientName">();
  const [crossChainMarket, setCrossChainMarket] = useState<MarketData>();

  const stablecoinMarkets = marketsData?.allMarkets.filter(
    (m) =>
      stableCoin &&
      (m.borrowableAssets.includes(stableCoin.asset) || m.collateralableAssets.includes(stableCoin.asset)),
  );

  const crossChainTabMarkets = (stablecoinMarkets || []).filter((m) => m.id !== stableCoin?.marketId);

  const crossChainDropdownOptions = crossChainTabMarkets.map(({ id, name, chain }) => ({
    value: id,
    label: showChainMarketsBreakdown ? `${chain} ${name}` : chain,
    cryptoIcon: getIconSrc(chain) ? chain : undefined,
  }));

  useEffect(() => {
    if (crossChainTabMarkets?.length) {
      setCrossChainMarket(crossChainTabMarkets[0]);
    }
  }, [crossChainTabMarkets]);

  if (!stableCoin) {
    return null;
  }

  const replaceParams = (path: string) => path.replace(RouteParams.ClientName, clientName!);
  const routes = getRoutes(stableCoin.asset);

  const chainsPageTabs: NavigationTabProps[] = [
    { label: "Overview", path: replaceParams(routes.overview) },
    ...(stableCoin?.riskTab?.enabled ? [{ label: "Risk", path: replaceParams(routes.risk) }] : []),
    ...(stableCoin.hasPools && poolsEnabled ? [{ label: "Liquidity", path: replaceParams(routes.pools) }] : []),
    ...(stableCoin.hasCrossChain
      ? [{ label: `Cross-Chain ${stableCoin.asset}`, path: replaceParams(routes.crossChain) }]
      : []),
  ];

  const isCrossChainTab = pathname.includes(replaceParams(routes.crossChain));

  const CrossChainHeader = (
    <Box display="flex" gap={3} alignItems="flex-start">
      <Box display="flex" alignItems="center" gap={2}>
        <CryptoIcon icon={stableCoin.asset} />
        <Typography variant="h3">{stableCoin.asset}</Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <CustomReactSelect
          options={crossChainDropdownOptions}
          value={crossChainDropdownOptions.find((opt) => opt.value === crossChainMarket?.id)}
          onChange={(opt) => {
            const market = crossChainTabMarkets.find((m) => m.id === opt?.value);
            if (!market) return;

            setCrossChainMarket(market);
          }}
          isSearchable={false}
          variant="h3"
        />
      </Box>
    </Box>
  );

  return (
    <WithUnifiedProductRedirect>
      {chainsPageTabs.length > 1 && (
        <PageHeader
          showBackButton={false}
          pageTitle={isCrossChainTab ? CrossChainHeader : stableCoin.asset}
          suffixComponent={chainsPageTabs.length > 1 && <TabsNavigation variant="sub-nav" tabs={chainsPageTabs} />}
        />
      )}
      <Routes>
        <Route
          index
          path={routes.overview.replace(routes.home, "")}
          element={
            <StablecoinDataProvider>
              <StablecoinTabOverview />
            </StablecoinDataProvider>
          }
        />
        {stableCoin?.riskTab?.enabled && (
          <Route
            index
            path={routes.risk.replace(routes.home, "")}
            element={
              <MarketDetails
                chain={stableCoin.chain}
                marketId={stableCoin.marketId ?? stableCoin.chain}
                tokenSymbol={stableCoin.asset}
                hideHeader
              />
            }
          />
        )}
        {poolsEnabled && (
          <Route
            path={`${routes.pools.replace(routes.home, "")}/*`}
            element={<PoolsTab asset={stableCoin.asset} hideHeader />}
          />
        )}
        {stableCoin.hasCrossChain && (
          <Route
            path={`${routes.crossChain.replace(routes.home, "")}/*`}
            element={
              !crossChainMarket ? (
                <Loader />
              ) : (
                <MarketDetails
                  chain={crossChainMarket.chain as Chain}
                  marketId={crossChainMarket.id}
                  tokenSymbol={stableCoin.asset}
                  hideHeader
                />
              )
            }
          />
        )}
        <Route path="*" element={<Navigate to={replaceParams(routes.overview)} replace />} />
      </Routes>
    </WithUnifiedProductRedirect>
  );
};
