import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { Typography, TypographyProps } from "@mui/material";

dayjs.extend(duration);

interface HeartbeatProps extends TypographyProps {
  nextTimestamp: number;
  frequencyInMinutes: number;
}

export const Heartbeat = ({ nextTimestamp, frequencyInMinutes, ...rest }: HeartbeatProps) => {
  const [timeLeft, setTimeLeft] = useState((nextTimestamp - Date.now()) / 1000);

  useEffect(() => {
    const interval = setInterval(() => {
      let left = (nextTimestamp - Date.now()) / 1000;

      while (left < 0) {
        left += 60 * frequencyInMinutes;
      }

      setTimeLeft(left);
    }, 1000);

    return () => clearInterval(interval);
  }, [nextTimestamp, frequencyInMinutes]);

  const durationText = useMemo(
    () => (timeLeft < 0 ? "00:00:00" : dayjs.duration(timeLeft, "seconds").format("HH:mm:ss")),
    [timeLeft],
  );

  return <Typography {...rest}>{durationText}</Typography>;
};
