import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { Chain, useOverviewStatsChainsQuery } from "src/pages/ccar-lending-page/generated";

const getTrendPercent = (current?: number, previous?: number, isAlignedWithTrend = true) => {
  if (!current || !previous) return {};

  const changePercent = (current - previous) / current;
  const delta = Math.abs(changePercent);
  const deltaTrend = Math.sign(changePercent);
  const deltaColor = deltaTrend * (isAlignedWithTrend ? 1 : -1);

  return {
    delta,
    deltaTrend,
    deltaColor,
    isDeltaPercent: true,
  };
};

const Cards = () => {
  const { chain } = useParams<{ chain: Chain }>();
  const { data } = useOverviewStatsChainsQuery();
  const chainData = data?.overviewStatsChains.find(({ current }) => current.chain === chain);

  const cards: ValueCardProps[] = [
    {
      title: "Total Supply",
      value: chainData?.current.totalSupplyUsd,
      currency: "USD",
      ...getTrendPercent(chainData?.current.totalSupplyUsd || 0, chainData?.previous?.totalSupplyUsd || 0),
    },
    {
      title: "Total Borrow",
      value: chainData?.current.totalBorrowUsd,
      currency: "USD",
      ...getTrendPercent(chainData?.current.totalBorrowUsd || 0, chainData?.previous?.totalBorrowUsd || 0),
    },
    {
      title: "Total Collateral at Risk",
      value: chainData?.current.totalValueAvailableForLiquidationUsd,
      currency: "USD",
      ...getTrendPercent(
        chainData?.current.totalValueAvailableForLiquidationUsd || 0,
        chainData?.previous?.totalValueAvailableForLiquidationUsd || 0,
        false,
      ),
    },
    {
      title: "Value at Risk",
      value: chainData?.current.totalValueAtRiskUsd,
      currency: "USD",
      ...getTrendPercent(
        chainData?.current.totalValueAtRiskUsd || 0,
        chainData?.previous?.totalValueAtRiskUsd || 0,
        false,
      ),
    },
  ];

  return (
    <Grid container>
      {cards.map((cardProps) => (
        <Grid key={cardProps.title} item xs={12} sm={6} lg={12 / cards.length}>
          <ValueCard {...cardProps} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
