import { useParams } from "react-router-dom";
import { Chain } from "src/pages/ccar-lending-page/generated";
import MarketsTab from "../../markets/markets";

const AssetsTab = () => {
  const { chain } = useParams<{ chain: Chain }>();
  return <MarketsTab chain={chain} title="Assets" />;
};

export default AssetsTab;
