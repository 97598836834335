import { useMemo } from "react";
import { Box, CustomMultiReactSelect, InputLabel } from "@frontend/ui";
import { marketCapTypeNames, volatilityTypeNames } from "../../types";
import { InputTooltip } from "./input-tooltip";

const getDropdownOptions = (options: readonly string[]) =>
  options.map((option) => ({
    value: option,
    label: option.split(/(?=[A-Z])/).join(" "),
  }));

type Props = {
  setFieldValue: (field: string, value: any) => Promise<any>;
  errors?: { volatility?: string; marketCap?: string };
};

const AssetExposureGenericSelect = ({ setFieldValue, errors }: Props) => {
  const keys = useMemo(
    () => ({
      volatility: "genericAssetsExposure.volatility",
      marketCap: "genericAssetsExposure.marketCap",
    }),
    [],
  );

  return (
    <Box display="flex" flexWrap="wrap" gap={3}>
      <Box flex="1" minWidth="max-content" data-testid="volatility-input">
        <InputLabel>
          Asset Volatility{" "}
          <InputTooltip title="The volatility refers to the degree of fluctuation exhibited by the asset with higher volatility within the pool." />
        </InputLabel>
        <Box>
          <CustomMultiReactSelect
            name="volatility"
            placeholder="Select Volatility"
            hideSelectedOptions={false}
            controlShouldRenderValue
            options={getDropdownOptions(volatilityTypeNames)}
            onChange={(selected) => {
              const newSelectedVolatilityFields = selected.map((v) => v.value);
              void setFieldValue(keys.volatility, newSelectedVolatilityFields);
            }}
            error={!!errors?.volatility}
            helperText={errors?.volatility}
          />
        </Box>
        <Box marginTop={1}>
          <InputLabel>Multi-select available</InputLabel>
        </Box>
      </Box>
      <Box flex="1" minWidth="max-content" data-testid="market-cap-input">
        <InputLabel>
          Market Cap{" "}
          <InputTooltip title="This pertains to the asset with a smaller market capitalization within the pool." />{" "}
        </InputLabel>
        <Box>
          <CustomMultiReactSelect
            name="marketCap"
            placeholder="Select Market Cap"
            hideSelectedOptions={false}
            controlShouldRenderValue
            options={getDropdownOptions(marketCapTypeNames)}
            onChange={(selected) => {
              const newSelectedMarketCapFields = selected.map((m) => m.value);
              void setFieldValue(keys.marketCap, newSelectedMarketCapFields);
            }}
            error={!!errors?.marketCap}
            helperText={errors?.marketCap}
          />
        </Box>
        <Box marginTop={1}>
          <InputLabel>Multi-select available</InputLabel>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetExposureGenericSelect;
