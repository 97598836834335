import { NumberChartProps } from "@frontend/ui/echarts/types";
import { HistoryDataFragment } from "src/pages/ccar-perpetuals-page/generated";

export const getChartPropsFromHistoryData = (data: HistoryDataFragment[] = [], seriesLabel = ""): NumberChartProps => {
  const sorted = [...data].sort((a, b) => a.time - b.time);
  return {
    series: [
      {
        label: seriesLabel,
        data: sorted.map((d) => [d.time, d.value]),
      },
    ],
  };
};
