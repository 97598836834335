import { FC } from "react";
import { PageTracker } from "src/components/page-tracker";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { Error } from "@frontend/ui/error";
import { RouteParams, RoutePath } from "src/config/routes";
import { ChaosApolloProvider } from "src/utils/graphql";
import { FeatureFlagsProvider } from "src/utils/feature-flags";
import { useAuth } from "@frontend/ui/auth";
import { CCARPerpsTabsLayout } from "../ccar-perpetuals-tabs-layout";
import { Client } from "../types";
import { clientsConfig, useClientConfig } from "../clients-config";
import {
  AccountsTab,
  AlertsTab,
  LeaderboardTab,
  LiquidationsTab,
  MarketsTab,
  OverviewTab,
  PositionsTab,
  RiskExplorerTab,
} from "../tabs";
import { AccountRoutes } from "../tabs/accounts/account-details";
import { TopBanner } from "../../community-analytics-page/components/top-banner";
import { JlpPoolTab } from "../tabs/jlp-pool";
import { NotPermittedError } from "../../../components/not-permitted-error";
import { PositionDetails } from "../tabs/positions/pages";

const replaceHomePath = (path: string) => path.replace(RoutePath.Risk.Home, "");

export const RiskMonitoringPerpetuals: FC = PageTracker(() => {
  const { clientName } = useParams<{ clientName: Client }>();
  const { role, overview, markets, alerts, jlpPool, positionDetails } = useClientConfig();
  const getDefaultRoute = () => RoutePath.Risk.Overview;
  const auth = useAuth();

  if (!Object.keys(clientsConfig).includes(clientName!)) {
    return <Error errorTitle="404" errorMessage="Page Not Found" />;
  }

  if (!auth.hasRole(role)) {
    return <NotPermittedError />;
  }

  return (
    <ChaosApolloProvider product="ccar-perpetuals">
      <FeatureFlagsProvider>
        <TopBanner />
        <Routes key={clientName}>
          <Route
            index
            element={<Navigate to={getDefaultRoute().replace(RouteParams.ClientName, clientName!)} replace />}
          />
          {overview && (
            <Route
              path={RoutePath.Risk.Overview.replace(RoutePath.Risk.Home, "")}
              element={
                <CCARPerpsTabsLayout>
                  <OverviewTab />
                </CCARPerpsTabsLayout>
              }
            />
          )}
          {markets && <Route path={`${replaceHomePath(RoutePath.Risk.Markets)}/*`} element={<MarketsTab />} />}
          <Route
            path={RoutePath.Risk.Positions.replace(RoutePath.Risk.Home, "")}
            element={
              <CCARPerpsTabsLayout>
                <PositionsTab />
              </CCARPerpsTabsLayout>
            }
          />
          {positionDetails?.enabled && (
            <Route
              path={RoutePath.Risk.PositionDetails.replace(RoutePath.Risk.Home, "")}
              element={
                <CCARPerpsTabsLayout>
                  <PositionDetails />
                </CCARPerpsTabsLayout>
              }
            />
          )}
          <Route
            path={RoutePath.Risk.Accounts.replace(RoutePath.Risk.Home, "")}
            element={
              <CCARPerpsTabsLayout>
                <AccountsTab />
              </CCARPerpsTabsLayout>
            }
          />
          <Route
            path={`${RoutePath.Risk.SubAccountPositions.replace(RoutePath.Risk.Home, "")}/*`}
            element={
              <CCARPerpsTabsLayout>
                <AccountRoutes />
              </CCARPerpsTabsLayout>
            }
          />
          <Route
            path={`${RoutePath.Risk.AccountPositions.replace(RoutePath.Risk.Home, "")}/*`}
            element={
              <CCARPerpsTabsLayout>
                <AccountRoutes />
              </CCARPerpsTabsLayout>
            }
          />
          <Route
            path={RoutePath.Risk.Liquidations.replace(RoutePath.Risk.Home, "")}
            element={
              <CCARPerpsTabsLayout>
                <LiquidationsTab />
              </CCARPerpsTabsLayout>
            }
          />
          <Route
            path={RoutePath.Risk.RiskExplorer.replace(RoutePath.Risk.Home, "")}
            element={
              <CCARPerpsTabsLayout>
                <RiskExplorerTab />
              </CCARPerpsTabsLayout>
            }
          />
          <Route
            path={RoutePath.Risk.Leaderboard.replace(RoutePath.Risk.Home, "")}
            element={
              <CCARPerpsTabsLayout>
                <LeaderboardTab />
              </CCARPerpsTabsLayout>
            }
          />
          {alerts?.enabled && (
            <Route
              path={RoutePath.Risk.Alerts.replace(RoutePath.Risk.Home, "")}
              element={
                <CCARPerpsTabsLayout>
                  <AlertsTab />
                </CCARPerpsTabsLayout>
              }
            />
          )}
          {jlpPool?.enabled && (
            <Route
              path={RoutePath.Risk.JlpPool.replace(RoutePath.Risk.Home, "")}
              element={
                <CCARPerpsTabsLayout>
                  <JlpPoolTab />
                </CCARPerpsTabsLayout>
              }
            />
          )}
          <Route
            path="*"
            element={<Navigate to={getDefaultRoute().replace(RouteParams.ClientName, clientName!)} replace />}
          />
        </Routes>
      </FeatureFlagsProvider>
    </ChaosApolloProvider>
  );
});
