import { Box } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { useCallback, useEffect, useState } from "react";
import TimeSpanPicker, {
  defaultTimeSpan,
} from "src/pages/ccar-lending-page/components/time-span-picker/time-span-picker";
import { usePoolsHistoryByProtocolLazyQuery } from "src/pages/ccar-lending-page/generated";
import TotalPoolsAmountByProtocolChart from "./total-pools-amount-by-protocol-chart";
import ProtocolPoolsAmountsChart from "./protocol-pools-amounts-chart";
import TotalPoolsAmountProtocolDistribution from "./total-pools-amount-protocol-distribution";

type Props = {
  asset: string;
};
const PoolsByProtocolCharts = ({ asset }: Props) => {
  const [loadPoolsHistoryByProtocol, { loading, data: poolsData }] = usePoolsHistoryByProtocolLazyQuery();

  const [selectedTimeSpan, setSelectedTimeSpan] = useState<TimeSpan>(defaultTimeSpan);

  useEffect(() => {
    void loadPoolsHistoryByProtocol({ variables: { asset, timeSpan: selectedTimeSpan } });
  }, [loadPoolsHistoryByProtocol, selectedTimeSpan, asset]);

  const onSelectTimeSpan = useCallback((timeSpan: TimeSpan) => {
    setSelectedTimeSpan(timeSpan);
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Box marginLeft="auto">
        <TimeSpanPicker selectedTimeSpan={selectedTimeSpan} onSelectTimeSpan={onSelectTimeSpan} />
      </Box>
      <TotalPoolsAmountProtocolDistribution asset={asset} poolsHistoryByProtocol={poolsData?.poolsHistoryByProtocol} />
      <TotalPoolsAmountByProtocolChart
        asset={asset}
        poolsHistoryByProtocol={poolsData?.poolsHistoryByProtocol}
        timeSpan={selectedTimeSpan}
        isLoading={loading}
      />
      <ProtocolPoolsAmountsChart
        asset={asset}
        poolsHistoryByProtocol={poolsData?.poolsHistoryByProtocol}
        timeSpan={selectedTimeSpan}
        isLoading={loading}
      />
    </Box>
  );
};

export default PoolsByProtocolCharts;
