import { useParams } from "react-router-dom";
import { Box, CryptoIcon, Grid, Paper, Typography } from "@frontend/ui";
import { TagChip } from "@frontend/ui/tag-chip";
import {
  Chain,
  useAssetsHistoricalPricesQuery,
  useMarketEModeCategoriesQuery,
} from "src/pages/ccar-lending-page/generated";
import { TopWallets } from "src/pages/ccar-lending-page/components/top-wallets";
import { HistoricalPriceChart, WalletsTable } from "../../../markets/components";
import { useMarketsContext } from "../../context/markets-context";

const EModeDetails = () => {
  const { marketId, eModeId } = useParams<{ marketId: string; eModeId: string }>();
  const { markets } = useMarketsContext();
  const { data } = useMarketEModeCategoriesQuery({ variables: { marketId: marketId! } });
  const eModeCategory = data?.eModeCategories.find((c) => c.categoryId === Number(eModeId));
  const { data: historicalPrices, loading } = useAssetsHistoricalPricesQuery({
    variables: {
      assets:
        eModeCategory?.assetSymbols.map((tokenSymbol) => ({
          tokenSymbol,
          chain: markets.find((market) => market.id === marketId)?.chain as Chain,
        })) || [],
    },
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper variant="card">
          <Typography variant="h2" mb={2}>
            Composition
          </Typography>
          <Box display="flex" gap={1}>
            {eModeCategory?.assetSymbols.map((asset) => (
              <TagChip
                label={asset}
                sx={{ bgcolor: "greys.800", p: 0.5, height: 32 }}
                icon={<CryptoIcon icon={asset.toLowerCase()} />}
              />
            ))}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <HistoricalPriceChart
          isLoading={loading}
          assetHistoricalPrices={historicalPrices?.assetsHistoricalPrices}
          isMultiAsset
        />
      </Grid>
      <Grid item xs={12}>
        <TopWallets marketId={marketId} eModeCategoryId={Number(eModeId)} />
      </Grid>
      <Grid item xs={12}>
        <WalletsTable marketId={marketId} eModeCategoryId={Number(eModeId)} />
      </Grid>
    </Grid>
  );
};

export default EModeDetails;
