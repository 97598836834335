import { Component, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Error } from "../error";

interface Props {
  children: ReactNode;
  hasError: boolean;
  setHasError: (error: boolean) => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Props) {
    const { hasError } = this.props;

    if (!hasError && prevProps.hasError) {
      // eslint-disable-next-line
      this.setState({ hasError: false });
    }
  }

  componentDidCatch() {
    const { setHasError } = this.props;
    setHasError(true);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <Error /> : children;
  }
}

export const ErrorBoundaryComponent = ({ children }: { children: ReactNode }) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();

  useEffect(() => setHasError(false), [location.key]);

  return (
    <ErrorBoundary hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundary>
  );
};
