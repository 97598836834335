import { useParams } from "react-router-dom";
import { useOverviewQuery, useOverviewStatsChainsQuery, OverviewResults, TimeSpan } from "../../generated";
import { clientsConfig } from "../../clients-config";
import { Client } from "../../types";
import { OverviewChartsStats, OverviewStatsData } from "./types";
import { useChartStats } from "./use-chart-stats";

export const useOverviewData = (
  timeSpan?: TimeSpan,
): {
  isLoading: boolean;
  isLoadingCards: boolean;
  isLoadingHistoryStats: boolean;
  cardsStats?: OverviewStatsData;
  chartsStats?: OverviewChartsStats;
  chainsStats?: OverviewResults[];
} => {
  const { clientName } = useParams<{ clientName: Client }>();
  const { overview, stableCoin } = clientsConfig[clientName!];

  const { loading: isLoadingOverviewStats, data: overviewStatsData } = useOverviewQuery({
    variables: {
      asset: stableCoin?.asset || "",
      withAsset: !!stableCoin?.asset.length,
    },
  });
  const { aggrAssetStats, overviewStats } = overviewStatsData || {};
  const cardsStats = overviewStats?.current
    ? {
        current: {
          ...overviewStats.current,
          stablecoinPrice: aggrAssetStats?.current.price || null,
        },
        previous: overviewStats.previous
          ? {
              ...overviewStats.previous,
              stablecoinPrice: aggrAssetStats?.previous?.price || null,
            }
          : undefined,
      }
    : undefined;

  const { loading: isLoadingChainsOverviewStats, data: chainsOverviewStatsResponse } = useOverviewStatsChainsQuery({
    skip: overview?.chainHistoryStats?.disabled,
  });
  const chainsStats = chainsOverviewStatsResponse?.overviewStatsChains;

  const { isLoading: isLoadingChartStats, chartsStats } = useChartStats(timeSpan);

  const isLoading = isLoadingOverviewStats || isLoadingChainsOverviewStats;

  return {
    isLoading,
    isLoadingCards: isLoadingOverviewStats,
    isLoadingHistoryStats: isLoadingChartStats,
    cardsStats,
    chartsStats,
    chainsStats,
  };
};
