import { TimeSpan } from "@frontend/ui/echarts/types";
import { useEethPegHistoryQuery } from "../../../../generated";
import { HistoryChart } from "../../../../components/history-chart";

type Props = {
  timeSpan: TimeSpan;
};

const EethPegChart = ({ timeSpan }: Props) => {
  const { loading, data } = useEethPegHistoryQuery({
    variables: {
      timeSpan,
    },
  });

  return (
    <HistoryChart
      title="Historical Peg Performance"
      label="eETH"
      loading={loading}
      data={data?.eethPegHistory}
      timeSpan={timeSpan}
      cryptoCurrency="ETH"
    />
  );
};

export default EethPegChart;
