import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { AuthCallbackResult, AuthLoginResult, UserInfo } from "./types";

interface UserData {
  roles: string[];
  userInfo: UserInfo;
}

interface AuthContextData {
  isAuthenticated: boolean;
  isRefreshing: boolean;
  authenticate: () => Promise<void>;
  logout: () => void;
  hasRole: (role?: string) => boolean;
  user: UserInfo | null;
}

const AuthContext = createContext<AuthContextData>({
  isAuthenticated: false,
  isRefreshing: true,
  authenticate: () => Promise.resolve(),
  logout: () => {},
  hasRole: () => false,
  user: null,
});

export const useAuth = () => useContext<AuthContextData>(AuthContext);

interface AuthProviderProps {
  authUrl: string;
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, authUrl }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const res = await axios.post<AuthCallbackResult>(
          `${authUrl}/refresh`,
          {},
          {
            withCredentials: true,
          },
        );
        setUserData({
          roles: res.data.roles,
          userInfo: res.data.user_info,
        });
        setIsAuthenticated(true);
      } catch (e) {
        setIsAuthenticated(false);
      }
      setIsRefreshing(false);
    };

    void checkAuth();
  }, [authUrl]);

  const authenticate = useCallback(async () => {
    const res = await axios.post<AuthLoginResult>(`${authUrl}/login`, {
      state: `${window.location.origin}/auth/callback?redirect=${window.location.pathname}`,
    });
    window.location.href = res.data.url;
  }, [authUrl]);

  const logout = useCallback(() => {
    void axios.post<AuthCallbackResult>(
      `${authUrl}/logout`,
      {},
      {
        withCredentials: true,
      },
    );
    setIsAuthenticated(false);
    setUserData(null);
  }, [authUrl]);

  const hasRole = useCallback((role?: string) => (role ? !!userData?.roles.includes(role) : true), [userData]);

  const authProviderValue = useMemo<AuthContextData>(
    () => ({
      isAuthenticated,
      authenticate,
      logout,
      user: userData?.userInfo ?? null,
      hasRole,
      isRefreshing,
    }),
    [isAuthenticated, hasRole, authenticate, logout, userData, isRefreshing],
  );

  return <AuthContext.Provider value={authProviderValue}>{children}</AuthContext.Provider>;
};
