import { CompositionOverTimeChart } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { getChartPropsFromHistoryData } from "src/utils/echarts";
import { palette } from "@frontend/ui/theme/palette";
import { HistoryDataFragment } from "../../../../generated";

type Props = {
  data?: HistoryDataFragment[];
  loading: boolean;
  timeSpan?: TimeSpan;
};

export const TvlChart = ({ data, loading, timeSpan }: Props) => {
  const { series } = getChartPropsFromHistoryData(data, "TVL");
  return (
    <CompositionOverTimeChart
      title="Total Value Locked"
      isLoading={loading}
      series={series.map((s) => ({ ...s, color: palette.aqua.main }))}
      showLegend={false}
      timeSpan={timeSpan}
    />
  );
};
