import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { useMemo } from "react";
import { WalletSupplyAndBorrow, useWalletTotalSupplyAndBorrowQuery } from "../../generated";
import { useClientConfig } from "../../clients-config";
import { useSupplyAndBorrowSnapshot } from "./components/time-machine/use-supply-borrow-snapshot";

dayjs.extend(utc);

const isToday = (date: Dayjs) => dayjs().startOf("day").isSame(date.startOf("day"));

type ReturnType = {
  supplyAndBorrow?: WalletSupplyAndBorrow;
  isLoading: boolean;
};

export const useWalletSupplyAndBorrow = (address: string, timeMachineDate?: Dayjs): ReturnType => {
  const { walletDetails } = useClientConfig();

  const isTimeMachineEnabled = timeMachineDate && !isToday(timeMachineDate);

  const { data: walletTotalSupplyAndBorrow, loading: isLoadingTotalSupplyAndBorrow } =
    useWalletTotalSupplyAndBorrowQuery({
      variables: { address },
      skip: !walletDetails?.tables.includes("totalSupplyAndBorrow") || isTimeMachineEnabled,
    });

  const utcDate = useMemo(() => timeMachineDate?.utc().startOf("day"), [timeMachineDate]);

  const { snapshot: supplyAndBorrowSnapshotData, isLoading: isLoadingSnapshotData } = useSupplyAndBorrowSnapshot({
    address,
    date: utcDate,
    skip: !isTimeMachineEnabled,
  });

  const supplyAndBorrow = isTimeMachineEnabled
    ? supplyAndBorrowSnapshotData
    : (walletTotalSupplyAndBorrow?.walletTotalSupplyAndBorrow as WalletSupplyAndBorrow);
  const isLoading = isLoadingSnapshotData || isLoadingTotalSupplyAndBorrow;

  return {
    isLoading,
    supplyAndBorrow,
  };
};
