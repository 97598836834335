import { Box } from "@frontend/ui";
import { useCallback, useEffect, useState } from "react";
import { TimeSpanPicker } from "../../../components/time-span-picker";
import { TimeSpan, useAggAmmExchangeEventsLazyQuery } from "../../../generated";
import { SoftLiquidationsCards, SoftLiquidationsHistogram } from "./components";
import { defaultTimeSpan } from "../../../components/time-span-picker/time-span-picker";
import { ExchangeEventsTable } from "../../markets-new/tabs/market-amm/components";

const SoftLiquidationsTab = () => {
  const [loadAggAmmExchangeEventsPoints, { loading, data: aggPointsData }] = useAggAmmExchangeEventsLazyQuery();

  const [selectedTimeSpan, setSelectedTimeSpan] = useState<TimeSpan>(defaultTimeSpan);

  useEffect(() => {
    void loadAggAmmExchangeEventsPoints({ variables: { timeSpan: selectedTimeSpan } });
  }, [loadAggAmmExchangeEventsPoints, selectedTimeSpan]);

  const onSelectTimeSpan = useCallback((timeSpan: TimeSpan) => {
    setSelectedTimeSpan(timeSpan);
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="flex-end">
        <TimeSpanPicker selectedTimeSpan={selectedTimeSpan} onSelectTimeSpan={onSelectTimeSpan} />
      </Box>
      <SoftLiquidationsCards aggAmmExchangeEventsPoints={aggPointsData?.aggAmmExchangeEvents} />
      <SoftLiquidationsHistogram
        isLoading={loading}
        aggAmmExchangeEventsPoints={aggPointsData?.aggAmmExchangeEvents || []}
        timeSpan={selectedTimeSpan}
      />
      <ExchangeEventsTable timeSpan={selectedTimeSpan} />
    </Box>
  );
};

export default SoftLiquidationsTab;
