import { FC } from "react";
import { Chip, ChipProps } from "../chip";

export const TagChip: FC<ChipProps> = (props) => (
  <Chip
    {...props}
    sx={{
      height: 24,
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 600,
      ".MuiChip-icon": {
        marginLeft: 0,
      },
      "& .MuiChip-label": {
        display: "inline-block",
        minWidth: 15,
      },
      ".MuiChip-icon + .MuiChip-label": {
        paddingLeft: 0.5,
      },
      borderRadius: "120px",
      backgroundColor: "transparent",
      border: "1px solid",
      borderColor: "greys.700",
      padding: "16px 4px",
      bgColor: "background.default",
      color: props.color,
      ...(props.sx || {}),
    }}
  />
);
