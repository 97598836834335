import { Header, RenderData } from "@frontend/types";
import { ChaosTable, chipCell, numberCell, textCell } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { PageHeader } from "@frontend/ui/page-header";
import { useDefiAppApiRequest } from "../api/use-api-request";
import { DefiAppApiEndpoint } from "../api/types";
import { RouteParams, RoutePath } from "../../../config/routes";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Rank",
  },
  {
    renderType: "TEXT",
    text: "Degen Name",
  },
  {
    renderType: "TEXT",
    text: "Faction Name",
  },
  {
    renderType: "TEXT",
    text: "Total Points",
  },
];

const DegenLeaderboard = () => {
  const { clientName } = useParams();
  const { isLoading, response } = useDefiAppApiRequest(DefiAppApiEndpoint.Degens);

  const data: RenderData[][] = (response || []).map((degen) => [
    numberCell(degen.ranking),
    textCell(degen.username),
    textCell(degen.faction.factionName, undefined, undefined, undefined, undefined, degen.faction.factionImage),
    chipCell(Number(degen.totalPoints)),
  ]);

  const getRowHref = (rowId: number) => {
    const degen = (response || [])[rowId];

    if (!degen) {
      return "";
    }

    return RoutePath.Risk.DegenDetails.replace(RouteParams.DegenId, degen.userId).replace(
      RouteParams.ClientName,
      clientName!,
    );
  };

  return (
    <>
      <PageHeader showBackButton={false} pageTitle="Degen Leaderboard" />
      <ChaosTable
        initialSortBy={0}
        isLoading={isLoading}
        title="Degen Leaderboard"
        headers={headers}
        data={data}
        showRowChevron
        rowHref={getRowHref}
      />
    </>
  );
};

export default DegenLeaderboard;
