import { Box } from "@frontend/ui";
import { useFeatureFlag, WithUnifiedProductRedirect } from "src/utils/feature-flags";
import { useState } from "react";
import { PageHeader } from "@frontend/ui/page-header";
import OverviewCards from "./overview-cards";
import OverviewCharts from "./overview-charts";
import ChainOverviewStatsRowView from "./overview-table";
import { useOverviewData } from "./use-overview-data";
import { useClientConfig } from "../../clients-config";
import { TimeSpan } from "../../generated";
import TimeSpanPicker, { defaultTimeSpan } from "../../components/time-span-picker/time-span-picker";

const OverviewTabInner = () => {
  const { marketType, timeSpanOptions } = useClientConfig();
  const hasLongHistory = useFeatureFlag("long_history");
  const [selectedTimeSpan, setSelectedTimeSpan] = useState<TimeSpan>(defaultTimeSpan);
  const timeSpan = hasLongHistory ? selectedTimeSpan : undefined;
  const { isLoadingCards, isLoadingHistoryStats, cardsStats, chartsStats, chainsStats } = useOverviewData(timeSpan);

  return (
    <Box height="100%">
      <Box display="flex" flexDirection="column">
        <PageHeader showBackButton={false} pageTitle="Overview" />
        <OverviewCards data={cardsStats} chartsStats={chartsStats} isLoading={isLoadingCards} />
        {timeSpan && (
          <Box display="flex" justifyContent="flex-end">
            <TimeSpanPicker
              selectedTimeSpan={timeSpan}
              onSelectTimeSpan={setSelectedTimeSpan}
              timeSpanOptions={timeSpanOptions}
            />
          </Box>
        )}
        <OverviewCharts data={chartsStats} isLoadingHistoryStats={isLoadingHistoryStats} selectedTimeSpan={timeSpan} />
        {chainsStats && marketType === "ChainAssetMarket" && (
          <Box marginTop={3}>
            <ChainOverviewStatsRowView data={chainsStats} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const OverviewTab = () => (
  <WithUnifiedProductRedirect>
    <OverviewTabInner />
  </WithUnifiedProductRedirect>
);

export default OverviewTab;
