import { Box } from "@frontend/ui";
import { Navigate, Route, Routes } from "react-router-dom";
import { OraclePortalLayout } from "./layout";
import { RoutePath } from "../../../config/routes";
import PriceFeedsProvider from "../context/price-feeds-context";
import { PriceFeeds } from "../tabs";
import { PriceFeedDetails } from "../pages";
import { TemporaryUnavailable } from "./temporary-unavailable";
import { AlertsPortalRoutes } from "../../../config/alerts-portal-routes";

export const replaceHomePath = (path: string) => path.replace(RoutePath.OraclePortal.Home, "");

const OraclePortalRoutes = () => (
  <PriceFeedsProvider>
    <Routes>
      <Route index element={<Navigate to={RoutePath.OraclePortal.PriceFeeds} replace />} />
      <Route
        path={replaceHomePath(RoutePath.OraclePortal.PriceFeeds)}
        element={
          <OraclePortalLayout hideTabs>
            <TemporaryUnavailable />
          </OraclePortalLayout>
        }
      />
      <Route path="*" element={<Navigate to={RoutePath.OraclePortal.Home} replace />} />
      {/* <Route */}
      {/*  path={replaceHomePath(RoutePath.OraclePortal.PriceFeeds)} */}
      {/*  element={ */}
      {/*    <OraclePortalLayout> */}
      {/*      <PriceFeeds /> */}
      {/*    </OraclePortalLayout> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={replaceHomePath(RoutePath.OraclePortal.PriceFeedDetails)} */}
      {/*  element={ */}
      {/*    <OraclePortalLayout> */}
      {/*      <PriceFeedDetails /> */}
      {/*    </OraclePortalLayout> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={replaceHomePath(RoutePath.OraclePortal.Providers)} */}
      {/*  element={ */}
      {/*    <OraclePortalLayout> */}
      {/*      <Box>Providers</Box> */}
      {/*    </OraclePortalLayout> */}
      {/*  } */}
      {/* /> */}
      {/* <Route */}
      {/*  path={replaceHomePath(RoutePath.OraclePortal.Research)} */}
      {/*  element={ */}
      {/*    <OraclePortalLayout> */}
      {/*      <Box>Research</Box> */}
      {/*    </OraclePortalLayout> */}
      {/*  } */}
      {/* /> */}
    </Routes>
  </PriceFeedsProvider>
);

export default OraclePortalRoutes;
