import { Paper, ValueOverTimeChart } from "@frontend/ui";
import { getChartPropsFromHistoryData } from "src/utils/echarts";
import { HistoryDataFragment } from "../../../../generated";

type Props = {
  data?: HistoryDataFragment[];
  loading: boolean;
};

export const TradingVolumeChart = ({ data = [], loading }: Props) => {
  const { series } = getChartPropsFromHistoryData(data, "Volume");
  return (
    <Paper variant="widget">
      <ValueOverTimeChart title="Pool Value" isLoading={loading} series={series} />
    </Paper>
  );
};
