import { FC } from "react";
import { ChartData } from "@frontend/types";
import { Grid } from "../grid";
import { Box } from "../box";
import { Typography } from "../typography";
import { PieChart } from "./pie-chart";
import { DataItem } from "./types";

type PieChartContainerProps = Pick<
  ChartData,
  "chartTitle" | "chartSubtitle" | "columnsToFill" | "labelPrefix" | "labelSuffix" | "hideChartLegends"
> & {
  dataPayload: DataItem[];
  pieSize?: number;
};

export const PieChartContainer: FC<PieChartContainerProps> = ({
  chartTitle,
  chartSubtitle,
  dataPayload,
  columnsToFill,
  labelPrefix,
  labelSuffix,
  hideChartLegends,
  pieSize = 400,
}): JSX.Element => (
  <Grid
    item
    xs={12}
    xl={columnsToFill || 6}
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Box data-testid="chart-title" mb={3}>
      <Typography variant="h2">{chartTitle?.value}</Typography>
    </Box>
    <Typography variant="body1" sx={{ color: "#9B9DA1" }} data-testid="chart-subtitle">
      {chartSubtitle?.value}
    </Typography>
    <Box width="100%" flexGrow={1} flex={1}>
      <PieChart
        size={pieSize}
        data={dataPayload}
        labelPrefix={labelPrefix}
        labelSuffix={labelSuffix}
        hideChartLegends={hideChartLegends}
      />
    </Box>
  </Grid>
);
