import { ChaosTable } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { PoolHeaderCell } from "src/components/custom-table-cells";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { Pool } from "src/pages/lp-dashboard-page/generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Pool Name",
    width: "18%",
  },
  {
    renderType: "TEXT",
    text: "Liquidity",
    suffix: "$",
    width: "7%",
  },
  {
    renderType: "TEXT",
    text: "Daily Volume",
    suffix: "$",
    width: "12%",
  },
  {
    renderType: "TEXT",
    text: "Daily Fees\n(90d Avg.)",
    suffix: "$",
    width: "9%",
  },
  {
    renderType: "TEXT",
    text: "APR",
    suffix: "%",
    width: "7%",
  },
  {
    renderType: "TEXT",
    text: "Daily Incentives",
    suffix: "$",
    width: "12%",
  },
  {
    renderType: "TEXT",
    text: "Proposed Incentives",
    suffix: "$",
    width: "15%",
  },
  {
    renderType: "TEXT",
    text: "Projected APR",
    suffix: "%",
  },
];

type Props = {
  pools: Pool[];
};
const PoolsTable = ({ pools }: Props) => {
  const { clientName } = useParams<{ clientName: string }>();

  const data: RenderData[][] = pools.map(
    ({ id, token1, token2, liquidityUSD, volumeUSD24h, feesUSD24h, incentives }) => {
      const currentAprValue = incentives?.currentIncentiveAPRs.fourteenDays;
      const targetAprValue = incentives?.targetIncentiveAPRs.fourteenDays;
      const currentIncentivesOSMOValue = incentives?.currentDailyIncentivesOSMO;
      const targetIncentivesOSMOValue = incentives?.targetDailyIncentivesOSMO;

      return [
        {
          renderType: "CUSTOM",
          text: `${token1.symbol}/${token2.symbol} #${id}`,
          component: <PoolHeaderCell token1={token1.symbol} token2={token2.symbol} poolId={id} />,
          value: Number(id),
          filterByValue: id,
          searchTerm: `#${id} ${token1.symbol}/${token2.symbol}`,
          exportText: `#${id} ${token1.symbol}/${token2.symbol}`,
        },
        {
          renderType: "TEXT",
          text: formatAmount(liquidityUSD, { currency: "USD" }),
          tooltipText: formatAmount(liquidityUSD, { currency: "USD", notation: "standard" }),
          value: liquidityUSD,
        },
        {
          renderType: "TEXT",
          text: formatAmount(volumeUSD24h, { currency: "USD" }),
          tooltipText: formatAmount(volumeUSD24h, { currency: "USD", notation: "standard" }),
          value: volumeUSD24h,
        },
        {
          renderType: "TEXT",
          text: formatAmount(feesUSD24h, { currency: "USD" }),
          tooltipText: formatAmount(feesUSD24h, { currency: "USD", notation: "standard" }),
          value: feesUSD24h,
        },
        {
          renderType: "TEXT",
          text: currentAprValue !== undefined ? formatAmount(currentAprValue, { isPercent: true }) : "-",
          value: currentAprValue !== undefined ? currentAprValue * 100 : 0,
        },
        {
          renderType: "TEXT",
          token1: "osmo",
          text:
            currentIncentivesOSMOValue !== undefined
              ? formatAmount(currentIncentivesOSMOValue, { notation: "standard" })
              : "-",
          value: currentIncentivesOSMOValue,
        },
        {
          renderType: "TEXT",
          token1: "osmo",
          text:
            targetIncentivesOSMOValue !== undefined
              ? formatAmount(targetIncentivesOSMOValue, { notation: "standard" })
              : "-",
          value: targetIncentivesOSMOValue,
        },
        {
          renderType: "TEXT",
          text:
            targetAprValue !== undefined
              ? formatAmount(targetAprValue, { isPercent: true, notation: "standard" })
              : "-",
          value: targetAprValue !== undefined ? targetAprValue * 100 : 0,
        },
      ];
    },
  );

  const handleRowHref = (rowId: number) => {
    const pool = pools[rowId];

    if (!pool || !clientName) return "";

    return RoutePath.LpDashboard.PoolDetails.replace(RouteParams.ClientName, clientName).replace(
      RouteParams.PoolId,
      pool.id.toString(),
    );
  };
  return (
    <ChaosTable
      headers={headers}
      data={data}
      title="All Pools"
      pageSize={15}
      initialSortBy={0}
      showSearch
      searchBy={[0]}
      isSettingsHidden
      rowHref={handleRowHref}
    />
  );
};

export default PoolsTable;
