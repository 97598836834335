import { useEffect, useState } from "react";
import { Box, CustomReactSelect, NotificationBanner, OptionType } from "@frontend/ui";
import { useClientConfig } from "src/pages/ccar-perpetuals-page/clients-config";
import { useParams } from "react-router-dom";
import { useTraderStatsQuery } from "src/pages/ccar-perpetuals-page/generated";
import { CumulativePnlChart } from "../components";
import { Cards, MetricsTable } from "./components";

const AccountAnalysis = () => {
  const { address } = useParams();
  const { accounts } = useClientConfig();
  const account = address?.toLowerCase() || "";

  const [selectedMarketAddress, setSelectedMarketAddress] = useState<string | null>();
  const { loading, data } = useTraderStatsQuery({
    variables: {
      account,
    },
  });

  useEffect(() => {
    setSelectedMarketAddress(data?.traderStats?.[0]?.market);
  }, [data?.traderStats]);

  const options = (data?.traderStats || []).reduce((acc: OptionType[], s) => {
    const asset = s.asset.toUpperCase();

    if (asset === "PORTFOLIO" || !s.market) return acc;

    const newOption: OptionType = {
      label: s.asset.toUpperCase(),
      value: s.market,
      cryptoIcon: s.asset,
    };
    return [...acc, newOption];
  }, []);

  const selectedTraderStats = data?.traderStats.find((t) => t?.market === selectedMarketAddress);
  const selectedNumberOfOperation = selectedTraderStats?.numOperations;
  const showAdvancedMetrics =
    loading || (selectedNumberOfOperation && selectedNumberOfOperation > (accounts?.analysisOperationsThreshold || 0));

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex">
        {(!!options.length || loading) && (
          <CustomReactSelect
            sx={{ maxWidth: 384, flex: 1 }}
            label="Market"
            value={options.find((opt) => opt.value === selectedMarketAddress)}
            options={options}
            onChange={(opt) => {
              setSelectedMarketAddress(!opt ? null : opt.value);
            }}
            isSearchable={false}
            isLoading={loading}
            isDisabled={loading}
            isClearable
            placeholder="Select Market"
          />
        )}
      </Box>
      {!showAdvancedMetrics && (
        <NotificationBanner
          message={`Advanced metrics and PnL graph are only displayed when the number of operations is greater than ${
            accounts?.analysisOperationsThreshold || 0
          }`}
          bgcolor="blue.opacity50"
        />
      )}
      <Cards traderStats={selectedTraderStats} isLoading={loading} />
      {showAdvancedMetrics && (
        <>
          <MetricsTable traderStats={selectedTraderStats} isLoading={loading} />
          <CumulativePnlChart
            account={account}
            marketAddress={selectedMarketAddress || undefined}
            isLoading={loading}
          />
        </>
      )}
    </Box>
  );
};

export default AccountAnalysis;
