import { PageHeader } from "@frontend/ui/page-header";
import { Box, CryptoIcon, Grid, Typography, useTheme } from "@frontend/ui";
import { Fragment, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { CommunityDashboardLayout } from "../../components/layouts";
import {
  displayProtocolTypes,
  labelByProtocolType,
  ProtocolGroupMetadata,
  useProtocolGroups,
} from "../../components/protocol-switcher/protocol-groups";
import { RouteParams } from "../../config/route-params";
import { RoutePath } from "../../config/routes";

const Header = ({ children }: { children: ReactNode }) => (
  <Typography
    variant="h4"
    sx={{
      textTransform: "uppercase",
      textWrap: "nowrap",
    }}
  >
    {children}
  </Typography>
);

const ProtocolView = ({ icon, label, options, protocol }: ProtocolGroupMetadata & { protocol: string }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleClick = () => {
    if (options?.length) {
      setOpen(true);
    } else {
      navigate(RoutePath.Risk.Overview.replace(RouteParams.ClientName, protocol));
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        sx={{
          ...(open && {
            bgcolor: "greys.800",
          }),
          "&:hover": {
            cursor: "pointer",
            bgcolor: "greys.800",
          },
        }}
        py={2}
        gap={2}
        onClick={handleClick}
      >
        <CryptoIcon icon={icon} size="medium" />
        <Header>{label}</Header>
      </Box>
      {options?.length && (
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            p: 0,
          }}
          PaperProps={{
            sx: {
              minWidth: 480,
              padding: 0,
            },
          }}
        >
          <DialogTitle>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={2} width="100%">
              <CryptoIcon icon={icon} size="medium" />
              <Header>{label}</Header>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              padding: 0,
            }}
          >
            <Grid container>
              {options?.map((option, index) => (
                <Grid
                  item
                  key={option.label}
                  borderTop="1px solid"
                  borderRight={{
                    xs: "none",
                    sm: index % 2 === 0 ? `1px solid ${theme.palette.greys["800"]}` : "none",
                  }}
                  borderColor="greys.800"
                  xs={12}
                  sm={6}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        bgcolor: "greys.800",
                      },
                    }}
                    gap={2}
                    onClick={() => {
                      navigate(RoutePath.Risk.Overview.replace(RouteParams.ClientName, option.value));
                      handleClose();
                    }}
                    p={4}
                  >
                    <Header>{option.label}</Header>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export const HomePage = () => {
  const protocols = useProtocolGroups();
  return (
    <CommunityDashboardLayout showClientToolsDropdown={false}>
      <PageHeader pageTitle="Risk Overview" showBackButton={false} />
      <Grid container>
        <Grid item xs={12} bgcolor="greys.900" p={4}>
          <Typography variant="h2">Clients</Typography>
        </Grid>
        {displayProtocolTypes.map((protocolType) => (
          <Fragment key={protocolType}>
            <Grid item xs={12} key={protocolType} bgcolor="greys.800" px={4} py={1}>
              <Header>{labelByProtocolType[protocolType]}</Header>
            </Grid>
            {Object.entries(protocols)
              .filter(([_, p]) => p.type === protocolType)
              .map(([key, protocol]) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={key}
                  borderBottom="1px solid"
                  borderRight="1px solid"
                  borderColor="greys.800"
                >
                  <ProtocolView {...protocol} protocol={key} />
                </Grid>
              ))}
          </Fragment>
        ))}
      </Grid>
    </CommunityDashboardLayout>
  );
};
