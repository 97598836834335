import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { useStablecoinDataContext } from "../../provider";

export const MarketBreakdownChart = () => {
  const { isLoading, history, tokenSymbol } = useStablecoinDataContext();

  const series =
    history && history[history.length - 1]
      ? history[history.length - 1].collateralDistribution.map(({ asset }) => ({
          label: asset,
          data: history.map(
            (h) =>
              [
                h.date * 1000,
                h.collateralDistribution.find((d) => d.asset.toLowerCase() === asset.toLowerCase())?.value || 0,
              ] as [number, number],
          ),
        }))
      : [];

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        isLoading={isLoading}
        title="Collateral Breakdown by Market"
        description={`Total collateral value (in USD) backing ${tokenSymbol}, across all positions, 
         broken down by market.`}
        currency="USD"
        series={series}
      />
    </Paper>
  );
};
