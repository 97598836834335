export const getRankColors = (rank: number) => {
  if (rank === 1) {
    return { bgcolor: "#EFCB4E", color: "#181C1F" };
  }

  if (rank === 2) {
    return { bgcolor: "#B5B7BB", color: "#181C1F" };
  }

  if (rank === 3) {
    return { bgcolor: "#DB955B", color: "#181C1F" };
  }

  if (rank <= 10) {
    return { bgcolor: "rgba(3, 102, 193, 0.50)", color: "#E5E9EB" };
  }

  if (rank <= 100) {
    return { bgcolor: "#1E6778", color: "#E5E9EB" };
  }

  return { bgcolor: "rgba(134, 148, 161, 0.20)", color: "#E5E9EB" };
};
