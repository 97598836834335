import { Header, RenderData } from "@frontend/types";
import { ChaosTable } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { useStablecoinDataContext } from "../../provider";
import { useGhoFacilitatorsQuery } from "../../../../generated";
import { getChainDisplayName } from "../../../alerts/utils";

export const FacilitatorsTable = () => {
  const { loading, data } = useGhoFacilitatorsQuery();
  const { tokenSymbol } = useStablecoinDataContext();

  const headers: Header[] = [
    { text: "Name", renderType: "TEXT" },
    { text: "Chain", renderType: "TEXT" },
    { text: `Minted ${tokenSymbol}`, renderType: "ICONS" },
    { text: `${tokenSymbol} Limit`, renderType: "ICONS" },
    {
      text: "% Minted",
      renderType: "TEXT",
      tooltip: `Sum of actively borrowed ${tokenSymbol} through this facilitator`,
    },
  ];

  const tableData: RenderData[][] = (data?.ghoFacilitators || []).map(
    ({ name, currentLevel, maxCapacity, chain }) =>
      [
        {
          renderType: "TEXT",
          text: name,
        },
        {
          renderType: "TEXT",
          text: getChainDisplayName(chain),
        },
        {
          renderType: "TEXT",
          text: formatAmount(currentLevel, { notation: "standard" }),
          token1: tokenSymbol,
          value: currentLevel,
        },
        {
          renderType: "TEXT",
          text: formatAmount(maxCapacity, { notation: "standard" }),
          token1: tokenSymbol,
          value: maxCapacity,
        },
        {
          renderType: "PROGRESS",
          progressValue: currentLevel / maxCapacity,
          width: 150,
        },
      ] as RenderData[],
  );

  return (
    <ChaosTable
      title="Facilitators"
      data={tableData}
      headers={headers}
      isLoading={loading}
      isFilterHidden
      isSettingsHidden
      isInitialSortDesc
      isInitialSortEnable
      initialSortBy={2}
    />
  );
};
