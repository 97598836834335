import { Header, RenderData } from "@frontend/types";
import { assetCell, ChaosTable, currencyCell, numberCell, percentCell, textCell } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { useGlvsQuery } from "../../../../../generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Market",
  },
  {
    renderType: "TEXT",
    text: "GM Token Price",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "GM Pool Value",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "GLV TVL USD",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "GLV TVL Token",
  },
  {
    renderType: "TEXT",
    text: "GLV TVL Cap",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "Weight",
    suffix: "%",
  },
  {
    renderType: "TEXT",
    text: "Pool Share",
    tooltip: "Shared of pool owned by GLV",
    suffix: "%",
  },
];

const GlvCompositionTable = () => {
  const { address } = useParams();
  const { data: glvsData, loading } = useGlvsQuery();
  const glv = glvsData?.glvs?.find((g) => g.glvAddress === address);

  const data: RenderData[][] = (glv?.markets || []).map((m) => [
    assetCell(m.marketSymbol, `${m.marketSymbol} / USD`),
    currencyCell(m.gmTokenPrice),
    currencyCell(m.gmPoolTotalSupplyUsd),
    currencyCell(m.valueUsd),
    numberCell(m.gmTokenAmount),
    currencyCell(m.capUsd),
    percentCell(m.composition / 100),
    percentCell(m.glvPoolShare / 100),
  ]);

  return <ChaosTable title="GLV Composition" headers={headers} data={data} isLoading={loading} />;
};

export default GlvCompositionTable;
