import { ControlledFilterProps, CustomSwitch, Box, ControlledFilters } from "@frontend/ui";

type PositionsFilterProps = {
  showOpen: boolean;
  onShowOpenChange?: (checked: boolean) => void;
  onFiltersChange: ControlledFilterProps["onChange"];
  filters: ControlledFilterProps["filters"];
};

export const PositionsFilter = ({ showOpen, onShowOpenChange, onFiltersChange, filters }: PositionsFilterProps) => (
  <Box display="flex" alignItems="center" gap={2}>
    {onShowOpenChange && (
      <CustomSwitch
        checked={showOpen}
        onChange={() => onShowOpenChange(!showOpen)}
        checkedLabel="Open Positions"
        uncheckedLabel="Settled Positions"
        testId="position-open"
      />
    )}
    <ControlledFilters filters={filters} onChange={onFiltersChange} />
  </Box>
);
