import { createContext, useContext } from "react";
import { AppColorSchemes } from "../types";

interface AppColorSchemeProps {
  colorScheme: AppColorSchemes;
}

export const AppColorSchemeContext = createContext<AppColorSchemeProps>({
  colorScheme: "light",
});

export const useAppColorScheme = (): AppColorSchemeProps => useContext(AppColorSchemeContext);
