import { AggAmmExchangeEvents, TimeSpan } from "../../../../../generated";
import { LiquidationsHistogram } from "../../../components";
import { getSoftLiquidationsHistogramDataByAggPoints } from "./utils";

type Props = {
  isLoading: boolean;
  timeSpan: TimeSpan;
  aggAmmExchangeEventsPoints: AggAmmExchangeEvents[];
};
const SoftLiquidationsHistogram = ({ isLoading, aggAmmExchangeEventsPoints, timeSpan }: Props) => (
  <LiquidationsHistogram
    isLoading={isLoading}
    data={getSoftLiquidationsHistogramDataByAggPoints(aggAmmExchangeEventsPoints || [])}
    timeSpan={timeSpan}
  />
);

export default SoftLiquidationsHistogram;
