import { Box, CompositionOverTimeChart, Grid, ValueOverTimeChart } from "@frontend/ui";
import { ComponentProps, useState } from "react";
import { useGmPoolsHistoryQuery } from "src/pages/ccar-perpetuals-page/generated";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { useParams } from "react-router-dom";
import { TimeSpanPicker } from "src/pages/ccar-lending-page/components/time-span-picker";
import { palette } from "@frontend/ui/theme/palette";
import { PoolsHistoryTimeSpans, daysAgoByTimeSpan, timeSpans } from "../types";

const WrappedChart = (props: ComponentProps<typeof CompositionOverTimeChart>) => (
  <Box sx={{ flex: 1, display: "flex" }}>
    <Box flex={1} width={0}>
      <CompositionOverTimeChart {...props} />
    </Box>
  </Box>
);

const WrappedValueOverTimeChart = (props: ComponentProps<typeof ValueOverTimeChart>) => (
  <Box sx={{ flex: 1, display: "flex" }}>
    <Box flex={1} width={0}>
      <ValueOverTimeChart {...props} />
    </Box>
  </Box>
);

const PoolHistoryCharts = () => {
  const { marketId } = useParams();
  const [selectedTimeSpan, setSelectedTimeSpan] = useState<PoolsHistoryTimeSpans>(TimeSpan.Month);

  const { data: poolsHistoryData, loading: isLoading } = useGmPoolsHistoryQuery({
    variables: {
      daysAgo: daysAgoByTimeSpan[selectedTimeSpan],
    },
  });

  const data = (poolsHistoryData?.gmPoolsHistory || []).map((h) => {
    const pool = h.data.find((p) => p.marketAddress === marketId!);
    return {
      timestamp: h.timestamp,
      ...pool,
    };
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box alignSelf="flex-end">
        <TimeSpanPicker<PoolsHistoryTimeSpans>
          selectedTimeSpan={selectedTimeSpan}
          onSelectTimeSpan={setSelectedTimeSpan}
          timeSpanOptions={timeSpans}
        />
      </Box>
      <Grid container>
        <Grid item xs={12} md={6}>
          <WrappedValueOverTimeChart
            title="Funding Rates Per 1h"
            series={[
              {
                label: "Long",
                data: data.map((d) => [d.timestamp, (d.fundingRateLong || 0) / 100]),
                type: "line",
              },
              {
                label: "Short",
                data: data.map((d) => [d.timestamp, (d.fundingRateShort || 0) / 100]),
                type: "line",
              },
            ]}
            currency=""
            isPercent
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WrappedChart
            title="Open Interest"
            series={[
              {
                label: "Long",
                data: data.map((d) => [d.timestamp, d.longInterestAmountUsd || 0]),
              },
              {
                label: "Short",
                data: data.map((d) => [d.timestamp, d.shortInterestAmountUsd || 0]),
              },
            ]}
            isLoading={isLoading}
            percentToggle
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WrappedValueOverTimeChart
            title="Borrow Rate Per 1h"
            series={[
              {
                label: "Long",
                data: data.map((d) => [d.timestamp, (d.borrowingRateLong || 0) / 100]),
                type: "line",
              },
              {
                label: "Short",
                data: data.map((d) => [d.timestamp, (d.borrowingRateShort || 0) / 100]),
                type: "line",
              },
            ]}
            currency=""
            isPercent
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WrappedChart
            title="Pool Value"
            showLegend={false}
            series={[
              {
                label: "Pool Value",
                data: data.map((d) => [d.timestamp, d.poolValue || 0]),
                color: palette.aqua.main,
              },
            ]}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PoolHistoryCharts;
