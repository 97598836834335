import React from "react";
import ReactDOM from "react-dom";
import { datadogRum } from "@datadog/browser-rum";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { dataQueryURL } from "./utils/data-query-url";

const isLocal = window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");

if (!isLocal) {
  datadogRum.init({
    applicationId: "8434be23-8e5e-4ac4-85b1-34c4d7ffc666",
    clientToken: "pube34e4b78b82b446dbabe893dcd5f1eac",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    service: "community-analytics",
    env: window.location.hostname.includes("staging") ? "stg" : "prd",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [dataQueryURL()],
    beforeSend: (event) => {
      // discard a RUM error if it's caused by a chrome extension
      if (
        event.type === "error" &&
        (event.error.stack?.includes("chrome-extension") || event.error.message.includes("Loading chunk"))
      ) {
        return false;
      }

      return true;
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
