import { Paper, StackedBarChart } from "@frontend/ui";
import { useGhoDiscountHistogramQuery } from "../../../generated";

export const GhoDiscountHistogramChart = () => {
  const { data, loading } = useGhoDiscountHistogramQuery();

  const chartData: [string, number][] = [...(data?.ghoDiscountHistogram || [])]
    .sort((a, b) => a.bucket.start - b.bucket.start)
    .map((d) => [`${d.bucket.start}% - ${d.bucket.end}%`, d.count]);

  return (
    <Paper variant="card">
      <Paper variant="widget">
        <StackedBarChart
          key="ghoDiscountHistogram-chart"
          showXAxisLine={false}
          title="GHO Discount Percent Distribution"
          isLoading={loading}
          series={[
            {
              label: "Total Tokens",
              data: chartData,
            },
          ]}
          showLegend={false}
          currency=""
        />
      </Paper>
    </Paper>
  );
};
