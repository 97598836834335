import { Box, CustomSwitch, PieChart } from "@frontend/ui";
import { useState } from "react";
import { TraderStats } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  traderStats?: TraderStats[];
  isLoading: boolean;
};
const DistributionCharts = ({ traderStats, isLoading }: Props) => {
  const [showMaxExposure, setShowMaxExposure] = useState(false);

  const getChartData = (key: keyof TraderStats) =>
    (traderStats || []).reduce((acc: { x: string; y: number }[], marketStats) => {
      const asset = marketStats.asset.toUpperCase();
      if (asset === "PORTFOLIO") return acc;
      const newPoint = {
        x: marketStats.asset.toUpperCase(),
        y: Number(marketStats[key]),
      };
      return [...acc, newPoint];
    }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Box width={0} flex={1}>
        <PieChart
          title="Volume Distribution"
          isLoading={isLoading}
          series={[
            {
              data: getChartData("volumeUsd").map((d) => [d.x, d.y]),
            },
          ]}
          currency="USD"
          showLegendCryptoIcon
        />
      </Box>
      <Box width={0} flex={1}>
        <PieChart
          title="Exposure Distribution"
          isLoading={isLoading}
          series={[
            {
              data: getChartData(showMaxExposure ? "maxGrossExposure" : "avgGrossExposure").map((d) => [d.x, d.y]),
            },
          ]}
          currency="USD"
          showLegendCryptoIcon
          headerSuffix={
            <CustomSwitch
              onChange={() => setShowMaxExposure((prev) => !prev)}
              checked={showMaxExposure}
              uncheckedLabel="Avg"
              checkedLabel="Max"
            />
          }
        />
      </Box>
    </Box>
  );
};

export default DistributionCharts;
