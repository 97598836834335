import { toTitleCase } from "@frontend/ui/utils/formatters";
import { TvlElementFragment, TvlElementName } from "src/pages/ccar-perpetuals-page/generated";
import { getChartPropsFromHistoryData } from "src/utils/echarts";

const getLabelByTvlElementName = (name: TvlElementName) => {
  switch (name) {
    case TvlElementName.LpVaultTvl:
      return "LP Vault TVL";

    case TvlElementName.TotalCollateralUsd:
      return "Total Collateral";

    default:
      return toTitleCase(name);
  }
};

export const getTvlCompositionSeriesData = (tvlComposition: TvlElementFragment[]) =>
  tvlComposition
    .map((element) => {
      const label = getLabelByTvlElementName(element.name);
      const { series } = getChartPropsFromHistoryData(element.values, label);

      return series;
    })
    .flat();
