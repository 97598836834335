import { AggAmmExchangeEvents } from "../../../../../generated";
import { LiquidationsHistogramDataType } from "../../../types";

export const getSoftLiquidationsHistogramDataByAggPoints = (
  aggAmmExchangeEventsPoints: AggAmmExchangeEvents[],
): LiquidationsHistogramDataType =>
  aggAmmExchangeEventsPoints.reduce((acc, curr) => {
    const newAcc: LiquidationsHistogramDataType = { ...acc };

    const boughtAmountByAsset: Record<string, number> = {};
    curr.marketsAggExchangeEvents.forEach((market) => {
      market.totalExchangeAmountsByAsset.forEach((asset) => {
        const currentAmount = boughtAmountByAsset[asset.symbol] || 0;
        boughtAmountByAsset[asset.symbol] = currentAmount + asset.amountBoughtUSD;
      });
    });

    Object.entries(boughtAmountByAsset).forEach(([assetSymbol, amountBoughtUSD]) => {
      newAcc[assetSymbol] = [
        ...(newAcc[assetSymbol] || []),
        {
          x: curr.timestamp,
          y: amountBoughtUSD,
        },
      ];
    });
    return newAcc;
  }, {} as LiquidationsHistogramDataType);
