import { Switch, SxProps, Theme, Tooltip } from "@mui/material";
import { ChangeEvent, FC, ReactNode } from "react";
import { Box } from "../box";
import { Typography } from "../typography";
import { CustomIcon } from "../custom-icon";
import { CryptoIcon } from "../crypto-icon";

interface CustomSwitchProps {
  id?: string;
  checked?: boolean;
  value?: string | boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
  testId?: string;
  checkedLabel?: string | ReactNode;
  uncheckedLabel?: string | ReactNode;
  checkedIcon?: string | ReactNode;
  uncheckedIcon?: string | ReactNode;
  checkedCryptoIcon?: string;
  uncheckedCryptoIcon?: string;
  checkedLabelTooltip?: string;
  uncheckedLabelTooltip?: string;
  labelsVariant?: "h6" | "h4";
  disabled?: boolean;
}

export const CustomSwitch: FC<CustomSwitchProps> = ({
  id,
  checked,
  value,
  onChange,
  sx,
  testId,
  checkedLabel,
  uncheckedLabel,
  checkedIcon,
  uncheckedIcon,
  checkedCryptoIcon,
  uncheckedCryptoIcon,
  labelsVariant = "h4",
  checkedLabelTooltip = "",
  uncheckedLabelTooltip = "",
  disabled = false,
}) => (
  <Box
    sx={{
      display: "inline-flex",
      alignItems: "center",
      gap: 2,
      opacity: disabled ? 0.2 : 1,
    }}
  >
    {typeof uncheckedIcon === "string" ? <CustomIcon icon={uncheckedIcon} /> : uncheckedIcon}
    {uncheckedCryptoIcon && <CryptoIcon icon={uncheckedCryptoIcon} />}
    {uncheckedLabel && (
      <Tooltip title={uncheckedLabelTooltip}>
        <Typography variant={labelsVariant}>{uncheckedLabel}</Typography>
      </Tooltip>
    )}
    <Switch
      data-testid={testId}
      checked={checked}
      value={value}
      onChange={onChange}
      disabled={disabled}
      sx={sx}
      id={id}
    />
    {typeof checkedIcon === "string" ? <CustomIcon icon={checkedIcon} /> : checkedIcon}
    {checkedCryptoIcon && <CryptoIcon icon={checkedCryptoIcon} />}
    {checkedLabel && (
      <Tooltip title={checkedLabelTooltip}>
        <Typography variant={labelsVariant}>{checkedLabel}</Typography>
      </Tooltip>
    )}
  </Box>
);
