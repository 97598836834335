import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import TvlChart from "../../components/tvl-chart";
import TopAccountsChart from "../../components/top-accounts-chart";
import { OverviewCards } from "./cards";

const Overview = () => (
  <Box display="flex" flexDirection="column">
    <PageHeader showBackButton={false} pageTitle="Overview" />
    <OverviewCards />
    <TvlChart />
    <TopAccountsChart />
  </Box>
);

export default Overview;
