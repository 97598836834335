import { PieChart } from "@frontend/ui";
import { DataItem } from "@frontend/ui/charts";
import { LiquidationsDataCutType } from "../types";
import { LiquidationsDailyPerAsset } from "../../../generated";

type Props = {
  dataCut: LiquidationsDataCutType;
  liquidations: {
    isLoading: boolean;
    data?: LiquidationsDailyPerAsset[];
  };
};

export const LiquidationsPie = ({ dataCut, liquidations }: Props) => {
  const aggregated: DataItem[] = [];
  (liquidations.data || []).forEach((liq) => {
    const assetKey = liq.asset;

    if (aggregated.find((d) => d.x === assetKey)) {
      const index = aggregated.findIndex((d) => d.x === assetKey);
      aggregated[index] = {
        x: assetKey,
        y: (aggregated[index].y as number) + Number(liq.liquidationAmount),
      };
    } else {
      aggregated.push({
        x: assetKey,
        y: Number(liq.liquidationAmount),
      });
    }
  });

  return (
    <PieChart
      title="Collateral Distribution"
      description={`Amount of collateral liquidated ${
        dataCut.daysAgo ? `in the last ${dataCut.daysAgo} days` : ""
      }, by asset`}
      series={[
        {
          data: aggregated.map((a) => [a.x, a.y] as [string, number]),
        },
      ]}
      isLoading={!liquidations || liquidations.isLoading}
      emptyState={!aggregated.length}
    />
  );
};
