import { Header, RenderData } from "@frontend/types";
import { ChaosTable, Paper } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { PoolHeaderCell } from "src/components/custom-table-cells";
import { WalletPool } from "src/pages/lp-dashboard-page/generated";

type Props = {
  data: WalletPool[];
};

const poolsHeaders: Header[] = [
  {
    renderType: "TEXT",
    text: "Pool Name",
    width: "30%",
  },
  {
    renderType: "TEXT",
    text: "Liquidity Bonded",
    suffix: "$",
  },
  {
    renderType: "TEXT",
    text: "Current APR",
    suffix: "%",
  },
  {
    renderType: "TEXT",
    text: "Projected APR",
    suffix: "%",
  },
  {
    renderType: "TEXT",
    text: "APR Change",
    suffix: "%",
  },
];

const WalletPoolsTable = ({ data }: Props) => {
  const tableData: RenderData[][] = data.map(
    (pool) =>
      [
        {
          renderType: "CUSTOM",
          text: `${pool.token1.symbol}/${pool.token2.symbol}`,
          component: <PoolHeaderCell token1={pool.token1.symbol} token2={pool.token2.symbol} poolId={pool.id} />,
          value: Number(pool.id),
          filterByValue: `${pool.token1.symbol}/${pool.token2.symbol}`,
        },
        {
          renderType: "TEXT",
          text: formatAmount(pool.liquidityBondedUSD, { currency: "USD" }),
          value: pool.liquidityBondedUSD,
          tooltipText: formatAmount(pool.liquidityBondedUSD, { currency: "USD", notation: "standard" }),
        },
        {
          renderType: "TEXT",
          text: formatAmount(pool.incentivesAPR, { isPercent: true }),
          value: pool.incentivesAPR * 100,
        },
        {
          renderType: "TEXT",
          text: formatAmount(pool.projectedIncentivesAPR, { isPercent: true }),
          value: pool.projectedIncentivesAPR * 100,
        },
        {
          renderType: "TEXT",
          text: formatAmount(pool.projectedIncentivesAPR - pool.incentivesAPR, { isPercent: true }),
          value: (pool.projectedIncentivesAPR - pool.incentivesAPR) * 100,
        },
      ] as RenderData[],
  );

  return (
    <Paper
      sx={{
        boxShadow: "none",
        padding: 2,
        borderRadius: 2,
        backgroundColor: "#30343B",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        justifyContent: "space-between",
      }}
    >
      <ChaosTable
        headers={poolsHeaders}
        data={tableData}
        pageSize={15}
        isFullHeight
        isInitialSortEnable
        isInitialSortDesc
        isSettingsHidden
      />
    </Paper>
  );
};

export default WalletPoolsTable;
