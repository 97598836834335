import { Box, Button } from "@frontend/ui";
import { ReactElement, useRef } from "react";
import { toJpeg } from "html-to-image";

interface DownloadableProps<T> {
  children: ReactElement;
  id: string;
  preDownload?: () => T;
  postDownload?: (data: T | undefined) => void;
}

export const Downloadable = <T = any>({ children, id, preDownload, postDownload }: DownloadableProps<T>) => {
  const ref = useRef<HTMLElement | null>(null);

  const downloadImage = (canvas: string) => {
    const link = document.createElement("a");
    link.href = canvas;
    link.download = `${id}.jpeg`;
    link.click();
  };

  const handleImageDownloadClick = () => {
    if (!ref.current) return;

    const data = preDownload?.();
    toJpeg(ref.current, { quality: 1 })
      .then((canvas) => {
        postDownload?.(data);
        downloadImage(canvas);
      })
      .catch(console.error);
  };

  return (
    <>
      <Button onClick={handleImageDownloadClick} sx={{ display: "none" }} id={id}>
        Download Image
      </Button>
      <Box ref={ref}>{children}</Box>
    </>
  );
};
