import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { Chain, useMarketCollateralDistributionOverTimeQuery } from "src/pages/ccar-lending-page/generated";

type Props = {
  chain: Chain;
  tokenSymbol: string;
};

export const MarketDistributionOverTime = ({ chain, tokenSymbol }: Props) => {
  const { data, loading } = useMarketCollateralDistributionOverTimeQuery({
    variables: { chain, tokenSymbol },
  });
  const collateral =
    data?.marketCollateralDistributionOverTime?.map((item) => ({
      asset: item.asset,
      supply: item.collateralHistory,
    })) || [];

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        isLoading={loading}
        title="Backing Collateral Distribution Over Time"
        description="Distribution of  collateral assets in this market over time"
        currency="USD"
        percentToggle
        series={collateral.map((distribution) => ({
          type: "line",
          label: distribution.asset,
          data: distribution.supply.map((b) => [b.time * 1000, b.value]),
        }))}
      />
    </Paper>
  );
};
