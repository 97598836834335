import { Box, Button, CustomIcon, IconButton, Paper, Typography } from "@frontend/ui";
import { Drawer, Switch } from "@mui/material";
import { RJSFSchema } from "@rjsf/utils";
import { ChangeEvent, useState } from "react";
import { NotificationChannel, NotificationConfig } from "../utils";
import { JsonSchemaInput } from "./json-schema-input";

interface NotificationChannelInputProps {
  channel: NotificationChannel | "community";
  value: NotificationConfig | undefined;
  onChange: (value: NotificationConfig) => void;
  disabled?: boolean;
  preActiveChange?: (channel: NotificationChannel | "community", enabled: boolean) => Promise<void>;
}

export const mapChannelToData: Record<
  NotificationChannel | "community",
  {
    icon: string;
    name: string;
    inputSchema?: RJSFSchema;
    description?: string;
  }
> = {
  [NotificationChannel.TELEGRAM]: {
    icon: "telegram-color",
    name: "Telegram",
    inputSchema: {
      type: "object",
      properties: {
        overrideChannelIds: {
          type: "array",
          items: {
            type: "string",
          },
          title: "Telegram Channel Ids",
          description: "Override the default channel ids for the alert",
        },
      },
    },
  },
  [NotificationChannel.SLACK]: {
    icon: "slack",
    name: "Slack",
    inputSchema: {
      type: "object",
      properties: {
        overrideChannelIds: {
          type: "array",
          items: {
            type: "string",
          },
          title: "Slack Channel Ids",
          description: "Override the default channel ids for the alert",
        },
      },
    },
  },
  [NotificationChannel.DISCORD]: {
    icon: "discord",
    name: "Discord",
    inputSchema: {
      type: "object",
      properties: {
        overrideChannelIds: {
          type: "array",
          items: {
            type: "string",
          },
          title: "Discord Channel Ids",
          description: "Override the default channel ids for the alert",
        },
      },
    },
  },
  [NotificationChannel.JIRA]: {
    icon: "jira",
    name: "Jira",
    description: "Create a Jira ticket for each triggered alert (Only for actionable alerts)",
  },
  [NotificationChannel.SNS]: {
    icon: "aws-sns",
    name: "Amazon SNS",
  },
  [NotificationChannel.WEBHOOKS]: {
    icon: "webhooks",
    name: "Webhooks",
  },
  community: {
    icon: "Chaos",
    name: "Community Analytics",
    description: "Restrict the ability to set the alert as private",
  },
};

export const NotificationChannelInput = ({
  channel,
  value,
  onChange,
  disabled,
  preActiveChange,
}: NotificationChannelInputProps) => {
  const [openNotificationConfig, setOpenNotificationConfig] = useState(false);

  const [panelChanges, setPanelChanges] = useState<NotificationConfig>(value ?? { enabled: true });

  const handleConfirm = () => {
    onChange(panelChanges);
    setOpenNotificationConfig(false);
  };

  const handleActiveChange = async (e: ChangeEvent<HTMLInputElement>) => {
    void preActiveChange?.(channel, e.target.checked);
    onChange({ ...value, enabled: e.target.checked });
  };

  return (
    <Paper variant="widget">
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          justifyItems="center"
          width="100%"
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <CustomIcon icon={mapChannelToData[channel].icon} />
            <Typography variant="h4">{mapChannelToData[channel].name}</Typography>
          </Box>

          <Switch checked={value?.enabled ?? false} onChange={handleActiveChange} disabled={disabled} size="small" />
        </Box>
        {mapChannelToData[channel].description && (
          <Typography variant="subtitle1">{mapChannelToData[channel].description}</Typography>
        )}
        {mapChannelToData[channel].inputSchema && (
          <Typography
            variant={disabled ? "label" : "link"}
            onClick={() => setOpenNotificationConfig(!disabled)}
            sx={{
              textTransform: "none",
              ...(!disabled && {
                color: "primary.main",
              }),
            }}
          >
            Update
          </Typography>
        )}
      </Box>

      <Drawer anchor="right" open={openNotificationConfig} onClose={() => setOpenNotificationConfig(false)}>
        <Box height="100%" p={4}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={4}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <CustomIcon icon={mapChannelToData[channel].icon} />
              <Typography variant="h2" ml={1}>
                {mapChannelToData[channel].name}
              </Typography>
            </Box>
            <IconButton onClick={() => setOpenNotificationConfig(false)}>
              <CustomIcon icon="close" />
            </IconButton>
          </Box>
          <Box>
            {mapChannelToData[channel].inputSchema && (
              <JsonSchemaInput
                value={value?.inputs ?? []}
                schema={mapChannelToData[channel].inputSchema!}
                disabled={disabled}
                onChange={(newInputs) => setPanelChanges({ ...value, inputs: newInputs, enabled: true })}
              />
            )}
          </Box>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Box>
      </Drawer>
    </Paper>
  );
};
