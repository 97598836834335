import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { Loader } from "@frontend/ui/loader";
import { useAuth } from "@frontend/ui/auth";
import { Error } from "@frontend/ui/error";
import { Box, Button, CustomIcon, Typography } from "@frontend/ui";
import { CommunityDashboardLayout } from "../../components/layouts";

export const AuthCallbackPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    async function callback() {
      if (searchParams.has("success") && searchParams.get("success") === "true") {
        navigate(searchParams.get("redirect") as string);
      }
    }
    void callback();
  }, [auth, searchParams, navigate]);

  return (
    <CommunityDashboardLayout showClientToolsDropdown={false} hideLoginButton>
      {!searchParams.has("error") && <Loader title="Authenticating" />}
      {searchParams.has("error") && (
        <Error
          errorTitle="Authentication failed"
          errorMessage={
            <Box>
              <Typography>An error occurred while authenticating with Google, please try again later.</Typography>
              {searchParams.get("error") && (
                <Typography variant="subtitle2" color="error">
                  Error: {searchParams.get("error")}
                </Typography>
              )}
              {searchParams.get("redirect") && (
                <Button
                  href={searchParams.get("redirect") as string}
                  startIcon={<CustomIcon icon="backward" sx={{ svg: { width: 16, height: 16 } }} />}
                >
                  Go back
                </Button>
              )}
            </Box>
          }
        />
      )}
    </CommunityDashboardLayout>
  );
};
