import { Box, Typography, CustomSwitch, PieChart } from "@frontend/ui";
import { useEffect, useState } from "react";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { AssetDistributionResult } from "../../../generated";

type Props = {
  asset?: string;
  assetsDistribution?: AssetDistributionResult;
  isLoading?: boolean;
};

enum DistributionType {
  Borrow = "BORROW",
  Collateral = "COLLATERAL",
}

const MarketAssetDistribution = ({ asset, assetsDistribution, isLoading }: Props) => {
  const hasSupplyDistribution = assetsDistribution?.supplied.some((d) => d.value);
  const hasBorrowDistribution = assetsDistribution?.borrowed.some((d) => d.value);
  const [field, setField] = useState<DistributionType>(
    hasSupplyDistribution ? DistributionType.Collateral : DistributionType.Borrow,
  );

  useEffect(() => {
    setField(hasSupplyDistribution ? DistributionType.Collateral : DistributionType.Borrow);
  }, [hasSupplyDistribution]);

  const { clientName, marketId } = useParams();

  const toggleDistribution = () => {
    setField(field === DistributionType.Collateral ? DistributionType.Borrow : DistributionType.Collateral);
  };
  const checked = field === DistributionType.Collateral;
  const showToggle = hasSupplyDistribution && hasBorrowDistribution;
  const data = (checked ? assetsDistribution?.supplied || [] : assetsDistribution?.borrowed || []).sort(
    (itemA, itemB) => itemB.value - itemA.value,
  );
  const totalValue = data.reduce((partialSum, item) => partialSum + item.value, 0);
  const totalEModeValue = data.reduce(
    (sum, doc) => sum + (doc.eModeValues?.reduce((emodeSum, emode) => emodeSum + emode.value, 0) ?? 0),
    0,
  );
  const onClickChartItem = (assetSymbol: string) => {
    const assetPath = RoutePath.Risk.MarketAssetOverview.replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.MarketId, marketId!)
      .replace(RouteParams.Asset, assetSymbol);
    return window.open(assetPath);
  };

  return (
    <PieChart
      title={`${field === DistributionType.Collateral ? "Collateral" : "Borrow"} Distribution`}
      description={
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h5">
            Distribution of {field === DistributionType.Collateral ? "collateral" : "borrowed"} assets against{" "}
            {asset || ""}, in USD
          </Typography>
          <Box display="flex" gap={3}>
            <Box>
              <Typography variant="label">Total</Typography>
              <Typography variant="h5" fontWeight={600}>
                {formatAmount(totalValue, { currency: "usd" })}
              </Typography>
            </Box>
            {!!totalEModeValue && (
              <Box>
                <Typography variant="label">Total E-Mode</Typography>
                <Typography variant="h5" fontWeight={600}>
                  {formatAmount(totalEModeValue, { currency: "usd" })}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      }
      series={[
        {
          data: data.map((item) => [item.id, item.value] as [string, number]),
        },
      ]}
      showLegendCryptoIcon
      headerSuffix={
        showToggle ? (
          <CustomSwitch
            id="borrowed-collateral-switch"
            uncheckedLabel="Borrowed Against"
            checkedLabel="Collateral Against"
            onChange={toggleDistribution}
            checked={checked}
          />
        ) : undefined
      }
      getLegendItemSubtitle={(id: string) => {
        const item = data.find((d) => d.id === id);

        if (!item?.eModeValues || item.eModeValues.length === 0) return undefined;

        return (
          <Box>
            {item.eModeValues.map((emode) => (
              <Box display="flex" alignItems="center" gap={1} key={emode.eModeCategoryLabel}>
                <Typography variant="subtitle1">{emode.eModeCategoryLabel}:</Typography>
                <Typography variant="h4">{formatAmount(Number(emode.value), { currency: "usd" })}</Typography>
              </Box>
            ))}
          </Box>
        );
      }}
      onClickLegendItem={onClickChartItem}
      isLoading={isLoading}
    />
  );
};

export default MarketAssetDistribution;
