import { TimeSpan } from "@frontend/ui/echarts/types";
import dayjs from "dayjs";
import { useTradingOverviewQuery } from "../../../../generated";
import { OpenInterestChartWidget } from "./open-interest-chart";

type Props = {
  timestamp: number;
};

const OpenInterestModal = ({ timestamp }: Props) => {
  const { data, loading } = useTradingOverviewQuery({
    variables: {
      daysAgo: 1,
      hourlySupport: true,
      toTimestamp: dayjs(timestamp).endOf("day").valueOf(),
      fromTimestamp: dayjs(timestamp).startOf("day").valueOf(),
    },
  });
  return (
    <OpenInterestChartWidget
      title={`Hourly Open Interest Distribution for ${dayjs(timestamp).format("MMM D, YYYY")}`}
      shortInterestUsd={data?.statsOverTime?.shortInterestUsd}
      longInterestUsd={data?.statsOverTime?.longInterestUsd}
      loading={loading}
      timeSpan={TimeSpan.Day}
    />
  );
};

export default OpenInterestModal;
