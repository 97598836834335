import { Box } from "@frontend/ui";
import { PoolPageWrapper } from "src/components/pool-page-wrapper";
import { RoutePath } from "src/config/routes";
import { PoolCharts, PoolStatsCards } from "./components";

const PoolDetailsPage = () => (
  <PoolPageWrapper backPageRoute={RoutePath.LpDashboard.PoolsAll} showTokensStatsCard>
    {(poolDetails) => (
      <Box display="flex" flexDirection="column" gap={3}>
        <PoolStatsCards data={poolDetails} />
        <PoolCharts data={poolDetails} />
      </Box>
    )}
  </PoolPageWrapper>
);

export default PoolDetailsPage;
