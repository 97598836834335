import axios from "axios";
import { useEffect, useState } from "react";
import qs from "qs";
import { dataFetchersURL } from "src/utils/data-fetchers-url";
import {
  PublicSimulationsDataOptions,
  PublicSimulationsEndpoint,
  PublicSimulationsQuery,
  PublicSimulationsResponse,
} from "../public-simulations-types";
import { useClientConfig } from "../clients-config";
import { PublicSimulationsType } from "../types";

const getPublicSimulationUrl = (endpoint: PublicSimulationsEndpoint, query?: string) =>
  `${dataFetchersURL()}data/public_simulations/${endpoint}${query ? `?${query}` : ""}`;

export async function GetPublicSimulationsData<T extends PublicSimulationsEndpoint>(
  endpoint: T,
  publicSimulationsType: PublicSimulationsType,
  queryObj: PublicSimulationsQuery<T>,
): Promise<PublicSimulationsResponse<T> | undefined> {
  try {
    const query = qs.stringify(
      {
        ...queryObj,
        type: publicSimulationsType,
      },
      { arrayFormat: "comma" },
    );
    const url = getPublicSimulationUrl(endpoint, query);
    const res = await axios.get<PublicSimulationsResponse<T>>(url);

    return res.data;
  } catch (e) {
    console.log("GetPageData Error: ", e);
    return undefined;
  }
}

export const usePublicSimulationsData = <T extends PublicSimulationsEndpoint>({
  endpoint,
  query,
  sendRequest = true,
}: PublicSimulationsDataOptions<T>): {
  isLoading: boolean;
  response: PublicSimulationsResponse<T> | undefined;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<PublicSimulationsResponse<T> | undefined>(undefined);
  const clientConfig = useClientConfig();

  useEffect(() => {
    void (async () => {
      if (!sendRequest) {
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const fetchedData = await GetPublicSimulationsData<T>(endpoint, clientConfig.publicSimulationsType, query);
      setResponse(fetchedData);
      setIsLoading(false);
    })();
  }, [endpoint, clientConfig, query, sendRequest]);

  return { isLoading, response };
};
