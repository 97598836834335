import { useParams } from "react-router-dom";
import { RoutePath } from "src/config/routes";
import { Client, TwapClientConfig } from "./types";

export const clientsConfig: Record<Client, TwapClientConfig> = {
  uniswap: {
    methodology: {
      link: "https://chaoslabs.xyz/resources/chaos_uniswap_v3_twap_oracle_manipulation.pdf",
      isExternalLink: true,
      methodologyTitle: "Methodology",
      methodologyIntro:
        "This tool is part of a comprehensive analysis of TWAP Oracle price manipulation by Chaos Labs, to identify and quantify the costs associated with price manipulation in the Uniswap v3 ecosystem.",
      redirectBack: RoutePath.Twap.Home,
    },
  },
};

export const useClientConfig = (): TwapClientConfig => {
  const { clientName } = useParams<{ clientName: Client }>();
  return clientsConfig[clientName!];
};
