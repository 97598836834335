import { Box, Typography, CryptoIcon } from "@frontend/ui";

export const TokenChartTitle = ({ tokenA, tokenB }: { tokenA: string; tokenB: string }) => (
  <Box display="flex" gap={2} alignItems="center">
    <Typography variant="h4">Increase</Typography>
    <Box display="flex" gap={1} alignItems="center">
      <CryptoIcon icon={tokenA.toLowerCase()} sx={{ img: { width: 24, height: 24 } }} />
      <Typography variant="h6">{tokenA}</Typography>
      <Typography variant="h6">/</Typography>
      <CryptoIcon icon={tokenB.toLowerCase()} sx={{ img: { width: 24, height: 24 } }} />
      <Typography variant="h6">{tokenB}</Typography>
      <Typography variant="h4">Price</Typography>
    </Box>
  </Box>
);
