import { FC } from "react";
import { Box } from "@frontend/ui";
import { PageTracker } from "src/components/page-tracker";
import { ChaosApolloProvider } from "src/utils/graphql";
import { LpDashboardPageRoutes } from "./routes";

export const LpDashboardPage: FC = PageTracker(() => (
  <ChaosApolloProvider product="osmosis">
    <Box pb={0} height="100%">
      <LpDashboardPageRoutes />
    </Box>
  </ChaosApolloProvider>
));
