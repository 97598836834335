import { RoutePath } from "src/config/routes";

export const getTabByPath = (
  path: string,
  isUnderReview?: boolean,
): {
  path: string;
  assetPath?: string;
  label: string;
} => {
  if (path.includes("listed-assets")) {
    return {
      path: RoutePath.Risk.MarketDetailsListedAssets,
      label: "Assets",
    };
  }
  if (path.includes("recommendations")) {
    return {
      path: RoutePath.Risk.MarketDetailsRecommendations(isUnderReview),
      assetPath: RoutePath.Risk.MarketAssetRecommendations(isUnderReview),
      label: "Recommendations",
    };
  }
  if (path.includes("e-mode")) {
    return {
      path: RoutePath.Risk.MarketEModes,
      label: "E-Mode",
    };
  }

  return {
    path: RoutePath.Risk.MarketDetails,
    label: "Overview",
  };
};
