import { useEffect, useMemo, useState } from "react";
import template from "lodash/template";
import { DefiAppApiEndpoint, DefiAppApiResponse, DefiAppApiUrlInterpolation } from "./types";
import API from "./api";

export type ReturnType<T> = {
  isLoading: boolean;
  response?: T;
};

export const useDefiAppApiRequest = <T extends DefiAppApiEndpoint = DefiAppApiEndpoint>(
  endpoint: T,
  urlInterpolation?: DefiAppApiUrlInterpolation<T>,
  skipRequest?: boolean,
): ReturnType<DefiAppApiResponse<T>> => {
  const [response, setResponse] = useState<DefiAppApiResponse<T>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void (async () => {
      if (skipRequest) {
        return;
      }
      setIsLoading(true);

      const interpolatedEndpoint = urlInterpolation
        ? template(endpoint, {
            interpolate: /{([\s\S]+?)}/g,
          })(urlInterpolation)
        : endpoint;

      const res = await API.get<DefiAppApiResponse<T>>(interpolatedEndpoint);
      setResponse(res.data);
      setIsLoading(false);
    })();
  }, [endpoint, skipRequest, urlInterpolation]);

  const returnObj = useMemo(
    () => ({
      response,
      isLoading,
    }),
    [response, isLoading],
  );

  return returnObj;
};
