import { FC, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { UTCTimestamp } from "lightweight-charts";
import { Box } from "@frontend/ui/box";
import { Grid } from "@frontend/ui/grid";
import { Button } from "@frontend/ui/button";
import { Paper } from "@frontend/ui/paper";
import { Typography } from "@frontend/ui/typography";
import { Chip } from "@frontend/ui/chip";
import { MarketsOverviewCardWithIcon } from "@frontend/ui/overview-card";
import { OrderBook } from "src/components/order-book";
import { LightweightCandlesChart, LightweightLineChart } from "src/components/charts";
import { Tabs } from "@frontend/ui/tabs";
import { ChartData, FundingRate, DyDxPerptualMarkets, CandleData } from "@frontend/types";
import { FilterBar } from "@frontend/ui/filter-bar";
import { simplifyForSearch } from "src/utils/search";
import { useDataFetchersQuery } from "src/hooks/useDataFetchersQuery";
import { Loader } from "@frontend/ui/loader";
import { CryptoIcon } from "@frontend/ui";

type HeaderDataType = {
  title: string;
  value: string;
  currency?: string;
  isChange?: boolean;
};

const HeaderData: FC<HeaderDataType> = ({ title, value, currency, isChange }) => (
  <Grid item>
    <Typography variant="caption">{title}</Typography>
    <Typography
      variant="body2"
      color={(isChange || undefined) && (parseFloat(value.replaceAll(",", "")) <= 0 ? "error.main" : "success.main")}
      display="flex"
      alignItems="center"
    >
      {isChange &&
        (parseFloat(value.replaceAll(",", "")) > 0 ? (
          <ArrowDropUp sx={{ fontSize: 16 }} />
        ) : (
          <ArrowDropDown sx={{ fontSize: 16 }} />
        ))}
      {currency}
      {value}
    </Typography>
  </Grid>
);

type DyDxPerpetualFundingRateType = {
  markets: ChartData[];
  candles: {
    [key in DyDxPerptualMarkets]: CandleData[];
  };
  funding_rate_over_time: {
    [key in DyDxPerptualMarkets]: FundingRate[];
  };
};

type FundingRateInterval =
  | "oneHourRate"
  | "eightHourRate"
  | "twentyFourHourRate"
  | "sevenDayRate"
  | "oneMonthRate"
  | "threeMonthsRate"
  | "oneYearRate";

const fundingRateIntervals: { label: string; interval: FundingRateInterval }[] = [
  {
    label: "1h Rate",
    interval: "oneHourRate",
  },
  {
    label: "8h Rate",
    interval: "eightHourRate",
  },
  {
    label: "7d Rate",
    interval: "sevenDayRate",
  },
  {
    label: "1m Rate",
    interval: "oneMonthRate",
  },
  {
    label: "3m Rate",
    interval: "threeMonthsRate",
  },
  {
    label: "Annualized",
    interval: "oneYearRate",
  },
];

export const DyDxPerpetualFundingRate = () => {
  const { response } = useDataFetchersQuery<DyDxPerpetualFundingRateType>("dydx.perpetual_funding_rate", "offchain");
  const keys = Object.keys(response?.candles || {}) as DyDxPerptualMarkets[];
  const [currentMarket, setCurrentMarket] = useState<DyDxPerptualMarkets>(
    !keys.length || keys.includes("ETH-USD") ? "ETH-USD" : keys[0],
  );
  const [tabIndex, setTabIndex] = useState(0);
  const [showPairPicker, setShowPairPicker] = useState(false);
  const [fundingInterval, setFundingInterval] = useState<FundingRateInterval>("oneHourRate");
  const [searchValue, setSearchValue] = useState("");

  const onSearchChange = (value: string) => setSearchValue(value);
  const searchedMarkets = searchValue
    ? response?.markets.filter(({ chartTitle }) =>
        simplifyForSearch(chartTitle?.value).includes(simplifyForSearch(searchValue)),
      )
    : response?.markets;

  const metadata = response?.markets.find(({ chartTitle }) => chartTitle?.value === currentMarket)
    ?.chartMetaData as unknown as {
    indexPrice: string;
    oraclePrice: string;
    priceChange24H: string;
    openInterest: string;
    volume24H: string;
    trades24H: string;
  };
  const token = currentMarket.split("-")[0].toLowerCase();
  const chartHeight = { xs: 300, lg: "100%" };

  return (
    <Box
      height={{ lg: "100%" }}
      maxHeight={{ lg: "calc(100vh - 284px)" }}
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Paper variant="card" sx={{ overflow: "visible", p: 2, mb: { xs: 0, lg: 2 } }}>
        <Box display="flex" alignItems="center" flexDirection={{ xs: "column", lg: "row" }}>
          <Button
            color="secondary"
            onClick={() => setShowPairPicker(!showPairPicker)}
            sx={{
              px: 5,
              flexShrink: 0,
              mb: { xs: 2, lg: 0 },
            }}
          >
            <CryptoIcon icon={token} sx={{ img: { width: 16, height: 16 }, mr: 1 }} />
            <Typography>{currentMarket}</Typography>
            {showPairPicker ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Button>
          {metadata && (
            <Grid container px={1} spacing={1} justifyContent="space-evenly">
              <HeaderData title="Index Price" value={metadata.indexPrice} currency="$" />
              <HeaderData title="Oracle Price" value={metadata.oraclePrice} currency="$" />
              <HeaderData title="24h Change" value={metadata.priceChange24H} currency="$" isChange />
              <HeaderData title="Open Interest" value={`${metadata.openInterest} ${token}`} />
              <HeaderData title="24h Volume" value={metadata.volume24H} currency="$" />
              <HeaderData title="24h Trades" value={metadata.trades24H} />
            </Grid>
          )}
        </Box>
      </Paper>
      <Box display="flex" flex="1" overflow="hidden">
        {showPairPicker && (
          <ClickAwayListener onClickAway={() => setShowPairPicker(false)}>
            <Box
              overflow="auto"
              position={{ xs: "absolute", lg: "static" }}
              zIndex={100}
              maxHeight={{ xs: 400, lg: "100%" }}
              top={70}
              left="calc(50% - 125px)"
              mr={2}
              gap={2}
            >
              <FilterBar fullWidth value={searchValue} onChange={onSearchChange} sx={{ mb: 1 }} />
              <Box>
                {searchedMarkets?.map((chart, i) => (
                  <MarketsOverviewCardWithIcon
                    key={`${chart.chartTitle?.value || ""}-${i}`}
                    index={i}
                    title={chart.chartTitle?.value || ""}
                    value={chart.chartSubtitle?.value}
                    count={chart.chartSubtitle?.value}
                    delta={`${chart.chartDelta?.key || ""}: ${chart.chartDelta?.value || ""}`}
                    subtitle={`${chart.chartSubtitle?.key || ""}: ${chart.chartSubtitle?.value || ""}`}
                    kv={chart.chartDetail}
                    icon={chart.chartTitle?.value.toLowerCase()}
                    onClick={() => {
                      setCurrentMarket((chart.chartTitle?.value || "BTC-USD") as DyDxPerptualMarkets);
                      setShowPairPicker(false);
                    }}
                  />
                ))}
              </Box>
            </Box>
          </ClickAwayListener>
        )}
        <Box
          flex="1"
          display="flex"
          flexDirection={{ xs: "column-reverse", lg: "row" }}
          sx={{ width: "calc(100% - 250px)" }}
        >
          <OrderBook currentMarket={currentMarket} />
          <Paper
            variant="card"
            sx={{
              ml: { lg: 2 },
              my: { xs: 2, lg: 0 },
              p: 2,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", lg: "0" },
            }}
          >
            <Box mb={2} flex="0">
              <Tabs
                value={tabIndex}
                tabs={[{ label: "Price" }, { label: "Funding Rate" }]}
                onChange={(_, i) => setTabIndex(i)}
              />
            </Box>
            {!response ? (
              <Loader />
            ) : tabIndex === 0 ? (
              <LightweightCandlesChart
                data={response.candles[currentMarket]
                  .map((d) => ({
                    ...d,
                    open: Number(d.open),
                    high: Number(d.high),
                    low: Number(d.low),
                    close: Number(d.close),
                    time: (new Date(d.updatedAt).getTime() / 1000) as UTCTimestamp,
                  }))
                  .sort((a, b) => a.time - b.time)}
                height={chartHeight}
              />
            ) : (
              <>
                <Box ml="auto">
                  {fundingRateIntervals.map((fri) => (
                    <Chip
                      key={fri.label}
                      size="small"
                      label={fri.label}
                      onClick={() => setFundingInterval(fri.interval)}
                      sx={{ mr: 1, mb: 1 }}
                      variant={fundingInterval === fri.interval ? "outlined" : "filled"}
                    />
                  ))}
                </Box>
                <LightweightLineChart
                  data={response.funding_rate_over_time[currentMarket]
                    .map((d) => ({
                      time: (new Date(d.effectiveAt).getTime() / 1000) as UTCTimestamp,
                      value: parseFloat(d[fundingInterval]) * 100,
                    }))
                    .sort((a, b) => a.time - b.time)}
                  valueSuffix="%"
                  hoverBoxLabel={`Current ${
                    fundingRateIntervals.find(({ interval }) => interval === fundingInterval)?.label || "Rate"
                  }`}
                  height={chartHeight}
                />
              </>
            )}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};
