import { ChaosTable, ExternalLinkCell, dateCell } from "@frontend/ui/chaos-table";
import { getAccountDetailsUrl, getTxDetailsUrl } from "@frontend/ui/utils/chain-url-mapper";
import { Header, RenderData } from "@frontend/types";
import { formatAddressCompact, formatAmount } from "@frontend/ui/utils/formatters";
import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { Chain, LiquidationsEvent } from "../../../generated";
import { getChainDisplayName } from "../../alerts/utils";

const formatPrice = (price: number) => formatAmount(price || 0, { currency: "USD" });

type Props = {
  isLoading: boolean;
  liquidationsEvents?: LiquidationsEvent[];
};

const LiquidationsEventsTable = ({ liquidationsEvents, isLoading }: Props) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { isMultichain, isMultiMarket } = useClientConfig();

  const tableHeaders: Header[] = [
    {
      renderType: "TEXT",
      text: "Event Date",
      width: "200px",
      isDate: true,
    },
    ...(isMultichain
      ? [
          {
            renderType: "TEXT",
            text: "Chain",
            textHidden: true,
            width: "75px",
          } as Header,
        ]
      : []),
    ...(isMultiMarket && !isMultichain
      ? [
          {
            renderType: "TEXT",
            text: "Market",
          } as Header,
        ]
      : []),
    {
      renderType: "TEXT",
      text: "Collateral Asset",
    },
    {
      renderType: "TEXT",
      text: "Debt Asset",
    },
    {
      renderType: "TEXT",
      text: "Borrower",
    },
    {
      renderType: "TEXT",
      text: "Debt Repaid",
      prefix: "$",
    },
    {
      renderType: "TEXT",
      text: "Collateral Seized",
      prefix: "$",
    },
    {
      renderType: "TEXT",
      text: "TX Hash",
      width: "150px",
    },
    {
      renderType: "TEXT",
      text: "Liquidator",
      width: "150px",
    },
  ];

  const tableRows: RenderData[][] = (liquidationsEvents || []).map(
    (event: LiquidationsEvent) =>
      [
        dateCell(new Date(event.timestamp)),
        ...(isMultichain
          ? [
              {
                renderType: "TEXT",
                text: getChainDisplayName(event.chain as Chain),
                token1: event.chain,
                extraData: {
                  symbol: event.chain,
                },
              } as Header,
            ]
          : []),
        ...(isMultiMarket && !isMultichain
          ? [
              {
                renderType: "TEXT",
                text: event.marketId,
                tooltipText: event.marketId,
              } as Header,
            ]
          : []),
        {
          renderType: "TEXT",
          text: event.colleteralTokenSymbol,
          token1: event.colleteralTokenSymbol,
          extraData: {
            symbol: event.colleteralTokenSymbol,
          },
        },
        {
          renderType: "TEXT",
          text: event.debtTokenSymbol,
          token1: event.debtTokenSymbol,
          extraData: {
            symbol: event.debtTokenSymbol,
          },
        },
        {
          renderType: "LINK",
          text: formatAddressCompact(event.debtUserId, 4),
          href: RoutePath.Risk.WalletDetails.replace(RouteParams.ClientName, clientName!).replace(
            RouteParams.Address,
            event.debtUserId,
          ),
          tooltipText: event.debtUserId,
          searchTerm: event.debtUserId,
          exportText: event.debtUserId,
        },
        {
          renderType: "TEXT",
          text: formatPrice(event.debtRepaidAmountInUsd),
          tooltipText: formatAmount(event.debtRepaidAmountInUsd || 0, {
            currency: "USD",
            notation: "standard",
          }),
          value: Number(event.debtRepaidAmountInUsd) || 0,
        },
        {
          renderType: "TEXT",
          // TODO: Clean this up after deploying venus isolated pools in
          text: event.colleteralSeizedAmountInUsd ? formatPrice(event.colleteralSeizedAmountInUsd) : "",
          tooltipText: event.colleteralSeizedAmountInUsd
            ? formatAmount(event.colleteralSeizedAmountInUsd || 0, {
                currency: "USD",
                notation: "standard",
              })
            : "",
          value: Number(event.colleteralSeizedAmountInUsd) || 0,
        },
        {
          renderType: "CUSTOM",
          text: formatAddressCompact(event.trxId),
          searchTerm: event.trxId,
          exportText: event.trxId,
          component: (
            <ExternalLinkCell
              text={formatAddressCompact(event.trxId, 4)}
              tooltip={event.trxId}
              href={getTxDetailsUrl(event.chain as Chain, event.trxId)}
            />
          ),
        },
        {
          renderType: "CUSTOM",
          text: formatAddressCompact(event.liquidatorAccount),
          searchTerm: event.liquidatorAccount,
          exportText: event.liquidatorAccount,
          component: (
            <ExternalLinkCell
              text={formatAddressCompact(event.liquidatorAccount, 4)}
              tooltip={event.liquidatorAccount}
              href={getAccountDetailsUrl(event.chain as Chain, event.liquidatorAccount)}
            />
          ),
        },
      ] as RenderData[],
  );

  return (
    <ChaosTable
      title="Recent Liquidation Events"
      headers={tableHeaders}
      data={tableRows}
      pageSize={10}
      isInitialSortEnable
      initialSortBy={0}
      isInitialSortDesc
      isLoading={isLoading}
    />
  );
};

export default LiquidationsEventsTable;
