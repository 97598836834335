import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const scrollTopBlacklist = ["alerts"];

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (scrollTopBlacklist.some((path) => pathname.includes(path))) {
      return;
    }

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  return null;
};
