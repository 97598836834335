import { Box, Paper, Typography } from "@frontend/ui";
import BenqiCalculator from "./benqi-calculator";
import BenqiCalculatorFormula from "./benqi-calculator-formula";

const BenqiCalculatorPage = () => (
  <Box width="100%" height="100%" display="flex" justifyContent="center" flexDirection="column">
    <Box mb={2} maxWidth={900}>
      <Typography>
        This calculator is designed for validator operators to estimate the amount of QI that will be profitable to
        stake.
      </Typography>
      <Typography>
        Learn more at{" "}
        <a href="https://docs.benqi.fi/benqi-liquid-staking/veqi" target="_blank" rel="noreferrer">
          https://docs.benqi.fi/benqi-liquid-staking/veqi
        </a>
      </Typography>
      <Typography fontSize={14} fontStyle="italic" mt={2}>
        This tool is for informational purposes only and is not financial or legal advice.
      </Typography>
    </Box>
    <BenqiCalculator />
    <Paper
      color="secondary"
      sx={{
        marginTop: "24px",
        padding: "24px 24px",
        borderRadius: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        bgcolor: "background.default",
        overflowX: "scroll",
      }}
    >
      <BenqiCalculatorFormula />
      <Typography mb={2} fontWeight={400} fontSize={14}>
        * Effective Staked QI - This is usually the Staked QI amount that was entered. If the entered amount entitles
        the validator to more AVAX than the amount that can be delegated to the validator then the Effective Staked QI
        is: (Effective Validator QI Ratio x Total Staked QI) / (1 - Effective Validator QI Ratio) where: Effective
        Validator QI Ratio = ([Validator Staked AVAX] x 4) / Total AVAX delegated to QI Stakers
      </Typography>
      <Typography mb={2} fontWeight={400} fontSize={14}>
        ** Total AVAX delegated to QI Stakers - 35% of the total supply of AVAX staked in BENQI
      </Typography>
    </Paper>
  </Box>
);

export default BenqiCalculatorPage;
