import { Paper, CompositionOverTimeChart } from "@frontend/ui";
import { palette } from "@frontend/ui/theme/palette";
import { HistoryDataFragment } from "src/pages/ccar-perpetuals-page/generated";

type OpenInterestDistributionChartProps = {
  isLoading: boolean;
  short?: HistoryDataFragment[];
  long?: HistoryDataFragment[];
  combineOpenInterest?: boolean;
};

export const OpenInterestDistributionChart = ({
  isLoading,
  long = [],
  short = [],
  combineOpenInterest,
}: OpenInterestDistributionChartProps) => (
  <CompositionOverTimeChart
    isLoading={isLoading}
    percentToggle
    title={combineOpenInterest ? "Open Interest Over Time" : "Open Interest Distribution"}
    series={
      !combineOpenInterest
        ? [
            {
              label: "Short",
              color: palette.red.main,
              type: "bar",
              data: short.map((s) => [s.time, s.value]),
            },
            {
              label: "Long",
              color: palette.green.hover,
              type: "bar",
              data: long.map((l) => [l.time, l.value]),
            },
          ]
        : [
            {
              label: "Open Interest",
              type: "bar",
              data: long.map((l) => [l.time, l.value + (short.find(({ time }) => time === l.time)?.value || 0)]),
            },
          ]
    }
    showLegend={!combineOpenInterest}
  />
);
