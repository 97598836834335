import { Chain, LendingEvent } from "../../generated";

export type WalletEvent = {
  transactionId?: string;
  chain: string;
  timestamp: number;
  actionName: string;
  assetName: string;
  assetSymbol: string;
  amount: string;
  amountInUsd: number;
};

export const normalizeWalletEvents = (walletEvents: WalletEvent[], address: string): LendingEvent[] =>
  walletEvents.map((event) => ({
    address,
    amount: Number(event.amount),
    amountInUsd: Number(event.amountInUsd),
    assetName: event.assetName,
    assetSymbol: event.assetSymbol,
    chain: event.chain as Chain,
    marketId: event.chain,
    marketName: event.chain,
    marketType: null,
    timestamp: event.timestamp,
    txHash: event.transactionId || null,
    type: event.actionName,
  }));
