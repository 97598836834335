import {
  Deviation,
  DeviationStatistics,
  PriceFeedPrice,
  PriceFeedsResponse,
  ProviderDeviationDistribution,
  ExchangeVolume,
  ExchangesVwap,
  GrangerCausality,
  LatencyStatisticsResponse,
} from "../types";

export enum Endpoints {
  PriceFeeds = "price_feeds",
  FeedPrice = "price_feeds/{symbol}/price",
  PriceFeedPrices = "price_feeds/{symbol}/prices",
  PriceFeedLatencyStatistics = "price_feeds/{symbol}/{baseline}/latency_statistics",
  PriceFeedDeviationStatistics = "price_feeds/{symbol}/{baseline}/deviation_statistics",
  PriceFeedDeviationFromPriceIndex = "price_feeds/{symbol}/price_index_deviation",
  PriceFeedDeviationFromBaseline = "price_feeds/{symbol}/{baseline}/baseline_deviation",
  PriceFeedDeviationDistribution = "price_feeds/{symbol}/{baseline}/deviation_buckets",
  PriceFeedVolumes = "price_feeds/{symbol}/volumes",
  PriceFeedVwap = "price_feeds/{symbol}/vwap",
  PriceFeedGrangerCausality = "price_feeds/{symbol}/{baseline}/granger_causality",
}

export type ProvidersTimeSpanParams = {
  providers: string[];
  start_time: string;
  end_time: string;
};

export type TimeSpanParams = {
  start_time: string;
  end_time: string;
};

export type TimestampParam = {
  timestamp: string;
};

type AssetInterpolation = {
  symbol: string;
};

type AssetBaselineInterpolation = {
  symbol: string;
  baseline: string;
};

export type Params<T extends Endpoints> = T extends
  | Endpoints.PriceFeedPrices
  | Endpoints.PriceFeedLatencyStatistics
  | Endpoints.PriceFeedDeviationStatistics
  | Endpoints.PriceFeedDeviationFromPriceIndex
  | Endpoints.PriceFeedDeviationFromBaseline
  | Endpoints.PriceFeedDeviationDistribution
  | Endpoints.PriceFeedVolumes
  | Endpoints.PriceFeedGrangerCausality
  ? ProvidersTimeSpanParams
  : T extends Endpoints.PriceFeedVwap
    ? TimeSpanParams
    : T extends Endpoints.FeedPrice
      ? TimestampParam
      : undefined;

export type Response<T extends Endpoints> = T extends Endpoints.PriceFeeds
  ? PriceFeedsResponse
  : T extends Endpoints.PriceFeedPrices
    ? PriceFeedPrice[]
    : T extends Endpoints.PriceFeedLatencyStatistics
      ? LatencyStatisticsResponse
      : T extends Endpoints.PriceFeedDeviationStatistics
        ? DeviationStatistics[]
        : T extends Endpoints.PriceFeedDeviationDistribution
          ? ProviderDeviationDistribution[]
          : T extends Endpoints.PriceFeedDeviationFromPriceIndex | Endpoints.PriceFeedDeviationFromBaseline
            ? Deviation[]
            : T extends Endpoints.PriceFeedVolumes
              ? ExchangeVolume[]
              : T extends Endpoints.PriceFeedVwap
                ? ExchangesVwap[]
                : T extends Endpoints.PriceFeedGrangerCausality
                  ? GrangerCausality[]
                  : T extends Endpoints.FeedPrice
                    ? { price: number }
                    : unknown;

export type Interpolation<T extends Endpoints> = T extends
  | Endpoints.PriceFeedPrices
  | Endpoints.PriceFeedDeviationFromPriceIndex
  | Endpoints.PriceFeedVolumes
  | Endpoints.PriceFeedVwap
  | Endpoints.FeedPrice
  ? AssetInterpolation
  : T extends
        | Endpoints.PriceFeedLatencyStatistics
        | Endpoints.PriceFeedDeviationStatistics
        | Endpoints.PriceFeedDeviationStatistics
        | Endpoints.PriceFeedDeviationDistribution
        | Endpoints.PriceFeedDeviationFromBaseline
        | Endpoints.PriceFeedGrangerCausality
    ? AssetBaselineInterpolation
    : undefined;
