import { Box } from "@frontend/ui";
import { SxProps, Theme } from "@mui/material";

type PoolTokenWrapperProps = {
  children: React.ReactElement;
  sx?: SxProps<Theme>;
  size?: number;
};

const PoolTokenWrapper = ({ children, sx, size = 40 }: PoolTokenWrapperProps) => (
  <Box
    width={size}
    height={size}
    border="1px solid #e5e9eb80"
    borderRadius="50%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      background:
        "linear-gradient(0deg, #252A2F, #252A2F), linear-gradient(0deg, rgba(229, 233, 235, 0.5), rgba(229, 233, 235, 0.5))",
      ...(sx || {}),
    }}
  >
    {children}
  </Box>
);

export default PoolTokenWrapper;
