import { Box, CustomReactSelect, Typography } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useParams, useLocation } from "react-router-dom";
import { TabsNavigation } from "src/components/layouts";
import { NavigationTabProps } from "src/components/layouts/tabs-navigation";
import { RouteParams, RoutePath } from "src/config/routes";
import { getIconSrc } from "@frontend/ui/crypto-icon/get-icon-src";
import { useGmPoolQuery, useMarketsQuery } from "src/pages/ccar-perpetuals-page/generated";
import { useClientConfig } from "src/pages/ccar-perpetuals-page/clients-config";

const MarketDropdownHeader = () => {
  const { clientName, marketId } = useParams();
  const { markets: marketsConfig, marketBaseAssetOverride } = useClientConfig();
  const location = useLocation();
  const { data: marketsData } = useMarketsQuery();
  const markets = marketsData?.markets || [];

  const replaceParams = (path: string) =>
    path.replace(RouteParams.ClientName, clientName!).replace(RouteParams.MarketId, marketId!);

  const marketsPageTabs: NavigationTabProps[] = [
    {
      label: "Overview",
      isActive: (path: string) =>
        path.includes(replaceParams(RoutePath.CCARPerpetuals.MarketDetailsOverview)) ||
        path.includes(replaceParams(RoutePath.Risk.MarketDetailsOverview)),
    },
    ...(marketsConfig?.hasGmPools
      ? [
          {
            label: "Pool",
            isActive: (path: string) =>
              path.includes(replaceParams(RoutePath.CCARPerpetuals.MarketDetailsPool)) ||
              path.includes(replaceParams(RoutePath.Risk.MarketDetailsPool)),
          },
        ]
      : []),
  ];

  const dropdownOptions = markets.map(({ symbol, name, id }) => {
    const asset = marketBaseAssetOverride?.[id] || symbol;
    return {
      value: id,
      label: name,
      cryptoIcon: getIconSrc(asset) ? asset.toLowerCase() : undefined,
      link: location.pathname.replace(marketId!, id),
    };
  });

  const isInPoolsPage = location.pathname.includes(replaceParams(RoutePath.CCARPerpetuals.MarketDetailsPool));

  const { data: gmPoolData } = useGmPoolQuery({
    variables: {
      marketId: marketId!,
    },
    skip: !isInPoolsPage || !marketsConfig?.hasGmPools,
  });

  return (
    <PageHeader
      backLink={RoutePath.Risk.Markets.replace(RouteParams.ClientName, clientName!)}
      pageTitle={
        <Box display="flex" gap={[1, 2]} alignItems="center">
          {markets && markets?.length > 1 && (
            <CustomReactSelect
              options={dropdownOptions}
              value={dropdownOptions.find((option) => option.value === marketId!)}
              isSearchable={false}
              variant="h3"
            />
          )}
          {gmPoolData?.gmPool && (
            <>
              <Typography variant="h3" color="almostWhite.main" mb={0.5} alignSelf="end">
                /
              </Typography>
              <Typography variant="h3">{gmPoolData.gmPool.poolName.split("-").join(" - ")}</Typography>
            </>
          )}
        </Box>
      }
      suffixComponent={
        marketsPageTabs.length > 1 ? <TabsNavigation variant="sub-nav" tabs={marketsPageTabs} /> : undefined
      }
      showBackButton={markets && markets?.length > 1}
    />
  );
};

export default MarketDropdownHeader;
