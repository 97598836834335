import { Header, RenderData } from "@frontend/types";
import {
  ChaosTable,
  ExternalLinkCell,
  currencyCell,
  labelsCell,
  linkCell,
  numberCell,
  textCell,
  dateCell,
} from "@frontend/ui";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { useMemo } from "react";
import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { getTxDetailsUrl } from "@frontend/ui/utils/chain-url-mapper";
import { LendingEvent, LendingEventsInput, useLendingEventsQuery } from "../../generated";
import { getEventDisplayName, getEventIcon } from "./utils";
import { useClientConfig } from "../../clients-config";

type Props = {
  eventsQuery?: Partial<LendingEventsInput>;
  events?: LendingEvent[];
  metadata?: string;
};

const EventsTable = ({ eventsQuery, events: eventsProp, metadata }: Props) => {
  const { clientName } = useParams();
  const { marketType, isMultiMarket, showChainMarketsBreakdown, eventsTable } = useClientConfig();
  const isChainMarket = marketType === "ChainMarket";

  const query: LendingEventsInput = useMemo(
    () => ({
      marketId: eventsQuery?.marketId || null,
      assetSymbol: eventsQuery?.assetSymbol || null,
      walletAddress: eventsQuery?.walletAddress || null,
      chain: null,
      startTime: null,
      endTime: null,
    }),
    [eventsQuery],
  );

  const { loading, data } = useLendingEventsQuery({
    variables: { query },
    skip: !eventsQuery,
  });

  const events = data?.lendingEvents || eventsProp || [];
  const hasTxHash = events.some((event) => !!event.txHash);
  const hasAmountInUsd = !eventsTable?.hideAmountInUsd && events.some((event) => !!event.amountInUsd);
  const showWalletAddress = eventsQuery && !eventsQuery.walletAddress;

  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Date",
      isDate: true,
    },
    {
      renderType: "TEXT",
      text: "Action",
      width: "16%",
    },
    ...(!eventsQuery?.marketId && showChainMarketsBreakdown
      ? [
          {
            renderType: "TEXT",
            text: "Chain",
          } as Header,
        ]
      : []),
    ...(!eventsQuery?.marketId && isMultiMarket
      ? [
          {
            renderType: "TEXT",
            text: "Market",
          } as Header,
        ]
      : []),
    ...(!eventsQuery?.assetSymbol
      ? [
          {
            renderType: "TEXT",
            text: "Asset",
          } as Header,
        ]
      : []),
    {
      renderType: "TEXT",
      text: "Amount (Token)",
    },
    ...(hasAmountInUsd
      ? [
          {
            renderType: "TEXT",
            text: "Amount (USD)",
            prefix: "$",
          } as Header,
        ]
      : []),
    ...(hasTxHash
      ? [
          {
            renderType: "TEXT",
            text: "TX Hash",
          } as Header,
        ]
      : []),
    ...(showWalletAddress
      ? [
          {
            renderType: "TEXT",
            text: "Account",
          } as Header,
        ]
      : []),
  ];

  const tableData: RenderData[][] = events.map((event: LendingEvent) => {
    const { timestamp, assetSymbol, amount, amountInUsd, chain, txHash } = event;
    const eventDisplayName = getEventDisplayName(event.type);
    const eventIcon = getEventIcon(event.type);
    const market = event.marketName || event.chain;

    const row: RenderData[] = [
      dateCell(new Date(timestamp * 1000)),
      labelsCell("INFO", eventDisplayName, eventDisplayName, eventIcon),
      ...(!eventsQuery?.marketId && showChainMarketsBreakdown ? [textCell(event.chain, undefined, event.chain)] : []),
      ...(!eventsQuery?.marketId && isMultiMarket
        ? [textCell(market, undefined, isChainMarket ? market : undefined)]
        : []),
      ...(!eventsQuery?.assetSymbol ? [textCell(assetSymbol)] : []),
      amount !== undefined ? numberCell(amount, { tokenIcon: assetSymbol }) : textCell(""),
      ...(hasAmountInUsd ? [amountInUsd !== undefined ? currencyCell(amountInUsd) : textCell("")] : []),
      ...(hasTxHash
        ? [
            txHash
              ? ({
                  renderType: "CUSTOM",
                  text: formatAddressCompact(txHash),
                  searchTerm: txHash,
                  exportText: txHash,
                  component: (
                    <ExternalLinkCell
                      text={formatAddressCompact(txHash, 4)}
                      tooltip={txHash}
                      href={getTxDetailsUrl(chain, txHash)}
                    />
                  ),
                } as RenderData)
              : textCell(""),
          ]
        : []),
      ...(showWalletAddress
        ? [
            linkCell(
              formatAddressCompact(event.address, 4),
              RoutePath.Risk.WalletDetails.replace(RouteParams.ClientName, clientName!).replace(
                RouteParams.Address,
                event.address,
              ),
              event.address,
            ),
          ]
        : []),
    ];
    return row;
  });

  return (
    <ChaosTable
      title="Recent Events"
      headers={headers}
      data={tableData}
      metadata={metadata}
      isLoading={loading}
      pageSize={10}
      initialSortBy={0}
      isInitialSortDesc
      isFullHeight
    />
  );
};

export default EventsTable;
