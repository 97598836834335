import { OptionsSelect } from "@frontend/ui";
import { TimeSpan } from "../../generated";
import { getLabelByTimeSpan } from "./utils";

type Props<T = TimeSpan> = {
  selectedTimeSpan: T;
  onSelectTimeSpan: (timeSpan: T) => void;
  timeSpanOptions?: readonly T[];
  hideLabel?: boolean;
  size?: "small" | "medium";
};

const allTimeSpanOptions = [TimeSpan.Day, TimeSpan.Week, TimeSpan.Month, TimeSpan.Quarter, TimeSpan.Year];

export const defaultTimeSpan = TimeSpan.Month;

const TimeSpanPicker = <T = TimeSpan>({
  onSelectTimeSpan,
  selectedTimeSpan,
  timeSpanOptions = allTimeSpanOptions as T[],
  hideLabel,
  size,
}: Props<T>) => (
  <OptionsSelect<T>
    title={hideLabel ? undefined : "Time Range"}
    options={timeSpanOptions.map((value) => ({ value, label: getLabelByTimeSpan(value as TimeSpan) }))}
    selectedOption={selectedTimeSpan}
    onSelectOption={onSelectTimeSpan}
    size={size}
  />
);

export default TimeSpanPicker;
