import { Box } from "../../box";
import { Typography } from "../../typography";
import { TokenPair } from "./token-pair";
import { TokenWrapper } from "./token-pair/token-wrapper";
import { CryptoIcon } from "../../crypto-icon";

export type TokenPairProps = {
  token1: string;
  token2: string;
  text?: string;
  subTitle?: string;
  variant?: "default" | "vertical";
};

export const TokenPairCell = ({ token1, token2, text, subTitle, variant = "default" }: TokenPairProps) =>
  variant === "vertical" ? (
    <Box display="flex" flexDirection="column" gap={0.5} height="100%" justifyContent="center">
      {[token1, token2].map((tokenSymbol, idx) => {
        const isFirst = idx === 0;
        return (
          <Box display="flex" gap={1} key={`${token1}-${token2}`}>
            <TokenWrapper size={24}>
              <CryptoIcon icon={tokenSymbol.toLowerCase()} sx={{ img: { width: 21, height: 21 } }} />
            </TokenWrapper>
            <Typography>
              {tokenSymbol.toUpperCase()}
              {isFirst ? " /" : ""}
            </Typography>
          </Box>
        );
      })}
    </Box>
  ) : (
    <Box height="100%" display="flex" alignItems="center" gap={1}>
      <TokenPair token1={token1} token2={token2} />
      <Box flexDirection="column">
        {text ? (
          <Typography>{text}</Typography>
        ) : (
          <Typography>
            {token1} / {token2}
          </Typography>
        )}
        {subTitle && <Typography variant="caption">{subTitle}</Typography>}
      </Box>
    </Box>
  );
