import { CompositionOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { MarketStatsOverTimeFragment } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  marketsStatsOverTime?: MarketStatsOverTimeFragment[];
  cryptoCurrency?: string;
  loading: boolean;
};

export const MarketsOpenInterestChart = ({ marketsStatsOverTime, cryptoCurrency, loading }: Props) => (
  <CompositionOverTimeChart
    isLoading={loading}
    title="Open Interest & Trading Volume"
    description="Showing per market Open Interest and daily protocol trading volume."
    yAxisLabel="Trading Volume"
    yAxisLabelRight="Open Interest"
    hideTotal
    showAggregateOther
    series={[
      ...(marketsStatsOverTime?.map<NumberChartSeries>((m, i) => ({
        name: m.marketId,
        label: m.marketId,
        type: "bar",
        stack: "Asset",
        barWidth: 8,
        barGap: 1,
        yAxisIndex: 1,
        data: m.longInterestUsd.map((v, j) => [v.time, v.value + marketsStatsOverTime[i].shortInterestUsd[j].value]),
      })) || []),
      (marketsStatsOverTime || []).reduce<NumberChartSeries>(
        (acc, m) => {
          const times: number[] = Array.from(
            new Set([...m.volumeUsd.map(({ time }) => time), ...acc.data.map(([time]) => time)]),
          );

          return {
            ...acc,
            data: times.map((time) => {
              const currentValue = acc.data.find((v) => v[0] === time)?.[1] || 0;
              const addedValue = m.volumeUsd.find((v) => v.time === time)?.value || 0;

              return [time, currentValue + addedValue];
            }),
          };
        },
        {
          name: "Trading Volume",
          label: "Trading Volume",
          type: "line",
          data: [],
        } as NumberChartSeries,
      ),
    ]}
    cryptoCurrency={cryptoCurrency}
  />
);
