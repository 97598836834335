import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, iconsCell, numberCell, percentCell, textCell } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { useAvsDataQuery, useNodeOperatorsQuery } from "../../../generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "AVS Name",
  },
  {
    renderType: "TEXT",
    text: "TVR",
  },
  {
    renderType: "TEXT",
    text: "Number of EtherFi Operators",
  },
];

const AvsTable = () => {
  const { nodeOperatorId } = useParams();
  const { data, loading } = useNodeOperatorsQuery();

  const selectedOperator = data?.nodeOperators?.find((op) => op.id === nodeOperatorId);
  const avsToOperatorsNumber = (data?.nodeOperators || [])
    .flatMap((op) => op.avs)
    .reduce<Record<string, number>>((obj, a) => ({ ...obj, [a.name]: (obj[a.name] || 0) + 1 }), {});

  const tableData: RenderData[][] = (selectedOperator?.avs || []).map((a) => [
    textCell(a.name, undefined, a.name),
    currencyCell(a.tvrEth, { cryptoCurrency: "ETH" }),
    numberCell(avsToOperatorsNumber[a.name] || 0),
  ]);

  return (
    <ChaosTable
      title="AVSs"
      initialSortBy={1}
      isInitialSortDesc
      headers={headers}
      data={tableData}
      isLoading={loading}
      isFullHeight
      isFilterHidden
    />
  );
};

export default AvsTable;
