import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { WalletDetails } from "src/pages/lp-dashboard-page/generated";

type Props = {
  data: WalletDetails;
};

const WalletStatsCards = ({ data }: Props) => {
  const totalLiquidityBonded = data.pools.reduce(
    (accumulator, pool) => accumulator + (pool?.liquidityBondedUSD || 0),
    0,
  );

  const totalAprChange = data.pools.reduce(
    (acc, pool) =>
      pool
        ? acc + (pool.liquidityBondedUSD / totalLiquidityBonded) * (pool.projectedIncentivesAPR - pool.incentivesAPR)
        : acc,
    0,
  );

  const cardsData: ValueCardProps[] = [
    {
      title: "Liquidity Bonded",
      value: totalLiquidityBonded,
      currency: "USD",
    },
    {
      title: "Daily Incentives",
      value: data.currentDailyIncentivesUSD,
      currency: "USD",
    },
    {
      title: "Projected Incentives",
      value: data.projectedDailyIncentivesUSD,
      currency: "USD",
    },
    {
      title: "APR Change",
      value: totalAprChange,
      isPercent: true,
    },
  ];
  return (
    <Grid container>
      {cardsData.map((card) => (
        <Grid key={card.title} item xs={12} sm={4} lg={3}>
          <ValueCard title={card.title} value={card.value} isPercent={card.isPercent} currency={card.currency} />
        </Grid>
      ))}
    </Grid>
  );
};

export default WalletStatsCards;
