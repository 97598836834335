import { FullPoolFragment, PoolDetailsQueryVariables, usePoolDetailsQuery } from "../../generated";

type ReturnType = {
  isLoading: boolean;
  poolDetails?: FullPoolFragment;
};

export const usePoolDetailsData = (query: PoolDetailsQueryVariables): ReturnType => {
  const poolDetailsResponse = usePoolDetailsQuery({ variables: query });
  const poolDetails = poolDetailsResponse?.data?.poolDetails;

  const response: ReturnType = {
    isLoading: poolDetailsResponse.loading,
    poolDetails,
  };

  return response;
};
