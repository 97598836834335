import { PoolData } from "./generated";

export function getPoolName(poolData?: PoolData) {
  if (!poolData) {
    return "";
  }

  return (
    poolData.name || `${poolData.assetSymbol} ${poolData.version < 1 ? "v1" : poolData.version < 300 ? "v2" : ""} Pool`
  );
}

export function getPoolCreditMangerName(creditManagerName?: string, poolData?: PoolData) {
  if (creditManagerName) {
    return creditManagerName;
  }

  if (!poolData) {
    return "";
  }

  return `${poolData?.assetSymbol} ${poolData?.version < 1 ? "v1" : poolData?.version < 300 ? "v2" : ""}`;
}

export const creditAccountStatusToText: Record<number, string> = {
  0: "Active",
  1: "Closed",
  2: "Repaid",
  3: "Liquidated",
  4: "LiquidateExpired",
  5: "LiquidatePaused",
};
