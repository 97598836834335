import { Box, Tooltip, Typography, Accordion, Paper } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { SxProps } from "@mui/material";
import { SeriesData } from "./chart-option";

export enum Metric {
  "AVG" = "Average",
  "MIN" = "Min",
  "MAX" = "Max",
  "P99" = "P99",
  "P95" = "P95",
}

export type CustomMetric = {
  name: string;
  func: (data: number[]) => number;
  tooltip?: string;
};

export type ChartMetric = Metric | CustomMetric;

type Props = {
  metrics: ChartMetric[];
  data: SeriesData;
  isPercent?: boolean;
  currency?: string;
};

const getMetricValue = (metric: ChartMetric, data: SeriesData): number => {
  const values = data.map(([, value]) => value);
  const sortedValues = [...values].sort((a, b) => a - b);

  const getPercentile = (percentile: 0.99 | 0.95) => sortedValues[Math.round(sortedValues.length * percentile) - 1];

  switch (metric) {
    case Metric.AVG:
      return values.reduce((acc, value) => acc + value, 0) / data.length;
    case Metric.MIN:
      return Math.min(...values);
    case Metric.MAX:
      return Math.max(...values);
    case Metric.P99:
      return getPercentile(0.99);
    case Metric.P95:
      return getPercentile(0.95);
    default:
      return metric.func(values);
  }
};

const Metrics = ({ metrics, data, isPercent, currency, containerSx }: Props & { containerSx?: SxProps }) => {
  const formatterOptions = {
    isPercent,
    currency,
  };
  return (
    <Box display="flex" gap={3} sx={containerSx}>
      {metrics.map((metric) => {
        const metricTitle = typeof metric === "object" ? metric.name : metric;
        const metricValue = getMetricValue(metric, data);

        return (
          <Box key={metricTitle}>
            <Box display="flex" flexDirection="column" whiteSpace="nowrap">
              <Tooltip title={typeof metric === "object" ? metric.tooltip || "" : ""}>
                <Typography variant="caption" width="fit-content">
                  {metricTitle}
                </Typography>
              </Tooltip>
              <Tooltip title={formatAmount(metricValue, { ...formatterOptions, notation: "standard" })}>
                <Typography width="fit-content">{formatAmount(metricValue, formatterOptions)}</Typography>
              </Tooltip>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export const ChartMetrics = (props: Props) => {
  const { metrics } = props;
  const wrapperSx = {
    borderRadius: 1,
    px: 2,
    py: 1,
  };

  if (metrics.length < 4) {
    return (
      <Paper variant="card" sx={wrapperSx}>
        <Metrics {...props} />
      </Paper>
    );
  }

  return (
    <Accordion
      sx={wrapperSx}
      header={
        <Metrics
          {...props}
          metrics={metrics.slice(0, 4)}
          containerSx={{
            justifyContent: "space-between",
          }}
        />
      }
    >
      <Metrics {...props} metrics={metrics.slice(4)} containerSx={{ pt: 2 }} />
    </Accordion>
  );
};
