import { useState } from "react";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { CompositionOverTimeChart, CustomSwitch } from "@frontend/ui";
import { EventStrategy, useInflowOutflowHistoryQuery } from "../../../../generated";

type Props = {
  timeSpan: TimeSpan;
};

const CollateralInflowOutflowChart = ({ timeSpan }: Props) => {
  const [isValueInEth, setIsValueInEth] = useState(true);
  const { loading, data } = useInflowOutflowHistoryQuery({
    variables: {
      timeSpan,
      strategy: EventStrategy.EethEigenlayer,
    },
  });

  return (
    <CompositionOverTimeChart
      title="Collateral Inflow / Outflow Over Time"
      series={[
        {
          id: "inflow",
          label: "Inflow",
          data: (data?.inflowOutflowHistory || []).map((d) => [d.timestamp, isValueInEth ? d.inflowEth : d.inflowUsd]),
          type: "bar",
        },
        {
          id: "outflow",
          label: "Outflow",
          data: (data?.inflowOutflowHistory || []).map((d) => [
            d.timestamp,
            isValueInEth ? -d.outflowEth : -d.outflowUsd,
          ]),
          type: "bar",
        },
      ]}
      isLoading={loading}
      cryptoCurrency={isValueInEth ? "ETH" : undefined}
      headerSuffix={
        <CustomSwitch
          onChange={() => setIsValueInEth((prev) => !prev)}
          checked={isValueInEth}
          checkedLabel="ETH"
          uncheckedLabel="USD"
        />
      }
      timeSpan={timeSpan}
    />
  );
};

export default CollateralInflowOutflowChart;
