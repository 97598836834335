import { Theme } from "@mui/material";
import { alpha } from "@mui/system";
import { palette } from "../theme/palette";

export const chartTheme = (themePalette: Theme) => ({
  animation: false,
  backgroundColor: "transparent",
  textColor: themePalette.palette.greys["400"],
  textStyle: {
    fontFamily: "Aeonik Fono",
    color: themePalette.palette.greys["400"],
    fontWeight: 500,
    fontSize: 14,
  },
  color: [
    themePalette.palette.primary.main,
    "#FFA318",
    "#24B3D0",
    "#2DB574",
    "#DE4469",
    "#7146A0",
    "#0366C1",
    "#9a22d2",
    "#0e55bf",
    palette.orange.active,
    palette.blue.active,
    palette.green.active,
    palette.red.active,
    palette.violette.active,
  ],
  borderWidth: 0,
  tooltip: {
    borderWidth: 0,
    backgroundColor: themePalette.palette.greys["800"],
    textStyle: {
      color: themePalette.palette.text.primary,
      fontFamily: "Aeonik Fono",
      fontWeight: 500,
      fontSize: 14,
    },
  },
  timeAxis: {
    nameTextStyle: {
      color: themePalette.palette.text.primary,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      fontSize: 12,
    },
  },
  valueAxis: {
    nameTextStyle: {
      color: themePalette.palette.text.primary,
      fontSize: 14,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [alpha(themePalette.palette.text.primary, 0.2)],
        type: [4, 4],
      },
    },
    axisLabel: {
      fontSize: 12,
    },
  },
  legend: {
    bottom: "1%",
    textStyle: {
      color: palette.almostWhite.main,
      fontSize: 14,
      fontFamily: "Inter",
    },
    icon: "circle",
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 16,
    pageTextStyle: {
      color: themePalette.palette.text.primary,
    },
    pageIconColor: themePalette.palette.text.secondary,
    pageIconInactiveColor: themePalette.palette.text.disabled,
    left: 0,
    padding: [5, 0, 10, 0],
    width: "100%",
  },
  titleColor: "#ffffff",
  subtitleColor: "#ffffff",
  textColorShow: false,
  markTextColor: "rgba(255,255,255,0.75)",
  borderColor: "#cccccc",
  visualMapColor: ["#516b91", "#59c4e6", "#a5e7f0"],
  visualMap: {
    textStyle: {
      color: palette.almostWhite.main,
    },
  },
  categoryAxis: {
    nameTextStyle: {
      color: palette.white.main,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      show: true,
      margin: 16,
      textStyle: {
        color: palette.almostWhite.main,
        fontSize: 14,
        lineHeight: 24,
      },
    },
    splitLine: {
      show: false,
    },
  },
});
