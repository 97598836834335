import { ChaosTable, currencyCell, currencyTrendCell, linkCell, numberCell, textCell } from "@frontend/ui";
import { getBlockDetailsUrl } from "@frontend/ui/utils/chain-url-mapper";
import { Header, RenderData } from "@frontend/types";
import { useParams } from "react-router-dom";
import { PositionFragment, SortPositionsBy } from "../../../../../../generated";
import { dateCell, dateDiffCell, positionSideCell, positionSymbolCell } from "../../../../../positions/cells";
import { PositionsFilter } from "../../../../../positions/positions-filter";
import { usePositionsData } from "../../../../../positions/use-postions-data";
import { useClientConfig } from "../../../../../../clients-config";
import { RouteParams, RoutePath } from "../../../../../../../../config/routes";

const tableHeaders = (
  hasSharedCollateral: boolean,
  showLiquidationBlock?: boolean,
  open?: boolean,
  useBasePnl?: boolean,
  showFeesColumn?: boolean,
  isGmxV2?: boolean,
): Header[] => [
  {
    renderType: "TEXT",
    text: "Opened",
    width: "15%",
    field: SortPositionsBy.TimeOpened,
  },
  {
    renderType: "TEXT",
    text: open ? "Duration" : "Settled",
    width: !open ? "15%" : undefined,
    nonSortable: open,
  },
  {
    renderType: "ICONS",
    text: "Market",
    field: SortPositionsBy.AssetSymbol,
  },
  {
    renderType: "TEXT",
    text: "Type",
    field: SortPositionsBy.Side,
  },
  {
    renderType: "CURRENCY",
    unit: "usd",
    text: "Open Price",
    nonSortable: true,
  },
  // TODO[aviadg]: remove this once DEV-4956 is done
  ...((isGmxV2
    ? open
      ? [
          {
            renderType: "CURRENCY",
            unit: "usd",
            text: "Current Price",
            field: SortPositionsBy.LastPriceUsd,
          },
        ]
      : []
    : [
        {
          renderType: "CURRENCY",
          unit: "usd",
          text: open ? "Current Price" : "Close Price",
          field: SortPositionsBy.LastPriceUsd,
        },
      ]) as Header[]),
  ...(open || !hasSharedCollateral
    ? ([
        {
          renderType: "CURRENCY",
          unit: "usd",
          text: hasSharedCollateral ? "Liquidation Price" : "Collateral",
          tooltip: hasSharedCollateral ? undefined : "Collateral at the time the position was opened",
          field: hasSharedCollateral ? SortPositionsBy.LiquidationPrice : SortPositionsBy.CollateralBalanceUsd,
        },
      ] as Header[])
    : []),
  {
    renderType: "TEXT",
    text: "Leverage",
    tooltip: open ? undefined : "Leverage at the time the position was opened",
    field: SortPositionsBy.Leverage,
  },
  {
    renderType: "CURRENCY",
    unit: "usd",
    text: "Size",
    tooltip: open ? undefined : "Size at the time before the position was closed",
    field: open ? SortPositionsBy.BalanceUsd : SortPositionsBy.OriginalBalanceUsd,
  },
  ...(useBasePnl
    ? open
      ? ([
          { renderType: "CURRENCY", unit: "usd", text: "Unrealized PnL", field: SortPositionsBy.UnrealizedPnl },
        ] as Header[])
      : ([
          {
            renderType: "CURRENCY",
            unit: "usd",
            text: "Gross RPnL",
            field: SortPositionsBy.TotalBasePnlUsd,
            tooltip: "Realized PnL before Fees and Price Impact",
          },
        ] as Header[])
    : ([
        {
          renderType: "CURRENCY",
          unit: "usd",
          text: "PnL",
          field: SortPositionsBy.Pnl,
        },
      ] as Header[])),
  ...(showFeesColumn
    ? ([{ renderType: "CURRENCY", unit: "usd", text: "Fees", tooltip: "Total fee and Price Impact" }] as Header[])
    : []),
  ...(showLiquidationBlock && !open
    ? [
        {
          renderType: "TEXT",
          text: "Liquidation Block",
          width: "15%",
          field: SortPositionsBy.LiquidationBlock,
        } as Header,
      ]
    : []),
];

const mapRow = (
  position: PositionFragment,
  hasSharedCollateral: boolean,
  chain: string,
  showLiquidationBlock?: boolean,
  open?: boolean,
  useBasePnl?: boolean,
  showFeesColumn?: boolean,
  isGmxV2?: boolean,
  marketBaseAssetOverride?: Record<string, string>,
): RenderData[] => {
  const overrideAsset = position.marketId && marketBaseAssetOverride?.[position.marketId];
  return [
    dateCell(position.timeOpened),
    open ? dateDiffCell(position.timeOpened, position.timeClosed) : dateCell(position.timeClosed!),
    positionSymbolCell(overrideAsset || position.assetSymbol, overrideAsset || position.assetName),
    positionSideCell(position.side),
    currencyCell(position.openPriceUsd),
    ...(!isGmxV2 || open ? [currencyCell(position.lastPriceUsd)] : []),
    ...(open || !hasSharedCollateral
      ? [
          hasSharedCollateral
            ? currencyCell(position.liquidationPrice)
            : currencyCell(position.balanceUsd / position.leverage),
        ]
      : []),
    numberCell(position.leverage),
    currencyCell(position.balanceUsd),
    ...(useBasePnl
      ? open
        ? [currencyTrendCell(position.unrealizedPnl)]
        : [currencyTrendCell(position.totalBasePnlUsd)]
      : [currencyTrendCell(position.pnl)]),
    ...(showFeesColumn ? [currencyCell(position.totalFeesAndPriceImpactUsd ?? 0)] : []),
    ...(showLiquidationBlock && !open
      ? [
          position.liquidationBlock
            ? linkCell(
                position.liquidationBlock.toString() || "",
                getBlockDetailsUrl(chain, position.liquidationBlock.toString()),
              )
            : textCell("-"),
        ]
      : []),
  ];
};

type PositionsTableProps = {
  title: string;
  account: string;
  subAccount?: number;
  chain: string;
  metadata?: string;
  hasSharedCollateral: boolean;
  open?: boolean;
  showLiquidationBlock?: boolean;
};

export const PositionsTable = ({
  account,
  subAccount,
  metadata,
  title,
  chain,
  open,
  hasSharedCollateral,
  showLiquidationBlock,
}: PositionsTableProps) => {
  const { clientName } = useParams();
  const {
    positionDetails,
    positions: { useBasePnl, showFeesColumn },
    marketBaseAssetOverride,
  } = useClientConfig();
  const { positions, loading, filters, onSearch, onFiltersChange, onPageChange, onSortChange } = usePositionsData({
    open,
    account,
    subAccount,
    sort: open ? SortPositionsBy.TimeOpened : SortPositionsBy.TimeClosed,
  });

  const isGmxV2 = clientName?.startsWith("gmx-v2");
  return (
    <ChaosTable
      title={title}
      headers={tableHeaders(hasSharedCollateral, showLiquidationBlock, open, useBasePnl, showFeesColumn, isGmxV2)}
      isLoading={loading}
      pageSize={10}
      data={positions.map((position) =>
        mapRow(
          position,
          hasSharedCollateral,
          chain,
          showLiquidationBlock,
          open,
          useBasePnl,
          showFeesColumn,
          isGmxV2,
          marketBaseAssetOverride,
        ),
      )}
      isFullHeight
      isFilterHidden
      showSearch
      serchbarPlaceholder="Search positions"
      customFilter={
        <PositionsFilter key={filters.length} showOpen={!!open} filters={filters} onFiltersChange={onFiltersChange} />
      }
      initialSortBy={open ? 0 : 1}
      isInitialSortDesc
      emptyState={{
        icon: "chaos",
        title: "No positions to show",
      }}
      rowHref={(rowId) => {
        const position = positions[rowId];

        if (positionDetails?.enabled) {
          return RoutePath.Risk.PositionDetails.replace(RouteParams.ClientName, clientName!).replace(
            RouteParams.PositionId,
            position.positionId,
          );
        }
        return "";
      }}
      rowHrefTarget="_blank"
      metadata={metadata}
      onSortChange={onSortChange}
      onPageChange={onPageChange}
      onSearch={onSearch}
    />
  );
};
