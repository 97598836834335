import { Loader } from "@frontend/ui/loader";
import { useParams } from "react-router-dom";
import { Chain } from "src/pages/ccar-lending-page/generated";
import { useMarketsContext } from "../../context/markets-context";
import MarketDetails from "../../../markets/market-details";

const AssetDetails = () => {
  const { markets } = useMarketsContext();
  const { marketId, asset } = useParams();
  const market = markets.find((m) => m.id === marketId);

  if (!market) return <Loader />;

  const isNonBorrowableAsset = !market.borrowableAssets.includes(asset!);

  return (
    <MarketDetails
      marketId={marketId!}
      chain={market.chain as Chain}
      tokenSymbol={asset!}
      hideHeader
      isNonBorrowableAsset={isNonBorrowableAsset}
    />
  );
};

export default AssetDetails;
