import { TimeSpan } from "@frontend/ui/echarts/types";
import { ModalWrapper } from "@frontend/ui/modals";
import { useState } from "react";
import { HistoryData } from "src/pages/ccar-perpetuals-page/generated";
import { OpenInterestChartWidget } from "./open-interest-chart";
import OpenInterestModal from "./open-interest-modal";
import { useFeatureFlag } from "../../../../../../utils/feature-flags";
import { useClientConfig } from "../../../../clients-config";

type Props = {
  shortInterestUsd?: HistoryData[];
  longInterestUsd?: HistoryData[];
  loading: boolean;
  timeSpan?: TimeSpan;
};

export const OpenInterestChart = ({ shortInterestUsd, longInterestUsd, loading, timeSpan }: Props) => {
  const [modalTimestamp, setModalTimestamp] = useState<number>();
  const { hourlyHistorySupport } = useClientConfig();
  const drillDownModalEnabled = useFeatureFlag("open-interest-drill-down-modal");

  return (
    <>
      <OpenInterestChartWidget
        shortInterestUsd={shortInterestUsd}
        longInterestUsd={longInterestUsd}
        loading={loading}
        timeSpan={timeSpan}
        showCustomTooltip={hourlyHistorySupport && timeSpan !== TimeSpan.Week}
        onClick={(timestamp: number) => {
          setModalTimestamp(timestamp);
        }}
      />
      {drillDownModalEnabled && timeSpan !== TimeSpan.Week && (
        <ModalWrapper open={!!modalTimestamp} onClose={() => setModalTimestamp(undefined)} maxWidth={1200}>
          <OpenInterestModal timestamp={modalTimestamp!} />
        </ModalWrapper>
      )}
    </>
  );
};
