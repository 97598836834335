import { Typography, Box, ECharts, IconButton, CustomIcon } from "@frontend/ui";
import { palette } from "@frontend/ui/theme/palette";
import { formatAxis } from "@frontend/ui/utils/formatters";
import { useState, useMemo } from "react";
import { PoolLiquidityData } from "src/pages/uniswap/generated";
import { currentLiquidityTooltipFormatter } from "./current-liquidity-chart-tooltip";

type Props = {
  currentLiquidity?: PoolLiquidityData;
};

const ZOOM_INTERVAL = 20;
const INITIAL_TICKS_TO_SHOW = 200;

const CurrentLiquidityChart = ({ currentLiquidity }: Props) => {
  const [zoomFactor, setZoomFactor] = useState(0);
  const ticksZoom = zoomFactor * ZOOM_INTERVAL;
  const ticksToShow = INITIAL_TICKS_TO_SHOW - ticksZoom;

  const liquidityData = useMemo(() => {
    if (!currentLiquidity) return [];

    const { ticks } = currentLiquidity;

    const validTicks = ticks.filter((tick) => tick.amount0_usd >= 0 && tick.amount1_usd >= 0);

    const currentTickIdx = validTicks.findIndex((tick) => currentLiquidity.current_human_price === tick.price);

    if (currentTickIdx < 0) return [];

    const filteredTicks = validTicks.filter((_, idx) => Math.abs(idx - currentTickIdx) <= ticksToShow);

    return (
      filteredTicks.map((tick) => {
        const isCurrentTick = validTicks[currentTickIdx].tickIdx === tick.tickIdx;
        const value = [tick.tickIdx, tick.amount0_usd + tick.amount1_usd];

        if (isCurrentTick) {
          return {
            value,
            itemStyle: { color: palette.red.main },
          };
        }

        return value;
      }) || []
    );
  }, [currentLiquidity, ticksToShow]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="h4">Current Liquidity</Typography>
      <Box height={300} position="relative">
        <ECharts
          option={{
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
              formatter: currentLiquidityTooltipFormatter(currentLiquidity),
            },
            xAxis: {
              type: "category",
              show: false,
            },
            grid: {
              bottom: -20,
            },
            yAxis: {
              type: "value",
              axisLabel: {
                formatter: (value: number) => formatAxis(value, 2, "USD"),
                fontSize: 12,
              },
              splitNumber: 3,
            },
            series: [
              {
                data: liquidityData,
                type: "bar",
                barWidth: 4,
                itemStyle: {
                  borderRadius: [50, 50, 0, 0],
                },
                color: palette.aqua.main,
              },
            ],
          }}
        />
        <Box display="flex" gap={1} position="absolute" top={0} right={0}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              setZoomFactor((prev) => prev - 1);
            }}
          >
            <CustomIcon icon="minus" />
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            disabled={ticksToShow === ZOOM_INTERVAL}
            onClick={() => {
              setZoomFactor((prev) => prev + 1);
            }}
          >
            <CustomIcon icon="plus" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CurrentLiquidityChart;
