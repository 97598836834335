import { FC, useCallback, useRef } from "react";
import {
  VictoryChart,
  VictoryAxis,
  VictoryVoronoiContainer,
  VictoryArea,
  VictoryAreaProps,
  VictoryLine,
  VictoryBar,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  ScalePropType,
} from "victory";
import dayjs from "dayjs";
import { Box } from "../box";
import { useElementSize } from "../hooks/use-element-size";
import { Gradient } from "./gradient";
import { chartTooltip } from "./chart-tooltip";
import { colorScale } from "../utils/color-scale";
import { formatAmount, getFractionDigits } from "../utils/formatters";

export interface ChartData {
  x: string | number;
  y: string | number;
}

export const chaosChartTypes = ["AREA", "LINE", "HISTOGRAM"] as const;
export type ChaosChartType = (typeof chaosChartTypes)[number];
export interface ChaosChartProps {
  data?: ChartData[] | ChartData[][];
  isHorizontalAxisDisabled?: boolean;
  currency?: string;
  labelSize?: "small" | "large";
  startY?: number;
  horizontalAxisType?: "block" | "date" | "number";
  color?: string;
  interpolation?: VictoryAreaProps["interpolation"];
  type?: ChaosChartType;
  xAxisLabel?: string;
  yAxisLabel?: string;
  hiddenChartsIndexes?: number[];
  legend?: string[];
  scale?: ScalePropType;
  yDomainGap?: number;
}

export const ChaosChart: FC<ChaosChartProps> = ({
  data,
  isHorizontalAxisDisabled,
  currency,
  labelSize,
  startY,
  horizontalAxisType,
  color,
  interpolation,
  type = "AREA",
  xAxisLabel,
  yAxisLabel,
  hiddenChartsIndexes,
  legend,
  scale,
  yDomainGap = 0.0003,
}) => {
  const isMultiChart = Array.isArray(data?.[0]);
  const chartsData = (!data ? [] : isMultiChart ? data : [data]) as ChartData[][];

  const chartRef = useRef<HTMLDivElement>(null);
  const size = useElementSize(chartRef);

  const getAxisValues = (axis: "x" | "y") =>
    chartsData.reduce((values: number[], chartData) => {
      const chartYValues = chartData.map((item) => Number(item[axis]));
      const newValues = [...values].concat(chartYValues);
      return newValues;
    }, []);

  const yValues = getAxisValues("y");
  const xValues = getAxisValues("x");

  const minY = Math.min(...yValues) || 0;
  const maxY = Math.max(...yValues) || 0;
  const fractionDigits = getFractionDigits(maxY - minY);
  const yLabelsLength = yValues.map((x) => formatAmount(x).length);
  const maxYSize =
    (fractionDigits > 0 ? fractionDigits + 2 : 0) + // decimal point if needed
    Math.max(...yLabelsLength);

  const isHistogram = type === "HISTOGRAM";

  const chartToRender = useCallback(
    (chartData: ChartData[], chartIdx: number, colorIdx = 0) => {
      const isHidden = hiddenChartsIndexes?.includes(chartIdx);
      switch (type) {
        case "LINE":
          return (
            <VictoryLine
              animate
              interpolation="linear"
              style={{
                data: {
                  stroke: colorScale[colorIdx as number],
                  strokeWidth: 2,
                  display: isHidden ? "none" : undefined,
                },
              }}
              data={chartData}
            />
          );
        case "HISTOGRAM":
          return (
            <VictoryGroup
              offset={(chartData?.length || 0) < 5 ? 18 : undefined}
              colorScale={colorScale}
              style={{
                data: {
                  display: isHidden ? "none" : undefined,
                },
              }}
            >
              <VictoryBar data={chartData} barWidth={10} cornerRadius={5} />
            </VictoryGroup>
          );

        default:
          return (
            <VictoryArea
              animate={{ duration: 200 }}
              interpolation={interpolation || "linear"}
              style={{
                data: {
                  fill: `url(#${color || "blue"})`,
                  stroke: "#23AED2",
                  strokeWidth: 1,
                  display: isHidden ? "none" : undefined,
                },
              }}
              data={chartData}
            />
          );
      }
    },
    [color, interpolation, type, hiddenChartsIndexes],
  );

  return (
    <Box
      ref={chartRef}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <Gradient />
      <VictoryChart
        {...size}
        scale={scale}
        domainPadding={{ x: isHistogram ? 50 : 0, y: 12 }}
        padding={{
          top: legend ? 40 : 8,
          right: isHorizontalAxisDisabled ? 0 : 24,
          bottom: isHorizontalAxisDisabled ? 0 : xAxisLabel ? 50 : 28,
          left: (yAxisLabel ? 75 : 48) + maxYSize * (labelSize === "large" ? 4 : 2),
        }}
        minDomain={{ y: startY ?? minY - Math.abs(minY) * yDomainGap }}
        maxDomain={{ y: maxY + Math.abs(maxY) * yDomainGap }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }: { datum: { x: string; y: string; legend?: string } }) => {
              const yValue = formatAmount(Number(datum.y), {
                currency,
                maximumFractionDigits: fractionDigits,
                notation: "standard",
              });

              if (isHistogram) return datum.y;

              const label = datum.legend ? `${datum.legend}\n` : "";

              switch (horizontalAxisType) {
                case "number": {
                  return `${label}${datum.x} - ${yValue}`;
                }
                case "block": {
                  return `${label}Block: ${datum.x} - ${yValue}`;
                }
                case "date": {
                  return `${label}${dayjs(datum.x).format(
                    labelSize === "small" ? "MM/DD/YY" : "MM/DD/YY HH:mm",
                  )} - ${yValue}`;
                }
                default: {
                  return yValue;
                }
              }
            }}
            labelComponent={chartTooltip}
          />
        }
      >
        <VictoryAxis
          dependentAxis
          fixLabelOverlap
          axisLabelComponent={<VictoryLabel dy={-35} />}
          label={yAxisLabel}
          style={{
            axisLabel: {
              fill: "rgba(255, 255, 255, .5)",
              fontFamily: "Archivo, sans-serif",
            },
            grid: {
              stroke: "rgba(255, 255, 255, .2)",
              strokeWidth: 1,
              strokeDasharray: 4,
            },
            tickLabels: {
              fill: "rgba(255, 255, 255, .5)",
              fontFamily: "Archivo, sans-serif",
              lineHeight: "24px",
              fontSize: labelSize === "small" ? 12 : 16,
            },
            axis: {
              stroke: "transparent",
            },
          }}
          tickFormat={(n: number) =>
            formatAmount(n, {
              currency,
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
              notation: "compact",
            })
          }
        />

        {!isHorizontalAxisDisabled && (
          <VictoryAxis
            fixLabelOverlap
            axisLabelComponent={<VictoryLabel dy={10} />}
            label={xAxisLabel}
            style={{
              axisLabel: {
                fill: "rgba(255, 255, 255, .5)",
                fontFamily: "Archivo, sans-serif",
              },
              tickLabels: {
                fill: "rgba(255, 255, 255, .5)",
                fontFamily: "Archivo, sans-serif",
                lineHeight: "24px",
                fontSize: labelSize === "small" ? 12 : 16,
              },
              axis: {
                stroke: "rgba(255, 255, 255, .2)",
                strokeWidth: 1,
                strokeDasharray: 4,
              },
            }}
            tickFormat={
              horizontalAxisType === "date" ? (t: number) => dayjs(t).format("DD MMM YYYY") : (t: number) => t
            }
            tickValues={isHistogram ? xValues : undefined}
          />
        )}
        {chartsData.map((x, idx) => chartToRender(x, idx, idx % colorScale.length))}
        {legend && (
          <VictoryLegend
            orientation="horizontal"
            colorScale={colorScale}
            data={legend.map((i) => ({
              name: i.toString(),
              labels: {
                fill: "rgba(255, 255, 255, .5)",
                fontFamily: "Archivo, sans-serif",
              },
            }))}
          />
        )}
      </VictoryChart>
    </Box>
  );
};
