import { Box, CryptoIcon, Tooltip, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { PoolTokens } from "./types";

type Props = {
  data: PoolTokens;
};

const PooledTokensRate = ({ data }: Props) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="caption">Pooled Tokens</Typography>
    <Box display="flex" alignItems="space-around" gap={1}>
      <CryptoIcon
        icon={data.token1.symbol.toLowerCase()}
        sx={{
          img: { width: "24px", height: "24px" },
        }}
      />
      <Tooltip title={formatAmount(data.token1.amount!, { notation: "standard" })}>
        <Typography whiteSpace="nowrap">{formatAmount(data.token1.amount!)}</Typography>
      </Tooltip>
      <CryptoIcon
        icon={data.token2.symbol.toLowerCase()}
        sx={{
          img: { width: "24px", height: "24px" },
        }}
      />
      <Tooltip title={formatAmount(data.token2.amount!, { notation: "standard" })}>
        <Typography whiteSpace="nowrap">{formatAmount(data.token2.amount!)}</Typography>
      </Tooltip>
    </Box>
  </Box>
);

export default PooledTokensRate;
