import { FC, ReactNode, createContext, useMemo, useState, Dispatch, SetStateAction, useContext } from "react";
import { AssetFragmentFragment, useMarketAssetsBreakdownQuery } from "src/pages/ccar-lending-page/generated";

export type ListedAssetsContextType = {
  listedAssets?: AssetFragmentFragment[];
  isLoading: boolean;
  marketId?: string;
  setMarketId: Dispatch<SetStateAction<string | undefined>>;
};

const initialState: ListedAssetsContextType = {
  isLoading: false,
  setMarketId: () => {},
};

export const MarketListedAssetsContext = createContext<ListedAssetsContextType>(initialState);

export const useMarketListedAssetsContext = () => useContext(MarketListedAssetsContext);

const MarketListedAssetsProvider: FC<ReactNode> = ({ children }) => {
  const [marketId, setMarketId] = useState<string>();

  const { loading, data } = useMarketAssetsBreakdownQuery({
    variables: {
      marketId: marketId || null,
      chain: null,
      tokenSymbol: null,
    },
    skip: !marketId,
  });

  const contextState: ListedAssetsContextType = useMemo(
    () => ({
      listedAssets: data?.assetsBreakdown,
      isLoading: loading,
      marketId,
      setMarketId,
    }),
    [marketId, data, loading],
  );

  return <MarketListedAssetsContext.Provider value={contextState}>{children}</MarketListedAssetsContext.Provider>;
};

export default MarketListedAssetsProvider;
