import { Link } from "react-router-dom";
import { Box } from "@frontend/ui";
import { TagChip } from "@frontend/ui/tag-chip";
import { clientViewProps } from "src/pages/community-analytics-page/client-view-props";
import { ProtocolSwitcher } from "../protocol-switcher";

type Props = {
  homePath: string;
  isMobile: boolean;
  shouldShowProtocolSwitcher: boolean;
};

export const ClientLogo = ({ homePath, isMobile, shouldShowProtocolSwitcher }: Props) => {
  const clientsProps = clientViewProps();

  if (!clientsProps) return null;

  if (shouldShowProtocolSwitcher) {
    return <ProtocolSwitcher />;
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Link to={homePath}>
        <img
          style={{ display: "block", height: isMobile ? 20 : "auto" }}
          src={clientsProps?.headerProps.clientLogo}
          alt="header"
        />
      </Link>
      {clientsProps?.headerProps?.logoChip && (
        <TagChip
          sx={{
            bgcolor: "#22252C",
            borderRadius: "120px",
          }}
          label={clientsProps.headerProps.logoChip}
        />
      )}
    </Box>
  );
};
