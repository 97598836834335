import { renderToString } from "react-dom/server";
import { TooltipComponentFormatterCallbackParams } from "echarts";
import { Box, Typography } from "@frontend/ui";
import { formatCurrency } from "@frontend/ui/utils/formatters";
import { palette } from "@frontend/ui/theme/palette";
import { BandFragment } from "../../generated";

type ParamType = { axisValue: number };

export const liquidationBandsTooltipFormatter =
  (
    bandsByIndex: Record<string, BandFragment>,
    borrowAsset: string,
    collateralAsset: string,
    currentPrice: number,
    currentBandIndex: number,
  ) =>
  (params: TooltipComponentFormatterCallbackParams) => {
    const paramsArr = Array.isArray(params) ? params : [params];
    const index = Number((paramsArr[0] as unknown as ParamType)?.axisValue);
    const currentBand = bandsByIndex[index];

    const borrowColor = paramsArr[1].color;
    const collateralColor = paramsArr[0].color;

    const content = (
      <Box display="grid" gap={0.5}>
        <Box display="grid" gridTemplateColumns="1fr" gap={1}>
          <Box display="flex" gap={1} alignItems="flex-start">
            <Box
              sx={{
                backgroundColor: String(borrowColor),
                width: 8,
                height: 8,
                marginTop: 0.75,
              }}
            />
            <Typography component="div" variant="caption">
              <Box sx={{ color: palette.almostWhite.main }}>{borrowAsset}</Box>
              {formatCurrency(currentBand.stableCoin)}
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="flex-start">
            <Box
              sx={{
                backgroundColor: String(collateralColor),
                width: 8,
                height: 8,
                marginTop: 0.75,
              }}
            />
            <Typography component="div" variant="caption">
              <Box sx={{ color: palette.almostWhite.main }}>{collateralAsset}</Box>
              {currentBand.collateral.toFixed(4)} ≈ {formatCurrency(currentBand.collateralUSD)}
            </Typography>
          </Box>
          <Typography component="div" variant="caption">
            <Box sx={{ color: palette.almostWhite.main }}>Band: {currentBand.index}</Box>
            {formatCurrency(currentBand.lowerBoundPriceUSD)} - {formatCurrency(currentBand.upperBoundPriceUSD)}
          </Typography>
          {currentBandIndex === index && (
            <Typography component="div" variant="caption">
              <Box sx={{ color: palette.almostWhite.main }}>Current {collateralAsset} Price (USD)</Box>
              {formatCurrency(currentPrice)}
            </Typography>
          )}
        </Box>
      </Box>
    );

    return renderToString(content);
  };
