import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { WalletsAtRiskTable } from "src/components/wallets-at-risk-table";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { RiskExplorerQuery, useRiskExplorerLiquidatedWalletsQuery } from "../../../generated";
import { Client } from "../../../types";
import { ChainsRiskTable } from "./chains-risk-table";

type Props = {
  dataCut: RiskExplorerQuery[];
};

const ExplorerTables = ({ dataCut }: Props) => {
  const { clientName } = useParams<{ clientName: Client }>();
  const { riskThreshold, eligibleThreshold } = useClientConfig();
  const { data, loading } = useRiskExplorerLiquidatedWalletsQuery({
    variables: {
      query: dataCut,
      becameEligibleOnly: false,
    },
  });
  const wallets = data?.riskExplorerLiquidatedWallets;
  const walletsHandleOnClickRow = (rowIdx: number) => {
    const walletId = wallets?.[rowIdx]?.address;
    return RoutePath.Risk.WalletDetails.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.Address,
      walletId || "",
    );
  };

  return (
    <>
      <ChainsRiskTable
        wallets={wallets || []}
        riskThreshold={riskThreshold}
        eligibleThreshold={eligibleThreshold}
        isLoading={loading}
      />
      <WalletsAtRiskTable
        wallets={wallets || []}
        description="Wallets that will be at risk or eligible for liquidation."
        rowHref={walletsHandleOnClickRow}
        isLoading={loading}
      />
    </>
  );
};

export default ExplorerTables;
