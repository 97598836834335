import { Box } from "@frontend/ui";
import { NodeOperatorCards, AvsTable } from "./components";

const NodeOperatorDetails = () => (
  <Box display="flex" flexDirection="column" gap={3}>
    <NodeOperatorCards />
    <AvsTable />
  </Box>
);

export default NodeOperatorDetails;
