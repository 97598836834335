import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { SortChangeHandler } from "@frontend/ui/dist/hooks/use-pagination";
import { Filter, FilterPicker } from "@frontend/ui";
import { SortOrder, useRiskAlertsLazyQuery, InternalAlertsQuery, InternalAlert } from "../../generated";

export type AlertFilteringTypes = "All" | "Actionable" | "Non-Actionable";

const PAGES_TO_FETCH = 15;

interface Props {
  setResetPagination: (uuid: string) => void;
  pageSize: number;
}

export const useAlertsData = ({ setResetPagination, pageSize }: Props) => {
  const [query, setQuery] = useState<InternalAlertsQuery>({
    page: 0,
    order: SortOrder.Descending,
    alertsPerPage: pageSize * PAGES_TO_FETCH,
    sortByCol: null,
    filter: {
      groups: null,
      assets: null,
      chain: null,
      marketId: null,
      search: null,
      ccarClients: null,
      isActionable: null,
    },
  });
  const [loadAlerts, { loading }] = useRiskAlertsLazyQuery();
  const [alerts, setAlerts] = useState<InternalAlert[]>([]);

  const setActionable = useCallback(
    (isActionable: AlertFilteringTypes) => {
      setQuery((q) => ({
        page: 0,
        order: SortOrder.Descending,
        alertsPerPage: pageSize * PAGES_TO_FETCH,
        sortByCol: null,
        filter: {
          groups: q.filter?.groups ?? null,
          assets: q.filter?.assets ?? null,
          chain: q.filter?.chain ?? null,
          marketId: q.filter?.marketId ?? null,
          isActionable: isActionable === "All" ? null : isActionable === "Actionable",
          search: q.filter?.search ?? null,
          ccarClients: q.filter?.ccarClients ?? null,
        },
      }));
      setResetPagination(uuidv4());
    },
    [setResetPagination, pageSize],
  );

  useEffect(() => {
    void loadAlerts({ variables: { query } }).then(({ data }) => {
      if (data?.internalRiskAlerts) {
        setAlerts((currentAlerts) =>
          query.page === 0
            ? (data.internalRiskAlerts as InternalAlert[])
            : [...currentAlerts, ...(data.internalRiskAlerts as InternalAlert[])],
        );
      }
    });
  }, [loadAlerts, query]);

  const onPageChange = useCallback(
    (page: number) => {
      if (alerts && pageSize * page === alerts.length) {
        setQuery((q) => ({ ...q, page: page + 1, alertsPerPage: pageSize * PAGES_TO_FETCH }));
      }
    },
    [alerts, pageSize],
  );

  const onSortChange: SortChangeHandler = useCallback(
    (sort, order) => {
      setQuery((q) =>
        q.sortByCol === sort &&
        ((q.order === SortOrder.Ascending && order === 1) || (q.order === SortOrder.Descending && order === -1))
          ? q
          : {
              ...q,
              page: 0,
              sortByCol: sort,
              order: order === 1 ? SortOrder.Ascending : SortOrder.Descending,
            },
      );
      setResetPagination(uuidv4());
    },
    [setResetPagination],
  );

  const onSearch = useCallback(
    (text: string) => {
      setQuery((q) => ({
        ...q,
        page: 0,
        filter: {
          groups: q.filter?.groups ?? null,
          assets: q.filter?.assets ?? null,
          chain: q.filter?.chain ?? null,
          marketId: q.filter?.marketId ?? null,
          isActionable: q.filter?.isActionable ?? null,
          search: text || null,
          ccarClients: q.filter?.ccarClients ?? null,
        },
      }));
      setResetPagination(uuidv4());
    },
    [setResetPagination],
  );

  const onFiltersChange = useCallback(
    (updatedFilter: Filter[]) => {
      const protocolFilter = updatedFilter.find((f) => f.fieldName === "ccarClient") as FilterPicker;
      const groupFilter = updatedFilter.find((f) => f.fieldName === "group") as FilterPicker;

      const protocols = protocolFilter?.options.filter((opt) => !opt.isHidden).map((opt) => opt.value!);
      const groups = groupFilter?.options.filter((opt) => !opt.isHidden).map((opt) => opt.value!);

      setQuery((q) => ({
        ...q,
        page: 0,
        filter: {
          groups: groups?.length ? groups : null,
          assets: q.filter?.assets ?? null,
          chain: q.filter?.chain ?? null,
          marketId: q.filter?.marketId ?? null,
          isActionable: q.filter?.isActionable ?? null,
          search: q.filter?.search ?? null,
          ccarClients: protocols?.length ? protocols : null,
        },
      }));
      setResetPagination(uuidv4());
    },
    [setResetPagination],
  );

  return {
    loading,
    alerts,
    onPageChange,
    onSortChange,
    onSearch,
    onFiltersChange,
    filter: query.filter,
    setActionable,
  };
};
