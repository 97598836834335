import { Paper, Box, Typography, Grid } from "@frontend/ui";
import { TreemapChart } from "@frontend/ui/charts";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { Client } from "src/pages/ccar-lending-page/types";
import { MarketWalletsDistribution } from "../types";

type Props = {
  asset: string;
  walletsDistribution: MarketWalletsDistribution;
};

const MarketWalletDistribution = ({ asset, walletsDistribution }: Props) => {
  const { clientName } = useParams<{ clientName: Client }>();
  return (
    <Paper variant="card">
      <Grid container spacing={3}>
        {walletsDistribution.suppliers.length > 0 && (
          <Grid item key="supply" xs={12} md={walletsDistribution.borrowers.length ? 6 : 12} gap={3}>
            <Typography variant="h2" mb={2}>{`Top Suppliers of ${asset}`}</Typography>
            <Paper
              sx={{
                boxShadow: "none",
                padding: "16px",
                borderRadius: "16px",
                backgroundColor: "#22252C",
              }}
            >
              <TreemapChart
                height={472}
                identity="id"
                data={walletsDistribution.suppliers}
                onClick={(id) => {
                  if (id !== "Others") {
                    window.open(
                      RoutePath.Risk.WalletDetails.replace(RouteParams.ClientName, clientName!).replace(
                        RouteParams.Address,
                        id,
                      ),
                    );
                  }
                }}
              />
            </Paper>
          </Grid>
        )}
        {walletsDistribution.borrowers.length > 0 && (
          <Grid item key="borrow" xs={12} md={walletsDistribution.suppliers.length ? 6 : 12} gap={3}>
            <Typography variant="h2" mb={2}>{`Top Borrowers of ${asset}`}</Typography>
            <Paper
              sx={{
                boxShadow: "none",
                padding: "16px",
                borderRadius: "16px",
                backgroundColor: "#22252C",
              }}
            >
              <TreemapChart
                height={472}
                identity="id"
                data={walletsDistribution.borrowers}
                onClick={(id) => {
                  if (id !== "Others") {
                    window.open(
                      RoutePath.Risk.WalletDetails.replace(RouteParams.ClientName, clientName!).replace(
                        RouteParams.Address,
                        id,
                      ),
                    );
                  }
                }}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
      <Box display="flex" flexWrap="wrap" alignItems="row" justifyContent="space-around" gap={6} />
    </Paper>
  );
};

export default MarketWalletDistribution;
