import { TokenPairCell } from "@frontend/ui/chaos-table/custom-cells/token-pair-cell";
import { PoolTokensPairProps } from "../pool-tokens-pair";

type PoolNameCellProps = {
  poolId: string | number;
} & PoolTokensPairProps;

const PoolHeaderCell = ({ token1, token2, poolId }: PoolNameCellProps) => (
  <TokenPairCell token1={token1} token2={token2} subTitle={`Pool #${poolId}`} />
);

export default PoolHeaderCell;
