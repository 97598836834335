import { Chain } from "../../generated";
import { CCARClientConfig } from "../types";

export const aaveV2Config: CCARClientConfig = {
  chains: [Chain.Ethereum, Chain.Avalanche, Chain.Polygon],
  riskThreshold: 1.2,
  eligibleThreshold: 1,
  isMultichain: true,
  isMultiMarket: true,
  marketType: "ChainMarket",
  endpointsPrefix: "aavev2",
  overview: {
    cards: [
      "totalSupplyUsd",
      "totalBorrowUsd",
      "totalValueLockedUsd",
      "totalValueAvailableForLiquidationUsd",
      "totalValueAtRiskUsd",
      "numberOfWalletsAtRisk",
      "numberOfWalletsAvailableForLiquidation",
      "totalPotentialBadDebtUsd",
    ],
    charts: ["historyStatsTvl", "chainHistoryStats"],
    chainTableConfig: {
      columns: [
        "chain",
        "totalSupplyUsd",
        "totalBorrowUsd",
        "totalValueLockedUsd",
        "totalValueAtRiskUsd",
        "activeUsers",
      ],
    },
  },
  wallets: {
    hiddenRows: {
      badDebt: true,
    },
  },
  assetDetails: {
    tables: [],
    charts: [],
  },
  walletDetails: {
    tables: ["recentEvents", "totalSupplyAndBorrow"],
  },
  marketDetails: {
    tables: ["wallets", "assetEvents"],
    charts: [
      "historicalPrices",
      "collateralAtRisk",
      "supplyWalletsHealth",
      "borrowWalletsHealth",
      "marketsDistribution",
      "topWallets",
    ],
    liquidationThresholdLabel: "Loan to Value",
  },
  liquidations: {
    showLiquidationPie: false,
    showLiquidationEvents: true,
  },
  riskExplorer: {
    liquidationThresholdLabel: "Liquidation Threshold",
    showAdvancedSettings: true,
  },
  markets: {
    overview: {
      showEventsTable: true,
    },
  },
  alerts: {
    enabled: true,
    notificationsChannels: [
      {
        channel: "telegram",
        link: "https://t.me/+lQsW50s5IgJkZmQx",
      },
    ],
  },
};
