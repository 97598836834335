import { CustomSwitch } from "@frontend/ui";

type Props = {
  asset: string;
  showTvl: boolean;
  setShowTvl: React.Dispatch<React.SetStateAction<boolean>>;
};
const TvlBalanceToggle = ({ asset, showTvl, setShowTvl }: Props) => (
  <CustomSwitch
    onChange={() => setShowTvl((prev) => !prev)}
    checked={showTvl}
    uncheckedLabel={`${asset} Balance`}
    checkedLabel="TVL"
  />
);

export default TvlBalanceToggle;
