import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { NodeOperatorsCards, NodeOperatorsTable, NodeOperatorsCharts } from "./components";

const NodeOperators = () => (
  <Box>
    <PageHeader showBackButton={false} pageTitle="Node Operators" />
    <Box display="flex" flexDirection="column" gap={3}>
      <NodeOperatorsCards />
      <NodeOperatorsTable />
      <NodeOperatorsCharts />
    </Box>
  </Box>
);

export default NodeOperators;
