import { Box, Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { TwapPoolFragment } from "src/pages/uniswap/generated";

type Props = {
  pool: TwapPoolFragment;
};

const Cards = ({ pool }: Props) => {
  const cards: ValueCardProps[] = [
    {
      title: "TVL",
      value: pool.total_amount_locked_usd,
      currency: "USD",
    },
    {
      title: "Volume 24H",
      value: pool.volume24USD ?? "-",
      currency: "USD",
    },
    {
      title: "Fees 24H",
      value: pool.fees24USD ?? "-",
      currency: "USD",
    },
    {
      title: `${pool.token0.symbol} Exhaustion`,
      value: pool.token0.exhaustionPriceInOtherToken * pool.token1.priceUSD,
      currency: "USD",
      tooltip: `Capital required for buying all ${pool.token0.symbol} liquidity from the pool`,
    },
    {
      title: `${pool.token1.symbol} Exhaustion`,
      value: pool.token1.exhaustionPriceInOtherToken * pool.token0.priceUSD,
      currency: "USD",
      tooltip: `Capital required for buying all ${pool.token1.symbol} liquidity from the pool`,
    },
  ];
  return (
    <Box>
      <Grid container>
        {cards.map((cardProps) => (
          <Grid key={cardProps.title} item xs={12} sm={3} lg={12 / 5}>
            <ValueCard {...cardProps} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Cards;
