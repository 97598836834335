import { Typography } from "@frontend/ui";
import { usePegKeepersContext } from "../../../../context/peg-keepers-context";
import PegKeeperOverviewCard from "./peg-keeper-overview-card";
import PegKeeperEvents from "./peg-keeper-events";

type Props = {
  poolAddress: string;
};

const PegKeeperSection = ({ poolAddress }: Props) => {
  const { pegKeepers } = usePegKeepersContext();
  const pegKeeper = pegKeepers.find((p) => p.poolAddress === poolAddress);

  if (!pegKeeper) return null;

  return (
    <>
      <Typography variant="h3">Peg Keeper</Typography>
      <PegKeeperOverviewCard pegKeeper={pegKeeper} />
      <PegKeeperEvents poolAddress={poolAddress} />
    </>
  );
};

export default PegKeeperSection;
