import { addressCell, ChaosTable, ControlledFilters, currencyCell, currencyTrendCell, numberCell } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { MarketFragment, PositionFragment, SortPositionsBy } from "../../../../generated";
import { positionSideCell } from "../../../positions/cells";
import { usePositionsData } from "../../../positions/use-postions-data";
import { useClientConfig } from "../../../../clients-config";

const formatRow = (
  { account, side, balanceUsd, leverage, totalBasePnlUsd, pnl }: PositionFragment,
  useBasePnl: boolean,
): RenderData[] => [
  addressCell(account),
  positionSideCell(side),
  currencyCell(balanceUsd / leverage),
  numberCell(leverage),
  currencyCell(balanceUsd),
  currencyTrendCell(useBasePnl ? totalBasePnlUsd : pnl),
];

const tableHeader = (useBasePnl: boolean): Header[] => [
  {
    renderType: "TEXT",
    text: "Account",
    field: SortPositionsBy.Account,
  },
  {
    renderType: "TEXT",
    text: "Type",
    nonSortable: true,
  },
  {
    renderType: "CURRENCY",
    unit: "usd",
    text: "Collateral",
    field: SortPositionsBy.CollateralBalanceUsd,
  },
  {
    renderType: "TEXT",
    text: "Leverage",
    field: SortPositionsBy.Leverage,
    tooltip: "Average leverage",
  },
  {
    renderType: "TEXT",
    text: "Size",
    field: SortPositionsBy.BalanceUsd,
  },
  {
    renderType: "TEXT",
    text: useBasePnl ? "Gross RPnL" : "PnL",
    field: useBasePnl ? SortPositionsBy.TotalBasePnlUsd : SortPositionsBy.Pnl,
  },
];

type PositionsTableProps = {
  market?: MarketFragment;
};

const pageSize = 15;

export const PositionsTable = ({ market }: PositionsTableProps) => {
  const { clientName } = useParams<{ clientName: string }>();
  const {
    positions: { useBasePnl },
  } = useClientConfig();
  const { positions, loading, filters, onFiltersChange, onSortChange, onPageChange } = usePositionsData({
    market,
    sort: SortPositionsBy.Pnl,
    open: true,
  });

  if (!market) {
    return null;
  }

  return (
    <ChaosTable
      isLoading={loading}
      isFullHeight
      showSearch={false}
      pageSize={pageSize}
      customFilter={<ControlledFilters filters={filters} onChange={onFiltersChange} />}
      title="Positions"
      description={`Accounts which have open long or short positions for ${market.name}`}
      headers={tableHeader(!!useBasePnl)}
      data={positions.map((p) => formatRow(p, !!useBasePnl))}
      onSortChange={onSortChange}
      onPageChange={onPageChange}
      emptyState={{
        icon: "chaos",
        title: "No positions found",
      }}
      rowHref={(rowId) => {
        const account = positions[rowId]?.account;
        const subAccount = positions[rowId]?.subAccount;

        if (subAccount !== undefined && subAccount !== null) {
          return RoutePath.Risk.SubAccountPositions.replace(RouteParams.ClientName, clientName!)
            .replace(RouteParams.Address, account)
            .replace(RouteParams.SubAccount, subAccount.toString());
        }

        return RoutePath.Risk.AccountPositions.replace(RouteParams.ClientName, clientName!).replace(
          RouteParams.Address,
          account,
        );
      }}
      rowHrefTarget="_blank"
      showRowChevron
      isFilterHidden
      metadata={market.name}
      initialSortBy={5}
      isInitialSortDesc
    />
  );
};
