import { ReactNode } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { CustomIcon } from "../../custom-icon";
import { CryptoIcon } from "../../crypto-icon";
import { CHART_ACTIONS_ID } from "./export-utils";

type Props = {
  title?: string;
  titleCryptoIcon?: string;
  description?: string | ReactNode;
  tooltip?: string;
  children?: ReactNode;
  exportToCsv?: () => void;
};

export const ChartHeader = ({ title, titleCryptoIcon, description, tooltip, children, exportToCsv }: Props) => (
  <Box
    display="flex"
    alignItems="flex-start"
    justifyContent="space-between"
    flex={1}
    p={3}
    sx={{
      bgcolor: "greys.900",
    }}
  >
    <Box>
      {title && (
        <Box display="flex" alignItems="stretch" gap={1}>
          <Box display="flex" alignItems="center" gap={2}>
            {titleCryptoIcon && <CryptoIcon icon={titleCryptoIcon} />}
            <Typography variant="h3">{title}</Typography>
          </Box>
          {tooltip && (
            <Tooltip title={tooltip} placement="right-end">
              <CustomIcon icon="info" />
            </Tooltip>
          )}
        </Box>
      )}
      {typeof description === "string" ? <Typography variant="h5">{description}</Typography> : description}
    </Box>
    <Box display="flex" alignItems="center" justifyContent="space-after" mb={0} id={CHART_ACTIONS_ID}>
      {children}
      {exportToCsv && (
        <IconButton size="small" onClick={exportToCsv} sx={{ ml: 2 }}>
          <Tooltip title="Export to CSV">
            <CustomIcon icon="export" />
          </Tooltip>
        </IconButton>
      )}
    </Box>
  </Box>
);
