import { ReactNode, useState } from "react";
import { RouteParams, RoutePath } from "src/config/routes";
import { useNavigate } from "react-router-dom";
import { CommunityDashboardLayout } from "src/components/layouts";
import { SearchBarProps } from "@frontend/ui/search-bar";

type Props = {
  children: ReactNode;
};

const WalletsSearchLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const onSearch = (value: string) => {
    navigate(
      RoutePath.LpDashboard.WalletDetails.replace(RouteParams.Address, value).replace(
        RouteParams.ClientName,
        "osmosis",
      ),
    );
  };

  const searchBarProps: SearchBarProps = {
    fullWidth: true,
    placeholder: "Osmosis Wallet Address",
    value: searchValue,
    onChange: onSearchChange,
    onSearch,
    sx: { mb: 1 },
  };

  return <CommunityDashboardLayout searchBarProps={searchBarProps}>{children}</CommunityDashboardLayout>;
};

export default WalletsSearchLayout;
