import { Box, Tooltip, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { PositionData } from "src/pages/uniswap/generated";

type Props = {
  title: string;
  tooltip?: string;
  token0: string;
  token1: string;
  data: PositionData;
};

const PositionDetails = ({ title, tooltip, token0, token1, data }: Props) => (
  <Box display="flex" flexDirection="column">
    <Tooltip title={tooltip || ""}>
      <Typography variant="h6" color="almostWhite.main">
        {title}
      </Typography>
    </Tooltip>
    <Typography>{formatAmount(data.value, { currency: "USD", notation: "standard" })}</Typography>
    <Typography>{`${formatAmount(data.amount_token0, { notation: "standard" })} ${token0}`}</Typography>
    <Typography>{`${formatAmount(data.amount_token1, { notation: "standard" })} ${token1}`}</Typography>
  </Box>
);

export default PositionDetails;
