import { ChaosTable, numberCell, textCell, labelsCell } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { usePegKeeperEventsQuery } from "src/pages/ccar-lending-page/generated";
import { formatDate } from "@frontend/ui/utils/formatters";

type Props = {
  poolAddress: string;
};

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Timestamp",
  },
  {
    renderType: "TEXT",
    text: "Block Number",
  },
  {
    renderType: "TEXT",
    text: "Action",
  },
  {
    renderType: "TEXT",
    text: "Amount",
  },
];

const PegKeeperEvents = ({ poolAddress }: Props) => {
  const { loading, data } = usePegKeeperEventsQuery({
    variables: {
      poolAddress,
    },
  });

  const tableData: RenderData[][] = (data?.pegKeeperEvents || []).map(({ timestamp, blockNumber, type, amount }) => [
    textCell(formatDate(new Date(timestamp * 1000)), timestamp),
    textCell(`${blockNumber}`, blockNumber),
    labelsCell("INFO", type.replace("PegKeeper-", "")),
    numberCell(amount),
  ]);

  return (
    <ChaosTable
      title="Peg Keeper Events"
      data={tableData}
      headers={headers}
      isLoading={loading}
      initialSortBy={1}
      isInitialSortDesc
      pageSize={20}
    />
  );
};

export default PegKeeperEvents;
