import { PieChart } from "@frontend/ui";
import { JlpPoolData } from "../../../generated";

type Props = {
  data?: JlpPoolData;
  isLoading: boolean;
};

const PoolAssets = ({ data, isLoading }: Props) => (
  <PieChart
    title="Pool Assets Distribution"
    series={[
      {
        data:
          data?.liquidityAllocations.map(
            (allocation) => [allocation.tokenSymbol, allocation.sizeUsd] as [string, number],
          ) || [],
      },
    ]}
    showLegendCryptoIcon
    isLoading={isLoading}
  />
);

export default PoolAssets;
