import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { TabProps } from "@frontend/ui";

const tabToPath = (tab: string): string => tab.replace(/ /g, "-").toLowerCase();

export type NavigationTabProps = TabProps & {
  path?: string;
  isActive?: (pathname: string) => boolean;
};

export const useTabsNavigations = (tabs?: NavigationTabProps[]) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const activeTabIdx = useMemo(
    () =>
      tabs?.findIndex((tab) => {
        if (tab.isActive) return tab.isActive(pathname);

        if (tab.path) {
          return pathname.includes(tab.path);
        }
        return pathname.includes(tab.label.replace(" ", "-").toLowerCase());
      }) || 0,
    [pathname, tabs],
  );

  const [tabIdx, setTabIdx] = useState(activeTabIdx);

  useEffect(() => setTabIdx(activeTabIdx), [activeTabIdx]);

  const handleTabChange = (_event: React.SyntheticEvent, newTabIdx: number) => {
    if (!tabs) return;

    const tab = tabs[newTabIdx];

    navigate(tab.path || pathname.replace(/[^/]*$/, tabToPath(tab.label)));
  };

  return {
    activeTabIdx: tabIdx,
    handleTabChange,
  };
};
