import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { Chain, ChainAssetFragment, ChainHistoryStatsDataPointFragment } from "src/pages/ccar-lending-page/generated";

type AssetsSeriesDataType = {
  totalBorrowUsd: [number, number][];
  totalSupplyUsd: [number, number][];
  borrowApy: [number, number][];
  supplyApy: [number, number][];
  totalEModeBorrowUsd: [number, number][];
  totalEModeSupplyUsd: [number, number][];
  borrowAgainstDistribution: Record<string, [number, number][]>;
  collateralDistribution: Record<string, [number, number][]>;
};

export type DataKey = keyof AssetsSeriesDataType;

export const mapHistoryStatsPointsToSeriesData = (
  points: ChainHistoryStatsDataPointFragment[],
  isValuesInUSD: boolean,
  marketId?: string,
  chain?: Chain,
) =>
  points.reduce((assetsData: Record<string, AssetsSeriesDataType>, datum) => {
    const { result, date } = datum;

    const assets = marketId
      ? result
          .reduce((acc, res) => [...acc, ...(res.assets || [])], [] as ChainAssetFragment[])
          .filter((a) => a.marketId === marketId)
      : result.find((res) => res.chain === chain)?.assets;

    if (!assets) return assetsData;

    const newAssetsData = { ...assetsData };

    assets.forEach(
      ({
        symbol,
        totalBorrowUsd,
        totalSupplyUsd,
        totalBorrowToken,
        totalSupplyToken,
        borrowApy,
        supplyApy,
        totalEModeBorrowUsd,
        totalEModeSupplyUsd,
        totalEModeBorrowToken,
        totalEModeSupplyToken,
        borrowAgainstDistribution,
        collateralDistribution,
      }) => {
        const key = symbol;
        if (!key?.length) {
          return;
        }
        const currentAssetData = { ...(newAssetsData[key] || {}) };

        const borrowValue = isValuesInUSD ? totalBorrowUsd : totalBorrowToken;
        const supplyValue = isValuesInUSD ? totalSupplyUsd : totalSupplyToken;
        const eModeBorrowValue = isValuesInUSD ? totalEModeBorrowUsd : totalEModeBorrowToken;
        const eModeSupplyValue = isValuesInUSD ? totalEModeSupplyUsd : totalEModeSupplyToken;

        const timestamp = date * 1000;

        currentAssetData.totalBorrowUsd = [...(currentAssetData.totalBorrowUsd || []), [timestamp, borrowValue || 0]];
        currentAssetData.totalSupplyUsd = [...(currentAssetData.totalSupplyUsd || []), [timestamp, supplyValue || 0]];

        currentAssetData.borrowApy = [...(currentAssetData.borrowApy || []), [timestamp, borrowApy || 0]];
        currentAssetData.supplyApy = [...(currentAssetData.supplyApy || []), [timestamp, supplyApy || 0]];
        currentAssetData.totalEModeBorrowUsd = [
          ...(currentAssetData.totalEModeBorrowUsd || []),
          [timestamp, eModeBorrowValue || 0],
        ];
        currentAssetData.totalEModeSupplyUsd = [
          ...(currentAssetData.totalEModeSupplyUsd || []),
          [timestamp, eModeSupplyValue || 0],
        ];

        Object.values(borrowAgainstDistribution || []).forEach((borrowAgainst) => {
          if (!currentAssetData.borrowAgainstDistribution) currentAssetData.borrowAgainstDistribution = {};
          const { asset: borrowAsset, value } = borrowAgainst;
          const currentBorrowAssetData = currentAssetData.borrowAgainstDistribution?.[borrowAsset] || [];
          const newPoint = [timestamp, value] as [number, number];
          currentAssetData.borrowAgainstDistribution[borrowAsset] = [...currentBorrowAssetData, newPoint];
        });

        Object.values(collateralDistribution || []).forEach((borrowAgainst) => {
          if (!currentAssetData.collateralDistribution) currentAssetData.collateralDistribution = {};
          const { asset: borrowAsset, value } = borrowAgainst;
          const currentBorrowAssetData = currentAssetData.collateralDistribution?.[borrowAsset] || [];
          const newPoint = [timestamp, value] as [number, number];
          currentAssetData.collateralDistribution[borrowAsset] = [...currentBorrowAssetData, newPoint];
        });

        newAssetsData[key] = currentAssetData;
      },
    );

    return newAssetsData;
  }, {}) || {};

export const filterZeros = (item: number[]) => item[1] !== 0;

export const mapSeriesData = (
  assetsSeriesData: Record<string, AssetsSeriesDataType>,
  dataKey: DataKey,
  filterAssetsPredicate?: (symbol: string, isEMode?: boolean) => boolean,
  label?: string,
  isFilterZeros?: boolean,
): NumberChartSeries[] =>
  Object.entries(assetsSeriesData)
    .filter(([symbol]) => filterAssetsPredicate?.(symbol, dataKey.includes("EMode")) ?? true)
    .reduce((acc, [symbol, seriesData]) => {
      const data = seriesData[dataKey];

      if (Array.isArray(data)) {
        return [
          ...acc,
          {
            label: label || (dataKey.includes("EMode") ? `${symbol} (e-mode)` : symbol),
            data: Array.isArray(data) ? data.filter((item) => (isFilterZeros ? filterZeros(item) : true)) : [],
          },
        ];
      }

      const newSeries = Object.entries(data).map(([asset, values]) => ({ label: asset, data: values }));
      return [...acc, ...newSeries];
    }, [] as NumberChartSeries[])
    .flat() || [];
