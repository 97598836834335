import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { AvsCards } from "./components/cards";
import { AvsTable } from "./components/avs-table";
import { AvsCharts } from "./components/charts/avs-charts";

const Avs = () => (
  <Box>
    <PageHeader showBackButton={false} pageTitle="AVS" />
    <Box display="flex" flexDirection="column" gap={3}>
      <AvsCards />
      <AvsTable />
      <AvsCharts />
    </Box>
  </Box>
);

export default Avs;
