import { ValueCardProps } from "@frontend/ui/value-card";
import MultiValueCard from "src/components/multi-values-card/multi-values-card";
import { getDeltaFields } from "@frontend/ui/trend";
import { AggregateAssetStatsResultsFragment } from "../../../generated";

type Props = {
  assetStats?: AggregateAssetStatsResultsFragment;
  isLoading: boolean;
};

const MultiValueStatsCard = ({ assetStats, isLoading }: Props) => {
  const { price: currentPrice } = assetStats?.current || {};

  const { price: previousPrice } = assetStats?.previous || {};

  const multiCardValues: ValueCardProps[] = [
    {
      title: "Current Price",
      value: currentPrice,
      currency: "USD",
      ...getDeltaFields(currentPrice, previousPrice, true),
    },
  ];

  return <MultiValueCard isLoading={isLoading} values={multiCardValues} />;
};

export default MultiValueStatsCard;
