import { NumberChartSeries, TimeSpan } from "@frontend/ui/echarts/types";
import { CompositionOverTimeChart } from "@frontend/ui";
import { useCollateralBreakdownHistoryQuery } from "../../../../generated";

type Props = {
  timeSpan: TimeSpan;
};

const CollateralBreakdownChart = ({ timeSpan }: Props) => {
  const { loading, data } = useCollateralBreakdownHistoryQuery({
    variables: {
      timeSpan,
    },
  });

  const seriesByAsset = (data?.collateralBreakdownHistory || []).reduce((acc: Record<string, NumberChartSeries>, h) => {
    const newSeriesEntries: [string, NumberChartSeries][] = h.collateralBreakdown.map((c) => {
      const prevSeries = acc[c.symbol];
      const prevData = prevSeries?.data || [];
      const newPoint = [h.timestamp, c.amountEth] as [number, number];
      const newData = [...prevData, newPoint];

      const series: NumberChartSeries = {
        label: c.symbol,
        data: newData,
      };
      return [c.symbol, series];
    });

    const newSeriesObj = Object.fromEntries(newSeriesEntries);

    return { ...acc, ...newSeriesObj };
  }, {});

  return (
    <CompositionOverTimeChart
      title="Collateral Breakdown Over Time"
      series={Object.values(seriesByAsset)}
      isLoading={loading}
      cryptoCurrency="ETH"
    />
  );
};

export default CollateralBreakdownChart;
