import { Filter } from "@frontend/ui";
import { useCallback, useEffect, useState } from "react";
import { SortChangeHandler } from "@frontend/ui/hooks/use-pagination";
import {
  PerpetualsAccountFragment,
  SortOrder,
  SortAccountsBy,
  usePerpetualsAccountsLazyQuery,
  useMarketsQuery,
} from "../../generated";
import { getAccountsFilters, getOptionsFilterValue } from "./components/accounts-table-filters";

type Props = {
  sort?: SortAccountsBy;
  pageSize?: number;
};

export const useAccountsData = ({ sort, pageSize = 10 }: Props = {}) => {
  const [filters, setFilters] = useState<Filter[]>([]);
  const [accounts, setAccounts] = useState<PerpetualsAccountFragment[]>([]);
  const [query, setQuery] = useState({
    order: SortOrder.Descending,
    sort: sort || SortAccountsBy.TotalPnl,
    skip: 0,
  });
  const [fetchAccounts, { loading }] = usePerpetualsAccountsLazyQuery();
  const [search, setSearch] = useState("");

  useMarketsQuery({
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      const initialFilters = getAccountsFilters({
        assets: data.markets.map((m) => ({
          name: m.name,
          token: m.symbol,
          value: m.symbol,
        })),
      });
      setFilters(initialFilters);
    },
  });

  useEffect(() => {
    const tradedAssetsFilterValue = getOptionsFilterValue(filters, SortAccountsBy.TradedAssets);
    void fetchAccounts({
      variables: {
        input: {
          limit: 100,
          skip: query.skip,
          sort: query.sort,
          order: query.order,
          filter: {
            search: search || null,
            averageLeverage: null,
            averageSize: null,
            positionsCount: null,
            totalPnl: null,
            tradedAssets: tradedAssetsFilterValue?.length ? tradedAssetsFilterValue : null,
          },
        },
      },
    }).then(({ data }) => {
      if (data?.perpetualsAccounts) {
        setAccounts((p) => (query.skip ? [...p, ...data.perpetualsAccounts] : data.perpetualsAccounts));
      }
    });
  }, [fetchAccounts, filters, query.order, query.skip, query.sort, search]);

  const onPageChange = useCallback(
    (page: number) => {
      if (accounts && pageSize * page === accounts.length) {
        setQuery((q) => ({ ...q, skip: accounts.length }));
      }
    },
    [accounts, pageSize],
  );

  const onFiltersChange = useCallback((updatedFilter: Filter[]) => {
    setFilters([...updatedFilter]);
    setQuery((q) => ({ ...q, skip: 0 }));
  }, []);

  const onSortChange: SortChangeHandler = useCallback((sortBy, orderBy) => {
    setQuery({
      skip: 0,
      sort: sortBy as SortAccountsBy,
      order: orderBy === 1 ? SortOrder.Ascending : SortOrder.Descending,
    });
  }, []);
  const onSearch = useCallback((text: string) => setSearch(text.toLowerCase()), []);

  return {
    accounts,
    loading,
    filters,
    onPageChange,
    onFiltersChange,
    onSortChange,
    onSearch,
  };
};
