import ReactMarkdown from "react-markdown";
import { Box, Typography } from "@frontend/ui";
import { usePageTitle } from "src/hooks";

export const PrivacyPage = () => {
  usePageTitle("Privacy Policy");

  return (
    <Box sx={{ li: { m: 1 }, "> p": { my: 1 }, "> * p": { my: 1 } }} p={4}>
      <Typography variant="h1">Privacy Policy</Typography>
      <ReactMarkdown>
        {`**Privacy Policy**

*Last Update:* October 24, 2024

This Privacy Policy ("***Privacy Policy***") sets out how Chaos Labs, Inc. and its affiliates ("***Chaos Labs**,*" "***we***," "***us***," or "***our***") collects and uses any information that you provide to us when using our websites, mobile applications, social media pages, or any other products or services that we offer (collectively referred to as the "***Services***"). When using the Services, including our website located at [https://chaoslabs.xyz/](https://chaoslabs.xyz/) (the "***Site***"), you may be asked to provide certain information by which you can be identified. This Privacy Policy governs how we collect, use, share and otherwise process that information. We reserve the right in our sole discretion to modify this Privacy Policy from time to time. We will provide you with notice of any material changes via the Services, e-mail or other similar means. Your continued use of Services after such updates will confirm your acknowledgment of any modifications. Your use of the Services is governed by our Terms of Service located at [https://community.chaoslabs.xyz/terms](https://community.chaoslabs.xyz/terms) (the “***TOS***”), which incorporates this Privacy Policy. Capitalized terms used herein but not defined will have the meanings given to them in the TOS.

BY USING OR ACCESSING THE SERVICES IN ANY MANNER, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS PRIVACY POLICY, AND YOU HEREBY CONSENT THAT WE MAY COLLECT, USE, AND SHARE YOUR INFORMATION IN THE WAYS DESCRIBED HEREIN.

1. **General.**

   a. **What Does this Policy Cover?** This Privacy Policy covers our treatment of personally identifiable information ("***Personal Information***") that we collect when you are accessing or using our Services (including, for clarity, the Site). We collect various types of Personal Information from our users, and we use this Personal Information internally in connection with our Services, including to personalize, provide and improve our Services. We also collect Personal Information to allow you to set up a user account and profile, to contact you and allow other users to contact you, to fulfill your requests for certain products and services, and to analyze how you use the Services. In certain cases, we may also share some Personal Information with third parties, as described below.

   b. Links to Other Websites, Apps, and Services. The Services may contain links to other third-party websites, apps and services ("***Third-Party Services***"). We have no control over any Third-Party Services and are not responsible for the protection and privacy of any information that you provided while visiting Third Party Services. You should exercise caution and review the privacy policies applicable to the Third-Party Services. They are not governed by this Privacy Policy.

2. **Collection of Your Information.**

   a. General Information. We collect or receive information about your interactions with us through information you provide directly to us, information collected automatically, and information collected from other websites (such as additional information other companies, publicly available information, information about households, or other information that we may append or match to your information collected or received by us). Depending on how you use our Services, your information may include the following, without limitation:

   i. Registration-related information (such as name, addresses, email addresses, telephone numbers, occupation and information imported from social login permissions granted to us);

   ii. Usage-related information which may be collected automatically by us, or through our Services or third-party services within our Services (for example, what Services you use, how often you use them, anything you may publicly share or post using tools made available by our Services, your responses to the offerings and advertisements presented or made available by us) (collectively, "***Usage Data***");

   iii. Technical information (such as your browser or operating system, your manner of connecting to the Internet and the name of your Internet service provider or wireless carrier, your Internet Protocol (IP) address, referring websites or services (websites or services you used immediately prior to using the Site or other Services), exiting websites or services (immediately after using our Site or Services), data relating to malfunctions or problems occurring when you use our Services, and information about other software on your device for the limited purpose of protecting your security or improving your online experience);

   iv. Search-related information (for example, searches you perform on our Site or in our other Services, and how you use the results of those searches);

   v. Transaction-related information (such as credit card or other preferred means of payment, billing information, credit card number, billing address, and/or a history of purchases through our Services);

   vi. Access-related information (such as device, connections, and methods used to access and interact with our Services);

   vii. Communication-related information (such as text messages, emails, faxes, telephone calls, and postal mail from or with Service end users, as well as from third-party outside sources including database vendors); and

   viii. Customer service information about you as a user of our Services.

   b. Information Collected Automatically. Whenever you interact with our Services, we automatically receive and record information from your browser or device. Such Usage Data may include the IP addresses or domain names of the computers utilized by Service end users, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (such as successful outcome and error), the country of origin, the features of the browser and the operating system used by you, the various time details per visit (such as the time spent on each page within the Services), and the details about the path followed within the Services with special reference to the sequence of pages visited, other parameters about the device operating system and/or your IT environment, and data, conversion rates, marketing and conversion data and statistics, reports, analytics data, reviews, and surveys. Usage Data may sometimes be essentially anonymous or aggregated when collected, but could be used indirectly to identify a person.

   c. Tracking Options; Do Not Track Disclosures. Some web browsers incorporate a "Do Not Track" feature that signals to websites and Services that you visit that you do not want to have your online activity tracked. Each browser communicates "Do Not Track" signals to websites differently, making it unworkable to honor each and every request correctly. In order to alleviate any communication error between browsers and our Services, our Services do not support "Do Not Track" signals or requests at this time. As the technology and communication between browser and website improves, we may elect in our discretion to reevaluate the ability to honor "Do Not Track" signals and may make changes to our policy. You may adjust your browser or operating system settings to limit this tracking or to decline cookies, but by doing so, you may not be able to use certain features on the Services or take full advantage of all of our offerings. Check the "Help" menu of your browser or operating system to learn how to adjust your tracking settings or cookie preferences. To learn more about the use of cookies or other technologies to deliver more relevant advertising and your choices about not having this information used by certain Service Providers (defined below), please click here. On your mobile device, you can adjust your privacy and advertising settings to limit your tracking for advertising or control whether you receive more relevant advertising. Note that our systems may not recognize Do Not Track headers or requests from some or all browsers.

   d. Personal Data. In some countries, including in the European Economic Area (EEA), some of this information may be considered "personal data" under applicable data protection laws. Please read on for more details.

3. **Use of Your Information.**

   a. How Your Information May Be Used. Your information may be used for purposes including those set forth below, without limitation:

   i. To operate and improve our Services, including to fulfill your requests for tools, software, functionality, features and other products and services;

   ii. For Usage Data analysis and to conduct research about your use of our Services (for example, we may use Usage Data to provide better service to visitors or end users, customize the Services based on your preferences, compile and analyze statistics and trends about the use of our Services);

   iii. To curate content in response to your usage (for example, to help offer you other products, features, or services that may be of interest, to personalize the content and advertisements provided to you, to present offers to you on behalf of business partners and associates); 

   iv. For other marketing purposes (*e.g.*, to communicate with you, to respond to your inquiries/feedback); and

   v.  To create aggregated, de-identified and/or anonymized data. We may create aggregated, de-identified and/or anonymized data from your Personal Information and other individuals whose Personal Information we collect. We make Personal Information into de-identified and/or anonymized data by removing information that makes the data identifiable to you. We may use this aggregated, de-identified and/or anonymized data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.  

   b. Cookies and similar technologies. We may use cookies, flash cookies, local shared objects, web beacons, pixels, single pixel GIFs, clear GIFs, and/or other technologies along with your information to enhance and personalize your experience and gather information that helps us optimize our Services. You may be able to change the preferences on your browser or device to prevent or limit your device's acceptance of cookies, but this may prevent you from taking advantage of some of our features. This Privacy Policy does not cover the use of cookies by any third parties, and we are not responsible for their respective privacy statements or practices. We use the following types of cookies and similar technologies on the Site and Services:

   i. Session cookies exist only during an online session. They disappear from your computer when you close your browser or turn off your computer. We use session cookies to allow our systems to uniquely identify you during a session or while you are logged into the Site. This allows us to process your online transactions and requests and verify your identity, after you have logged in, as you move through our Site.

   ii. Persistent cookies remain on your computer after you have closed your browser or turned off your computer. We use persistent cookies to track aggregate and statistical information about user activity, and to display advertising on third-party sites.

   iii. Advertising cookies are used to serve you personalized online ads. Your browser is assigned a pseudonymous ID used to track the ads that have been served to your browser and to identify those on which you've clicked. These providers may use cookies to select and serve you ads when you click on ads that appear on the Site or Services and based on your browsing behavior. You can opt out of advertising cookies as described below.

   iv. Analytics cookies are used to compile aggregate and statistical data about site traffic and site interaction so that we can offer a better website experience and tools in the future. We may use third-party service providers to provide these analytics services. We use Google Analytics to provide these analytics services. You can opt out of Google Analytics accessing your information at [https://tools.google.com/dlpage/gaoptout](https://tools.google.com/dlpage/gaoptout).

4. **Sharing of Your Information.**

   a. General Disclosure Policy. We do not rent or sell your Personal Information (*e.g.*, name, address, telephone number, credit card information) to third parties for their marketing purposes. We may share your information with certain third parties to provide products and services that you have requested, when we have your consent, or as otherwise described in this Privacy Policy. We reserve the right to disclose Personal Information as described below. We reserve the right to disclose Usage Data without restriction.

   b. De-Identified Information.** We may de-identify your Personal Information so that you are not identified as an individual, and we may share such aggregated, non-personally identifiable information, publicly or with our partners like publishers, advertisers, or connected websites (*e.g.*, share information publicly to show trends about the general use of our Site and/or Services). 

   c. User Profiles and Submissions. Certain user profile information (including, without limitation, your name, location, and any text, image, or video content that such user has uploaded to the Services) may be displayed to other users to facilitate user interaction or to address your request for our Services. Your account privacy settings may allow you to limit the other users who can see Personal Information in your user profile and/or what information in your user profile is visible to others. Please remember that any content you upload to your public user profile, along with any Personal Information or content that you voluntarily disclose online in a manner in which other users can view (*e.g.*, on discussion boards, in messages or chat areas, etc.) becomes publicly available, and it may be indexable by search engines, or copied, forwarded, saved, or archived by anyone. Your username may also be displayed to other users if and when you send messages or comments, upload text or images, or share features publicly (*e.g.*, a public watchlist) through the Services, and other users may be able to contact you through messages or comments.

   d. Advertisers.

   i. Your information may be used for the presentation of advertisements. We may use ad networks to customize and display advertising on our Services. We may share certain information about you as a user (such as age, zip code, or other information we have collected or received) with certain ad network and service providers to help them deliver more relevant content and advertisements through their networks. We may work with other companies for certain services, such as analytics or advertising, and you may be subject to their privacy statement(s) as well, though you may opt out through them directly.

   ii. To learn more about targeted advertising and advertising networks, or about your ability to opt out of collection of information by certain third parties, please visit the opt-out pages of the [Network Advertising Initiative](http://optout.networkadvertising.org/?c=1) or the [Digital Advertising Alliance](https://optout.aboutads.info/?c=2&lang=EN). Please note that opting out of advertising cookies does not mean that you will not be served ads; you will continue to receive generic ads.

   iii. We may deliver a file to you through the Services from an ad network called web beacons. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Because your web browser must request these advertisements and web beacons from the ad network's servers, these companies can view, edit, or set their own cookies, just as if you had requested a web page from their site. You may be able to opt-out of web beacon tracking conducted by third parties through our Services by adjusting the Do Not Track settings on your browser; please note that we do not control whether or how these third parties comply with Do Not Track requests.

   iv. Our offerings may include features or functionalities provided by third parties, or may integrate or interact with third-party products and services (including, without limitation, via APIs, plug ins, links, frames, embedding, or other interactions). In the process of providing such functionalities within our services, your browser or other tools or technology may send certain information to the third-party provider. The use of these third-party-provided features or functionalities is subject to their own privacy policies. For example, without limitation, we may use a third party for ad serving, retargeting, remarketing and/or for analytics, in which case such third party may have access to your data, subject to its policies. These third-party vendors may use their own cookies and/or other third-party cookies together to (1) inform, optimize, and serve ads across the web based on your past visits to our Services and others, and (2) report to us how your ad impressions, other uses of ad services, and interactions with these ad impressions and ad services are related to visits to our site. If and to the extent from time to time we use Double Click or another Google brand for ad serving, analytics, remarketing, retargeting, etc., you can set certain preferences and opt-outs using Google Ads Settings, and Google Analytics' [currently available opt-outs](https://tools.google.com/dlpage/gaoptout/), found at [https://www.google.com/ads/preferences](https://www.google.com/ads/preferences) and [https://adssettings.google.com](https://adssettings.google.com/).

   e. Affiliated Entities. We reserve the right to provide your personal information and Usage Data to any affiliated entities we may have, including our subsidiaries. Affiliated entities are entities that we control or that control us.

   f. Business Partners. Business partners or other third parties may receive data about groups of our users. In certain situations, businesses or third-party websites we are affiliated with may offer or provide products or services to you through or in connection with the Services (either alone or jointly with us). These affiliated businesses will be identified as such. We may share your Personal Information with these affiliated businesses only to the extent that it is related to such transaction or service; however, we do not sell your personal information to third parties for their marketing purposes. We have no control over the policies and practices of third-party websites or businesses as to privacy or anything else, so if you choose to take part in any transaction or service relating to an affiliated website or business, please review all such related policies.

   g. Service Providers. We reserve the right to provide access to your personal information and Usage Data to our trusted service providers that assist us with the operation and maintenance of our sites and services. We may contract with third parties to process payments, host our servers, provide security and provide production, fulfillment, optimization, analytics, and reporting services. We will use commercially reasonable efforts to ensure that our service providers will be given access to your information only as is reasonably necessary to provide the services for which they are contracted.

   h. Co-Marketers; Cross-Promotion; Sponsorships. We may participate with another company or individual for purposes of jointly promoting our products, services, promotions, or contests, or their products, services, promotions, or contests. We reserve the right to disclose Personal Information to them for purposes of (i) compensation, transaction-processing, fulfillment, and support, and (ii) offering you other products, services, promotions, or contests. These marketing partners may also contact you about other or related products, services, promotions, or contests.

   i. Business Transfers; Successors. We may choose to buy or sell assets and may share or transfer customer information in connection with the evaluation of and entry into such transactions. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information could be one of the assets transferred to or acquired by a third party. If such an action results in a material change in the use of your information, you will be provided notice (which may be via updates to this page) about your ability to decline to permit such a transfer.

   j. Legal Process, Enforcement, and Security Notice. We reserve the right to access, read, preserve, and disclose any Personal Information under the following circumstances: (i) to satisfy any applicable law, regulation, legal process, or enforceable governmental request (*e.g.*, to comply with a subpoena or court order); (ii) to detect, prevent, and address fraud or other illegal activity; and (ii) to investigate, respond to, or enforce violations of our rights, or the security of our Site and Services (including policies within our Terms of Use and other agreements), or that of us, our employees, or our users.**

5. **Access To and Choices About Your Information.**

   a. Accessing Your Information. The information you may view, update, and delete may change as the Services change. Through your account settings, you may access, and, in some cases, edit or delete the following information that you have provided to us: (i) account email address and password; (ii) identifying name, username or legal name; (iii) location; or (iv) user profile information (including text, images, and other content you may have uploaded to the Site).

   b. Your Choices Regarding Your Information. You may choose to restrict the collection or use of your Personal Information in the following ways:

   i. Whenever you are asked to fill in a form on our Site or our other Services, consider what information to include and exclude. Sometimes, there may be a box that you can check or uncheck to indicate that you do not want the information to be used for direct marketing purposes;

   ii. We may provide you with access to your registration and the ability to edit this information in your account settings dashboard and profile pages. Please be aware that even after you delete or update information within our Services, we may not immediately delete residual copies from our active servers and may not remove information from our backup systems. Similarly, if and to the extent any information is indexable by search engines (including, without limitation, public profile information), it may not be updated by such search engines when we update it, and old versions may be archived by them or by third parties outside our control;

   iii. Some of our Services may provide you with additional information and choices about your privacy, which you should review; and

   iv. If you have previously agreed to our using your Personal Information for direct marketing purposes, you may change your mind at any time by writing to us using the contact information below. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally.

   c. Legal Basis. Our legal basis for collecting and using your Personal Information is to do so with your consent, which you may withdraw at any time (where we need the Personal Information for performance of a contract or requested service, or where the collection and use is in our or another party's legitimate interest(s) and not overridden by your data protection interests or fundamental rights and freedoms). In some cases, we may also have a legal obligation to collect the Personal Information in question. You understand and agree that we may collect, use, disclose, and otherwise process any information that you provide, even if you are located outside of the United States.

6. **Additional Protections.**

   a. For Children. The Children's Online Privacy Protection Act ("***COPPA***") requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13. We do not knowingly collect or solicit personally identifiable information from children under 13; if you are a child under 13, please do not attempt to register for the Services or send any Personal Information about yourself to us. If we learn we have collected Personal Information from a child under 13, we will delete that information as soon as commercially possible. If you believe that a child under 13 may have provided us with their Personal Information, please contact us at [info@chaoslabs.xyz](mailto:info@chaoslabs.xyz).

   b. For California Users. We may share your Personal Information with third parties and affiliated third parties (such as local, state, and regional affiliates, and affiliate alliances), for their direct marketing purposes. As these third parties and this category of affiliated third parties are considered an unaffiliated third party under California law, California residents may opt out of our disclosure of Personal Information to third parties for their direct marketing purposes, or ask us for a notice identifying the categories of Personal Information shared with affiliates or third parties for marketing purposes, and contact information for such affiliates or third parties (pursuant to the State's "Shine the Light" law (California Statute Â§ 1798.83)). To opt out or to receive a copy of this notice, please contact us using the contact information below.

7. **For European Economic Area (“*EEA*”) and United Kingdom Users (*“UK*”).**

   a. Legal Basis. If you are a European resident, our legal basis for collecting and using your personal data or information is to do so with your consent, where we need the personal data or information for performance of a contract, or where the collection and use is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect the Personal Information in question. If we collected your personal data or information with your consent, you may withdraw your consent at any time. Our retention of your personal data and any subsequent communications are based on our legitimate interest in providing you with new and potentially relevant materials based on your geography, role, or company. As always, you can elect to opt out from receiving such future communications. To the extent that we transfer Personal Information from the EEA or the UK to a jurisdiction outside the EEA or the UK that has not been determined by the European Commission as providing adequate data protections (such as the United States), we will ensure that such Personal Information is safeguarded through appropriate contractual terms or other approved mechanisms.

   b. Additional Rights. If you are a resident of the EEA or the UK, you have the right to:

   i. Find out if we use your Personal Information, access your personal data or information, and receive copies of your Personal Information;

   ii. Update your personal data or information, or request to have it corrected or amended if it is inaccurate or incomplete;

   iii. Withdraw any express consent that you have provided to the processing of your Personal Information at any time without penalty, including the deletion or the request of deletion of your personal data or information, as long as we have no other legal basis to use as such;

   iv. Restrict the processing of your Personal Information if you believe your personal data or information is inaccurate, no longer necessary for our business purposes, or simply object to our processing of your Personal Information (pending our investigation and/or verification of your claim);

   v. Request portability of your Personal Information for transfer to another provider;

   vi. Object to automated decision-making; and

   vii. Complain to your local data protection authority at any time.

   c. Disclosures. To withdraw consent or exercise the aforementioned rights, please contact us as described below. Please note that withdrawing your consent will not affect the lawfulness of any processing that we may have conducted prior to your withdrawal, nor will it affect processing of your Personal Information conducted in reliance on lawful processing grounds other than consent. If we ask you to provide personal data to us to comply with a legal requirement or enter into a contract, we will inform you of this and let you know whether providing us with your personal data is required, and, if not, the consequences of not sharing your personal data with us. Similarly, if we collect and use your Personal Information in reliance on our or a third party's legitimate interests and those interests are not already listed above, we will inform you of those legitimate interest(s). d. Filing Complaints. If you are not satisfied with our response, or believe we are processing your Personal Information in violation of the law, you have the right to lodge a complaint with the Supervisory Authority (also known as Data Protection Authority) in your EEA country or in the UK. For our operations within the EEA or UK, our Lead Supervisory Authority is the UK Information Commissioner's Office (information [here](https://ico.org.uk/)).

8. **Information Security.**

   a. Our Security Safeguards. We have established commercially reasonable safeguards to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration and destruction, but we cannot guarantee that your information will never be disclosed in a manner inconsistent with this Privacy Policy (*e.g.*, as a result of unauthorized acts by third parties that violate applicable law, or our or our affiliated providers' policies). Please be aware that despite our efforts, no data security measures can guarantee 100% security.

   b. Your Obligations. Your account is protected by a password for your privacy and security. We may use passwords and other technologies to register or authenticate you, and enable you to take advantage of our Services. You must prevent unauthorized access to your account and Personal Information by selecting and protecting your password appropriately, and limiting access to your computer or device and browser by signing off after you have finished accessing your account. We are not responsible for any lost, stolen or compromised passwords, or for any activity on your account via unauthorized password activity.

9. **Data Storage and Retention.**

   a. General Information. Your Personal Information is stored and processed in our servers located in the United States, and may also be stored or processed in other countries by our service providers, if and to the extent compliant with law. You understand and agree that we may collect, use, disclose and otherwise the information you provide as described in this Privacy Policy, even if you are from an area outside the United States. Your Personal Information may be disclosed in response to inquiries or requests from governmental authorities or to respond to judicial process in the United States. We will retain your Personal Information for as long as it is needed to provide you with the Services, or to fulfill any legal or contractual obligations we may have.

   b. Notice for EEA Users. Your Personal Information will be transferred to and processed in the United States, which has data protection laws that are different than those in your country and may not be as protective.

**Contact Us**. If you have any questions or concerns about this Privacy Policy or its implementation, you may reach out to us at [info@chaoslabs.xyz](mailto:info@chaoslabs.xyz). If you believe your information is wrong, we may provide you with ways to update it quickly or to remove it, unless we have to keep that information for legitimate business or legal purposes. If updating your personal information, we may ask you to verify your identity before we can act on your request. We may reject requests that are unreasonably repetitive, risk the privacy of others, require disproportionate technical effort (*e.g*., developing a new system or fundamentally changing an existing practice), or would otherwise be extremely impractical (e.g., requests for information residing in backup storage that is not easily accessible).
`}
      </ReactMarkdown>
    </Box>
  );
};
