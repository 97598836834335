/* eslint-disable react/require-default-props */
import ReactECharts, { EChartsReactProps } from "echarts-for-react";
import type { EChartsOption } from "echarts";
import * as echarts from "echarts";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { Typography } from "../typography";
import { Box } from "../box";
import { useKeyPress } from "../utils/use-key-press";
import { useTheme, useThemeMode } from "../theme";
import { chartTheme } from "./theme";

type Props = Omit<EChartsReactProps, "option"> & {
  zoomable?: boolean;
  onClick?: (x: number, y: number) => void;
  emptyState?: boolean;
  emptyStateText?: string;
  disableWatermark?: boolean;
  option: EChartsOption;
  padding?: number;
};

const ECharts = forwardRef<ReactECharts | null, Props>(
  ({ zoomable, onClick, option, emptyState, emptyStateText, disableWatermark, padding, ...restProps }, externalRef) => {
    const theme = useTheme();
    const { mode } = useThemeMode();
    const chartRef = useRef<ReactECharts>(null);

    echarts.registerTheme("light", chartTheme(theme));
    echarts.registerTheme("dark", chartTheme(theme));

    const zoomKeyPressed = useKeyPress(["ctrl", "Meta"], zoomable);
    const brushKeyPressed = useKeyPress(["Shift"], zoomable);

    const dynamicOption = useMemo(() => {
      if (disableWatermark) return option;

      const graphics = Array.isArray(option.graphic) ? option.graphic : option.graphic ? [option.graphic] : [];

      const hasLegend = option.legend && !Array.isArray(option.legend) && option.legend.show !== false;

      const grid = {
        containLabel: true,
        top: hasLegend ? 48 : 10,
        bottom: 0,
        left: 5,
        right: 15,
      };

      return {
        ...option,
        grid,
        containLabel: true,
        ...(!disableWatermark && {
          graphic: [
            ...graphics,
            {
              type: "image",
              id: "logo",
              left: "center",
              top: "center",
              style: {
                image: "/chaos-logo.svg",
                width: 184,
                height: 42,
              },
            },
          ],
        }),
      };
    }, [option, disableWatermark]);

    const zoomOption = useMemo(() => {
      const { dataZoom } = dynamicOption;
      if (!zoomable || typeof dataZoom !== "object" || Array.isArray(dataZoom)) return dynamicOption;
      return { ...dynamicOption, dataZoom: { ...dataZoom, zoomLock: !zoomKeyPressed } };
    }, [dynamicOption, zoomKeyPressed, zoomable]);

    useEffect(() => {
      const echartInstance = chartRef?.current?.getEchartsInstance();

      if (echartInstance && zoomable) {
        echartInstance.dispatchAction({
          type: "takeGlobalCursor",
          key: "dataZoomSelect",
          dataZoomSelectActive: brushKeyPressed,
        });
      }
    }, [zoomable, brushKeyPressed, chartRef]);

    useImperativeHandle<ReactECharts | null, ReactECharts | null>(
      externalRef,
      () => (chartRef.current ? chartRef.current : null),
      [chartRef],
    );

    return emptyState ? (
      <Box display="flex" alignItems="center" justifyContent="center" height="80%">
        <Typography variant="h4" color="light.main">
          {emptyStateText || "No Data to Show"}
        </Typography>
      </Box>
    ) : (
      <ReactECharts
        ref={chartRef}
        {...restProps}
        onEvents={{
          click: (c: { data?: [number, number] }) => {
            if (onClick && Array.isArray(c.data) && c.data.length === 2) {
              onClick(c.data[0], c.data[1]);
            }
          },
        }}
        option={zoomOption}
        style={{ height: "100%", width: "100%", padding: padding ?? 24 }}
        theme={mode}
      />
    );
  },
);

export default ECharts;
