import { Box, CustomIcon, Grid, Paper, Tooltip, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { LeaderboardLeague } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  type: LeaderboardLeague;
};

const leagueConfig: Record<LeaderboardLeague, { minEquity: number; prizePool: number; winners: number }> = {
  [LeaderboardLeague.Bronze]: { minEquity: 250, prizePool: 60_000, winners: 25 },
  [LeaderboardLeague.Silver]: { minEquity: 3_000, prizePool: 120_000, winners: 25 },
  [LeaderboardLeague.Gold]: { minEquity: 8_000, prizePool: 240_000, winners: 15 },
  [LeaderboardLeague.Platinum]: { minEquity: 25_000, prizePool: 380_000, winners: 10 },
};

export const LeagueCard = ({ type }: Props) => (
  <Paper variant="card">
    <Box mb={2}>
      <Typography display="flex" gap={1} textTransform="capitalize" fontWeight={600}>
        <CustomIcon icon={`${type}-league`} />
        {`${type} Trading League`}
      </Typography>
    </Box>
    <Grid container>
      <Grid item xs={6} md={4}>
        <Box display="flex" gap={1}>
          <Typography variant="caption">Min. Equity</Typography>
          <Tooltip title="Eligibility is defined by average equity during the previous trading season">
            <CustomIcon icon="info" />
          </Tooltip>
        </Box>
        <Typography variant="h3">{formatAmount(leagueConfig[type].minEquity, { currency: "USD" })}</Typography>
      </Grid>
      <Grid item xs={6} md={4}>
        <Typography variant="caption">Prize Pool</Typography>
        <Typography variant="h3">{formatAmount(leagueConfig[type].prizePool, { currency: "USD" })}</Typography>
      </Grid>
      <Grid item xs={6} md={4}>
        <Typography variant="caption">Number of Winners</Typography>
        <Typography variant="h3">{formatAmount(leagueConfig[type].winners)}</Typography>
      </Grid>
    </Grid>
  </Paper>
);
