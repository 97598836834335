import { ValueOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { MarketStatsOverTimeFragment } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  marketsStatsOverTime?: MarketStatsOverTimeFragment[];
  loading: boolean;
};

export const MarketsFundingRateChart = ({ marketsStatsOverTime, loading }: Props) => (
  <ValueOverTimeChart
    isLoading={loading}
    title="Funding Rate"
    description="Showing the 1 hour funding rate per market, over time."
    hideTotal
    series={
      marketsStatsOverTime?.map<NumberChartSeries>((m) => ({
        type: "line",
        name: m.marketId,
        label: m.marketId,
        data: m.fundingRate?.map((v) => [v.time, v.value]) || [],
      })) || []
    }
    isPercent
  />
);
