import styled from "@mui/material/styles/styled";
import { alpha } from "@mui/system";
import { Tabs, Tab, Typography } from "@mui/material";
import { TabsProps } from "./tabs";
import { Chip } from "../chip";

const StyledTabs = styled(Tabs)(({ theme }) => {
  const color = theme.palette.mode === "light" ? theme.palette.primary.light : theme.palette.primary.dark;

  return {
    borderBottom: "1px solid",
    borderColor: theme.palette.greys[800],
    padding: "0 24px",
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      height: 2,
      transition: "none",
    },
    "& .MuiTabs-indicatorSpan": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: 0,
      left: "50%",
      width: 16,
      height: 2,
      backgroundColor: color,
      transform: "translateX(-50%)",
      transition: "width .3s ease, background-color .3s ease",
    },
  };
});

const StyledTab = styled(Tab)(({ theme }) => {
  const color = theme.palette.mode === "light" ? theme.palette.primary.light : theme.palette.primary.dark;

  return {
    textDecoration: "none",
    position: "relative",
    padding: "16px 8px",
    overflow: "visible",
    color: theme.palette.text.primary,
    marginRight: 24,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: "transparent",
      transition: "background .3s ease",
    },
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: 0,
      left: "50%",
      width: 16,
      height: 2,
      backgroundColor: color,
      transform: "translateX(-50%)",
      transition: "width .3s ease, background-color .3s ease",
    },
    "&:hover:after": {
      width: "100%",
    },
    "&.Mui-selected": {
      color: theme.palette.text.primary,
      "&:before": {
        background: `linear-gradient(180deg, ${alpha(color, 0)} 0%, ${alpha(color, 0.16)} 100%)`,
        filter: "blur(4px)",
      },
      "&:after": {
        width: "100%",
        backgroundColor: color,
      },
    },
  };
});

export const TitleTabs = ({ tabs, value, onChange }: TabsProps) => (
  <StyledTabs
    value={value}
    onChange={onChange}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
    variant="scrollable"
    scrollButtons="auto"
  >
    {tabs.map((tab) => (
      <StyledTab
        key={tab.label?.toString()}
        data-testid={tab.testId}
        label={
          <>
            <Typography variant="h4">{tab.label}</Typography>
            {tab.badge && <Chip label={tab.badge} color="primary" size="small" sx={{ marginLeft: 1.5 }} />}
          </>
        }
      />
    ))}
  </StyledTabs>
);
