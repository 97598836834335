import { useMemo } from "react";
import chunk from "lodash/chunk";
import { Box, Carousel, useMediaQuery, Theme } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { IncentiveProvidersTabs } from "../../incentive-providers-tabs";
import { PoolsTable, PoolCard } from "./components";
import { usePoolsData } from "./use-pools-data";

const PoolsPage = () => {
  const { isLoading, superFluidPools, allPools } = usePoolsData();
  const isSmallWidth = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  const { groups, gridTemplateColumns, gridTemplateRows } = useMemo(
    () => ({
      groups: chunk(superFluidPools, isSmallWidth ? 1 : 6),
      gridTemplateRows: isSmallWidth ? "1fr" : "1fr 1fr",
      gridTemplateColumns: isSmallWidth ? "1fr" : "1fr 1fr 1fr",
    }),
    [superFluidPools, isSmallWidth],
  );

  if (isLoading) return <Loader />;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {superFluidPools && superFluidPools.length > 0 && (
        <Carousel
          title="Superfluid Pools"
          suffix={
            <Box mb={2}>
              <IncentiveProvidersTabs />
            </Box>
          }
        >
          {groups.map((group, i) => (
            <Box
              key={i}
              sx={{
                gap: 2,
                display: "grid",
                gridTemplateRows,
                gridTemplateColumns,
              }}
            >
              {group.map((pool) => (
                <Box key={pool.id} sx={{ flex: 1 }}>
                  <PoolCard pool={pool} />
                </Box>
              ))}
            </Box>
          ))}
        </Carousel>
      )}
      {allPools && <PoolsTable pools={allPools} />}
    </Box>
  );
};

export default PoolsPage;
