import { Box, Tooltip, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { FC } from "react";

type TrendProps = {
  delta: number;
  deltaTrend: number;
  deltaColor?: number;
  currency?: string;
  isPercent?: boolean;
};

export function getDeltaFields(
  current: number | undefined | null,
  previous: number | undefined | null,
  isAlignedWithTrend: boolean | undefined,
) {
  if (typeof current === "number" && typeof previous === "number") {
    const delta = Math.abs(current - previous);
    const deltaTrend = Math.sign(current - previous);
    const deltaColor = isAlignedWithTrend ? deltaTrend : -deltaTrend;

    return { delta, deltaTrend, deltaColor };
  }

  return { delta: undefined, deltaTrend: undefined, deltaColor: undefined };
}

export const Trend: FC<TrendProps> = ({ delta, deltaTrend, deltaColor = 0, currency, isPercent }) => {
  const trendIndicator: string = deltaTrend === 0 ? "" : deltaTrend === -1 ? "-" : "+";
  const trendColor = deltaTrend === 0 ? undefined : deltaColor < 0 ? "error.main" : "success.main";
  return (
    <Box px={1} py={0.25} borderRadius={2} my={0.5}>
      <Tooltip title="24h" arrow placement="bottom-start">
        <Typography color={trendColor} display="flex" alignItems="center" whiteSpace="nowrap" variant="body2">
          {trendIndicator} {delta > 0 ? formatAmount(delta, { isPercent, currency }) : "-"}
        </Typography>
      </Tooltip>
    </Box>
  );
};
