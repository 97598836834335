import { useParams } from "react-router-dom";
import { Box, CustomSwitch } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, numberCell, percentCell } from "@frontend/ui/chaos-table";
import { toTitleCase } from "@frontend/ui/utils/formatters";
import { RouteParams, RoutePath } from "src/config/routes";
import { useMemo, useState } from "react";
import { WithUnifiedProductRedirect } from "src/utils/feature-flags";
import { PageHeader } from "@frontend/ui/page-header";
import { useAggrAssetsBreakdownQuery } from "../../generated";
import { Client } from "../../types";
import { useClientConfig } from "../../clients-config";

const AssetsTabInner = () => {
  const { stableCoin } = useClientConfig();
  const { clientName } = useParams<{ clientName: Client }>();
  const { loading, data } = useAggrAssetsBreakdownQuery();
  const protocolName: string = toTitleCase(clientName!);

  const [type, setType] = useState<"borrow" | "supply">("supply");
  const showBorrow = type === "borrow";
  const protocolVersion = "";
  const activeData = useMemo(
    () =>
      data?.aggrAssetsBreakdown.filter((d) => {
        switch (type) {
          case "borrow":
            return d.isBorrowable;
          case "supply":
            return d.isCollateralizable;
          default:
            return d;
        }
      }) || [],
    [data, type],
  );

  const handleOnClickRow = (rowIdx: number) => {
    const asset = activeData[rowIdx]?.symbol;

    if (stableCoin && stableCoin.asset === asset) {
      return RoutePath.Risk.StablecoinOverview(stableCoin.asset).replace(RouteParams.ClientName, clientName!);
    }

    return RoutePath.Risk.AssetDetails.replace(RouteParams.ClientName, clientName!).replace(RouteParams.Asset, asset);
  };

  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Asset",
      isAutocomplete: true,
    },
    {
      renderType: "TEXT",
      text: "Price",
      prefix: "$",
    },
    ...((showBorrow
      ? [
          {
            renderType: "TEXT",
            text: "Total Borrow",
            prefix: "$",
          },
          {
            renderType: "TEXT",
            text: "Total Supply",
            prefix: "$",
          },
          {
            renderType: "TEXT",
            text: "Utilization",
            suffix: "%",
          },
        ]
      : [
          {
            renderType: "TEXT",
            text: "Total Collateral",
            prefix: "$",
          },
          {
            renderType: "TEXT",
            text: "Total Borrow Power",
            prefix: "$",
          },
        ]) as Header[]),
    {
      renderType: "TEXT",
      text: "Collateral at Risk",
    },
    {
      renderType: "TEXT",
      text: "Wallets at Risk",
    },
  ];
  const assetsRows: RenderData[][] = useMemo(
    () =>
      activeData?.map(
        (asset) =>
          [
            {
              renderType: "TEXT",
              token1: asset.symbol,
              tooltipText: asset.symbol,
              value: asset.symbol.toLowerCase(),
              text: asset.name,
            },
            currencyCell(asset.price),
            ...(showBorrow
              ? [
                  currencyCell(asset.totalBorrow),
                  currencyCell(asset.totalSupply),
                  percentCell(asset.totalBorrow / asset.totalSupply),
                ]
              : [currencyCell(asset.totalSupply), currencyCell(asset.totalBorrowPowerUsd)]),
            currencyCell(asset.totalValueAtRisk),
            numberCell(asset.walletsAtRisk),
          ] as RenderData[],
      ),
    [activeData, showBorrow],
  );

  return (
    <Box height="100%" overflow="auto">
      <PageHeader
        showBackButton={false}
        pageTitle="Assets"
      />
      <ChaosTable
        title="Assets"
        description={`Showing accumulated data for each asset across all ${protocolName} ${protocolVersion} markets`}
        headers={headers}
        isLoading={loading}
        data={assetsRows}
        pageSize={15}
        isFullHeight
        showSearch={false}
        isAppliedFiltersHidden
        initialSortBy={2}
        isInitialSortEnable
        isInitialSortDesc
        serchbarPlaceholder="Search"
        rowHref={handleOnClickRow}
        customFilter={
          <CustomSwitch
            checked={showBorrow}
            onChange={() => setType(showBorrow ? "supply" : "borrow")}
            checkedLabel="Borrow Assets"
            uncheckedLabel="Collateral Assets"
            testId="type-toggle"
          />
        }
        showRowChevron
      />
    </Box>
  );
};

export const AssetsTab = () => (
  <WithUnifiedProductRedirect>
    <AssetsTabInner />
  </WithUnifiedProductRedirect>
);
