import { FC } from "react";
import { Link } from "../link";
import { Box } from "../box";
import { Paper } from "../paper";
import { Typography } from "../typography";
import { Tooltip } from "../tooltip";
import { Icon } from "../icon";
import { OverviewCard } from "./overview-card";
import { CustomIcon } from "../custom-icon";

const typographyStyles = {
  successTypography: {
    color: "success.main",
    pl: 1,
  },
  failTypography: { color: "error.main", pl: 1 },
};

export interface OverviewCardProps {
  index: string | number | undefined;
  icon?: string | undefined;
  title: string;
  value?: string | number | undefined;
  subValue?: JSX.Element;
  delta?: string;
  detail?: string;
  subtitle?: string;
  link?: string;
  count?: string;
  kv?: { key: string; value: string };
  onClick?: (selected: any) => void;
}

const trimQueryUrlParams = (url: string): string => (url.includes("?") ? url.substring(0, url.indexOf("?")) : url);

const convertTickerToUpperCaseRemoveNonAlpha = (ticker: string | undefined) => {
  if (ticker === undefined || ticker.startsWith("http")) {
    return "";
  }
  let asString = ticker.toString();
  if (asString.indexOf(".") !== undefined) {
    const parsed = asString.substring(0, ticker.indexOf("."));
    asString = parsed || asString;
  }
  return asString?.toUpperCase();
};

const prettifyString = (str: string): string => {
  const MAX_LEN = 50;
  if (str.length > MAX_LEN) {
    return `${str.substring(0, MAX_LEN)}...`;
  }
  return str;
};

export const OverviewCardWithIcon: FC<OverviewCardProps> = ({ index, icon, title, delta, detail, subtitle }) => {
  const normalizedTickerString = convertTickerToUpperCaseRemoveNonAlpha(icon).toLowerCase();

  return (
    <div key={`${title} + ${index || ""}`}>
      <Paper
        variant="card"
        sx={{
          height: "100%",
          minHeight: 120,
          padding: "24px 16px 24px 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "lightblue.main",
          }}
        >
          {!normalizedTickerString ? null : (
            <CustomIcon icon={normalizedTickerString} sx={{ position: "relative", svg: { width: 32, height: 32 } }} />
          )}
          {title.includes("https://") || title.includes("http://") ? (
            <Link target="_blank" href={title} style={{ width: "100%" }}>
              <Tooltip title={trimQueryUrlParams(title)}>
                <Typography variant="h2" component="h2" style={{ marginLeft: !normalizedTickerString ? 0 : 10 }}>
                  {prettifyString(trimQueryUrlParams(title))}
                </Typography>
              </Tooltip>
            </Link>
          ) : (
            <Typography
              noWrap
              variant="h2"
              component="h2"
              sx={{
                marginLeft: !normalizedTickerString ? 0 : "10px",
              }}
            >
              {prettifyString(title)}
            </Typography>
          )}
        </Box>
        {!subtitle || subtitle?.includes("undefined") ? null : (
          <Tooltip title={trimQueryUrlParams(subtitle)}>
            <Typography
              variant="h2"
              component="h2"
              sx={{
                mt: 1,
              }}
            >
              {prettifyString(subtitle)}
            </Typography>
          </Tooltip>
        )}
        {!delta || delta.includes("undefined") ? null : (
          <Typography variant="h2" component="h2" noWrap>
            <Typography sx={{ color: "success.main" }} component="span">
              {prettifyString(delta)}
            </Typography>
          </Typography>
        )}
        {!detail || detail.includes("undefined") ? null : (
          <Typography variant="h2" component="h2">
            <Typography sx={{ color: "success.main" }} component="span">
              {prettifyString(detail)}
            </Typography>
          </Typography>
        )}
      </Paper>
    </div>
  );
};

export const LinkableCard: FC<OverviewCardProps> = ({ index, title, delta, subtitle, link }) => (
  <div key={`${title} + ${index || ""}`}>
    <Link target="_blank" href={link} style={{ width: "100%" }}>
      <OverviewCard
        title={title}
        value={subtitle}
        icon="user"
        subValue={
          <Typography sx={typographyStyles.successTypography} component="span">
            {delta}
          </Typography>
        }
      />
    </Link>
  </div>
);

export const StaticCard: FC<OverviewCardProps> = ({ index, title, delta, subtitle }) => (
  <div key={`${title} + ${index || ""}`}>
    <OverviewCard
      title={title}
      value={subtitle || ""}
      icon="user"
      subValue={
        <Typography sx={typographyStyles.successTypography} component="span">
          {delta}
        </Typography>
      }
    />
  </div>
);

export const StaticCardWithIcon: FC<OverviewCardProps> = ({
  index,
  title,
  delta,
  subtitle,
  detail,
  icon,
}): JSX.Element => (
  <div key={`${title} + ${index || ""}`}>
    <OverviewCardWithIcon
      index={index}
      key={title}
      title={title}
      value={subtitle || ""}
      count={subtitle || ""}
      delta={delta || ""}
      subtitle={subtitle || ""}
      detail={detail || ""}
      icon={icon?.toLowerCase() || ""}
    />
  </div>
);

export const MarketsOverviewCardWithIcon: FC<OverviewCardProps> = ({ title, subtitle, icon, onClick, kv }) => {
  const normalizedTickerString = convertTickerToUpperCaseRemoveNonAlpha(icon);
  let icons: { icon1?: string; icon2?: string } = {
    icon1: undefined,
    icon2: undefined,
  };
  if (normalizedTickerString && normalizedTickerString.length > 2) {
    const splitIconsList = normalizedTickerString.split("-");
    icons = {
      icon1: splitIconsList[0].toLowerCase(),
      icon2: splitIconsList[1].toLowerCase(),
    };
  }
  const hasIcons = icons.icon1 && icons.icon2;
  return (
    <Paper
      variant="card"
      onClick={onClick}
      sx={{
        width: 250,
        cursor: "pointer",
        p: 2,
        pr: 1,
      }}
      square
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "lightblue.main",
        }}
      >
        {!hasIcons ? null : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {icons.icon1 && <CustomIcon icon={icons.icon1} sx={{ svg: { width: 16, height: 16 } }} />}
            {icons.icon2 && <CustomIcon icon={icons.icon2} sx={{ ml: 1, svg: { width: 16, height: 16 } }} />}
          </Box>
        )}
        <Typography
          noWrap
          variant="body1"
          sx={{
            marginLeft: !hasIcons ? 0 : "10px",
          }}
        >
          {prettifyString(title)}
        </Typography>
      </Box>
      {!subtitle || subtitle?.includes("undefined") ? null : (
        <Tooltip title={trimQueryUrlParams(subtitle)}>
          <Typography
            variant="body1"
            sx={{
              mt: 1,
            }}
          >
            {prettifyString(subtitle)}
          </Typography>
        </Tooltip>
      )}
      {!kv || !kv?.value || kv.value.includes("undefined") ? null : (
        <Typography
          sx={{
            color: Number(kv.value) > 0 ? "success.main" : "error.main",
          }}
          variant="body2"
          display="flex"
          alignItems="center"
        >
          <Icon sx={{ fontSize: 16 }} name={Number(kv.value) > 0 ? "ArrowDropUp" : "ArrowDropDown"} />{" "}
          {prettifyString(`${kv?.key}: ${kv?.value}`)}
        </Typography>
      )}
    </Paper>
  );
};
