import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import { ReactNode, useState } from "react";
import { IconButton } from "../icon-button";
import { Typography } from "../typography";
import { Box } from "../box";

type Props = {
  children: ReactNode;
  title?: string;
  suffix?: JSX.Element;
};

export const Carousel = ({ children, title, suffix }: Props) => {
  const [page, setPage] = useState(0);

  return (
    <Box overflow="hidden" data-testid="carousel">
      {Array.isArray(children) && (
        <Box>
          <Box display="flex" alignItems="center">
            {title && (
              <Typography variant="h2" mb={1} flex="1">
                {title}
              </Typography>
            )}
            {suffix}
          </Box>
          <Box display="flex" mb={1.5} mr={1} justifyContent="flex-end">
            <IconButton
              sx={{ width: 16, height: 16 }}
              onClick={() => setPage(page - 1)}
              // first page disables ChevronLeftIcon
              disabled={page === 0}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              sx={{ width: 16, height: 16 }}
              onClick={() => setPage(page + 1)}
              // last page disables ChevronRightIcon
              disabled={page >= children.length - 1}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            gap={3}
            sx={{ transition: "transform 0.5s", transform: `translateX(calc(${page * -100}% - ${page * 24}px))` }}
            position="relative"
            mb={1}
          >
            {children.map((child, index) => (
              <Box key={index} flexWrap="wrap" flex={{ xs: "1 0 100%" }}>
                {child}
              </Box>
            ))}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            {children.map((_, i) => {
              const isCurrentPage = i === page;

              return (
                <IconButton key={i} onClick={() => setPage(i)}>
                  <CircleIcon
                    sx={{
                      color: isCurrentPage ? "white" : "light.main",
                      fontSize: isCurrentPage ? 12 : 8,
                    }}
                  />
                </IconButton>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};
