import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useMarketsContext } from "../../../context/markets-context";

const Cards = () => {
  const { stableCoin, hasCollateralLabel } = useClientConfig();
  const { marketId } = useParams();
  const { markets } = useMarketsContext();
  const {
    totalBorrowUsd = 0,
    totalSupplyUsd = 0,
    borrowCap,
    totalCollateralAtRiskUsd,
    numberOfWalletsAtRisk,
    utilizationRate,
  } = markets?.find(({ id }) => marketId === id) || {};

  const allCards: Record<string, ValueCardProps> = {
    totalSupply: {
      title: hasCollateralLabel ? "Total Collateral" : "Total Supply",
      value: totalSupplyUsd,
      currency: "USD",
    },
    totalBorrow: {
      title: "Total Borrow",
      value: totalBorrowUsd,
      currency: "USD",
      progress:
        stableCoin?.showInOverview && borrowCap
          ? {
              maxValue: borrowCap,
              displayMaxValue: true,
            }
          : undefined,
    },
    collateralRatio: {
      title: "Collateral Ratio",
      value: totalSupplyUsd / (totalBorrowUsd || 1) || undefined,
      isPercent: true,
    },
    utilization: {
      title: "Utilization Rate",
      value: utilizationRate || "N/A",
      isPercent: true,
    },
    collateralAtRisk: {
      title: "Total Collateral at Risk",
      tooltip: "Total value at risk of liquidation in positions with health score approaching liquidation",
      value: totalCollateralAtRiskUsd,
      currency: "USD",
    },
    walletsAtRisk: {
      title: "Wallets at Risk",
      tooltip: "Wallets holding positions with health score approaching liquidation",
      value: numberOfWalletsAtRisk,
    },
  };

  const extraCards =
    stableCoin?.showInOverview && borrowCap && typeof utilizationRate === "number"
      ? [allCards.collateralRatio]
      : [allCards.walletsAtRisk];

  const cards: ValueCardProps[] = [
    allCards.totalSupply,
    allCards.totalBorrow,
    ...extraCards,
    allCards.collateralAtRisk,
  ];

  return (
    <Grid container>
      {cards.map((cardProps) => (
        <Grid key={cardProps.title} item xs={12} sm={6} lg={12 / cards.length}>
          <ValueCard {...cardProps} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
