import {
  PoolCurrentLiquidityQueryResult,
  PoolHistoryQueryResult,
  PoolHistoryQueryVariables,
  SingleTwapPoolQueryResult,
  usePoolCurrentLiquidityQuery,
  usePoolHistoryQuery,
  useSingleTwapPoolQuery,
} from "../../../generated";

type Props = {
  variables: PoolHistoryQueryVariables;
};

export type PoolDataResponse = {
  singlePool: SingleTwapPoolQueryResult;
  currentLiquidity: PoolCurrentLiquidityQueryResult;
  history: PoolHistoryQueryResult;
};

export const usePoolData = ({ variables }: Props): PoolDataResponse => {
  const singleTwapPoolQueryResult = useSingleTwapPoolQuery({ variables });
  const poolCurrentLiquidityQueryResult = usePoolCurrentLiquidityQuery({ variables });
  const poolHistoryQueryResult = usePoolHistoryQuery({ variables });

  const response: PoolDataResponse = {
    history: poolHistoryQueryResult,
    currentLiquidity: poolCurrentLiquidityQueryResult,
    singlePool: singleTwapPoolQueryResult,
  };

  return response;
};
