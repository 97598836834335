import { Box, Typography, ECharts, useTheme } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { ChartOption, ChartArgs } from "./chart-option";
import { ChartMetric, ChartMetrics } from "./chart-metrics";

type Props = {
  title: string | React.ReactNode;
  titleVariant?: "h4" | "h5";
  subtitle?: string | React.ReactNode;
  subtitleVariant?: "h5" | "h6";
  chartHeight?: number;
  emptyState?: boolean;
  isLoading?: boolean;
  metrics?: ChartMetric[];
} & ChartArgs;

const ChartWidget = ({
  title,
  subtitle,
  emptyState,
  titleVariant = "h4",
  subtitleVariant = "h6",
  chartHeight = 300,
  isLoading,
  metrics,
  ...chartArgs
}: Props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        height: "100%",
      }}
    >
      <Box display="flex" flexDirection="column" gap={1} height="100%">
        <Box>
          {typeof title === "object" ? title : <Typography variant={titleVariant}>{title}</Typography>}
          {typeof subtitle === "object" ? subtitle : <Typography variant={subtitleVariant}>{subtitle}</Typography>}
        </Box>
        {metrics && !Array.isArray(chartArgs.series) && (
          <ChartMetrics
            metrics={metrics}
            data={chartArgs.series.data}
            currency={chartArgs.currency}
            isPercent={chartArgs.isYAxisPercentage}
          />
        )}
        <Box height={chartHeight} marginTop="auto">
          {isLoading ? <ShimmerLoader /> : <ECharts emptyState={emptyState} option={ChartOption(theme, chartArgs)} />}
        </Box>
      </Box>
    </Box>
  );
};

export default ChartWidget;
