import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { Client, IncentivesOptimizationClientConfig } from "./types";

export const clientsConfig: Record<Client, IncentivesOptimizationClientConfig> = {
  osmosis: {
    methodology: {
      link: RoutePath.IncentivesOptimization.Methodology.replace(RouteParams.ClientName, "osmosis"),
      methodologyTitle: "Hathor Nodes Methodology",
      methodologyIcon: "hathor",
      methodologyIntro: `Chaos Labs and Hathor Nodes have partnered to optimize the Osmosis incentives program;
         with data support from Flipside Crypto. The optimization model uses past and current data 
         to calculate the incentives needed to drive sufficient liquidity across all pools to keep minimal 
         slippage on large-size trades.`,
      methodologyFileName: "osmosis-methodology.md",
      redirectBack: RoutePath.IncentivesOptimization.Home,
    },
  },
};

export const useClientConfig = (): IncentivesOptimizationClientConfig => {
  const { clientName } = useParams<{ clientName: Client }>();
  return clientsConfig[clientName!];
};
