import { TwapPoolFragment, useAllTwapPoolsQuery } from "../../../generated";

export type PoolsDataResponse = {
  isLoading: boolean;
  allPools?: TwapPoolFragment[];
};
export const usePoolsData = (): PoolsDataResponse => {
  const allPoolsResponse = useAllTwapPoolsQuery();
  const allPools = allPoolsResponse.data?.liquidityManipulations;

  const response: PoolsDataResponse = {
    isLoading: allPoolsResponse.loading,
    allPools,
  };

  return response;
};
