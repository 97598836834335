import { useCallback, useEffect, useState } from "react";
import { DataWrap } from "@frontend/types";
import { Box, Typography, TextField, InputLabel, Button, Paper, Grid, CryptoIcon } from "@frontend/ui";
import { useDataFetchersQuery } from "src/hooks/useDataFetchersQuery";
import { Loader } from "@frontend/ui/loader";
import { formatAmount } from "@frontend/ui/utils/formatters";
import NumberFormatInput from "./number-format-input";
import {
  veQiFormInputsNames,
  FormField,
  formFieldsConfig,
  FormFieldType,
  BenqiCalculatorFormValues,
  BenqiCalculatorFetchedValues,
  calculateBenqiOptimalQi,
  CalcResult,
} from "./utils";

const formFieldsDisplayArr: FormField[][] = [
  [formFieldsConfig.validatorStakedAvax, formFieldsConfig.validatorFeeRate],
  [formFieldsConfig.stakedQi],
];

const BenqiCalculator = () => {
  const [lastCalcResult, setLastCalcResult] = useState<CalcResult>({});
  const [formValues, setFormValues] = useState<Partial<BenqiCalculatorFormValues>>({});
  const [formFieldsErrors, setFormFieldsErrors] = useState<Partial<Record<FormFieldType, string>>>({});

  useEffect(() => {
    const newErrors: Partial<Record<FormFieldType, string>> = veQiFormInputsNames.reduce(
      (acc, inputName) => {
        const fieldConfig = formFieldsConfig[inputName];
        const fieldValue = formValues[inputName];

        if (!fieldValue) return acc;

        const { min, max } = fieldConfig;
        if (min === undefined || max === undefined) return acc;

        if (fieldValue < min || fieldValue > max) {
          acc[inputName] = `Value must be between ${formatAmount(min, { notation: "standard" })} and ${formatAmount(
            max,
            { notation: "standard" },
          )}`;
        }

        return acc;
      },
      {} as Partial<Record<FormFieldType, string>>,
    );

    setFormFieldsErrors(newErrors);
  }, [formValues]);

  const setFieldValue = useCallback(
    (value: string | number, field: FormFieldType) => {
      const isNumber = value !== "" && !Number.isNaN(Number(value));

      setFormValues({
        ...formValues,
        [field]: isNumber ? Number(value) : undefined,
      });
    },
    [formValues],
  );

  const isFormInvalid =
    [formValues.validatorStakedAvax, formValues.validatorFeeRate, formValues.stakedQi].includes(undefined) ||
    Object.keys(formFieldsErrors).some((err) => !!err);

  const { response: benqiCalculatorFetchedValues, isLoading } = useDataFetchersQuery<
    DataWrap<BenqiCalculatorFetchedValues>
  >("benqi.liquid_staking_calculator", "offchain");

  const submitForm = () => {
    if (!benqiCalculatorFetchedValues || isFormInvalid) return;

    const { data } = benqiCalculatorFetchedValues;

    const calcResult = calculateBenqiOptimalQi(formValues, data);
    setLastCalcResult(calcResult);
  };

  if (isLoading) return <Loader />;

  return (
    <Paper
      variant="card"
      sx={{
        width: "100%",
        flexShrink: 1,
        padding: { xs: 3, sm: 5 },
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        gap: 5,
      }}
    >
      <Box display="flex" flexDirection="column" flex={1} gap={3}>
        {formFieldsDisplayArr.map((fieldsRow, index) => (
          <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }} gap={3} key={index.toString()}>
            {fieldsRow.map(({ title, name, isPrice, isPercent, icon }) => {
              const errorMessage = formFieldsErrors[name];
              const inputProps = {
                startAdornment: isPrice ? (
                  <Typography pl={2} fontSize={16} variant="caption">
                    $
                  </Typography>
                ) : undefined,
                // eslint-disable-next-line no-nested-ternary
                endAdornment: isPercent ? (
                  <Typography pr={2} variant="caption">
                    %
                  </Typography>
                ) : icon ? (
                  <CryptoIcon icon={icon} />
                ) : undefined,
                inputComponent: NumberFormatInput as never,
              };

              return (
                <Box flex={1} key={name}>
                  <InputLabel>{title}</InputLabel>
                  <TextField
                    error={!!errorMessage}
                    helperText={errorMessage}
                    fullWidth
                    InputProps={inputProps}
                    sx={{
                      input: { pl: isPrice ? 0.5 : 2 },
                    }}
                    placeholder="Value"
                    autoComplete="off"
                    value={Number.isNaN(Number(formValues[name])) ? "" : formValues[name]}
                    onChange={(e) => {
                      setFieldValue(e.target.value, name);
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        ))}
        <Button color="primary" disabled={isFormInvalid} fullWidth sx={{ marginTop: "auto" }} onClick={submitForm}>
          Calculate Returns
        </Button>
      </Box>
      <Box
        width={{ xs: "100%", sm: 184, md: "50%" }}
        borderRadius={4}
        p={2}
        sx={{
          background: "#22252C",
        }}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Box>
          <Typography fontWeight={600}>Estimated Staking Profit</Typography>
        </Box>
        <Grid container spacing={3}>
          {lastCalcResult && (
            <>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={400} variant="caption">
                  QI Staking APR
                </Typography>
                {lastCalcResult.stakingApr === undefined || Number.isNaN(lastCalcResult.stakingApr) ? (
                  <Typography variant="h2">-</Typography>
                ) : (
                  <Box bgcolor="#17191E" p="4px 8px" width="fit-content" borderRadius={2}>
                    <Typography color="#30D4C1" variant="h2">
                      {formatAmount(Number(lastCalcResult.stakingApr.toFixed(4)) * 100, { notation: "standard" })}%
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={400} variant="caption">
                  Annualized AVAX Returns
                </Typography>
                {lastCalcResult.expectedProfit === undefined || Number.isNaN(lastCalcResult.expectedProfit) ? (
                  <Typography variant="h2">-</Typography>
                ) : (
                  <Box>
                    <Box bgcolor="#17191E" p="4px 8px" width="fit-content" borderRadius={2}>
                      <Typography color="#FFA318" variant="h2">
                        {formatAmount(Number(lastCalcResult.expectedProfit.toFixed(2)), { notation: "standard" })}
                      </Typography>
                    </Box>
                    <Typography variant="h5">
                      {lastCalcResult.isExpectedProfitMax
                        ? "Maximum possible returns"
                        : `Staking ${formatAmount(Math.ceil(lastCalcResult.effectiveQiAmount || 0), {
                            notation: "standard",
                          })} 
                          QI, with 5% validator fee rate will maximize returns.`}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6}>
            <Typography fontWeight={400} variant="caption">
              AVAX Price
            </Typography>
            <Typography fontWeight={400}>${benqiCalculatorFetchedValues?.data.avaxPrice}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography fontWeight={400} variant="caption">
              QI Price
            </Typography>
            <Typography fontWeight={400}>${benqiCalculatorFetchedValues?.data.qiPrice}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default BenqiCalculator;
