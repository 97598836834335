import { formatPercent } from "@frontend/ui/utils/formatters";
import { PoolPageHeader } from "src/components/pool-page-wrapper";
import { PoolTokensStatsCard } from "src/components/pool-token-stats-card";
import { RoutePath } from "src/config/routes";
import { TwapPoolFragment } from "src/pages/uniswap/generated";

type Props = {
  pool: TwapPoolFragment;
};

const Header = ({ pool }: Props) => (
  <PoolPageHeader
    token1={pool.token0.symbol}
    token2={pool.token1.symbol}
    subtitle={pool.feeTier ? formatPercent(pool.feeTier / 1000000, { maximumFractionDigits: 3 }) : undefined}
    backRoute={RoutePath.Twap.Home}
    suffixComponent={
      <PoolTokensStatsCard
        data={{
          token1: {
            symbol: pool.token0.symbol,
            amount: pool.token0.amountLocked,
            price: pool.token0.priceInOtherToken,
          },
          token2: {
            symbol: pool.token1.symbol,
            amount: pool.token1.amountLocked,
            price: pool.token1.priceInOtherToken,
          },
        }}
        isPriceAlreadyAdjusted
      />
    }
  />
);

export default Header;
