import { useState } from "react";
import { Box } from "../../../box";
import ECharts from "../../echarts";
import { CustomSwitch } from "../../../custom-switch";
import { ShimmerLoader } from "../../../shimmer-loader";
import { NumberChartProps, NumberChartSeries } from "../../types";
import { getCompositionChartOption } from "./get-composition-chart-option";
import { transformToCSV } from "../export-utils";
import { downloadCsv } from "../../../utils/csv";
import { ChartHeader } from "../chart-header";
import { ChartWrapper } from "../chart-wrapper";
import { useTheme } from "../../../theme";

type Props = {
  percentToggle?: boolean;
  hideTotal?: boolean;
  showAggregateOther?: boolean;
  cryptoCurrency?: string;
  onClick?: (x: number, y: number) => void;
} & NumberChartProps;

const MAX_SERIES_DATA = 5;

const getAggregatedOtherSeries = (series: NumberChartSeries[]) => {
  const isSeriesMoreThanMax = series.length > MAX_SERIES_DATA + 1;

  const othersSeriesData = series
    .slice(MAX_SERIES_DATA + 1)
    .map((s) => s.data)
    .flat();

  const sumByTimestamp = othersSeriesData.reduce(
    (acc: Record<number, number>, [timestamp, value]: [number, number]) => ({
      ...acc,
      [timestamp]: (acc[timestamp] || 0) + value,
    }),
    {},
  );

  const othersSeries: NumberChartSeries = {
    ...series[MAX_SERIES_DATA],
    data: Object.entries(sumByTimestamp).map(
      ([timestamp, value]) => [Number(timestamp), Number(value)] as [number, number],
    ),
    label: "Other",
  };

  return isSeriesMoreThanMax ? othersSeries : undefined;
};

export const CompositionOverTimeChart = ({
  title,
  titleCryptoIcon,
  description,
  percentToggle,
  chartHeight = 348,
  emptyState,
  emptyStateText,
  isLoading,
  series,
  headerSuffix,
  hideTotal,
  showAggregateOther,
  cryptoCurrency,
  tooltip,
  onClick,
  ...restProps
}: Props) => {
  const theme = useTheme();
  const [isPercent, setIsPercent] = useState(false);
  const hasDataForPercentToggle = series.length > 1;
  const exportToCsv = () => {
    downloadCsv(
      ["Timestamp", ...series.map((serie) => serie.label || "")],
      transformToCSV(series),
      title || "chart_data",
    );
  };

  const leftAxisSeries = series.filter((s) => !s.yAxisIndex);
  const otherSeries = series.filter((s) => s.yAxisIndex);
  const sortedLeftAxisSeries = [...(leftAxisSeries || [])].sort(
    (a, b) => b.data.reduce((acc, v) => acc + v[1], 0) - a.data.reduce((acc, v) => acc + v[1], 0),
  );
  const aggregateOther = showAggregateOther && sortedLeftAxisSeries.length >= 10;
  const topLeftSeries = aggregateOther ? sortedLeftAxisSeries.slice(0, MAX_SERIES_DATA) : sortedLeftAxisSeries;
  const otherLeftSeries = aggregateOther && getAggregatedOtherSeries(sortedLeftAxisSeries);

  if (otherLeftSeries) {
    topLeftSeries.push(otherLeftSeries);
  }

  return (
    <ChartWrapper id={title?.toLowerCase().trim().split(" ").join("-")}>
      {(title || percentToggle) && (
        <ChartHeader
          title={title}
          titleCryptoIcon={titleCryptoIcon}
          description={description}
          tooltip={tooltip}
          exportToCsv={exportToCsv}
        >
          {percentToggle && hasDataForPercentToggle && (
            <CustomSwitch
              onChange={() => setIsPercent(!isPercent)}
              checked={!isPercent}
              checkedLabel={cryptoCurrency || "$"}
              uncheckedLabel="%"
            />
          )}
          {headerSuffix}
        </ChartHeader>
      )}
      <Box height={chartHeight}>
        {isLoading ? (
          <ShimmerLoader />
        ) : (
          <ECharts
            onClick={onClick}
            emptyState={emptyState}
            emptyStateText={emptyStateText}
            option={getCompositionChartOption(theme, {
              isPercent,
              series: [...topLeftSeries, ...otherSeries],
              showPercentOfTotal: isPercent,
              hideTotal,
              cryptoCurrency,
              ...restProps,
            })}
          />
        )}
      </Box>
    </ChartWrapper>
  );
};
