import { CompositionOverTimeChart } from "@frontend/ui";
import { NumberChartSeries, TimeSpan } from "@frontend/ui/echarts/types";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { useState } from "react";
import { ProtocolPoolsHistoryFragment } from "src/pages/ccar-lending-page/generated";
import TvlBalanceToggle from "./tvl-balance-toggle";

type Props = {
  asset: string;
  poolsHistoryByProtocol?: ProtocolPoolsHistoryFragment[];
  timeSpan: TimeSpan;
  isLoading: boolean;
};
const TotalPoolsAmountByProtocolChart = ({ asset, poolsHistoryByProtocol, timeSpan, isLoading }: Props) => {
  const [showTvl, setShowTvl] = useState(false);

  const series = [...(poolsHistoryByProtocol || [])].map((poolsByProtocol) => {
    const { protocol, data } = poolsByProtocol;

    const seriesData: [number, number][] = data.map((protocolPools) => {
      const { timestamp, totalTvlUSD, totalTokenBalance } = protocolPools;
      const amount = showTvl ? totalTvlUSD : totalTokenBalance;
      return [timestamp, amount] as [number, number];
    });

    const protocolSeries: NumberChartSeries = {
      label: capitalizeFirstLetter(protocol),
      type: "bar",
      data: seriesData,
    };
    return protocolSeries;
  });

  return (
    <CompositionOverTimeChart
      title={`${showTvl ? "TVL" : asset} in Liquidity Pools`}
      isLoading={isLoading}
      series={series || []}
      currency={showTvl ? "USD" : ""}
      timeSpan={timeSpan}
      headerSuffix={<TvlBalanceToggle asset={asset} showTvl={showTvl} setShowTvl={setShowTvl} />}
    />
  );
};

export default TotalPoolsAmountByProtocolChart;
