import { Box, Button, CryptoIcon, Typography } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { clientsViewPropsMapping } from "src/pages/community-analytics-page/clients-view-props-mapping";
import { useProtocolGroups } from "./protocol-groups";
import ProtocolSwitcherModal from "./protocol-switcher-modal";

export const useProtocolSwitcherContext = () => {
  const groups = useProtocolGroups();
  const { clientName } = useParams<{ clientName: string }>();
  const isInRiskMonitoring = window.location.pathname.includes("/risk");
  const groupsIncludeClient = Object.keys(groups).some((key) => clientName?.includes(key));

  return {
    shouldShowProtocolSwitcher: groupsIncludeClient && isInRiskMonitoring,
  };
};

export const ProtocolSwitcher = () => {
  const { clientName } = useParams<"clientName">();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const protocols = useProtocolGroups();
  const currentProtocolKey = Object.keys(protocols).find((k) => clientName?.includes(k)) || "";
  const currentProtocol = protocols[currentProtocolKey];
  const protocolVersion = currentProtocol.options?.find((o) => o.value === clientName);

  /** Preload logo images to avoid flashes while switching clients */
  useEffect(() => {
    const allLogos = Object.values(clientsViewPropsMapping).map((m) => m.headerProps.clientLogo);
    allLogos.forEach((logo) => {
      const img = new Image();
      img.src = logo;
    });
  }, []);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Box display="flex" gap={1} alignItems="center">
        <CryptoIcon icon={currentProtocol.icon} />
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h5" fontWeight={600}>
            {currentProtocol.label}
          </Typography>
          {protocolVersion && (
            <Typography variant="subtitle1" fontWeight={400}>
              {protocolVersion.label}
            </Typography>
          )}
        </Box>
        <Button
          onClick={handleClick}
          color="secondary"
          sx={{
            px: 1,
            py: 0.5,
            color: "secondary.main",
            bgcolor: "background.main",
            border: "1px solid",
            borderColor: "greys.700",
            lineHeight: "16px",
          }}
        >
          Switch
        </Button>
      </Box>
      <ProtocolSwitcherModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};
