import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createTheme, ThemeProvider as MUIThemeProvider, useTheme as useThemeMUI } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { alpha, createBreakpoints, darken } from "@mui/system";
import { Theme, useMediaQuery } from "@mui/material";
import { SimplePaletteColorOptions } from "@mui/material/styles/createPalette";
import { useAppColorScheme } from "../hooks/use-app-color-scheme";
import { getColors } from "./colors";
import { inputStyles } from "./inputs";
import { palette } from "./palette";
import { AppColorSchemes } from "../types";

declare module "@mui/material/styles" {
  interface Palette {
    greys: {
      [k: number]: string;
    };
    lightGreen: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    greys: {
      [k: number]: string;
    };
    lightGreen: SimplePaletteColorOptions;
  }
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    card: true;
    widget: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColoOverrides {
    error: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    link: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true;
    link: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  label: React.CSSProperties;
  link: React.CSSProperties;
}

interface ThemeProviderProps {
  children?: React.ReactNode;
}

type ThemeModeContextValueType = {
  mode: AppColorSchemes;
  isSystemMode: boolean;
  setMode: (mode: AppColorSchemes | "system") => void;
};

const ThemeModeContext = createContext<ThemeModeContextValueType>({
  mode: "light",
  isSystemMode: true,
  setMode: () => {},
});

export const useThemeMode = () => useContext(ThemeModeContext);

export const ThemeProvider = ({ children }: ThemeProviderProps): JSX.Element => {
  const { colorScheme } = useAppColorScheme();
  const breakpoints = createBreakpoints({});
  const mobileBreakpoint = breakpoints.down("sm");

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<AppColorSchemes | "system">("dark");

  useEffect(() => {
    const localMode = localStorage.getItem("theme-mode") as AppColorSchemes;
    setMode(localMode || "dark");
  }, [prefersDarkMode]);

  const themeMode: AppColorSchemes = useMemo(
    () => (mode === "system" ? (prefersDarkMode ? "dark" : "light") : mode),
    [mode, prefersDarkMode],
  );

  const colors = getColors(themeMode);

  const theme = createTheme({
    palette: {
      mode: colorScheme,
      ...colors,
      ...palette,
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      textWrap: "pretty",
      h1: {
        fontFamily: "At Aero",
        fontWeight: 500,
        fontSize: "48px",
        lineHeight: "48px",
        letterSpacing: "-0.96px",
      },
      h2: {
        fontFamily: "At Aero",
        fontWeight: 500,
        fontSize: "30px",
        lineHeight: "40px",
        letterSpacing: "-0.6px",
      },
      h3: {
        fontFamily: "At Aero",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "32px",
        letterSpacing: "-0.2px",
      },
      h4: {
        fontFamily: "At Aero",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "-0.2px",
      },
      h5: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
      },
      h6: {
        fontFamily: "At Aero",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "-0.2px",
      },
      body1: {
        fontFamily: "At Aero",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "32px",
      },
      body2: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "24px",
      },
      label: {
        fontFamily: "Aeonik Fono",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0.14px",
        textTransform: "uppercase",
        color: colors.greys["400"],
      },
      subtitle1: {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.15px",
        color: colors.greys["400"],
      },
      subtitle2: {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "24px",
        color: colors.greys["400"],
      },
      link: {
        fontFamily: "Aeonik Fono",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0.14px",
        color: colors.text.primary,
        textDecoration: "none",
        textTransform: "uppercase",
        cursor: "pointer",
        "&:hover": {
          color: darken(colors.secondary.main, 0.1),
        },
        "&:active": {
          color: darken(colors.secondary.main, 0.2),
        },
        "& svg path": {
          fill: "currentColor",
        },
      },
    } as ExtendedTypographyOptions,
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: "At Aero";
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local("At Aero"), local("At Aero-Medium"), url('/fonts/at-aero-medium.woff2') format("woff2");
        }
        @font-face {
          font-family: "Aeonik Fono";
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local("Aeonik Fono"), local("Aeonik Fono-Medium"), url('/fonts/aeonik-fono-medium.woff2') format("woff2");
        }
      `,
      },
      MuiAccordion: {
        styleOverrides: {
          root: () => ({
            backgroundColor: alpha(colors.greys["900"], 0.6),
            transition: "all 0.1s ease",
            margin: "0 !important",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: 12,
            color: colors.text.primary,
            lineHeight: "24px",
            border: "none",
            "&:before": {
              display: "none",
            },
            "&:hover": {
              background: alpha(colors.greys["800"], 0.6),
            },
          }),
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: () => ({
            border: "none",
            minHeight: "48px !important",
            display: "flex",
            flexDirection: "row-reverse",
          }),
          content: () => ({
            margin: "0 !important",
          }),
          expandIconWrapper: () => ({
            transform: "rotate(-90deg)",
            marginLeft: -8,
            marginRight: 8,
            "&.Mui-expanded": {
              transform: "rotate(0)",
            },
          }),
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: () => ({
            backgroundColor: alpha(colors.background.default, 0.6),
            padding: 16,
          }),
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: () => ({
            "& path": {
              fill: "currentColor",
            },
          }),
        },
      },
      MuiPaper: {
        variants: [
          {
            props: { variant: "card" },
            style: {
              paddingTop: 16,
              paddingBottom: 16,
              paddingRight: 24,
              paddingLeft: 24,
              background: colors.background.default,
              [mobileBreakpoint]: {
                padding: 12,
              },
            },
          },
          {
            props: { variant: "widget" },
            style: {
              padding: 24,
              background: colors.background.default,
              color: colors.text.primary,
              borderRadius: 8,
              [mobileBreakpoint]: {
                padding: 12,
              },
            },
          },
        ],
        defaultProps: {
          elevation: 0,
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            border: "1px solid #fff",
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 2,
            height: 16,
            backgroundColor: colors.greys["800"],
          },
          bar: {
            borderRadius: 2,
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            color: palette.white.main,
            whiteSpace: "nowrap",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            fontFamily: "Aeonik Fono",
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0.14px",
            borderRadius: 2,
            padding: "11px 23px",
            whiteSpace: "nowrap",
            "&.Mui-disabled": {
              opacity: 0.5,
              backgroundColor: colors.primary.main,
              ".MuiButton-icon": {
                opacity: 0.5,
              },
            },
          },
          text: {
            color: colors.text.primary,
          },
          outlined: {
            background: "transparent",
            color: colors.text.primary,
            borderColor: colors.greys["700"],

            "&:hover": {
              backgroundColor: colors.greys["800"],
              borderColor: colors.greys["600"],
            },

            "&:active": {
              backgroundColor: colors.greys["700"],
            },

            "&:disabled": {
              opacity: 0.5,
              background: "transparent",
              color: palette.white.main,
            },

            "& .MuiButton-startIcon": {
              marginLeft: 0,
            },
          },
        },
        variants: [
          {
            props: { variant: "link" },
            style: (th) => {
              const { ownerState } = th as unknown as {
                ownerState: { color?: "primary" | "secondary" | "error" | "success" };
              };
              return {
                textDecoration: "none",
                color: colors.text.primary,
                backgroundColor: "transparent",
                "&:hover": {
                  textDecoration: "none",
                  color: (ownerState?.color && colors[ownerState.color]?.main) ?? colors.primary.main,
                  backgroundColor: "transparent",
                },
              };
            },
          },
        ],
      },
      MuiChip: {
        styleOverrides: {
          root: ({ ownerState }) => {
            let chipColors = {};
            switch (ownerState.color) {
              case "primary":
              case "info":
                chipColors = {
                  backgroundColor: palette.blue.main,
                  color: palette.white.main,
                };
                break;
              case "warning":
                chipColors = {
                  backgroundColor: palette.orange.main,
                };
                break;
              case "error":
                chipColors = {
                  backgroundColor: palette.red.main,
                };
                break;
              default:
                break;
            }

            return {
              ...chipColors,
            };
          },
          sizeSmall: () => ({
            borderRadius: 4,
            fontSize: 12,
            lineHeight: "16px",
            height: "20px",
          }),
          labelSmall: () => ({
            padding: "2px 4px",
          }),
          icon: () => ({
            marginRight: -2,
            marginLeft: 8,
          }),
          clickable: () => ({
            ":hover": {
              backgroundColor: palette.blue.main,
            },
          }),
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: 600,
            maxWidth: "100%",
            background: colors.background.default,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: colors.text.primary,
            "&:hover": {
              backgroundColor: colors.greys["800"],
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            transition: "color .3s ease",
            color: "currentColor",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: 14,
          },
        },
        defaultProps: {},
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "12px",
            lineHeight: "16px",
            color: colors.light.main,
            marginBottom: 8,
            "&.Mui-focused": {
              color: "currentColor",
            },
            "&.Mui-error": {
              color: palette.red.main,
            },
            "&.Mui-disabled": inputStyles.disabled,
          },
        },
        defaultProps: {
          shrink: true,
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: "12px",
            lineHeight: "16px",
            margin: "8px 0 0",
            color: palette.almostWhite.main,
            "&.Mui-disabled": inputStyles.disabled,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            display: "flex",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: () => ({
            fontWeight: 400,
            marginLeft: 0,
            "&:hover .MuiCheckbox-root": {
              color: colors.light.main,
            },
          }),
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: colors.text.primary,
            "&&": {
              transform: "none",
              position: "relative",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: "currentcolor",
            "&.Mui-checked": {
              color: "currentcolor",
            },
            ...(ownerState.size === "small" && {
              padding: 0,
            }),
          }),
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: 0,
            color: "#fff",
            width: 24,
            transition: "color 0.3s ease",
            "&:hover": {
              color: colors.light.main,
            },
            "&.Mui-checked": {
              color: "#fff",
            },
            "&.Mui-checked:hover": {
              color: colors.light.main,
            },
            "&.Mui-disabled": {
              opacity: 0.1,
              cursor: "default",
            },
            "& svg": {
              "& path": {
                fill: "currentColor",
              },
            },
            "& ~ .MuiFormControlLabel-label": {
              paddingLeft: 16,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            WebkitTextFillColor: colors.text.primary,
            backgroundColor: "transparent",
            color: colors.greys["400"],
            borderRadius: 2,
            border: "1px solid",
            borderColor: colors.greys["700"],
            padding: 0,
            transition: "border-color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease",
            "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.greys["800"],
            },
            "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error)": {
              backgroundColor: colors.greys["800"],
            },
            "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-input:-webkit-autofill": {
              WebkitTextFillColor: colors.text.primary,
            },
            "&.Mui-focused": {
              backgroundColor: colors.greys["800"],
              borderColor: colors.greys["700"],
              color: colors.text.primary,
            },
            "&.Mui-error": {
              backgroundColor: palette.red.opacity10,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderWidth: 0,
            },
            "&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
              backgroundColor: colors.greys["800"],
            },
            "&.MuiInputBase-adornedStart": {
              paddingLeft: 16,
            },
            "&.MuiInputBase-adornedEnd": {
              paddingRight: 16,
            },
            "& .MuiInputAdornment-root": {
              marginRight: 8,
            },
            "&.Mui-disabled": inputStyles.disabled,
            "& fieldset": {
              display: "none",
            },
          },
          input({ ownerState }) {
            return {
              height: "auto",
              lineHeight: "24px",
              fontWeight: 400,
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 16,
              paddingRight: 16,
              ...(ownerState?.startAdornment && { paddingLeft: 0 }),
              ...(ownerState?.endAdornment && { paddingRight: 0 }),
              "&:-webkit-autofill": {
                WebkitBoxShadow: `0 0 0 24px ${colors.background.default} inset`,
                WebkitTextFillColor: palette.almostWhite.main,
              },

              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },

              "&[type=number]": {
                MozAppearance: "textfield,",
              },
            };
          },
          notchedOutline: {
            borderColor: palette.inputs.main,
            hover: {
              borderColor: palette.inputs.hover,
            },
            "&:disabled": {
              borderWidth: 0,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              padding: 4,
              width: "100%",
            },
            "&:disabled": {
              opacity: 0.3,
            },
          },
          paper: {
            background: palette.lightGrey.main,
            boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.08)",
            borderRadius: 8,
          },
          listbox: {
            "& .MuiAutocomplete-option": {
              padding: "12px 24px",
            },
          },
          inputRoot: {
            padding: "8px 12px",
          },
          tag: {
            margin: 4,
            maxWidth: "calc(100% - 8px)",
            "& .MuiChip-label": {
              padding: "0 6px 0 12px",
            },
            "& .MuiChip-deleteIcon": {
              margin: "0 4px 0 0",
              width: 24,
              height: 24,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            width: 24,
            height: 24,
            position: "absolute",
            top: 12,
            right: 16,
            pointerEvents: "none",
          },
          select: {
            paddingRight: "56px !important",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: () => ({
            width: "calc(100% - 24px)",
            marginLeft: 12,
            ".MuiSlider-rail": { backgroundColor: palette.almostWhite.main, height: "2px" },
            ".MuiSlider-track": { backgroundColor: palette.blue.main, height: "4px", border: "none" },
            ".MuiSlider-thumb": {
              background: palette.blue.main,
              height: 24,
              width: 24,
              "&:after": {
                content: '""',
                width: 8,
                height: 8,
                borderRadius: 8,
                background: "white",
              },
            },
          }),
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: 2,
            backdropFilter: "blur(13px)",
            background: "transparent",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: "12px 24px",
            fontWeight: 400,
            transition: "background 0.3s ease",
            "&.Mui-selected": {
              background: "rgba(255,255,255,0.1) !important",
            },
            "&:not(.Mui-selected):not(.Mui-disabled):hover": {
              background: "rgba(255,255,255,0.05)",
            },
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            margin: 0,
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: () => ({
            alignItems: "flex-start",
          }),
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: () => ({
            padding: 4,
            backgroundColor: colors.greys["900"],
            borderRadius: 0,
            width: "fit-content",
            overflow: "auto",
            maxWidth: "100%",
            display: "flex",
            gap: 4,
            "& .MuiToggleButton-root": {
              color: colors.text.primary,
              background: colors.greys["800"],
              "&.Mui-selected": {
                backgroundColor: colors.primary.main,
                color: colors.text.primary,
              },
              "&:hover": {
                background: colors.greys["700"],
              },
              "&.Mui-selected:hover": {
                backgroundColor: colors.primary.main,
                color: colors.text.primary,
              },
            },
          }),
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: () => ({
            border: "none",
            textTransform: "none",
            color: colors.text.primary,
            padding: "8px 16px",
            margin: 0,
            fontSize: 14,
            fontWeight: 600,
            borderRadius: "2px",
          }),
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: colors.greys[800],
            borderRadius: 2,
            whiteSpace: "pre-line",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "24px",
          },
          popper: {
            zIndex: 0,
            "&.hidden": {
              zIndex: 1000,
            },
          },
          arrow: {
            color: colors.greys[800],
            "&.light": {
              color: palette.white.main,
            },
            "&.dark": {
              color: palette.black.main,
            },
          },
        },
        defaultProps: {
          arrow: true,
        },
      },

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      MuiPickersPopper: {
        styleOverrides: {
          paper: {
            borderRadius: 2,
            background: colors.greys[800],
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: palette.blue.main,
            },
          },
          today: {
            backgroundColor: palette.aqua.opacity50,
            border: "none !important",
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            backgroundColor: palette.grey.main,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: colors.text.primary,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 42,
            height: 26,
            padding: 0,
            "& .MuiSwitch-switchBase": {
              padding: 0,
              margin: 2,
              transitionDuration: "300ms",
              color: colors.primary.main,
              "&.Mui-checked": {
                transform: "translateX(16px)",
                color: colors.background.default,
                "& + .MuiSwitch-track": {
                  opacity: 1,
                  border: 0,
                  backgroundColor: colors.primary.main,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.5,
                },
              },
              "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: colors.primary.main,
                border: `6px solid {colors.primary.main}`,
              },
              "&.Mui-disabled .MuiSwitch-thumb": {
                color: colors.greys[600],
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.3,
              },
            },
            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
              width: 22,
              height: 22,
            },
            "& .MuiSwitch-track": {
              borderRadius: 26 / 2,
              opacity: 1,
              backgroundColor: colors.greys[700],
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            background: colors.background.default,
            padding: 24,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: "At Aero",
            fontWeight: 500,
            fontSize: "30px",
            lineHeight: "40px",
            letterSpacing: "-0.6px",
          },
        },
      },
    },
  });

  const handleSetMode = useCallback(
    (newMode: AppColorSchemes | "system") => {
      localStorage.setItem("theme-mode", newMode);
      setMode(mode);
    },
    [mode],
  );

  const themeModeContextValue = useMemo(
    () => ({
      mode: themeMode,
      isSystemMode: mode === "system",
      setMode: handleSetMode,
    }),
    [handleSetMode, mode, themeMode],
  );

  return (
    <ThemeModeContext.Provider value={themeModeContextValue}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ThemeModeContext.Provider>
  );
};

export const useTheme = (): Theme => useThemeMUI();

export { useMediaQuery } from "@mui/material";

export const useBreakpointDown = (breakpoint: "sm" | "md" | "lg") =>
  useMediaQuery<Theme>((theme) => theme.breakpoints.down(breakpoint));

export type { Theme };
