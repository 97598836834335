import { useNavigate, useParams } from "react-router-dom";
import { RiskMonitoringRoutes } from "src/config/risk-monitoring-routes";
import { ProtocolGroupType } from "./protocol-groups";
import { RouteParams } from "../../config/route-params";
import { RoutePath } from "../../config/routes";

export const useProtocolNavigate = () => {
  const navigate = useNavigate();
  const { clientName } = useParams<"clientName">();

  return (client: string, type: ProtocolGroupType, selectedProtocolType: ProtocolGroupType) => {
    let path = "";
    if (selectedProtocolType === type) {
      path = window.location.pathname.replace(clientName!, client);
    } else if (type === "lending" || type === "chain-analytics" || type === "leverage") {
      path = RoutePath.Risk.Home.replace(RouteParams.ClientName, client);
    } else if (type === "perp") {
      path = RoutePath.CCARPerpetuals.Home.replace(RouteParams.ClientName, client);
    }

    // Ignore any route params other than the client name
    // If present, navigate to the parent route.
    const riskMonitoringHome = RiskMonitoringRoutes.Home.replace(RouteParams.ClientName, clientName!);
    const match = window.location.pathname.match(`${riskMonitoringHome}/(.[a-z]+)`); // https://regex101.com/r/jxFlfz/1

    if (match) {
      path = match[0].replace(clientName!, client);
    }

    if (path.length) {
      navigate(path);
    }
  };
};
