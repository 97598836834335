import { useMarketBandsQuery } from "../../generated";
import { LiquidationBandsChart } from "./liquidation-bands-chart";

type Props = {
  marketId: string;
  borrowAsset: string;
  collateralAsset?: string;
};

export const LiquidationBands = ({ marketId, borrowAsset, collateralAsset = "" }: Props) => {
  const { data, loading } = useMarketBandsQuery({ variables: { marketId }, skip: !collateralAsset?.length });

  return (
    <LiquidationBandsChart isLoading={loading} distribution={data?.marketBands} {...{ borrowAsset, collateralAsset }} />
  );
};
