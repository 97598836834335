import { Box } from "@mui/material";
import { ReactNode, useRef } from "react";
import { CHART_ACTIONS_ID } from "./export-utils";
import { Downloadable } from "../../downloadable-element";

type Props = {
  id?: string;
  children: ReactNode;
};

export const ChartWrapper = ({ id, children }: Props) => {
  const chartWrapperRef = useRef<HTMLDivElement | null>(null);

  const preDownload: () => string = () => {
    if (!chartWrapperRef.current) return "";

    const element = chartWrapperRef.current?.querySelector(`#${CHART_ACTIONS_ID}`) as HTMLElement;
    const prevDisplay = element?.style.display;

    element?.style.setProperty("display", "none");

    return prevDisplay ?? "none";
  };

  const postDownload = (prevDisplay: string | undefined) => {
    if (!chartWrapperRef.current) return;

    const element = chartWrapperRef.current?.querySelector(`#${CHART_ACTIONS_ID}`) as HTMLElement;

    element?.style.setProperty("display", prevDisplay as string);
  };

  return (
    <Downloadable id={id ?? "my-chart"} preDownload={preDownload} postDownload={postDownload}>
      <Box
        ref={chartWrapperRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          border: "1px solid",
          borderColor: "greys.800",
        }}
      >
        {children}
      </Box>
    </Downloadable>
  );
};
