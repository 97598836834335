import { RouteParams } from "./route-params";

const ParameterRecommendationsHome = `/${RouteParams.ClientName}/recommendations`;
const UnderReviewParameterRecommendationsHome = `${ParameterRecommendationsHome}/under-review`;

const Methodology = `${ParameterRecommendationsHome}/methodology`;

const MarketAssetPathPart = `markets/${RouteParams.MarketId}/assets/${RouteParams.Asset}`;

const Asset = `${ParameterRecommendationsHome}/${MarketAssetPathPart}`;
const ExperimentResultsCurrent = `${ParameterRecommendationsHome}/${MarketAssetPathPart}/experiment/current`;
const ExperimentResultsRecommended = `${ParameterRecommendationsHome}/${MarketAssetPathPart}/experiment/recommended`;
const SimulationResults = `${ParameterRecommendationsHome}/${MarketAssetPathPart}/simulation-results/${RouteParams.SimulationResultId}`;

const UnderReviewAsset = `${UnderReviewParameterRecommendationsHome}/${RouteParams.PythonSimulationResultId}`;
const UnderReviewExperimentResultsCurrent = `${UnderReviewParameterRecommendationsHome}/${RouteParams.PythonSimulationResultId}/experiment/current`;
const UnderReviewExperimentResultsRecommended = `${UnderReviewParameterRecommendationsHome}/${RouteParams.PythonSimulationResultId}/experiment/recommended`;
const UnderReviewSimulationResults = `${UnderReviewParameterRecommendationsHome}/${RouteParams.PythonSimulationResultId}/simulation-results/${RouteParams.SimulationResultId}`;

export const ParameterRecommendationsRoutes = {
  Home: ParameterRecommendationsHome,
  UnderReviewHome: UnderReviewParameterRecommendationsHome,
  Methodology,
  Asset,
  ExperimentResultsCurrent,
  ExperimentResultsRecommended,
  SimulationResults,
  UnderReviewAsset,
  UnderReviewExperimentResultsCurrent,
  UnderReviewExperimentResultsRecommended,
  UnderReviewSimulationResults,
};
