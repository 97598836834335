import { ManipulationCost, UniswapChain } from "src/pages/uniswap/generated";

export const getTwapBlocksByChain = (chain: UniswapChain) => {
  switch (chain) {
    case UniswapChain.Ethereum:
      return 144;
    case UniswapChain.Arbitrum:
      return 7200;
    case UniswapChain.Optimism:
      return 900;
    case UniswapChain.Polygon:
      return 900;

    default:
      return 144;
  }
};

export const calculateCosts = (
  manipulationCosts: ManipulationCost[],
  isValuesInUSD: boolean,
  otherTokenPrice: number,
  chain: UniswapChain,
  poolTvl: number,
  blocksControlled?: number,
  additionalLiquidity?: number,
  isAdditionalLiquidityOnSpot?: boolean,
): [number, number][] => {
  function calculateAdditionalLiquidity(): ManipulationCost[] {
    if (additionalLiquidity) {
      if (isAdditionalLiquidityOnSpot) {
        return manipulationCosts.map(({ ratio, cost }) => ({
          ratio,
          cost: cost + additionalLiquidity / 2 / otherTokenPrice,
        }));
      }
      return manipulationCosts.map(({ ratio, cost }) => ({ ratio, cost: cost * (1 + additionalLiquidity / poolTvl) }));
    }
    return manipulationCosts;
  }

  const manipulationCostsWithLiquidity = calculateAdditionalLiquidity();

  if (!blocksControlled || blocksControlled === getTwapBlocksByChain(chain)) {
    return manipulationCostsWithLiquidity.map(({ ratio, cost }) => [
      ratio - 1,
      isValuesInUSD ? cost * otherTokenPrice : cost,
    ]);
  }

  const percentageSeries = new Array(manipulationCosts.length).fill(0).map((_, i) => 1 + i / 100);
  const highestRatio = manipulationCosts[manipulationCosts.length - 1].ratio;
  const controlRatio = getTwapBlocksByChain(chain) / blocksControlled;

  return percentageSeries.reduce(
    (acc, percentage) => {
      const spotPriceChange = percentage ** controlRatio;
      if (spotPriceChange > highestRatio) return acc;

      const closestRatio = manipulationCostsWithLiquidity.find(
        ({ ratio }) => ratio === Math.round(spotPriceChange * 100) / 100,
      );
      if (!closestRatio) return acc;
      const { cost } = closestRatio;
      return [...acc, [percentage - 1, isValuesInUSD ? cost * otherTokenPrice : cost]];
    },
    [] as [number, number][],
  );
};
