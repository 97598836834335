import { FC, ReactNode, createContext, useContext, useMemo } from "react";
import { PriceFeed } from "../types";
import { Endpoints, useApiRequest } from "../api";

export type PriceFeedsContextType = {
  priceFeeds: PriceFeed[];
  getFeedById?: (asset: string) => PriceFeed | undefined;
  isLoading: boolean;
};

const initialState: PriceFeedsContextType = {
  priceFeeds: [],
  isLoading: false,
};

export const PriceFeedsContext = createContext<PriceFeedsContextType>(initialState);

export const usePriceFeedsContext = () => {
  const context = useContext(PriceFeedsContext);
  return context;
};

const PriceFeedsProvider: FC<ReactNode> = ({ children }) => {
  const { isLoading, response: priceFeeds } = useApiRequest(Endpoints.PriceFeeds);

  const contextState: PriceFeedsContextType = useMemo(() => {
    const parsedPriceFeeds = Object.entries(priceFeeds || {}).map(([asset, data]) => ({
      asset,
      ...data,
    }));

    return {
      priceFeeds: parsedPriceFeeds,
      getFeedById: (asset: string) => parsedPriceFeeds?.find((f) => f.asset === asset),
      isLoading,
    };
  }, [isLoading, priceFeeds]);

  return <PriceFeedsContext.Provider value={contextState}>{children}</PriceFeedsContext.Provider>;
};

export default PriceFeedsProvider;
