import { Grid, ValueCardGroup, ValueCard, ValueCardProps } from "@frontend/ui";
import { ValueCardGroupItem } from "@frontend/ui/value-card-group/value-card-group";
import { MarketStatsFragment } from "../generated";
import { getDeltaAsRatio } from "./utils";
import { useClientConfig } from "../clients-config";

type DataPoint = {
  title: string;
  icon?: string;
  value?: number;
  previous?: number;
  negativeMetric?: boolean;
};

type Props = {
  isLoading?: boolean;
  marketStats: {
    current?: MarketStatsFragment;
    previous?: MarketStatsFragment;
    negativeMetric?: boolean;
  };
};

const formatCardDatapoint = ({ value, previous, negativeMetric, ...rest }: DataPoint) => ({
  ...rest,
  value,
  ...getDeltaAsRatio(value, previous, negativeMetric),
});

export const MarketStatsCards = ({ marketStats, isLoading }: Props) => {
  const { markets, combineOpenInterest } = useClientConfig();
  const { cards: cardsConfig, borrowRateName, hideDailyChange } = markets || {};
  const hasFundingRate = markets?.cards.includes("fundingRate") && marketStats.current?.nextFundingRate !== null;

  const priceStats: ValueCardProps[] = [
    {
      title: "Price",
      value: marketStats.current?.price,
      currency: "USD",
      ...getDeltaAsRatio(marketStats.current?.price, marketStats.previous?.price),
    },
    ...(cardsConfig?.includes("borrowRate")
      ? [
          {
            title: borrowRateName || "Borrow Rate",
            value: marketStats.current?.borrowRate,
            isPercent: true,
            valueMaximumFractionDigits: 4,
          },
        ]
      : []),
    ...(cardsConfig?.includes("dailyVolume")
      ? [
          {
            title: "24H Volume",
            value: marketStats.current?.dailyVolumeUsd,
            currency: "USD",
            ...getDeltaAsRatio(marketStats.current?.dailyVolumeUsd, marketStats.previous?.dailyVolumeUsd),
          },
        ]
      : []),
    ...(cardsConfig?.includes("24HTrades") && marketStats.current?.dailyTrades !== null
      ? [
          {
            title: "24H Trades",
            value: marketStats.current?.dailyTrades,
            ...getDeltaAsRatio(
              marketStats.current?.dailyTrades || undefined,
              marketStats.previous?.dailyTrades || undefined,
            ),
          },
        ]
      : []),
    ...(!cardsConfig?.includes("borrowRate") && cardsConfig?.includes("interestImbalance") && !hideDailyChange
      ? [
          {
            title: "Daily Change",
            value:
              marketStats.current?.price !== undefined && marketStats.previous?.price !== undefined
                ? marketStats.current.price - marketStats.previous.price
                : undefined,
            currency: "USD",
            ...getDeltaAsRatio(marketStats.current?.price, marketStats.previous?.price),
          },
        ]
      : []),
  ];
  const poolStats: ValueCardProps[] = [
    {
      title: "Total Open Interest",
      currency: "USD",
      value: marketStats.current?.totalOpenInterest,
      previous: marketStats.previous?.totalOpenInterest,
    },
    ...(markets?.cards.includes("interestImbalance")
      ? [
          {
            title: "Interest Imbalance",
            isPercent: true,
            value: marketStats.current?.interestImbalance,
            previous: marketStats.previous?.interestImbalance,
          },
        ]
      : []),
  ].map(formatCardDatapoint);

  const longStats: ValueCardGroupItem[] = [
    {
      title: "Count",
      value: marketStats.current?.longPositions,
      previous: marketStats.previous?.longPositions,
    },
    ...(!combineOpenInterest
      ? [
          {
            title: "Value",
            currency: "USD",
            value: marketStats.current?.longInterest,
            previous: marketStats.previous?.longInterest,
          },
        ]
      : []),
    {
      title: "Avg. Leverage",
      value: marketStats.current?.averageLongLeverage,
      previous: marketStats.previous?.averageLongLeverage,
    },
    ...(cardsConfig?.includes("sideFundingRate")
      ? [
          {
            title: "Funding Rate 1H",
            value: marketStats.current?.longFundingRatePerBlock,
            isPercent: true,
            showSign: true,
          },
        ]
      : []),
  ].map(formatCardDatapoint);
  const shortStats: ValueCardGroupItem[] = [
    {
      title: "Count",
      value: marketStats.current?.shortPositions,
      previous: marketStats.previous?.shortPositions,
    },
    ...(!combineOpenInterest
      ? [
          {
            title: "Value",
            currency: "USD",
            value: marketStats.current?.shortInterest,
            previous: marketStats.previous?.shortInterest,
          },
        ]
      : []),
    {
      title: "Avg. Leverage",
      value: marketStats.current?.averageShortLeverage,
      previous: marketStats.previous?.averageShortLeverage,
    },
    ...(cardsConfig?.includes("sideFundingRate")
      ? [
          {
            title: "Funding Rate 1H",
            value: marketStats.current?.shortFundingRatePerBlock,
            isPercent: true,
            showSign: true,
          },
        ]
      : []),
  ].map(formatCardDatapoint);
  const fundingRateStats: ValueCardGroupItem[] = [
    {
      title: "1H",
      value: marketStats.current?.nextFundingRate !== null ? marketStats.current?.nextFundingRate : undefined,
      previous: marketStats.previous?.nextFundingRate !== null ? marketStats.previous?.nextFundingRate : undefined,
      isPercent: true,
    },
    {
      title: "24H Annualized",
      value:
        marketStats.current?.annualizedFundingRate !== null ? marketStats.current?.annualizedFundingRate : undefined,
      previous:
        marketStats.previous?.annualizedFundingRate !== null ? marketStats.previous?.annualizedFundingRate : undefined,
      isPercent: true,
    },
  ].map(formatCardDatapoint);

  return (
    <Grid container spacing={1}>
      {priceStats.map((stats, i) => (
        <Grid key={`${stats.title}${i.toString()}`} item zeroMinWidth xs={12} md={6} lg={3}>
          <ValueCard {...stats} isDeltaPercent />
        </Grid>
      ))}
      {poolStats.map((stats, i) => (
        <Grid key={`${stats.title}${i.toString()}`} item zeroMinWidth xs={12} md={6} lg={3}>
          <ValueCard {...stats} isDeltaPercent />
        </Grid>
      ))}
      <Grid item zeroMinWidth xs={12} lg={hasFundingRate ? 4 : 6}>
        <ValueCardGroup title="Long Positions" items={longStats} isLoading={isLoading} />
      </Grid>
      <Grid item zeroMinWidth xs={12} lg={hasFundingRate ? 4 : 6}>
        <ValueCardGroup title="Short Positions" items={shortStats} isLoading={isLoading} />
      </Grid>
      {hasFundingRate && (
        <Grid item zeroMinWidth xs={12} lg={4}>
          <ValueCardGroup title="Funding Rate" items={fundingRateStats} isLoading={isLoading} />
        </Grid>
      )}
    </Grid>
  );
};
