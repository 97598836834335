import { Paper, Typography, CustomReactSelect } from "@frontend/ui";
import { useEffect, useMemo, useState } from "react";
import { Chain, useAssetPriceImpactPairsQuery } from "src/pages/ccar-lending-page/generated";
import { OnChainLiquidityCharts } from "./on-chain-liquidity-charts";

type Props = {
  token: string;
  chain: string;
};

export const OnChainLiquidity = ({ token, chain }: Props) => {
  const [compareToken, setCompareToken] = useState("");

  const { loading: loadingPairs, data: pairsData } = useAssetPriceImpactPairsQuery({
    variables: {
      tokenSymbol: token,
      chain: chain as Chain,
    },
  });

  const hasPairsToCompare = !loadingPairs && pairsData?.assetPriceImpactPairs.length;

  const options = useMemo(
    () =>
      pairsData?.assetPriceImpactPairs
        .map((pair) => ({
          value: pair.token1,
          label: pair.token1,
          cryptoIcon: pair.token1.toLowerCase(),
        }))
        .filter((option) => option.value !== token) || [],
    [token, pairsData],
  );

  useEffect(() => {
    if (options.length && !compareToken.length) {
      setCompareToken(options[0].value);
    }
  }, [options, compareToken]);

  if (!hasPairsToCompare) {
    return null;
  }

  return (
    <Paper variant="card">
      <Typography variant="h3" mb={[2, 3]}>
        On-Chain Liquidity
      </Typography>
      <CustomReactSelect
        isLoading={loadingPairs}
        label="Token to compare"
        value={options.find((option) => option.value === compareToken)}
        options={options}
        onChange={(selected) => {
          if (!selected) {
            return;
          }
          setCompareToken(selected?.value);
        }}
        sx={{ mb: [2, 3], maxWidth: 320 }}
      />
      <OnChainLiquidityCharts {...{ token, compareToken, chain }} />
    </Paper>
  );
};
