import { Route, Routes, Navigate } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { CommunityDashboardLayout } from "src/components/layouts";
import { WalletDetailsPage, PoolDetailsPage, PoolsPage } from "./pages";
import { WalletsSearchLayout } from "./pages/wallet-details/components";
import { PoolsOverviewPage } from "./pages/pools-overview";

export const LpDashboardPageRoutes = () => {
  const clientName = "osmosis";

  return (
    <Routes>
      <Route
        index
        element={
          <Navigate to={RoutePath.LpDashboard.PoolsOverview.replace(RouteParams.ClientName, clientName)} replace />
        }
      />
      <Route
        path={RoutePath.LpDashboard.Pools.replace(RoutePath.LpDashboard.Home, "")}
        element={
          <Navigate to={RoutePath.LpDashboard.PoolsOverview.replace(RouteParams.ClientName, clientName)} replace />
        }
      />
      <Route
        path={RoutePath.LpDashboard.PoolsOverview.replace(RoutePath.LpDashboard.Home, "")}
        element={
          <WalletsSearchLayout>
            <PoolsOverviewPage />
          </WalletsSearchLayout>
        }
      />
      <Route
        path={RoutePath.LpDashboard.PoolsAll.replace(RoutePath.LpDashboard.Home, "")}
        element={
          <WalletsSearchLayout>
            <PoolsPage />
          </WalletsSearchLayout>
        }
      />
      <Route
        path={RoutePath.LpDashboard.PoolDetails.replace(RoutePath.LpDashboard.Home, "")}
        element={
          <WalletsSearchLayout>
            <PoolDetailsPage />
          </WalletsSearchLayout>
        }
      />
      <Route
        path={RoutePath.LpDashboard.WalletDetails.replace(RoutePath.LpDashboard.Home, "")}
        element={
          <CommunityDashboardLayout>
            <WalletDetailsPage />
          </CommunityDashboardLayout>
        }
      />
    </Routes>
  );
};
