import { palette } from "@frontend/ui/theme/palette";
import { Order, OrderType } from "./types";
import { NumberChartSeries } from "../echarts/types";

export const getSpotPrice = (orderBook: Order[]) => {
  const highestBid = orderBook.filter((item) => item.order_type === "bids").sort((a, b) => b.price - a.price)[0];
  const lowestAsk = orderBook.filter((item) => item.order_type === "asks").sort((a, b) => a.price - b.price)[0];

  const price = highestBid && lowestAsk ? (highestBid.price + lowestAsk.price) / 2 : 0;
  return price;
};

const getOrdersData = (orders: Order[]) =>
  orders.reduce(
    (acc, item) => {
      const lastItem = acc[acc.length - 1];
      const totalSize = item.size + (lastItem?.[1] || 0);
      const newPoint: [number, number] = [item.price, totalSize];
      return [...acc, newPoint];
    },
    [] as [number, number][],
  );

const getSortedOrders = (orderBook: Order[], type: OrderType) =>
  orderBook.filter((item) => item.order_type === type).sort((a, b) => a.index - b.index);

export const getChartSeries = (orderBook: Order[]): NumberChartSeries[] => {
  const bids = getSortedOrders(orderBook, "bids");
  const asks = getSortedOrders(orderBook, "asks");

  const bidsData = getOrdersData(bids).reverse();
  const asksData = getOrdersData(asks);

  const bidsSeries: NumberChartSeries = {
    data: bidsData,
    color: palette.green.main,
    showArea: true,
    animation: false,
  };
  const asksSeries: NumberChartSeries = {
    data: asksData,
    color: palette.red.main,
    showArea: true,
    animation: false,
  };
  return [bidsSeries, asksSeries];
};
