import { useState } from "react";
import { Box, CustomReactSelect, ValueOverTimeChart } from "@frontend/ui";
import { palette } from "@frontend/ui/theme/palette";
import { HistoryDataFragment } from "src/pages/ccar-perpetuals-page/generated";
import dayjs from "dayjs";

type Props = {
  volume: HistoryDataFragment[];
  liquidity: HistoryDataFragment[];
  incentives: HistoryDataFragment[];
  loading?: boolean;
  isElasticity?: boolean;
};

const ELASTICITY_DAYS = 30;

const CorrelationChart = ({ volume, liquidity, incentives, loading, isElasticity }: Props) => {
  const [correlationMetricA, setCorrelationMetricA] = useState("Liquidity");
  const [correlationMetricB, setCorrelationMetricB] = useState("Incentives");
  const metricOptions = [
    {
      label: "Liquidity",
      value: "liquidity",
    },
    {
      label: "Volume",
      value: "volume",
    },
    {
      label: "Incentives",
      value: "incentives",
    },
  ];
  const getHistoryByLabel = (label: string) => {
    if (label === "Liquidity") {
      return liquidity;
    }

    if (label === "Volume") {
      return volume;
    }

    return incentives;
  };
  const historyDataA = getHistoryByLabel(correlationMetricA);
  const historyDataB = getHistoryByLabel(correlationMetricB);
  const getElasticityValue = (a: HistoryDataFragment) => {
    const aOld = historyDataA.find((d) => dayjs().diff(d.time, "days") === ELASTICITY_DAYS);
    const b = historyDataB.find((d) => d.time === a.time);
    const bOld = historyDataA.find((d) => dayjs().diff(d.time, "days") === ELASTICITY_DAYS);

    if (aOld && b && bOld) {
      return Math.abs((a.value - aOld.value) / aOld.value / ((b.value - bOld.value) / bOld.value));
    }

    return 0;
  };

  return (
    <ValueOverTimeChart
      isLoading={loading}
      title={isElasticity ? "Elasticity" : "Ratio"}
      description={
        isElasticity
          ? `${ELASTICITY_DAYS} days ${correlationMetricB} elasticity of ${correlationMetricA} over time`
          : `Showing the ratio of ${correlationMetricA} and ${correlationMetricB} calculated as ${correlationMetricA} / ${correlationMetricB} over time`
      }
      series={[
        {
          label: isElasticity ? "Elasticity" : "Ratio",
          data: historyDataA
            .filter((d) => (isElasticity ? dayjs().diff(d.time, "days") > ELASTICITY_DAYS : true))
            .map((d) => [
              d.time,
              isElasticity
                ? getElasticityValue(d)
                : d.value / (historyDataB.find(({ time }) => time === d.time)?.value || Number.MAX_SAFE_INTEGER),
            ]),
          color: palette.blue.main,
        },
      ]}
      yAxisMin
      yAxisMax
      showLegend={false}
      currency=""
      headerSuffix={
        <Box display="flex" gap={2}>
          <CustomReactSelect
            options={metricOptions}
            value={metricOptions.find(({ label }) => label === correlationMetricA)}
            onChange={(value) => setCorrelationMetricA(value?.label || "Liquidity")}
          />
          <CustomReactSelect
            options={metricOptions}
            value={metricOptions.find(({ label }) => label === correlationMetricB)}
            onChange={(value) => setCorrelationMetricB(value?.label || "Volume")}
          />
        </Box>
      }
    />
  );
};

export default CorrelationChart;
