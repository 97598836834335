import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { DataQueries } from "src/hooks/useDataFetchersQuery";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { WalletSupplyAndBorrow } from "src/pages/ccar-lending-page/generated";

const loadWalletSnapshot = async (
  endpointPrefix: string,
  walletAddress: string,
  date: number,
): Promise<WalletSupplyAndBorrow | undefined> => {
  const result = await DataQueries<{ data: WalletSupplyAndBorrow }>(`${endpointPrefix}.wallets_snapshot`, "offchain", {
    walletAddress,
    date,
  });

  return result?.data;
};

type ArgsType = {
  address: string;
  date?: Dayjs;
  skip?: boolean;
};

export const useSupplyAndBorrowSnapshot = ({ address, date, skip }: ArgsType) => {
  const { endpointsPrefix } = useClientConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [snapshot, setSnapshot] = useState<WalletSupplyAndBorrow>();

  useEffect(() => {
    if (skip || !date) return;

    const timestamp = date.toDate().getTime();
    void (async () => {
      setIsLoading(true);
      const supplyAndBorrowSnapshot = await loadWalletSnapshot(endpointsPrefix, address, timestamp);
      setSnapshot(supplyAndBorrowSnapshot);
      setIsLoading(false);
    })();
  }, [address, date, endpointsPrefix, skip]);

  return { snapshot, isLoading };
};
