import { FC } from "react";
import { SxProps } from "@mui/material";
import { Box } from "../box";
import { TextField } from "../text-field";
import { CustomIcon } from "../custom-icon";
import SearchBarButtonWrapper from "./search-bar-button-wrapper";

export interface SearchBarProps {
  value?: string;
  onChange: (value: string) => void;
  onSearch?: (value: string) => void;
  placeholder?: string;
  fullWidth?: boolean;
  sx?: SxProps;
  loading?: boolean;
}

export const SearchBar: FC<SearchBarProps> = ({ value, onChange, onSearch, placeholder, fullWidth = false }) => (
  <Box width="280px" height="48px" display="flex">
    <TextField
      type="search"
      value={value}
      fullWidth={fullWidth}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder || "Search field"}
      onKeyDown={(event) => {
        switch (event.key) {
          case "Enter": {
            if (value && onSearch) {
              onSearch(value);
            }
            break;
          }
          default:
            break;
        }
      }}
      InputProps={{
        sx: {
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          borderTopRightRadius: "0",
          borderBottomRightRadius: "0",
          background: "#8694A11A",
        },
      }}
    />
    <SearchBarButtonWrapper onClick={() => value && onSearch?.(value)}>
      <CustomIcon icon="search" />
    </SearchBarButtonWrapper>
  </Box>
);
