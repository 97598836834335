import { FC, ReactNode, createContext, useMemo, useState, Dispatch, SetStateAction } from "react";
import { Chain, AssetFragmentFragment, useMarketAssetsBreakdownQuery } from "src/pages/ccar-lending-page/generated";

export type ListedAssetsContextType = {
  listedAssets?: AssetFragmentFragment[];
  isLoading: boolean;
  chain?: Chain;
  setChain: Dispatch<SetStateAction<Chain | undefined>>;
};

const initialState: ListedAssetsContextType = {
  isLoading: false,
  setChain: () => {},
};

export const ListedAssetsContext = createContext<ListedAssetsContextType>(initialState);

const ListedAssetsProvider: FC<ReactNode> = ({ children }) => {
  const [chain, setChain] = useState<Chain>();

  const { loading, data } = useMarketAssetsBreakdownQuery({
    variables: {
      chain: chain || null,
      marketId: null,
      tokenSymbol: null,
    },
    skip: !chain,
  });

  const contextState: ListedAssetsContextType = useMemo(
    () => ({
      listedAssets: data?.assetsBreakdown,
      isLoading: loading,
      chain,
      setChain,
    }),
    [chain, data, loading],
  );

  return <ListedAssetsContext.Provider value={contextState}>{children}</ListedAssetsContext.Provider>;
};

export default ListedAssetsProvider;
