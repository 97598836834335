import { Box, CompositionOverTimeChart, Grid, ValueOverTimeChart } from "@frontend/ui";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { NumberChartSeries, TimeSpan } from "@frontend/ui/echarts/types";
import { TopWallets } from "src/pages/ccar-lending-page/components/top-wallets";
import { MultiChartData, OverviewChartsStats } from "../types";
import { Chain } from "../../risk-explorer/types";
import { MarketHistoryCharts } from "../../markets-new/components/market-history-charts";

type Props = {
  timeSpan?: TimeSpan;
  data?: OverviewChartsStats;
  isLoading?: boolean;
  chain?: Chain;
};

const multiChartDataToValueOverTimeSeries = (data?: MultiChartData, tvl?: MultiChartData): NumberChartSeries[] =>
  Object.entries(data || {}).map(([chain, d]) => ({
    type: "line",
    label: d.title,
    data: d.data.reduce<[number, number][]>((acc, c) => {
      const divideBy = tvl?.[chain].data.find(({ x }) => x.getTime() === c.x.getTime())?.y;

      if (divideBy) {
        return [...acc, [c.x.getTime(), c.y / divideBy]];
      }

      return acc;
    }, []),
  }));

export const ChainHistoryStats = ({ data, isLoading, timeSpan, chain }: Props) => {
  const { isMultiMarket, chains } = useClientConfig();
  const defaultChain = chains[0];

  if (!isMultiMarket && defaultChain) {
    return (
      <Box display="grid" gap={3}>
        <MarketHistoryCharts chain={defaultChain} timeSpan={timeSpan} />
        <TopWallets chain={defaultChain} />
      </Box>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <CompositionOverTimeChart
            isLoading={isLoading}
            series={Object.values(data?.totalLiquidity || []).map((d) => ({
              label: d.title,
              data: d.data.map((c) => [c.x.getTime(), c.y]),
            }))}
            title="Total Supply"
            percentToggle
            showLegend
            timeSpan={timeSpan}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CompositionOverTimeChart
            isLoading={isLoading}
            series={Object.values(data?.totalBorrow || []).map((d) => ({
              label: d.title,
              data: d.data.map((c) => [c.x.getTime(), c.y]),
            }))}
            title="Total Borrow"
            percentToggle
            showLegend
            timeSpan={timeSpan}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ValueOverTimeChart
            isLoading={isLoading}
            series={multiChartDataToValueOverTimeSeries(data?.totalLiquidity, data?.tvl)}
            title="Supply Dominance"
            description="Protocol Supply % of Chain Liquidity Over Time"
            isPercent
            yAxisMax
            yAxisMin
            showLegend
            connectNulls
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ValueOverTimeChart
            isLoading={isLoading}
            series={multiChartDataToValueOverTimeSeries(data?.totalBorrow, data?.tvl)}
            title="Borrow Dominance"
            description="Protocol Borrow % of Chain Liquidity Over Time"
            isPercent
            yAxisMax
            yAxisMin
            showLegend
            connectNulls
          />
        </Grid>
      </Grid>
      <TopWallets chain={chain} />
    </>
  );
};
