import { useEffect, useState } from "react";

export type PaginationSort = Record<string, -1 | 1>;

export type SortChangeHandler = (sort: string, order: 1 | -1) => void;

export function usePagination<T>(data: T[], count: number, itemsPerPage = 10, retrigger?: string) {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataForPage, setDataForPage] = useState<T[]>();

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    setDataForPage(data.slice(start, end));
  }, [currentPage, data, count, itemsPerPage]);
  useEffect(() => setCurrentPage(1), [retrigger]);

  const pageCount = Math.ceil(count / itemsPerPage);

  return {
    currentPage,
    dataForPage,
    count,
    setCurrentPage,
    pageCount,
  };
}
