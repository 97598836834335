import { Paper, Grid } from "@frontend/ui";
import { ChartWidget } from "@frontend/ui/chart-widget";
import { palette } from "@frontend/ui/theme/palette";
import { formatDate } from "@frontend/ui/utils/formatters";
import { PoolHistoryData, PoolLiquidityData } from "src/pages/uniswap/generated";
import CurrentLiquidityChart from "./current-liquidity-chart";

type Props = {
  historyData?: PoolHistoryData;
  isLoadingHistoryData: boolean;
  poolCurrentLiquidityData?: PoolLiquidityData;
};

const PoolCharts = ({ historyData, isLoadingHistoryData, poolCurrentLiquidityData }: Props) => {
  const timeSeriesVolumeData: [number, number][] =
    historyData?.volume.map((datapoint) => [datapoint.time * 1000, datapoint.value]) || [];

  const timeSeriesFeeData: [number, number][] =
    historyData?.fee.map((datapoint) => [datapoint.time * 1000, datapoint.value]) || [];

  return (
    <Paper variant="card">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="widget">
            <CurrentLiquidityChart currentLiquidity={poolCurrentLiquidityData} />
          </Paper>
        </Grid>
        {timeSeriesFeeData && (
          <Grid item xs={12} lg={6}>
            <Paper variant="widget">
              <ChartWidget
                series={{
                  data: timeSeriesFeeData,
                  name: "Fee",
                  color: palette.blue.main,
                  showArea: true,
                }}
                type="line"
                title="Fees Over Time"
                currency="USD"
                xAxisOptions={{
                  type: "category",
                  axisLabel: {
                    fontSize: 12,
                    formatter: (value: string) => formatDate(new Date(Number(value))),
                    hideOverlap: true,
                  },
                }}
                isLoading={isLoadingHistoryData}
              />
            </Paper>
          </Grid>
        )}
        {timeSeriesVolumeData && (
          <Grid item xs={12} lg={6}>
            <Paper variant="widget">
              <ChartWidget
                series={{
                  data: timeSeriesVolumeData,
                  name: "Volume",
                  color: palette.blue.main,
                  showArea: true,
                }}
                type="line"
                title="Total Volume Over Time"
                currency="USD"
                xAxisOptions={{
                  type: "category",
                  axisLabel: {
                    fontSize: 12,
                    formatter: (value: string) => formatDate(new Date(Number(value))),
                    hideOverlap: true,
                  },
                }}
                isLoading={isLoadingHistoryData}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default PoolCharts;
