import { renderToString } from "react-dom/server";
import { TooltipComponentFormatterCallbackParams } from "echarts";
import { Box, Typography } from "@frontend/ui";
import { PoolLiquidityData } from "src/pages/uniswap/generated";
import { formatAmount } from "@frontend/ui/utils/formatters";

type ParamType = { axisValue: string | number };

const DataRow = ({ title, value }: { title: string; value: string | number }) => (
  <Box display="flex" justifyContent="space-between" gap={2}>
    <Typography variant="caption">{title}</Typography>
    <Typography variant="caption">{value}</Typography>
  </Box>
);

export const currentLiquidityTooltipFormatter =
  (currentLiquidity?: PoolLiquidityData) => (params: TooltipComponentFormatterCallbackParams) => {
    const paramsArr = Array.isArray(params) ? params : [params];
    const tickId = (paramsArr[0] as unknown as ParamType)?.axisValue;
    const currentTick = currentLiquidity?.ticks.find((tick) => tick.tickIdx === Number(tickId));

    if (!currentLiquidity || !currentTick) return "";

    const { token0, token1 } = currentLiquidity;
    const { amount0, amount1, price: token0PriceInToken1 } = currentTick;
    return renderToString(
      <Box display="flex" flexDirection="column">
        <DataRow title={`${token0} Price:`} value={`${formatAmount(token0PriceInToken1)} ${token1}`} />
        <DataRow title={`${token1} Price:`} value={`${formatAmount(1 / token0PriceInToken1)} ${token0}`} />
        {!!amount0 && <DataRow title={`${token0} Locked:`} value={formatAmount(amount0)} />}
        {!!amount1 && <DataRow title={`${token1} Locked:`} value={formatAmount(amount1)} />}
      </Box>,
    );
  };
