import { Box, Button, CustomIcon, IconButton, Typography, useConfirmationMessage } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { CircularProgress, FormLabel, Stack, Switch } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import {
  AlertSetting,
  AlertSettingKeys,
  convertAlertLevelToDisplayName,
  convertAlertRunnerTypeToDisplayName,
} from "./utils";
import { AlertSettingsForm } from "./form-inputs/types";
import { alertSeverityLabels, alertTypeLabels } from "../../../../utils/consts";
import { GeneralInformationSection } from "./form-inputs/general-information-section";
import { FormSection } from "./form-inputs/form-section";
import { TimingConfigurationSection } from "./form-inputs/timing-configuration-section";
import { TriggersConfigurationSection } from "./form-inputs/triggers-configuration-section";
import { NotificationConfigurationSection } from "./form-inputs/notification-configuration-section";
import { ClientsConfigurationSection } from "./form-inputs/clients-configuration-section";
import { useAlertSettings } from "./use-settings-table-data";
import { AlertsPortalRoutes } from "../../../../config/alerts-portal-routes";
import { usePageTitle } from "../../../../hooks";
import { QueryConfigurationSection } from "./form-inputs/query-configuration-section";
import { RouteParams } from "../../../../config/route-params";
import { ExportImportAlertButton } from "./form-inputs/export-import-alert-button";

function convertAlertSettingsToFormValues(alertSetting: AlertSetting | null): AlertSettingsForm | null {
  if (!alertSetting) {
    return null;
  }
  return {
    ...alertSetting,
    runnerType: {
      label: convertAlertRunnerTypeToDisplayName(alertSetting.runnerType),
      value: alertSetting.runnerType ?? "default-runner",
    },
    level: {
      value: alertSetting.level ?? "PROTOCOL",
      label: convertAlertLevelToDisplayName(alertSetting.level),
    },
    severity: {
      value: alertSetting.severity,
      label: alertSeverityLabels[alertSetting.severity],
    },
    alertType: {
      value: alertSetting.alertType,
      label: alertTypeLabels[alertSetting.alertType],
    },
  };
}

export const SettingsDetails = () => {
  usePageTitle("Settings");
  const navigate = useNavigate();
  const { state } = useLocation() as { state: { new: boolean } };
  const { alertId = "" } = useParams<"alertId">();
  const { alertSettings, loading, onUpdate, onAdd } = useAlertSettings();
  const { confirm } = useConfirmationMessage();

  const [alertSetting, setAlertSetting] = useState<AlertSetting | null>(null);

  const methods = useForm<AlertSettingsForm>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: convertAlertSettingsToFormValues(alertSetting) ?? {},
  });

  const { handleSubmit, reset, getFieldState, control } = methods;

  useEffect(() => {
    const obj = alertSettings.find((setting) => setting.alertId === alertId) ?? null;
    setAlertSetting(obj);
    reset(convertAlertSettingsToFormValues(obj) ?? {});
  }, [alertSettings, alertId, reset]);

  // The dirtyFields of the react-form-hook does not work as expected
  // Then this state will save each field that was touched or changed
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});

  // need this to track changes in the form
  const changes = useWatch({
    control,
    name: AlertSettingKeys as (keyof AlertSettingsForm)[],
  });

  useEffect(() => {
    AlertSettingKeys.forEach((field) => {
      if (getFieldState(field as keyof AlertSettingsForm)?.isDirty) {
        setDirtyFields((prev) => ({ ...prev, [field]: true }));
      }
    });
  }, [alertSetting, changes, getFieldState]);

  const [submitting, setSubmitting] = useState(false);
  const [submittingStatus, setSubmittingStatus] = useState<"idle" | "success" | "failure">("idle");

  const getUpdatedFields = (formValues: AlertSettingsForm) =>
    Object.keys(formValues)
      .filter((field) => dirtyFields[field as keyof AlertSettingsForm])
      .reduce((acc, field) => {
        if (field === "level" || field === "severity" || field === "alertType" || field === "runnerType") {
          const currentValue = formValues[field as keyof AlertSettingsForm] as { label: string; value: string };
          return {
            ...acc,
            [field]: currentValue.value,
          };
        }

        return {
          ...acc,
          [field]: formValues[field as keyof AlertSettingsForm],
        };
      }, {} as AlertSetting);

  const getCreatedAlertSetting = (formValues: AlertSettingsForm) => {
    const updatedFields = getUpdatedFields(formValues);

    return {
      ...updatedFields,
      evaluateStep: updatedFields.evaluateStep ?? {
        argumentsSchema: {
          type: "object",
          properties: {},
          required: [],
        },
        defaultArguments: [],
      },
    };
  };

  const onSubmit = (values: AlertSettingsForm) => {
    setSubmitting(true);

    if (state?.new) {
      confirm({
        title: "Confirm Alert Setup",
        message: "Please review to ensure everything is correct, and click “Confirm” to add the alert.",
        onConfirm: () => {
          const alertToAdd = getCreatedAlertSetting(values);
          void onAdd(alertToAdd).then((newAlertSetting) => {
            setSubmitting(false);
            if (newAlertSetting) {
              setSubmittingStatus("success");

              // The next line navigates to the new alert settings page
              // we use window.location.href instead of navigate because a bug in the react-router-dom, that does not update the page
              // navigate(AlertsPortalRoutes.SettingsDetails.replace(RouteParams.Alert, newAlertSetting.alertId), {
              //   replace: true,
              // });
              window.location.href = `${window.location.origin}${AlertsPortalRoutes.SettingsDetails.replace(
                RouteParams.Alert,
                newAlertSetting.alertId,
              )}`;
            } else {
              setSubmittingStatus("failure");
              setTimeout(() => {
                setSubmittingStatus("idle");
              }, 1000);
            }
          });
        },
        onCancel: () => {
          setSubmitting(false);
        },
      });
    } else {
      const updatedValues = getUpdatedFields(values);
      confirm({
        title: "Confirm Changes to Alert Settings",
        message:
          "Are you sure you want to save the changes? This will update the alert configuration and may affect how alerts are triggered and processed.",
        onConfirm: () => {
          void onUpdate(alertId, updatedValues).then((updatedAlertSettings) => {
            setSubmitting(false);
            if (updatedAlertSettings) {
              setSubmittingStatus("success");
              setAlertSetting(updatedAlertSettings);
              reset(convertAlertSettingsToFormValues(updatedAlertSettings) ?? {});
              setDirtyFields({});
            } else {
              setSubmittingStatus("failure");
            }

            setTimeout(() => {
              setSubmittingStatus("idle");
            }, 1000);
          });
        },
        onCancel: () => {
          setSubmitting(false);
        },
      });
    }
  };

  if (loading) return <Loader />;

  if (!alertSetting && !state?.new) {
    navigate(AlertsPortalRoutes.Settings, { replace: true });

    return null;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box height="100%" display="flex" flexDirection="column" position="relative">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            pt={3}
            pb={5}
            gap={2}
            position="sticky"
            top={0}
            zIndex={100}
            bgcolor="background.default"
            borderBottom="1px solid"
            borderColor="divider"
            flexWrap="wrap"
          >
            <Box display="flex" alignItems="center" gap={2}>
              <IconButton
                size="small"
                onClick={() => {
                  navigate(AlertsPortalRoutes.Settings, { replace: true, state: { reset: true } });
                }}
              >
                <CustomIcon icon="arrow-left" />
              </IconButton>
              <Typography variant="h1">{alertSetting?.name ?? "New Alert"}</Typography>
            </Box>
            <Box flex={1} />
            <Box display="flex" flexDirection="row" alignItems="center" gap={2} flexWrap="wrap">
              <ExportImportAlertButton submitting={submitting} isNewAlert={state?.new} />
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <Controller
                  name="enabled"
                  control={control}
                  disabled={submitting}
                  render={({ field }) => (
                    <Switch size="medium" {...field} checked={field.value} onClick={(e) => e.stopPropagation()} />
                  )}
                />
                <FormLabel>Active</FormLabel>
              </Box>
              <Button
                color={
                  submittingStatus === "success" ? "success" : submittingStatus === "failure" ? "error" : "primary"
                }
                disabled={submitting || !Object.keys(dirtyFields).length}
                variant="contained"
                type="submit"
              >
                {submitting && <CircularProgress color="inherit" size="24px" sx={{ marginRight: 2 }} />}
                {submittingStatus === "success" && <DoneIcon />}
                {submittingStatus === "failure" && <ErrorIcon />}
                {submitting
                  ? "Saving"
                  : submittingStatus === "success"
                    ? "Saved"
                    : submittingStatus === "failure"
                      ? "Failed"
                      : state?.new
                        ? "Create"
                        : "Save Changes"}
              </Button>
            </Box>
          </Box>
          <Stack spacing={2}>
            <FormSection title="General Configurations">
              <GeneralInformationSection submitting={submitting} isNewAlert={state?.new} />
            </FormSection>
            <FormSection title="Timing Configurations">
              <TimingConfigurationSection submitting={submitting} />
            </FormSection>
            <FormSection title="Query Configurations">
              <QueryConfigurationSection submitting={submitting} isNewAlert={state?.new} />
            </FormSection>
            <FormSection title="Trigger Configurations">
              <TriggersConfigurationSection submitting={submitting} />
            </FormSection>
            <FormSection title="Notifications">
              <NotificationConfigurationSection submitting={submitting} />
            </FormSection>
            <FormSection title="Clients">
              <ClientsConfigurationSection submitting={submitting} />
            </FormSection>
          </Stack>
        </Box>
      </form>
    </FormProvider>
  );
};
