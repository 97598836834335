import { useEffect, useMemo } from "react";
import { Box } from "@frontend/ui";
import { AssetsPriceChange, Asset } from "@frontend/ui/assets-price-change";
import { Loader } from "@frontend/ui/loader";
import { useClientConfig } from "../../clients-config";
import { useMarketsQuery, useRiskExplorerDataLazyQuery } from "../../generated";
import { LiquidationsTable } from "../liquidations/components";
import { RiskExplorerCards } from "./components";

export const RiskExplorer = () => {
  const { network } = useClientConfig();
  const { data: marketsData } = useMarketsQuery();
  const [loadRiskExplorer, { data: riskExplorerData }] = useRiskExplorerDataLazyQuery();
  const assets = useMemo(
    () =>
      marketsData?.markets.reduce<Record<string, Asset[]>>(
        (res, m) => ({
          [network.name]: [
            ...res[network.name],
            {
              name: m.name,
              symbol: m.symbol,
              mappedSymbol: m.symbol,
              price: m.priceUSD,
            },
          ],
        }),
        { [network.name]: [] },
      ),
    [marketsData, network],
  );

  useEffect(() => {
    if (assets) {
      const chain = Object.keys(assets)[0];
      void loadRiskExplorer({
        variables: {
          query: { chains: [chain], tokenSymbol: assets[chain][0].symbol, priceChangePercent: 0 },
        },
      });
    }
  }, [loadRiskExplorer, assets]);

  return !assets ? (
    <Loader />
  ) : (
    <Box height="100%" display="flex" flexDirection="column" rowGap={5}>
      <AssetsPriceChange
        assets={assets}
        onApply={(query) => {
          void loadRiskExplorer({ variables: { query } });
        }}
        subtitle="Shows the effect of an asset price changes on liquidations."
      />
      <Box display="flex" flexDirection="column" gap={3}>
        <RiskExplorerCards
          liquidatedPositions={riskExplorerData?.riskExplorerLiquidatedPositions.length}
          liquidatedValue={riskExplorerData?.riskExplorerLiquidatedPositions.reduce(
            (s, p) => s + (p.collateralAmountUSD || 0),
            0,
          )}
          pnl={
            riskExplorerData
              ? {
                  current: riskExplorerData.riskExplorerNetPnL.current,
                  simulated: riskExplorerData.riskExplorerNetPnL.simulated,
                  net: riskExplorerData.tradingOverviewStats.current.netPnL,
                }
              : undefined
          }
        />
        <Box>
          <LiquidationsTable
            title="Liquidation Events"
            subtitle="Positions that will be liquidated if asset prices change as simulated."
            events={riskExplorerData?.riskExplorerLiquidatedPositions}
            hideDate
            hideTransaction
            chain={network.name}
          />
        </Box>
      </Box>
    </Box>
  );
};
