import { Route, Routes } from "react-router-dom";
import { ChaosApolloProvider } from "src/utils/graphql";
import { RoutePath } from "src/config/routes";
import { MethodologyPage } from "../../methodology-page";
import { useClientConfig } from "./clients-config";
import TwapPage from "./pages/main-page/twap-page";
import { PoolPage } from "./pages/pool-page";

export const TwapRoutes = () => {
  const { methodology } = useClientConfig();

  return (
    <ChaosApolloProvider product="uniswap">
      <Routes>
        <Route index element={<TwapPage />} />
        <Route path={RoutePath.Twap.PoolDetails.replace(RoutePath.Twap.Home, "")} element={<PoolPage />} />
        <Route
          path={RoutePath.Twap.Methodology.replace(RoutePath.Twap.Home, "")}
          element={<MethodologyPage methodology={methodology} />}
        />
      </Routes>
    </ChaosApolloProvider>
  );
};
