import { Header, RenderData } from "@frontend/types";
import { ChaosTable, chipCell, dateCell, textCell } from "@frontend/ui";
import { DegenReferralPointsResponse } from "../../../api/types";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Faction",
  },
  {
    renderType: "TEXT",
    text: "Referral Name",
  },
  {
    renderType: "TEXT",
    text: "Points",
  },
  {
    renderType: "TEXT",
    text: "Referral Date",
    isDate: true,
  },
];

type Props = {
  referralPoints?: DegenReferralPointsResponse[];
  isLoading: boolean;
};

const ReferralsTable = ({ referralPoints, isLoading }: Props) => {
  const data: RenderData[][] = (referralPoints || []).map((r) => [
    textCell(r.faction.factionName, undefined, undefined, undefined, undefined, r.faction.factionImage),
    textCell(r.referee.username),
    chipCell(r.totalPoints),
    dateCell(new Date(r.referee.createdAt)),
  ]);

  return <ChaosTable title="Referrals Overview" headers={headers} data={data} isLoading={isLoading} />;
};

export default ReferralsTable;
