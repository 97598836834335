import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { useGmPoolQuery } from "src/pages/ccar-perpetuals-page/generated";
import { PoolCards, PoolConfigTable, PoolHistoryCharts } from "./components";

const GmPoolDetails = () => {
  const { marketId } = useParams();
  const { data, loading } = useGmPoolQuery({
    variables: {
      marketId: marketId!,
    },
  });

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <PoolCards gmPool={data?.gmPool} />
      <PoolHistoryCharts />
      <PoolConfigTable
        gmPoolName={data?.gmPool?.poolName}
        gmPoolConfig={data?.gmPool?.poolConfig}
        isLoading={loading}
      />
    </Box>
  );
};

export default GmPoolDetails;
