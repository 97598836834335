import { Box, Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { FullPoolFragment } from "src/pages/lp-dashboard-page/generated";

type Props = {
  data: FullPoolFragment;
};

const PoolStatsCards = ({ data }: Props) => {
  const cards: ValueCardProps[] = [
    {
      title: "Total Liquidity",
      value: data.liquidityUSD,
      currency: "USD",
    },
    {
      title: "Volume 24H",
      value: data.volumeUSD24h,
      currency: "USD",
    },
    {
      title: "Fees 24H",
      value: data.feesUSD24h,
      currency: "USD",
    },
    {
      title: "14d Unbonding APR",
      value: data.incentives ? data.incentives.currentIncentiveAPRs.fourteenDays : "-",
      isPercent: true,
    },
    {
      title: "Projected 14d Unbonding APR",
      tooltip: "Projected APR for this pool, based on recommended incentives and bonded liquidity values",
      value: data.incentives ? data.incentives.targetIncentiveAPRs.fourteenDays : "-",
      isPercent: true,
      bgColor: "#0366C126",
    },
  ];
  return (
    <Box>
      <Grid container>
        {cards.map((cardProps) => (
          <Grid key={cardProps.title} item xs={12} sm={3} lg={12 / 5}>
            <ValueCard {...cardProps} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PoolStatsCards;
