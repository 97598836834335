import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, iconsCell, percentCell, textCell } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { useAvsDataQuery } from "../../../generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Name",
  },
  {
    renderType: "TEXT",
    text: "Amount Delegated to AVS",
  },
  {
    renderType: "TEXT",
    text: "TVR Share %",
  },
  {
    renderType: "TEXT",
    text: "AVS Secured",
  },
];

const OperatorsTable = () => {
  const { avsId } = useParams();
  const { data, loading } = useAvsDataQuery();

  const selectedAvsData = data?.avsData?.find((d) => d.id === avsId);
  const etherfiAvsTvr = selectedAvsData?.protocols.find((p) => p.protocol === "EtherFi")?.delegationEth;

  const tableData: RenderData[][] = (selectedAvsData?.operators || []).map((op) => {
    const amountDelegatedToSelectedAvs = op.tvrEth;

    return [
      textCell(op.name),
      currencyCell(amountDelegatedToSelectedAvs, { cryptoCurrency: "ETH" }),
      percentCell(etherfiAvsTvr && amountDelegatedToSelectedAvs ? amountDelegatedToSelectedAvs / etherfiAvsTvr : 0),
      iconsCell(op.avs),
    ];
  });

  return (
    <ChaosTable
      title="Operators"
      initialSortBy={1}
      isInitialSortDesc
      headers={headers}
      data={tableData}
      isLoading={loading}
      isFullHeight
      isFilterHidden
    />
  );
};

export default OperatorsTable;
