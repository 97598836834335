import { Box, CustomReactSelect, Typography } from "@frontend/ui";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { useLocation, useParams } from "react-router-dom";
import { RouteParams } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { Chain, useEModeCategoriesQuery } from "src/pages/ccar-lending-page/generated";
import { PageHeader } from "@frontend/ui/page-header";
import { getRouteByTabPath } from "./get-tab-route-by-path";

type ParamsType = {
  clientName: string;
  chain: Chain;
  eModeId: string;
};

const ChainEModeDropdownHeader = () => {
  const { clientName, chain, eModeId } = useParams<ParamsType>();
  const location = useLocation();
  const { chains } = useClientConfig();
  const { data } = useEModeCategoriesQuery({ variables: { chain: chain! } });

  const marketsDropdownOptions = chains.map((c) => ({
    value: c,
    label: c,
    cryptoIcon: wrappedSymbolToIconSymbol(c),
    link: getRouteByTabPath(location.pathname)
      .replace(RouteParams.Chain, c)
      .replace(RouteParams.ClientName, clientName!),
  }));
  const eModeCategoriesOptions =
    data?.eModeCategories.map((c) => ({
      value: c.categoryId.toString(),
      label: c.categoryLabel,
      link: location.pathname.replace(eModeId!, c.categoryId.toString()),
    })) || [];

  return (
    <PageHeader
      backLink={getRouteByTabPath(location.pathname)
        .replace(RouteParams.ClientName, clientName!)
        .replace(RouteParams.Chain, chain!)}
      pageTitle={
        <Box display="flex" gap={[1, 2]} alignItems="center" data-testid="page-title">
          <>
            <CustomReactSelect
              options={marketsDropdownOptions}
              value={marketsDropdownOptions.find((option) => option.value === chain!)}
              isSearchable={false}
              variant="h3"
            />
            <Typography variant="h3" color="almostWhite.main">
              /
            </Typography>
          </>
          <CustomReactSelect
            options={eModeCategoriesOptions}
            value={eModeCategoriesOptions.find((option) => option.value === eModeId)}
            isSearchable={false}
            variant="h3"
          />
        </Box>
      }
    />
  );
};

export default ChainEModeDropdownHeader;
