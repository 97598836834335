import { Box } from "@frontend/ui";
import { MethodologyIntro } from "src/components/methodology-intro";
import { PageTracker } from "src/components/page-tracker";
import { PoolsTable, TotalIncentivesCards } from "./components";
import { useClientConfig } from "./clients-config";
import { PoolsDataResponse, usePoolsData } from "../lp-dashboard-page/pages/pools/use-pools-data";
import { useIncentivesSavedQuery } from "../lp-dashboard-page/generated";

const IncentivesOptimizationPage = PageTracker(() => {
  const { methodology } = useClientConfig();
  const poolsDataResponse: PoolsDataResponse = usePoolsData();

  const totalCurrentIncentives = poolsDataResponse?.allPools?.reduce(
    (value, pool) => value + (pool.incentives?.currentDailyIncentivesOSMO || 0),
    0,
  );
  const totalRecommendedIncentives = poolsDataResponse?.allPools?.reduce(
    (value, pool) => value + (pool.incentives?.targetDailyIncentivesOSMO || 0),
    0,
  );
  const totalCurrentLiquidity = poolsDataResponse?.allPools?.reduce((value, pool) => value + pool.liquidityUSD || 0, 0);
  const totalTargetLiquidity = poolsDataResponse?.allPools?.reduce(
    (value, pool) => value + (pool.incentives?.targetLiquidityUSD || 0),
    0,
  );

  const { data: totalIncentivesSavedToDate } = useIncentivesSavedQuery();

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {!!(totalCurrentIncentives && totalRecommendedIncentives && totalCurrentLiquidity && totalTargetLiquidity) && (
        <TotalIncentivesCards
          totalCurrentIncentives={totalCurrentIncentives}
          totalRecommendedIncentives={totalRecommendedIncentives}
          totalIncentivesSavedToDate={totalIncentivesSavedToDate?.incentivesSaved || 0}
          totalCurrentLiquidity={totalCurrentLiquidity}
          totalTargetLiquidity={totalTargetLiquidity}
        />
      )}
      {methodology && <MethodologyIntro methodology={methodology} />}
      <PoolsTable poolsDataResponse={poolsDataResponse} />
    </Box>
  );
});

export default IncentivesOptimizationPage;
