import { ValueType } from "@frontend/ui";
import { TraderStats } from "src/pages/ccar-perpetuals-page/generated";

export const metrics: Partial<
  Record<
    keyof TraderStats,
    {
      name: string;
      valueType?: ValueType;
      tooltip?: string;
      icon?: string;
    }
  >
> = {
  fromDate: {
    name: "First Date",
    valueType: "date",
    icon: "calendar",
  },
  tillDate: {
    name: "Last Date",
    valueType: "date",
    icon: "calendar",
  },
  avgNetExposurePctFromAvgGross: {
    name: "Average Net Exposure Percent From Average Gross",
    valueType: "percent",
    tooltip:
      "Measures how long/short distributed the position is - i.e. for a trader who is always long - 1, always short -1, same long and short exposure - 0.",
    icon: "coins-stack-icon",
  },
  avgPriceImpactBps: {
    name: "Avg Price Impact Bps",
    icon: "waterfall-up",
  },
  informationRatio: {
    name: "Information Ratio",
    tooltip:
      "Checks how significant are the excessive returns over the benchmark - mean(daily excess returns) / std(daily excess returns)",
    icon: "transaction",
  },
  medianFlipTime: {
    name: "Median Flip Time",
    tooltip: "median time between long/short position flip",
    valueType: "duration",
    icon: "clock",
  },
  medianTimeBetweenOrders: {
    name: "Median Time Between Orders",
    valueType: "duration",
    icon: "clock",
  },
  profitPerVolume: {
    name: "Profit Per $1M Volume",
    tooltip: "Profit for every $1M of volume",
    icon: "coin-dollar",
  },
  profitableDaysPct: {
    name: "Profitable Days Percent",
    valueType: "percent",
    tooltip: "% of profitable days",
    icon: "coin-dollar",
  },
  spotAssetReturn: {
    name: "Spot Asset Return",
    valueType: "percent",
    tooltip: "Spot asset return on same period",
    icon: "backward",
  },
  sumPriceImpact: {
    name: "Total Price Impact",
    valueType: "currency",
    icon: "dollar-out",
  },
  fundingFees: {
    name: "Total Funding Fee",
    valueType: "currency",
    icon: "dollar-out",
  },
  borrowingFees: {
    name: "Total Borrowing Fee",
    valueType: "currency",
    icon: "dollar-out",
  },
  positionFee: {
    name: "Total Position Fee",
    valueType: "currency",
    icon: "dollar-out",
  },
};
