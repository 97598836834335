import { formatPercent } from "@frontend/ui/utils/formatters";
import { Theme } from "@mui/material";
import { RiskStatus } from "../types";

export const getHealthText = (health: number, isHealthPercent?: boolean) => {
  if (health === Number.MAX_VALUE) return "∞";

  if (isHealthPercent) {
    const healthAsPercent = health * 100;
    const showDecimals = healthAsPercent > -1 && healthAsPercent < 1;

    return formatPercent(Math.min(health, 1), { maximumFractionDigits: showDecimals ? 2 : 0 });
  }

  if (health >= 100) return "100+";

  return Math.trunc(health * 100) / 100;
};

export const getRiskLabel = (riskStatus: RiskStatus) => {
  switch (riskStatus) {
    case RiskStatus.BAD_DEBT:
      return "Bad Debt";
    case RiskStatus.ELIGIBLE_FOR_LIQUIDATION:
      return "Eligible";
    case RiskStatus.AT_RISK_FOR_LIQUIDATION:
      return "At Risk";
    case RiskStatus.NO_RISK_FOR_LIQUIDATION:
    default:
      return "No Risk";
  }
};

export const getHealthTooltip = (health: number, isHealthPercent?: boolean) => {
  if (health === Number.MAX_VALUE) return "Wallet has no borrow positions";

  if (isHealthPercent) return "";

  return health.toFixed(4);
};

export const getStatusChangeTooltip = (health: number, statusChange: boolean) => {
  if (statusChange) return `Wallet moved to '${getRiskLabel(health)}' after price change`;

  return undefined;
};

export const getRiskLabelColor = (theme: Theme, riskStatus: RiskStatus) => {
  switch (riskStatus) {
    case RiskStatus.BAD_DEBT:
    case RiskStatus.ELIGIBLE_FOR_LIQUIDATION:
      return theme.palette.error.main;
    case RiskStatus.AT_RISK_FOR_LIQUIDATION:
      return theme.palette.warning.main;
    case RiskStatus.NO_RISK_FOR_LIQUIDATION:
    default:
      return theme.palette.success.main;
  }
};
