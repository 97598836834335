import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { Grid } from "@frontend/ui";
import { getDeltaFields } from "@frontend/ui/trend";
import isBoolean from "lodash/isBoolean";
import { AggregateAssetStatsResultsFragment } from "../../../generated";

type Props = {
  stats?: AggregateAssetStatsResultsFragment;
  isBorrowable?: boolean;
};

const StatCards = ({ stats, isBorrowable }: Props) => {
  const currentStats = stats?.current;
  const previousStats = stats?.previous;

  const {
    collateralAtRisk: currentValueAtRisk,
    totalBorrow: currentTotalBorrow,
    totalSupply: currentTotalSupply,
    totalBorrowPowerUsd: currentTotalBorrowPowerUsd,
  } = currentStats || {};

  const hasCurrentUtilizationRate =
    currentTotalBorrow !== undefined && currentTotalSupply !== undefined && currentTotalSupply > 0;
  const currentUtilizationRate = hasCurrentUtilizationRate ? currentTotalBorrow / currentTotalSupply : undefined;
  const currentUtilizationRateValue = currentTotalSupply === 0 ? "N/A" : currentUtilizationRate;

  const {
    collateralAtRisk: previousValueAtRisk,
    totalBorrow: previousTotalBorrow,
    totalSupply: previousTotalSupply,
    totalBorrowPowerUsd: previousTotalBorrowPowerUsd,
  } = previousStats || {};

  const previousUtilizationRate =
    previousTotalBorrow !== undefined && previousTotalSupply !== undefined
      ? previousTotalBorrow / previousTotalSupply
      : undefined;

  const defaultCards = [1, 2, 3, 4].map(() => ({
    title: "",
    value: undefined,
    icon: "",
    currency: "USD",
  }));

  let cardsProps: ValueCardProps[] = defaultCards;

  const borrowableAssetCardProps = [
    {
      title: "Total Supply",
      value: currentTotalSupply,
      currency: "USD",
      ...getDeltaFields(currentTotalSupply, previousTotalSupply, true),
    },
    {
      title: "Total Borrow",
      value: currentTotalBorrow,
      currency: "USD",
      ...getDeltaFields(currentTotalSupply, previousTotalSupply, true),
    },
    {
      title: "Utilization Rate",
      value: currentUtilizationRateValue,
      isPercent: true,
      ...getDeltaFields(currentUtilizationRate, previousUtilizationRate, true),
    },
    {
      title: "Collateral at Risk",
      value: currentValueAtRisk,
      currency: "USD",
      ...getDeltaFields(currentValueAtRisk, previousValueAtRisk, false),
    },
  ];

  const collateralAssetCardProps = [
    {
      title: "Total Collateral",
      value: currentTotalSupply,
      currency: "USD",
      ...getDeltaFields(currentTotalSupply, previousTotalSupply, true),
    },
    {
      title: "Collateral at Risk",
      value: currentValueAtRisk,
      currency: "USD",
      ...getDeltaFields(currentValueAtRisk, previousValueAtRisk, false),
    },
    {
      title: "Borrow Power",
      value: currentTotalBorrowPowerUsd,
      currency: "USD",
      ...getDeltaFields(currentTotalBorrowPowerUsd, previousTotalBorrowPowerUsd, true),
    },
  ];

  if (isBoolean(isBorrowable)) {
    cardsProps = isBorrowable ? borrowableAssetCardProps : collateralAssetCardProps;
  }

  return (
    <Grid container>
      {cardsProps.map((cardProps, i) => (
        <Grid
          key={`${cardProps.title}${i.toString()}`}
          item
          zeroMinWidth
          xs={12}
          md={6}
          lg={3}
          data-testid="asset-stats"
        >
          <ValueCard {...cardProps} />
        </Grid>
      ))}
    </Grid>
  );
};

export default StatCards;
