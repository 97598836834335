import { Box } from "@frontend/ui";
import { FieldArray } from "formik";
import { AssetWithSentiment, LpStrategyAssets, UniswapChain } from "src/pages/uniswap/generated";
import { AssetSentimentSelectRow } from "./asset-sentiment-select-row";

type Props = {
  lpStrategyAssets: LpStrategyAssets[];
  assetSentimentValues?: AssetWithSentiment[];
  setFieldValue: (field: string, value: any) => Promise<any>;
};

const AssetExposureSpecificSelect = ({ lpStrategyAssets, assetSentimentValues, setFieldValue }: Props) => {
  const lpStrategyAssetsByChain: Record<UniswapChain, string[]> = lpStrategyAssets.reduce(
    (dict, item) => ({
      ...dict,
      [item.chain]: item.tokens,
    }),
    {} as Record<UniswapChain, string[]>,
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <FieldArray
        name="specificAssetsExposure"
        render={(arrayHelpers) => (
          <Box display="flex" flexDirection="column" gap={3} width="100%">
            {assetSentimentValues &&
              lpStrategyAssetsByChain &&
              assetSentimentValues.map((assetSentimentValue, index) => (
                <AssetSentimentSelectRow
                  lpStrategyAssetsByChain={lpStrategyAssetsByChain}
                  key={`${assetSentimentValue.chain}-${assetSentimentValue.token}-${assetSentimentValue.price_sentiment}-${index}`}
                  assetSentimentValue={assetSentimentValue}
                  index={index}
                  canAddRow
                  setFieldValue={setFieldValue}
                  onAddRow={() =>
                    arrayHelpers.push({
                      chain: assetSentimentValue.chain,
                      token: assetSentimentValue.token,
                      price_sentiment: assetSentimentValue.price_sentiment,
                    })
                  }
                  onRemoveRow={(indexToRemove) => arrayHelpers.remove(indexToRemove)}
                />
              ))}
          </Box>
        )}
      />
    </Box>
  );
};

export default AssetExposureSpecificSelect;
