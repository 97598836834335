import { Box, Typography } from "@frontend/ui";

export const FormSection = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <Box display="flex" flexDirection="column" gap={2} width="100%">
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="divider"
      width="100%"
      py={1}
    >
      <Typography
        variant="h4"
        ml={1}
        sx={{
          textTransform: "uppercase",
        }}
      >
        {title}
      </Typography>
    </Box>
    {children}
  </Box>
);
