import { Header, RenderData } from "@frontend/types";
import {
  Box,
  Button,
  ChaosTable,
  CustomMultiReactSelect,
  Heartbeat,
  iconsCell,
  labelsCell,
  MultiOptionType,
  textCell,
  Typography,
} from "@frontend/ui";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@frontend/ui/auth";
import { usePageTitle } from "../../../../hooks";
import { CcarClient, mapProtocolToData, ProtocolCategory } from "../alerts/utils";
import { alertSeverityLabels, alertSeverityStatus, alertTypeLabels } from "../../../../utils/consts";
import { useAlertSettings } from "./use-settings-table-data";
import { AlertsPortalRoutes } from "../../../../config/alerts-portal-routes";
import { RouteParams } from "../../../../config/route-params";

const alertsHeaders: Header[] = [
  {
    renderType: "TEXT",
    text: "Name",
    field: "name",
  },
  {
    renderType: "TEXT",
    text: "Severity",
    field: "severity",
    width: "80px",
  },
  {
    renderType: "TEXT",
    text: "Type",
    field: "type",
  },
  {
    renderType: "TEXT",
    text: "Frequency",
    field: "frequencyInMinutes",
    suffix: "Minutes",
  },
  {
    renderType: "ICONS",
    text: "Protocols",
    nonSortable: true,
    hideFilter: true,
  },
  {
    renderType: "TEXT",
    text: "Status",
    field: "enabled",
  },
  {
    renderType: "TEXT",
    text: "Heartbeat",
    field: "nextRun",
  },
];

export const SettingsTab = () => {
  usePageTitle("Settings");
  const navigate = useNavigate();
  const auth = useAuth();
  const { filteredAlerts, loading, onProtocolsFilterChange, onSearch, selectedAlertLevel, selectedProtocols } =
    useAlertSettings();

  const rows = filteredAlerts?.map((alertSetting) => {
    const frequencyFactor =
      alertSetting.frequencyInMinutes % 1440 === 0 ? 1440 : alertSetting.frequencyInMinutes % 60 === 0 ? 60 : 1;
    const frequencyUnits = frequencyFactor === 1440 ? "Days" : frequencyFactor === 60 ? "Hours" : "Minutes";

    return [
      textCell(alertSetting.name),
      labelsCell(alertSeverityStatus[alertSetting.severity], alertSeverityLabels[alertSetting.severity]),
      textCell(alertTypeLabels[alertSetting.alertType]),
      textCell(
        `${alertSetting.frequencyInMinutes / frequencyFactor} ${frequencyUnits}`,
        alertSetting.frequencyInMinutes,
      ),
      iconsCell(
        Object.entries(alertSetting.clientsConfig ?? {})
          .filter(([, config]) => config.enabled)
          .map(([ccarClient]) => ccarClient),
        "",
        Object.entries(alertSetting.clientsConfig ?? {})
          .filter(([, config]) => config.enabled)
          .map(
            ([ccarClient]) =>
              `${mapProtocolToData[ccarClient as CcarClient]?.name} ${
                mapProtocolToData[ccarClient as CcarClient]?.version
              }`,
          )
          .join(" ,"),
        "",
        Object.entries(alertSetting.clientsConfig ?? {})
          .filter(([, config]) => config.enabled)
          .reduce(
            (acc, [ccarClient]) => ({
              ...acc,
              [ccarClient]: mapProtocolToData[ccarClient as CcarClient]?.icon,
            }),
            {},
          ),
      ),
      labelsCell(alertSetting.enabled ? "OK" : "ERROR", alertSetting.enabled ? "Active" : "Inactive"),
      {
        renderType: "CUSTOM",
        component:
          alertSetting.enabled && alertSetting.nextRun ? (
            <Heartbeat nextTimestamp={alertSetting.nextRun} frequencyInMinutes={alertSetting.frequencyInMinutes} />
          ) : (
            ""
          ),
      },
    ] as RenderData[];
  });

  const groupClientsByCategory = Object.entries(mapProtocolToData).reduce(
    (acc, [ccarClient, { category }]) => {
      if (!acc[category]) {
        acc[category] = {
          value: category,
          label: category,
          groupValues: [],
        };
      }

      acc[category].groupValues?.push(ccarClient);
      return acc;
    },
    {} as Record<ProtocolCategory, MultiOptionType>,
  );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" my={5}>
        <Typography variant="h1">Alert Settings</Typography>
        <Button
          color="primary"
          variant="contained"
          disabled={!auth.hasRole("alert-creators-group")}
          onClick={() => {
            navigate(AlertsPortalRoutes.SettingsDetails.replace(RouteParams.Alert, `new-alert-${Date.now()}`), {
              replace: true,
              state: { new: true },
            });
          }}
        >
          Create Alert
        </Button>
      </Box>
      <ChaosTable
        pageSize={10}
        isFullHeight
        isLoading={loading}
        isSettingsHidden
        title=" "
        headers={alertsHeaders}
        data={rows}
        emptyState={{
          icon: "warning",
          title: "No Alert Settings Found",
        }}
        rowHref={(rowId) => filteredAlerts?.[rowId]?.alertId ?? ""}
        showRowChevron
        showSearch
        onSearch={onSearch}
        customFilter={
          selectedAlertLevel !== "GLOBAL" ? (
            <CustomMultiReactSelect
              sx={{
                width: "440px",
              }}
              controlShouldRenderValue
              placeholder="Select Protocol"
              tooltip="Filter by protocol"
              options={[
                ...Object.values(groupClientsByCategory),
                ...Object.entries(mapProtocolToData).map(([value, { name, version, icon }]) => ({
                  label: `${name} ${version}`,
                  value,
                  cryptoIcon: icon,
                })),
              ]}
              selectAllLabel="All"
              value={selectedProtocols}
              onChange={(e) => {
                onProtocolsFilterChange(e as MultiOptionType[]);
              }}
              allowSelectAll
              multiValueProps={{
                maxItems: 2,
              }}
            />
          ) : undefined
        }
      />
    </Box>
  );
};
