import { useState } from "react";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { daysAgoByTimeSpan } from "@frontend/ui/echarts/utils";
import { Box, Grid } from "@frontend/ui";
import { TimeSpanPicker } from "../../../../../ccar-lending-page/components/time-span-picker";
import { useWithdrawalsAggHistoryQuery } from "../../../../generated";
import WithdrawalsWaitTimeChart from "./withdrawals-wait-time-chart";
import WithdrawalsAmountChart from "./withdrawals-amount-chart";
import WithdrawalWaitTimeDistribution from "./withdrawal-wait-time-distribution";

const WithdrawalsCharts = () => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan.Quarter);
  const { data, loading } = useWithdrawalsAggHistoryQuery({
    variables: {
      daysAgo: daysAgoByTimeSpan[timeSpan],
    },
  });

  return (
    <Box>
      <Box marginLeft="auto">
        <TimeSpanPicker
          timeSpanOptions={[TimeSpan.Month, TimeSpan.Quarter, TimeSpan.Year]}
          selectedTimeSpan={timeSpan}
          onSelectTimeSpan={setTimeSpan}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WithdrawalsWaitTimeChart timeSpan={timeSpan} data={data?.withdrawalsAggHistory} isLoading={loading} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WithdrawalsAmountChart timeSpan={timeSpan} data={data?.withdrawalsAggHistory} isLoading={loading} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WithdrawalWaitTimeDistribution />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WithdrawalsCharts;
