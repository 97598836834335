import { PropsWithChildren, ReactNode, useContext, useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { Box } from "@frontend/ui";
import { ChaosApolloProvider } from "src/utils/graphql";
import { Chain } from "src/pages/ccar-lending-page/generated";
import { RiskTabsLayout } from "src/pages/ccar-lending-page/components/risk-tabs-layout";
import ChainDropdownHeader from "./chain-dropdown-header";
import ChainAssetDropdownHeader from "./chain-asset-dropdown-header";
import ChainEModeDropdownHeader from "./chain-e-mode-dropdown-header";
import { ListedAssetsContext } from "../context/listed-assets-context";
import { AssetsRecommendationsContext } from "../context/assets-recommendations-context";

export const replaceHomePath = (path: string) => path.replace(RoutePath.Risk.Chains, "");

export const DefaultChainPageTabRedirect = () => {
  const { clientName, chain } = useParams();
  return (
    <Navigate
      to={RoutePath.Risk.ChainDetailsOverview.replace(RouteParams.ClientName, clientName!).replace(
        RouteParams.Chain,
        chain!,
      )}
      replace
    />
  );
};

type PageLayoutProps = {
  pageType: "chain" | "asset" | "e-mode";
};

const PageLayout = ({ children, pageType }: PropsWithChildren<PageLayoutProps>) => {
  const location = useLocation();
  const { chain } = useParams<{ chain: Chain }>();
  const listedAssetContext = useContext(ListedAssetsContext);
  const assetsRecommendationsContext = useContext(AssetsRecommendationsContext);

  const proposedTabPath = location.pathname.split("/").pop()!;
  const isProposedTab = window.location.pathname.includes(proposedTabPath);

  useEffect(() => {
    if (chain) {
      listedAssetContext.setChain(chain);
      assetsRecommendationsContext.setChain(chain);
    }
  }, [chain, listedAssetContext, assetsRecommendationsContext]);

  return (
    <RiskTabsLayout>
      <Box display="flex" flexDirection="column">
        {pageType === "chain" && <ChainDropdownHeader />}
        {pageType === "asset" &&
          (isProposedTab ? (
            <ChaosApolloProvider product="asset-listing">
              <ChainAssetDropdownHeader />
            </ChaosApolloProvider>
          ) : (
            <ChainAssetDropdownHeader />
          ))}
        {pageType === "e-mode" && <ChainEModeDropdownHeader />}
        {children}
      </Box>
    </RiskTabsLayout>
  );
};

export const renderChainPageLayout = (children: ReactNode) => <PageLayout pageType="chain">{children}</PageLayout>;

export const renderAssetPageLayout = (children: ReactNode) => <PageLayout pageType="asset">{children}</PageLayout>;

export const renderEModePageLayout = (children: ReactNode) => <PageLayout pageType="e-mode">{children}</PageLayout>;
