import { Box, ChaosTable } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { useParams } from "react-router-dom";
import { RoutePath, RouteParams } from "src/config/routes";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { PoolsDataResponse } from "src/pages/lp-dashboard-page/pages/pools/use-pools-data";
import { Loader } from "@frontend/ui/loader";
import { Pool } from "src/pages/lp-dashboard-page/generated";
import { PoolHeaderCell } from "src/components/custom-table-cells";

type Props = {
  poolsDataResponse: PoolsDataResponse;
};

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Pool Name",
    width: "20%",
  },
  {
    renderType: "TEXT",
    text: "Current Liquidity",
    width: "10%",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "Target Liquidity",
    width: "10%",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "Current Osmo APR",
    width: "10%",
    suffix: "%",
  },
  {
    renderType: "TEXT",
    text: "Adjusted Osmo APR",
    width: "10%",
    suffix: "%",
  },
  {
    renderType: "TEXT",
    text: "Osmosis Incentives",
    suffix: "OSMO",
    width: "20%",
  },
  {
    renderType: "TEXT",
    text: "Recommended Change (Osmo)",
    width: "12.5%",
  },
  {
    renderType: "TEXT",
    text: "Recommended Change (%)",
    width: "12.5%",
    suffix: "%",
  },
];

const PoolsTable = ({ poolsDataResponse }: Props) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { isLoading, allPools } = poolsDataResponse;

  if (isLoading) return <Loader />;

  const tableData: RenderData[][] | undefined = !allPools
    ? undefined
    : allPools.map(
        (pool: Pool) =>
          [
            {
              renderType: "CUSTOM",
              text: `${pool.token1.symbol}/${pool.token2.symbol}`,
              component: <PoolHeaderCell token1={pool.token1.symbol} token2={pool.token2.symbol} poolId={pool.id} />,
              value: Number(pool.id),
              filterByValue: pool.id,
              searchTerm: `#${pool.id} ${pool.token1.symbol}/${pool.token2.symbol}`,
              exportText: `#${pool.id} ${pool.token1.symbol}/${pool.token2.symbol}`,
            },
            {
              renderType: "TEXT",
              text: formatAmount(pool.liquidityUSD, { currency: "USD", notation: "standard" }),
              value: pool.liquidityUSD,
            },
            {
              renderType: "TEXT",
              text: pool.incentives
                ? formatAmount(pool.incentives.targetLiquidityUSD, { currency: "USD", notation: "standard" })
                : "-",
              value: pool.incentives ? pool.incentives.targetLiquidityUSD : 0,
            },
            {
              renderType: "TEXT",
              text: pool.incentives
                ? formatAmount(pool.incentives.currentIncentiveAPRs.fourteenDays, {
                    isPercent: true,
                    notation: "standard",
                  })
                : "-",
              value: pool.incentives ? pool.incentives.currentIncentiveAPRs.fourteenDays * 100 : 0,
            },
            {
              renderType: "TEXT",
              text: pool.incentives
                ? formatAmount(pool.incentives.targetIncentiveAPRs.fourteenDays, {
                    isPercent: true,
                    notation: "standard",
                  })
                : "-",
              value: pool.incentives ? pool.incentives.targetIncentiveAPRs.fourteenDays * 100 : 0,
            },
            {
              renderType: "RECOMMENDATION",
              token1: "osmo",
              previousText: pool.incentives
                ? formatAmount(pool.incentives.currentDailyIncentivesOSMO, { notation: "standard" })
                : "-",
              text: pool.incentives
                ? formatAmount(pool.incentives.targetDailyIncentivesOSMO, { notation: "standard" })
                : "-",
              value: pool.incentives ? pool.incentives.targetDailyIncentivesOSMO : 0,
            },
            {
              renderType: "TEXT",
              token1: "osmo",
              text:
                pool.incentives && pool.incentives.currentDailyIncentivesOSMO !== 0
                  ? formatAmount(
                      pool.incentives.targetDailyIncentivesOSMO - pool.incentives.currentDailyIncentivesOSMO,
                      { notation: "standard" },
                    )
                  : "-",
              value:
                pool.incentives && pool.incentives.currentDailyIncentivesOSMO !== 0
                  ? pool.incentives.targetDailyIncentivesOSMO - pool.incentives.currentDailyIncentivesOSMO
                  : 0,
            },
            {
              renderType: "TEXT",
              text:
                pool.incentives && pool.incentives.currentDailyIncentivesOSMO !== 0
                  ? formatAmount(
                      (pool.incentives.targetDailyIncentivesOSMO - pool.incentives.currentDailyIncentivesOSMO) /
                        pool.incentives.currentDailyIncentivesOSMO,
                      { isPercent: true },
                    )
                  : "-",
              value:
                pool.incentives && pool.incentives.currentDailyIncentivesOSMO !== 0
                  ? ((pool.incentives.targetDailyIncentivesOSMO - pool.incentives.currentDailyIncentivesOSMO) /
                      pool.incentives.currentDailyIncentivesOSMO) *
                    100
                  : 0,
            },
          ] as RenderData[],
      );

  const handleRowHref = (rowId: number) => {
    const pool = allPools ? allPools[rowId] : undefined;

    if (!pool || !clientName) return "";

    return RoutePath.IncentivesOptimization.Pool.replace(RouteParams.ClientName, clientName).replace(
      RouteParams.PoolId,
      pool.id.toString(),
    );
  };

  return (
    <Box>
      {tableData && (
        <ChaosTable
          headers={headers}
          data={tableData}
          title="Recommendations"
          rowHref={handleRowHref}
          showSearch
          isInitialSortEnable
          initialSortBy={0}
          pageSize={15}
          minWidth={1100}
        />
      )}
    </Box>
  );
};

export default PoolsTable;
