import { Grid } from "@frontend/ui";
import TvrPieChart from "./tvr-pie-chart";
import OperatorsBreakdownByAvs from "./operators-breakdown-by-avs";

export const NodeOperatorsCharts = () => (
  <Grid container>
    <Grid item xs={12}>
      <TvrPieChart />
    </Grid>
    <Grid item xs={12}>
      <OperatorsBreakdownByAvs />
    </Grid>
  </Grid>
);
