import { Chain } from "../ccar-lending-page/generated";
import { ClientConfig } from "../methodology-page/types";

export type Client = "aave" | "benqi" | "venus" | "seamless";
export type PublicSimulationsType =
  | "AaveParamRecommendations"
  | "BenqiParamRecommendations"
  | "VenusParamRecommendations"
  | "SeamlessParamRecommendations";

type SimulationParamConfig = {
  name: string;
  tooltip: string;
};

export const aaveParams = ["liquidationThreshold", "liquidationBonus", "loanToValue"] as const;
type AAVEParamType = (typeof aaveParams)[number];

export const aaveParamsConfig: Record<AAVEParamType, SimulationParamConfig> = {
  liquidationThreshold: {
    name: "Liquidation Threshold",
    tooltip: "The percentage at which a position is defined as undercollateralized and eligible for liquidation.",
  },
  liquidationBonus: {
    name: "Liquidation Penalty",
    tooltip:
      "A fee rendered on the price of assets of the collateral when liquidators purchase it as part of the liquidation.",
  },
  loanToValue: {
    name: "Loan To Value",
    tooltip: "The maximum amount of assets that can be borrowed when using this asset as collateral.",
  },
} as const;

export const benqiParams = ["liquidationThreshold"] as const;
type BenqiParamType = (typeof benqiParams)[number];

export const benqiParamsConfig: Record<BenqiParamType, SimulationParamConfig> = {
  liquidationThreshold: {
    name: "Collateral Factor",
    tooltip: "The maximum amount of assets that can be borrowed when using this asset as collateral.",
  },
} as const;

export type SimulationParamType = AAVEParamType | BenqiParamType;

export type AssetsTableConfig = {
  showRecommendationsDiffsColumns?: boolean;
  hideChaosUtilityColumn?: boolean;
};

export type ParameterRecommendationsClientConfig = ClientConfig & {
  publicSimulations: boolean;
  endpointsPrefix: string;
  isMultiChain: boolean;
  isMultiMarket: boolean;
  isChainMarket?: boolean;
  hasProposedAssets?: boolean;
  chain?: Chain;
  assetsTableConfig?: AssetsTableConfig;
  simulationParams: readonly SimulationParamType[];
  simulationParamsConfig: Partial<Record<SimulationParamType, SimulationParamConfig>>;
  publicSimulationsType: PublicSimulationsType;
  onChainSimulationEnabled?: boolean;
  distributionHistogramIncludeZerosDefault?: boolean;
  capsMethodologyUrl?: string;
};
