import { PageHeader } from "@frontend/ui/page-header";
import { useParams } from "react-router-dom";
import { RouteParams } from "../../../../../config/route-params";
import { RoutePath } from "../../../../../config/routes";

interface PoolDetailsHeaderProps {
  title: string;
  poolId?: string;
}

const PoolDetailsHeader = ({ title, poolId }: PoolDetailsHeaderProps) => {
  const { clientName } = useParams();

  const backLink = RoutePath.Risk.Pools.replace(RouteParams.ClientName, clientName!);
  return (
    <PageHeader
      backLink={backLink}
      pageTitle={title}
      showBackButton
      extraData={{
        text: poolId!,
        allowCopy: true,
      }}
    />
  );
};

export default PoolDetailsHeader;
