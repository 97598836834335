import { ComponentProps } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "../button";
import { Link } from "../link";
import { Typography } from "../typography";

type Props = {
  children: React.ReactNode;
  link: string;
  isExternalLink?: boolean;
};

export const LinkButton = ({ children, link, isExternalLink }: Props) => {
  const content = <Typography variant="h5">{children}</Typography>;
  const commonLinkProps: ComponentProps<typeof Link> = {
    underline: "none",
    color: "inherit",
    px: 3,
    py: 1.5,
  };

  return (
    <Button color="primary" sx={{ p: 0 }}>
      {!isExternalLink ? (
        <Link component={RouterLink} to={link} {...commonLinkProps}>
          {content}
        </Link>
      ) : (
        <Link href={link} target="_blank" {...commonLinkProps}>
          {content}
        </Link>
      )}
    </Button>
  );
};
