import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { clientViewProps } from './client-view-props';

const HomPageRedirect = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const clientsProps = clientViewProps();
  const clientHomePath = (clientsProps?.homePath || clientName) ?? '';

  const navigate = useNavigate();

  useEffect(() => {
    if (clientHomePath) {
      window.location.href = `https://chaoslabs.xyz/customers/${clientHomePath}`;
    } else {
      throw new Error('Client name is required');
    }
  }, [clientHomePath, navigate]);

  return null;
};

export default HomPageRedirect;
