import { useParams } from "react-router-dom";
import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { RouteParams, RoutePath } from "src/config/routes";
import { WithUnifiedProductRedirect } from "src/utils/feature-flags";
import StatCards from "./components/stats-cards";
import { Client } from "../../types";
import { useAggrAssetStatsQuery } from "../../generated";
import MultiValueStatsCard from "./components/multivalue-stats-card";
import { AssetCrossChainDistribution, CrossChainExposure, MarketCapDistribution, ValueAtRisk } from "./components";

const AssetDetailsInner = () => {
  const { asset: tokenSymbol = "" } = useParams<{ clientName: Client; asset: string }>();
  const { data: assetStats, loading: loadingAssetStats } = useAggrAssetStatsQuery({
    variables: { tokenSymbol },
  });
  const asset = assetStats?.aggrAssetStats.current;

  return (
    <Box display="flex" flexWrap="nowrap" flexDirection="column" height="100%" width="100%">
      <PageHeader
        pageTitle={tokenSymbol}
        icon={wrappedSymbolToIconSymbol(tokenSymbol)}
        suffixComponent={<MultiValueStatsCard isLoading={loadingAssetStats} assetStats={assetStats?.aggrAssetStats} />}
      />
      <Box display="flex" flexDirection="column" flex={1}>
        <Box mb={[1, 2, 3]} display="flex" flexWrap="wrap" gap={1}>
          <StatCards stats={assetStats?.aggrAssetStats} isBorrowable={asset?.isBorrowable} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <AssetCrossChainDistribution asset={asset} />
          <CrossChainExposure asset={asset} />
          <ValueAtRisk asset={asset} />
          <MarketCapDistribution asset={asset} />
        </Box>
      </Box>
    </Box>
  );
};

export const AssetDetails = () => (
  <WithUnifiedProductRedirect>
    <AssetDetailsInner />
  </WithUnifiedProductRedirect>
);
