import { Box, Link, Typography } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { PageTracker } from "src/components/page-tracker";
import { PoolPageWrapper } from "src/components/pool-page-wrapper";
import { RouteParams, RoutePath } from "src/config/routes";
import { PoolCharts } from "src/pages/lp-dashboard-page/pages/pool-details/components";
import { RecommendationsCards, RecommendationsTable } from "./components";

const RecommendationPoolPage = PageTracker(() => {
  const { clientName } = useParams<{ clientName: string }>();

  return (
    <PoolPageWrapper backPageRoute={RoutePath.IncentivesOptimization.Home}>
      {(poolDetails) => (
        <Box display="flex" flexWrap="nowrap" flexDirection="column" height="100%" width="100%" gap={3}>
          <RecommendationsCards poolDetails={poolDetails} />
          <Box paddingTop={3}>
            <Typography variant="h2">Optimized Liquidity Incentives</Typography>
            <Typography variant="subtitle1">
              Recommended incentives for this pool according to the model created by Hathor Nodes to optimize the
              Osmosis incentives program.
            </Typography>
            <Link
              href={RoutePath.IncentivesOptimization.Methodology.replace(RouteParams.ClientName, clientName!)}
              style={{ textDecoration: "none", color: "#31d8bf" }}
            >
              <Typography variant="subtitle1">Discover Methodology</Typography>
            </Link>
          </Box>
          <RecommendationsTable poolDetails={poolDetails} />
          <PoolCharts data={poolDetails} />
        </Box>
      )}
    </PoolPageWrapper>
  );
});

export default RecommendationPoolPage;
