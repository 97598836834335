import { Box, Tooltip, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import {
  LpStrategyOutput,
  LpStrategyTimeSeriesDataQueryResult,
  LpStrategyTimeSeriesDataQueryVariables,
  useLpStrategyTimeSeriesDataQuery,
} from "src/pages/uniswap/generated";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import PositionDetails from "./position-details";
import StrategyReturnsChart from "./strategy-returns-chart";
import PositionBoundsChart from "./position-bounds-chart";

type Props = {
  data: LpStrategyOutput;
};

const CalculatedResultsPool = ({ data }: Props) => {
  const inputs: LpStrategyTimeSeriesDataQueryVariables = {
    mainStrategyBlob: data.main_strategy_blob,
    buyHoldStrategyBlob: data.buy_hold_strategy_blob,
    lvrStrategyBlob: data.lvr_strategy_blob,
  };

  const timeSeriesQueryResult: LpStrategyTimeSeriesDataQueryResult = useLpStrategyTimeSeriesDataQuery({
    variables: inputs,
  });

  return (
    <Box display="flex" flexDirection="column" bgcolor="black.main" gap={3} width="100%" padding={1}>
      <Box display="flex" flexWrap="wrap" flexDirection="row" justifyContent="space-between" gap={3}>
        <PositionDetails
          title="Initial Position"
          token0={data.token0}
          token1={data.token1}
          data={data.initial_position}
        />
        <PositionDetails
          title="Max Position"
          tooltip="Highest value of the position throughout the backtesting period"
          token0={data.token0}
          token1={data.token1}
          data={data.max_position}
        />
        <PositionDetails
          title="Min Position"
          tooltip="Lowest value of the position throughout the backtesting period"
          token0={data.token0}
          token1={data.token1}
          data={data.min_position}
        />
        <Box display="flex" flexDirection="column">
          <Typography variant="h6" color="almostWhite.main">
            Rebalances
          </Typography>
          <Typography>{data.rebalances}</Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Tooltip title="The percentage of time in which funds were deployed to a position in the pool">
            <Typography variant="h6" color="almostWhite.main">
              Time in Market (%)
            </Typography>
          </Tooltip>
          <Typography>{formatAmount(data.time_in_market, { isPercent: true })}</Typography>
        </Box>
      </Box>
      {timeSeriesQueryResult && (
        <Box>
          {timeSeriesQueryResult.loading && <ShimmerLoader sx={{ height: 160, borderRadius: 2, width: "100%" }} />}
          {timeSeriesQueryResult.data && (
            <Box display="flex" flexDirection="column" gap={1}>
              <Box height="350px">
                <StrategyReturnsChart data={timeSeriesQueryResult.data.lpStrategyTimeSeriesData} />
              </Box>
              <Box height="350px">
                <PositionBoundsChart
                  quoteToken={data.token1}
                  data={timeSeriesQueryResult.data.lpStrategyTimeSeriesData}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default CalculatedResultsPool;
