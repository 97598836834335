import { FC, ReactNode, createContext, useContext, useMemo } from "react";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { usePegKeepersQuery, PegKeeper } from "src/pages/ccar-lending-page/generated";

export type PegKeepersContextType = {
  pegKeepers: PegKeeper[];
  isLoading: boolean;
};

const initialState: PegKeepersContextType = {
  isLoading: false,
  pegKeepers: [],
};

export const PegKeepersContext = createContext<PegKeepersContextType>(initialState);

export const usePegKeepersContext = () => {
  const context = useContext(PegKeepersContext);
  return context;
};

const PegKeepersProvider: FC<ReactNode> = ({ children }) => {
  const { stableCoin } = useClientConfig();
  const { loading, data } = usePegKeepersQuery({
    skip: !stableCoin?.hasPegKeepers,
  });
  const contextState: PegKeepersContextType = useMemo(
    () => ({
      pegKeepers: data?.pegKeepers || [],
      isLoading: loading,
    }),
    [data?.pegKeepers, loading],
  );

  return <PegKeepersContext.Provider value={contextState}>{children}</PegKeepersContext.Provider>;
};

export default PegKeepersProvider;
