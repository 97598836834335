import { Pool, useAllPoolsQuery } from "../../generated";

export type PoolsDataResponse = {
  isLoading: boolean;
  allPools?: Pool[];
  superFluidPools?: Pool[];
};
export const usePoolsData = (): PoolsDataResponse => {
  const allPoolsResponse = useAllPoolsQuery();
  const allPools = allPoolsResponse.data?.pools.filter((p) => !!p) as Pool[];
  const superFluidPools = allPools?.filter((pool) => pool?.isSuperfluid);

  const response: PoolsDataResponse = {
    isLoading: allPoolsResponse.loading,
    allPools,
    superFluidPools,
  };

  return response;
};
