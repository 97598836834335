import { FC } from "react";
import { Slice } from "victory";

interface CustomSliceProps {
  datum?: {
    y: number;
    background: boolean;
  };
  sliceStartAngle?: number;
  sliceEndAngle?: number;
}

const valueToColor = (value: { y: number }) => {
  if (!value) return "#22252C";

  if (value.y > 90) {
    return "#2DB574";
  }
  if (value.y > 80) {
    return "#2DB574";
  }
  if (value.y > 70) {
    return "#e1aa4c";
  }
  if (value.y > 50) {
    return "#e75252";
  }
  if (value.y > 0) {
    return "#e75252";
  }

  return "#22252C";
};

export const CustomSlice: FC<CustomSliceProps> = ({ datum, sliceStartAngle, sliceEndAngle, ...props }) => (
  <Slice
    {...props}
    cornerRadius={6}
    sliceStartAngle={datum?.background ? 0 : sliceStartAngle}
    sliceEndAngle={datum?.background ? 360 : sliceEndAngle}
    // @ts-expect-error style prop actually exists
    style={{ fill: datum.background ? "#22252C" : valueToColor(datum) }}
  />
);
