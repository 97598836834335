import {
  AssetWithSentiment,
  LpStrategyInput,
  PositionManagement,
  PriceSentiment,
  PriceVolatility,
  RiskProfile,
  TimeHorizon,
  TokenMarketCap,
} from "../generated";

// Enforce order of presented options

export const sentimentsTypeNames = Object.values([
  PriceSentiment.Positive,
  PriceSentiment.Neutral,
  PriceSentiment.Negative,
]);
export const volatilityTypeNames = Object.values([PriceVolatility.High, PriceVolatility.Medium, PriceVolatility.Low]);
export const marketCapTypeNames = Object.values([
  TokenMarketCap.BlueChip,
  TokenMarketCap.High,
  TokenMarketCap.Medium,
  TokenMarketCap.Low,
  TokenMarketCap.StablesPool,
]);
export const riskProfileTypeNames = Object.values([
  RiskProfile.Conservative,
  RiskProfile.Aggressive,
  RiskProfile.VeryAggressive,
]);
export const timeHorizonIntervalTypeNames = Object.values([TimeHorizon.Month, TimeHorizon.Week]);

export type Strategy = {
  riskProfile?: RiskProfile;
  timeHorizon?: TimeHorizon;
  costOfCapital?: number;
  positionManagement: boolean;
};

export type GenericAssetsExposure = {
  volatility: PriceVolatility[];
  marketCap: TokenMarketCap[];
};

export type FormValues = {
  specificAssetsExposure?: AssetWithSentiment[];
  genericAssetsExposure?: GenericAssetsExposure;
  strategy: Strategy;
};

export const formatLpStrategyInputFromValues = (
  values: FormValues,
  showSpecificAssetsExposure: boolean,
): LpStrategyInput => {
  const formattedInput: LpStrategyInput = {
    common: {
      risk_profile: values.strategy.riskProfile!,
      time_horizon: values.strategy.timeHorizon!,
      cost_of_capital: values.strategy.costOfCapital!,
      position_management: values.strategy.positionManagement ? PositionManagement.Active : PositionManagement.Passive,
    },
    preference: showSpecificAssetsExposure
      ? undefined
      : {
          volatility: values.genericAssetsExposure!.volatility,
          market_caps: values.genericAssetsExposure!.marketCap,
        },
    specific: showSpecificAssetsExposure ? { assets: values.specificAssetsExposure! } : undefined,
  };

  return formattedInput;
};
