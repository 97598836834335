import React from "react";
import { PaperProps } from "@mui/material";
import { Box } from "../box";
import { Paper } from "../paper";
import { Grid } from "../grid";
import { Collapse } from "../collapse";
import { IconButton } from "../icon-button";
import { CustomIcon } from "../custom-icon";

export interface AccordionProps {
  defaultExpanded?: boolean;
  header?: React.ReactNode;
  content?: React.ReactNode;
  sx?: PaperProps["sx"];
  children: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({ sx, header, content, defaultExpanded, children }) => {
  const [open, setOpen] = React.useState<boolean>(!!defaultExpanded);

  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <Paper
      variant="card"
      sx={{
        padding: "20px 24px",
        ...(sx || {}),
      }}
    >
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        columnSpacing={3}
        className="clickable"
        onClick={toggleOpen}
        gap={2}
      >
        <Grid
          item
          zeroMinWidth
          sx={{
            flexGrow: 1,
            flexBasis: 0,
          }}
        >
          {header}
        </Grid>
        <Grid
          item
          sx={{
            flexBasis: 40,
            paddingLeft: "0 !important",
            mr: -1,
          }}
        >
          <IconButton
            sx={{
              svg: {
                transition: "transform 0.3s ease",
                transform: `rotate(${open ? 180 : 0}deg)`,
              },
            }}
            size="small"
          >
            <CustomIcon icon="chevron-down" />
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={open} unmountOnExit>
        <Box>{content || children}</Box>
      </Collapse>
    </Paper>
  );
};
