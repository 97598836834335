import { TabsNavigation } from "src/components/layouts";
import { OsmosisRoutePath, RouteParams } from "src/config/routes";

const IncentiveProvidersTabs = () => (
  <TabsNavigation
    variant="sub-nav"
    tabs={[
      {
        label: "Overview",
        path: OsmosisRoutePath.LpDashboard.PoolsOverview.replace(RouteParams.ClientName, "osmosis"),
        testId: "overview-tab",
      },
      {
        label: "Pools",
        path: OsmosisRoutePath.LpDashboard.PoolsAll.replace(RouteParams.ClientName, "osmosis"),
        testId: "pools-tab",
      },
    ]}
  />
);

export default IncentiveProvidersTabs;
