import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Nullable } from "@frontend/types";
import { Box } from "@frontend/ui/box";
import { Typography } from "@frontend/ui/typography";
import { usePageTitle } from "src/hooks";
import { Tabs, TabProps } from "@frontend/ui/tabs";
import { ModalWrapper } from "@frontend/ui/modals";
import { Link } from "@frontend/ui";
import { EquationWithData } from "./equation-with-data";
import { DyDxCalculatorModal } from "./modal";

export type DataTerm = "r" | "R" | "w" | "Sum" | "f";

export interface CalculatorInitialData {
  id: string;
  name: string;
  calculationResults: Nullable<[string, string]>;
  values: Partial<Record<DataTerm, string>>;
}

export const DyDxCalculatorPage = () => {
  usePageTitle("Trading Rewards Calculator");

  const [data, setData] = useState<CalculatorInitialData[]>([]);
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number | undefined>();

  const onTabChange = (_: React.SyntheticEvent, tabNumber: number) => setTabIndex(tabNumber);

  const onModalClose = () => {
    setIsModalOpen(false);
    setSelectedTab(undefined);
  };

  const onTabEdit = (tabNumber: number) => {
    setSelectedTab(tabNumber);
    setIsModalOpen(true);
  };

  const onTabRemove = () => {
    if (selectedTab) {
      setData(data.filter((_, i) => i !== selectedTab));
      setTabIndex(selectedTab === 0 ? 0 : selectedTab - 1);
    }
    onModalClose();
  };

  const onTabSave = (name: string) => {
    if (selectedTab) {
      setData(data.map((d, i) => (selectedTab === i ? { ...d, name } : d)));
    }
    onModalClose();
  };

  const onTabCreate = () => {
    setTimeout(() => {
      setTabIndex(data.length);
    }, 0);
  };

  useEffect(() => {
    const initialData: CalculatorInitialData[] = [
      {
        id: uuidv4(),
        name: "Calculcation 1",
        calculationResults: null,
        values: {
          R: "1582192",
          Sum: "",
          f: "",
        },
      },
    ];
    const dataFromLS = localStorage.getItem("dydx-calculations");
    if (dataFromLS) {
      const parsedData = JSON.parse(dataFromLS) as CalculatorInitialData[];
      if (parsedData.length > 0) setData(parsedData);
      if (parsedData.length === 0) setData(initialData);
    } else setData(initialData);
  }, []);

  useEffect(() => {
    localStorage.setItem("dydx-calculations", JSON.stringify(data));
    setTabs(data.map(({ name }) => ({ label: name })));
  }, [data]);

  return (
    <>
      <Box mb={5}>
        <Typography mr={0.5}>
          {"$DYDX will be distributed to traders based on fees paid on the dYdX Layer 2 Protocol" +
            " $DYDX will be distributed on a 28-day epoch basis over five years and is not subject to any vesting or lockups." +
            " 1,582,192 $DYDX will be distributed per epoch." +
            " The following function is used to compute how much $DYDX is awarded to each trader during each epoch." +
            " For an additional overview of the Trading Rewards program, please read dYdX Trading Rewards on the "}
          <Link
            target="_blank"
            href="https://docs.dydx.community/dydx-governance/rewards/trading-rewards"
            color="#61dafb"
            variant="caption"
            underline="hover"
          >
            Governance Documentation.
          </Link>
        </Typography>
      </Box>
      {tabs.length > 0 && <Tabs fullWidth tabs={tabs} value={tabIndex} onChange={onTabChange} />}

      {data.map((d, i) => (
        <EquationWithData
          key={d.id}
          currentData={d}
          onCreate={onTabCreate}
          onDataChange={setData}
          isHidden={i !== tabIndex}
          nextCalculationNumber={data.length + 1}
          onEdit={() => onTabEdit(i)}
        />
      ))}

      <ModalWrapper open={isModalOpen} onClose={onModalClose} maxWidth={600}>
        <DyDxCalculatorModal
          name={selectedTab ? data[selectedTab].name : ""}
          onClose={onModalClose}
          onRemove={onTabRemove}
          onSave={onTabSave}
        />
      </ModalWrapper>
    </>
  );
};
