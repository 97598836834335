import { FC } from "react";
import { SxProps } from "@mui/material";
import { Box } from "../box";

interface ShimmerLoaderProps {
  sx?: SxProps;
  fill?: boolean;
  testId?: string;
}

export const ShimmerLoader: FC<ShimmerLoaderProps> = ({ sx, fill, testId }) => {
  const colors = ["rgba(134, 148, 161, 0.1)", "rgba(134, 148, 161, 0.2)"];
  return (
    <Box
      data-testid={testId}
      sx={{
        ...{
          display: "inline-block",
          overflow: "hidden",
          backgroundColor: colors[0],
          ...(fill
            ? {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }
            : {
                position: "relative",
                height: "100%",
                width: "100%",
              }),

          "&::after": {
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            transform: "translateX(-100%)",
            background: `linear-gradient(90deg, ${colors[0]}, ${colors[1]}, ${colors[0]})`,
            animation: "shimmer 1s infinite",
            content: '" "',
          },

          "@keyframes shimmer": {
            "100%": {
              transform: "translateX(100%)",
            },
          },
        },
        ...sx,
      }}
    />
  );
};
