import { VictoryTooltip } from "victory";

export const chartTooltip = (
  <VictoryTooltip
    constrainToVisibleArea
    flyoutStyle={{ fill: "#17191E" }}
    flyoutPadding={{
      top: 8,
      bottom: 8,
      right: 8,
      left: 8,
    }}
    style={{
      fontSize: 14,
      fontFamily: "Archivo, sans-serif",
      fill: "#E5E9EB",
    }}
  />
);
