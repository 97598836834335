import { Paper, CompositionOverTimeChart, Typography, Box, CustomSwitch } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { useState } from "react";
import { LiquidationsDailyPerAssetFragment } from "src/pages/ccar-perpetuals-page/generated";

type LiquidationsOverTimeChartProps = {
  daysAgo: number;
  liquidationsDaily?: LiquidationsDailyPerAssetFragment[];
  isLoading: boolean;
  showCountToggle?: boolean;
};

const getChartProps = (
  liquidationsDaily: LiquidationsDailyPerAssetFragment[] = [],
  showLiquidationsCount = false,
): { dates: number[]; series: NumberChartSeries[] } => {
  if (!liquidationsDaily.length) {
    return { dates: [], series: [] };
  }

  return {
    dates: liquidationsDaily[0].liquidations.map((l) => l.time),
    series: liquidationsDaily.map(({ liquidations, liquidationCount, asset }) => ({
      type: "bar",
      label: showLiquidationsCount ? "Events" : asset,
      data: showLiquidationsCount
        ? liquidationCount.map((l) => [l.time, l.value])
        : liquidations.map((l) => [l.time, l.value]),
    })),
  };
};

export const LiquidationsOverTimeChart = ({
  daysAgo,
  liquidationsDaily,
  isLoading,
  showCountToggle,
}: LiquidationsOverTimeChartProps) => {
  const [showLiquidationsCount, setShowLiquidationsCount] = useState(false);
  const { series } = getChartProps(liquidationsDaily, showLiquidationsCount);

  return (
    <CompositionOverTimeChart
      title="Liquidations Over Time"
      description={`${
        showLiquidationsCount ? "Total liquidation events" : "Value liquidated"
      } in the last ${daysAgo} days, by date and market`}
      isLoading={isLoading}
      yAxisLabel={showLiquidationsCount ? "Total Amount of Liquidations" : "Total Value of Liquidations"}
      chartHeight={380}
      series={series}
      currency=""
      headerSuffix={
        showCountToggle && (
          <CustomSwitch
            onChange={() => setShowLiquidationsCount(!showLiquidationsCount)}
            checked={!showLiquidationsCount}
            checkedLabel="Value Liquidated"
            uncheckedLabel="Total Liquidations"
          />
        )
      }
    />
  );
};
