import { AppColorSchemes } from "../types";
import { palette } from "./palette";

export const getColors = (mode: AppColorSchemes) => {
  const isLight = mode === "light";
  return {
    primary: {
      main: isLight ? "#2D6AE7" : "#2D6AE7",
    },
    secondary: {
      main: isLight ? "#528BFF" : "#528BFF",
    },
    background: {
      default: isLight ? "#FFFFFF" : "#0C111D",
      paper: isLight ? "#FCFCFD" : "#182230",
      modal: "#17191E",
      light: "#3B4046",
      tooltip: "#17191E",
    },
    error: {
      main: isLight ? "#E2483D" : "#e1554a",
      opacity50: palette.red.opacity50,
      opacity20: palette.red.opacity20,
    },
    text: {
      primary: isLight ? "#101828" : "#FCFCFD",
      secondary: isLight ? "#98A2B3" : "#98A2B3",
    },
    greys: {
      400: isLight ? "#666E7A" : "#98A2B3",
      500: isLight ? "#8591A1" : "#6B7685",
      600: isLight ? "#A3AFBF" : "#525F6E",
      700: isLight ? "#E8EFF2" : "#344054",
      800: isLight ? "#F6F8F9" : "#182230",
      900: isLight ? "#FCFCFD" : "#101828",
    },
    divider: isLight ? "#E8EFF2" : "#344054",
    chartText: palette.white.main,
    light: {
      main: palette.almostWhite.main,
    },
    success: {
      main: isLight ? "#14AE5C" : "#2FC77E",
      opacity50: palette.green.opacity50,
      opacity20: palette.green.opacity20,
    },
    lightGreen: {
      main: "#C4FF80",
    },
    verified: {
      main: palette.green.main,
      opacity50: palette.green.opacity50,
      opacity20: palette.green.opacity20,
    },
    deployed: {
      main: palette.violette.main,
      opacity50: palette.violette.opacity50,
      opacity20: palette.violette.opacity20,
    },
    progress: {
      main: palette.turqouise.opacity80,
      opacity50: palette.turqouise.opacity50,
      opacity20: palette.turqouise.opacity20,
    },
    uploaded: {
      main: palette.orange.main,
      opacity50: palette.orange.opacity50,
      opacity20: palette.orange.opacity20,
    },
    recommended: {
      main: palette.blue.main,
      opacity50: palette.blue.opacity50,
      opacity20: palette.blue.opacity20,
    },
    disabled: {
      main: "#9B9DA180",
      opacity50: "rgba(155, 157, 161, 0.5)",
    },
  };
};
