import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { PageHeader } from "@frontend/ui/page-header";
import { useOverviewStatsChainsQuery } from "../../generated";
import ChainOverviewStatsRowView from "../overview/overview-table";

const Chains = () => {
  const { clientName } = useParams();
  const { loading, data } = useOverviewStatsChainsQuery();

  return (
    <>
      <PageHeader
        showBackButton={false}
        pageTitle="Chains"
      />
      <ChainOverviewStatsRowView
        data={data?.overviewStatsChains}
        isLoading={loading}
        onClickChainHref={(chain) =>
        RoutePath.Risk.ChainDetails.replace(RouteParams.ClientName, clientName!).replace(RouteParams.Chain, chain)
      }
      />
    </>
  );
};

export default Chains;
