import { Box } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { FullPoolFragment } from "src/pages/lp-dashboard-page/generated";
import { RecommendationCard, RecommendationCardProps } from "../../../parameter-recommendations-page/components";

type Props = {
  poolDetails: FullPoolFragment;
};

const RecommendationsCards = ({ poolDetails }: Props) => {
  const recommendationsCards: RecommendationCardProps[] = [
    {
      title: "Liquidity",
      icon: "exponential-graph",
      currentValue: formatAmount(poolDetails.liquidityUSD, { currency: "USD", notation: "standard" }),
      valueWithRecommendation: poolDetails.incentives
        ? formatAmount(poolDetails.incentives?.targetLiquidityUSD, { currency: "USD", notation: "standard" })
        : "-",
      recommendationLabel: "Target",
    },
    {
      title: "Osmo APR",
      icon: "deposit-icon",
      currentValue: poolDetails.incentives
        ? formatAmount(poolDetails.incentives.currentIncentiveAPRs.fourteenDays, { isPercent: true })
        : "-",
      valueWithRecommendation: poolDetails.incentives
        ? formatAmount(poolDetails.incentives.targetIncentiveAPRs.fourteenDays, { isPercent: true })
        : "-",
      recommendationLabel: "Target",
    },
  ];

  return (
    <Box display="flex" gap={3} flexWrap="wrap">
      {recommendationsCards.map((card) => (
        <Box key={card.title} flex={1}>
          <RecommendationCard {...card} />
        </Box>
      ))}
    </Box>
  );
};

export default RecommendationsCards;
