import { PropsWithChildren, ReactNode, useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { Box } from "@frontend/ui";
import { RiskTabsLayout } from "src/pages/ccar-lending-page/components/risk-tabs-layout";
import { ChaosApolloProvider } from "src/utils/graphql";
import { MarketDropdownHeader, MarketAssetDropdownHeader, MarketEmodeDropdownHeader } from "./headers";
import { useMarketListedAssetsContext } from "../../context/market-listed-assets-context";
import { useMarketAssetsRecommendationsContext } from "../../context";
import { MarketAssetDropdownHeaderProps } from "./headers/market-asset-dropdown-header";

export const replaceHomePath = (path: string) => path.replace(RoutePath.Risk.Markets, "");

export const DefaultMarketPageTabRedirect = () => {
  const { clientName, marketId } = useParams();

  return (
    <Navigate
      to={RoutePath.Risk.MarketDetailsOverview.replace(RouteParams.ClientName, clientName!).replace(
        RouteParams.MarketId,
        marketId!,
      )}
      replace
    />
  );
};

type PageLayoutProps = {
  pageType: "market" | "asset" | "e-mode";
  marketAssetProps?: MarketAssetDropdownHeaderProps;
};

const PageLayout = ({ children, pageType, marketAssetProps }: PropsWithChildren<PageLayoutProps>) => {
  const { marketId } = useParams();
  const location = useLocation();
  const listedAssetsContext = useMarketListedAssetsContext();
  const assetsRecommendationsContext = useMarketAssetsRecommendationsContext();

  const isProposedTab = location.pathname.includes("proposed");

  useEffect(() => {
    if (marketId) {
      listedAssetsContext.setMarketId(marketId);
      assetsRecommendationsContext.setMarketId(marketId);
    }
  }, [marketId, assetsRecommendationsContext, listedAssetsContext]);

  return (
    <RiskTabsLayout>
      <Box display="flex" flexDirection="column">
        {pageType === "market" && <MarketDropdownHeader />}
        {pageType === "asset" &&
          (isProposedTab ? (
            <ChaosApolloProvider product="asset-listing">
              <MarketAssetDropdownHeader {...marketAssetProps} />
            </ChaosApolloProvider>
          ) : (
            <MarketAssetDropdownHeader {...marketAssetProps} />
          ))}
        {pageType === "e-mode" && <MarketEmodeDropdownHeader />}
        {children}
      </Box>
    </RiskTabsLayout>
  );
};

export const renderMarketPageLayout = (children: ReactNode) => <PageLayout pageType="market">{children}</PageLayout>;

export const renderAssetPageLayout = (children: ReactNode, props?: MarketAssetDropdownHeaderProps) => (
  <PageLayout pageType="asset" marketAssetProps={props}>
    {children}
  </PageLayout>
);

export const renderEModePageLayout = (children: ReactNode) => <PageLayout pageType="e-mode">{children}</PageLayout>;
