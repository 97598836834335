import { useState } from "react";
import { CustomSwitch, StackedBarChart } from "@frontend/ui";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { StringChartSeries } from "@frontend/ui/echarts/types";
import { Chain, TopCreditAccountSort, useTopCreditAccountsQuery } from "../generated";

type Props = {
  chain?: Chain;
};

const TopAccountsChart = ({ chain }: Props) => {
  const [sortBy, setSortBy] = useState<TopCreditAccountSort>(TopCreditAccountSort.Borrow);
  const { data, loading } = useTopCreditAccountsQuery({
    variables: {
      sortBy,
      chain,
    },
  });

  const sortedAddresses = [...(data?.topCreditAccounts || [])]
    .sort((a, b) =>
      sortBy === TopCreditAccountSort.Borrow
        ? b.debtUsd - a.debtUsd
        : (b.totalCollateralUsd || 0) - (a.totalCollateralUsd || 0),
    )
    .map((a) => a.accountAddress);

  const seriesByToken = (data?.topCreditAccounts || []).reduce((acc: Record<string, StringChartSeries>, account) => {
    const { accountAddress, reserves } = account;

    const updatedSeries = reserves.map((reserve) => {
      const { symbol } = reserve.asset;
      const key = `${symbol}-${reserve.isCollateral ? "collateral" : "borrow"}`;
      const prevSeries = acc[key];
      const prevData = prevSeries?.data || [];
      const amount = Number(reserve.amountUsd) * (reserve.isCollateral ? 1 : -1);
      const newDataPoint = [accountAddress, amount] as [string, number];
      const newData = [...prevData, newDataPoint];
      const newSeries: [string, StringChartSeries] = [
        key,
        {
          id: key,
          label: symbol,
          data: newData,
        },
      ];
      return newSeries;
    });

    return { ...acc, ...Object.fromEntries(updatedSeries) };
  }, {});

  return (
    <StackedBarChart
      key={`top-accounts-${sortBy}-${chain || ""}`}
      title="Top Credit Accounts"
      series={Object.values(seriesByToken)}
      isLoading={loading}
      xAxisFontSize={10}
      tooltipHeaderFormatter={(v: string | number) => formatAddressCompact(v.toString())}
      formatXAxisLabel={(a: string) => formatAddressCompact(a)}
      headerSuffix={
        <CustomSwitch
          onChange={() =>
            setSortBy((prev) =>
              prev === TopCreditAccountSort.Collateral ? TopCreditAccountSort.Borrow : TopCreditAccountSort.Collateral,
            )
          }
          checked={sortBy === TopCreditAccountSort.Borrow}
          checkedLabel="Top Borrowers"
          uncheckedLabel="Top Collateral"
        />
      }
      option={{
        xAxis: {
          data: sortedAddresses,
        },
      }}
    />
  );
};

export default TopAccountsChart;
