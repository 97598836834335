import { CellStatusType, Header } from "@frontend/types";
import {
  ChaosTable,
  currencyCell,
  labelsCell,
  chainCell,
  percentCell,
  textCell,
  numberCell,
} from "@frontend/ui/chaos-table";
import { toTitleCase } from "@frontend/ui/utils/formatters";
import { useParams } from "react-router-dom";
import { Chain, OverviewResults } from "../../generated";
import { ChainsTableColumn, Client } from "../../types";
import { useClientConfig } from "../../clients-config";

type Props = {
  data?: OverviewResults[];
  isLoading?: boolean;
  onClickChainHref?: (chain: Chain) => string;
};

const ROW_HEIGHT = 72;

const headers: Record<ChainsTableColumn, Header> = {
  chain: {
    renderType: "TEXT",
    text: "Chain",
  },
  activeUsers: {
    renderType: "TEXT",
    text: "Active Users",
  },
  totalSupplyUsd: {
    renderType: "TEXT",
    text: "Total Supply",
    prefix: "$",
  },
  totalBorrowUsd: {
    renderType: "TEXT",
    text: "Total Borrow",
    prefix: "$",
  },
  totalValueLockedUsd: {
    renderType: "TEXT",
    text: "Total Value Locked",
    prefix: "$",
  },
  totalValueAvailableForLiquidationUsd: {
    renderType: "TEXT",
    text: "Value Eligible for Liquidations",
    prefix: "$",
  },
  totalValueAtRiskUsd: {
    renderType: "TEXT",
    text: "Collateral at Risk",
    prefix: "$",
  },
  totalChainTvl: {
    renderType: "TEXT",
    text: "Chain TVL",
    prefix: "$",
  },
  totalSupplyPercentChainTvl: {
    renderType: "TEXT",
    text: "Protocol Supply % of Chain TVL",
    prefix: "%",
  },
  protocolHealth: {
    renderType: "TEXT",
    text: "Health Score",
    prefix: "",
  },
};

const useTableConfig = () => {
  const { overview } = useClientConfig();
  const columns = overview?.chainTableConfig?.columns || [];
  return { headers: columns.map((column) => headers[column]), columns };
};

const healthCell = (health: number | null, protocol = "") => {
  const tooltip = `Health of ${toTitleCase(
    protocol,
  )} on this chain, compared to overall chain liquidity and other protocols
  `;
  if (health === null) {
    return textCell("");
  }

  let status: CellStatusType = "INFO";
  let statusText = "";
  if (health <= 50) {
    status = "ERROR";
    statusText = "Low";
  } else if (health <= 150) {
    status = "WARNING";
    statusText = "Medium";
  } else {
    status = "OK";
    statusText = "High";
  }

  return labelsCell(status, statusText, tooltip);
};

const ChainOverviewStatsRowView = ({ data = [], isLoading, onClickChainHref }: Props) => {
  const config = useTableConfig();
  const { clientName } = useParams<{ clientName: Client }>();
  const tableData = data.map((chainstats) =>
    config.columns.map((column) => {
      switch (column) {
        case "chain":
          return chainCell(chainstats.current.chain || "", chainstats.current.chain || "");
        case "activeUsers":
          return numberCell(chainstats.current.activeUsers || 0);
        case "totalSupplyUsd":
          return currencyCell(chainstats.current.totalSupplyUsd);
        case "totalBorrowUsd":
          return currencyCell(chainstats.current.totalBorrowUsd);
        case "totalChainTvl":
          return currencyCell(chainstats.current.totalChainTvl);
        case "totalSupplyPercentChainTvl":
          return percentCell(
            chainstats.current.totalChainTvl
              ? (chainstats.current.totalSupplyUsd || 0) / chainstats.current.totalChainTvl
              : 0,
          );
        case "totalValueAtRiskUsd":
          return currencyCell(chainstats.current.totalValueAtRiskUsd);
        case "totalValueAvailableForLiquidationUsd":
          return currencyCell(chainstats.current.totalValueAvailableForLiquidationUsd);
        case "totalValueLockedUsd":
          return currencyCell(chainstats.current.totalValueLockedUsd);
        case "protocolHealth":
          return healthCell(chainstats.current.protocolHealth, clientName);
        default:
          return textCell("");
      }
    }),
  );

  const handleClickRow = (rowIdx: number) => {
    const { chain } = data[rowIdx].current;
    if (!chain || !onClickChainHref) return "";
    return onClickChainHref(chain);
  };

  return (
    <ChaosTable
      title="Chains"
      headers={config.headers}
      data={tableData}
      isLoading={isLoading}
      rowHeight={ROW_HEIGHT}
      tableHeight={ROW_HEIGHT * tableData.length}
      pageSize={10}
      initialSortBy={1}
      isInitialSortDesc
      rowHref={handleClickRow}
      showRowChevron={!!onClickChainHref}
    />
  );
};

export default ChainOverviewStatsRowView;
