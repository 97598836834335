import { useCallback } from "react";
import { formatDuration } from "@frontend/ui/utils/formatters";
import { StackedBarChart } from "@frontend/ui";
import { useWithdrawalWaitTimeDistributionQuery } from "../../../../generated";

const formatWaitTime = (waitTimeSeconds: number) =>
  formatDuration({
    milliseconds: waitTimeSeconds * 1000,
    compactHours: 1,
    showHighestUnitOnly: false,
  });

const WithdrawalWaitTimeDistribution = () => {
  const { data, loading } = useWithdrawalWaitTimeDistributionQuery();

  const chartData: [string, number][] = [...(data?.withdrawalWaitTimeDistribution || [])]
    .sort((a, b) => a.minWaitTimeSeconds - b.minWaitTimeSeconds)
    .map((d) => [d.id, d.processedRequestsCount]);

  const formatWaitTimeLabel = useCallback(
    (id: string | number, showRange = false) => {
      const bin = (data?.withdrawalWaitTimeDistribution || []).find((d) => d.id === id);

      if (!bin) return "";

      const { minWaitTimeSeconds, maxWaitTimeSeconds } = bin;

      if (!maxWaitTimeSeconds) {
        return `> ${formatWaitTime(minWaitTimeSeconds)}`;
      }

      if (showRange) {
        return `${formatWaitTime(minWaitTimeSeconds)} - ${formatWaitTime(maxWaitTimeSeconds)}`;
      }

      return `${formatWaitTime(maxWaitTimeSeconds)}`;
    },
    [data?.withdrawalWaitTimeDistribution],
  );

  return (
    <StackedBarChart
      chartHeight={325}
      showXAxisLine={false}
      title="Withdrawal Wait Time Distribution"
      isLoading={loading}
      series={[
        {
          label: "Number of Withdrawals",
          data: chartData,
        },
      ]}
      formatXAxisLabel={(id: string) => formatWaitTimeLabel(id)}
      showLegend={false}
      currency=""
      tooltipHeaderFormatter={(id: string | number) => formatWaitTimeLabel(id, true)}
    />
  );
};

export default WithdrawalWaitTimeDistribution;
