import { Box, CryptoIcon, Typography, ValueCardProps } from "@frontend/ui";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import MultiValueCard from "src/components/multi-values-card/multi-values-card";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { PoolFragment } from "src/pages/ccar-lending-page/generated";

type Props = {
  pool: PoolFragment;
};

const PoolOverview = ({ pool }: Props) => {
  const { stableCoin } = useClientConfig();

  const poolAsset = pool.tokens.find((t) => t.symbol.toLowerCase() === stableCoin?.asset?.toLowerCase());

  const assetName = stableCoin?.asset || "";
  const poolAssetAmount = poolAsset && poolAsset.balance ? Number(poolAsset.balance) : 0;
  const poolAssetPrice = poolAsset?.usdPrice || 0;
  const poolTvl = pool.totalLiquidity
    ? pool.totalLiquidity
    : pool.tokens
        .map((t) => (t.balance ? Number(t.usdPrice) * Number(t.balance) : 0))
        .reduce((sum, value) => Number(sum) + Number(value));

  const cardValues: ValueCardProps[] = [
    {
      title: `${assetName} Price`,
      value: poolAssetPrice,
      currency: "USD",
      valueMaximumFractionDigits: 5,
    },
    {
      title: `${assetName} Amount`,
      value: poolAssetAmount,
    },
    {
      title: "Pool TVL",
      value: poolTvl,
      currency: "USD",
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" gap={3} flexWrap="wrap">
        <Box flex={1}>
          <Typography variant="caption">Protocol</Typography>
          <Box display="flex" gap={1}>
            <CryptoIcon icon={pool.source} />
            <Typography>{capitalizeFirstLetter(pool.source)}</Typography>
          </Box>
        </Box>
        <Box flex={1}>
          <Typography variant="caption">Tokens</Typography>
          <Box display="flex" gap={1}>
            {pool.tokens.map((token) => (
              <CryptoIcon icon={token.symbol} />
            ))}
          </Box>
        </Box>
      </Box>
      <MultiValueCard values={cardValues} />
    </Box>
  );
};

export default PoolOverview;
