import { Box } from "../../../box";
import { CryptoIcon } from "../../../crypto-icon";
import { TokenWrapper } from "./token-wrapper";

export type TokenPairProps = {
  token1: string;
  token2: string;
  size?: number;
};

const TokenPair = ({ token1, token2, size = 40 }: TokenPairProps) => (
  <Box display="flex" gap={-1}>
    <TokenWrapper sx={{ zIndex: 2 }} size={size}>
      <CryptoIcon icon={token1.toLowerCase()} sx={{ img: { width: size - 5, height: size - 5 } }} />
    </TokenWrapper>
    <TokenWrapper sx={{ marginLeft: -1, zIndex: 1 }} size={size}>
      <CryptoIcon icon={token2.toLowerCase()} sx={{ img: { width: size - 5, height: size - 5 } }} />
    </TokenWrapper>
  </Box>
);

export default TokenPair;
