import { TimeSpan } from "@frontend/ui/echarts/types";
import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { StablecoinDataContextType } from "./types";
import { useStablecoinData } from "./use-stablecoin-data";
import { defaultTimeSpan } from "../../components/time-span-picker/time-span-picker";

const initialValue: StablecoinDataContextType = {
  isLoading: true,
  tokenSymbol: "",
  timeSpan: defaultTimeSpan,
  setTimeSpan: () => undefined,
};

const StablecoinDataContext = createContext<StablecoinDataContextType>(initialValue);

export const StablecoinDataProvider = ({ children }: { children: ReactNode }) => {
  const [timeSpan, setTimeSpan] = useState<TimeSpan>(initialValue.timeSpan);
  const value = useStablecoinData(timeSpan);

  const contextValue = useMemo(
    () => ({
      ...value,
      timeSpan,
      setTimeSpan,
    }),
    [timeSpan, value, setTimeSpan],
  );
  return <StablecoinDataContext.Provider value={contextValue}>{children}</StablecoinDataContext.Provider>;
};

export const useStablecoinDataContext = () => useContext(StablecoinDataContext);
