import { FC, useState } from "react";
import { Button, CustomIcon } from "@frontend/ui";
import { styled } from "@mui/material/styles";
import { useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import { AlertSettingsForm } from "./types";
import { AlertSettingKeys } from "../utils";

interface ExportImportAlertButtonProps {
  submitting: boolean;
  isNewAlert?: boolean;
}

const VisuallyHiddenInput = styled("input")({
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const ExportImportAlertButton: FC<ExportImportAlertButtonProps> = ({ submitting, isNewAlert }) => {
  const { getValues, setValue, trigger } = useFormContext<AlertSettingsForm>();
  const [loading, setLoading] = useState(false);

  const handleUploadAlertSettings = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const reader = new FileReader();
    if (file) {
      setLoading(true);
      reader.onload = async (event) => {
        if (event.target) {
          const alertSettings = JSON.parse(event.target.result as string) as AlertSettingsForm;
          Object.entries(alertSettings).forEach(([key, value]) => {
            if (AlertSettingKeys.includes(key as keyof AlertSettingsForm)) {
              setValue(key as keyof AlertSettingsForm, value, { shouldDirty: true });
            }
          });
          await trigger();
        }
        setLoading(false);
      };
      reader.readAsText(file);
    }
  };

  const handleExportAlertSettings = () => {
    setLoading(true);
    const values = getValues();
    const jsonToExport: Partial<AlertSettingsForm> = AlertSettingKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: values[key],
      }),
      {} as Partial<AlertSettingsForm>,
    );
    const blob = new Blob([JSON.stringify(jsonToExport)], { type: "text/json" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${values.alertId}-settings.json`;
    link.click();
    URL.revokeObjectURL(link.href);
    setLoading(false);
  };

  return (
    <Button
      variant="text"
      startIcon={!loading ? <CustomIcon icon={isNewAlert ? "import" : "export"} /> : undefined}
      disabled={submitting}
      component={isNewAlert ? "label" : Button}
      onClick={!isNewAlert ? handleExportAlertSettings : undefined}
    >
      {loading && <CircularProgress color="inherit" size="24px" sx={{ marginRight: 2 }} />}
      {isNewAlert ? "Import Settings" : "Export Settings"}
      {isNewAlert && <VisuallyHiddenInput type="file" onChange={handleUploadAlertSettings} accept=".json" />}
    </Button>
  );
};
