import { Box, ChaosTable, TokenPairCell } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { LpStrategyDataQuery, LpStrategyOutput } from "src/pages/uniswap/generated";
import CalculatedResultsPool from "./calculated-results-pool";

const PERCENTAGE_ZERO_ACCURACY = 0.0001; // 0.01%

const formatPercentWithAccuracyLevel = (value: number): string => {
  const formattedValue =
    Math.abs(value) > PERCENTAGE_ZERO_ACCURACY
      ? formatAmount(value, { isPercent: true })
      : formatAmount(0, { isPercent: true });

  return formattedValue;
};

const getStrategyTooltipText = (strategy: string): string | undefined => {
  switch (strategy) {
    case "Dynamic":
      return "Dynamic Ranges - The range of the position is initially around the current price and calibrated around the new price when the price moves significantly, based on the assets’ volatility.";
    case "Volatility":
      return "Time in Market - The range of the position is calibrated around the current price, and upon highly volatile price movements, the liquidity is withdrawn. It is then deployed again after a period of time around the new price. This strategy ensures that liquidity is deployed to the pool most of the time during the simulation.";
    case "Hybrid":
      return "This strategy combines the behaviors of the Dynamic Ranges and Time In Market strategies.";
    default:
      return undefined;
  }
};

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Pool",
    width: "300px",
  },
  {
    renderType: "TEXT",
    text: "Fee Tier",
    suffix: "%",
  },
  {
    renderType: "TEXT",
    text: "Strategy",
  },
  {
    renderType: "TEXT",
    text: "PnL",
    suffix: "%",
    tooltip:
      "The net Profit and Loss (PnL) of the strategy is calculated by subtracting the opportunity cost from the overall PnL.",
  },
  {
    renderType: "TEXT",
    text: "Impermanent Loss (%)",
    suffix: "%",
    tooltip: "Impermanent Loss as % of the initial amount deployed to the pool",
  },
  {
    renderType: "TEXT",
    text: "LvR",
    suffix: "%",
    tooltip: "The LP running cost that must be offset by trading fee income for liquidity provision to be profitable.",
  },
  {
    renderType: "TEXT",
    text: "Min Value (%) without fees",
    suffix: "%",
    tooltip:
      "The minimum value of the position throughout the backtesting as % of the " +
      "initial amount deployed to the pool",
  },
  {
    renderType: "TEXT",
    text: "Max Value (%) without fees",
    suffix: "%",
    tooltip:
      "The maximum value of the position throughout the backtesting as % of the " +
      "initial amount deployed to the pool",
  },
  {
    renderType: "TEXT",
    text: "Time in Range (%)",
    suffix: "%",
    tooltip: "The percentage of time in which funds were deployed to a position in the range",
  },
];

type Props = {
  data: LpStrategyDataQuery;
};

const CalculatedResultsTable = ({ data }: Props) => {
  const tableData: RenderData[][] | undefined = !data.lpStrategyData
    ? undefined
    : data.lpStrategyData.map(
        (lpStrategyOutput: LpStrategyOutput) =>
          [
            {
              renderType: "CUSTOM",
              text: `${lpStrategyOutput.token0}/${lpStrategyOutput.token1}`,
              component: (
                <TokenPairCell
                  token1={lpStrategyOutput.token0}
                  token2={lpStrategyOutput.token1}
                  subTitle={lpStrategyOutput.chain}
                />
              ),
              value: lpStrategyOutput.address,
              filterByValue: lpStrategyOutput.address,
              searchTerm: `#${lpStrategyOutput.address} ${lpStrategyOutput.token0}/${lpStrategyOutput.token1}`,
              exportText: `#${lpStrategyOutput.address} ${lpStrategyOutput.token0}/${lpStrategyOutput.token1}`,
            },
            {
              renderType: "TEXT",
              text: formatAmount(lpStrategyOutput.fee / 100, { isPercent: true }),
              value: lpStrategyOutput.fee,
            },
            {
              renderType: "TEXT",
              text: lpStrategyOutput.strategy === "Volatility" ? "Time" : lpStrategyOutput.strategy,
              value: lpStrategyOutput.strategy,
              tooltipText: getStrategyTooltipText(lpStrategyOutput.strategy),
            },
            {
              renderType: "TEXT",
              text: formatPercentWithAccuracyLevel(lpStrategyOutput.pnl),
              value: lpStrategyOutput.pnl,
            },
            {
              renderType: "TEXT",
              text: formatPercentWithAccuracyLevel(lpStrategyOutput.impermanent_loss),
              value: lpStrategyOutput.impermanent_loss,
            },
            {
              renderType: "TEXT",
              text: formatPercentWithAccuracyLevel(lpStrategyOutput.lvr),
              value: lpStrategyOutput.lvr,
            },
            {
              renderType: "TEXT",
              text: formatPercentWithAccuracyLevel(lpStrategyOutput.min_value_without_fees),
              value: lpStrategyOutput.min_value_without_fees,
            },
            {
              renderType: "TEXT",
              text: formatPercentWithAccuracyLevel(lpStrategyOutput.max_value_without_fees),
              value: lpStrategyOutput.max_value_without_fees,
            },
            {
              renderType: "TEXT",
              text: formatPercentWithAccuracyLevel(lpStrategyOutput.time_in_range),
              value: lpStrategyOutput.time_in_range,
            },
          ] as RenderData[],
      );

  return (
    <Box>
      {tableData && (
        <ChaosTable
          headers={headers}
          data={tableData}
          title="Backtesting Results"
          description="Showing the backtesting results of strategies and pools that produced the highest PnL,
           and match the specified values."
          isTableControlsHidden
          isInitialSortEnable
          initialSortBy={3}
          isInitialSortDesc
          pageSize={10}
          emptyState={{ icon: "idea", title: "There are no pools and strategies to match the specified value" }}
          isFullHeight
          expandContent={(id: number) => <CalculatedResultsPool data={data.lpStrategyData[id]} />}
        />
      )}
    </Box>
  );
};

export default CalculatedResultsTable;
