import { GmPoolConfigFragment } from "src/pages/ccar-perpetuals-page/generated";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, textCell, valueCell } from "@frontend/ui";
import { poolConfigKeyToDisplay } from "./pool-params-display-config";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Parameter",
  },
  {
    renderType: "TEXT",
    text: "Value",
  },
];

type Props = {
  gmPoolName?: string;
  gmPoolConfig?: GmPoolConfigFragment;
  isLoading: boolean;
};

const PoolConfigTable = ({ gmPoolConfig, gmPoolName, isLoading }: Props) => {
  const tableData = (gmPoolConfig ? Object.entries(gmPoolConfig) : []).reduce((acc: RenderData[][], entry) => {
    const [paramKey, value] = entry as [keyof GmPoolConfigFragment, number | null];

    if (paramKey === "__typename" || value === null) return acc;

    const paramDisplay = poolConfigKeyToDisplay[paramKey];

    const newRow: RenderData[] = [textCell(paramDisplay.name), valueCell(value, paramDisplay.valueType, "standard")];

    return [...acc, newRow];
  }, []);

  return (
    <ChaosTable
      headers={headers}
      data={tableData}
      isLoading={isLoading}
      title="Pool Config"
      isFilterHidden
      metadata={gmPoolName}
    />
  );
};

export default PoolConfigTable;
