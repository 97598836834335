import { Header, RenderData } from "@frontend/types";
import { CustomCheckbox } from "@frontend/ui/custom-checkbox";
import { v4 as uuidv4 } from "uuid";
import {
  addressCell,
  Box,
  chainCell,
  ChaosTable,
  currencyCell,
  currencyTrendCell,
  CustomSwitch,
  dateCell,
  iconsCell,
  labelsCell,
  textCell,
  Typography,
} from "@frontend/ui";
import { useEffect, useMemo, useState } from "react";
import { useCreditAccountsQuery } from "../generated";
import { creditAccountStatusToText } from "../utils";

type Props = {
  creditManagerId?: string;
};

const CreditAccountsTable = ({ creditManagerId }: Props) => {
  const [showOpenedAccounts, setShowOpenedAccounts] = useState(true);
  const [hideZeroValueAccounts, setHideZeroValueAccounts] = useState(true);
  const [resetPagination, setResetPagination] = useState<string>();
  const [resetSortBy, setResetSortBy] = useState<string>();

  const { data, loading } = useCreditAccountsQuery({
    variables: {
      isOpen: showOpenedAccounts,
      creditManagerId,
    },
  });

  useEffect(() => {
    setResetPagination(uuidv4());
  }, [data, hideZeroValueAccounts, showOpenedAccounts]);

  useEffect(() => {
    setResetSortBy(uuidv4());
  }, [showOpenedAccounts]);

  const headers: Header[] = useMemo(
    () => [
      {
        renderType: "TEXT",
        text: "Account",
        width: "180px",
      },
      ...(!creditManagerId
        ? ([
            {
              renderType: "TEXT",
              text: "Chain",
              width: "150px",
            },
          ] as Header[])
        : []),
      {
        renderType: "TEXT",
        text: "Total Value",
      },
      {
        renderType: "TEXT",
        text: "Assets",
        width: "150px",
      },
      {
        renderType: "TEXT",
        text: "Health Factor",
      },
      {
        renderType: "TEXT",
        text: "Leverage",
        prefix: "x",
      },
      {
        renderType: "CURRENCY",
        unit: "USD",
        text: "Debt",
      },
      {
        renderType: "CURRENCY",
        unit: "USD",
        text: "PnL",
      },
      ...(!showOpenedAccounts
        ? ([
            {
              renderType: "TEXT",
              text: "Closed At",
              width: "200px",
            },
          ] as Header[])
        : []),
      {
        renderType: "TEXT",
        text: "Status",
      },
    ],
    [creditManagerId, showOpenedAccounts],
  );

  const renderedData: RenderData[][] = useMemo(() => {
    const creditAccounts = hideZeroValueAccounts
      ? [...(data?.creditAccounts || [])].filter((a) => a.totalValueUsd > 0)
      : data?.creditAccounts || [];

    return creditAccounts.map((a) => [
      addressCell(a.accountAddress),
      ...(!creditManagerId ? [chainCell(a.chain, a.chain)] : []),
      currencyCell(a.totalValueUsd),
      iconsCell(
        a.reserves.map((r) => r.asset.symbol),
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      ),
      {
        renderType: "TEXT",
        text: `${a.healthFactor}`,
        value: a.healthFactor,
        isValueExcluded: a.healthFactor === Number.MAX_VALUE,
        status: a.healthFactor <= 1.5 ? (a.healthFactor < 1.1 ? "ERROR" : "WARNING") : "INFO",
        width: "70px",
      } as RenderData,
      textCell(`x${a.leverage / 100}`, a.leverage / 100),
      currencyCell(a.debtUsd),
      currencyTrendCell(a.pnlUsd),
      ...(!showOpenedAccounts
        ? [a.timestampClosed ? dateCell(new Date(a.timestampClosed * 1000), false) : textCell("-", 0)]
        : []),
      labelsCell("INFO", creditAccountStatusToText[a.status]),
    ]);
  }, [creditManagerId, data?.creditAccounts, hideZeroValueAccounts, showOpenedAccounts]);

  return (
    <ChaosTable
      title="Credit Accounts"
      headers={headers}
      data={renderedData}
      pageSize={10}
      isLoading={loading}
      initialSortBy={creditManagerId ? 1 : 2}
      isInitialSortDesc
      isFullHeight
      searchBy={[0]}
      showSearch
      resetPagination={resetPagination}
      resetSortBy={resetSortBy}
      headerSuffixComponent={
        <CustomSwitch
          checked={showOpenedAccounts}
          onChange={() => setShowOpenedAccounts((prev) => !prev)}
          checkedLabel="Open"
          uncheckedLabel="Closed"
        />
      }
      titleSuffixComponent={
        <Box display="flex" gap={1} alignItems="center">
          <CustomCheckbox
            checked={hideZeroValueAccounts}
            size="small"
            onChange={() => setHideZeroValueAccounts((prev) => !prev)}
          />
          <Typography>Hide zero value accounts</Typography>
        </Box>
      }
    />
  );
};

export default CreditAccountsTable;
