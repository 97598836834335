import { RenderData } from "@frontend/types";
import { formatNumericValue } from "../../utils/formatters";
import { Typography } from "../../typography";
import { Tooltip } from "../../tooltip";

export const currencyTrendCell = (value: number, cryptoCurrency?: string): RenderData => {
  const currency = !cryptoCurrency ? "USD" : undefined;
  const formattedStandard = `${formatNumericValue(value || 0, {
    currency,
    notation: "standard",
    maximumFractionDigits: 2,
  })}${cryptoCurrency ? ` ${cryptoCurrency}` : ""}`;
  const formattedCompact = `${formatNumericValue(value || 0, {
    currency,
    notation: "compact",
    maximumFractionDigits: 2,
  })}${cryptoCurrency ? ` ${cryptoCurrency}` : ""}`;

  let color = "main.white";
  if (value > 0) {
    color = "success.main";
  } else if (value < 0) {
    color = "error.main";
  }

  return {
    renderType: "CUSTOM",
    text: formattedStandard,
    component: (
      <Tooltip title={formattedStandard}>
        <Typography color={color}>{formattedCompact}</Typography>
      </Tooltip>
    ),
    value,
  };
};
