import { useEffect, useState } from "react";
import axios from "axios";
import { ResponseWithCount } from "@frontend/types";
import { dataFetchersURL } from "src/utils/data-fetchers-url";

export async function DataQueries<ReturnType = any, DataType = Record<string, unknown>>(
  query: string,
  type: "onchain" | "offchain",
  data?: DataType,
): Promise<ReturnType | undefined> {
  try {
    const res = await axios.post<ReturnType>(`${dataFetchersURL()}data/query`, {
      query,
      type,
      data,
    });

    return res.data;
  } catch (e) {
    console.log("DataQueries Error: ", e);
    return undefined;
  }
}

export const useDataFetchersQuery = <ReturnType = any, QueryType = Record<string, unknown>>(
  endpoint: `${string}.${string}`,
  type: "onchain" | "offchain",
  query?: QueryType,
  isQueryRequired?: boolean,
): {
  isLoading: boolean;
  response: ReturnType | undefined;
} => {
  const [isLoading, setIsLoading] = useState(!isQueryRequired || !!query);
  const [response, setResponse] = useState<ReturnType | undefined>(undefined);

  useEffect(() => {
    void (async () => {
      if (!isQueryRequired || query) {
        setIsLoading(true);
        const fetchedData = await DataQueries<ReturnType, QueryType>(endpoint, type, query);
        setResponse(fetchedData);
        setIsLoading(false);
      }
    })();
  }, [endpoint, type, query, isQueryRequired]);

  return { isLoading, response };
};

export const usePaginatedDataFetchersQuery = <
  ReturnType = any,
  QueryType extends { fromId?: string } = Record<string, unknown>,
>(
  endpoint: `${string}.${string}`,
  type: "onchain" | "offchain",
  query?: QueryType,
): {
  isLoading: boolean;
  response: ReturnType[] | undefined;
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<ReturnType[]>();

  useEffect(() => {
    void (async () => {
      setIsLoading(true);
      const fetchedData = await DataQueries<ResponseWithCount<ReturnType>, QueryType>(endpoint, type, query);
      if (fetchedData) {
        setResponse((prevResponse) =>
          query?.fromId ? [...(prevResponse || []), ...fetchedData.data] : fetchedData?.data,
        );
      }

      setIsLoading(false);
    })();
  }, [endpoint, type, query]);

  return { isLoading, response };
};
