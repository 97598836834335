import { FC, ReactNode, createContext, useContext, useMemo } from "react";
import { useAllMarketsQuery, MarketData } from "src/pages/ccar-lending-page/generated";

export type MarketsContextType = {
  markets: MarketData[];
  isLoading: boolean;
};

const initialState: MarketsContextType = {
  isLoading: false,
  markets: [],
};

export const MarketsContext = createContext<MarketsContextType>(initialState);

export const useMarketsContext = () => {
  const context = useContext(MarketsContext);
  return context;
};

const MarketsProvider: FC<ReactNode> = ({ children }) => {
  const { loading, data } = useAllMarketsQuery();
  const contextState: MarketsContextType = useMemo(
    () => ({
      markets: data?.allMarkets || [],
      isLoading: loading,
    }),
    [data?.allMarkets, loading],
  );

  return <MarketsContext.Provider value={contextState}>{children}</MarketsContext.Provider>;
};

export default MarketsProvider;
