import { useClientConfig } from "../../clients-config";
import { MarketType } from "../../clients-config/types";
import { BorrowCollateralAssetMarket, ChainAssetMarket, ChainMarket, Market } from "../../generated";

type ReturnType = {
  marketSymbol1?: string;
  marketSymbol2?: string;
};

export const getMarketSymbols = (market: Market, marketType: MarketType): ReturnType => {
  if (marketType === "ChainMarket") {
    return {
      marketSymbol1: (market as ChainMarket).chain,
    };
  }

  if (marketType === "BorrowCollateralAssetMarket") {
    const { borrowAsset, collateralAsset } = market as BorrowCollateralAssetMarket;

    return {
      marketSymbol1: borrowAsset,
      marketSymbol2: collateralAsset,
    };
  }

  if (marketType === "ChainAssetMarket") {
    const { chain, asset } = market as ChainAssetMarket;

    return {
      marketSymbol1: chain,
      marketSymbol2: asset,
    };
  }

  return {};
};

export const useMarketSymbols = (market: Market): ReturnType => {
  const { marketType } = useClientConfig();
  return getMarketSymbols(market, marketType);
};
