import { Box } from "../../../box";
import ECharts from "../../echarts";
import { ShimmerLoader } from "../../../shimmer-loader";
import { StringChartProps } from "../../types";
import { getValueOverTimeChart } from "./get-value-by-category-chart-options";
import { downloadCsv } from "../../../utils/csv";
import { transformToCSV } from "../export-utils";
import { ChartHeader } from "../chart-header";
import { ChartWrapper } from "../chart-wrapper";
import { useTheme } from "../../../theme";

export const ValueByCategoryChart = ({
  title,
  description,
  chartHeight = 348,
  emptyState,
  emptyStateText,
  isLoading,
  headerSuffix,
  series,
  zoomable,
  tooltip,
  ...restProps
}: StringChartProps) => {
  const theme = useTheme();
  const exportToCsv = () => {
    downloadCsv(
      ["Timestamp", ...series.map((serie) => serie.label || "")],
      transformToCSV(series),
      title || "chart_data",
    );
  };
  return (
    <ChartWrapper id={title?.toLowerCase().trim().split(" ").join("-")}>
      <ChartHeader title={title} description={description} tooltip={tooltip} exportToCsv={exportToCsv}>
        {headerSuffix}
      </ChartHeader>
      <Box height={chartHeight}>
        {isLoading ? (
          <ShimmerLoader />
        ) : (
          <ECharts
            emptyState={emptyState}
            emptyStateText={emptyStateText}
            option={getValueOverTimeChart(theme, { series, zoomable, ...restProps })}
            zoomable={zoomable}
          />
        )}
      </Box>
    </ChartWrapper>
  );
};
