import { getTxDetailsUrl } from "@frontend/ui/utils/chain-url-mapper";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, dateCell, linkCell, textCell } from "@frontend/ui";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { Chain, PositionOrder } from "../../../../../generated";
import { useClientConfig } from "../../../../../clients-config";

const headers = (useBasePnl: boolean) =>
  [
    {
      renderType: "TEXT",
      text: "Date",
      width: "200px",
    },
    {
      renderType: "TEXT",
      text: "Action",
    },
    {
      renderType: "TEXT",
      text: "Order Type",
    },
    {
      renderType: "CURRENCY",
      text: "Size",
      unit: "USD",
    },
    {
      renderType: "CURRENCY",
      text: "Price",
      unit: "USD",
    },
    ...(useBasePnl
      ? [
          {
            renderType: "CURRENCY",
            text: "Realized PnL",
            unit: "USD",
            width: "10%",
          },
          {
            renderType: "CURRENCY",
            text: "Unrealized PnL",
            unit: "USD",
            width: "10%",
          },
        ]
      : [{ renderType: "CURRENCY", text: "PnL", unit: "USD", width: "10%" }]),
    {
      renderType: "CURRENCY",
      text: "Fee",
      unit: "USD",
      width: "10%",
    },
    {
      renderType: "TEXT",
      text: "TX Id",
      width: "220px",
    },
  ] as Header[];

type Props = {
  orders?: PositionOrder[];
  isLoading: boolean;
};

const OrdersTable = ({ orders, isLoading }: Props) => {
  const {
    positions: { useBasePnl },
  } = useClientConfig();
  const tableData: RenderData[][] = (orders || []).map((order) => [
    dateCell(new Date(order.timestamp)),
    textCell(order.action),
    textCell(order.orderType),
    order.sizeUsd ? currencyCell(order.sizeUsd) : textCell("-", -Infinity),
    currencyCell(order.price),
    ...(useBasePnl
      ? [
          order.realizedPnl ? currencyCell(order.realizedPnl) : textCell("-", -Infinity),
          order.unrealizedPnl ? currencyCell(order.unrealizedPnl) : textCell("-", -Infinity),
        ]
      : [currencyCell(order.pnl)]),
    currencyCell(order.feeUsd),
    linkCell(formatAddressCompact(order.txId), getTxDetailsUrl(Chain.Solana, order.txId), order.txId),
  ]);

  return (
    <ChaosTable
      title="Orders"
      headers={headers(!!useBasePnl)}
      data={tableData}
      isLoading={isLoading}
      initialSortBy={0}
      isInitialSortDesc
      isFilterHidden
    />
  );
};

export default OrdersTable;
