import { Box, CompositionOverTimeChart, Paper } from "@frontend/ui";
import { NumberChartSeries, TimeSpan } from "@frontend/ui/echarts/types";
import { useState } from "react";
import { Chain, usePoolQuotedAssetsHistoryQuery } from "../../../generated";
import { TimeSpanPicker } from "../../../../ccar-lending-page/components/time-span-picker";

type Props = {
  chain?: Chain;
  poolAddress: string;
  loadingPool: boolean;
};

const QuotedAssetsChart = ({ chain, poolAddress, loadingPool }: Props) => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan.Month);
  const { loading, data } = usePoolQuotedAssetsHistoryQuery({
    variables: {
      timeSpan,
      chain: chain!,
      poolAddress,
    },
    skip: !chain,
  });

  const seriesByAssert = (data?.poolQuotedAssetsHistory || []).reduce((acc: Record<string, NumberChartSeries>, h) => {
    const { timestamp, quotedAssets } = h;

    const updatedSeries = quotedAssets.reduce((seriesAcc: [string, NumberChartSeries][], asset) => {
      const { symbol, totalQuoted } = asset;
      const prevSeries = acc[symbol];
      const prevData = prevSeries?.data || [];
      const newDataPoint = [timestamp, totalQuoted] as [number, number];
      if (!totalQuoted && !prevSeries) {
        return seriesAcc;
      }
      const newData = [...prevData, newDataPoint];
      const newSeries: [string, NumberChartSeries] = [
        symbol,
        {
          id: symbol,
          label: symbol,
          data: newData,
          type: "bar",
        },
      ];
      return [...seriesAcc, newSeries];
    }, []);

    return { ...acc, ...Object.fromEntries(updatedSeries) };
  }, {});

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box marginLeft="auto">
        <TimeSpanPicker selectedTimeSpan={timeSpan} onSelectTimeSpan={setTimeSpan} />
      </Box>
      <Paper variant="widget">
        <CompositionOverTimeChart
          title="Quota Assets Breakdown"
          series={Object.values(seriesByAssert)}
          isLoading={loading || loadingPool}
          chartHeight={325}
          currency=""
          percentToggle
          sortTooltipValues
          timeSpan={timeSpan}
        />
      </Paper>
    </Paper>
  );
};

export default QuotedAssetsChart;
