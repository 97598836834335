import { useContext } from "react";
import { Box, CustomReactSelect, Typography } from "@frontend/ui";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { useLocation, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import TabsNavigation, { NavigationTabProps } from "src/components/layouts/tabs-navigation";
import { Chain } from "src/pages/ccar-lending-page/generated";
import { PageHeader } from "@frontend/ui/page-header";
import { getRouteByTabPath } from "./get-tab-route-by-path";
import { useAssetsDropdownOptionsByTab } from "./use-market-assets-by-tab";
import { ListedAssetsContext } from "../context/listed-assets-context";
import { AssetsRecommendationsContext } from "../context/assets-recommendations-context";

type ParamsType = {
  clientName: string;
  chain: Chain;
  asset: string;
};

const ChainAssetDropdownHeader = () => {
  const { clientName, chain, asset } = useParams<ParamsType>();
  const location = useLocation();
  const { listedAssets } = useContext(ListedAssetsContext);
  const { assetsRecommendations } = useContext(AssetsRecommendationsContext);
  const { isLoading, assets } = useAssetsDropdownOptionsByTab();
  const { chains, isMultichain, chainsTab } = useClientConfig();

  const marketsDropdownOptions = chains.map((c) => ({
    value: c,
    label: c,
    cryptoIcon: wrappedSymbolToIconSymbol(c),
    link: getRouteByTabPath(location.pathname)
      .replace(RouteParams.Chain, c)
      .replace(RouteParams.ClientName, clientName!),
  }));

  const defaultAssetOption = [asset!];
  const marketsAssetsDropdownOptions = (assets || defaultAssetOption).map((s) => ({
    value: s,
    label: s,
    cryptoIcon: wrappedSymbolToIconSymbol(s),
    link: location.pathname.replace(asset!, s),
  }));

  const replaceParams = (path: string) =>
    path
      .replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.Chain, chain!)
      .replace(RouteParams.Asset, asset!);

  const isListedAsset = listedAssets?.some((a) => a.asset === asset!);
  const hasRecommendations = assetsRecommendations?.some((r) => r.asset === asset!);

  const assetPageTabs: NavigationTabProps[] = [
    ...(isListedAsset ? [{ label: "Risk", path: replaceParams(RoutePath.Risk.ChainAssetOverview) }] : []),
    ...(chainsTab?.assetRecommendations && hasRecommendations
      ? [{ label: "Recommendations", path: replaceParams(RoutePath.Risk.ChainAssetRecommendations) }]
      : []),
  ];

  return (
    <PageHeader
      backLink={getRouteByTabPath(location.pathname)
        .replace(RouteParams.ClientName, clientName!)
        .replace(RouteParams.Chain, chain!)}
      pageTitle={
        <Box display="flex" gap={[1, 2]} alignItems="center" data-testid="page-title">
          {isMultichain && (
            <>
              <CustomReactSelect
                disabled={isLoading}
                options={marketsDropdownOptions}
                value={marketsDropdownOptions.find((option) => option.value === chain!)}
                isSearchable={false}
                variant="h3"
              />
              <Typography variant="h3" color="almostWhite.main">
                /
              </Typography>
            </>
          )}
          <CustomReactSelect
            disabled={isLoading}
            options={marketsAssetsDropdownOptions}
            value={marketsAssetsDropdownOptions.find((option) => option.value === asset!)}
            isSearchable={false}
            variant="h3"
          />
        </Box>
      }
      suffixComponent={assetPageTabs.length > 1 && <TabsNavigation variant="sub-nav" tabs={assetPageTabs} />}
    />
  );
};

export default ChainAssetDropdownHeader;
