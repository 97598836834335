import { useMemo } from "react";
import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { FactionCards, FactionDegensTable } from "./components";
import { useDefiAppApiRequest } from "../../api/use-api-request";
import { DefiAppApiEndpoint } from "../../api/types";

const FactionDetails = () => {
  const { factionId } = useParams();
  const { response: factions } = useDefiAppApiRequest(DefiAppApiEndpoint.Factions);

  const degensParams = useMemo(
    () => ({
      id: factionId!,
    }),
    [factionId],
  );
  const { isLoading: isLoadingDegens, response: degens } = useDefiAppApiRequest(
    DefiAppApiEndpoint.FactionDegens,
    degensParams,
  );

  const faction = factions?.find((f) => f.factionId === factionId);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <FactionCards faction={faction} factionDegens={degens} />
      <FactionDegensTable factionDegens={degens} isLoading={isLoadingDegens} />
    </Box>
  );
};

export default FactionDetails;
