import { PageHeader } from "@frontend/ui/page-header";
import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { OrdersTable, PositionCards } from "./components";
import { useClientConfig } from "../../../../clients-config";
import { usePositionOrdersQuery } from "../../../../generated";

const PositionDetails = () => {
  const { positions: positionsConfig } = useClientConfig();
  const { positionId } = useParams();
  const { data, loading } = usePositionOrdersQuery({
    variables: {
      positionId: positionId!,
    },
  });

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <PageHeader
        pageTitle="Position"
        extraData={{
          text: "",
          link: positionsConfig?.accountDetailsUrl
            ? {
                text: positionId!,
                href: `${positionsConfig?.accountDetailsUrl}${positionId!}`,
                isExternal: true,
              }
            : undefined,
          allowCopy: true,
        }}
      />
      <PositionCards position={data?.position} />
      <OrdersTable orders={data?.positionOrders} isLoading={loading} />
    </Box>
  );
};

export default PositionDetails;
