import { Error } from "@frontend/ui/error";
import { Box, Typography } from "@frontend/ui";
import { useAuth } from "@frontend/ui/auth";
import { Loader } from "@frontend/ui/loader";
import { CommunityDashboardLayout } from "../layouts";

export const NotPermittedError = (): JSX.Element => {
  const auth = useAuth();

  return (
    <CommunityDashboardLayout showClientToolsDropdown={false}>
      {auth.isRefreshing ? (
        <Loader />
      ) : (
        <Error
          errorTitle="Not permitted"
          errorMessage={
            <Box>
              <Typography>You are not permitted to view this page</Typography>
              {auth.isAuthenticated && (
                <Typography variant="subtitle2">Please contact your administrator for more information</Typography>
              )}
              {!auth.isAuthenticated && <Typography variant="subtitle2">Please log in to view this page</Typography>}
            </Box>
          }
        />
      )}
    </CommunityDashboardLayout>
  );
};
