import { CryptoIcon, CustomIcon, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from "@frontend/ui";

type Props = {
  key: string;
  label: string;
  icon?: string;
  isSelected?: boolean;
  showChevron?: boolean;
  isLastItem?: boolean;
  onClick: () => void;
};

const ProtocolSwitcherListItem = ({ key, label, icon, isSelected, showChevron, isLastItem, onClick }: Props) => {
  const theme = useTheme();

  return (
    <ListItemButton
      key={key}
      onClick={onClick}
      sx={{
        ":active, &:focus": { bgcolor: "greys.700" },
        "&:hover": { bgcolor: "greys.800" },
        borderBottom: isLastItem ? "none" : "1px solid",
        borderColor: "greys.800",
        bgcolor: isSelected ? "greys.800" : "transparent",
      }}
    >
      {icon && (
        <ListItemIcon sx={{ minWidth: 32 }}>
          <CryptoIcon icon={icon} />
        </ListItemIcon>
      )}
      <ListItemText>
        <Typography variant="label" color="text.primary">
          {label}
        </Typography>
      </ListItemText>
      {isSelected && (
        <svg
          style={{ minWidth: 24 }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="4" fill={theme.palette.primary.main} />
        </svg>
      )}
      {showChevron && (
        <ListItemIcon sx={{ minWidth: 0 }}>
          <CustomIcon icon="chevron-right" />
        </ListItemIcon>
      )}
    </ListItemButton>
  );
};
export default ProtocolSwitcherListItem;
