import { DatePicker } from "@frontend/ui";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

type Props = {
  onChange: (value: Dayjs) => Promise<void>;
  value: Dayjs;
  disabled?: boolean;
};

const TimeMachineInput = ({ onChange, value, disabled }: Props) => {
  const [open, setOpen] = useState(false);

  const close = () => setOpen(false);
  const toggle = () => setOpen(!open);

  return (
    <DatePicker
      value={value}
      open={open}
      disabled={disabled}
      onChange={(date: Dayjs | null) => {
        if (date === null) return;
        void onChange(date).then(close);
      }}
      onClose={close}
      slotProps={{
        textField: {
          onClick: toggle,
        },
        popper: {
          placement: "bottom-end",
        },
      }}
      maxDate={dayjs()}
    />
  );
};

export default TimeMachineInput;
