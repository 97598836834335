import { FC, useEffect, useState } from "react";
import { Box, CustomIcon, IconButton, InputLabel, Typography } from "@frontend/ui";
import { Button, Drawer } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { RJSFSchema } from "@rjsf/utils";
import { ObjectJsonSchemaInput } from "./object-json-schema-input";
import { TriggerStepConfiguration } from "../utils";
import { AlertSettingsForm } from "./types";

interface EvaluationSchemaInputProps {
  submitting: boolean;
  isNewAlert?: boolean;
}

export const EvaluationSchemaInput: FC<EvaluationSchemaInputProps> = ({ submitting, isNewAlert }) => {
  const { control, setValue } = useFormContext<AlertSettingsForm>();
  const [openConfig, setOpenConfig] = useState(false);
  const [evaluationSchema, setEvaluationSchema] = useState<RJSFSchema | undefined>(undefined);
  const [evaluationSchemaIsValid, setEvaluationSchemaIsValid] = useState(true);
  const [hasFilterStep, setHasFilterStep] = useState(false);
  const [filterSchema, setFilterSchema] = useState<RJSFSchema | undefined>(undefined);
  const [filterSchemaIsValid, setFilterSchemaIsValid] = useState(true);

  const [evaluateStep, filterStep] = useWatch({
    control,
    name: ["evaluateStep", "filterStep"],
  }) as [TriggerStepConfiguration, TriggerStepConfiguration];

  useEffect(() => {
    setEvaluationSchema(evaluateStep?.argumentsSchema);
    setFilterSchema(filterStep?.argumentsSchema);
    setHasFilterStep(!!filterStep);
  }, [evaluateStep, filterStep, setValue]);

  const handleConfirm = () => {
    setOpenConfig(false);
    setValue(
      "evaluateStep",
      {
        argumentsSchema: evaluationSchema ?? {},
        defaultArguments: evaluateStep?.defaultArguments ?? [],
      },
      { shouldDirty: true },
    );
    if (hasFilterStep) {
      setValue(
        "filterStep",
        filterStep
          ? {
              argumentsSchema: filterSchema ?? {},
              defaultArguments: filterStep?.defaultArguments ?? [],
            }
          : undefined,
        { shouldDirty: true },
      );
    }
  };

  return (
    <>
      <Box gap={1}>
        <InputLabel>Schema</InputLabel>
        <Button variant="outlined" onClick={() => setOpenConfig(true)} disabled={submitting}>
          Configure parameters
        </Button>
      </Box>
      <Drawer anchor="right" open={openConfig} onClose={() => setOpenConfig(false)}>
        <Box height="100%" p={4}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2" ml={1}>
              Evaluation Parameters Schema
            </Typography>
            <IconButton onClick={() => setOpenConfig(false)}>
              <CustomIcon icon="close" />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <ObjectJsonSchemaInput
              title="Evaluation Arguments"
              description="Configure the arguments for the evaluation step (The order of the arguments is important)"
              value={evaluationSchema ?? {}}
              onChange={(val) => setEvaluationSchema(val)}
              onValidate={(isValid) => setEvaluationSchemaIsValid(isValid)}
              height="30vh"
              showWarningMessage={!isNewAlert}
            />
            {hasFilterStep && (
              <>
                <Typography variant="h1">Filter Parameters Schema</Typography>
                <ObjectJsonSchemaInput
                  title="Filter Arguments"
                  description="Configure the arguments for the filter step (The order of the arguments is important)"
                  value={filterSchema ?? {}}
                  onChange={(val) => setFilterSchema(val)}
                  height="30vh"
                  onValidate={(isValid) => setFilterSchemaIsValid(isValid)}
                  showWarningMessage={!isNewAlert}
                />
              </>
            )}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={!evaluationSchemaIsValid || !filterSchemaIsValid}
            sx={{
              marginY: 4,
            }}
          >
            Confirm
          </Button>
        </Box>
      </Drawer>
    </>
  );
};
