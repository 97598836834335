import { ReactNode } from "react";
import { Box, Typography } from "@frontend/ui";
import { CryptoIcon } from "../../../crypto-icon";
import { formatEChartValue } from "../../utils";

export type LegendItem = {
  name: string;
  value: number;
  color: string;
};

interface Props {
  data: LegendItem[];
  showLegendCryptoIcon?: boolean;
  isPercent?: boolean;
  currency?: string;
  cryptoCurrency?: string;
  onHover: (name: string, highlight: boolean) => void;
  onClick: (name: string) => void;
  getLegendItemSubtitle?: (name: string) => ReactNode | undefined;
  formatLegendItemLabel?: (name: string) => string;
}

const PieChartCustomLegend = ({
  data,
  showLegendCryptoIcon,
  isPercent,
  currency,
  cryptoCurrency,
  onHover,
  onClick,
  getLegendItemSubtitle,
  formatLegendItemLabel,
}: Props) => (
  <Box
    sx={{
      flexGrow: 1,
      gap: [1, 3],
      display: "flex",
      flexDirection: "row",
      height: "100%",
      flexWrap: "wrap",
      justifyContent: {
        xs: "center",
        md: "initial",
      },
    }}
  >
    {[...data]
      .sort((a, b) => b.value - a.value)
      .map((item) => (
        <Box
          key={`${item.name}-${item.value}`}
          display="flex"
          alignItems="center"
          gap={1}
          sx={{ cursor: "pointer" }}
          width={250}
          onMouseEnter={() => onHover(item.name, true)}
          onMouseLeave={() => onHover(item.name, false)}
          onClick={() => onClick(item.name)}
        >
          <Box
            sx={{
              width: 8,
              height: 8,
              backgroundColor: item.color,
              borderRadius: "50%",
              flexShrink: 0,
            }}
          />
          {showLegendCryptoIcon && <CryptoIcon icon={item.name} />}
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="h5" fontWeight={600} color="greys.400">
                {formatLegendItemLabel?.(item.name) ?? item.name}
              </Typography>
              <Typography variant="h4">
                {formatEChartValue({
                  isPercent,
                  currency,
                  cryptoCurrency,
                  notation: "compact",
                })(item.value)}
              </Typography>
            </Box>
            {getLegendItemSubtitle?.(item.name)}
          </Box>
        </Box>
      ))}
  </Box>
);

export default PieChartCustomLegend;
