import { Filter } from "@frontend/ui";
import { useCallback, useEffect, useState } from "react";
import { SortChangeHandler } from "@frontend/ui/hooks/use-pagination";
import {
  Event,
  EventStrategy,
  EventTypeFilter,
  SortEventsBy,
  SortOrder,
  useEventsLazyQuery,
} from "../../../../generated";

export type EventsTableDataProps = {
  eventType: EventTypeFilter;
  sort?: SortEventsBy;
  pageSize?: number;
  strategy?: EventStrategy;
  notProcessedRequests?: boolean;
};

export const useEventsTableData = ({
  sort,
  pageSize = 10,
  eventType,
  strategy,
  notProcessedRequests,
}: EventsTableDataProps) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [query, setQuery] = useState({
    order: SortOrder.Descending,
    sort: sort || SortEventsBy.Timestamp,
    skip: 0,
  });
  const [fetchEvents, { loading }] = useEventsLazyQuery();
  const [search, setSearch] = useState("");

  useEffect(() => {
    void (async () => {
      const { data } = await fetchEvents({
        variables: {
          input: {
            limit: 100,
            skip: query.skip,
            sort: query.sort,
            order: query.order,
            filter: {
              type: eventType,
              strategy: strategy || null,
              search: search || null,
              notProcessedRequests: notProcessedRequests || null,
            },
          },
        },
      });
      if (data?.events) {
        setEvents((p) => (query.skip ? [...p, ...data.events] : data.events));
      }
    })();
  }, [eventType, fetchEvents, notProcessedRequests, query.order, query.skip, query.sort, search, strategy]);

  const onPageChange = useCallback(
    (page: number) => {
      if (events && pageSize * page === events.length) {
        setQuery((q) => ({ ...q, skip: events.length }));
      }
    },
    [events, pageSize],
  );

  const onFiltersChange = useCallback((updatedFilter: Filter[]) => {
    setFilters([...updatedFilter]);
    setQuery((q) => ({ ...q, skip: 0 }));
  }, []);

  const onSortChange: SortChangeHandler = useCallback((sortBy, orderBy) => {
    setQuery({
      skip: 0,
      sort: sortBy as SortEventsBy,
      order: orderBy === 1 ? SortOrder.Ascending : SortOrder.Descending,
    });
  }, []);

  const onSearch = useCallback((text: string) => setSearch(text.toLowerCase()), []);

  return {
    events,
    filters,
    loading,
    onPageChange,
    onFiltersChange,
    onSortChange,
    onSearch,
  };
};
