import { Controller, useFormContext } from "react-hook-form";
import { Badge, Box, Grid, Typography } from "@frontend/ui";
import { CcarClient } from "../../alerts/utils";
import { ClientConfigurationInput } from "./client-configuration-input";
import { AlertSettingsForm } from "./types";

interface ClientsConfigurationSectionProps {
  submitting: boolean;
}

export const ClientsConfigurationSection = ({ submitting }: ClientsConfigurationSectionProps) => {
  const { control } = useFormContext<AlertSettingsForm>();

  return (
    <Box>
      <Typography variant="caption" mb={2}>
        Override the default configuration for this client, including the parameters for the evaluation and filter
        steps. (&nbsp;&nbsp;
        <Badge color="success" variant="dot" badgeContent={1} /> &nbsp;&nbsp;Indicates that the configuration has been
        overridden)
      </Typography>
      <Grid
        container
        sx={{
          border: "1px solid",
          borderBottom: "none",
          borderRight: "none",
          borderColor: "divider",
          "& > div": {
            borderRight: "1px solid",
            borderBottom: "1px solid",
            borderColor: "divider",
          },
        }}
      >
        <Controller
          name="clientsConfig"
          control={control}
          disabled={submitting}
          render={({ field }) => (
            <>
              {Object.keys(CcarClient).map((client) => (
                <Grid xs={12} md={6} lg={3} item key={client}>
                  <ClientConfigurationInput
                    key={client}
                    client={client as CcarClient}
                    value={field?.value?.[client as CcarClient]}
                    onChange={(a) =>
                      field.onChange({
                        ...field.value,
                        [client]: a,
                      })
                    }
                    disabled={field.disabled}
                  />
                </Grid>
              ))}
            </>
          )}
        />
      </Grid>
    </Box>
  );
};
