import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, numberCell, textCell } from "@frontend/ui";
import { Chain, usePoolsQuery } from "../../../generated";
import { getPoolName } from "../../../utils";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Pool",
  },
  {
    renderType: "TEXT",
    text: "Total Supply",
  },
  {
    renderType: "TEXT",
    text: "Total Borrow",
  },
  {
    renderType: "TEXT",
    text: "Deposit APY",
  },
  {
    renderType: "TEXT",
    text: "Utilization APY",
  },
  {
    renderType: "TEXT",
    text: "Utilization",
  },
];

interface PoolsTableProps {
  chain: Chain;
}

const PoolsTable = ({ chain }: PoolsTableProps) => {
  const { data, loading } = usePoolsQuery({
    variables: {
      chain,
    },
  });

  const getRowHref = (rowId: number) => {
    const pool = data?.poolsData?.[rowId];

    if (!pool) {
      return "";
    }

    return pool.id;
  };

  const renderedData: RenderData[][] =
    data?.poolsData.map((p) => [
      textCell(getPoolName(p), undefined, p.assetSymbol),
      currencyCell(p.totalSupplyUsd),
      currencyCell(p.totalBorrowUsd),
      numberCell(p.depositApy, { isPercent: true }),
      numberCell(p.utilizationApy, { isPercent: true }),
      {
        renderType: "PROGRESS",
        progressValue: p.poolUtilization,
        value: p.poolUtilization,
        textPosition: "top",
        width: 150,
      } as RenderData,
    ]) || [];

  return (
    <ChaosTable
      title="Pools"
      headers={headers}
      data={renderedData}
      rowHref={getRowHref}
      showRowChevron
      pageSize={10}
      isLoading={loading}
      initialSortBy={1}
      isInitialSortDesc
    />
  );
};

export default PoolsTable;
