export const veQiFormInputsNames = ["validatorStakedAvax", "validatorFeeRate", "stakedQi"] as const;

export type FormFieldType = (typeof veQiFormInputsNames)[number];

export type BenqiCalculatorFormValues = {
  [field in FormFieldType]: number;
};

export type FormField = {
  name: FormFieldType;
  title: string;
  isPrice?: boolean;
  isPercent?: boolean;
  icon?: string;
  max?: number;
  min?: number;
};

export const formFieldsConfig: Record<FormFieldType, FormField> = {
  validatorStakedAvax: {
    name: "validatorStakedAvax",
    title: "Validator Staked AVAX",
    icon: "avax",
    min: 0,
    max: 300000000,
  },
  validatorFeeRate: {
    name: "validatorFeeRate",
    title: "Validator Fee Rate (%)",
    isPercent: true,
    min: 0,
    max: 5,
  },
  stakedQi: {
    name: "stakedQi",
    title: "Staked QI",
    icon: "qi",
    min: 0,
    max: 7200000000,
  },
};

export type BenqiCalculatorFetchedValues = {
  avaxPrice: number;
  qiPrice: number;
  totalStakedAvax: number;
  totalStakedQi: number;
  stakingApr: number;
};

export type CalcResult = {
  stakingApr?: number;
  expectedProfit?: number;
  effectiveQiAmount?: number;
  isExpectedProfitMax?: boolean;
};

// The veQI Pool is allocated to only veQI holders.
//  35% of total delegations have been allocated to this pool.
// see: https://docs.benqi.fi/benqi-liquid-staking/veqi-vote-escrowed-qi/delegation-strategy
const AVAX_DELGATED_TO_QI_STAKERS = 0.35;

export const calculateBenqiOptimalQi = (
  formValues: Partial<BenqiCalculatorFormValues>,
  fetchedValues: BenqiCalculatorFetchedValues,
): CalcResult => {
  const { validatorStakedAvax, validatorFeeRate, stakedQi } = formValues;

  if (validatorStakedAvax === undefined || validatorFeeRate === undefined || stakedQi === undefined) return {};

  const { totalStakedAvax, totalStakedQi, stakingApr: avalancheStakingApr, avaxPrice, qiPrice } = fetchedValues;

  const effectiveValidatorQiRatio = Math.min(
    1,
    (validatorStakedAvax * 4) / (totalStakedAvax * AVAX_DELGATED_TO_QI_STAKERS),
  );

  const effectiveQiAmount = (effectiveValidatorQiRatio * totalStakedQi) / (1 - effectiveValidatorQiRatio);

  const effectiveStakedQi = Math.max(0, Math.min(stakedQi, effectiveQiAmount));

  const expectedProfit =
    (effectiveStakedQi / (totalStakedQi + effectiveStakedQi)) *
    totalStakedAvax *
    AVAX_DELGATED_TO_QI_STAKERS *
    (avalancheStakingApr / 100) *
    (validatorFeeRate / 100);

  const stakingApr = (expectedProfit * avaxPrice) / (stakedQi * qiPrice);

  const isExpectedProfitMax = effectiveStakedQi === effectiveQiAmount && validatorFeeRate === 5;

  return {
    stakingApr,
    expectedProfit,
    effectiveQiAmount,
    isExpectedProfitMax,
  };
};
