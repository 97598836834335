export enum RiskStatus {
  "ELIGIBLE_FOR_LIQUIDATION" = 0,
  "AT_RISK_FOR_LIQUIDATION" = 1,
  "NO_RISK_FOR_LIQUIDATION" = 2,
}

export type Wallet = {
  address: string;
  totalLiquidityUSD?: number;
  totalCollateralUSD?: number;
  borrowPowerUSD?: number;
  totalSuppliedUSD?: number;
  totalBorrowsUSD: number;
  health: number;
  minRiskStatus: RiskStatus;
  chains: string[];
  positions: string[];
  becameAtRisk?: boolean;
};
