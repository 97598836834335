import { TreemapChart } from "@frontend/ui/charts";
import { Box, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { OverviewChartsStats } from "../types";

type Props = {
  data?: OverviewChartsStats;
};

type DistributionAsset = {
  name: string;
  value: number;
};

const sortDistribution = (a: DistributionAsset, b: DistributionAsset) => {
  const tokenA = wrappedSymbolToIconSymbol(a.name);
  const tokenB = wrappedSymbolToIconSymbol(b.name);
  return tokenA > tokenB ? 1 : -1;
};

export const SupplyBorrowDistribution = ({ data }: Props) => {
  if (!data?.borrowDistribution || !data.supplyDistribution) {
    return null;
  }
  return (
    <Box height="100%" display="flex" gap={1.5} flexDirection={{ xs: "column", md: "row" }}>
      <Box flex={1} width={{ xs: "100%", md: 0 }} bgcolor="background.default" p={3} pb={4.5} borderRadius={2}>
        <Typography display="flex" justifyContent="center" variant="h4" mb={1}>
          Distribution of Supplied Assets (USD)
        </Typography>
        <TreemapChart
          data={data.supplyDistribution.sort(sortDistribution)}
          orientLabel={false}
          labelFormat={(value: number, name?: string) => `${name || ""}\n${formatAmount(value, { currency: "usd" })}`}
          tooltipFormat={(value: number, name?: string) =>
            `${name || ""}\n${formatAmount(value, { currency: "usd", notation: "standard" })}`
          }
        />
      </Box>
      <Box flex={1} width={{ xs: "100%", md: 0 }} bgcolor="background.default" p={3} pb={4.5} borderRadius={2}>
        <Typography display="flex" justifyContent="center" variant="h4" mb={1}>
          Distribution of Borrowed Assets (USD)
        </Typography>
        <TreemapChart
          data={data.borrowDistribution.sort(sortDistribution)}
          orientLabel={false}
          labelFormat={(value: number, name?: string) => `${name || ""}\n${formatAmount(value, { currency: "usd" })}`}
          tooltipFormat={(value: number, name?: string) =>
            `${name || ""}\n${formatAmount(value, { currency: "usd", notation: "standard" })}`
          }
        />
      </Box>
    </Box>
  );
};
