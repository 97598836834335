import { useParams } from "react-router-dom";
import { Box, CryptoIcon, Paper, Typography } from "@frontend/ui";
import { TagChip } from "@frontend/ui/tag-chip";
import { Chain, useAssetsHistoricalPricesQuery, useEModeCategoriesQuery } from "src/pages/ccar-lending-page/generated";
import { TopWallets } from "src/pages/ccar-lending-page/components/top-wallets";
import { HistoricalPriceChart, WalletsTable } from "../../../markets/components";

const EModeDetails = () => {
  const { chain, eModeId } = useParams<{ chain: Chain; eModeId: string }>();
  const { data } = useEModeCategoriesQuery({ variables: { chain: chain! } });
  const eModeCategory = data?.eModeCategories.find((c) => c.categoryId === Number(eModeId));
  const { data: historicalPrices, loading } = useAssetsHistoricalPricesQuery({
    variables: {
      assets: eModeCategory?.assetSymbols.map((tokenSymbol) => ({ tokenSymbol, chain: chain! })) || [],
    },
  });

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Paper variant="card">
        <Typography variant="h2" mb={2}>
          Composition
        </Typography>
        <Paper variant="widget" sx={{ display: "flex", gap: 0.5 }}>
          {eModeCategory?.assetSymbols.map((asset) => (
            <TagChip
              label={asset}
              sx={{ bgcolor: "darkGrey.main", p: 0.5, height: 32 }}
              icon={<CryptoIcon icon={asset.toLowerCase()} />}
            />
          ))}
        </Paper>
      </Paper>
      <HistoricalPriceChart
        isLoading={loading}
        assetHistoricalPrices={historicalPrices?.assetsHistoricalPrices}
        isMultiAsset
      />
      <Paper variant="card">
        <TopWallets chain={chain} eModeCategoryId={Number(eModeId)} />
      </Paper>
      <WalletsTable chain={chain} eModeCategoryId={Number(eModeId)} />
    </Box>
  );
};

export default EModeDetails;
