import { Box, Typography } from "@frontend/ui";
import { Button } from "@mui/material";
import NotFoundImage from "./404.png";

export const TemporaryUnavailable = () => (
  <Box
    minHeight="50vh"
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent="space-around"
    sx={{
      border: "1px solid #344054",
      borderBottom: "0",
    }}
    paddingRight={4}
    paddingLeft={4}
  >
    <Box minHeight="100%" maxWidth="50%" display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="h1" fontWeight={500} fontSize={64} lineHeight={1.2}>
        This page it temporarily unavailable
      </Typography>
      <Typography variant="caption" fontWeight={400} fontSize={24} lineHeight={1.2} mt={4}>
        It seems something went wrong.
      </Typography>
      <Typography variant="caption" fontWeight={400} fontSize={24} lineHeight={1.2} mt={4} maxWidth="80%">
        While we resolve the issue, we invite you to explore our Oracle Research blog.
      </Typography>

      <Button color="primary" href="https://chaoslabs.xyz/blog?tag=Oracles" target="_blank" sx={{ marginTop: 4 }}>
        Oracle Research
      </Button>
    </Box>
    <Box minHeight="100%" maxWidth="50%">
      <img alt="404" src={NotFoundImage} />
    </Box>
  </Box>
);
