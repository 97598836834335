import { ValueType } from "@frontend/ui";
import { GmPoolConfigFragment } from "src/pages/ccar-perpetuals-page/generated";

type Display = {
  name: string;
  valueType?: ValueType;
};

type PoolConfigKey = keyof Omit<GmPoolConfigFragment, "__typename">;
export const poolConfigKeyToDisplay: Record<PoolConfigKey, Display> = {
  positionImpactExponentFactor: {
    name: "Position Impact Exponent",
  },
  positionImpactFactorPositive: {
    name: "Position Impact Positive Factor",
  },
  positionImpactFactorNegative: {
    name: "Position Impact Negative Factor",
  },
  maxPositionImpactFactorPositive: {
    name: "Position Impact Max Positive Factor",
  },
  maxPositionImpactFactorNegative: {
    name: "Position Impact Max Negative Factor",
  },
  maxPositionImpactFactorForLiquidations: {
    name: "Position Impact Max Factor for Liquidations",
  },
  swapImpactExponentFactor: {
    name: "Swap Impact Exponent",
  },
  swapImpactFactorPositive: {
    name: "Swap Impact Positive Factor",
  },
  swapImpactFactorNegative: {
    name: "Swap Impact Negative Factor",
  },
  swapFeeFactorForPositiveImpact: {
    name: "Swap Fee Factor (Positive Price Impact)",
  },
  swapFeeFactorForNegativeImpact: {
    name: "Swap Fee Factor (Negative Price Impact)",
  },
  positionFeeFactorForPositiveImpact: {
    name: "Position Fee Factor (Positive Price Impact)",
  },
  positionFeeFactorForNegativeImpact: {
    name: "Position Fee Factor (Negative Price Impact)",
  },
  borrowingFactorLong: {
    name: "Borrowing Factor Long",
  },
  borrowingFactorShort: {
    name: "Borrowing Factor Short",
  },
  borrowingExponentFactorLong: {
    name: "Borrowing Exponent Long",
  },
  borrowingExponentFactorShort: {
    name: "Borrowing Exponent Short",
  },
  fundingFactor: {
    name: "Funding Factor",
  },
  fundingExponentFactor: {
    name: "Funding Exponent",
  },
  minCollateralUsd: {
    name: "Min Collateral",
    valueType: "currency",
  },
  minPositionSizeUsd: {
    name: "Min Position size",
    valueType: "currency",
  },
  minCollateralFactor: {
    name: "Min Collateral Factor",
  },
  minCollateralFactorForOpenInterestLong: {
    name: "Min Collateral Factor OI Long",
  },
  minCollateralFactorForOpenInterestShort: {
    name: "Min Collateral Factor OI Short",
  },
  maxLeverage: {
    name: "Max Leverage",
  },
  maxOpenInterestLong: {
    name: "Max Open Interest Long",
    valueType: "currency",
  },
  maxOpenInterestShort: {
    name: "Max Open Interest Short",
    valueType: "currency",
  },
  reserveFactorLong: {
    name: "Reserve Factor Long",
    valueType: "percent",
  },
  reserveFactorShort: {
    name: "Reserve Factor Short",
    valueType: "percent",
  },
  openInterestReserveFactorLong: {
    name: "Open Interest Reserve Factor Long",
    valueType: "percent",
  },
  openInterestReserveFactorShort: {
    name: "Open Interest Reserve Factor Short",
    valueType: "percent",
  },
  maxPnlFactorLong: {
    name: "Max PnL Factor Long",
    valueType: "percent",
  },
  maxPnlFactorShort: {
    name: "Max PnL Factor Short",
    valueType: "percent",
  },
};
