import { PieChart } from "@frontend/ui";
import { useNodeOperatorsQuery } from "../../../../generated";

const TvrPieChart = () => {
  const { data, loading } = useNodeOperatorsQuery();

  return (
    <PieChart
      title="Total Value Restaked by Node Operator"
      series={[
        {
          data: (data?.nodeOperators || []).map((o) => [o.name, o.tvrEth || 0] as [string, number]),
        },
      ]}
      cryptoCurrency="ETH"
      isLoading={loading}
    />
  );
};

export default TvrPieChart;
