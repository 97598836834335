import { useState } from "react";
import { Badge, Box, Button, CryptoIcon, CustomIcon, Grid, IconButton, Paper, Typography } from "@frontend/ui";
import { Drawer, Switch } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { CcarClient, mapProtocolToData } from "../../alerts/utils";
import { ClientConfig, NotificationChannel, NotificationConfig, TriggerStepConfiguration } from "../utils";
import { JsonSchemaInput } from "./json-schema-input";
import { NotificationChannelInput } from "./notification-channel-input";
import { AlertSettingsForm } from "./types";
import { FormSection } from "./form-section";

interface ClientConfigurationInputProps {
  client: CcarClient;
  value: ClientConfig | undefined;
  onChange: (value: ClientConfig) => void;
  disabled?: boolean;
}

export const ClientConfigurationInput = ({ client, value, onChange, disabled }: ClientConfigurationInputProps) => {
  const { control } = useFormContext<AlertSettingsForm>();
  const [openConfig, setOpenConfig] = useState(false);
  const [panelChanges, setPanelChanges] = useState<ClientConfig>(value ?? { enabled: true });

  const [globalNotificationConfig, globalEvaluateStep, globalFilterStep, globalHideFromUi, levelOption] = useWatch({
    control,
    name: ["globalNotificationConfig", "evaluateStep", "filterStep", "hideFromUi", "level"],
  }) as [
    Record<NotificationChannel, NotificationConfig>,
    TriggerStepConfiguration,
    TriggerStepConfiguration,
    boolean,
    {
      label: string;
      value: string;
    },
  ];
  const level = levelOption?.value;

  const handleOpenConfig = () => {
    setOpenConfig(!disabled);
    setPanelChanges(value ?? { enabled: true });
  };

  const handleConfirm = () => {
    onChange({ ...panelChanges, enabled: true });
    setOpenConfig(false);
  };

  return (
    <>
      <Paper variant="widget">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          justifyItems="center"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <CryptoIcon icon={mapProtocolToData[client].icon} />
            <Typography variant="h6" ml={1}>
              {mapProtocolToData[client].name} {mapProtocolToData[client].version}
            </Typography>
          </Box>

          <Switch
            checked={value?.enabled ?? false}
            disabled={disabled}
            size="small"
            onChange={(e) => onChange({ ...value, enabled: e.target.checked })}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2} mt={2}>
          <Typography
            variant={disabled ? "label" : "link"}
            onClick={handleOpenConfig}
            sx={{
              textTransform: "none",
              ...(!disabled && {
                color: "primary.main",
              }),
            }}
          >
            Override Configuration
          </Typography>
          <Badge
            color="success"
            variant="dot"
            badgeContent={
              Object.values(value?.notificationConfig ?? {}).some((config) => !!config) ||
              value?.overrideFetchInputs?.length ||
              value?.overrideFilterInputs?.length
                ? 1
                : 0
            }
            sx={{
              mr:
                Object.values(value?.notificationConfig ?? {}).some((config) => !!config) ||
                value?.overrideFetchInputs?.length ||
                value?.overrideFilterInputs?.length
                  ? 1
                  : 0,
            }}
          />
        </Box>
      </Paper>

      <Drawer anchor="right" open={openConfig} onClose={() => setOpenConfig(false)}>
        <Box height="100%" p={4}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box display="flex" flexDirection="row" alignItems="center">
              <CryptoIcon icon={mapProtocolToData[client].icon} />
              <Typography variant="h2" ml={1}>
                {mapProtocolToData[client].name} {mapProtocolToData[client].version}
              </Typography>
            </Box>
            <IconButton onClick={() => setOpenConfig(false)}>
              <CustomIcon icon="close" />
            </IconButton>
          </Box>
          <Box>
            {level === "PROTOCOL" && globalEvaluateStep?.argumentsSchema && (
              <JsonSchemaInput
                value={
                  panelChanges?.overrideFetchInputs?.length
                    ? panelChanges.overrideFetchInputs
                    : globalEvaluateStep.defaultArguments ?? []
                }
                schema={{
                  ...globalEvaluateStep?.argumentsSchema,
                  title: "Evaluation Parameters",
                  description: "Override the default parameters for the evaluation step of the alert",
                }}
                disabled={false}
                onChange={(newInputs) => setPanelChanges({ ...panelChanges, overrideFetchInputs: newInputs })}
              />
            )}
            {globalFilterStep?.argumentsSchema && (
              <JsonSchemaInput
                value={
                  panelChanges?.overrideFilterInputs?.length
                    ? panelChanges.overrideFilterInputs
                    : globalFilterStep.defaultArguments ?? []
                }
                schema={{
                  ...globalFilterStep.argumentsSchema,
                  title: "Filter Parameters",
                  description: "Override the default parameters for the filter step of the alert",
                }}
                disabled={false}
                onChange={(newInputs) => setPanelChanges({ ...panelChanges, overrideFilterInputs: newInputs })}
              />
            )}

            <FormSection title="Notifications">
              <Grid
                container
                sx={{
                  border: "1px solid",
                  borderBottom: "none",
                  borderRight: "none",
                  borderColor: "divider",
                  "& > div": {
                    borderRight: "1px solid",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  },
                }}
              >
                {Object.keys(NotificationChannel).map((channel) => (
                  <Grid item md={6} xs={12} key={channel}>
                    <NotificationChannelInput
                      key={channel}
                      channel={channel as NotificationChannel}
                      value={
                        (panelChanges?.notificationConfig as Record<NotificationChannel, NotificationConfig>)?.[
                          channel as NotificationChannel
                        ] ?? globalNotificationConfig?.[channel as NotificationChannel]
                      }
                      onChange={(newConfig) =>
                        setPanelChanges({
                          ...panelChanges,
                          notificationConfig: { ...panelChanges?.notificationConfig, [channel]: newConfig },
                        })
                      }
                    />
                  </Grid>
                ))}
                <Grid item md={6} xs={12}>
                  <NotificationChannelInput
                    channel="community"
                    value={{
                      enabled: !(panelChanges?.hideFromUi ?? globalHideFromUi),
                    }}
                    onChange={(val) => setPanelChanges({ ...panelChanges, hideFromUi: !val.enabled })}
                  />
                </Grid>
              </Grid>
            </FormSection>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            sx={{
              marginY: 4,
            }}
          >
            Confirm
          </Button>
        </Box>
      </Drawer>
    </>
  );
};
