import { ResponsiveLineCanvas, LineCanvasProps } from "@nivo/line";
import { colorScale } from "../utils/color-scale";
import { theme } from "./chart-theme";

type Props = LineCanvasProps;

export const CanvasLineChart = ({ ...lineCanvasProps }: Props) => (
  <ResponsiveLineCanvas
    theme={theme("small")}
    margin={{
      top: 2,
      right: 2,
      bottom: 2,
      left: 2,
    }}
    colors={colorScale}
    axisLeft={null}
    axisBottom={null}
    curve="monotoneX"
    isInteractive={false}
    enableGridX={false}
    enableGridY={false}
    enablePoints={false}
    {...lineCanvasProps}
  />
);
