export interface AlertWebhook {
  name: string;
  url: string;
  alertIds?: string[];
  ccarClients?: string[];
  allClientsEnabled: boolean;
  allAlertsEnabled: boolean;
}

export function convertClientToDisplayName(inputString: string) {
  const parts = inputString.split(/[-_]/);
  const capitalizedParts = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
  return capitalizedParts.join(" ");
}
