import { ReactNode } from "react";
import { DefaultLabelFormatterCallbackParams, MarkLineComponentOption } from "echarts";

export enum TimeSpan {
  Day = "day",
  Month = "month",
  Quarter = "quarter",
  Week = "week",
  Year = "year",
}

// see: https://echarts.apache.org/v4/en/option.html#series
export type SeriesType = "line" | "bar" | "area";

export type CustomTooltipFunc = (
  params: DefaultLabelFormatterCallbackParams[],
  valueFormatter?: (val: string | number) => string,
  showPercentOfTotal?: boolean,
) => ReactNode;

type BaseChartProps = {
  title?: string;
  titleCryptoIcon?: string;
  description?: string | ReactNode;
  headerSuffix?: ReactNode;
  emptyStateText?: string;
  emptyState?: boolean;
  chartHeight?: number;
  currency?: string;
  isPercent?: boolean;
  isLoading?: boolean;
  yAxisLabel?: string;
  dualAxis?: boolean;
  yAxisLabelRight?: string;
  yAxisMax?: boolean;
  yAxisMin?: boolean;
  showLegend?: boolean;
  connectNulls?: boolean;
  timeSpan?: TimeSpan;
  showPercentOfTotal?: boolean;
  hideTotal?: boolean;
  showXAxisLine?: boolean;
  zoomable?: boolean;
  cryptoCurrency?: string;
  tooltip?: string;
  xAxisLabel?: string;
  getCustomTooltip?: CustomTooltipFunc;
  useDefaultEchartsTimeFormat?: boolean;
  sortTooltipValues?: boolean;
  dateFormatter?: (timestamp: number) => string | undefined;
  xAxisSplitNumber?: number;
  xAxisFontSize?: number;
  hideLegend?: boolean;
  showAllXAxisLabels?: boolean;
};

export type NumberChartProps = {
  series: NumberChartSeries[];
  fillMissingPoints?: boolean;
  yAxisMaximumFractionDigits?: number;
  tooltipHeaderFormatter?: (value: string | number) => string | undefined;
  yAxisLabelFormatter?: (value: number) => string;
  tooltipValueFormatter?: (value: number) => string;
} & BaseChartProps;

export type StringChartProps = BaseChartProps & {
  series: StringChartSeries[];
  tooltipShowTotal?: boolean;
  xAxisData?: string[];
  formatXAxisLabel?: (label: string) => string;
  tooltipHeaderFormatter?: (value: string | number) => ReactNode;
};

type BaseChartSeries = {
  id?: string;
  label?: string;
  color?: string;
  type?: SeriesType;
  lineStyle?: { width: number };
  yAxisIndex?: number;
  smooth?: boolean;
  highlightTrends?: boolean;
  showArea?: boolean;
  animation?: boolean;
  stack?: string;
  z?: number;
  markLine?: MarkLineComponentOption;
};

export type NumberChartSeries = {
  data: [number, number][];
} & BaseChartSeries;

export type StringChartSeries = {
  data: [string, number][];
} & BaseChartSeries;
