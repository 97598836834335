import { Box, Paper, Tooltip, Typography } from "@frontend/ui";
import { CustomIcon } from "@frontend/ui/custom-icon";
import { palette } from "@frontend/ui/theme/palette";
import { Trend, TrendProps } from "@frontend/ui/trend";

export type RecommendationCardProps = {
  title: string;
  titleColor?: string;
  titleVariant?: "h5" | "h6";
  icon: string;
  currentValue?: string;
  valueWithRecommendation?: string;
  bgColor?: string;
  tooltip?: string;
  isIconOriginalColor?: boolean;
  recommendationLabel?: string;
  currentTrend?: TrendProps;
  recommendedTrend?: TrendProps;
};
export const RecommendationCard = ({
  title,
  titleColor,
  titleVariant = "h6",
  icon,
  currentValue,
  valueWithRecommendation,
  bgColor,
  tooltip,
  isIconOriginalColor,
  recommendationLabel,
  currentTrend,
  recommendedTrend,
}: RecommendationCardProps) => (
  <Paper
    sx={{
      boxShadow: "none",
      padding: 2,
      borderRadius: 2,
      backgroundColor: bgColor || palette.darkGrey.main,
      display: "flex",
      flexDirection: "column",
      gap: 1,
      width: "100%",
      height: "100%",
    }}
  >
    <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
      <Box display="flex" alignItems="center" gap={1}>
        <CustomIcon
          icon={icon}
          sx={{
            width: 18,
            height: 18,
            svg: { path: { fill: isIconOriginalColor ? undefined : "white" } },
          }}
        />
        <Typography variant={titleVariant} color={titleColor} whiteSpace="nowrap">
          {title}
        </Typography>
      </Box>
      {tooltip && (
        <Tooltip title={tooltip}>
          <Box>
            <CustomIcon
              icon="info"
              sx={{
                color: "almostWhite.main",
                "& path": { fill: "currentColor" },
              }}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
    <Box display="flex" flexWrap="wrap" gap={2}>
      {currentValue !== undefined && (
        <Box flex={1}>
          <Typography variant="caption">Current</Typography>
          <Box display="flex" gap={1}>
            <Typography variant="h3">{currentValue}</Typography>
            {!!currentTrend && <Trend {...currentTrend} />}
          </Box>
        </Box>
      )}
      {valueWithRecommendation && (
        <Box flex={1}>
          <Typography variant="caption" whiteSpace="nowrap">
            {recommendationLabel || "With recommendation"}
          </Typography>
          <Box display="flex" gap={1}>
            <Typography variant="h3">{valueWithRecommendation}</Typography>
            {!!recommendedTrend && <Trend {...recommendedTrend} />}
          </Box>
        </Box>
      )}
    </Box>
  </Paper>
);
