import React, { useEffect, useState } from "react";
import { SxProps } from "@mui/material";
import { Box } from "../box";

const ChaosIcon = () => import("../assets/img/icons/logo-icon.svg").then(({ ReactComponent }) => <ReactComponent />);
const AirplayIcon = () =>
  import("../assets/img/icons/nav/airplay.svg").then(({ ReactComponent }) => <ReactComponent />);
const AirplayGradientShadowIcon = () =>
  import("../assets/img/icons/airplay-gradient-shadow.svg").then(({ ReactComponent }) => <ReactComponent />);
const ViewListIcon = () =>
  import("../assets/img/icons/nav/view-list.svg").then(({ ReactComponent }) => <ReactComponent />);
const AlbumLibraryIcon = () =>
  import("../assets/img/icons/nav/album-library.svg").then(({ ReactComponent }) => <ReactComponent />);
const GiftIcon = () => import("../assets/img/icons/nav/gift.svg").then(({ ReactComponent }) => <ReactComponent />);
const GridIcon = () => import("../assets/img/icons/nav/grid.svg").then(({ ReactComponent }) => <ReactComponent />);
const CardEjectIcon = () =>
  import("../assets/img/icons/card-eject.svg").then(({ ReactComponent }) => <ReactComponent />);
const CardLockIcon = () => import("../assets/img/icons/card-lock.svg").then(({ ReactComponent }) => <ReactComponent />);
const HistoryIcon = () =>
  import("../assets/img/icons/nav/history.svg").then(({ ReactComponent }) => <ReactComponent />);
const HathorIcon = () =>
  import("../assets/img/crypto/hathor-logo.svg").then(({ ReactComponent }) => <ReactComponent />);
const KeyboardIcon = () =>
  import("../assets/img/icons/nav/keyboard.svg").then(({ ReactComponent }) => <ReactComponent />);
const PaletteIcon = () =>
  import("../assets/img/icons/nav/palette.svg").then(({ ReactComponent }) => <ReactComponent />);
const PerspectiveIcon = () =>
  import("../assets/img/icons/nav/perspective.svg").then(({ ReactComponent }) => <ReactComponent />);
const VectorIcon = () => import("../assets/img/icons/nav/vector.svg").then(({ ReactComponent }) => <ReactComponent />);
const BulletBlueIcon = () =>
  import("../assets/img/icons/bullet-blue.svg").then(({ ReactComponent }) => <ReactComponent />);
const GridFourIcon = () => import("../assets/img/icons/grid-four.svg").then(({ ReactComponent }) => <ReactComponent />);
const GridTwoHorizontal = () =>
  import("../assets/img/icons/grid-two-h.svg").then(({ ReactComponent }) => <ReactComponent />);
const BubbleRaceIcon = () =>
  import("../assets/img/icons/bubble-race.svg").then(({ ReactComponent }) => <ReactComponent />);
const UsersIcon = () => import("../assets/img/icons/users.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChevronDownFilledIcon = () =>
  import("../assets/img/icons/chevron-down-filled.svg").then(({ ReactComponent }) => <ReactComponent />);
const FiledVideo = () => import("../assets/img/icons/file-video.svg").then(({ ReactComponent }) => <ReactComponent />);
const FilterIcon = () => import("../assets/img/icons/filter.svg").then(({ ReactComponent }) => <ReactComponent />);
const PlayIcon = () => import("../assets/img/icons/play.svg").then(({ ReactComponent }) => <ReactComponent />);
const PieGradientShadowIcon = () =>
  import("../assets/img/pie-gradient-shadow.svg").then(({ ReactComponent }) => <ReactComponent />);
const TriangleGradientShadowIcon = () =>
  import("../assets/img/triangle-gradient-shadow.svg").then(({ ReactComponent }) => <ReactComponent />);
const CheckSquareIcon = () =>
  import("../assets/img/icons/check-square.svg").then(({ ReactComponent }) => <ReactComponent />);
const MenuToggleIcon = () =>
  import("../assets/img/icons/menu-toggle.svg").then(({ ReactComponent }) => <ReactComponent />);
const PieSliceIcon = () => import("../assets/img/icons/pie-slice.svg").then(({ ReactComponent }) => <ReactComponent />);
const SortNoneIcon = () => import("../assets/img/icons/sort-non.svg").then(({ ReactComponent }) => <ReactComponent />);
const SortDownIcon = () => import("../assets/img/icons/sort-down.svg").then(({ ReactComponent }) => <ReactComponent />);
const SortUpIcon = () => import("../assets/img/icons/sort-up.svg").then(({ ReactComponent }) => <ReactComponent />);
const LabIcon = () => import("../assets/img/icons/lab.svg").then(({ ReactComponent }) => <ReactComponent />);
const CalendarIcon = () => import("../assets/img/icons/calendar.svg").then(({ ReactComponent }) => <ReactComponent />);
const BriefCaseIcon = () =>
  import("../assets/img/icons/briefcase.svg").then(({ ReactComponent }) => <ReactComponent />);
const CheckboxOffIcon = () =>
  import("../assets/img/icons/checkbox-off.svg").then(({ ReactComponent }) => <ReactComponent />);
const GithubIcon = () => import("../assets/img/icons/github.svg").then(({ ReactComponent }) => <ReactComponent />);
const GithubAltIcon = () =>
  import("../assets/img/icons/github-alt.svg").then(({ ReactComponent }) => <ReactComponent />);
const GoogleIcon = () => import("../assets/img/icons/google.svg").then(({ ReactComponent }) => <ReactComponent />);
const TwitterIcon = () => import("../assets/img/icons/twitter.svg").then(({ ReactComponent }) => <ReactComponent />);
const XLogoIcon = () => import("../assets/img/icons/logo-x.svg").then(({ ReactComponent }) => <ReactComponent />);
const TwitterFilledIcon = () =>
  import("../assets/img/icons/twitter-filled.svg").then(({ ReactComponent }) => <ReactComponent />);
const OutlineTwitter = () =>
  import("../assets/img/icons/outline-twitter.svg").then(({ ReactComponent }) => <ReactComponent />);
const EyeIcon = () => import("../assets/img/icons/eye.svg").then(({ ReactComponent }) => <ReactComponent />);
const FolderUpload = () =>
  import("../assets/img/icons/folder-upload.svg").then(({ ReactComponent }) => <ReactComponent />);
const EyeOffIcon = () => import("../assets/img/icons/eye-off.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChevronDownIcon = () =>
  import("../assets/img/icons/chevron-down.svg").then(({ ReactComponent }) => <ReactComponent />);
const RemoveIcon = () => import("../assets/img/icons/remove.svg").then(({ ReactComponent }) => <ReactComponent />);
const CloseIcon = () => import("../assets/img/icons/close.svg").then(({ ReactComponent }) => <ReactComponent />);
const SearchIcon = () => import("../assets/img/icons/search.svg").then(({ ReactComponent }) => <ReactComponent />);
const WifiIcon = () => import("../assets/img/icons/wi-fi.svg").then(({ ReactComponent }) => <ReactComponent />);
const UserIcon = () => import("../assets/img/icons/user.svg").then(({ ReactComponent }) => <ReactComponent />);
const BellIcon = () => import("../assets/img/icons/bell.svg").then(({ ReactComponent }) => <ReactComponent />);
const SettingsIcon = () => import("../assets/img/icons/settings.svg").then(({ ReactComponent }) => <ReactComponent />);
const SignOutIcon = () => import("../assets/img/icons/sign-out.svg").then(({ ReactComponent }) => <ReactComponent />);
const CloseCircleIcon = () =>
  import("../assets/img/icons/close-circle.svg").then(({ ReactComponent }) => <ReactComponent />);
const CheckCircleIcon = () =>
  import("../assets/img/icons/check-circle.svg").then(({ ReactComponent }) => <ReactComponent />);
const StackedAreaUpIcon = () =>
  import("../assets/img/icons/stacked-area-up.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChevronRightIcon = () =>
  import("../assets/img/icons/chevron-right.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChevronLeftIcon = () =>
  import("../assets/img/icons/chevron-left.svg").then(({ ReactComponent }) => <ReactComponent />);
const ListBoxesIcon = () =>
  import("../assets/img/icons/list-boxes.svg").then(({ ReactComponent }) => <ReactComponent />);
const ExclamationSquareIcon = () =>
  import("../assets/img/icons/exclamation-square.svg").then(({ ReactComponent }) => <ReactComponent />);
const ExclamationMarkIcon = () =>
  import("../assets/img/icons/exclamation-mark.svg").then(({ ReactComponent }) => <ReactComponent />);
const ArrowLeftIcon = () =>
  import("../assets/img/icons/arrow-left.svg").then(({ ReactComponent }) => <ReactComponent />);
const ArrowRightIcon = () =>
  import("../assets/img/icons/arrow-right.svg").then(({ ReactComponent }) => <ReactComponent />);
const BreadcrumbsArrowIcon = () =>
  import("../assets/img/icons/breadcrumbs-arrow.svg").then(({ ReactComponent }) => <ReactComponent />);
const CloudDownloadIcon = () =>
  import("../assets/img/icons/cloud-download.svg").then(({ ReactComponent }) => <ReactComponent />);
const PrinterIcon = () => import("../assets/img/icons/printer.svg").then(({ ReactComponent }) => <ReactComponent />);
const SettingsAdjustIcon = () =>
  import("../assets/img/icons/settings-adjust.svg").then(({ ReactComponent }) => <ReactComponent />);
const GridTwoVerticalIcon = () =>
  import("../assets/img/icons/grid-two-v.svg").then(({ ReactComponent }) => <ReactComponent />);
const CheckboxIndeterminateIcon = () =>
  import("../assets/img/icons/checkbox-indeterminate.svg").then(({ ReactComponent }) => <ReactComponent />);
const FullscreenIcon = () =>
  import("../assets/img/icons/fullscreen.svg").then(({ ReactComponent }) => <ReactComponent />);
const ExitFullscreenIcon = () =>
  import("../assets/img/icons/exit-fullscreen.svg").then(({ ReactComponent }) => <ReactComponent />);
const FileCheckInIcon = () =>
  import("../assets/img/icons/file-check-in.svg").then(({ ReactComponent }) => <ReactComponent />);
const CoinDollarIcon = () =>
  import("../assets/img/icons/coin-dollar.svg").then(({ ReactComponent }) => <ReactComponent />);
const StarIcon = () => import("../assets/img/icons/star.svg").then(({ ReactComponent }) => <ReactComponent />);
const ImportIcon = () => import("../assets/img/icons/import.svg").then(({ ReactComponent }) => <ReactComponent />);
const ScaleIcon = () => import("../assets/img/icons/scale.svg").then(({ ReactComponent }) => <ReactComponent />);
const CoinsIcon = () => import("../assets/img/icons/coins.svg").then(({ ReactComponent }) => <ReactComponent />);
const StarFilledIcon = () =>
  import("../assets/img/icons/star-filled.svg").then(({ ReactComponent }) => <ReactComponent />);
const EraserIcon = () => import("../assets/img/icons/eraser.svg").then(({ ReactComponent }) => <ReactComponent />);
const TrashCanIcon = () => import("../assets/img/icons/trash-can.svg").then(({ ReactComponent }) => <ReactComponent />);
const TransactionIcon = () =>
  import("../assets/img/icons/transaction.svg").then(({ ReactComponent }) => <ReactComponent />);
const ImageUploadIcon = () =>
  import("../assets/img/icons/image-upload.svg").then(({ ReactComponent }) => <ReactComponent />);
const EditIcon = () => import("../assets/img/icons/edit.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChevronRightSmallIcon = () =>
  import("../assets/img/icons/chevron-right-small.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChevronDownSmallIcon = () =>
  import("../assets/img/icons/chevron-down-small.svg").then(({ ReactComponent }) => <ReactComponent />);
const ImageUploadBigIcon = () =>
  import("../assets/img/icons/image-upload-big.svg").then(({ ReactComponent }) => <ReactComponent />);
const OracleIcon = () => import("../assets/img/icons/oracle.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChainlinkIcon = () =>
  import("../assets/img/icons/chainlink.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChatDotsIcon = () => import("../assets/img/icons/chat-dots.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChatIcon = () => import("../assets/img/icons/chat.svg").then(({ ReactComponent }) => <ReactComponent />);
const CloseSmallIcon = () =>
  import("../assets/img/icons/close-small.svg").then(({ ReactComponent }) => <ReactComponent />);
const FileCodeIcon = () => import("../assets/img/icons/file-code.svg").then(({ ReactComponent }) => <ReactComponent />);
const FileListIcon = () => import("../assets/img/icons/file-list.svg").then(({ ReactComponent }) => <ReactComponent />);
const ExternalLinkIcon = () =>
  import("../assets/img/icons/external-link.svg").then(({ ReactComponent }) => <ReactComponent />);
const CopyIcon = () => import("../assets/img/icons/copy.svg").then(({ ReactComponent }) => <ReactComponent />);
const UsersWhiteIcon = () =>
  import("../assets/img/icons/users-white.svg").then(({ ReactComponent }) => <ReactComponent />);
const CheckIcon = () => import("../assets/img/icons/check.svg").then(({ ReactComponent }) => <ReactComponent />);
const BurgerMenuIcon = () =>
  import("../assets/img/icons/burger-menu.svg").then(({ ReactComponent }) => <ReactComponent />);
const InfoIcon = () => import("../assets/img/icons/info.svg").then(({ ReactComponent }) => <ReactComponent />);
const PlusIcon = () => import("../assets/img/icons/plus.svg").then(({ ReactComponent }) => <ReactComponent />);
const MinusIcon = () => import("../assets/img/icons/minus.svg").then(({ ReactComponent }) => <ReactComponent />);
const PlusCircleIcon = () =>
  import("../assets/img/icons/plus-circle.svg").then(({ ReactComponent }) => <ReactComponent />);
const GlobeIcon = () => import("../assets/img/icons/globe.svg").then(({ ReactComponent }) => <ReactComponent />);
const ClockIcon = () => import("../assets/img/icons/clock.svg").then(({ ReactComponent }) => <ReactComponent />);
const LightBulbIcon = () =>
  import("../assets/img/icons/light-bulb.svg").then(({ ReactComponent }) => <ReactComponent />);
const TelegramIcon = () => import("../assets/img/icons/telegram.svg").then(({ ReactComponent }) => <ReactComponent />);
const TelegramColorIcon = () =>
  import("../assets/img/icons/telegram-color.svg").then(({ ReactComponent }) => <ReactComponent />);
const RedditIcon = () => import("../assets/img/icons/reddit.svg").then(({ ReactComponent }) => <ReactComponent />);
const ListIcon = () => import("../assets/img/icons/list.svg").then(({ ReactComponent }) => <ReactComponent />);
const ManageSearchIcon = () =>
  import("../assets/img/icons/manage-search.svg").then(({ ReactComponent }) => <ReactComponent />);
const RocketIcon = () => import("../assets/img/icons/rocket.svg").then(({ ReactComponent }) => <ReactComponent />);
const IdeaIcon = () => import("../assets/img/icons/idea.svg").then(({ ReactComponent }) => <ReactComponent />);
const FolderCheckIcon = () =>
  import("../assets/img/icons/folder-check.svg").then(({ ReactComponent }) => <ReactComponent />);
const PlanetIcon = () => import("../assets/img/icons/planet.svg").then(({ ReactComponent }) => <ReactComponent />);
const CircularIcon = () => import("../assets/img/icons/circular.svg").then(({ ReactComponent }) => <ReactComponent />);
const RoadSignIcon = () => import("../assets/img/icons/road-sign.svg").then(({ ReactComponent }) => <ReactComponent />);
const MoreIcon = () => import("../assets/img/icons/more.svg").then(({ ReactComponent }) => <ReactComponent />);
const ForkIcon = () => import("../assets/img/icons/fork.svg").then(({ ReactComponent }) => <ReactComponent />);
const PencilIcon = () => import("../assets/img/icons/pencil.svg").then(({ ReactComponent }) => <ReactComponent />);
const UserPlusIcon = () => import("../assets/img/icons/user_plus.svg").then(({ ReactComponent }) => <ReactComponent />);
const UserCloseIcon = () =>
  import("../assets/img/icons/user_close.svg").then(({ ReactComponent }) => <ReactComponent />);
const EtherIcon = () => import("../assets/img/icons/ether.svg").then(({ ReactComponent }) => <ReactComponent />);
const DepositIcon = () => import("../assets/img/icons/deposit.svg").then(({ ReactComponent }) => <ReactComponent />);
const BorrowIcon = () => import("../assets/img/icons/borrow.svg").then(({ ReactComponent }) => <ReactComponent />);
const RepayIcon = () => import("../assets/img/icons/repay.svg").then(({ ReactComponent }) => <ReactComponent />);
const ReedemPayIcon = () =>
  import("../assets/img/icons/reedem-pay.svg").then(({ ReactComponent }) => <ReactComponent />);
const OutlineMail = () =>
  import("../assets/img/icons/outline-mail.svg").then(({ ReactComponent }) => <ReactComponent />);
const ExponentialGraphIcon = () =>
  import("../assets/img/icons/exponential-graph.svg").then(({ ReactComponent }) => <ReactComponent />);
const HeartIcon = () => import("../assets/img/icons/heart-icon.svg").then(({ ReactComponent }) => <ReactComponent />);
const LockIcon = () => import("../assets/img/icons/lock-icon.svg").then(({ ReactComponent }) => <ReactComponent />);
const CoinsStackIcon = () =>
  import("../assets/img/icons/coins-stack-icon.svg").then(({ ReactComponent }) => <ReactComponent />);
const LocationWarningIcon = () =>
  import("../assets/img/icons/location-warning-icon.svg").then(({ ReactComponent }) => <ReactComponent />);
const WarningIcon = () => import("../assets/img/warning.svg").then(({ ReactComponent }) => <ReactComponent />);
const WalletIcon = () => import("../assets/img/icons/wallet-icon.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChartMinimumIcon = () =>
  import("../assets/img/icons/chart-minimum.svg").then(({ ReactComponent }) => <ReactComponent />);
const ChartMaximumIcon = () =>
  import("../assets/img/icons/chart-maximum.svg").then(({ ReactComponent }) => <ReactComponent />);
const LineUpIcon = () => import("../assets/img/icons/line-up.svg").then(({ ReactComponent }) => <ReactComponent />);
const LineUpSmoothIcon = () =>
  import("../assets/img/icons/line-up-smooth.svg").then(({ ReactComponent }) => <ReactComponent />);
const LineDownIcon = () => import("../assets/img/icons/line-down.svg").then(({ ReactComponent }) => <ReactComponent />);
const LineDownSmoothIcon = () =>
  import("../assets/img/icons/line-down-smooth.svg").then(({ ReactComponent }) => <ReactComponent />);
const DollarInIcon = () => import("../assets/img/icons/dollar-in.svg").then(({ ReactComponent }) => <ReactComponent />);
const DollarOutIcon = () =>
  import("../assets/img/icons/dollar-out.svg").then(({ ReactComponent }) => <ReactComponent />);
const PieChartIcon = () => import("../assets/img/icons/pie-chart.svg").then(({ ReactComponent }) => <ReactComponent />);
const WaterfallAvgIcon = () =>
  import("../assets/img/icons/waterfall-average.svg").then(({ ReactComponent }) => <ReactComponent />);
const WaterfallUpIcon = () =>
  import("../assets/img/icons/waterfall-up.svg").then(({ ReactComponent }) => <ReactComponent />);
const WaterfallDownIcon = () =>
  import("../assets/img/icons/waterfall-down.svg").then(({ ReactComponent }) => <ReactComponent />);
const DollarIcon = () => import("../assets/img/icons/dollar.svg").then(({ ReactComponent }) => <ReactComponent />);
const CoinInsertIcon = () =>
  import("../assets/img/icons/coin-insert.svg").then(({ ReactComponent }) => <ReactComponent />);
const BankIcon = () => import("../assets/img/icons/bank.svg").then(({ ReactComponent }) => <ReactComponent />);
const DefaultIcon = () => import("../assets/img/crypto/default.svg").then(({ ReactComponent }) => <ReactComponent />);
const QuestionMarkCircleIcon = () =>
  import("../assets/img/icons/question-mark-circle.svg").then(({ ReactComponent }) => <ReactComponent />);
const ReloadIcon = () => import("../assets/img/icons/reload.svg").then(({ ReactComponent }) => <ReactComponent />);
const RouteIcon = () => import("../assets/img/icons/route.svg").then(({ ReactComponent }) => <ReactComponent />);
const CalcIcon = () => import("../assets/img/icons/calc.svg").then(({ ReactComponent }) => <ReactComponent />);
const TokenIcon = () => import("../assets/img/icons/token.svg").then(({ ReactComponent }) => <ReactComponent />);
const ExportIcon = () => import("../assets/img/icons/export.svg").then(({ ReactComponent }) => <ReactComponent />);
const BronzeLeagueIcon = () =>
  import("../assets/img/icons/bronze-league.svg").then(({ ReactComponent }) => <ReactComponent />);
const SilverLeagueIcon = () =>
  import("../assets/img/icons/silver-league.svg").then(({ ReactComponent }) => <ReactComponent />);
const GoldLeagueIcon = () =>
  import("../assets/img/icons/gold-league.svg").then(({ ReactComponent }) => <ReactComponent />);
const PlatinumLeagueIcon = () =>
  import("../assets/img/icons/platinum-league.svg").then(({ ReactComponent }) => <ReactComponent />);
const LinkedinIcon = () =>
  import("../assets/img/icons/linkedin-icon.svg").then(({ ReactComponent }) => <ReactComponent />);
const BackwardIcon = () => import("../assets/img/icons/backward.svg").then(({ ReactComponent }) => <ReactComponent />);
const DeviationIcon = () =>
  import("../assets/img/icons/deviation.svg").then(({ ReactComponent }) => <ReactComponent />);
const GrangerCausalityIcon = () =>
  import("../assets/img/icons/granger-causality.svg").then(({ ReactComponent }) => <ReactComponent />);
const LatencyIcon = () => import("../assets/img/icons/latency.svg").then(({ ReactComponent }) => <ReactComponent />);
const ExchangeIcon = () => import("../assets/img/icons/exchange.svg").then(({ ReactComponent }) => <ReactComponent />);
const CollabIcon = () => import("../assets/img/icons/stack.svg").then(({ ReactComponent }) => <ReactComponent />);
const StopIcon = () => import("../assets/img/icons/stop.svg").then(({ ReactComponent }) => <ReactComponent />);
const PieHalfIcon = () => import("../assets/img/icons/pie-half.svg").then(({ ReactComponent }) => <ReactComponent />);
const CandlesticksDownIcon = () =>
  import("../assets/img/icons/candlesticks-down.svg").then(({ ReactComponent }) => <ReactComponent />);
const CandlesticksUpIcon = () =>
  import("../assets/img/icons/candlesticks-up.svg").then(({ ReactComponent }) => <ReactComponent />);
const ColumnDownIcon = () =>
  import("../assets/img/icons/column-down.svg").then(({ ReactComponent }) => <ReactComponent />);
const PieThreeIcon = () => import("../assets/img/icons/pie-three.svg").then(({ ReactComponent }) => <ReactComponent />);
const UserAtRiskIcon = () =>
  import("../assets/img/icons/user-risk.svg").then(({ ReactComponent }) => <ReactComponent />);
const FolderLockedIcon = () =>
  import("../assets/img/icons/folder-locked.svg").then(({ ReactComponent }) => <ReactComponent />);
const StackedColumn = () =>
  import("../assets/img/icons/stacked-column.svg").then(({ ReactComponent }) => <ReactComponent />);
const LineDownDotsIcon = () =>
  import("../assets/img/icons/line-down-dots.svg").then(({ ReactComponent }) => <ReactComponent />);
const ShoppingListIcon = () =>
  import("../assets/img/icons/shopping-list.svg").then(({ ReactComponent }) => <ReactComponent />);
const SlackIcon = () => import("../assets/img/icons/slack.svg").then(({ ReactComponent }) => <ReactComponent />);
const DiscordIcon = () => import("../assets/img/icons/discord.svg").then(({ ReactComponent }) => <ReactComponent />);
const JiraIcon = () => import("../assets/img/icons/jira.svg").then(({ ReactComponent }) => <ReactComponent />);
const AwsSnsIcon = () => import("../assets/img/icons/aws-sns.svg").then(({ ReactComponent }) => <ReactComponent />);
const WebhookIcon = () => import("../assets/img/icons/webhook.svg").then(({ ReactComponent }) => <ReactComponent />);
const WebhookColoredIcon = () =>
  import("../assets/img/icons/webhook-colored.svg").then(({ ReactComponent }) => <ReactComponent />);

const getIcon = async (iconName: string) => {
  const loweCaseIconName = iconName.toLowerCase();
  switch (loweCaseIconName) {
    case "linkedin":
      return LinkedinIcon();
    case "outline-mail":
      return OutlineMail();
    case "sort-none":
      return SortNoneIcon();
    case "sort-down":
      return SortDownIcon();
    case "sort-up":
      return SortUpIcon();
    case "lab":
      return LabIcon();
    case "briefcase":
      return BriefCaseIcon();
    case "chaos":
      return ChaosIcon();
    case "file-video":
      return FiledVideo();
    case "filter":
      return FilterIcon();
    case "play":
      return PlayIcon();
    case "eraser":
      return EraserIcon();
    case "folder-upload":
      return FolderUpload();
    case "airplay":
      return AirplayIcon();
    case "airplay-gradient-shadow":
      return AirplayGradientShadowIcon();
    case "album-library":
      return AlbumLibraryIcon();
    case "gift":
      return GiftIcon();
    case "grid":
      return GridIcon();
    case "history":
      return HistoryIcon();
    case "keyboard":
      return KeyboardIcon();
    case "palette":
      return PaletteIcon();
    case "perspective":
      return PerspectiveIcon();
    case "vector":
      return VectorIcon();
    case "menu-toggle":
      return MenuToggleIcon();
    case "pie-slice":
      return PieSliceIcon();
    case "check-square":
      return CheckSquareIcon();
    case "checkbox-off":
      return CheckboxOffIcon();
    case "github":
      return GithubIcon();
    case "github-alt":
      return GithubAltIcon();
    case "google":
      return GoogleIcon();
    case "twitter":
      return TwitterIcon();
    case "twitter-filled":
      return TwitterFilledIcon();
    case "outline-twitter":
      return OutlineTwitter();
    case "eye":
      return EyeIcon();
    case "eye-off":
      return EyeOffIcon();
    case "bullet-blue":
      return BulletBlueIcon();
    case "grid-four":
      return GridFourIcon();
    case "grid-two-h":
      return GridTwoHorizontal();
    case "chevron-down":
      return ChevronDownIcon();
    case "chevron-down-small":
      return ChevronDownSmallIcon();
    case "chevron-down-filled":
      return ChevronDownFilledIcon();
    case "chevron-left":
      return ChevronLeftIcon();
    case "chevron-right":
      return ChevronRightIcon();
    case "chevron-right-small":
      return ChevronRightSmallIcon();
    case "remove":
      return RemoveIcon();
    case "close":
      return CloseIcon();
    case "search":
      return SearchIcon();
    case "wi-fi":
      return WifiIcon();
    case "bell":
      return BellIcon();
    case "settings":
      return SettingsIcon();
    case "users":
      return UsersIcon();
    case "sign-out":
      return SignOutIcon();
    case "close-circle":
      return CloseCircleIcon();
    case "check-circle":
      return CheckCircleIcon();
    case "stacked-area-up":
      return StackedAreaUpIcon();
    case "list-boxes":
      return ListBoxesIcon();
    case "exclamation-square":
      return ExclamationSquareIcon();
    case "exclamation-mark":
      return ExclamationMarkIcon();
    case "arrow-left":
      return ArrowLeftIcon();
    case "arrow-right":
      return ArrowRightIcon();
    case "breadcrumbs-arrow":
      return BreadcrumbsArrowIcon();
    case "cloud-download":
      return CloudDownloadIcon();
    case "printer":
      return PrinterIcon();
    case "settings-adjust":
      return SettingsAdjustIcon();
    case "grid-two-v":
      return GridTwoVerticalIcon();
    case "checkbox-indeterminate":
      return CheckboxIndeterminateIcon();
    case "fullscreen":
      return FullscreenIcon();
    case "exit-fullscreen":
      return ExitFullscreenIcon();
    case "coin-dollar":
      return CoinDollarIcon();
    case "coin-insert":
      return CoinInsertIcon();
    case "bank":
      return BankIcon();
    case "star":
      return StarIcon();
    case "file-check-in":
      return FileCheckInIcon();
    case "import":
      return ImportIcon();
    case "user":
      return UserIcon();
    case "scale":
      return ScaleIcon();
    case "coins":
      return CoinsIcon();
    case "star-filled":
      return StarFilledIcon();
    case "trash-can":
      return TrashCanIcon();
    case "transaction":
      return TransactionIcon();
    case "image-upload":
      return ImageUploadIcon();
    case "info":
      return InfoIcon();
    case "edit":
      return EditIcon();
    case "image-upload-big":
      return ImageUploadBigIcon();
    case "oracle":
      return OracleIcon();
    case "chat-dots":
      return ChatDotsIcon();
    case "chat":
      return ChatIcon();
    case "chainlink":
      return ChainlinkIcon();
    case "close-small":
      return CloseSmallIcon();
    case "bubble-race":
      return BubbleRaceIcon();
    case "file-code":
      return FileCodeIcon();
    case "file-list":
      return FileListIcon();
    case "external-link":
      return ExternalLinkIcon();
    case "copy":
      return CopyIcon();
    case "users-white":
      return UsersWhiteIcon();
    case "check":
      return CheckIcon();
    case "burger-menu":
      return BurgerMenuIcon();
    case "plus":
      return PlusIcon();
    case "minus":
      return MinusIcon();
    case "plus-circle":
      return PlusCircleIcon();
    case "view-list":
      return ViewListIcon();
    case "web":
      return GlobeIcon();
    case "clock":
      return ClockIcon();
    case "pie-gradient-shadow":
      return PieGradientShadowIcon();
    case "triangle-gradient-shadow":
      return TriangleGradientShadowIcon();
    case "light-bulb":
      return LightBulbIcon();
    case "telegram":
      return TelegramIcon();
    case "telegram-color":
      return TelegramColorIcon();
    case "reddit":
      return RedditIcon();
    case "list":
      return ListIcon();
    case "manage-search":
      return ManageSearchIcon();
    case "calendar":
      return CalendarIcon();
    case "rocket":
      return RocketIcon();
    case "idea":
      return IdeaIcon();
    case "folder-check":
      return FolderCheckIcon();
    case "planet":
      return PlanetIcon();
    case "circular":
      return CircularIcon();
    case "road-sign":
      return RoadSignIcon();
    case "more":
      return MoreIcon();
    case "fork":
      return ForkIcon();
    case "user_plus":
      return UserPlusIcon();
    case "user_close":
      return UserCloseIcon();
    case "pencil":
      return PencilIcon();
    case "ether":
      return EtherIcon();
    case "deposit-icon":
      return DepositIcon();
    case "borrow-icon":
      return BorrowIcon();
    case "repay-icon":
      return RepayIcon();
    case "redeem-icon":
      return ReedemPayIcon();
    case "exponential-graph":
      return ExponentialGraphIcon();
    case "heart-icon":
      return HeartIcon();
    case "hathor":
      return HathorIcon();
    case "lock-icon":
      return LockIcon();
    case "coins-stack-icon":
      return CoinsStackIcon();
    case "location-warning-icon":
      return LocationWarningIcon();
    case "wallet-icon":
      return WalletIcon();
    case "warning":
      return WarningIcon();
    case "card-eject":
      return CardEjectIcon();
    case "card-lock":
      return CardLockIcon();
    case "chart-minimum":
      return ChartMinimumIcon();
    case "chart-maximum":
      return ChartMaximumIcon();
    case "line-up":
      return LineUpIcon();
    case "line-up-smooth":
      return LineUpSmoothIcon();
    case "line-down":
      return LineDownIcon();
    case "line-down-smooth":
      return LineDownSmoothIcon();
    case "dollar-in":
      return DollarInIcon();
    case "dollar-out":
      return DollarOutIcon();
    case "pie-chart":
      return PieChartIcon();
    case "waterfall-average":
      return WaterfallAvgIcon();
    case "waterfall-up":
      return WaterfallUpIcon();
    case "waterfall-down":
      return WaterfallDownIcon();
    case "dollar":
      return DollarIcon();
    case "question-mark":
      return QuestionMarkCircleIcon();
    case "reload":
      return ReloadIcon();
    case "route":
      return RouteIcon();
    case "calc":
      return CalcIcon();
    case "token":
      return TokenIcon();
    case "export":
      return ExportIcon();
    case "bronze-league":
      return BronzeLeagueIcon();
    case "silver-league":
      return SilverLeagueIcon();
    case "gold-league":
      return GoldLeagueIcon();
    case "platinum-league":
      return PlatinumLeagueIcon();
    case "backward":
      return BackwardIcon();
    case "deviation":
      return DeviationIcon();
    case "granger-causality":
      return GrangerCausalityIcon();
    case "latency":
      return LatencyIcon();
    case "exchange":
      return ExchangeIcon();
    case "collab":
      return CollabIcon();
    case "stop":
      return StopIcon();
    case "pie-half":
      return PieHalfIcon();
    case "candlesticks-down":
      return CandlesticksDownIcon();
    case "candlesticks-up":
      return CandlesticksUpIcon();
    case "column-down":
      return ColumnDownIcon();
    case "pie-three":
      return PieThreeIcon();
    case "user-risk":
      return UserAtRiskIcon();
    case "folder-locked":
      return FolderLockedIcon();
    case "stacked-column":
      return StackedColumn();
    case "line-down-dots":
      return LineDownDotsIcon();
    case "shopping-list":
      return ShoppingListIcon();
    case "slack":
      return SlackIcon();
    case "discord":
      return DiscordIcon();
    case "jira":
      return JiraIcon();
    case "aws-sns":
      return AwsSnsIcon();
    case "webhooks":
      return WebhookColoredIcon();
    case "webhook":
      return WebhookIcon();
    case "x-logo":
      return XLogoIcon();
    default:
      return DefaultIcon();
  }
};

enum IconSize {
  "x-small" = 16,
  "small" = 20,
  "default" = 24,
}

export interface CustomIconProps {
  icon: string;
  className?: string;
  sx?: SxProps;
  size?: "x-small" | "small" | "default";
}

export const CustomIcon = React.forwardRef<unknown, CustomIconProps>(
  ({ icon, className, sx, size = "default", ...props }, ref) => {
    const [svgElement, setSvgElement] = useState<JSX.Element>();
    const sizeValue = size && IconSize[size];

    useEffect(() => {
      let isMounted = true;
      void getIcon(icon).then((svg) => isMounted && setSvgElement(svg));
      return () => {
        isMounted = false;
      };
    }, [icon]);

    return (
      <Box
        {...props}
        component="span"
        ref={ref}
        className={`${icon}-icon ${className || ""}`}
        sx={{
          verticalAlign: "middle",
          alignItems: "center",
          svg: {
            ...(sizeValue && { width: sizeValue, height: sizeValue }),
            display: "block",
          },
          ...sx,
        }}
      >
        {svgElement}
      </Box>
    );
  },
);

CustomIcon.defaultProps = {
  className: undefined,
  sx: undefined,
  size: undefined,
};
