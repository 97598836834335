import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { useClientConfig } from "../../../clients-config";

type LiquidationsCardsProps = {
  liquidatedPositions?: number;
  liquidatedValue?: number;
  pnl?: {
    current: number;
    simulated: number;
    net: number;
  };
};

export const RiskExplorerCards = ({ liquidatedPositions, liquidatedValue, pnl }: LiquidationsCardsProps) => {
  const { hasSharedCollateral } = useClientConfig();
  const cards: ValueCardProps[] = [
    {
      title: "Liquidated Positions",
      value: liquidatedPositions,
    },
    ...(!hasSharedCollateral
      ? [
          {
            title: "Liquidated Value",
            value: liquidatedValue,
            currency: "USD",
          },
        ]
      : []),
    {
      title: "Open PnL",
      value: pnl ? pnl.net - pnl.current + pnl.simulated : undefined,
      currency: "USD",
      delta: pnl && pnl.simulated !== pnl.current ? pnl.simulated - pnl.current : undefined,
      deltaTrend: pnl && pnl.simulated > pnl.current ? 1 : -1,
      deltaColor: pnl && pnl.simulated > pnl.current ? 1 : -1,
      deltaTooltip: "Diff from current PnL",
      tooltip: "PnL of open positions in the simulated scenario",
    },
  ];

  return (
    <Grid container>
      {cards.map((card) => (
        <Grid key={card.title} item xs={12} md={hasSharedCollateral ? 6 : 4}>
          <ValueCard key={card.title} {...card} />
        </Grid>
      ))}
    </Grid>
  );
};
