import { CompositionOverTimeChart, Box } from "@frontend/ui";
import { useStablecoinDataContext } from "../../provider";

export const CollateralCompositionChart = () => {
  const { isLoading, history, tokenSymbol } = useStablecoinDataContext();

  const series =
    history && history[history.length - 1]
      ? history[history.length - 1].collateralDistribution.map(({ asset }) => ({
          label: asset,
          data: history.map(
            (h) =>
              [
                h.date * 1000,
                h.collateralDistribution.find((d) => d.asset.toLowerCase() === asset.toLowerCase())?.value || 0,
              ] as [number, number],
          ),
        }))
      : [];

  return (
    <Box data-testid="collateral-by-asset-chart">
      <CompositionOverTimeChart
        isLoading={isLoading}
        title="Collateral Breakdown by Asset"
        description={`Per asset breakdown of collateral across all ${tokenSymbol} borrow positions.`}
        currency="USD"
        series={series}
      />
    </Box>
  );
};
