import { useParams } from "react-router-dom";
import { Box, Grid, PieChart, ValueOverTimeChart } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { PageHeader } from "@frontend/ui/page-header";
import { useClientConfig } from "../../clients-config";
import { useReservesData } from "./use-reserves-data";
import { Client } from "../../types";

const ReservesInner = () => {
  const { clientName } = useParams<{ clientName: Client }>();
  const { reserves } = useClientConfig();
  const { isLoading, chartsStats } = useReservesData(clientName!);
  return (
    <Box height="100%">
      {isLoading ? (
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
          <Loader />
        </Box>
      ) : (
        <Box>
          <PageHeader showBackButton={false} pageTitle="Reserves" />
          {reserves?.charts.includes("reserveDistribution") && chartsStats?.reserveDistribution && (
            <PieChart
              title="Reserve Distribution by Asset (USD)"
              series={[
                {
                  data: chartsStats?.reserveDistribution.map((d) => [d.x, d.y]),
                },
              ]}
              showLegendCryptoIcon
            />
          )}
          {reserves?.charts.includes("reserveHistory") && chartsStats?.reserveHistory && (
            <Grid container>
              {Object.entries(chartsStats?.reserveHistory).map(([assetSymbol, seriesData]) => (
                <Grid key={assetSymbol} item xs={12} md={6}>
                  <ValueOverTimeChart
                    title={assetSymbol}
                    series={[
                      {
                        label: assetSymbol,
                        data: seriesData,
                        type: "line",
                      },
                    ]}
                    currency=""
                    yAxisMax
                    yAxisMin
                    showLegend={false}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
    </Box>
  );
};

const ReservesTab = () => <ReservesInner />;

export default ReservesTab;
