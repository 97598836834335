import { useState, useEffect } from "react";
import { Box } from "@frontend/ui/box";
import { Typography } from "@frontend/ui/typography";
import { DyDxPerptualMarkets } from "@frontend/types";
import { Tabs } from "@frontend/ui/tabs";
import { Loader } from "@frontend/ui/loader";

type OrderType = {
  size: string;
  price: string;
  offset: string;
};

type OrderBookType = {
  asks: Array<OrderType>;
  bids: Array<OrderType>;
};

const OrderHead = ({ currencyArray }: { currencyArray: Array<string> }) => (
  <Box position="sticky" top="0" zIndex={2} bgcolor="background.light">
    <Box p={1} display="flex" flexDirection="row">
      <Box width="50%" display="flex">
        <Typography variant="caption">{`Size ${currencyArray[0]}`}</Typography>
      </Box>
      <Box width="50%" display="flex">
        <Typography variant="caption">{`Price ${currencyArray[1]}`}</Typography>
      </Box>
    </Box>
  </Box>
);

const OrderRow = ({ item }: { item: OrderType }) => (
  <Box
    display="flex"
    flexDirection="row"
    position="relative"
    zIndex={1}
    p={1}
    sx={{ ":nth-of-type(even)": { bgcolor: "grey.main" }, ":hover": { bgcolor: "lightGrey.main" } }}
  >
    <Box width="50%" display="flex" position="relative" overflow="hidden" textOverflow="ellipsis">
      <Typography variant="body2">{Number(item.size).toFixed(4)}</Typography>
    </Box>
    <Box width="50%" display="flex" overflow="hidden" textOverflow="ellipsis">
      <Typography variant="body2">{item.price}</Typography>
    </Box>
  </Box>
);

export const OrderBook = ({ currentMarket }: { currentMarket: DyDxPerptualMarkets }) => {
  const [internalBids, setBids] = useState<OrderType[]>([]);
  const [internalAsks, setAsks] = useState<OrderType[]>([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const getOrdersBook = async () => {
      // eslint-disable-next-line
      const data: OrderBookType = await (await fetch(`https://api.dydx.exchange/v3/orderbook/${currentMarket}`)).json();

      if (data) {
        setBids(data.bids);
        setAsks(data.asks);
      }
    };

    void getOrdersBook();
    const interval = setInterval(() => {
      void getOrdersBook();
    }, 1000);

    return () => clearInterval(interval);
  }, [currentMarket]);

  return (
    <Box bgcolor="darkGrey.main" sx={{ borderRadius: 2, height: { xs: 500, lg: "100%" } }}>
      <Box display="flex" height="100%" overflow="auto" minWidth={230}>
        {internalBids.length ? (
          <Box display="flex" flexDirection="column" width="100%" alignItems="center">
            <Box my={2}>
              <Tabs
                value={tabIndex}
                tabs={[{ label: "Bids" }, { label: "Asks" }]}
                onChange={(_, i) => setTabIndex(i)}
              />
            </Box>
            <Box overflow="auto" width="100%">
              <OrderHead currencyArray={currentMarket.split("-")} />
              {(tabIndex === 0 ? internalBids : internalAsks).map((item) => (
                <OrderRow key={item.price + item.size} item={item} />
              ))}
            </Box>
          </Box>
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};
