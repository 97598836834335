import { Box, Typography } from "@frontend/ui";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";

export const LiquidationsNotification = () => {
  const { liquidations } = useClientConfig();

  return (
    <Box display="inline-flex" gap={[1, 2]}>
      {liquidations?.subtitle && (
        <Typography color="info" sx={{ display: "inline-flex" }}>
          {liquidations.subtitle}
        </Typography>
      )}
    </Box>
  );
};
