import { palette } from "@frontend/ui/theme/palette";
import {
  Box,
  CompositionOverTimeChart,
  CryptoIcon,
  CustomSwitch,
  Grid,
  Paper,
  Typography,
  ValueCard,
  ValueOverTimeChart,
} from "@frontend/ui";
import { useState } from "react";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { usePoolsOverivewQuery } from "../../generated";
import { IncentiveProvidersTabs } from "../../incentive-providers-tabs";
import { CorrelationChart } from "../pools/components";

const PoolsOverviewPage = () => {
  const { loading, data } = usePoolsOverivewQuery();
  const [isValuesInUSD, setIsValuesInUSD] = useState(true);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" alignItems="center">
        <Typography variant="h2" flex="1">
          Overview
        </Typography>
        <IncentiveProvidersTabs />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ValueCard title="Incentivized Liquidity" value={data?.poolsOverview.totalLiquidity} currency="usd" />
        </Grid>
        <Grid item xs={4}>
          <ValueCard title="Daily Incentives" value={data?.poolsOverview.totalIncentives} currency="usd" />
        </Grid>
        <Grid item xs={4}>
          <ValueCard title="Volume 24H" value={data?.poolsOverview.volume24h} currency="usd" />
        </Grid>
      </Grid>
      <Paper variant="card">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper variant="widget">
              <CorrelationChart
                loading={!data}
                incentives={data?.poolsOverview.incentivesUSDHistory || []}
                liquidity={data?.poolsOverview.liquidityHistory || []}
                volume={data?.poolsOverview.volumeHistory || []}
                isElasticity
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="widget">
              <CorrelationChart
                loading={!data}
                incentives={data?.poolsOverview.incentivesUSDHistory || []}
                liquidity={data?.poolsOverview.liquidityHistory || []}
                volume={data?.poolsOverview.volumeHistory || []}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="widget">
              <CompositionOverTimeChart
                title="Liquidity/TVL (USD)"
                series={[
                  {
                    label: "Liquidity",
                    data: data?.poolsOverview.liquidityHistory.map((d) => [d.time, d.value]) || [],
                    color: palette.blue.main,
                  },
                ]}
                showLegend={false}
                isLoading={loading}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper variant="widget">
              <ValueOverTimeChart
                title="Volume (USD)"
                series={[
                  {
                    label: "Volume",
                    type: "bar",
                    data: data?.poolsOverview.volumeHistory.map((d) => [d.time, d.value]) || [],
                    color: palette.blue.main,
                  },
                ]}
                showLegend={false}
                isLoading={loading}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper variant="widget">
              <ValueOverTimeChart
                title="Incentives"
                series={[
                  {
                    label: "Incentives",
                    type: "bar",
                    data:
                      (isValuesInUSD
                        ? data?.poolsOverview.incentivesUSDHistory
                        : data?.poolsOverview.incentivesHistory
                      )?.map((d) => [d.time, d.value]) || [],
                    color: palette.blue.main,
                  },
                ]}
                currency={isValuesInUSD ? "USD" : ""}
                showLegend={false}
                isLoading={loading}
                headerSuffix={
                  <CustomSwitch
                    onChange={() => setIsValuesInUSD((prev) => !prev)}
                    checked={isValuesInUSD}
                    checkedIcon="dollar"
                    uncheckedIcon={<CryptoIcon icon={wrappedSymbolToIconSymbol("OSMO")} />}
                    labelsVariant="h4"
                  />
                }
              />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default PoolsOverviewPage;
