import { FC } from "react";
import { CommunityDashboardLayout } from "src/components/layouts";
import { useParams } from "react-router-dom";
import { RouteParams } from "src/config/route-params";
import { RoutePath } from "src/config/routes";
import { useClientConfig } from "./clients-config";
import { useFeatureFlag } from "../../utils/feature-flags";

export const CCARPerpsTabsLayout: FC = ({ children }) => {
  const { accounts, overview, markets, alerts, riskExplorer, leaderboard, jlpPool } = useClientConfig();
  const { clientName } = useParams();
  const replaceParams = (path: string) => path.replace(RouteParams.ClientName, clientName!);
  const accountTabEnabled = useFeatureFlag("accounts-tab") && accounts;

  return (
    <CommunityDashboardLayout
      showClientToolsDropdown={false}
      tabs={[
        ...(overview
          ? [
              {
                label: "Overview",
                isActive: (path: string) =>
                  path.includes(replaceParams(RoutePath.CCARPerpetuals.Overview)) ||
                  path.includes(replaceParams(RoutePath.Risk.Overview)),
                path: replaceParams(RoutePath.Risk.Overview),
              },
            ]
          : []),
        ...(markets
          ? [
              {
                label: "Markets",
                path: replaceParams(RoutePath.Risk.Markets),
              },
            ]
          : []),
        ...(accountTabEnabled
          ? [
              {
                label: "Accounts",
                path: replaceParams(RoutePath.Risk.Accounts),
              },
            ]
          : []),
        {
          label: "Positions",
          path: replaceParams(RoutePath.Risk.Positions),
          isActive: (path: string) =>
            (!accountTabEnabled ? path.includes(replaceParams(RoutePath.Risk.Accounts)) : false) ||
            path.includes(replaceParams(RoutePath.Risk.Positions)),
        },
        {
          label: "Liquidations",
          path: replaceParams(RoutePath.Risk.Liquidations),
        },
        ...(riskExplorer?.enabled
          ? [
              {
                label: "Risk Explorer",
                path: replaceParams(RoutePath.Risk.RiskExplorer),
              },
            ]
          : []),
        ...(leaderboard?.enabled
          ? [
              {
                label: "Leaderboard",
                path: replaceParams(RoutePath.Risk.Leaderboard),
              },
            ]
          : []),
        ...(alerts?.enabled
          ? [
              {
                label: "Alerts",
                path: replaceParams(RoutePath.Risk.Alerts),
              },
            ]
          : []),
        ...(jlpPool?.enabled
          ? [
              {
                label: "JLP Pool",
                path: replaceParams(RoutePath.Risk.JlpPool),
              },
            ]
          : []),
      ]}
    >
      {children}
    </CommunityDashboardLayout>
  );
};
