import { Box, CryptoIcon } from "@frontend/ui";
import { PoolTokenWrapper } from "../pool-token-icon";

export type PoolTokensPairProps = {
  token1: string;
  token2: string;
  size?: number;
};

const PoolTokensPair = ({ token1, token2, size = 40 }: PoolTokensPairProps) => (
  <Box display="flex" gap={-1}>
    <PoolTokenWrapper sx={{ zIndex: 2 }} size={size}>
      <CryptoIcon icon={token1.toLowerCase()} sx={{ img: { width: size - 5, height: size - 5 } }} />
    </PoolTokenWrapper>
    <PoolTokenWrapper sx={{ marginLeft: -1, zIndex: 1 }} size={size}>
      <CryptoIcon icon={token2.toLowerCase()} sx={{ img: { width: size - 5, height: size - 5 } }} />
    </PoolTokenWrapper>
  </Box>
);

export default PoolTokensPair;
