import { Navigate, Route, Routes } from "react-router-dom";
import { RoutePath } from "src/config/routes";
import { PageTracker } from "src/components/page-tracker";
import { ChaosApolloProvider } from "src/utils/graphql";
import { FeatureFlagsProvider } from "src/utils/feature-flags";
import { useAuth } from "@frontend/ui/auth";
import { AlertsPortalRoutes } from "../../../config/alerts-portal-routes";
import { NotPermittedError } from "../../../components/not-permitted-error";
import { CHAOS_LABS_MEMBER_ROLE } from "../../../utils/consts";
import { AlertsTab } from "../tabs/alerts/alerts";
import { WebhooksTab } from "../tabs/webhooks/webhooks";
import { SettingsTab } from "../tabs/settings/settings";
import { SiderPageLayout } from "../../../components/layouts/sider-page-layout";
import { SettingsDetails } from "../tabs/settings/settings-details";
import { AlertSettingsProvider } from "../tabs/settings/use-settings-table-data";

const replaceHomePath = (path: string) => path.replace(RoutePath.AlertsPortal.Home, "");

const tabs = [
  {
    label: "Alerts",
    value: "alerts",
    path: AlertsPortalRoutes.DefaultPage,
    icon: "bell",
  },
  {
    label: "Webhooks",
    value: "webhooks",
    path: AlertsPortalRoutes.Webhooks,
    icon: "webhook",
  },
  {
    label: "Alert Settings",
    value: "settings",
    path: AlertsPortalRoutes.Settings,
    icon: "settings",
  },
];

export const CCARAlertsPortalRoutes = PageTracker(() => {
  const auth = useAuth();

  if (!auth.hasRole(CHAOS_LABS_MEMBER_ROLE)) {
    return <NotPermittedError />;
  }
  return (
    <ChaosApolloProvider product="alerts" dontIncludeProtocolHeader>
      <FeatureFlagsProvider>
        <AlertSettingsProvider>
          <Routes>
            <Route index element={<Navigate to={AlertsPortalRoutes.DefaultPage} replace />} />
            <Route
              path={replaceHomePath(RoutePath.AlertsPortal.Alerts)}
              element={
                <SiderPageLayout homePath={RoutePath.AlertsPortal.Alerts} tabs={tabs}>
                  <AlertsTab />
                </SiderPageLayout>
              }
            />
            <Route
              path={replaceHomePath(RoutePath.AlertsPortal.Webhooks)}
              element={
                <SiderPageLayout homePath={RoutePath.AlertsPortal.Alerts} tabs={tabs}>
                  <WebhooksTab />
                </SiderPageLayout>
              }
            />
            <Route
              path={replaceHomePath(RoutePath.AlertsPortal.Settings)}
              element={
                <SiderPageLayout homePath={RoutePath.AlertsPortal.Alerts} tabs={tabs}>
                  <SettingsTab />
                </SiderPageLayout>
              }
            />

            <Route
              path={replaceHomePath(RoutePath.AlertsPortal.SettingsDetails)}
              element={
                <SiderPageLayout homePath={RoutePath.AlertsPortal.Alerts} tabs={tabs}>
                  <SettingsDetails />
                </SiderPageLayout>
              }
            />
            <Route path="*" element={<Navigate to={AlertsPortalRoutes.DefaultPage} replace />} />
          </Routes>
        </AlertSettingsProvider>
      </FeatureFlagsProvider>
    </ChaosApolloProvider>
  );
});
