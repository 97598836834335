import { Filter, makePickerFilter } from "@frontend/ui";
import { SortAccountsBy } from "src/pages/ccar-perpetuals-page/generated";

type AccountFilterProps = {
  assets: { name: string; token: string }[];
};

export const getAccountsFilters = ({ assets }: AccountFilterProps): Filter[] => [
  makePickerFilter({
    title: "Asset",
    fieldName: SortAccountsBy.TradedAssets,
    options: assets.map((asset) => ({
      name: asset.name,
      cryptoIcon: asset.token.toLowerCase(),
      value: asset.token,
    })),
  }),
];

export const getOptionsFilterValue = (filters: Filter[], key: SortAccountsBy) => {
  const filter = filters.find((f) => f.fieldName === key);

  if (filter) {
    const values: string[] = [];
    if (filter.type === "options") {
      filter.options.forEach((option) => {
        if (!option.isHidden && option.value) {
          values.push(option.value);
        }
      });
    }
    return values;
  }

  return null;
};
