import { TwapPoolFragment } from "src/pages/uniswap/generated";
import { NotificationBanner } from "@frontend/ui";
import { ManipulationCostCard } from "./components/manipulation-cost-card";

type Props = {
  pool: TwapPoolFragment;
};

const ManipulationCostCharts = ({ pool }: Props) => (
  <>
    <ManipulationCostCard title="Spot Price Manipulation - Capital Requirement" pool={pool} />
    <ManipulationCostCard
      title="Controlled Chain Manipulation"
      subtitle={(blocksControlled: number) =>
        `Capital required for manipulation, assumes control of ${blocksControlled} consecutive blocks`
      }
      extraContent={
        <NotificationBanner
          icon="light-bulb"
          message="All manipulation costs in this section assume significant block control over a 30-minute time window. The cost of validator control is hard to calculate precisely but is likely extremely high and can vary drastically. Attaining block control for an attack over a 30-minute time frame is difficult and will increase overall cost and capital requirements for attackers significantly."
        />
      }
      pool={pool}
      isControlledChain
    />
  </>
);

export default ManipulationCostCharts;
