import { Header, RenderData } from "@frontend/types";
import { ChaosTable, textCell, valueCell } from "@frontend/ui";
import { TraderStats } from "src/pages/ccar-perpetuals-page/generated";
import { metrics } from "./metrics-map";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Metric",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Value",
    nonSortable: true,
  },
];

type Props = {
  traderStats?: TraderStats;
  isLoading: boolean;
};
const MetricsTable = ({ traderStats, isLoading }: Props) => {
  const data: RenderData[][] = Object.entries(isLoading ? {} : metrics).map(([key, d]) => {
    const value = traderStats?.[key as keyof TraderStats] || "-";
    return [
      textCell(d.name, undefined, undefined, d.tooltip, d.icon),
      valueCell(Number(value), d.valueType, "standard"),
    ];
  });

  return <ChaosTable title="Metrics" headers={headers} data={data} isLoading={isLoading} isFilterHidden />;
};

export default MetricsTable;
