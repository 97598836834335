import { PieChart } from "@frontend/ui";
import { useCollateralBreakdownQuery } from "../../../../generated";

const CollateralBreakdownPieChart = () => {
  const { loading, data } = useCollateralBreakdownQuery();

  return (
    <PieChart
      title="Pool Assets Distribution"
      series={[
        {
          data:
            (data?.collateralBreakdown?.collateralBreakdown || []).map(
              (c) => [c.symbol, c.amountEth] as [string, number],
            ) || [],
        },
      ]}
      cryptoCurrency="ETH"
      showLegendCryptoIcon
      isLoading={loading}
    />
  );
};

export default CollateralBreakdownPieChart;
