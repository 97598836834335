import { Route, Routes } from "react-router-dom";
import { ChaosApolloProvider } from "src/utils/graphql";
import { RoutePath } from "src/config/routes";
import IncentivesOptimizationPage from "./incentives-optimization-page";
import { RecommendationPoolPage } from "./recommendation-pool-page";
import { MethodologyPage } from "../methodology-page";
import { useClientConfig } from "./clients-config";

export const IncentivesOptimizationRoutes = () => {
  const { methodology } = useClientConfig();

  return (
    <ChaosApolloProvider product="osmosis">
      <Routes>
        <Route index element={<IncentivesOptimizationPage />} />
        <Route
          path={RoutePath.IncentivesOptimization.Pool.replace(RoutePath.IncentivesOptimization.Home, "")}
          element={<RecommendationPoolPage />}
        />
        <Route
          path={RoutePath.IncentivesOptimization.Methodology.replace(RoutePath.IncentivesOptimization.Home, "")}
          element={<MethodologyPage methodology={methodology} />}
        />
      </Routes>
    </ChaosApolloProvider>
  );
};
