import { ChaosTable, numberCell, assetCell, ExternalLinkCell, currencyCell, dateCell } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { Chain, TimeSpan, useAmmExchangeEventsQuery } from "src/pages/ccar-lending-page/generated";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { getTxDetailsUrl } from "@frontend/ui/utils/chain-url-mapper";

type Props = {
  marketId?: string;
  market?: string;
  timeSpan?: TimeSpan;
};

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Timestamp",
  },
  {
    renderType: "TEXT",
    text: "Token Sold",
  },
  {
    renderType: "TEXT",
    text: "Token Bought",
  },
  {
    renderType: "TEXT",
    text: "Amount Sold",
  },
  {
    renderType: "TEXT",
    text: "Amount Bought",
  },
  {
    renderType: "TEXT",
    text: "Amount Sold (USD)",
  },
  {
    renderType: "TEXT",
    text: "Amount Bought (USD)",
  },
  {
    renderType: "TEXT",
    text: "Tx Hash",
  },
];

const ExchangeEventsTable = ({ marketId, market, timeSpan }: Props) => {
  const { loading, data } = useAmmExchangeEventsQuery({
    variables: {
      marketId: marketId || null,
      timeSpan: timeSpan || null,
    },
  });

  const tableData: RenderData[][] = (data?.ammExchangeEvents || []).map(
    ({
      timestamp,
      soldAssetSymbol,
      boughtAssetSymbol,
      amountSold,
      amountBought,
      amountSoldUSD,
      amountBoughtUSD,
      txHash,
    }) => [
      dateCell(new Date(timestamp * 1000)),
      assetCell(soldAssetSymbol),
      assetCell(boughtAssetSymbol),
      numberCell(amountSold),
      numberCell(amountBought),
      currencyCell(amountSoldUSD),
      currencyCell(amountBoughtUSD),
      {
        renderType: "CUSTOM",
        text: formatAddressCompact(txHash),
        searchTerm: txHash,
        exportText: txHash,
        component: (
          <ExternalLinkCell
            text={formatAddressCompact(txHash, 4)}
            tooltip={txHash}
            href={getTxDetailsUrl(Chain.Ethereum, txHash)}
          />
        ),
      },
    ],
  );

  return (
    <ChaosTable
      title="Exchange Events"
      data={tableData}
      metadata={market}
      headers={headers}
      isLoading={loading}
      initialSortBy={0}
      isInitialSortDesc
      pageSize={20}
    />
  );
};

export default ExchangeEventsTable;
