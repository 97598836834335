import { useEffect, useMemo, useState } from "react";
import { Box } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { AssetsPriceChange } from "@frontend/ui/assets-price-change";
import { WithUnifiedProductRedirect } from "src/utils/feature-flags";
import { RiskExplorerQuery } from "../../generated";
import { Assets } from "./types";
import RiskCards from "./components/explorer-cards";
import ExplorerTables from "./components/explorer-tables";
import { useRiskExplorerData } from "./use-risk-explorer-data";
import { useClientConfig } from "../../clients-config";

const RiskExplorerTabInner = () => {
  const [dataCut, setDataCut] = useState<RiskExplorerQuery[]>();
  const { chains, stableCoin, isMultichain, riskExplorer } = useClientConfig();
  const { data, isLoading } = useRiskExplorerData(dataCut);

  const assets = useMemo(
    () =>
      data.markets?.reduce<Assets>(
        (res, a) =>
          a &&
          a.mappedSymbol.toLowerCase() !== stableCoin?.asset.toLowerCase() &&
          !res[a.chain]?.map((b) => b.mappedSymbol).includes(a.mappedSymbol)
            ? {
                ...res,
                [a.chain]: [
                  ...(res[a.chain] || []),
                  {
                    name: a.asset,
                    symbol: a.asset.toLowerCase(),
                    price: a.price,
                    mappedSymbol: a.mappedSymbol,
                  },
                ],
              }
            : res,
        {} as Assets,
      ),
    [data.markets, stableCoin],
  );

  const displayChains = useMemo(() => chains.filter((c) => !!assets?.[c]), [assets, chains]);

  useEffect(() => {
    const asset = assets && displayChains.length ? assets[displayChains[0]][0] : undefined;
    const tokenSymbol = asset?.mappedSymbol;

    if (tokenSymbol) {
      setDataCut(
        (d) =>
          d || [
            {
              chains: displayChains.filter((chain) =>
                assets?.[chain].find((a) => a.mappedSymbol === asset.mappedSymbol),
              ),
              liquidationThreshold: null,
              tokenSymbol,
              priceChangePercent: 0,
              isRawSymbol: null,
              marketsIds: null,
            },
          ],
      );
    }
  }, [assets, dataCut, displayChains]);

  return !dataCut ? (
    <Loader />
  ) : (
    <Box height="100%" display="flex" flexDirection="column">
      {!assets ? (
        <Loader />
      ) : (
        <Box display="flex" flexDirection="column">
          <AssetsPriceChange
            assets={assets}
            onApply={(query) => setDataCut(query as RiskExplorerQuery[])}
            stableCoinSymbol={stableCoin?.asset}
            isMultichain={isMultichain}
            subtitle="Shows the effect of an asset price changes on the health and liquidation of wallets with positions on those assets"
            showAdvancedSettings={riskExplorer?.showAdvancedSettings}
            liquidationThresholdLabel={riskExplorer?.liquidationThresholdLabel}
          />
        </Box>
      )}
      {isLoading || !data?.overview ? (
        <ShimmerLoader sx={{ height: 144 }} />
      ) : (
        <Box display="flex" flexDirection="column" mt={4}>
          <RiskCards data={data.overview} />
        </Box>
      )}
      <Box display="flex" flexDirection="column">
        {dataCut && <ExplorerTables dataCut={dataCut} />}
      </Box>
    </Box>
  );
};

const RiskExplorerTab = () => (
  <WithUnifiedProductRedirect>
    <RiskExplorerTabInner />
  </WithUnifiedProductRedirect>
);
export default RiskExplorerTab;
