import { Box, ChaosTable, TokenPairCell, currencyCell, chainCell, percentCell } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { useParams } from "react-router-dom";
import { RoutePath, RouteParams } from "src/config/routes";
import { Loader } from "@frontend/ui/loader";
import PoolTokensRatio from "src/components/pool-token-stats-card/pool-tokens-ratio";
import { PoolsDataResponse } from "../pages/main-page/use-pools-data";
import { TwapPoolFragment } from "../../generated";

type Props = {
  poolsDataResponse: PoolsDataResponse;
};

export type TokenInTable = {
  symbol: string;
  price: number;
  costOfRatioPlus10Percent: number | null;
  exhaustionCostUsd: number;
};

export type PoolInTable = {
  chain: string;
  poolAddress: string;
  feeTier: number;
  tvlUSD: number;
  token0: TokenInTable;
  token1: TokenInTable;
  volume24USD: number | null;
};

const manipualtionTooltip =
  "TWAP manipulation cost assuming full control of the network or no arbitrage for 30 minutes";
const exhaustionTooltip = "Capital required for buying all token liquidity from the pool";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Chain",
    width: "5%",
    textHidden: true,
  },
  {
    renderType: "TEXT",
    text: "Pool Name",
    width: "23%",
  },
  {
    renderType: "TEXT",
    text: "Fee Tier",
    suffix: "%",
  },
  {
    renderType: "TEXT",
    text: "TVL",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "Volume 24H",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "Price",
    width: "20%",
  },
  {
    renderType: "TEXT",
    tooltip: manipualtionTooltip,
    text: "Base Token / Quote Token 10% Increase",
    suffix: "$",
    width: "15%",
  },
  {
    renderType: "TEXT",
    tooltip: manipualtionTooltip,
    text: "Quote Token / Base Token 10% Increase",
    suffix: "$",
    width: "15%",
  },
  {
    renderType: "TEXT",
    tooltip: exhaustionTooltip,
    text: "Base Token / Quote Token Exhaustion",
    suffix: "$",
    width: "14%",
  },
  {
    renderType: "TEXT",
    tooltip: exhaustionTooltip,
    text: "Quote Token / Base Token Exhaustion",
    suffix: "$",
    width: "14%",
  },
];

const PoolsTable = ({ poolsDataResponse }: Props) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { isLoading, allPools } = poolsDataResponse;

  if (isLoading) return <Loader />;

  const pools: PoolInTable[] | undefined = poolsDataResponse.allPools?.map((twapPool: TwapPoolFragment) => ({
    chain: twapPool.chain,
    poolAddress: twapPool.poolAddress,
    feeTier: twapPool.feeTier / 1000000,
    tvlUSD: twapPool.total_amount_locked_usd,
    volume24USD: twapPool.volume24USD ?? null,
    token0: {
      symbol: twapPool.token0.symbol,
      price: twapPool.token0.priceInOtherToken,
      costOfRatioPlus10Percent:
        (twapPool.token0.manipulationCosts.find((cost) => cost.ratio === 1.1)?.cost || 0) * twapPool.token1.priceUSD,
      exhaustionCostUsd: twapPool.token0.exhaustionPriceInOtherToken * twapPool.token1.priceUSD,
    },
    token1: {
      symbol: twapPool.token1.symbol,
      price: twapPool.token1.priceInOtherToken,
      amountLocked: twapPool.token1.amountLocked,
      costOfRatioPlus10Percent:
        (twapPool.token1.manipulationCosts.find((cost) => cost.ratio === 1.1)?.cost || 0) * twapPool.token0.priceUSD,
      exhaustionCostUsd: twapPool.token1.exhaustionPriceInOtherToken * twapPool.token0.priceUSD,
    },
  }));

  const tableData: RenderData[][] = (pools || []).map(
    (pool) =>
      [
        chainCell(pool.chain, pool.chain, pool.chain),
        {
          renderType: "CUSTOM",
          text: `${pool.token0.symbol}/${pool.token1.symbol}`,
          component: <TokenPairCell token1={pool.token0.symbol} token2={pool.token1.symbol} />,
          searchTerm: `${pool.token0.symbol}/${pool.token1.symbol} ${pool.poolAddress}`,
          exportText: `${pool.token0.symbol}/${pool.token1.symbol} ${pool.poolAddress}`,
        },
        percentCell(pool.feeTier, undefined, "CHIP"),
        currencyCell(pool.tvlUSD),
        currencyCell(pool.volume24USD),
        {
          renderType: "CUSTOM",
          text: "",
          component: (
            <PoolTokensRatio
              fromToken={{ symbol: pool.token0.symbol, price: pool.token0.price }}
              toToken={{ symbol: pool.token1.symbol, price: pool.token1.price }}
              isPriceAlreadyAdjusted
              showTitle={false}
            />
          ),
        },
        pool.token0.costOfRatioPlus10Percent
          ? currencyCell(pool.token0.costOfRatioPlus10Percent)
          : {
              renderType: "TEXT",
              text: "-",
            },
        pool.token1.costOfRatioPlus10Percent
          ? currencyCell(pool.token1.costOfRatioPlus10Percent)
          : {
              renderType: "TEXT",
              text: "-",
            },
        currencyCell(pool.token0.exhaustionCostUsd),
        currencyCell(pool.token1.exhaustionCostUsd),
      ] as RenderData[],
  );

  const handleRowHref = (rowId: number) => {
    const pool = allPools ? allPools[rowId] : undefined;

    if (!pool || !clientName) return "";

    return RoutePath.Twap.PoolDetails.replace(RouteParams.ClientName, clientName)
      .replace(RouteParams.Chain, pool.chain)
      .replace(RouteParams.PoolId, pool.poolAddress);
  };

  return (
    <Box>
      <ChaosTable
        headers={headers}
        data={tableData}
        title="All Pools"
        rowHref={handleRowHref}
        showSearch
        isInitialSortEnable
        isInitialSortDesc
        initialSortBy={4}
        pageSize={15}
        minWidth={1100}
      />
    </Box>
  );
};

export default PoolsTable;
