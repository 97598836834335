import { Box } from "@frontend/ui";
import { MethodologyIntro } from "src/components/methodology-intro";
import { PageTracker } from "src/components/page-tracker";
import { useClientConfig } from "../../clients-config";
import PoolsTable from "../../components/pools-table";
import { PoolsDataResponse, usePoolsData } from "./use-pools-data";

const TwapPage = PageTracker(() => {
  const { methodology } = useClientConfig();
  const poolsDataResponse: PoolsDataResponse = usePoolsData();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {methodology && <MethodologyIntro methodology={methodology} />}
      <PoolsTable poolsDataResponse={poolsDataResponse} />
    </Box>
  );
});

export default TwapPage;
