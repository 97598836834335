import { Paper, ValueOverTimeChart } from "@frontend/ui";
import { getChartPropsFromHistoryData } from "src/utils/echarts";
import { HistoryDataFragment } from "../../../../generated";

type Props = {
  data?: HistoryDataFragment[];
  loading: boolean;
};

export const InterestImbalanceChart = ({ data, loading }: Props) => {
  const { series } = getChartPropsFromHistoryData(data, "Interest Imbalance");
  return (
    <ValueOverTimeChart
      isLoading={loading}
      title="Interest Imbalance"
      description="The absolute difference between Long and Short open interest, as ratio of total open interest."
      series={series}
      showLegend={false}
      isPercent
    />
  );
};
