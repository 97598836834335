import { ReactElement, useMemo } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@frontend/ui/theme";
import { AppColorSchemeContext } from "@frontend/ui/hooks/use-app-color-scheme";
import { AdapterDayjs, LocalizationProvider } from "@frontend/ui/date-picker";
import { AppColorSchemes } from "@frontend/ui/types";
import { AuthProvider } from "@frontend/ui/auth";
import { ConfirmationMessageProvider } from "@frontend/ui";
import { RenderRoutes } from "./RenderRoutes";
import { authUrl } from "./utils/auth-url";

const App = (): ReactElement => {
  const colorProviderValue = useMemo(
    () => ({
      colorScheme: "dark" as AppColorSchemes,
    }),
    [],
  );

  return (
    <AppColorSchemeContext.Provider value={colorProviderValue}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider>
          <CssBaseline />
          <AuthProvider authUrl={authUrl()}>
            <ConfirmationMessageProvider>
              <RenderRoutes />
            </ConfirmationMessageProvider>
          </AuthProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </AppColorSchemeContext.Provider>
  );
};

export default App;
