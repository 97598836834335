import { PieChart } from "@frontend/ui";
import { useEffect, useState } from "react";
import { ProtocolPoolsHistoryFragment } from "src/pages/ccar-lending-page/generated";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import TvlBalanceToggle from "./tvl-balance-toggle";

type Props = {
  asset: string;
  poolsHistoryByProtocol?: ProtocolPoolsHistoryFragment[];
};
const TotalPoolsAmountProtocolDistribution = ({ asset, poolsHistoryByProtocol }: Props) => {
  const [showTvl, setShowTvl] = useState(false);
  const [poolsData, setPoolsData] = useState(poolsHistoryByProtocol);

  useEffect(() => {
    if (poolsHistoryByProtocol) setPoolsData(poolsHistoryByProtocol);
  }, [poolsHistoryByProtocol]);

  const chartData = (poolsData || []).map(({ protocol, data }) => {
    const lastPoint = [...data].sort((a, b) => b.timestamp - a.timestamp)[0];

    return {
      x: capitalizeFirstLetter(protocol),
      y: showTvl ? lastPoint.totalTvlUSD : lastPoint.totalTokenBalance,
    };
  });

  return (
    <PieChart
      title={`Distribution of ${asset} across Protocols`}
      isLoading={!poolsData}
      series={[
        {
          data: chartData.map((d) => [d.x, d.y]),
        },
      ]}
      currency={showTvl ? "USD" : ""}
      headerSuffix={<TvlBalanceToggle asset={asset} showTvl={showTvl} setShowTvl={setShowTvl} />}
      showLegendCryptoIcon
    />
  );
};
export default TotalPoolsAmountProtocolDistribution;
