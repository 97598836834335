import { Box, Page404 } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { ReactNode, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RouteParams } from "src/config/routes";
import { FullPoolFragment } from "src/pages/lp-dashboard-page/generated";
import { usePoolDetailsData } from "src/pages/lp-dashboard-page/pages/pool-details/use-pool-details-data";
import { PoolTokensStatsCard } from "../pool-token-stats-card";
import PoolPageHeader from "./pool-page-header";

type Props = {
  children: (poolDetails: FullPoolFragment) => ReactNode;
  backPageRoute: string;
  showTokensStatsCard?: boolean;
};

type PoolDetailsQuery = {
  poolId: string;
};

export const PoolPageWrapper = ({ children, backPageRoute, showTokensStatsCard = false }: Props) => {
  const { clientName, poolId } = useParams<{ clientName: string; poolId: string }>();
  const query: PoolDetailsQuery = useMemo(() => ({ poolId: poolId || "" }), [poolId]);

  const navigate = useNavigate();
  useEffect(() => {
    if (!poolId) {
      navigate(backPageRoute.replace(RouteParams.ClientName, clientName!), { replace: true });
    }
  });

  const { isLoading, poolDetails } = usePoolDetailsData(query);

  if (isLoading) return <Loader />;

  return (
    <Box>
      {poolDetails ? (
        <Box display="flex" flexWrap="nowrap" flexDirection="column" height="100%" width="100%" gap={3}>
          <Box display="flex" flexWrap="nowrap" flexDirection="row" height="100%" width="100%" gap={3}>
            <PoolPageHeader
              token1={poolDetails.token1.symbol}
              token2={poolDetails.token2.symbol}
              subtitle={poolId ? `Pool #${poolId}` : undefined}
              backRoute={backPageRoute}
              suffixComponent={showTokensStatsCard ? <PoolTokensStatsCard data={poolDetails} /> : undefined}
            />
          </Box>
          <Box>{children(poolDetails)}</Box>
        </Box>
      ) : (
        <Page404 />
      )}
    </Box>
  );
};
