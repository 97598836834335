import OptionsSelect from "../options-select";

const options = [
  {
    value: true,
    label: "USD",
  },
  {
    value: false,
    label: "Token",
  },
] as const;

type Props = {
  isUsdSelected: boolean;
  onSelect: (value: boolean) => void;
};
const UsdTokenSelect = ({ isUsdSelected, onSelect }: Props) => (
  <OptionsSelect<boolean> title="Show In" options={options} selectedOption={isUsdSelected} onSelectOption={onSelect} />
);

export default UsdTokenSelect;
