import { Route, Routes, Navigate, useParams } from "react-router-dom";
import { Error } from "@frontend/ui/error";
import { RouteParams, RoutePath } from "src/config/routes";
import { PageTracker } from "src/components/page-tracker";
import { ChaosApolloProvider } from "src/utils/graphql";
import { TopBanner } from "src/pages/community-analytics-page/components/top-banner";
import { FeatureFlagsProvider } from "src/utils/feature-flags";
import { CCARTabsLayout } from "./ccar-lending-tabs-layout";
import {
  LiquidationsTab,
  MarketsTab,
  OverviewTab,
  WalletsTab,
  WalletDetails,
  RiskExplorerTab,
  AlertsTab,
  AssetDetails,
  AssetsTab,
  StablecoinTab,
} from "../tabs";
import { clientsConfig, useClientConfig } from "../clients-config";
import { Client } from "../types";
import { replaceHomePath, renderPageLayout } from "./utils";
import ReservesTab from "../tabs/reserves/reserves";

export const CCARLendingRoutes = PageTracker(() => {
  const { clientName } = useParams<{ clientName: Client }>();
  const { overview, marketDetails, assetDetails, wallets, riskExplorer, liquidations, stableCoin, reserves, alerts } =
    useClientConfig();

  const getDefaultRoute = () => {
    if (overview) {
      return RoutePath.CCARLending.Overview;
    }
    if (alerts) {
      return RoutePath.CCARLending.Alerts;
    }
    if (marketDetails) {
      return RoutePath.CCARLending.Markets;
    }
    if (reserves) {
      return RoutePath.CCARLending.Reserves;
    }
    return RoutePath.CCARLending.RiskExplorer;
  };

  if (!Object.keys(clientsConfig).includes(clientName!)) {
    return <Error errorTitle="404" errorMessage="Page Not Found" />;
  }
  return (
    <ChaosApolloProvider product="ccar-lending">
      <FeatureFlagsProvider>
        <TopBanner />
        <Routes key={clientName}>
          <Route
            index
            element={<Navigate to={getDefaultRoute().replace(RouteParams.ClientName, clientName!)} replace />}
          />
          {overview && (
            <Route
              path={replaceHomePath(RoutePath.CCARLending.Overview)}
              element={
                <CCARTabsLayout>
                  <OverviewTab />
                </CCARTabsLayout>
              }
            />
          )}
          {marketDetails && (
            <Route
              path={replaceHomePath(RoutePath.CCARLending.Markets)}
              element={
                <CCARTabsLayout>
                  <MarketsTab />
                </CCARTabsLayout>
              }
            />
          )}
          {assetDetails && (
            <Route
              path={replaceHomePath(RoutePath.CCARLending.Assets)}
              element={
                <CCARTabsLayout>
                  <AssetsTab />
                </CCARTabsLayout>
              }
            />
          )}
          {wallets && (
            <Route
              path={replaceHomePath(RoutePath.CCARLending.Wallets)}
              element={
                <CCARTabsLayout>
                  <WalletsTab />
                </CCARTabsLayout>
              }
            />
          )}
          {liquidations && (
            <Route
              path={replaceHomePath(RoutePath.CCARLending.Liquidations)}
              element={
                <CCARTabsLayout>
                  <LiquidationsTab />
                </CCARTabsLayout>
              }
            />
          )}
          {riskExplorer && (
            <Route
              path={replaceHomePath(RoutePath.CCARLending.RiskExplorer)}
              element={
                <CCARTabsLayout>
                  <RiskExplorerTab />
                </CCARTabsLayout>
              }
            />
          )}
          {stableCoin && (
            <Route
              path={replaceHomePath(RoutePath.CCARLending.Stablecoin(stableCoin.asset))}
              element={
                <CCARTabsLayout>
                  <StablecoinTab />
                </CCARTabsLayout>
              }
            />
          )}
          {reserves && (
            <Route
              path={replaceHomePath(RoutePath.CCARLending.Reserves)}
              element={
                <CCARTabsLayout>
                  <ReservesTab />
                </CCARTabsLayout>
              }
            />
          )}
          {alerts?.enabled && (
            <Route
              path={`${replaceHomePath(RoutePath.CCARLending.Alerts)}/*`}
              element={
                <CCARTabsLayout>
                  <AlertsTab />
                </CCARTabsLayout>
              }
            />
          )}
          <Route
            path={replaceHomePath(RoutePath.CCARLending.AssetDetails)}
            element={renderPageLayout(<AssetDetails />)}
          />
          <Route
            path={replaceHomePath(RoutePath.CCARLending.WalletDetails)}
            element={renderPageLayout(<WalletDetails />)}
          />
          <Route
            path="*"
            element={<Navigate to={getDefaultRoute().replace(RouteParams.ClientName, clientName!)} replace />}
          />
        </Routes>
      </FeatureFlagsProvider>
    </ChaosApolloProvider>
  );
});
