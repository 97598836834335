import { useContext } from "react";
import { useParams } from "react-router-dom";
import { useIsInUnifiedProduct } from "src/hooks/use-is-in-unified-product";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, tokenPairCell } from "@frontend/ui/chaos-table";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { RouteParams, RoutePath } from "src/config/routes";
import { WithUnifiedProductRedirect } from "src/utils/feature-flags";
import { Chain, useMarketAssetsBreakdownQuery } from "../../generated";
import { useClientConfig } from "../../clients-config";
import { Client } from "../../types";
import { ListedAssetsContext } from "../chains/context/listed-assets-context";

type Props = {
  title?: string;
  chain?: Chain;
};

const MarketsTabInner = ({ chain, title = "Markets" }: Props) => {
  const { clientName } = useParams<{ clientName: Client }>();
  const { isMultichain, chains, markets: marketsConfig } = useClientConfig();
  const { isSingularMarket, hiddenColumns } = marketsConfig || {};
  const { isInUnifiedProduct } = useIsInUnifiedProduct();

  const { loading, data } = useMarketAssetsBreakdownQuery({
    variables: {
      chain: chain || null,
      marketId: null,
      tokenSymbol: null,
    },
    skip: isInUnifiedProduct,
  });
  const listedAssetContext = useContext(ListedAssetsContext);
  const assetsBreakdown = listedAssetContext.listedAssets || data?.assetsBreakdown;
  const isLoading = listedAssetContext.isLoading || loading;

  const borrowableAssets = assetsBreakdown?.filter((asset) => asset.isBorrowable) || [];
  const collateralizableAssets = assetsBreakdown?.filter((asset) => asset.isCollateralizable) || [];

  const handleOnClickRow = (rowIdx: number) => {
    const marketAsset = (isSingularMarket ? collateralizableAssets : borrowableAssets)[rowIdx];
    const { chain: chainName, asset } = marketAsset || {};

    if (isInUnifiedProduct) {
      return RoutePath.Risk.ChainAssetOverview.replace(RouteParams.ClientName, clientName!)
        .replace(RouteParams.Chain, chain!)
        .replace(RouteParams.Asset, asset);
    }

    return RoutePath.CCARLending.MarketDetails.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.Market,
      isMultichain ? `${asset || ""}-${chainName || ""}` : asset || "",
    );
  };

  const marketsAssets = marketsConfig?.isSingularMarket ? collateralizableAssets : borrowableAssets;
  const hasMarketType = marketsAssets?.some((asset) => !!asset.marketType);

  const showChainColumn = !hiddenColumns?.chain && !chain && chains.length > 1;

  const marketsHeaders: Header[] = [
    ...(hasMarketType
      ? ([
          {
            renderType: "TEXT",
            text: "Type",
            width: "5%",
          },
        ] as Header[])
      : []),
    ...(showChainColumn
      ? [
          {
            renderType: "TEXT",
            text: "Chain",
            width: "2%",
            textHidden: true,
          } as Header,
        ]
      : []),
    ...([
      isSingularMarket
        ? {
            renderType: "TEXT",
            text: "Collateral / Borrow",
            width: "10%",
            textHidden: chains.length > 1,
            isAutocomplete: true,
          }
        : {
            renderType: "TEXT",
            text: "Asset",
            width: "5%",
            textHidden: chains.length > 1,
            isAutocomplete: true,
          },
    ] as Header[]),
    {
      renderType: "TEXT",
      text: "Price",
      prefix: "$",
      width: "5%",
    },
    {
      renderType: "TEXT",
      text: "Supply APY",
      suffix: "%",
      width: "5%",
    },
    {
      renderType: "TEXT",
      text: "Borrow APY",
      suffix: "%",
      width: "5%",
    },
    {
      renderType: "TEXT",
      text: "Total Supply",
      prefix: "$",
      width: "5%",
    },
    {
      renderType: "TEXT",
      text: "Total Borrow",
      prefix: "$",
      width: "5%",
    },
    {
      renderType: "TEXT",
      text: "Utilization",
      suffix: "%",
      width: "5%",
    },
    {
      renderType: "TEXT",
      text: "Collateral at Risk",
      width: "5%",
    },
    {
      renderType: "TEXT",
      text: "Wallets at Risk",
      width: "5%",
    },
  ];

  const marketsRows: RenderData[][] | undefined = marketsAssets.map((asset) => {
    const borrowableAsset = isSingularMarket ? borrowableAssets.find((a) => a.marketId === asset.marketId) : undefined;

    const borrowAssetData = isSingularMarket ? borrowableAsset || asset : asset;

    return [
      ...(hasMarketType
        ? [
            {
              renderType: "TEXT",
              text: asset.marketType || "",
            },
          ]
        : []),
      ...(showChainColumn
        ? [
            {
              renderType: "TEXT",
              text: asset.chain,
              tooltipText: asset.chain,
              token1: asset.chain,
              extraData: {
                symbol: asset.chain,
              },
            },
          ]
        : []),
      ...(marketsConfig?.isSingularMarket && borrowableAsset
        ? [
            tokenPairCell({
              token1: asset.mappedSymbol,
              token2: borrowableAsset.mappedSymbol,
              text: asset.mappedSymbol,
            }),
          ]
        : [
            {
              renderType: "TEXT",
              text: asset.asset,
              tooltipText: asset.asset,
              token1: asset.asset,
            },
          ]),
      {
        renderType: "TEXT",
        text: formatAmount(asset.price || 0, { currency: "USD", notation: "compact", maximumFractionDigits: 2 }),
        tooltipText: formatAmount(asset.price || 0, { currency: "USD", notation: "standard" }),
        value: Number(asset.price) || 0,
      },
      {
        renderType: "TEXT",
        text: formatAmount(borrowAssetData.supplyAPY || 0, {
          isPercent: true,
          notation: "standard",
          maximumFractionDigits: 4,
        }),
        value: Number(borrowAssetData.supplyAPY * 100) || 0,
      },
      {
        renderType: "TEXT",
        text: formatAmount(borrowAssetData.borrowAPY || 0, {
          isPercent: true,
          notation: "standard",
          maximumFractionDigits: 4,
        }),
        value: Number(borrowAssetData.borrowAPY * 100) || 0,
      },
      {
        renderType: "TEXT",
        text: formatAmount(borrowAssetData.totalSupply || 0, {
          currency: "USD",
          notation: "compact",
          maximumFractionDigits: 2,
        }),
        tooltipText: formatAmount(borrowAssetData.totalSupply || 0, { currency: "USD", notation: "standard" }),
        value: Number(borrowAssetData.totalSupply) || 0,
      },
      {
        renderType: "TEXT",
        text: formatAmount(borrowAssetData.totalBorrow || 0, {
          currency: "USD",
          notation: "compact",
          maximumFractionDigits: 2,
        }),
        tooltipText: formatAmount(borrowAssetData.totalBorrow || 0, { currency: "USD", notation: "standard" }),
        value: Number(borrowAssetData.totalBorrow) || 0,
      },
      {
        renderType: "TEXT",
        text: formatAmount(borrowAssetData.totalBorrow / borrowAssetData.totalSupply || 0, {
          isPercent: true,
          notation: "standard",
        }),
        value: Number((borrowAssetData.totalBorrow / borrowAssetData.totalSupply) * 100) || 0,
      },
      {
        renderType: "TEXT",
        text: formatAmount(asset.totalValueAtRisk || 0, {
          currency: "USD",
          notation: "compact",
          maximumFractionDigits: 2,
        }),
        tooltipText: formatAmount(asset.totalValueAtRisk || 0, { currency: "USD", notation: "standard" }),
        value: Number(asset.totalValueAtRisk) || 0,
      },
      {
        renderType: "TEXT",
        text: asset.walletsAtRisk,
        value: Number(asset.walletsAtRisk) || 0,
      },
    ] as RenderData[];
  });

  return (
    <ChaosTable
      title={title}
      headers={marketsHeaders}
      data={marketsRows}
      isLoading={isLoading}
      pageSize={15}
      showSearch
      isFilterHidden={false}
      initialSortBy={5}
      isInitialSortEnable
      isInitialSortDesc
      serchbarPlaceholder="Search"
      rowHref={handleOnClickRow}
      showRowChevron
      isFullHeight
    />
  );
};

const MarketsTab = (props: Props) => (
  <WithUnifiedProductRedirect>
    <MarketsTabInner {...props} />
  </WithUnifiedProductRedirect>
);

export default MarketsTab;
