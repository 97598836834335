import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { Box, Downloadable, Grid, UsdTokenSelect } from "@frontend/ui";
import { getDeltaFields } from "@frontend/ui/trend";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useRef, useState } from "react";
import { AssetStatsFragment } from "../../../generated";

type Props = {
  stats?: AssetStatsFragment;
  isNonBorrowableAsset?: boolean;
};

const MarketStatsCards = ({ stats, isNonBorrowableAsset }: Props) => {
  const { marketDetails } = useClientConfig();
  const cardsRef = useRef<HTMLElement | null>(null);
  const [isValuesInUsd, setIsValuesInUsd] = useState(false);
  const { liquidationThresholdLabel } = marketDetails || {};
  const currentStats = stats?.current;
  const previousStats = stats?.previous;

  const {
    price: currentPrice,
    supplyAPY: currentSupplyAPY,
    variableBorrowAPY: currentVariableBorrowAPY,
    valueAtRisk: currentValueAtRisk,
    totalBorrow: currentTotalBorrow,
    totalSupply: currentTotalSupply,
    loanToValue: currentLoanToValue,
    totalSupplyToken: currentTotalSupplyToken,
    totalBorrowToken: currentTotalBorrowToken,
    supplyCap,
    borrowCap,
  } = currentStats || {};

  const hasCurrentUtilizationRate =
    currentTotalBorrow !== undefined && currentTotalSupply !== undefined && currentTotalSupply > 0;
  const currentUtilizationRate = hasCurrentUtilizationRate ? currentTotalBorrow / currentTotalSupply : undefined;
  const currentUtilizationRateValue = currentTotalSupply === 0 ? "N/A" : currentUtilizationRate;

  const {
    price: previousPrice,
    valueAtRisk: previousValueAtRisk,
    totalBorrow: previousTotalBorrow,
    totalSupply: previousTotalSupply,
    totalSupplyToken: previousTotalSupplyToken,
    totalBorrowToken: previousTotalBorrowToken,
  } = previousStats || {};

  const previousUtilizationRate =
    previousTotalBorrow !== undefined && previousTotalSupply !== undefined
      ? previousTotalBorrow / previousTotalSupply
      : undefined;

  const currentSupply = isValuesInUsd ? currentTotalSupply : currentTotalSupplyToken;
  const prevSupply = isValuesInUsd ? previousTotalSupply : previousTotalSupplyToken;
  const currentBorrow = isValuesInUsd ? currentTotalBorrow : currentTotalBorrowToken;
  const prevBorrow = isValuesInUsd ? previousTotalBorrow : previousTotalBorrowToken;

  const valueAtRisk = currentValueAtRisk && currentPrice ? currentValueAtRisk / (isValuesInUsd ? 1 : currentPrice) : 0;
  const currValueAtRisk = currentValueAtRisk === undefined ? undefined : valueAtRisk;
  const prevValueAtRisk =
    previousValueAtRisk && previousPrice ? previousValueAtRisk / (isValuesInUsd ? 1 : previousPrice) : undefined;

  const cardsProps: ValueCardProps[] = [
    {
      title: "Total Supply",
      value: currentSupply ?? undefined,
      currency: isValuesInUsd ? "USD" : undefined,
      ...getDeltaFields(currentSupply || undefined, prevSupply || undefined, true),
      progress:
        supplyCap && currentPrice
          ? {
              maxValue: supplyCap * (isValuesInUsd ? currentPrice : 1),
              displayMaxValue: true,
            }
          : undefined,
      valueMaximumFractionDigits: supplyCap ? 2 : undefined,
    },
    {
      title: "Total Borrow",
      value: currentBorrow ?? undefined,
      currency: isValuesInUsd ? "USD" : undefined,
      ...getDeltaFields(currentBorrow || undefined, prevBorrow || undefined, true),
      progress:
        borrowCap && currentPrice
          ? {
              maxValue: borrowCap * (isValuesInUsd ? currentPrice : 1),
              displayMaxValue: true,
            }
          : undefined,
      valueMaximumFractionDigits: borrowCap ? 2 : undefined,
    },
    {
      title: "Utilization Rate",
      value: currentUtilizationRateValue,
      isPercent: true,
      ...getDeltaFields(currentUtilizationRate, previousUtilizationRate, true),
      progress: {
        maxValue: 1,
      },
    },
    {
      title: "APY",
      value:
        currentSupplyAPY !== undefined && currentVariableBorrowAPY !== undefined
          ? [
              {
                title: "Supply APY",
                value: currentSupplyAPY,
                isPercent: true,
                valueMaximumFractionDigits: 4,
                capAtValue: 0.0001,
              },
              {
                title: "Borrow APY",
                value: currentVariableBorrowAPY,
                isPercent: true,
                valueMaximumFractionDigits: 4,
                capAtValue: 0.0001,
              },
            ]
          : undefined,
    },
  ];

  const nonBorrowableAssetCardsProps: ValueCardProps[] = [
    {
      title: "Price",
      value: currentPrice,
      currency: "USD",
      ...getDeltaFields(currentPrice, previousPrice, true),
    },
    {
      title: "Total Collateral",
      value: currentSupply ?? undefined,
      currency: isValuesInUsd ? "USD" : undefined,
      progress:
        supplyCap && currentPrice
          ? {
              maxValue: supplyCap * (isValuesInUsd ? currentPrice : 1),
              displayMaxValue: true,
            }
          : undefined,
      ...getDeltaFields(currentSupply ?? undefined, prevSupply ?? undefined, true),
    },
    {
      title: liquidationThresholdLabel || "Collateral Factor",
      value: currentLoanToValue ?? undefined,
      isPercent: true,
    },
    {
      title: "Collateral at Risk",
      value: currValueAtRisk,
      currency: isValuesInUsd ? "USD" : undefined,
      ...getDeltaFields(currValueAtRisk, prevValueAtRisk, false),
    },
  ];

  return (
    <Grid container paddingTop={1}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <UsdTokenSelect isUsdSelected={isValuesInUsd} onSelect={setIsValuesInUsd} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Downloadable id="market-stats-cards">
          <Grid container>
            {(isNonBorrowableAsset ? nonBorrowableAssetCardsProps : cardsProps).map((cardProps, i) => (
              <Grid
                key={`${cardProps.title}${i.toString()}`}
                item
                zeroMinWidth
                xs={12}
                md={6}
                lg={3}
                data-testid="asset-stats"
              >
                <ValueCard {...cardProps} />
              </Grid>
            ))}
          </Grid>
        </Downloadable>
      </Grid>
    </Grid>
  );
};

export default MarketStatsCards;
