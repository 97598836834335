import { Box, Typography } from "@frontend/ui";
import { TreemapChart } from "@frontend/ui/charts";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { WalletPool } from "src/pages/lp-dashboard-page/generated";
import { PortfolioBreakdownChartDataType } from "../../../types";

type Props = {
  data: WalletPool[];
};

const WalletPortfolioBreakdownChart = ({ data }: Props) => {
  const portfolioBreakdownData: PortfolioBreakdownChartDataType[] = data.map(
    (pool) =>
      ({
        name: `${pool.token1.symbol}/${pool.token2.symbol}`,
        value: pool.liquidityBondedUSD,
      }) as PortfolioBreakdownChartDataType,
  );

  return (
    <Box
      sx={{
        boxShadow: "none",
        padding: 2,
        borderRadius: 2,
        backgroundColor: "#30343B",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        justifyContent: "space-between",
      }}
    >
      <Typography display="flex" justifyContent="start" variant="h2" mb={1}>
        Wallet’s Portfolio Breakdown
      </Typography>
      <TreemapChart
        data={portfolioBreakdownData}
        labelFormat={(value: number, name?: string) => `${name || ""}\n${formatAmount(value, { currency: "USD" })}`}
        tooltipFormat={(value: number) => `${formatAmount(value, { currency: "USD", notation: "standard" })}`}
      />
    </Box>
  );
};

export default WalletPortfolioBreakdownChart;
