import { ChaosTable } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { OrderBookSpread } from "src/pages/ccar-perpetuals-page/generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Parameter",
  },
  {
    renderType: "TEXT",
    text: "P25",
  },
  {
    renderType: "TEXT",
    text: "P50",
  },
  {
    renderType: "TEXT",
    text: "P90",
  },
  {
    renderType: "TEXT",
    text: "P95",
  },
  {
    renderType: "TEXT",
    text: "P99",
  },
];

type Props = {
  data?: OrderBookSpread[];
  cryptoCurrency?: string;
  isLoading: boolean;
};

const getSpreadLiquidityCell = (liquidity: number, price: number, cryptoCurrency?: string): RenderData => {
  const formatText = (notation?: "standard") =>
    `${formatAmount(liquidity * price, { currency: !cryptoCurrency ? "USD" : undefined, notation })}${
      cryptoCurrency ? ` ${cryptoCurrency}` : ""
    }`;

  return {
    renderType: "TEXT",
    text: formatText(),
    value: liquidity * price,
    tooltipText: formatText("standard"),
  };
};

export const OrderBookLiquidity = ({ data, cryptoCurrency, isLoading }: Props) => {
  const tableData: RenderData[][] = (data || []).map((spread) => [
    {
      renderType: "TEXT",
      text: `${spread.spread * 10000}bps`,
    },
    getSpreadLiquidityCell(spread.p25, spread.price, cryptoCurrency),
    getSpreadLiquidityCell(spread.p50, spread.price, cryptoCurrency),
    getSpreadLiquidityCell(spread.p90, spread.price, cryptoCurrency),
    getSpreadLiquidityCell(spread.p95, spread.price, cryptoCurrency),
    getSpreadLiquidityCell(spread.p99, spread.price, cryptoCurrency),
  ]);
  return (
    <ChaosTable
      title="Order Book Liquidity"
      data={tableData}
      isLoading={isLoading}
      headers={headers}
      isSettingsHidden
      isFilterHidden
    />
  );
};
