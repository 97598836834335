import { useParams } from "react-router-dom";
import { ChaosTable, currencyCell, iconsCell, textCell } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { useMemo } from "react";
import { useAvsDataQuery } from "../../../generated";
import { RouteParams, RoutePath } from "../../../../../config/routes";

export const AvsTable = () => {
  const { data, loading } = useAvsDataQuery();
  const { clientName } = useParams();

  const headers: Header[] = useMemo(
    () => [
      {
        renderType: "TEXT",
        text: "AVS Name",
      },
      {
        renderType: "TEXT",
        text: "TVR",
        prefix: "ETH",
      },
      {
        renderType: "TEXT",
        text: "Total Number of Operators",
      },
    ],
    [],
  );

  const tableData: RenderData[][] = (data?.avsData || []).map((avs) => [
    {
      renderType: "TEXT",
      token1: avs.name,
      text: avs.name,
    },
    currencyCell(avs.protocols.find((p) => p.protocol === "EtherFi")?.delegationEth || 0, { cryptoCurrency: "ETH" }),
    textCell(avs.operators.length.toString()),
  ]);

  const getRowHref = (rowId: number) => {
    const avs = data?.avsData?.[rowId];

    if (!avs) {
      return "";
    }

    return RoutePath.Risk.AvsDetails.replace(RouteParams.ClientName, clientName!).replace(RouteParams.AvsId, avs.id);
  };

  return (
    <ChaosTable
      title="AVS Overview"
      initialSortBy={1}
      isInitialSortDesc
      showRowChevron
      pageSize={10}
      headers={headers}
      data={tableData}
      isLoading={loading}
      rowHref={getRowHref}
      isFullHeight
    />
  );
};
