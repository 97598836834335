import { Box } from "../box";
import { Paper } from "../paper";
import { Tooltip } from "../tooltip";
import { Typography } from "../typography";
import { CustomIcon } from "../custom-icon";
import { formatAmount } from "../utils/formatters";
import { ShimmerLoader } from "../shimmer-loader";
import { Trend } from "../trend";
import { formatCardValue } from "../value-card/helper";

export type ValueCardGroupItem = {
  title?: string;
  value?: number | string;
  isPercent?: boolean;
  isDeltaPercent?: boolean;
  currency?: string;
  delta?: number;
  deltaTrend?: number;
  deltaColor?: number;
  showSign?: boolean;
};

export type ValueCardGroupProps = {
  title: string;
  tooltip?: string;
  items: ValueCardGroupItem[];
  isLoading?: boolean;
};

const ValueCardGroup = ({ title, tooltip, items, isLoading }: ValueCardGroupProps) => (
  <Paper
    variant="card"
    sx={{
      boxShadow: "none",
      padding: 3,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 1,
      justifyContent: "space-between",
    }}
  >
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Typography variant="label">{title}</Typography>
        {tooltip && (
          <Box>
            <Tooltip title={tooltip} arrow>
              <Box>
                <CustomIcon
                  icon="info"
                  sx={{
                    "& path": { fill: "currentColor" },
                    svg: { height: "24px", width: "24px" },
                    ml: 1,
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
    <Box
      sx={{
        display: "flex",
        flexWrap: { xs: "wrap", md: "nowrap" },
        justifyContent: "space-between",
        gap: 2,
        minHeight: 44,
      }}
    >
      {isLoading && <ShimmerLoader sx={{ borderRadius: 1 }} />}
      {!isLoading &&
        items.map(
          ({
            title: itemTitle,
            value,
            isPercent,
            isDeltaPercent,
            currency,
            delta,
            deltaTrend,
            deltaColor,
            showSign,
          }) => {
            const fullValue =
              typeof value === "number"
                ? formatAmount(value || 0, {
                    isPercent,
                    currency,
                    notation: "standard",
                  })
                : "";
            const formattedValue =
              typeof value === "number"
                ? `${showSign && value > 0 ? "+" : ""}${formatCardValue(value, isPercent, currency, undefined)}`
                : value;
            return (
              <Box key={itemTitle} display="flex" flexDirection="column" flex={1}>
                {itemTitle && (
                  <Typography sx={{ fontSize: 12, lineHeight: 1.5, color: "text.secondary" }}>{itemTitle}</Typography>
                )}
                <Box display="flex" gap={1} flex={1}>
                  <Tooltip title={fullValue} arrow placement="bottom">
                    <Typography variant="h3">
                      {formattedValue}
                    </Typography>
                  </Tooltip>
                  {delta !== undefined && deltaTrend !== undefined && (
                    <Trend
                      delta={delta}
                      deltaTrend={deltaTrend}
                      deltaColor={deltaColor}
                      isPercent={isDeltaPercent || isPercent}
                      currency={currency}
                    />
                  )}
                </Box>
              </Box>
            );
          },
        )}
    </Box>
  </Paper>
);

export default ValueCardGroup;
