import { Box, CryptoIcon, CustomIcon, Tooltip, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";

type Props = {
  totalCurrentIncentives: number;
  totalRecommendedIncentives: number;
  totalIncentivesSavedToDate: number;
  totalCurrentLiquidity: number;
  totalTargetLiquidity: number;
};

const TotalIncentivesCards = ({
  totalCurrentIncentives,
  totalRecommendedIncentives,
  totalIncentivesSavedToDate,
  totalCurrentLiquidity,
  totalTargetLiquidity,
}: Props) => (
  <Box display="flex" flexDirection="row" justifyContent="space-around" gap={3}>
    <Box
      bgcolor="darkGrey.main"
      p={3}
      display="flex"
      flexDirection="column"
      borderRadius={2}
      flexWrap="wrap"
      gap={1}
      width="100%"
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <CustomIcon icon="dollar-out" />
        <Typography>Daily Incentives</Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" gap={3}>
        <Box display="flex" flexDirection="column">
          <Typography color="almostWhite.main">Current</Typography>
          <Box display="flex" flexDirection="row" gap={1}>
            <CryptoIcon icon="osmo" sx={{ img: { width: 20, height: 20 } }} />
            {formatAmount(totalCurrentIncentives, { notation: "standard" })}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography color="almostWhite.main">With Recommendations</Typography>
          <Box display="flex" flexDirection="row" gap={1}>
            <CryptoIcon icon="osmo" sx={{ img: { width: 20, height: 20 } }} />
            {formatAmount(totalRecommendedIncentives, { notation: "standard" })}
          </Box>
        </Box>
      </Box>
    </Box>
    <Box
      bgcolor="darkGrey.main"
      p={3}
      display="flex"
      flexDirection="column"
      borderRadius={2}
      flexWrap="wrap"
      gap={1}
      width="100%"
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <CustomIcon icon="lab" />
        <Typography flex="1">Incentivized Liquidity</Typography>
        <Tooltip title="Sum of liquidity across all incentivized pools.">
          <Box>
            <CustomIcon icon="info" />
          </Box>
        </Tooltip>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" gap={3}>
        <Box display="flex" flexDirection="column">
          <Typography color="almostWhite.main">Current</Typography>
          <Box display="flex" flexDirection="row" gap={1}>
            {formatAmount(totalCurrentLiquidity, { currency: "USD", notation: "standard" })}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography color="almostWhite.main">Target</Typography>
          <Box display="flex" flexDirection="row" gap={1}>
            {formatAmount(totalTargetLiquidity, { currency: "USD", notation: "standard" })}
          </Box>
        </Box>
      </Box>
    </Box>
    <Box
      bgcolor="darkGrey.main"
      p={3}
      display="flex"
      flexDirection="column"
      borderRadius={2}
      flexWrap="wrap"
      gap={3}
      width="100%"
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <CustomIcon icon="coin-dollar" />
        <Typography>Total Incentives Saved to Date</Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" gap={1}>
          <CryptoIcon icon="osmo" sx={{ img: { width: 20, height: 20 } }} />
          {formatAmount(totalIncentivesSavedToDate, { notation: "standard" })}
        </Box>
      </Box>
    </Box>
  </Box>
);

export default TotalIncentivesCards;
