import { FilterPicker, FilterRange } from "./table-filter";

type MakeFilterProps = {
  title: string;
  fieldName: string;
};

export type MakePickerFilterProps = MakeFilterProps & {
  options: {
    name: string;
    value: string;
    customIcon?: string;
    cryptoIcon?: string;
  }[];
  isAutocomplete?: boolean;
};

export type MakeRangeFilterProps = MakeFilterProps & {
  min: number;
  max: number;
  range: [number, number];
};

export const makeRangeFilter = ({ title, fieldName, min, max, range }: MakeRangeFilterProps): FilterRange => ({
  type: "range",
  title,
  fieldName,
  fieldIndex: 0,
  isApplied: false,
  range,
  min,
  max,
});

export const makePickerFilter = ({
  title,
  fieldName,
  options,
  isAutocomplete,
}: MakePickerFilterProps): FilterPicker => ({
  type: "options",
  title,
  fieldName,
  fieldIndex: 0,
  isApplied: false,
  options: options.map((o) => ({ ...o, isHidden: true })),
  isAutocomplete,
});
