import { useMemo } from "react";
import {
  LiquidationsOverviewQueryVariables,
  useLiquidationsOverviewQuery,
  useLiquidationsDailyPerAssetQuery,
  useLiquidationsEventsQuery,
  LiquidationsEvent,
  LiquidationsDailyPerAsset,
  TimeSpan,
  useBadDebtHistoryQuery,
  BadDebtHistory,
  LiquidationsOverview,
} from "../../generated";

export type LiquidationsData = {
  liquidationsOverview: { isLoading: boolean; data?: LiquidationsOverview };
  liquidations: { isLoading: boolean; data?: LiquidationsDailyPerAsset[] };
  liquidationsEvents?: { isLoading: boolean; data: LiquidationsEvent[] };
  badDebtData?: BadDebtHistory[];
  isLoadingBadDebt: boolean;
};

export const useLiquidationsData = ({
  daysAgo,
  timeSpan,
  marketIds,
  assets,
}: LiquidationsOverviewQueryVariables & { timeSpan: TimeSpan }): { data: LiquidationsData } => {
  const liquidationsQueryVariables = useMemo(() => ({ daysAgo, marketIds, assets }), [daysAgo, marketIds, assets]);

  const { loading: isLoadingOverviewData, data: overviewDataResponse } = useLiquidationsOverviewQuery({
    variables: liquidationsQueryVariables,
  });
  const liquidationsOverviewData = overviewDataResponse ? overviewDataResponse.liquidationsOverview : undefined;

  const { loading: isLoadingLiquidationsDailyPerAssetData, data: LiquidationsDailyPerAssetDataResponse } =
    useLiquidationsDailyPerAssetQuery({
      variables: liquidationsQueryVariables,
    });

  const { loading: isLoadingLiquidationEventsData, data: LiquidationsEventsDataResponse } = useLiquidationsEventsQuery({
    variables: liquidationsQueryVariables,
  });

  const liquidationsEventsArray: LiquidationsEvent[] = LiquidationsEventsDataResponse
    ? LiquidationsEventsDataResponse.liquidationsEvents
    : [];

  const { loading: isLoadingBadDebt, data: badDebtData } = useBadDebtHistoryQuery({
    variables: {
      timeSpan,
    },
  });

  return {
    data: {
      liquidationsOverview: {
        isLoading: isLoadingOverviewData,
        data: liquidationsOverviewData,
      },
      liquidations: {
        isLoading: isLoadingLiquidationsDailyPerAssetData,
        data: LiquidationsDailyPerAssetDataResponse?.liquidationsDailyPerAsset,
      },
      liquidationsEvents: {
        isLoading: isLoadingLiquidationEventsData,
        data: liquidationsEventsArray,
      },
      badDebtData: badDebtData?.badDebtHistory,
      isLoadingBadDebt,
    },
  };
};
