import { Box, Typography } from "@frontend/ui";
import { CustomTooltipFunc } from "@frontend/ui/echarts/types";
import { DefaultLabelFormatterCallbackParams } from "echarts";
import { Divider } from "@mui/material";
import { HistoryData } from "../../../../generated";

export const getOiChartCustomTooltip =
  (shortInterestUsd?: HistoryData[], longInterestUsd?: HistoryData[]): CustomTooltipFunc =>
  (params: DefaultLabelFormatterCallbackParams[], valueFormatter, showPercentOfTotal) => {
    const timestamp = (params[0].value as [number, number])[0];
    const avgShortOpenInterest = shortInterestUsd?.find((d) => d.time === timestamp)?.average;
    const avgLongOpenInterest = longInterestUsd?.find((d) => d.time === timestamp)?.average;
    const total = params.reduce((sum, { value }) => sum + (Array.isArray(value) ? Number(value[1]) : Number(value)), 0);

    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <Box>
          {!showPercentOfTotal && (
            <Box display="flex" gap={1} alignItems="baseline">
              <Typography variant="caption" color="#9B9DA1">
                Total:
              </Typography>
              <Typography variant="caption">{valueFormatter?.(total)}</Typography>
            </Box>
          )}
          {params.map((param) => {
            const { seriesId, seriesName = "", value, color } = param;

            return (
              <Box key={seriesId} display="flex" gap={1} alignItems="baseline">
                <Box bgcolor={color?.toString()} width={8} height={8} borderRadius="50%" />
                <Typography variant="caption" color="#9B9DA1">
                  {seriesName}:
                </Typography>
                <Typography variant="caption">
                  {valueFormatter?.(Array.isArray(value) ? Number(value[1]) : Number(value))}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Divider sx={{ bgcolor: "#9B9DA1" }} />
        {!!avgShortOpenInterest && !!avgLongOpenInterest && (
          <Box>
            <Box display="flex" gap={1} alignItems="baseline">
              <Typography variant="caption" color="#9B9DA1">
                Daily Average Long:
              </Typography>
              <Typography variant="caption">
                {valueFormatter?.(
                  showPercentOfTotal
                    ? avgLongOpenInterest / (avgShortOpenInterest + avgLongOpenInterest)
                    : avgLongOpenInterest,
                )}
              </Typography>
            </Box>
            <Box display="flex" gap={1} alignItems="baseline">
              <Typography variant="caption" color="#9B9DA1">
                Daily Average Short:
              </Typography>
              <Typography variant="caption">
                {valueFormatter?.(
                  showPercentOfTotal
                    ? avgShortOpenInterest / (avgShortOpenInterest + avgLongOpenInterest)
                    : avgShortOpenInterest,
                )}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  };
