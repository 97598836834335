import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { useDataFetchersQuery } from "src/hooks/useDataFetchersQuery";
import { Client } from "../../types";
import { MintHistogramDataType, MintsOverTimeData, OverviewChartsStats } from "./types";
import {
  Chain,
  ChainAssetFragment,
  ChainResults,
  TimeSpan,
  useHistoryStatsQuery,
  useHistoryStatsTvlQuery,
  useMarketAssetsBreakdownQuery,
} from "../../generated";
import { getHistoryStatsChartData } from "./get-history-stats-charts-data";
import { clientsConfig, useClientConfig } from "../../clients-config";

type ReturnType = {
  isLoading: boolean;
  chartsStats?: OverviewChartsStats;
};

export const useChartStats = (timeSpan?: TimeSpan, chain?: Chain): ReturnType => {
  const { clientName } = useParams<{ clientName: Client }>();
  const { isMultiMarket, isMultichain, marketType } = useClientConfig();
  const { endpointsPrefix, overview } = clientsConfig[clientName!];

  const showChainBreakdown = chain ? false : isMultichain && ["ChainMarket", "GeneralMarket"].includes(marketType);

  const { loading: isLoadingHistoryStatsTvl, data: historyStatsTvlResponse } = useHistoryStatsTvlQuery({
    variables: {
      timeSpan: timeSpan || null,
    },
    skip: !!chain || !overview?.charts.includes("historyStatsTvl"),
  });

  const { loading: isLoadingHistoryStats, data: historyStatsResponse } = useHistoryStatsQuery({
    variables: {
      chain: chain || null,
      market: null,
      marketId: null,
      timeSpan: timeSpan || null,
      tokenSymbol: null,
      withMarkets: false,
      includeAssetsBreakDown: !showChainBreakdown,
    },
    skip: !overview?.charts.includes("chainHistoryStats"),
  });

  const historyStats = historyStatsResponse?.chainHistoryStats;

  const getChartData = useCallback(
    (fields: { chainField?: keyof ChainResults; assetField?: keyof ChainAssetFragment }) => {
      if (!historyStats) return {};

      const showChainCharts = !fields.assetField || showChainBreakdown;

      return getHistoryStatsChartData(
        historyStats,
        showChainCharts ? fields.chainField : undefined,
        fields.assetField,
        isMultiMarket,
      );
    },
    [historyStats, isMultiMarket, showChainBreakdown],
  );

  const totalLiquidity = getChartData({
    chainField: "totalLiquidityUsd",
    assetField: "totalSupplyUsd",
  });

  const totalBorrow = getChartData({
    chainField: "totalBorrowUsd",
    assetField: "totalBorrowUsd",
  });

  const collateralAtRisk = getChartData({
    chainField: "totalValueAtRisk",
    assetField: "valueAtRisk",
  });

  const totalBadDebtUsd = getChartData({
    chainField: "totalBadDebtUsd",
  });

  const valueAvailableForLiquidationUsd = getChartData({
    chainField: "valueAvailableForLiquidationUsd",
    assetField: "valueEligibleForLiquidation",
  });

  const walletsAtRisk = getChartData({
    chainField: "walletsAtRisk",
  });

  const walletsEligibleForLiquidation = getChartData({
    chainField: "walletsEligibleForLiquidation",
  });

  const tvl = getChartData({ chainField: "chainTvlUsd", assetField: "tvl" });

  const historyStatsTvl = historyStatsTvlResponse
    ? historyStatsTvlResponse?.chainHistoryTvl.map(({ date, result }) => ({ x: Number(date) * 1000, y: result }))
    : undefined;

  const shouldFetchAssetBreakdown = overview?.charts.some((chart) => ["supplyAndBorrowDistribution"].includes(chart));

  const { loading: isLoadingMarketsAssetsBreakdown, data: marketsAssetsBreakdownResponse } =
    useMarketAssetsBreakdownQuery({ skip: !!chain || !shouldFetchAssetBreakdown });

  const supplyDistribution = marketsAssetsBreakdownResponse?.assetsBreakdown
    .filter(({ isCollateralizable }) => isCollateralizable)
    .map((item) => ({
      name: String(item?.asset),
      value: Number(item?.totalSupply),
    }));

  const borrowDistribution = marketsAssetsBreakdownResponse?.assetsBreakdown
    .filter(({ isBorrowable }) => isBorrowable)
    .map((item) => ({
      name: String(item?.asset),
      value: Number(item?.totalBorrow),
    }));

  const { isLoading: isLoadingMintHistogram, response: mintHistogramResponse } = useDataFetchersQuery<{
    data: MintsOverTimeData;
  }>(
    `${endpointsPrefix}.mint_histogram`,
    "offchain",
    undefined,
    !!chain || !overview?.charts.includes("mintHistogram"),
  );

  const mintHistogram: MintHistogramDataType | undefined = mintHistogramResponse
    ? Object.entries(mintHistogramResponse?.data?.buckets || {}).reduce(
        (acc: MintHistogramDataType, [date, tokensValues]: [string, { [token: string]: string }]) => {
          const newAcc = { ...acc };
          Object.entries(tokensValues).forEach(([token, value]) => {
            const newHistogramValue = { x: date, y: Number(value) || 0 };
            newAcc[token] = [...(newAcc[token] || []), newHistogramValue];
          });
          return newAcc;
        },
        {} as MintHistogramDataType,
      )
    : undefined;

  const isLoading =
    isLoadingHistoryStats || isLoadingHistoryStatsTvl || isLoadingMarketsAssetsBreakdown || isLoadingMintHistogram;

  const chartsStats = {
    historyStatsTvl,
    totalLiquidity,
    totalBorrow,
    tvl,
    supplyDistribution,
    borrowDistribution,
    mintHistogram,
    collateralAtRisk,
    totalBadDebtUsd,
    valueAvailableForLiquidationUsd,
    walletsAtRisk,
    walletsEligibleForLiquidation,
  };

  return {
    isLoading,
    chartsStats,
  };
};
