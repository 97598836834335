import { FC, useState } from "react";

import { Box } from "@frontend/ui/box";
import { Button } from "@frontend/ui/button";
import { Typography } from "@frontend/ui/typography";
import { InputLabel } from "@frontend/ui/input-label";
import { TextField } from "@frontend/ui/text-field";

interface DyDxCalculatorModalProps {
  name: string;
  onClose: () => void;
  onRemove: () => void;
  onSave: (name: string) => void;
}

export const DyDxCalculatorModal: FC<DyDxCalculatorModalProps> = ({ name, onClose, onRemove, onSave }) => {
  const [calculationName, setCalculationName] = useState(name);
  return (
    <>
      <Box component="header">
        <Box className="modal-header" sx={{ py: "36px" }}>
          <Typography variant="h2">Edit Calculation</Typography>
        </Box>
      </Box>

      <Box className="modal-body">
        <InputLabel
          sx={{
            flexDirection: "column",
            gap: 1,
            m: 0,
          }}
        >
          Calculation Name
          <TextField
            placeholder="Calculation Name"
            value={calculationName}
            onChange={(e) => setCalculationName(e.target.value)}
            fullWidth
          />
        </InputLabel>
      </Box>

      <Box component="footer">
        <Box
          className="modal-footer"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            gap: 3,
            py: 5,
            "& button": { width: { xs: "100%", sm: "initial" } },
          }}
        >
          <Button color="secondary" onClick={onRemove}>
            Remove Calculation
          </Button>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => onSave(calculationName)}>
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};
