const chainToBlockExplorerUrlMapping: { [chain: string]: string } = {
  Arbitrum_Goerli: "https://goerli.arbiscan.io/",
  Arbitrum_Sepolia: "https://sepolia.arbiscan.io/",
  Arbitrum: "https://arbiscan.io/",
  Avalanche: "https://snowtrace.io/",
  BNB: "https://bscscan.com/",
  Binance: "https://bscscan.com/",
  Fantom: "https://ftmscan.com/",
  Harmony: "https://explorer.harmony.one/",
  Optimism: "https://optimistic.etherscan.io/",
  Polygon: "https://polygonscan.com/",
  Ethereum: "https://etherscan.io/",
  Goerli: "https://goerli.etherscan.io/",
  Metis: "https://andromeda-explorer.metis.io/",
  Base: "https://base.blockscout.com/",
  Evmos: "https://escan.live/",
  Gnosis: "https://gnosisscan.io/",
  dYdXV4: "https://www.mintscan.io/dydx/",
  ZkSync: "https://explorer.zksync.io/",
  Manta: "https://pacific-explorer.manta.network/",
  Scroll: "https://scrollscan.com/",
  Blast: "https://blastscan.io/",
  Lina: "https://lineascan.build/",
  opBNB: "https://opbnbscan.com/",
  Solana: "https://solscan.io/",
};

export const getBlockDetailsUrl = (chain: string, block: string) =>
  `${chainToBlockExplorerUrlMapping[chain]}block/${block}`;
export const getTxDetailsUrl = (chain: string, txHash: string) =>
  `${chainToBlockExplorerUrlMapping[chain]}tx/${txHash}`;
export const getAccountDetailsUrl = (chain: string, accountAddress: string) =>
  `${chainToBlockExplorerUrlMapping[chain]}address/${accountAddress}`;
