import { Box } from "@frontend/ui";
import { GlvCompositionTable } from "./components";

const GlvDetails = () => (
  <Box>
    <GlvCompositionTable />
  </Box>
);

export default GlvDetails;
