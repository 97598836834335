import { RenderData } from "@frontend/types";
import { assetCell, currencyCell, numberCell } from "@frontend/ui";
import { MarketData, Token } from "src/pages/ccar-lending-page/generated";
import { MarketType } from "src/pages/ccar-lending-page/clients-config/types";
import { getMarketSymbols } from "../../../markets/use-market-symbols";

export const getCommonTableData = (
  position: Token,
  marketType: MarketType,
  isMultiMarket: boolean,
  markets: MarketData[],
  showChain?: boolean,
): RenderData[] => {
  const { marketSymbol1, marketSymbol2 } = getMarketSymbols(position.market, marketType);
  const marketData = markets.find((market) => market.id === position.market.id);

  return [
    ...(showChain
      ? [
          {
            renderType: "TEXT",
            text: marketData?.chain || "",
            token1: marketData?.chain,
          } as RenderData,
        ]
      : []),
    ...(isMultiMarket
      ? [
          {
            renderType: "TEXT",
            text: marketData?.name || "",
            ...(marketSymbol2 && marketSymbol1
              ? {
                  tooltipText: `${marketSymbol2} | ${marketSymbol1}`,
                  token1: marketSymbol2,
                  token2: marketSymbol1,
                }
              : marketSymbol1 && {
                  text: marketSymbol1,
                  token1: marketSymbol1,
                }),
          } as RenderData,
        ]
      : []),
    assetCell(position.assetSymbol),
  ];
};

export const getSupplyTableData = (
  positions: Token[],
  marketType: MarketType,
  isMultiMarket: boolean,
  markets: MarketData[],
  showChain?: boolean,
): RenderData[][] =>
  positions.map((position) => [
    ...getCommonTableData(position, marketType, isMultiMarket, markets, showChain),
    numberCell(position.supplyAmount || 0),
    currencyCell(position.supplyAmountInUsd || 0),
  ]);

export const getCollateralAndBorrowTableData = (
  positions: Token[],
  marketType: MarketType,
  isMultiMarket: boolean,
  markets: MarketData[],
  showChain?: boolean,
): RenderData[][] =>
  positions.map((position) => [
    ...getCommonTableData(position, marketType, isMultiMarket, markets, showChain),
    numberCell(position.collateralAmount || 0),
    numberCell(position.borrowAmount || 0),
    currencyCell(position.collateralAmountInUsd || 0),
    currencyCell(position.borrowAmountInUsd || 0),
  ]);
