import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { LiquidationsOverviewFragment } from "../../../generated";

type LiquidationsCardsProps = {
  overview?: LiquidationsOverviewFragment;
};

export const LiquidationsCards = ({ overview }: LiquidationsCardsProps) => {
  const cards: ValueCardProps[] = [
    {
      title: "Short Liquidations",
      value: overview?.shortLiquidationsCount,
    },
    {
      title: "Short Liquidated Value",
      value: overview?.shortLiquidationValueUSD,
      currency: "USD",
    },
    {
      title: "Long Liquidations",
      value: overview?.longLiquidationsCount,
    },
    {
      title: "Long Liquidated Value",
      value: overview?.longLiquidationValueUSD,
      currency: "USD",
    },
  ];

  return (
    <Grid container>
      {cards.map((card) => (
        <Grid key={card.title} item xs={12} md={6} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};
