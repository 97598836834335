import { CompositionOverTimeChart } from "@frontend/ui";
import { PoolFragment, usePoolHistoryQuery } from "src/pages/ccar-lending-page/generated";
import { poolsCompositionChartColors } from "src/pages/ccar-lending-page/tabs/overview/components";

type Props = {
  pool: PoolFragment;
  poolAddress: string;
};

const PoolCompositionChart = ({ pool, poolAddress }: Props) => {
  const { loading, data } = usePoolHistoryQuery({
    variables: {
      address: poolAddress,
    },
  });
  const poolHistory = data?.poolHistory;

  const compositionChartSeries = pool.tokens.map((token, i) => ({
    label: token.symbol,
    color: poolsCompositionChartColors[i],
    data: (poolHistory || []).map((h) => {
      const tokenData = h.pool.tokens.find((t) => t.address === token.address);
      const { balance, usdPrice } = tokenData || {};
      const tokenBalanceUsd = balance ? Number(usdPrice) * Number(balance) : 0;
      return [h.timestamp, tokenBalanceUsd] as [number, number];
    }),
  }));

  return (
    <CompositionOverTimeChart title="Pool Composition Over Time" isLoading={loading} series={compositionChartSeries} />
  );
};

export default PoolCompositionChart;
