import { FC } from "react";
import usePagination, { UsePaginationProps } from "@mui/material/usePagination";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styled from "@emotion/styled";
import { Button } from "../button";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: 8,
  userSelect: "none",
});

export const Pagination: FC<UsePaginationProps> = ({ count = 10, page, onChange, boundaryCount = 1 }) => {
  const { items } = usePagination({
    count,
    page,
    onChange,
    siblingCount: 3,
    boundaryCount,
  });

  return (
    <nav>
      <List>
        {items.map(({ page: pageNumber, type, selected, ...item }, index) => {
          let children = null;

          if (boundaryCount > 0 && (type === "start-ellipsis" || type === "end-ellipsis")) {
            children = "…";
          }

          if (type === "page") {
            children = (
              <Button
                variant="outlined"
                sx={{
                  p: 0,
                  minWidth: 32,
                  width: 32,
                  height: 32,
                  ...(selected
                    ? {
                        borderColor: "primary.main",
                        backgroundColor: "primary.main",
                        "&:hover": {
                          backgroundColor: "primary.hover",
                        },
                      }
                    : {}),
                }}
                {...item}
              >
                {pageNumber}
              </Button>
            );
          }

          if (type === "previous" || type === "next") {
            const isPrev = type === "previous";
            children = (
              <Button
                variant="outlined"
                size="small"
                startIcon={isPrev && <ChevronLeftIcon />}
                endIcon={!isPrev && <ChevronRightIcon />}
                sx={{
                  p: 0,
                  pr: isPrev ? 1 : 0,
                  pl: !isPrev ? 1 : 0,
                  height: 32,
                }}
                {...item}
              >
                {isPrev ? "Prev" : "Next"}
              </Button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
};
