import { formatAmount } from "../utils/formatters";

export const formatCardValue = (
  value: number,
  isPercent?: boolean,
  currency?: string,
  isFullValue?: boolean,
  maximumFractionDigits?: number,
  capAtValue?: number,
) =>
  `${capAtValue && value < capAtValue && value ? "<" : ""}${formatAmount(
    capAtValue && value ? Math.max(value, capAtValue) : value,
    {
      currency,
      notation: isFullValue ? "standard" : "compact",
      isPercent,
      maximumFractionDigits,
    },
  )}`;
