import { CopyableText, Drawer } from "@frontend/ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePoolQuery } from "src/pages/ccar-lending-page/generated";
import { Loader } from "@frontend/ui/loader";
import { getAccountDetailsUrl } from "@frontend/ui/utils/chain-url-mapper";
import PoolDetails from "./pool-details";

const PoolDetailsDrawer = () => {
  const { poolId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { data } = usePoolQuery({
    variables: {
      address: poolId!,
    },
  });

  return (
    <Drawer
      title={data?.pool?.name || ""}
      subtitle={
        <CopyableText
          text={data?.pool?.address || ""}
          href={data?.pool ? getAccountDetailsUrl(data.pool.chain, data.pool.address) : undefined}
        />
      }
      anchor="right"
      open
      onClose={() => {
        navigate(location.pathname.replace(`/${poolId!}`, ""));
      }}
    >
      {data ? <PoolDetails pool={data.pool} /> : <Loader />}
    </Drawer>
  );
};

export default PoolDetailsDrawer;
