import React, { ReactNode } from "react";
import { CommunityDashboardLayout } from "src/components/layouts";
import { useClientConfig } from "../clients-config";

export const CCARTabsLayout: React.FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const { overview, liquidations, marketDetails, assetDetails, wallets, riskExplorer, stableCoin, alerts, reserves } =
    useClientConfig();

  return (
    <CommunityDashboardLayout
      tabs={[
        ...(overview ? [{ label: "Overview" }] : []),
        ...(marketDetails ? [{ label: "Markets" }] : []),
        ...(assetDetails ? [{ label: "Assets" }] : []),
        ...(wallets ? [{ label: "Wallets" }] : []),
        ...(liquidations ? [{ label: "Liquidations" }] : []),
        ...(riskExplorer ? [{ label: "Risk Explorer" }] : []),
        ...(reserves ? [{ label: "Reserves" }] : []),
        ...(stableCoin
          ? [
              {
                label: stableCoin.asset,
                badge: stableCoin.beta ? "Beta" : undefined,
              },
            ]
          : []),
        ...(alerts?.enabled ? [{ label: "Alerts" }] : []),
      ]}
    >
      {children}
    </CommunityDashboardLayout>
  );
};
