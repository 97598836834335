import { ValueOverTimeChart } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { palette } from "@frontend/ui/theme/palette";
import { OverviewChartsStats } from "../types";

type Props = {
  data?: OverviewChartsStats["historyStatsTvl"];
  isLoading?: boolean;
  timeSpan?: TimeSpan;
};

const TvlOverTimeChart = ({ data, isLoading, timeSpan }: Props) => (
  <ValueOverTimeChart
    isLoading={isLoading}
    series={[
      {
        label: "TVL",
        data: (data || []).map((c) => [c.x, c.y]),
        color: palette.aqua.main,
      },
    ]}
    title="Total Value Locked"
    showLegend={false}
    timeSpan={timeSpan}
  />
);

export default TvlOverTimeChart;
