import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useClientConfig } from "src/pages/ccar-perpetuals-page/clients-config";
import { useFeatureFlag } from "src/utils/feature-flags";
import { RouteParams, RoutePath } from "src/config/routes";
import { AccountAnalysis, Overview } from "../tabs";
import Layout from "./layout";

const AccountRoutes = () => {
  const { clientName } = useClientConfig();
  const { address, subAccount } = useParams();
  const positionAnalysisEnabled = useFeatureFlag("perpetuals-positions-analysis");

  return (
    <Routes>
      <Route
        index
        element={
          <Navigate
            to={
              subAccount
                ? RoutePath.Risk.SubAccountPositionsOverview.replace(RouteParams.Address, address!)
                    .replace(RouteParams.SubAccount, subAccount)
                    .replace(RouteParams.ClientName, clientName)
                : RoutePath.Risk.AccountPositionsOverview.replace(RouteParams.Address, address!).replace(
                    RouteParams.ClientName,
                    clientName,
                  )
            }
            replace
          />
        }
      />
      {[true, false].map((isSubAccount) => {
        const basePath = isSubAccount ? RoutePath.Risk.SubAccountPositions : RoutePath.Risk.AccountPositions;
        const overviewPath = isSubAccount
          ? RoutePath.Risk.SubAccountPositionsOverview
          : RoutePath.Risk.AccountPositionsOverview;

        return (
          <>
            <Route
              path={overviewPath.replace(basePath, "")}
              element={
                <Layout>
                  <Overview />
                </Layout>
              }
            />
            {positionAnalysisEnabled && (
              <Route
                path={RoutePath.Risk.AccountPositionsAnalysis.replace(basePath, "")}
                element={
                  <Layout>
                    <AccountAnalysis />
                  </Layout>
                }
              />
            )}
          </>
        );
      })}
    </Routes>
  );
};
export default AccountRoutes;
