import { Controller, useFormContext, useWatch } from "react-hook-form";
import { CustomReactSelect, Grid } from "@frontend/ui";
import { AlertSettingsForm } from "./types";
import { CcarClient } from "../../alerts/utils";
import { ClientConfig } from "../utils";
import { EvaluationSchemaInput } from "./evaluation-schema-input";

interface QueryConfigurationSectionProps {
  submitting: boolean;
  isNewAlert?: boolean;
}

export const QueryConfigurationSection = ({ submitting, isNewAlert }: QueryConfigurationSectionProps) => {
  const { control, setValue, resetField } = useFormContext<AlertSettingsForm>();

  const [clientsConfig, runnerType] = useWatch({
    control,
    name: ["clientsConfig", "runnerType"],
  }) as [
    Partial<Record<CcarClient, ClientConfig>>,
    {
      label: string;
      value: string;
    },
  ];

  const handleRunnerTypeChange = (selectedRunnerType?: string) => {
    setValue(
      "clientsConfig",
      Object.entries(clientsConfig ?? {}).reduce(
        (acc, [client, config]) => {
          acc[client as CcarClient] = {
            ...config,
            overrideFetchInputs: undefined,
            overrideFilterInputs: undefined,
          };
          return acc;
        },
        {} as Partial<Record<CcarClient, ClientConfig>>,
      ),
    );

    if (selectedRunnerType === "default-runner") {
      resetField("evaluateStep");
      resetField("filterStep");
    } else if (selectedRunnerType === "dune-runner") {
      setValue("evaluateStep", {
        argumentsSchema: {
          type: "object",
          properties: {
            duneQueryId: {
              type: "number",
              title: "Dune Query ID",
              description: "The Dune Query ID",
            },
            queryEngine: {
              type: "string",
              title: "Dune Query Engine",
              description: "The dune query engine",
              enum: ["medium", "large"],
            },
            alertTitle: {
              type: "string",
              title: "Alert Title",
              description: "The alert title as a formatted string",
            },
            message: {
              type: "string",
              title: "Alert Message",
              description:
                "A formatted string providing detailed information about the alert, including dynamic data relevant to its context",
            },
            alertTriggerUniqueIdentifier: {
              type: "string",
              title: "Alert Trigger Unique Identifier",
              description:
                "A formatted string to get unique identifier for the alert trigger based on the results from dune",
            },
          },
          required: ["duneQueryId", "queryEngine", "alertTitle", "message", "alertTriggerUniqueIdentifier"],
        },
        defaultArguments: [undefined, "medium"],
      });
      setValue("filterStep", undefined);
    } else if (selectedRunnerType === "python-runner") {
      setValue("evaluateStep", {
        argumentsSchema: {
          type: "object",
          properties: {},
          required: [],
        },
        defaultArguments: [],
      });
      setValue("filterStep", undefined);
    }
  };

  const getHelperText = (runnerTypeVal?: string) => {
    switch (runnerTypeVal) {
      case "dune-runner":
        return "Ensure that the Dune Query ID is already set up and available.";
      case "python-runner":
        return "Ensure the alert logic is implemented and deployed to the environment.";
      default:
        return "Ensure the alert is implemented and deployed to the environment.";
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} md={6} xs={12}>
        <Controller
          name="runnerType"
          control={control}
          defaultValue={{
            label: "Default",
            value: "default-runner",
          }}
          render={({ field }) => (
            <CustomReactSelect
              controlShouldRenderValue
              label="Alert Runner"
              placeholder="Select alert runner"
              options={[
                {
                  label: "Default",
                  value: "default-runner",
                },
                {
                  label: "Dune Analytics",
                  value: "dune-runner",
                },
                {
                  label: "Python script",
                  value: "python-runner",
                },
              ]}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                handleRunnerTypeChange(value?.value);
              }}
              disabled={!isNewAlert || submitting}
              helperText={isNewAlert ? getHelperText(field?.value?.value) : undefined}
            />
          )}
        />
      </Grid>
      {runnerType?.value !== "dune-runner" && (
        <Grid item lg={4} md={6} xs={12} spacing={1}>
          <EvaluationSchemaInput submitting={submitting} isNewAlert={isNewAlert} />
        </Grid>
      )}
    </Grid>
  );
};
