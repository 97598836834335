import { PageHeader } from "@frontend/ui/page-header";
import { Methodology } from "./types";

type Props = {
  methodology: Methodology;
};

const MethodologyPageHeader = ({ methodology }: Props) => <PageHeader pageTitle={methodology.methodologyTitle} />;

export default MethodologyPageHeader;
