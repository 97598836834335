import { Box, Button, Typography } from "@frontend/ui";
import { Theme, useMediaQuery } from "@frontend/ui/theme";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useAuth } from "@frontend/ui/auth";
import imgChaosCommunityDashboardLogoHeader from "src/assets/img/chaos-community-analytics-logo.svg";
import { ReactNode } from "react";
import { ClientLogo } from "../client-logo";
import { useProtocolSwitcherContext } from "../protocol-switcher";
import { UserInfoCard } from "../user-info-card";
import { CHAOS_LABS_MEMBER_ROLE } from "../../utils/consts";
import { RoutePath } from "../../config/routes";

export type CommunityDashboardHeaderProps = {
  homePath: string;
  shareText?: string;
  shareHashtags?: string[];
  buttonsPrefix?: ReactNode;
  hideLoginButton?: boolean;
};

export const CommunityDashboardHeader = ({
  homePath,
  shareText,
  shareHashtags,
  buttonsPrefix,
  hideLoginButton,
}: CommunityDashboardHeaderProps) => {
  const auth = useAuth();
  const { shouldShowProtocolSwitcher } = useProtocolSwitcherContext();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      alignItems="center"
      position="relative"
      zIndex={20}
      py={2}
      bgcolor="greys.900"
      width="100%"
    >
      <Box
        display="flex"
        flex="1"
        flexWrap="wrap"
        alignItems="center"
        maxWidth="1328px"
        mx="auto"
        px={{
          xs: 3,
          lg: 8,
        }}
        justifyContent="space-between"
        width="100%"
      >
        <Box
          flex={1}
          sx={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Typography
            variant="link"
            component="a"
            href={homePath}
            rel="noreferrer"
            display="flex"
            alignItems="center"
            gap={2}
          >
            <img style={{ display: "block" }} src={imgChaosCommunityDashboardLogoHeader} alt="header" />
            {!isMobile && (
              <>
                <Typography variant="label">/</Typography>
                <Typography variant="label" whiteSpace="nowrap">
                  Community Analytics
                </Typography>
              </>
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            flexGrow: shouldShowProtocolSwitcher ? 1 : 0,
            display: "flex",
            justifyContent: isMobile ? "flex-end" : "center",
          }}
        >
          <ClientLogo {...{ homePath, isMobile, shouldShowProtocolSwitcher }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            ...(!isMobile && { flex: 1 }),
            ...(isMobile && { marginLeft: "4px" }),
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 5,
            }}
          >
            {!isMobile && (
              <>
                {buttonsPrefix}
                <Typography
                  variant="link"
                  display="flex"
                  alignItems="center"
                  onClick={() => {
                    const url = `https://twitter.com/share?ref_src=twsrc%5Etfw&url=${encodeURIComponent(
                      window.location.href,
                    )}&text=${encodeURIComponent(shareText || document.title)}${
                      shareHashtags ? `&hashtags=${encodeURIComponent(shareHashtags.join(","))}` : ""
                    }`;
                    window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600");
                  }}
                >
                  Share
                </Typography>
                <Typography
                  variant="link"
                  component="a"
                  href="https://chaoslabs.xyz/contact-us"
                  target="_blank"
                  rel="noreferrer"
                  display="flex"
                  alignItems="center"
                  whiteSpace="nowrap"
                >
                  Contact Us
                </Typography>
              </>
            )}
            {!hideLoginButton && (
              <UserInfoCard>
                {auth.hasRole(CHAOS_LABS_MEMBER_ROLE) && (
                  <Button
                    size="small"
                    variant="link"
                    onClick={() => {
                      const fullUrl = `${window.location.origin}${RoutePath.AlertsPortal.Home}`;
                      window.open(fullUrl, "_blank");
                    }}
                    startIcon={<NotificationsActiveIcon />}
                    fullWidth
                    sx={{
                      justifyContent: "flex-start",
                    }}
                  >
                    Alerts Portal
                  </Button>
                )}
              </UserInfoCard>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
