import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

type PropsType = {
  onChange: (event: { target: { value: string } }) => void;
};

const NumberFormatInput = forwardRef((props: PropsType, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange?.({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

export default NumberFormatInput;
