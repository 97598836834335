import { Box, Paper, Typography } from "@frontend/ui";
import { ChaosApolloProvider } from "src/utils/graphql";
import { OnChainLiquidityCharts } from "src/components/on-chain-liquidity/on-chain-liquidity-charts";
import AssetBorrowAndSupply from "./components/asset-borrow-and-supply";
import { TopWallets } from "../ccar-lending-page/components/top-wallets";
import { Chain } from "../ccar-lending-page/generated";
import ProtocolReserves from "./components/protocol-reserves";
import Liquidations from "../ccar-lending-page/tabs/liquidations/liquidations";

const BenqiReportPage = () => (
  <Box
    maxWidth={1320}
    margin="auto"
    minHeight="100vh"
    paddingBottom={3}
    display="flex"
    flexDirection="column"
    alignItems="center"
    gap={3}
  >
    <Typography variant="h1" marginTop={3} mb={1}>
      Benqi Report
    </Typography>
    <ChaosApolloProvider product="ccar-lending">
      <Typography variant="h2" mb={1}>
        Liquidations
      </Typography>
      <Liquidations />
      <Typography variant="h2" mb={1}>
        Supply & Borrow Per Asset
      </Typography>
      <AssetBorrowAndSupply />
      <Typography variant="h2" mb={1}>
        Top Borrowers
      </Typography>
      <Paper variant="card" sx={{ width: "100%" }}>
        <TopWallets chain={Chain.Avalanche} />
      </Paper>
      <Typography variant="h2" mb={1} mt={2}>
        Liquidity
      </Typography>
      <Paper variant="card" sx={{ width: "100%" }}>
        <OnChainLiquidityCharts token="QI" compareToken="AVAX" chain="Avalanche" />
      </Paper>
      <Typography variant="h2" mb={1} mt={2}>
        Reserves
      </Typography>
      <Paper variant="card" sx={{ width: "100%" }}>
        <ProtocolReserves />
      </Paper>
    </ChaosApolloProvider>
  </Box>
);

export default BenqiReportPage;
