import { CompositionOverTimeChart, CryptoIcon, CustomSwitch, Grid, Paper, ValueOverTimeChart } from "@frontend/ui";
import { palette } from "@frontend/ui/theme/palette";
import { FullPoolFragment } from "src/pages/lp-dashboard-page/generated";
import { useState } from "react";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { CorrelationChart } from "../../pools/components";

type Props = {
  data: FullPoolFragment;
};

const PoolCharts = ({ data }: Props) => {
  const [isValuesInUSD, setIsValuesInUSD] = useState(true);

  return (
    <Paper variant="card">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="widget">
            <CorrelationChart
              incentives={data.incentivesUSDHistory || []}
              liquidity={data.liquidityUSDHistory || []}
              volume={data.volumeUSDHistory || []}
              isElasticity
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="widget">
            <CorrelationChart
              incentives={data.incentivesUSDHistory || []}
              liquidity={data.liquidityUSDHistory || []}
              volume={data.volumeUSDHistory || []}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper variant="widget">
            <CompositionOverTimeChart
              title="Liquidity (TVL)"
              series={[
                {
                  label: "Liquidity",
                  data: data.liquidityUSDHistory?.map((d) => [d.time, d.value]) || [],
                  color: palette.blue.main,
                },
              ]}
              showLegend={false}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper variant="widget">
            <ValueOverTimeChart
              title="Incentives"
              series={[
                {
                  label: "Incentives",
                  type: "bar",
                  data:
                    (isValuesInUSD ? data.incentivesUSDHistory : data.incentivesHistory)?.map((d) => [
                      d.time,
                      d.value,
                    ]) || [],
                  color: palette.blue.main,
                },
              ]}
              currency={isValuesInUSD ? "USD" : ""}
              showLegend={false}
              headerSuffix={
                <CustomSwitch
                  onChange={() => setIsValuesInUSD((prev) => !prev)}
                  checked={isValuesInUSD}
                  checkedIcon="dollar"
                  uncheckedIcon={<CryptoIcon icon={wrappedSymbolToIconSymbol("OSMO")} />}
                  labelsVariant="h4"
                />
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper variant="widget">
            <CompositionOverTimeChart
              title="Volume (USD)"
              series={[
                {
                  label: "Volume",
                  data: data.volumeUSDHistory?.map((d) => [d.time, d.value]) || [],
                  color: palette.blue.main,
                },
              ]}
              showLegend={false}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper variant="widget">
            <CompositionOverTimeChart
              title="Fees (USD)"
              series={[
                {
                  label: "Fees",
                  data: data.feesUSDHistory?.map((d) => [d.time, d.value]) || [],
                  color: palette.green.main,
                },
              ]}
              showLegend={false}
            />
          </Paper>
        </Grid>
        {!!data.gammHistory?.length && (
          <Grid item xs={12}>
            <Paper variant="widget">
              <ValueOverTimeChart
                title="GAMM Over Time"
                series={[
                  {
                    type: "line",
                    label: "GAMM",
                    data: data.gammHistory?.map((d) => [d.time, d.value]) || [],
                    color: palette.blue.main,
                  },
                ]}
                showLegend={false}
                currency=""
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default PoolCharts;
