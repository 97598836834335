import type ReactECharts from "echarts-for-react";

export const handleLegendHover = (chartRef: React.RefObject<ReactECharts>, name: string, highlight: boolean) => {
  const actionType = highlight ? "highlight" : "downplay";
  chartRef.current?.getEchartsInstance().dispatchAction({
    type: actionType,
    name,
  });
};

export const handleLegendClick = (chartRef: React.RefObject<ReactECharts>, name: string) => {
  const chartInstance = chartRef.current?.getEchartsInstance();

  if (!chartInstance) return;

  chartInstance.dispatchAction({
    type: "highlight",
    name,
  });
};
