import { CompositionOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { palette } from "@frontend/ui/theme/palette";
import { HistoryDataFragment } from "src/pages/ccar-perpetuals-page/generated";

type Props = {
  loading: boolean;
  longSize?: HistoryDataFragment[];
  shortSize?: HistoryDataFragment[];
  currency?: string;
};

const getChartProps = (
  longSize: HistoryDataFragment[],
  shortSize: HistoryDataFragment[],
): { series: NumberChartSeries[] } => ({
  series: [
    {
      label: "Short",
      color: palette.red.main,
      type: "bar",
      data: shortSize.map((s) => [s.time, s.value]),
    },
    {
      label: "Long",
      color: palette.green.main,
      type: "bar",
      data: longSize.map((s) => [s.time, s.value]),
    },
  ],
});

export const AveragePositionSizeChart = ({ loading, longSize = [], shortSize = [], currency }: Props) => {
  const { series } = getChartProps(longSize, shortSize);

  return (
    <CompositionOverTimeChart
      title="Average Position Size"
      isLoading={loading}
      hideTotal
      series={series}
      showLegend={false}
      currency={currency}
    />
  );
};
