import { useState, useEffect } from "react";
import { ChartData } from "@frontend/types";
import { fillDashboardData } from "../utils/dashboards";
import { Loader } from "../loader";
import { Error } from "../error";
import { DashboardBuilder } from "./DashboardBuilder";

interface DashboardProps {
  dashboard: ChartData[][];
  name: string;
  description?: string;
  baseUrl: string;
}

export const Dashboard = ({ dashboard, name, description, baseUrl }: DashboardProps): JSX.Element => {
  const [filledDashboard, setFilledDashboard] = useState<ChartData[][]>();
  const [isReloading, setIsReloading] = useState(true);

  useEffect(() => {
    let isCanceled = false;

    setIsReloading(true);
    fillDashboardData(baseUrl, dashboard)
      .then((res) => {
        if (!isCanceled) {
          setFilledDashboard(res);
        }
      })
      .catch((e) => {
        console.log("Filled Dashboards failed...", e);
      })
      .finally(() => {
        setIsReloading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, [dashboard, baseUrl]);

  try {
    return isReloading || !filledDashboard ? (
      <Loader />
    ) : (
      <>{DashboardBuilder(filledDashboard, description, name, baseUrl)}</>
    );
  } catch (e) {
    console.error("Dashboard err:", e);
    return <Error />;
  }
};
