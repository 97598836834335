import { useParams } from "react-router-dom";
import { PoolsTab } from "../../../pools";

const AssetPools = () => {
  const { asset } = useParams();

  return <PoolsTab asset={asset!} />;
};

export default AssetPools;
