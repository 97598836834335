import { Box, ValueOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { useTraderStatsHistoryQuery } from "src/pages/ccar-perpetuals-page/generated";
import { getFilledTraderStats } from "./utils";

type Props = {
  account: string;
  marketAddress?: string;
  isLoading?: boolean;
};

const CumulativePnlChart = ({ account, marketAddress, isLoading }: Props) => {
  const { data, loading } = useTraderStatsHistoryQuery({
    variables: {
      account,
      marketAddress: marketAddress || null,
    },
    skip: isLoading,
  });

  const chartData = getFilledTraderStats(data?.traderStatsHistory || []).reduce<{
    seriesByMarket: Record<string, NumberChartSeries>;
    totalByTimeStamp: Record<number, number>;
  }>(
    (acc, p) => {
      const { seriesByMarket, totalByTimeStamp } = acc;
      const newDataPoint: [number, number] = [p.timestamp, p.totalPnlUsd];
      const newData = [...(seriesByMarket[p.asset]?.data || []), newDataPoint];

      const asset = p.asset.toUpperCase();

      if (asset === "PORTFOLIO") return acc;

      const newSeries: NumberChartSeries = {
        label: asset,
        data: newData,
        type: "line",
      };

      const newSeriesByMarket = { ...seriesByMarket, [p.asset]: newSeries };
      const newTotalByTimeStamp = {
        ...totalByTimeStamp,
        [p.timestamp]: (totalByTimeStamp[p.timestamp] || 0) + p.totalPnlUsd,
      };

      return {
        seriesByMarket: newSeriesByMarket,
        totalByTimeStamp: newTotalByTimeStamp,
      };
    },
    {
      seriesByMarket: {},
      totalByTimeStamp: {},
    },
  );

  const marketsSeries = Object.values(chartData.seriesByMarket);

  return (
    <ValueOverTimeChart
      isLoading={loading || isLoading}
      title="Cumulative Daily PnL"
      series={[
        ...(marketsSeries.length > 1
          ? [
              {
                label: "Total",
                data: Object.entries(chartData.totalByTimeStamp).map((e) => [Number(e[0]), Number(e[1])]),
                type: "line",
              } as NumberChartSeries,
            ]
          : []),
        ...marketsSeries,
      ]}
      currency="USD"
    />
  );
};

export default CumulativePnlChart;
