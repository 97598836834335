import { ValueOverTimeChart } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { WithdrawalsAggData } from "../../../../generated";

type Props = {
  timeSpan: TimeSpan;
  isLoading: boolean;
  data?: WithdrawalsAggData[];
};

const WithdrawalsAmountChart = ({ timeSpan, isLoading, data }: Props) => (
  <ValueOverTimeChart
    title="Daily Amount Requested, Processed And Claimed"
    series={[
      {
        label: "Requested",
        data: (data || []).map((d) => [d.timestamp, d.totalRequestedAmountEth]),
      },
      {
        label: "Processed",
        data: (data || []).map((d) => [d.timestamp, d.totalProcessedAmountEth]),
      },
      {
        label: "Claimed",
        data: (data || []).map((d) => [d.timestamp, d.totalClaimedAmountEth]),
      },
    ]}
    isLoading={isLoading}
    timeSpan={timeSpan}
    cryptoCurrency="ETH"
  />
);

export default WithdrawalsAmountChart;
