import { CustomIcon, Tooltip } from "@frontend/ui";

export const InputTooltip = ({ title }: { title: string }) => (
  <Tooltip title={title}>
    <CustomIcon
      sx={{
        ml: 0.5,
        cursor: "pointer",
      }}
      size="small"
      icon="question-mark"
    />
  </Tooltip>
);
