import { Box, Paper, Typography, Tooltip } from "@frontend/ui";
import { CustomIcon } from "@frontend/ui/custom-icon";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { Trend } from "@frontend/ui/trend";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { formatCardValue, ValueCardProps } from "@frontend/ui/value-card";
import { SxProps } from "@mui/material";

export type MultiValuesCardProps = {
  values: ValueCardProps[];
  isLoading?: boolean;
  title?: string;
  sx?: SxProps;
};

const MultiValueCard = ({ values, isLoading, title, sx }: MultiValuesCardProps) => (
  <Paper
    variant="card"
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 3,
      ...sx,
    }}
  >
    {title && <Typography variant="h2">{title}</Typography>}
    <Box display="flex" gap={3} position="relative">
      {isLoading && (
        <ShimmerLoader
          sx={{
            height: 68,
            borderRadius: 2,
            minWidth: 104 * values.length,
            width: "100%",
          }}
        />
      )}
      {!isLoading &&
        values.map(
          ({
            title: valueTitle,
            value,
            isPercent,
            currency,
            isDeltaPercent,
            delta,
            deltaTrend,
            deltaColor,
            tooltip,
            showFullValue,
            valueMaximumFractionDigits,
          }: ValueCardProps) => (
            <Box
              key={`${valueTitle}-${value?.toString() || ""}`}
              sx={{
                minWidth: 104,
                height: "100%",
                display: "flex",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <Box>
                <Box display="flex" alignItems="flex-start" flexDirection="row">
                  <Typography variant="h6" color="almostWhite.main">
                    {valueTitle}
                  </Typography>
                </Box>
                {tooltip && (
                  <Box>
                    <Tooltip title={tooltip} arrow>
                      <Box>
                        <CustomIcon
                          icon="info"
                          sx={{
                            mr: 1,
                            "& path": { fill: "#9B9DA1" },
                            weight: "10px",
                            width: "10px",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                )}
              </Box>
              {value === undefined && <ShimmerLoader sx={{ borderRadius: 1, height: "32px", mt: 1 }} />}
              <Tooltip
                title={
                  typeof value === "number"
                    ? formatAmount(value || 0, { isPercent, currency, notation: "standard" })
                    : ""
                }
                arrow
              >
                <Typography variant="h3" mt={0.5}>
                  {typeof value === "number"
                    ? formatCardValue(value, isPercent, currency, showFullValue, valueMaximumFractionDigits)
                    : value}
                </Typography>
              </Tooltip>
              {delta !== undefined && deltaTrend !== undefined && (
                <Trend
                  delta={delta}
                  deltaTrend={deltaTrend}
                  deltaColor={deltaColor}
                  isPercent={isDeltaPercent === true || isPercent}
                  currency={currency}
                />
              )}
            </Box>
          ),
        )}
    </Box>
  </Paper>
);

export default MultiValueCard;
