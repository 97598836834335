import { Header } from "@frontend/types";

const getCommonTableHeaders = (isMultiMarket: boolean, showChain?: boolean): Header[] => [
  ...(showChain
    ? [
        {
          renderType: "TEXT",
          text: "Chain",
        } as Header,
      ]
    : []),
  ...(isMultiMarket
    ? [
        {
          renderType: "TEXT",
          text: "Market",
        } as Header,
      ]
    : []),
  {
    renderType: "TEXT",
    text: "Asset",
  },
];

export const getSupplyTableHeaders = (isMultiMarket: boolean, showChain?: boolean): Header[] => [
  ...getCommonTableHeaders(isMultiMarket, showChain),
  {
    renderType: "TEXT",
    text: "Total Supplied (Token)",
  },
  {
    renderType: "TEXT",
    text: "Total Supplied (USD)",
    prefix: "$",
  },
];

export const getCollateralAndBorrowTableHeaders = (isMultiMarket: boolean, showChain?: boolean): Header[] => [
  ...getCommonTableHeaders(isMultiMarket, showChain),
  {
    renderType: "TEXT",
    text: "Total Collateral (Token)",
  },
  {
    renderType: "TEXT",
    text: "Total Borrowed (Token)",
  },
  {
    renderType: "TEXT",
    text: "Total Collateral (USD)",
    prefix: "$",
  },
  {
    renderType: "TEXT",
    text: "Total Borrowed (USD)",
    prefix: "$",
  },
];
