import { Box, ValueOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { palette } from "@frontend/ui/theme/palette";
import { LpStrategyTimeSeriesData } from "src/pages/uniswap/generated";

type Props = {
  data: LpStrategyTimeSeriesData;
};

const StrategyReturnsChart = ({ data }: Props) => {
  const dataSeries: NumberChartSeries[] = [
    {
      label: "PNL ($)",
      type: "line",
      color: palette.aqua.main,
      data: data.pnl_data.map((datapoint) => [datapoint.time * 1000, datapoint.value]),
    },
    {
      label: "Fees Earned",
      type: "line",
      color: palette.orange.main,
      data: data.fees_earned.map((datapoint) => [datapoint.time * 1000, datapoint.value]),
    },
    {
      label: "PNL + Fees($)",
      type: "line",
      color: palette.green.main,
      data: data.pnl_and_fees_earned.map((datapoint) => [datapoint.time * 1000, datapoint.value]),
    },
    {
      label: "Impermanent Loss ($)",
      type: "line",
      color: palette.red.main,
      data: data.impermanent_loss.map((datapoint) => [datapoint.time * 1000, datapoint.value]),
    },
    {
      label: "LvR ($)",
      type: "line",
      color: palette.paleTurquoise.main,
      data: data.lvr.map((datapoint) => [datapoint.time * 1000, datapoint.value]),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box height={300} position="relative">
        <ValueOverTimeChart title="Strategy Returns ($)" series={dataSeries} yAxisMin yAxisMax connectNulls />
      </Box>
    </Box>
  );
};

export default StrategyReturnsChart;
