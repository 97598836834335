import { useState } from "react";
import { Grid } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { daysAgoByTimeSpan } from "@frontend/ui/echarts/utils";
import { TimeSpanPicker } from "src/pages/ccar-lending-page/components/time-span-picker";
import { PageHeader } from "@frontend/ui/page-header";
import { LiquidationsCards, LiquidationsOverTimeChart, LiquidationsTable } from "./components";
import { useClientConfig } from "../../clients-config";
import { useLiquidationsQuery } from "../../generated";

export const Liquidations = () => {
  const [daysAgo, setDaysAgo] = useState(TimeSpan.Quarter);
  const { network, liquidations } = useClientConfig();
  const { data, loading } = useLiquidationsQuery({
    variables: {
      daysAgo: daysAgoByTimeSpan[daysAgo],
      useMarketAsset: !!liquidations?.isLiquidationsInMarketToken,
    },
  });

  return (
    <>
      <PageHeader
        showBackButton={false}
        pageTitle="Liquidations"
      />
      <Grid container spacing={[1, 2, 3]}>
        <Grid item xs={12} display="flex" justifyContent="end">
          <TimeSpanPicker
            timeSpanOptions={[TimeSpan.Week, TimeSpan.Month, TimeSpan.Quarter]}
            selectedTimeSpan={daysAgo}
            onSelectTimeSpan={setDaysAgo}
          />
        </Grid>
        <Grid item xs={12}>
          <LiquidationsCards overview={data?.liquidationsOverview} />
        </Grid>
        <Grid item xs={12}>
          <LiquidationsOverTimeChart
            daysAgo={daysAgoByTimeSpan[daysAgo]}
            liquidationsDaily={data?.liquidationsDailyPerAsset}
            isLoading={loading}
            showCountToggle
          />
        </Grid>
        <Grid item xs={12}>
          <LiquidationsTable
            events={data?.liquidationEvents}
            chain={network.name}
            hideTransaction={liquidations?.hideTxHash}
            hideAddress={liquidations?.hideAddress}
            hideLeverage={liquidations?.hideLeverage}
          />
        </Grid>
      </Grid>
    </>
  );
};
