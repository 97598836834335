import { AggAmmExchangeEvents } from "src/pages/ccar-lending-page/generated";

type CardData = {
  totalLiquidations: number;
  totalSoldUSD: number;
  totalBoughtUSD: number;
};

export const getCardsDataByAggPoints = (aggAmmExchangeEventsPoints: AggAmmExchangeEvents[]) =>
  aggAmmExchangeEventsPoints.reduce(
    (acc, curr) => {
      const newAcc: CardData = { ...acc };

      curr.marketsAggExchangeEvents.forEach((market) => {
        newAcc.totalLiquidations += market.totalExchangeEvents;
        market.totalExchangeAmountsByAsset.forEach((asset) => {
          newAcc.totalSoldUSD += asset.amountSoldUSD;
          newAcc.totalBoughtUSD += asset.amountBoughtUSD;
        });
      });

      return newAcc;
    },
    {
      totalLiquidations: 0,
      totalSoldUSD: 0,
      totalBoughtUSD: 0,
    } as CardData,
  );
