import { Route, Routes } from "react-router-dom";
import { RoutePath } from "src/config/routes";
import { CCARPerpsTabsLayout } from "src/pages/ccar-perpetuals-page/ccar-perpetuals-tabs-layout";
import Markets from "../markets";
import { replaceHomePath, DefaultMarketPageTabRedirect, renderMarketPageLayout, renderGlvPageLayout } from "./layout";
import { MarketDetails } from "../market-details";
import { GlvDetails, GmPoolDetails } from "../pages";
import { useClientConfig } from "../../../clients-config";

const MarketsRoutes = () => {
  const { markets } = useClientConfig();

  return (
    <Routes>
      <Route
        index
        element={
          <CCARPerpsTabsLayout>
            <Markets />
          </CCARPerpsTabsLayout>
        }
      />
      <Route
        path={replaceHomePath(RoutePath.CCARPerpetuals.MarketDetails)}
        element={<DefaultMarketPageTabRedirect />}
      />
      <Route
        path={replaceHomePath(RoutePath.CCARPerpetuals.MarketDetailsOverview)}
        element={renderMarketPageLayout(<MarketDetails />)}
      />
      <Route
        path={replaceHomePath(RoutePath.CCARPerpetuals.MarketDetailsPool)}
        element={renderMarketPageLayout(<GmPoolDetails />)}
      />
      {markets?.supportGlv && (
        <Route path={replaceHomePath(RoutePath.Risk.GlvDetails)} element={renderGlvPageLayout(<GlvDetails />)} />
      )}
    </Routes>
  );
};

export default MarketsRoutes;
