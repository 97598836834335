import { Grid, Paper, ValueOverTimeChart } from "@frontend/ui";
import { useProtocolReservesHistoryQuery } from "src/pages/ccar-lending-page/generated";

const ProtocolReserves = () => {
  const { data } = useProtocolReservesHistoryQuery();
  const assetsChartsData = (data?.protocolReservesHistory || []).reduce(
    (chartsData: Record<string, [number, number][]>, protocolReserves) => {
      const { reserves, date } = protocolReserves;
      const newChartsData = reserves.reduce((acc: Record<string, [number, number][]>, reserve) => {
        const { assetSymbol, reservesAmountInToken } = reserve;
        const currAssetData = acc[assetSymbol] || [];
        const newDataPoint = [date * 1000, reservesAmountInToken];
        const newAssetData = [...currAssetData, newDataPoint] as [number, number][];
        return { ...acc, [assetSymbol]: newAssetData };
      }, chartsData);
      return newChartsData;
    },
    {},
  );

  return (
    <Grid container spacing={2}>
      {Object.entries(assetsChartsData).map(([assetSymbol, seriesData]) => (
        <Grid key={assetSymbol} item xs={12} md={6}>
          <Paper variant="widget">
            <ValueOverTimeChart
              title={assetSymbol}
              series={[
                {
                  label: assetSymbol,
                  data: seriesData,
                  type: "line",
                },
              ]}
              currency=""
              yAxisMax
              yAxisMin
              showLegend={false}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProtocolReserves;
