import { Theme } from "@mui/material";
import { renderToString } from "react-dom/server";
import { TooltipComponentFormatterCallbackParams } from "echarts";
import { Box } from "../box";
import { Typography } from "../typography";
import { formatAxis } from "../utils/formatters";
import { CustomTooltipFunc } from "./types";

type ParamType = { axisValue: string | number };

interface EchartsTooltipFormatterProps {
  getCustomTooltip?: CustomTooltipFunc;
  headerFormatter?: (value: string | number) => React.ReactNode;
  valueFormatter?: (val: string | number) => string;
  currency?: string;
  showTotal?: boolean;
  showXAxisInline?: boolean;
  hideZeroValues?: boolean;
  showPercentOfTotal?: boolean;
  sortValues?: boolean;
  theme: Theme;
}

const getChartValue = (value: string | Date | number | [number, number] | object) =>
  Array.isArray(value) ? Number(value[1]) : Number(value);

export const echartsTooltipFormatter =
  ({
    showPercentOfTotal,
    getCustomTooltip,
    headerFormatter,
    currency,
    valueFormatter = (val: string | number) => formatAxis(val, 4, currency),
    showXAxisInline = false,
    hideZeroValues = false,
    showTotal = false,
    sortValues,
    theme,
  }: EchartsTooltipFormatterProps) =>
  (params: TooltipComponentFormatterCallbackParams) => {
    const paramsArr = Array.isArray(params) ? params : [params];
    const paramValue = (paramsArr[0] as unknown as ParamType)?.axisValue;
    const xAxisCategory = paramsArr[0]?.name;
    const xAxisValue = Array.isArray(paramValue) ? Number(paramValue[0]) : Number(paramValue);
    const tooltipHeader =
      headerFormatter?.(xAxisValue || xAxisCategory) || (!Number.isNaN(xAxisValue) ? xAxisValue : xAxisCategory);

    const total: number | undefined =
      showTotal && paramsArr.length > 1
        ? paramsArr.reduce((sum, { value }) => {
            const datapointValue = Array.isArray(value) ? Number(value[1]) : Number(value);
            return sum + datapointValue;
          }, 0)
        : undefined;

    return renderToString(
      <Box padding={2} borderRadius={2} data-testid="tooltip">
        {!!tooltipHeader?.toString() && (
          <Typography marginBottom={1} variant="body2" color={theme.palette.text.primary} fontFamily="Aeonik Fono">
            {tooltipHeader}
          </Typography>
        )}
        {total && (
          <Box display="flex" marginBottom={1} gap={1} alignItems="center">
            <Typography variant="body2" fontFamily="Inter" color={theme.palette.greys[400]}>
              Total:
            </Typography>
            <Typography variant="body2" color={theme.palette.text.primary} fontFamily="Aeonik Fono">
              {valueFormatter(total)}
            </Typography>
          </Box>
        )}
        <Box display="flex" maxWidth={700} gap={0.5} flexWrap="wrap" flexDirection="column">
          {getCustomTooltip?.(paramsArr, valueFormatter, showPercentOfTotal) ??
            paramsArr
              .filter((param) => {
                if (hideZeroValues && Array.isArray(param.value) && param.value[1] === 0) {
                  return false;
                }
                return param;
              })
              .sort((a, b) => (sortValues ? getChartValue(b.value) - getChartValue(a.value) : 1))
              .map((param) => {
                const { seriesId, seriesName = "", value, color, dimensionNames, componentSubType } = param;

                if (Array.isArray(value) && componentSubType === "scatter") {
                  return (
                    <Box>
                      {value.map((val, idx) => (
                        <Box key={seriesId} display="flex" gap={1} alignItems="baseline">
                          <Typography variant="body2" color={theme.palette.greys[400]}>
                            {dimensionNames?.[idx]}:
                          </Typography>
                          <Typography variant="body2">{valueFormatter(Number(val))}</Typography>
                        </Box>
                      ))}
                    </Box>
                  );
                }

                let formattedValue;

                if (Array.isArray(value)) {
                  // eslint-disable-next-line
                  const [_, v, isFiledPoint] = value;
                  formattedValue = isFiledPoint ? "N/A" : valueFormatter(Number(v));
                } else {
                  formattedValue = valueFormatter(Number(value));
                }

                return (
                  <Box key={seriesId} display="flex" gap={1} alignItems="baseline">
                    <Box bgcolor={color?.toString()} width={8} height={8} borderRadius="50%" />
                    <Typography variant="body2" fontFamily="Inter" color={theme.palette.greys[400]}>
                      {`${seriesName}${showXAxisInline ? ` ${xAxisCategory}` : ""}:`}
                    </Typography>
                    <Typography variant="body2" color={theme.palette.text.primary} fontFamily="Aeonik Fono">
                      {formattedValue}
                    </Typography>
                  </Box>
                );
              })}
        </Box>
      </Box>,
    );
  };
