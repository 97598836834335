import { renderToString } from "react-dom/server";
import { TooltipComponentFormatterCallbackParams } from "echarts";
import { ReactNode } from "react";
import { formatAxis } from "../../../utils/formatters";
import { Box } from "../../../box";
import { Typography } from "../../../typography";

interface EchartsTooltipFormatterProps {
  headerFormatter?: (value: string | number) => string | ReactNode;
  valueFormatter?: (val: string | number) => string;
  currency?: string;
  showXAxisInline?: boolean;
  showTotal?: boolean;
}

export const stackedBarChartTooltipFormatter =
  ({
    headerFormatter,
    currency,
    valueFormatter = (val: string | number) => formatAxis(val, 4, currency),
    showXAxisInline = false,
    showTotal = false,
  }: EchartsTooltipFormatterProps = {}) =>
  (params: TooltipComponentFormatterCallbackParams) => {
    const paramsArr = Array.isArray(params) ? params : [params];
    const xAxisCategory = paramsArr[0]?.name;
    const tooltipHeader = headerFormatter?.(xAxisCategory) || xAxisCategory;

    const total: number | undefined =
      paramsArr.length > 1
        ? paramsArr.reduce((sum, { value }) => {
            const datapointValue = Array.isArray(value) ? Number(value[1]) : Number(value);
            return sum + datapointValue;
          }, 0)
        : undefined;

    return renderToString(
      <Box padding={2} bgcolor="background.modal" borderRadius={2}>
        {!!tooltipHeader?.toString() && (
          <Typography marginBottom={1} variant="body2">
            {tooltipHeader}
          </Typography>
        )}
        {total && showTotal && (
          <Typography marginBottom={1} variant="body2">{`Total: ${valueFormatter(total)}`}</Typography>
        )}
        <Box maxWidth={700} display="grid" gridTemplateColumns="1fr">
          {paramsArr
            .sort((a, b) => {
              const valueA = Array.isArray(a.value) ? Number(a.value[1]) : Number(a.value);
              const valueB = Array.isArray(b.value) ? Number(b.value[1]) : Number(b.value);
              if (valueA && valueB) {
                return valueA > valueB ? -1 : 1;
              }
              return 0;
            })
            .map((param) => {
              const { seriesId, seriesName = "", value, color } = param;

              return (
                <Box key={seriesId} display="flex" gap={1} alignItems="baseline">
                  <Box bgcolor={color?.toString()} width={8} height={8} borderRadius="50%" />
                  <Typography variant="caption" color="#9B9DA1">
                    {`${seriesName}${showXAxisInline ? ` ${xAxisCategory}` : ""}:`}
                  </Typography>
                  <Typography variant="caption">
                    {valueFormatter(Array.isArray(value) ? Number(value[1]) : Number(value))}
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </Box>,
    );
  };
