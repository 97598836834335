import { Error } from "@frontend/ui/error";
import { ReactNode } from "react";
import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { RoutePath } from "../../../../config/routes";
import { RouteParams } from "../../../../config/route-params";
import { CommunityDashboardLayout } from "../../../../components/layouts";
import FactionsDropdownHeader from "./factions-dropdown-header";
import DegensDropdownHeader from "./degens-dropdown-header";
import { useFeatureFlag } from "../../../../utils/feature-flags";

type Props = {
  children: ReactNode;
};

const DefiAppLeaderboardsTabsLayout = ({ children }: Props) => {
  const { clientName } = useParams();
  const replaceParams = (path: string) => path.replace(RouteParams.ClientName, clientName!);
  const defiappEnabled = useFeatureFlag("defiapp");

  return (
    <CommunityDashboardLayout
      showClientToolsDropdown={false}
      tabs={
        defiappEnabled
          ? [
              {
                label: "Faction Leaderboard",
                path: replaceParams(RoutePath.Risk.FactionLeaderboard),
              },
              {
                label: "Degen Leaderboard",
                path: replaceParams(RoutePath.Risk.DegenLeaderboard),
              },
            ]
          : undefined
      }
    >
      {defiappEnabled ? children : <Error errorTitle="404" errorMessage="Page Not Found" />}
    </CommunityDashboardLayout>
  );
};

export const renderTabsLayout = (children: ReactNode) => (
  <DefiAppLeaderboardsTabsLayout>{children}</DefiAppLeaderboardsTabsLayout>
);

export const renderFactionPageLayout = (children: ReactNode) => (
  <DefiAppLeaderboardsTabsLayout>
    <Box display="flex" flexDirection="column">
      <FactionsDropdownHeader />
      {children}
    </Box>
  </DefiAppLeaderboardsTabsLayout>
);

export const renderDegenPageLayout = (children: ReactNode) => (
  <DefiAppLeaderboardsTabsLayout>
    <Box display="flex" flexDirection="column">
      <DegensDropdownHeader />
      {children}
    </Box>
  </DefiAppLeaderboardsTabsLayout>
);
