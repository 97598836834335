import { Box, CustomReactSelect, Typography } from "@frontend/ui";
import { useLocation, useParams } from "react-router-dom";
import { RouteParams } from "src/config/routes";
import { PageHeader } from "@frontend/ui/page-header";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useMarketEModeCategoriesQuery } from "src/pages/ccar-lending-page/generated";
import { getTabByPath } from "./get-tab-route-by-path";
import { useMarketsContext } from "../../../context/markets-context";

const MarketEmodeDropdownHeader = () => {
  const { clientName, marketId, eModeId } = useParams();
  const location = useLocation();
  const { markets, isLoading: isLoadingMarkets } = useMarketsContext();
  const { data, loading: isLoadingCategories } = useMarketEModeCategoriesQuery({
    variables: { marketId: marketId || "" },
    skip: !marketId,
  });
  const { marketType } = useClientConfig();
  const isChainMarket = marketType === "ChainMarket";

  const isLoading = isLoadingCategories || isLoadingMarkets;

  const marketsDropdownOptions = (markets || []).map(({ name, id }) => ({
    value: id,
    label: name,
    cryptoIcon: isChainMarket ? name.toLowerCase() : undefined,
    link: marketId ? location.pathname.replace(marketId, id).replace(eModeId || "", "") : "",
  }));

  const eModeCategoriesOptions =
    data?.eModeCategories.map((c) => ({
      value: c.categoryId.toString(),
      label: c.categoryLabel,
      link: location.pathname.replace(eModeId!, c.categoryId.toString()),
    })) || [];

  const marketTab = getTabByPath(location.pathname);
  const tabPath = marketTab.path.replace(RouteParams.ClientName, clientName!).replace(RouteParams.MarketId, marketId!);

  const currentEmode = eModeCategoriesOptions.find((option) => option.value === eModeId);

  return (
    <PageHeader
      backLink={tabPath}
      pageTitle={
        <Box display="flex" gap={[1, 2]} alignItems="center" data-testid="page-title">
          {markets && markets.length > 1 && (
            <>
              <CustomReactSelect
                disabled={isLoading}
                options={marketsDropdownOptions}
                value={marketsDropdownOptions.find((option) => option.value === marketId!)}
                isSearchable={false}
                variant="h3"
              />
              <Typography variant="h3" color="almostWhite.main">
                |
              </Typography>
              <CustomReactSelect
                options={eModeCategoriesOptions}
                value={currentEmode}
                isSearchable={false}
                variant="h3"
              />
            </>
          )}
        </Box>
      }
    />
  );
};

export default MarketEmodeDropdownHeader;
