import { IconButton } from "@mui/material";
import { Box } from "../box";

type SearchBarWrapperProps = {
  children: React.ReactElement;
  onClick?: () => void;
};

const SearchBarWrapper = ({ children, onClick }: SearchBarWrapperProps) => (
  <Box
    width="48px"
    height="48px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      borderRadius: "0px 8px 8px 0px",
      background:
        "linear-gradient(0deg, #252A2F, #252A2F), linear-gradient(0deg, rgba(229, 233, 235, 0.5), rgba(229, 233, 235, 0.5))",
    }}
  >
    <IconButton onClick={onClick}>{children}</IconButton>
  </Box>
);

export default SearchBarWrapper;
