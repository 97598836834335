import { ValueOverTimeChart } from "@frontend/ui";
import {
  AggregateAssetStatsFragment,
  useAssetExposureDistributionOverTimeQuery,
} from "src/pages/ccar-lending-page/generated";

type Props = {
  asset?: AggregateAssetStatsFragment;
};

export const MarketCapDistribution = ({ asset }: Props) => {
  const tokenSymbol = asset?.symbol || "";
  const { data, loading } = useAssetExposureDistributionOverTimeQuery({
    variables: {
      tokenSymbol,
    },
    skip: !asset,
  });
  const isCollateralizable = asset?.isCollateralizable;
  const marketCapRatio =
    data?.assetExposureDistributionOverTime
      ?.map((item) => ({
        chain: item.chain,
        data: isCollateralizable ? item.marketCapRatio.supply : item.marketCapRatio.borrow,
      }))
      .sort((a, b) => (a.chain > b.chain ? -1 : 1)) || [];

  return (
    <ValueOverTimeChart
      isLoading={loading}
      isPercent
      title="Total Supply out of Chain Market Cap"
      connectNulls
      series={marketCapRatio.map((distribution) => ({
        type: "line",
        label: distribution.chain,
        data: distribution.data.map((b) => [b.time * 1000, b.value]),
      }))}
    />
  );
};
