import { Header, RenderData } from "@frontend/types";
import { ChaosTable, chipCell, textCell } from "@frontend/ui";
import { DegenSwapPointsResponse } from "../../../api/types";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Faction",
  },
  {
    renderType: "TEXT",
    text: "Swap Points",
  },
];

type Props = {
  swapPoints?: DegenSwapPointsResponse[];
  isLoading: boolean;
};

const FactionPointsTable = ({ swapPoints, isLoading }: Props) => {
  const data: RenderData[][] = (swapPoints || []).map((s) => [
    textCell(s.factionName, undefined, undefined, undefined, undefined, s.factionImage),
    chipCell(s.degenFactionPoints),
  ]);

  return <ChaosTable title="Degen Points by Faction" headers={headers} data={data} isLoading={isLoading} />;
};

export default FactionPointsTable;
