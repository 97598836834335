import { RJSFSchema } from "@rjsf/utils";
import { AlertSeverity, AlertType } from "../../generated";
import { CcarClient } from "../alerts/utils";

export type AlertLevel = "GLOBAL" | "PROTOCOL" | "ALL";
export enum NotificationChannel {
  TELEGRAM = "TELEGRAM",
  SLACK = "SLACK",
  DISCORD = "DISCORD",
  JIRA = "JIRA",
  SNS = "SNS",
  WEBHOOKS = "WEBHOOKS",
}

export interface NotificationConfig {
  enabled: boolean;
  inputs?: any[];
}

export interface TriggerStepConfiguration {
  argumentsSchema: RJSFSchema;
  defaultArguments: any[];
}

export interface ClientConfig {
  enabled: boolean;
  hideFromUi?: boolean;
  overrideFetchInputs?: any[];
  overrideFilterInputs?: any[];
  notificationConfig?: Partial<Record<NotificationChannel, NotificationConfig>>;
}

export interface AlertSetting {
  alertId: string;
  name: string;
  description: string;
  runnerType?: string;
  enabled: boolean;
  alertType: AlertType;
  severity: AlertSeverity;
  level?: AlertLevel;
  frequencyInMinutes: number;
  keepAlertForInMinutes: number;
  isActionable: boolean;
  hideFromUi?: boolean;
  clientsConfig: Partial<Record<CcarClient, ClientConfig>>;
  evaluateStep: TriggerStepConfiguration;
  filterStep?: TriggerStepConfiguration;
  globalNotificationConfig: Partial<Record<NotificationChannel, NotificationConfig>>;
  nextRun?: number;
}

export const AlertSettingKeys: (keyof AlertSetting)[] = [
  "alertId",
  "name",
  "description",
  "runnerType",
  "enabled",
  "alertType",
  "severity",
  "level",
  "frequencyInMinutes",
  "keepAlertForInMinutes",
  "isActionable",
  "hideFromUi",
  "clientsConfig",
  "evaluateStep",
  "filterStep",
  "globalNotificationConfig",
];

export function convertAlertLevelToDisplayName(level?: AlertLevel): string {
  switch (level) {
    case "GLOBAL":
      return "Global";
    default:
      return "Protocol";
  }
}

export function convertAlertRunnerTypeToDisplayName(runnerType?: string): string {
  switch (runnerType) {
    case "dune-runner":
      return "Dune Analytics";
    case "python-runner":
      return "Python script";
    default:
      return "Default";
  }
}
