import { Box, Link, Typography } from "@frontend/ui";
import { IconButton } from "@frontend/ui/icon-button";
import { CustomIcon } from "@frontend/ui/custom-icon";
import imgChaosCommunityDashboardLogoHeader from "src/assets/img/chaos-community-analytics-logo.svg";
import { Wrapper } from "../layouts/border-wrapper";

export const PublicFooter = (): JSX.Element => (
  <Box py={4} bgcolor="background.default" width="100%" borderTop="1px solid" borderColor="greys.800" px={2}>
    <Wrapper>
      <Box width="100%" display="flex" flexDirection="column" gap={{ xs: 4, md: 4 }}>
        <Box display="flex" gap={{ xs: 4, md: 10 }} flexWrap="wrap">
          <Link target="_blank" href="https://chaoslabs.xyz/about" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="label" whiteSpace="nowrap" align="center" color="text.primary">
              Company
            </Typography>
          </Link>
          <Link target="_blank" href="https://chaoslabs.xyz/blog" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="label" whiteSpace="nowrap" align="center" color="text.primary">
              Blog
            </Typography>
          </Link>
          <Box display="flex" gap={1.5}>
            <Link
              target="_blank"
              href="https://chaoslabs.xyz/careers"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Typography variant="label" whiteSpace="nowrap" align="center" color="text.primary">
                Careers
              </Typography>
            </Link>
            <Box paddingX={2} borderRadius={2} bgcolor="primary.main">
              We're Hiring!
            </Box>
          </Box>
          <Box>
            <Link target="_blank" href="/privacy" style={{ textDecoration: "none", color: "white" }}>
              <Typography variant="label" whiteSpace="nowrap" align="center" color="text.primary">
                Privacy
              </Typography>
            </Link>
          </Box>
          <Box>
            <Link target="_blank" href="/terms" style={{ textDecoration: "none", color: "white" }}>
              <Typography variant="label" whiteSpace="nowrap" align="center" color="text.primary">
                Terms of Service
              </Typography>
            </Link>
          </Box>

          <Box flex={1} />

          <Box display="flex" alignItems="center" gap={2} color="greys.400">
            <Typography variant="label" whiteSpace="nowrap" align="center">
              FOLLOW US
            </Typography>
            <IconButton size="small" target="_blank" href="https://twitter.com/chaos_labs">
              <CustomIcon icon="x-logo" />
            </IconButton>
            <IconButton size="small" target="_blank" href="https://linkedin.com/company/chaos-labs-xyz">
              <CustomIcon icon="linkedin" />
            </IconButton>
          </Box>
        </Box>
        <Link target="_blank" href="https://chaoslabs.xyz/">
          <img style={{ display: "block" }} src={imgChaosCommunityDashboardLogoHeader} alt="header" />
        </Link>
      </Box>
    </Wrapper>
  </Box>
);
