import { RouteParams } from "./route-params";

const CCARHome = `/${RouteParams.ClientName}/ccar`;

const Markets = `${CCARHome}/markets`;
const MarketDetails = `${Markets}/${RouteParams.Market}`;

const Assets = `${CCARHome}/assets`;
const AssetDetails = `${Assets}/${RouteParams.Asset}`;

const Wallets = `${CCARHome}/wallets`;
const WalletDetails = `${Wallets}/${RouteParams.Address}`;

export const CCARLendingRoutes = {
  Home: CCARHome,
  Overview: `${CCARHome}/overview`,
  Markets,
  MarketDetails,
  Assets,
  AssetDetails,
  Wallets,
  WalletDetails,
  Tokens: `${CCARHome}/tokens`,
  Liquidations: `${CCARHome}/liquidations`,
  RiskExplorer: `${CCARHome}/risk-explorer`,
  Reserves: `${CCARHome}/reserves`,
  Alerts: `${CCARHome}/alerts`,
  Stablecoin: (slug: string) => `${CCARHome}/${slug}`,
  Risk: `${CCARHome}/risk`,
};
