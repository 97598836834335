import { Box } from "@frontend/ui";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import groupBy from "lodash/groupBy";
import LiquidationsCards from "./liquidations-cards";
import { LiquidationsDataCutType } from "../types";
import { LiquidationsDailyPerAsset, LiquidationsOverview, useAllMarketsQuery } from "../../../generated";
import { LiquidationsPie } from "./liquidations-pie";
import MarketMetricOverTimeCharts, { MarketHistoryValue } from "./market-metric-charts";

type Props = {
  dataCut: LiquidationsDataCutType;
  overviewData: {
    isLoading: boolean;
    data?: LiquidationsOverview;
  };
  liquidations: {
    isLoading: boolean;
    data?: LiquidationsDailyPerAsset[];
  };
};

const LiquidationsStatsOverTime = ({ dataCut, overviewData, liquidations }: Props) => {
  const { liquidations: config } = useClientConfig();
  const { loading: isLoadingMarkets } = useAllMarketsQuery();
  const isLoading = !liquidations?.data || liquidations.isLoading || isLoadingMarkets;

  const groupedLiquidations = groupBy(liquidations.data || [], (liq) => `${liq.marketId}-${liq.date}`);

  const liquidationsData = Object.values(groupedLiquidations).map((liquidationsGroup: LiquidationsDailyPerAsset[]) => {
    const totalLiquidated = liquidationsGroup.reduce((total, liq) => total + liq.liquidationAmount, 0);
    const assetBreakDown = liquidationsGroup.reduce((obj, liq) => ({ ...obj, [liq.asset]: liq.liquidationAmount }), {});

    const marketHistoryPoint: MarketHistoryValue = {
      marketId: liquidationsGroup[0].marketId,
      timestamp: new Date(liquidationsGroup[0].date).getTime(),
      value: totalLiquidated,
      assetBreakDown,
    };

    return marketHistoryPoint;
  });
  return (
    <Box height="100%" display="flex" flexDirection="column" gap={3}>
      <LiquidationsCards overviewData={overviewData.data} isLoading={overviewData.isLoading} />
      {config?.showLiquidationPie && <LiquidationsPie dataCut={dataCut} liquidations={liquidations} />}
      <MarketMetricOverTimeCharts
        dataCut={dataCut}
        isLoading={isLoading}
        metricName="Liquidations"
        data={liquidationsData}
        currency="USD"
      />
    </Box>
  );
};

export default LiquidationsStatsOverTime;
