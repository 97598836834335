import { Header, RenderData } from "@frontend/types";
import { ChaosTable, chipCell, dateCell, numberCell, textCell } from "@frontend/ui";
import { FractionDegenResponse } from "../../../api/types";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Rank",
  },
  {
    renderType: "TEXT",
    text: "Degen Name",
  },
  {
    renderType: "TEXT",
    text: "Join Date",
    isDate: true,
  },
  {
    renderType: "TEXT",
    text: "Leave Date",
    isDate: true,
  },
  {
    renderType: "TEXT",
    text: "Total Points",
  },
];

type Props = {
  factionDegens?: FractionDegenResponse[];
  isLoading: boolean;
};

const FactionDegensTable = ({ factionDegens, isLoading }: Props) => {
  const data: RenderData[][] = (factionDegens || []).map((d) => [
    numberCell(d.ranking),
    textCell(d.username),
    dateCell(new Date(d.joinedAtTimestamp)),
    d.leftAtTimestamp ? dateCell(new Date(d.leftAtTimestamp)) : textCell("-"),
    chipCell(Number(d.totalPoints)),
  ]);

  return <ChaosTable title="Faction Degens" headers={headers} data={data} isLoading={isLoading} initialSortBy={0} />;
};

export default FactionDegensTable;
