import { FC } from "react";
import { useParams } from "react-router-dom";
import { usePageTitle } from "src/hooks";
import { ChartData } from "@frontend/types";
import { Dashboard } from "@frontend/ui/dashboard";
import { Error } from "@frontend/ui/error";
import { PageTracker } from "src/components/page-tracker";
import { dataFetchersURL } from "src/utils/data-fetchers-url";
import { clientViewProps } from "../community-analytics-page/client-view-props";

export const CommunityAnalyticsPublicPage: FC = PageTracker((): JSX.Element => {
  usePageTitle("Analytics");
  const { dashboardId } = useParams<{ dashboardId?: string }>();
  const clientsProps = clientViewProps();
  const dashboard = clientsProps?.tools.find((tool) => tool.id === dashboardId);

  return !dashboard?.data ? (
    <Error errorTitle="404" errorMessage="Page Not Found" />
  ) : (
    <Dashboard baseUrl={dataFetchersURL()} name={dashboard.title} dashboard={dashboard.data as ChartData[][]} />
  );
});
