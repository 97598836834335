import React from "react";
import { Box, SxProps, Theme } from "@mui/material";

interface WrapperProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const Wrapper = ({ sx, children }: WrapperProps): JSX.Element => (
  <Box
    width="100%"
    maxWidth="1328px"
    mx="auto"
    display="flex"
    flex="1 0 0"
    sx={{
      px: {
        xs: 0,
        lg: 5,
      },
    }}
  >
    <Box
      flex="1 0 0"
      minWidth={0}
      sx={{
        ...sx,
      }}
    >
      {children}
    </Box>
  </Box>
);

const BorderWrapper = ({ sx, children }: WrapperProps): JSX.Element => (
  <Wrapper
    sx={{
      ...sx,
      borderLeft: {
        lg: `1px solid`,
      },
      borderRight: {
        lg: "1px solid",
      },
      borderColor: {
        lg: "greys.800",
      },
    }}
  >
    {children}
  </Wrapper>
);

export default BorderWrapper;
