import { NumberChartSeries, StringChartSeries } from "../types";

export const CHART_ACTIONS_ID = "chart-actions";

const transformData = (series: (NumberChartSeries | StringChartSeries)[]): Map<number | string, number[]> => {
  const resultMap = new Map<number | string, number[]>();

  series.forEach((serie) => {
    serie.data.forEach(([timestamp, value]) => {
      // If the timestamp already exists in the map, append to its array
      if (resultMap.has(timestamp)) {
        const existingValues = resultMap.get(timestamp);
        existingValues!.push(value);
        resultMap.set(timestamp, existingValues!);
      } else {
        // Otherwise, initialize a new array for that timestamp
        resultMap.set(timestamp, [value]);
      }
    });
  });

  return resultMap;
};

const transformToCSVFormat = (dataMap: Map<number | string, number[]>): string[][] => {
  const csvArray: string[][] = [];

  // Convert the map keys to an array and sort it
  const timestamps = Array.from(dataMap.keys()).sort();

  // Iterate over each timestamp to populate the CSV rows
  timestamps.forEach((timestamp) => {
    const values = dataMap.get(timestamp) || [];
    const row = [String(timestamp), ...values.map(String)];
    csvArray.push(row);
  });

  return csvArray;
};

export const transformToCSV = (series: (NumberChartSeries | StringChartSeries)[]): string[][] => {
  const resultMap = transformData(series);
  return transformToCSVFormat(resultMap);
};
