import { FC, ReactNode, createContext, useMemo, useState, Dispatch, SetStateAction } from "react";
import {
  GetPublicSimulationsResponse,
  GetPublicSimulationsQuery,
} from "src/pages/parameter-recommendations-page/public-simulations-types";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { Chain } from "src/pages/ccar-lending-page/generated";
import { usePublicSimulationsData } from "src/pages/parameter-recommendations-page/hooks/use-public-simulations-data";

export type AssetsRecommendationsContextType = {
  assetsRecommendations?: GetPublicSimulationsResponse;
  isLoading: boolean;
  chain?: Chain;
  setChain: Dispatch<SetStateAction<Chain | undefined>>;
};

const initialState: AssetsRecommendationsContextType = {
  isLoading: false,
  setChain: () => {},
};

export const AssetsRecommendationsContext = createContext<AssetsRecommendationsContextType>(initialState);

const AssetsRecommendationsProvider: FC<ReactNode> = ({ children }) => {
  const [chain, setChain] = useState<Chain>();
  const { isMultichain, chainsTab } = useClientConfig();

  const publicSimulationsQuery: GetPublicSimulationsQuery = useMemo(
    () => ({
      market: isMultichain ? chain : undefined,
    }),
    [chain, isMultichain],
  );

  const { response, isLoading } = usePublicSimulationsData({
    endpoint: "",
    query: publicSimulationsQuery,
    sendRequest: !!chainsTab?.assetRecommendations && !!chain,
  });

  const contextState: AssetsRecommendationsContextType = useMemo(
    () => ({
      assetsRecommendations: response,
      isLoading,
      chain,
      setChain,
    }),
    [chain, response, isLoading],
  );

  return <AssetsRecommendationsContext.Provider value={contextState}>{children}</AssetsRecommendationsContext.Provider>;
};

export default AssetsRecommendationsProvider;
