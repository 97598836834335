import {
  ChaosTable,
  ControlledFilters,
  addressCell,
  chipCell,
  currencyCell,
  iconsCell,
  numberCell,
} from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { PerpetualsAccountFragment, SortAccountsBy } from "src/pages/ccar-perpetuals-page/generated";
import { RouteParams, RoutePath } from "src/config/routes";
import { useParams } from "react-router-dom";
import { PageHeader } from "@frontend/ui/page-header";
import { useAccountsData } from "../use-accounts-data";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Rank",
    field: SortAccountsBy.Rank,
    width: "6%",
  },
  {
    renderType: "TEXT",
    text: "Account",
    nonSortable: true,
  },
  {
    renderType: "CURRENCY",
    unit: "usd",
    text: "Total PnL",
    field: SortAccountsBy.TotalPnl,
  },
  {
    renderType: "CURRENCY",
    unit: "usd",
    text: "Avg Size",
    field: SortAccountsBy.AverageSize,
  },
  {
    renderType: "TEXT",
    text: "Avg Leverage",
    field: SortAccountsBy.AverageLeverage,
  },
  {
    renderType: "CURRENCY",
    unit: "usd",
    text: "Total Volume",
    field: SortAccountsBy.TotalVolume,
  },
  {
    renderType: "TEXT",
    text: "Positions Count",
    field: SortAccountsBy.PositionsCount,
  },
  {
    renderType: "TEXT",
    text: "Traded Assets",
    nonSortable: true,
  },
];

const mapRow = (account: PerpetualsAccountFragment): RenderData[] => [
  chipCell(account.rank, undefined, "text.primary", "standard"),
  addressCell(account.account, undefined, true),
  currencyCell(account.totalPnl),
  currencyCell(account.averageSize),
  numberCell(account.averageLeverage),
  currencyCell(account.totalVolume),
  numberCell(account.positionsCount),
  iconsCell(account.tradedAssets),
];

export const AccountsTable = () => {
  const { clientName } = useParams();
  const { accounts, loading, filters, onSearch, onFiltersChange, onPageChange, onSortChange } = useAccountsData({});

  const handleRowClick = (rowId: number) => {
    const account = accounts[rowId]?.account;

    if (!account) return "";

    return RoutePath.Risk.AccountPositions.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.Address,
      account,
    );
  };

  return (
    <>
      <PageHeader showBackButton={false} pageTitle="Accounts" />
      <ChaosTable
        title="Accounts"
        headers={headers}
        isLoading={loading}
        pageSize={15}
        data={accounts.map((account) => mapRow(account))}
        isFullHeight
        isFilterHidden
        showSearch
        serchbarPlaceholder="Search Account"
        isSettingsHidden
        initialSortBy={2}
        isInitialSortDesc
        rowHref={handleRowClick}
        onSortChange={onSortChange}
        onPageChange={onPageChange}
        onSearch={onSearch}
        emptyState={{
          icon: "chaos",
          title: "No accounts to show",
        }}
        customFilter={<ControlledFilters key={filters.length} filters={filters} onChange={onFiltersChange} />}
      />
    </>
  );
};
