import { ValueByCategoryChart } from "@frontend/ui";
import { StringChartSeries } from "@frontend/ui/echarts/types";
import { useStablecoinDataContext } from "../../provider";
import { formatPriceChangeLiquidationResponse } from "../../utils/format-price-change-liquidation-response";

export const AssetPriceLiquidationChart = () => {
  const { priceChangeLiquidation, tokenSymbol, isLoading } = useStablecoinDataContext();
  const formatted = formatPriceChangeLiquidationResponse(priceChangeLiquidation);
  const series: StringChartSeries[] = formatted.map((datum) => ({
    id: datum.title,
    label: datum.title,
    data: datum.data.map(({ x, y }) => [x, y]),
  }));

  return (
    <ValueByCategoryChart
      title={`${tokenSymbol} at Risk`}
      description={`${tokenSymbol} to be eligible for liquidation (in USD) in correlation with asset price changes, assuming no additional collateral is supplied by borrowers.`}
      tooltip="Excludes stablecoins pegged to the US Dollar (eg: USDT)"
      series={series}
      isLoading={isLoading}
    />
  );
};
