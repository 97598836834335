import axios from "axios";

export async function DataQueries<ReturnType = any, DataType = Record<string, unknown>>(
  baseUrl: string,
  query: string,
  type: "onchain" | "offchain",
  data?: DataType,
): Promise<ReturnType | undefined> {
  try {
    const res = await axios.post<ReturnType>(`${baseUrl}data/query`, {
      query,
      type,
      data,
    });

    return res.data;
  } catch (e) {
    console.log("DataQueries Error: ", e);
    return undefined;
  }
}
