import { useEffect } from "react";
import {
  MarketAssetsBreakdownQuery,
  useMarketAssetsBreakdownQuery,
  useRiskExplorerOverviewLazyQuery,
  RiskExplorerOverviewResults,
  RiskExplorerQuery,
} from "../../generated";

export type RiskExplorerData = {
  markets?: MarketAssetsBreakdownQuery["assetsBreakdown"];
  overview?: RiskExplorerOverviewResults;
};

export const useRiskExplorerData = (
  query?: RiskExplorerQuery[],
): {
  isLoading: boolean;
  data: RiskExplorerData;
} => {
  const { data: assetsResponse, loading: isLoadingAssets } = useMarketAssetsBreakdownQuery();
  const [loadOverview, { data: overviewResponse, loading: isLoadingOverview }] = useRiskExplorerOverviewLazyQuery();

  useEffect(() => {
    if (query) {
      const variables = { query };
      void loadOverview({ variables });
    }
  }, [query, loadOverview]);

  return {
    isLoading: isLoadingAssets || isLoadingOverview,
    data: {
      markets: assetsResponse?.assetsBreakdown,
      overview: overviewResponse?.riskExplorerOverview,
    },
  };
};
