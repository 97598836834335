import { Box, CustomIcon, CustomReactSelect, IconButton } from "@frontend/ui";
import { useMemo } from "react";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { AssetWithSentiment, PriceSentiment, UniswapChain } from "src/pages/uniswap/generated";
import { sentimentsTypeNames } from "../../types";
import { InputTooltip } from "./input-tooltip";

type Props = {
  lpStrategyAssetsByChain: Record<UniswapChain, string[]>;
  assetSentimentValue: AssetWithSentiment;
  index: number;
  canAddRow: boolean;
  setFieldValue(key: string, value: string): Promise<void>;
  onAddRow(): void;
  onRemoveRow(index: number): void;
};

const getSentimentIcon = (value: string) => {
  switch (value.toLocaleLowerCase()) {
    case "positive":
      return "line-up-smooth";
    case "negative":
      return "line-down-smooth";
    case "neutral":
      return "chart-minimum";
    default:
      return "";
  }
};

export const AssetSentimentSelectRow = ({
  lpStrategyAssetsByChain,
  assetSentimentValue,
  index,
  canAddRow,
  setFieldValue,
  onRemoveRow,
  onAddRow,
}: Props) => {
  const keys = useMemo(
    () => ({
      chain: `specificAssetsExposure.${index}.chain`,
      token: `specificAssetsExposure.${index}.token`,
      sentiment: `specificAssetsExposure.${index}.price_sentiment`,
    }),
    [index],
  );

  return (
    <Box display="flex" flexWrap="wrap" width="100%" gap={3}>
      <Box flex="1" minWidth="max-content" data-testid="chain-input">
        <CustomReactSelect
          label={index === 0 ? "Chain" : ""}
          name={keys.chain}
          placeholder="Select Chain"
          options={
            Object.keys(lpStrategyAssetsByChain).map((chain) => ({
              value: chain,
              label: chain,
              cryptoIcon: chain.toLowerCase(),
            })) || []
          }
          value={{
            value: assetSentimentValue.chain,
            label: assetSentimentValue.chain,
            cryptoIcon: assetSentimentValue.chain.toLowerCase(),
          }}
          onChange={(selected) => {
            if (!selected) return;
            const newSelectedChain = selected.value as UniswapChain;
            void setFieldValue(keys.chain, newSelectedChain);
            const newSelectedAsset = lpStrategyAssetsByChain[newSelectedChain][0];
            void setFieldValue(keys.token, newSelectedAsset);
          }}
        />
      </Box>
      <Box flex="1" minWidth="max-content" data-testid="token-input">
        <CustomReactSelect
          label={index === 0 ? "Token" : ""}
          name={keys.token}
          placeholder="Select Token"
          options={lpStrategyAssetsByChain[assetSentimentValue.chain].map((token) => ({
            value: token,
            label: token,
            cryptoIcon: wrappedSymbolToIconSymbol(token.toLowerCase()),
          }))}
          value={{
            value: assetSentimentValue.token,
            label: assetSentimentValue.token,
            cryptoIcon: assetSentimentValue.token.toLowerCase(),
          }}
          onChange={(selected) => {
            if (!selected) return;
            const newSelectedAsset = selected.value;
            void setFieldValue(keys.token, newSelectedAsset);
          }}
        />
      </Box>
      <Box flex="1" minWidth="max-content" data-testid="price-sentiment-input">
        <CustomReactSelect
          label={
            index === 0 ? (
              <>
                Price Sentiment{" "}
                <InputTooltip title="The outlook refers to the anticipated direction of an asset's price. In the context of the backtesting engine, it assumes that the asset's price will move in the selected direction, but the price of the other asset in the pool may move differently." />
              </>
            ) : (
              ""
            )
          }
          name={keys.sentiment}
          placeholder="Select Sentiment"
          options={sentimentsTypeNames.map((s) => ({
            value: s,
            label: s,
            customIcon: getSentimentIcon(s),
          }))}
          value={{
            value: assetSentimentValue.price_sentiment,
            label: assetSentimentValue.price_sentiment,
            customIcon: getSentimentIcon(assetSentimentValue.price_sentiment),
          }}
          onChange={(selected) => {
            if (!selected) return;
            const newSelectedSentiment = selected.value as PriceSentiment;
            void setFieldValue(keys.sentiment, newSelectedSentiment);
          }}
        />
      </Box>
      <Box alignSelf="flex-end">
        {index === 0 ? (
          <IconButton type="button" color="primary" disabled={!canAddRow} onClick={() => onAddRow()}>
            <CustomIcon icon="plus" />
          </IconButton>
        ) : (
          <IconButton color="secondary" type="button" onClick={() => onRemoveRow(index)}>
            <CustomIcon icon="trash-can" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
