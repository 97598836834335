import { Route, Routes } from "react-router-dom";
import { ChaosApolloProvider } from "src/utils/graphql";
import { RoutePath } from "src/config/routes";
import LpStrategyCalculatorPage from "./lp-strategy-calculator-page";

export const LpStrategyCalculatorRoutes = () => (
  <ChaosApolloProvider product="uniswap">
    <Routes>
      <Route index element={<LpStrategyCalculatorPage />} />
      <Route
        path={RoutePath.LpStrategyCalculator.UnderReview.replace(RoutePath.LpStrategyCalculator.Home, "")}
        element={<LpStrategyCalculatorPage filterBestResultPerPool={false} />}
      />
    </Routes>
  </ChaosApolloProvider>
);
