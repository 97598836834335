import { FC } from "react";
import { Typography } from "../typography";
import { Box } from "../box";
import { ChaosChart } from "./chaos-chart";

interface AreaChartRowProps {
  data?: any;
  title: string;
  color?: string;
}

export const AreaChartRow: FC<AreaChartRowProps> = ({ title, data, color }): JSX.Element => (
  <Box position="relative">
    <Typography variant="h2">{title}</Typography>
    <Box sx={{ mt: { sm: 2, md: 4 }, height: 300 }}>
      {/* eslint-disable-next-line */}
      <ChaosChart data={data} color={color} startY={0} />
    </Box>
  </Box>
);
