import React, { FC, useState } from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Box } from "../box";
import { Typography } from "../typography";
import { CustomIcon } from "../custom-icon";
import { CustomPopover } from "../custom-popover";

export interface TabProps {
  label: string;
  badge?: string | number;
  definition?: string;
  value?: string | number;
  testId?: string;
  icon?: string;
}

export interface TabsInterface<T> {
  tabs: T[];
  value: number;
  onChange?: (e: React.SyntheticEvent, value: number) => void;
  fullWidth?: boolean;
}

export type TabsProps = TabsInterface<TabProps>;

interface TabBadgeProps {
  selected: boolean;
  children: React.ReactNode;
}

const TabBadge: FC<TabBadgeProps> = ({ children, selected }) => (
  <Typography
    sx={{
      color: "black.main",
      backgroundColor: selected ? "white.main" : "almostWhite.main",
      fontSize: 12,
      lineHeight: "16px",
      padding: "2px 4px",
      borderRadius: 1,
      ml: 1,
    }}
  >
    {children}
  </Typography>
);

export const Tabs: FC<TabsProps> = ({ tabs, value, onChange = () => {}, fullWidth }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <ToggleButtonGroup
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "number") {
          onChange(event, newValue);
        }
      }}
      exclusive
      aria-label="tabs"
      fullWidth={fullWidth}
    >
      {tabs.map((tab, i) => (
        <ToggleButton value={tab.value || i} key={`${tab.label}-${i}`}>
          {tab.label}

          {tab.definition && (
            <Box onMouseEnter={(e) => setAnchorEl(e.currentTarget)} onMouseLeave={() => setAnchorEl(null)} ml={0.5}>
              <CustomIcon icon="exclamation-square" sx={{ width: 16 }} />
              <CustomPopover anchorEl={anchorEl} definition={tab.definition} />
            </Box>
          )}

          {tab.badge !== undefined && <TabBadge selected={value === i}>{tab.badge}</TabBadge>}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
