import type { EChartsOption } from "echarts";
import { Theme } from "@mui/material";
import { StringChartProps } from "../../types";
import { formatEChartValue } from "../../utils";

export const getPieChartOptions = (
  theme: Theme,
  { series, isPercent, currency, cryptoCurrency }: StringChartProps,
): EChartsOption => ({
  tooltip: {
    trigger: "item",
    valueFormatter: (v) =>
      formatEChartValue({
        isPercent,
        currency,
        cryptoCurrency,
        notation: "standard",
      })(Number(v)),
  },
  legend: {
    show: false,
  },
  series: series.map((s) => ({
    name: s.label,
    type: "pie",
    radius: ["65%", "95%"],
    center: ["50%", "50%"],
    avoidLabelOverlap: false,
    emphasis: {
      label: {
        show: true,
        fontSize: 20,
        color: theme.palette.text.primary,
        formatter: "{b}\n\n{d}%",
      },
    },
    label: {
      show: false,
      position: "center",
    },
    data: [...s.data].sort((a, b) => b[1] - a[1]).map(([name, value]) => ({ name, value })),
  })),
});
