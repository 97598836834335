import { useMemo, useState } from "react";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, CustomSwitch, numberCell, percentCell, textCell } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "../../../../../config/routes";
import { PoolData, usePoolCreditManagersQuery } from "../../../generated";
import { getPoolCreditMangerName } from "../../../utils";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Credit Manager",
  },
  {
    renderType: "TEXT",
    text: "Total Borrowed",
  },
  {
    renderType: "TEXT",
    text: "Total Profit",
  },
  {
    renderType: "TEXT",
    text: "Total Losses",
  },
  {
    renderType: "TEXT",
    text: "Debt Limit",
  },
  {
    renderType: "TEXT",
    text: "Utilization",
  },
  {
    renderType: "TEXT",
    text: "Total Opened Accounts",
  },
];

interface CreditManagersTableProps {
  poolData?: PoolData;
}

const CreditManagersTable = ({ poolData }: CreditManagersTableProps) => {
  const { poolId, clientName } = useParams();
  const [showValuesInToken, setShowValuesInToken] = useState(true);

  const { data, loading } = usePoolCreditManagersQuery({
    variables: {
      poolId: poolId!,
    },
  });

  const renderedData: RenderData[][] = useMemo(() => {
    const cryptoCurrency = showValuesInToken ? poolData?.assetSymbol : undefined;

    return (data?.poolCreditManagers || []).map((m) => {
      const borrow = showValuesInToken ? m.totalBorrowed : m.totalBorrowedUsd;
      const profit = showValuesInToken ? m.totalProfit : m.totalProfitUsd;
      const loss = showValuesInToken ? m.totalLosses : m.totalLossesUsd;
      const debtLimit = showValuesInToken ? m.totalDebtLimit : m.totalDebtLimitUsd;
      const utilization = m.totalBorrowed / m.totalDebtLimit || 0;

      return [
        textCell(getPoolCreditMangerName(m.name, poolData), undefined, poolData?.assetSymbol),
        currencyCell(borrow, { cryptoCurrency }),
        currencyCell(profit, { cryptoCurrency }),
        currencyCell(loss, { cryptoCurrency }),
        currencyCell(debtLimit, { cryptoCurrency }),
        percentCell(utilization),
        numberCell(m.totalOpenedAccounts),
      ];
    });
  }, [data?.poolCreditManagers, poolData, showValuesInToken]);

  const getRowHref = (rowId: number) => {
    if (!data?.poolCreditManagers?.[rowId]) {
      return "";
    }

    const creditManager = data?.poolCreditManagers[rowId];

    return RoutePath.Risk.CreditManager.replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.PoolId, poolId!)
      .replace(RouteParams.CreditManagerId, creditManager.id);
  };

  return (
    <ChaosTable
      title="Credit Managers"
      headers={headers}
      data={renderedData}
      rowHref={getRowHref}
      showRowChevron
      pageSize={10}
      isLoading={loading}
      isFilterHidden
      initialSortBy={1}
      isInitialSortDesc
      headerSuffixComponent={
        <CustomSwitch
          checked={showValuesInToken}
          onChange={() => setShowValuesInToken((prev) => !prev)}
          checkedLabel={poolData?.assetSymbol || "Token"}
          uncheckedLabel="USD"
        />
      }
    />
  );
};

export default CreditManagersTable;
