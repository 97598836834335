import { ValueOverTimeChart } from "@frontend/ui";
import { useStablecoinDataContext } from "../../provider";

const MarketCapChart = () => {
  const { isLoading, history, tokenSymbol } = useStablecoinDataContext();

  return (
    <ValueOverTimeChart
      isLoading={isLoading}
      title={`${tokenSymbol} Market Cap`}
      currency="USD"
      yAxisMax
      yAxisMin
      showLegend={false}
      series={[
        {
          label: "Market Cap",
          data: (history || []).map((h) => [h.date * 1000, h.marketCap || 0]),
        },
      ]}
    />
  );
};

export default MarketCapChart;
