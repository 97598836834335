import { Box } from "@frontend/ui";
import { AssetClassBreakdownChart } from "./components/asset-class-breakdown-chart";
import { CollateralCompositionChart } from "./components/collateral-composition-chart";
import { CollateralRatioChart } from "./components/collateral-ratio-chart";
import { MarketBreakdownChart } from "./components/market-breakdown-chart";
import { useClientConfig } from "../../clients-config";
import { StableCoinCollateralChart } from "../../types";

const getChartNode = (chart: StableCoinCollateralChart) => {
  switch (chart) {
    case "assetBreakdown":
      return <CollateralCompositionChart key={chart} />;
    case "assetClassBreakdown":
      return <AssetClassBreakdownChart key={chart} />;
    case "collateralRatio":
      return <CollateralRatioChart key={chart} />;
    case "marketBreakdown":
      return <MarketBreakdownChart key={chart} />;
    default:
      return null;
  }
};

export const CollateralComposition = () => {
  const { stableCoin } = useClientConfig();
  const { charts = [] } = { ...stableCoin?.collateral };

  if (!charts.length) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          sx: "1fr",
          md: "1fr 1fr",
        },
        "& >.MuiBox-root:first-child": {
          gridColumn: charts.length % 2 === 1 ? "1 / 3" : 1,
        },
      }}
    >
      {charts.map(getChartNode)}
    </Box>
  );
};
