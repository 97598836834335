import { useParams } from "react-router-dom";
import { RouteParams } from "src/config/routes";
import { PoolBreadcrumb } from "src/components/pool-breadcrumb";
import { PoolTokensPair } from "src/components/pool-tokens-pair";
import { PageHeader } from "@frontend/ui/page-header";
import { useMediaQuery, Theme } from "@frontend/ui";

type Props = {
  token1: string;
  token2: string;
  subtitle?: string;
  backRoute: string;
  suffixComponent?: React.ReactNode;
};

const PoolPageHeader = ({ token1, token2, subtitle, backRoute, suffixComponent }: Props) => {
  const { clientName } = useParams<{ clientName: string }>();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const breadcrumbsLinks = [
    {
      title: "Pools",
      href: backRoute.replace(RouteParams.ClientName, clientName!),
    },
  ];

  return (
    <PageHeader
      pageTitle={`${token1} / ${token2}`}
      pageSubtitle={subtitle ?? undefined}
      breadcrumbTitle={<PoolBreadcrumb token1={token1} token2={token2} />}
      breadcrumbsLinks={breadcrumbsLinks}
      icon={<PoolTokensPair token1={token1} token2={token2} size={isMobile ? 48 : 64} />}
      suffixComponent={suffixComponent}
    />
  );
};

export default PoolPageHeader;
