import { RenderData, Header } from "@frontend/types";
import { chainCell, currencyCell, iconsCell, numberCell, percentCell, textCell } from "@frontend/ui";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { MarketData } from "src/pages/ccar-lending-page/generated";

type TableDataFn = (market: MarketData) => RenderData;
type TableField = [Header, TableDataFn];
type TableFieldKey =
  | "type"
  | "chain"
  | "market"
  | "borrowAssets"
  | "collateralAssets"
  | "totalCollateral"
  | "totalBorrow"
  | "tvl"
  | "walletsAtRisk"
  | "collateralAtRisk"
  | "borrowCap"
  | "utilizationRate"
  | "collateralDebtRatio";

type ConfigType = "lending" | "stablecoin";
type TableFields = Record<TableFieldKey, TableField>;

export const useTableFields = (isChainMarket: boolean, hasCollateralLabel?: boolean): TableFields => ({
  type: [
    {
      renderType: "TEXT",
      text: "Type",
      width: "7%",
    },
    (market) => textCell(market.type ?? ""),
  ],
  chain: [
    {
      renderType: "TEXT",
      text: "Chain",
    },
    (market) => chainCell(market.chain, market.chain),
  ],
  market: [
    {
      renderType: "TEXT",
      text: "Market",
      width: "15%",
    },
    (market) => (isChainMarket ? chainCell(market.name, market.name) : textCell(market.name)),
  ],
  collateralAssets: [
    {
      renderType: "TEXT",
      text: "Collateral Assets",
      isAutocomplete: true,
      width: "180px",
    },
    (market) => iconsCell(market.collateralableAssets),
  ],
  borrowAssets: [
    {
      renderType: "TEXT",
      text: "Borrow Assets",
      isAutocomplete: true,
      width: "180px",
    },
    (market) => iconsCell(market.borrowableAssets),
  ],
  totalCollateral: [
    {
      renderType: "TEXT",
      text: hasCollateralLabel ? "Total Collateral" : "Total Supply",
      prefix: "$",
    },
    (market) => currencyCell(market.totalSupplyUsd),
  ],
  totalBorrow: [
    {
      renderType: "TEXT",
      text: "Total Borrow",
      prefix: "$",
    },
    (market) => currencyCell(market.totalBorrowUsd),
  ],
  tvl: [
    {
      renderType: "TEXT",
      text: "TVL",
      prefix: "$",
    },
    (market) => currencyCell(market.totalSupplyUsd - market.totalBorrowUsd),
  ],
  walletsAtRisk: [
    {
      renderType: "TEXT",
      text: "Wallets at Risk",
    },
    (market) => numberCell(market.numberOfWalletsAtRisk || 0),
  ],
  collateralAtRisk: [
    {
      renderType: "TEXT",
      text: "Collateral at Risk",
      prefix: "$",
    },
    (market) => currencyCell(market.totalCollateralAtRiskUsd || 0),
  ],
  borrowCap: [
    {
      renderType: "TEXT",
      text: "Borrow Cap",
      prefix: "$",
    },
    (market) => currencyCell(market.borrowCap),
  ],
  utilizationRate: [
    {
      renderType: "TEXT",
      text: "% Borrowed",
    },
    (market) => percentCell(Math.min(market.utilizationRate || 0, 1)),
  ],
  collateralDebtRatio: [
    {
      renderType: "TEXT",
      text: "Collateral Ratio",
    },
    (market) => percentCell(market.totalSupplyUsd / market.totalBorrowUsd),
  ],
});

export const useTableConfig = (type: ConfigType, markets: MarketData[]) => {
  const { marketType, hasCollateralLabel, showChainMarketsBreakdown } = useClientConfig();
  const isChainMarket = marketType === "ChainMarket";
  const hasMarketType = markets?.some((market) => !!market.type);
  const fields = useTableFields(isChainMarket, hasCollateralLabel);

  let columns: TableFieldKey[] = [];
  let initialSortBy = 0;
  switch (type) {
    case "lending":
      columns = [
        "market",
        "collateralAssets",
        "borrowAssets",
        "totalCollateral",
        "totalBorrow",
        "tvl",
        "walletsAtRisk",
        "collateralAtRisk",
      ];

      initialSortBy = 4;
      if (showChainMarketsBreakdown) {
        columns = ["chain", ...columns];
        initialSortBy = 5;
      }
      if (hasMarketType) {
        columns = ["type", ...columns];
        initialSortBy = 5;
      }
      break;
    case "stablecoin":
      initialSortBy = 4;
      columns = [
        "market",
        "collateralAssets",
        "borrowAssets",
        "totalCollateral",
        "totalBorrow",
        "borrowCap",
        "utilizationRate",
        "collateralDebtRatio",
        "collateralAtRisk",
      ];
      break;
    default:
      break;
  }

  const headers = columns.map((c) => fields[c][0]);
  const fns = columns.map((c) => fields[c][1]);
  const getHeaders = () => headers;
  const getRowData = (market: MarketData) => fns.map((fn) => fn(market));

  return {
    initialSortBy,
    getHeaders,
    getRowData,
  };
};
