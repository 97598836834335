import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { CreditManagerCards, CreditManagerDetailsHeader } from "./components";
import CreditAccountsTable from "../../components/credit-accounts-table";
import { useCreditManagerDetailsQuery, usePoolQuery } from "../../generated";
import { getPoolCreditMangerName, getPoolName } from "../../utils";

const CreditManagerDetails = () => {
  const { poolId, creditManagerId } = useParams();
  const { data } = usePoolQuery({
    variables: {
      poolId: poolId!,
    },
  });

  const poolData = data?.poolData;

  const creditManagerQueryResponse = useCreditManagerDetailsQuery({
    variables: {
      poolId: poolId!,
      creditManagerId: creditManagerId!,
    },
  });

  const creditManagerData = creditManagerQueryResponse?.data?.creditManagerDetails;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <CreditManagerDetailsHeader
        poolName={getPoolName(poolData)}
        creditManagerName={getPoolCreditMangerName(creditManagerData?.name, poolData)}
      />
      <CreditManagerCards data={creditManagerData} poolData={poolData} />
      <CreditAccountsTable creditManagerId={creditManagerId} />
    </Box>
  );
};

export default CreditManagerDetails;
