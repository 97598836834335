import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { dataFetchersURL } from "src/utils/data-fetchers-url";

export interface AlertWebhook {
  name: string;
  url: string;
  ccarClients?: string[];
  allClientsEnabled: boolean;
  allAlertsEnabled: boolean;
}

export const useAlertWebhooks = (): {
  data: AlertWebhook[] | undefined;
  loading: boolean;
  refresh?: () => Promise<void>;
} => {
  const [webhooks, setWebhooks] = useState<AlertWebhook[]>();
  const [loadingWebhooks, setLoadingWebhooks] = useState(true);

  const getWebhooks = async () => {
    const res = await axios.get<AlertWebhook[]>(`${dataFetchersURL()}data/webhooks`, {
      withCredentials: true,
    });
    return res.data;
  };

  const fetchWebhooks = useCallback(async () => {
    setLoadingWebhooks(true);
    try {
      const data = await getWebhooks();
      setWebhooks(data);
    } catch (e) {
      setWebhooks([]);
    }
    setLoadingWebhooks(false);
  }, []);

  useEffect(() => {
    void fetchWebhooks();
  }, [fetchWebhooks]);

  return {
    data: webhooks,
    loading: loadingWebhooks,
    refresh: fetchWebhooks,
  };
};
