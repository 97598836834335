import { PieChart } from "@frontend/ui";
import { useAvsDataQuery } from "../../../../generated";

const TvrPieChart = () => {
  const { data, loading } = useAvsDataQuery();

  return (
    <PieChart
      title="AVS TVR"
      series={[
        {
          data: (data?.avsData || []).map(
            (c) => [c.name, c.protocols.find((p) => p.protocol === "EtherFi")?.delegationEth || 0] as [string, number],
          ),
        },
      ]}
      cryptoCurrency="ETH"
      showLegendCryptoIcon
      isLoading={loading}
    />
  );
};

export default TvrPieChart;
