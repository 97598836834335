import { Grid, Paper } from "@frontend/ui";
import { PoolTokens } from "./types";
import PoolTokensRatio from "./pool-tokens-ratio";
import PooledTokensRate from "./pooled-tokens-rate";

type Props = {
  data: PoolTokens;
  isPriceAlreadyAdjusted?: boolean;
};

const PoolTokensStatsCard = ({ data, isPriceAlreadyAdjusted = false }: Props) => (
  <Paper variant="card" sx={{ display: "flex" }}>
    <Grid container spacing={3}>
      <Grid item>
        <PoolTokensRatio
          fromToken={data.token1}
          toToken={data.token2}
          isPriceAlreadyAdjusted={isPriceAlreadyAdjusted}
        />
      </Grid>
      <Grid item>
        <PoolTokensRatio
          fromToken={data.token2}
          toToken={data.token1}
          isPriceAlreadyAdjusted={isPriceAlreadyAdjusted}
        />
      </Grid>
      {data.token1.amount && data.token2.amount && (
        <Grid item>
          <PooledTokensRate data={data} />
        </Grid>
      )}
    </Grid>
  </Paper>
);

export default PoolTokensStatsCard;
