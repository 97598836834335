import { Box, CryptoIcon, Tooltip, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";

type TokenProps = {
  symbol: string;
  price: number;
};
type Props = {
  fromToken: TokenProps;
  toToken: TokenProps;
  isPriceAlreadyAdjusted?: boolean;
  showTitle?: boolean;
};

const PoolTokensRatio = ({ fromToken, toToken, isPriceAlreadyAdjusted = false, showTitle = true }: Props) => {
  const ratioPrice = isPriceAlreadyAdjusted ? toToken.price : fromToken.price / toToken.price;

  return (
    <Box display="flex" flexDirection="column">
      {showTitle && <Typography variant="caption">{`${fromToken.symbol} to ${toToken.symbol}`}</Typography>}
      <Box display="flex" gap={1}>
        <Tooltip title={!showTitle && fromToken.symbol}>
          <CryptoIcon
            icon={fromToken.symbol.toLowerCase()}
            sx={{
              img: { width: "24px", height: "24px" },
            }}
          />
        </Tooltip>
        <Typography variant="h6">{`1 = ${formatAmount(ratioPrice, { notation: "standard" })}`}</Typography>
        <Tooltip title={!showTitle && toToken.symbol}>
          <CryptoIcon
            icon={toToken.symbol.toLowerCase()}
            sx={{
              img: { width: "24px", height: "24px" },
            }}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default PoolTokensRatio;
