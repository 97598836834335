import { Box } from "@frontend/ui";
import { AvsCards, OperatorsTable } from "./components";

const AvsDetails = () => (
  <Box display="flex" flexDirection="column" gap={3}>
    <AvsCards />
    <OperatorsTable />
  </Box>
);

export default AvsDetails;
