import { FC } from "react";
import { IconButton } from "../icon-button";
import { CustomIcon } from "../custom-icon";
import { palette } from "../theme/palette";

type CopyButtonProps = {
  text: string;
  color?: "white" | "almostWhite";
  onCopy: (message: string) => void;
};

export const CopyButton: FC<CopyButtonProps> = ({ text, color, onCopy }) => (
  <IconButton
    size="small"
    onClick={(e) => {
      e.preventDefault();
      void navigator.clipboard.writeText(text).catch((error) => {
        console.error(`Failed copying ${text} to clipboard:`, error);
      });
      onCopy(`Copied "${text}" to clipboard`);
    }}
  >
    <CustomIcon
      icon="copy"
      sx={{
        svg: {
          path: {
            fill: color === "almostWhite" ? palette.almostWhite.main : palette.white.main,
          },
        },
      }}
    />
  </IconButton>
);
