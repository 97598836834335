import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { TimeSpan, useCollateralDistributionOverTimeQuery } from "src/pages/ccar-lending-page/generated";

type Props = {
  timeSpan?: TimeSpan;
};

export const CollateralOverTime = ({ timeSpan }: Props) => {
  const { data, loading } = useCollateralDistributionOverTimeQuery({
    variables: {
      timeSpan: timeSpan || null,
    },
  });
  const assetCollateralHistory =
    data?.collateralDistributionOverTime?.map((item) => ({
      asset: item.asset,
      supply: item.collateralHistory,
    })) || [];

  return (
    <Paper variant="widget">
      <CompositionOverTimeChart
        isLoading={loading}
        title="Backing Collateral Composition"
        description="Exposure to assets over time across all markets"
        currency="USD"
        percentToggle
        series={assetCollateralHistory.map((distribution) => ({
          label: distribution.asset,
          data: distribution.supply.map((b) => [b.time * 1000, b.value]),
        }))}
      />
    </Paper>
  );
};
