import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { Chain } from "../ccar-lending-page/generated";
import {
  aaveParams,
  aaveParamsConfig,
  benqiParams,
  benqiParamsConfig,
  Client,
  ParameterRecommendationsClientConfig,
} from "./types";

const aaveConfig: ParameterRecommendationsClientConfig = {
  endpointsPrefix: "aavev3",
  isMultiChain: true,
  isMultiMarket: true,
  isChainMarket: true,
  publicSimulations: true,
  publicSimulationsType: "AaveParamRecommendations",
  simulationParams: aaveParams,
  simulationParamsConfig: aaveParamsConfig,
  onChainSimulationEnabled: true,
  hasProposedAssets: true,
  methodology: {
    link: RoutePath.ParameterRecommendations.Methodology.replace(RouteParams.ClientName, "aave"),
    backPageTitle: "Recommendations",
    methodologyTitle: "Methodology",
    methodologyIntro: `Chaos Labs is running hundreds of thousands of agent-based blockchain
      simulations to examine how different values for Aave V3 risk 
      parameters would behave under adverse market conditions - and find the 
      optimal values to maximize protocol borrow usage while minimizing losses 
      from liquidations and bad debt`,
    methodologyFileName: "aave-methodology.md",
    redirectBack: RoutePath.Risk.MarketDetailsRecommendations().replace(RouteParams.MarketId, "Ethereum"),
  },
  capsMethodologyUrl: "https://governance.aave.com/t/chaos-labs-updated-supply-and-borrow-cap-methodology/11602",
};

const benqiConfig: ParameterRecommendationsClientConfig = {
  endpointsPrefix: "benqi",
  isMultiChain: false,
  isMultiMarket: false,
  chain: Chain.Avalanche,
  publicSimulations: true,
  publicSimulationsType: "BenqiParamRecommendations",
  simulationParams: benqiParams,
  simulationParamsConfig: benqiParamsConfig,
  distributionHistogramIncludeZerosDefault: true,
  assetsTableConfig: {
    showRecommendationsDiffsColumns: true,
    hideChaosUtilityColumn: true,
  },
  methodology: {
    link: RoutePath.ParameterRecommendations.Methodology.replace(RouteParams.ClientName, "benqi"),
    backPageTitle: "Recommendations",
    methodologyTitle: "Methodology",
    methodologyIntro: `Chaos Labs runs hundreds of thousands of agent-based blockchain simulations to examine 
      how the BENQI protocol behaves under various risk parameter settings in adverse market conditions. 
      This process allows us to find the optimal values to maximize protocol borrow usage while 
      minimizing losses from liquidations and bad debt.`,
    methodologyFileName: "benqi-methodology.md",
    redirectBack: RoutePath.Risk.MarketDetailsRecommendations().replace(RouteParams.MarketId, "Avalanche"),
  },
};

const venusConfig: ParameterRecommendationsClientConfig = {
  endpointsPrefix: "venus",
  isMultiChain: false,
  isMultiMarket: true,
  chain: Chain.Binance,
  publicSimulations: true,
  publicSimulationsType: "VenusParamRecommendations",
  simulationParams: benqiParams,
  simulationParamsConfig: benqiParamsConfig,
  distributionHistogramIncludeZerosDefault: true,
  assetsTableConfig: {
    showRecommendationsDiffsColumns: true,
    hideChaosUtilityColumn: true,
  },
  methodology: {
    link: RoutePath.ParameterRecommendations.Methodology.replace(RouteParams.ClientName, "venus"),
    backPageTitle: "Recommendations",
    methodologyTitle: "Methodology",
    methodologyIntro: `Chaos Labs runs hundreds of thousands of agent-based blockchain simulations to examine 
      how the Venus protocol behaves under various risk parameter settings in adverse market conditions. 
      This process allows us to find the optimal values to maximize protocol borrow usage while 
      minimizing losses from liquidations and bad debt.`,
    methodologyFileName: "venus-methodology.md",
    redirectBack: RoutePath.Risk.MarketDetailsRecommendations().replace(RouteParams.MarketId, "BNB-CorePool"),
  },
};

const seamlessConfig: ParameterRecommendationsClientConfig = {
  endpointsPrefix: "seamless",
  isMultiChain: true,
  isMultiMarket: true,
  isChainMarket: true,
  publicSimulations: true,
  publicSimulationsType: "SeamlessParamRecommendations",
  simulationParams: aaveParams,
  simulationParamsConfig: aaveParamsConfig,
  onChainSimulationEnabled: true,
  hasProposedAssets: true,
  methodology: {
    link: RoutePath.ParameterRecommendations.Methodology.replace(RouteParams.ClientName, "seamless"),
    backPageTitle: "Recommendations",
    methodologyTitle: "Methodology",
    methodologyIntro: `Chaos Labs is running hundreds of thousands of agent-based blockchain
      simulations to examine how different values for Seamless risk 
      parameters would behave under adverse market conditions - and find the 
      optimal values to maximize protocol borrow usage while minimizing losses 
      from liquidations and bad debt`,
    methodologyFileName: "seamless-methodology.md",
    redirectBack: RoutePath.Risk.MarketDetailsRecommendations().replace(RouteParams.MarketId, "Base"),
  },
  capsMethodologyUrl: "https://governance.aave.com/t/chaos-labs-updated-supply-and-borrow-cap-methodology/11602",
};

export const clientsConfig: Record<Client, ParameterRecommendationsClientConfig> = {
  aave: aaveConfig,
  benqi: benqiConfig,
  venus: venusConfig,
  seamless: seamlessConfig,
};

export const useClientConfig = () => {
  const { clientName } = useParams<{ clientName: Client }>();
  return clientsConfig[clientName!];
};
