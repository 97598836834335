import { v4 as uuidv4 } from "uuid";
import { AddressOverviewCardInterface, ChartData, ChartType } from "@frontend/types";
import { Loader } from "../loader";
import { TokenCard, TokenCardProps } from "../token-card";
import { ChaosTable } from "../chaos-table";
import { LinkableCard, OverviewCardWithIcon, StaticCard } from "../overview-card";
import {
  AreaChartRow,
  HistogramChartRow,
  PieChartContainer,
  LineChart,
  ScatterChartRow,
  TokenTreeMaps,
  TokenTreeMapsProps,
  DataItem,
} from "../charts";
import { Typography } from "../typography";
import { Box } from "../box";
import { Grid } from "../grid";

const TitleSection = ({ title }: { title?: string }) => (
  <Grid item xs={12}>
    <Box position="relative">{title && <Typography variant="h2">{title}</Typography>}</Box>
  </Grid>
);

const mapDataToComponent = (
  data: ChartData,
  chartType: ChartType,
  baseUrl: string,
  idx: number,
): JSX.Element | null => {
  const {
    chartTitle,
    chartSubtitle,
    chartDescription,
    chartColor,
    // eslint-disable-next-line
    dataPayload,
    hideChartLegends,
    hideXAxisLabels,
    hidePoints,
  } = data;

  switch (chartType) {
    case ChartType.VictoryLineChart:
      return (
        <LineChart
          baseUrl={baseUrl}
          // eslint-disable-next-line
          title={dataPayload?.title || ""}
          // eslint-disable-next-line
          data={dataPayload?.data}
          description={chartDescription}
          // eslint-disable-next-line
          xAxis={dataPayload?.xAxis}
          // eslint-disable-next-line
          yAxis={dataPayload?.yAxis}
          hidePoints={hidePoints}
          // eslint-disable-next-line
          callbacks={dataPayload?.callbacks}
        />
      );
    case ChartType.AreaChartRow:
      return (
        <AreaChartRow
          key={`area-chart-${idx}`}
          title={chartTitle?.value || ""}
          // eslint-disable-next-line
          data={dataPayload}
          color={chartColor || undefined}
        />
      );
    case ChartType.TokenTreeMaps:
      return (
        <TokenTreeMaps
          title={chartTitle?.value}
          description={chartDescription}
          data={dataPayload as TokenTreeMapsProps["data"]}
        />
      );
    case ChartType.HistogramChartRow:
      // eslint-disable-next-line
      if (!dataPayload || !Array.isArray(dataPayload.data)) {
        return null;
      }
      return (
        <HistogramChartRow
          // eslint-disable-next-line
          title={chartTitle?.value || dataPayload?.title || ""}
          key={`histogram-${idx}`}
          // eslint-disable-next-line
          data={dataPayload?.data}
          // eslint-disable-next-line
          legend={dataPayload?.legends ? dataPayload.legends : []}
          description={chartDescription}
          // eslint-disable-next-line
          xAxis={dataPayload?.xAxis}
          // eslint-disable-next-line
          yAxis={dataPayload?.yAxis}
          // eslint-disable-next-line
          showTokenFilter={dataPayload?.isTokenData}
          // eslint-disable-next-line
          hideXAxisLabels={hideXAxisLabels}
        />
      );
    case ChartType.OverviewWithIconChart: {
      if (!Array.isArray(dataPayload)) {
        return null;
      }
      return (
        <Grid container key={`overview-with-icon-${idx}`} spacing={3}>
          {(chartTitle?.value || chartDescription) && <TitleSection title={chartTitle?.value} />}
          {dataPayload.map((chart, i) => (
            // eslint-disable-next-line
            <Grid item xs={12} xl={6} key={chart.chartTitle.value || `overview-chart-${i}`}>
              <OverviewCardWithIcon
                index={i}
                // eslint-disable-next-line
                title={chart.chartTitle.value}
                // eslint-disable-next-line
                value={chart.chartSubtitle?.value}
                // eslint-disable-next-line
                count={chart.chartSubtitle?.value}
                // eslint-disable-next-line
                delta={`${chart.chartDelta?.key}: ${chart.chartDelta?.value}`}
                // eslint-disable-next-line
                subtitle={`${chart.chartSubtitle?.key || undefined}: ${
                  // eslint-disable-next-line
                  chart.chartSubtitle?.value || undefined
                }`}
                // eslint-disable-next-line
                detail={`${chart.chartDetail?.key}: ${chart.chartDetail?.value}`}
                icon={`${
                  // eslint-disable-next-line
                  chart.chartTitle.value === undefined
                    ? null
                    : // eslint-disable-next-line
                      chart.chartTitle.value.toLowerCase()
                }`}
              />
            </Grid>
          ))}
        </Grid>
      );
    }
    case ChartType.AddressRow: {
      if (!Array.isArray(dataPayload)) {
        return null;
      }
      return (
        <Grid key={`address-row-${idx}`} spacing={3} container>
          {(chartTitle?.value || chartDescription) && <TitleSection title={chartTitle?.value} />}
          {dataPayload.map((addy: AddressOverviewCardInterface, i) => (
            <Grid item xs={12} xl={6} key={addy.title || `linkable-card-${i}`}>
              <LinkableCard
                index={i}
                title={addy.title}
                subtitle={addy.address}
                link={`https://etherscan.io/address/${addy.address}`}
              />
            </Grid>
          ))}
        </Grid>
      );
    }
    case ChartType.Table: {
      // eslint-disable-next-line
      if (!dataPayload || !dataPayload.data?.length) {
        return null;
      }
      // eslint-disable-next-line
      const initialSortBy = dataPayload.headers.find(
        ({ sortDirection }: { sortDirection: "ASC" | "DESC" }) => sortDirection,
      );

      return (
        <ChaosTable
          key={`chaos-table-${idx}`}
          // eslint-disable-next-line
          headers={dataPayload.headers}
          // eslint-disable-next-line
          data={dataPayload.data}
          // eslint-disable-next-line
          title={dataPayload.title || data.chartTitle?.value}
          // eslint-disable-next-line
          titleIcon={dataPayload.titleIcon}
          rowHeight={56}
          description={chartDescription}
          // eslint-disable-next-line
          initialSortBy={initialSortBy && dataPayload.headers.indexOf(initialSortBy)}
          // eslint-disable-next-line
          isInitialSortDesc={initialSortBy && initialSortBy.sortDirection === "DESC"}
        />
      );
    }
    case ChartType.ScatterChart: {
      // eslint-disable-next-line
      if (!dataPayload || !dataPayload.data?.length) {
        return null;
      }
      return (
        <ScatterChartRow
          key={`scatter-chart-${idx}`}
          // eslint-disable-next-line
          data={dataPayload.data}
          // eslint-disable-next-line
          xAxis={dataPayload.xAxis}
          // eslint-disable-next-line
          yAxis={dataPayload.yAxis}
          // eslint-disable-next-line
          title={dataPayload.title}
          description={chartDescription}
        />
      );
    }
    case ChartType.TokenCards: {
      if (!dataPayload) {
        return null;
      }
      return (
        <Grid key={`token-cards-${idx}`} container spacing={{ xs: 2, lg: 4 }}>
          {(chartTitle || chartDescription) && (
            <TitleSection title={typeof chartTitle === "string" ? chartTitle : chartTitle?.value} />
          )}
          {(dataPayload as TokenCardProps[]).map((payload) => (
            <Grid key={payload.title} item xs={12} lg={6} xl={4}>
              <TokenCard {...payload} />
            </Grid>
          ))}
        </Grid>
      );
    }
    case ChartType.PieChart: {
      if (!Array.isArray(dataPayload)) {
        return null;
      }
      return (
        <Grid key={`pie-chart-${idx}`} container spacing={4}>
          <PieChartContainer
            {...data}
            dataPayload={data.dataPayload as DataItem[]}
            chartTitle={chartTitle}
            chartSubtitle={{ key: "", value: chartSubtitle?.value || "" }}
            columnsToFill={12}
            hideChartLegends={hideChartLegends}
          />
        </Grid>
      );
    }
    default:
      return null;
  }
};

const mapMultiDataToComponent = (data: Array<ChartData>, chartType: ChartType, idx: number): JSX.Element | null => {
  const currentData = data[0];
  if (!currentData) {
    return null;
  }
  // Multiple Charts in Single Row Handling
  if (currentData.chartType === ChartType.PieChartRow) {
    const [leftChart, rightChart] = data;
    return (
      <Grid key={`pie-chart-${idx}`} container spacing={3}>
        {leftChart ? (
          <PieChartContainer
            {...leftChart}
            dataPayload={leftChart.dataPayload as DataItem[]}
            chartSubtitle={{ key: "", value: leftChart.chartSubtitle?.value || "" }}
            chartTitle={leftChart.chartTitle}
            hideChartLegends={leftChart.hideChartLegends}
          />
        ) : null}
        {rightChart ? (
          <PieChartContainer
            {...rightChart}
            dataPayload={leftChart.dataPayload as DataItem[]}
            chartSubtitle={{ key: "", value: rightChart.chartSubtitle?.value || "" }}
            chartTitle={rightChart.chartTitle}
            hideChartLegends={rightChart.hideChartLegends}
          />
        ) : null}
      </Grid>
    );
  }

  if (chartType === ChartType.OverviewCardRow) {
    return (
      <Grid key={`overview-card-${idx}`} spacing={3} container>
        {data.map((chart, i) => (
          <Grid item xs={12} xl={6} key={chart.chartTitle?.key || `overview-card-item-${i}`}>
            <StaticCard
              index={chart.chartTitle?.value}
              title={chart.chartTitle?.value || ""}
              // eslint-disable-next-line
              subtitle={chart.dataPayload}
              detail={chart.chartDetail?.value || ""}
              delta=""
              link=""
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return null;
};

export const DashboardBuilder = (
  content: ChartData[][],
  description: string | undefined,
  currentDashboardName: string | undefined,
  baseUrl: string,
): Array<JSX.Element | null> | JSX.Element => {
  if (currentDashboardName === undefined) {
    return <Loader />;
  }

  const chartList = content.map((data, i) => {
    const res = [];
    const currentData = data[0];

    if (!currentData) {
      return null;
    }

    const { chartType } = currentData;

    if (
      chartType === ChartType.PieChartRow ||
      chartType === ChartType.PairsRow ||
      chartType === ChartType.OverviewCardRow
    ) {
      res.push(mapMultiDataToComponent(data, chartType, i));
    }

    res.push(mapDataToComponent(currentData, chartType, baseUrl, i));

    return res;
  });
  const flattened = chartList.flat().filter((chart) => !!chart);
  return flattened.length > 1 ? (
    <Grid container spacing={{ xs: 2, lg: 4 }} flex={1}>
      {description && (
        <Grid item xs={12}>
          <Typography>{description}</Typography>
        </Grid>
      )}
      {flattened.map((item) => (
        <Grid key={uuidv4()} item xs={12}>
          {item}
        </Grid>
      ))}
    </Grid>
  ) : (
    flattened
  );
};
