import { TimeSpan } from "@frontend/ui/echarts/types";
import { useAprHistoryQuery } from "../../../../generated";
import { HistoryChart } from "../../../../components/history-chart";

type Props = {
  timeSpan: TimeSpan;
};

const AprChart = ({ timeSpan }: Props) => {
  const { loading, data } = useAprHistoryQuery({
    variables: {
      timeSpan,
    },
  });

  return (
    <HistoryChart
      title="APR Over Time"
      label="APR"
      loading={loading}
      data={data?.aprHistory}
      timeSpan={timeSpan}
      isPercent
    />
  );
};

export default AprChart;
