import { Paper, ValueOverTimeChart, Grid } from "@frontend/ui";
import { Chain, useAssetPriceImpactHistoryQuery } from "src/pages/ccar-lending-page/generated";
import { formatOnChainLiquidityData } from "./utils";

type Props = {
  token: string;
  compareToken: string;
  chain: string;
};

export const OnChainLiquidityCharts = ({ token, compareToken, chain }: Props) => {
  const { loading: loadingPriceImpact, data: priceImpactData } = useAssetPriceImpactHistoryQuery({
    variables: {
      currentToken: token,
      compareToken,
      chain: chain as Chain,
    },
    skip: !compareToken.length,
  });

  return (
    <Grid container spacing={[2, 3]}>
      <Grid item xs={12} md={6}>
        <Paper variant="widget">
          <ValueOverTimeChart
            key={compareToken}
            title={`${token}/${compareToken} Sell Price Impact`}
            description="Token amount that can be sold with a given price impact."
            series={formatOnChainLiquidityData(priceImpactData?.sellPriceImpactHistory)}
            isLoading={loadingPriceImpact || !priceImpactData}
            emptyState={priceImpactData && !priceImpactData?.sellPriceImpactHistory.length}
            currency=""
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper variant="widget">
          <ValueOverTimeChart
            key={compareToken}
            title={`${token}/${compareToken} Buy Price Impact`}
            description="Token amount that can be bought with given price impact."
            series={formatOnChainLiquidityData(priceImpactData?.buyPriceImpactHistory)}
            isLoading={loadingPriceImpact || !priceImpactData}
            emptyState={priceImpactData && !priceImpactData?.buyPriceImpactHistory.length}
            currency=""
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
