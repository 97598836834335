import Editor from "@monaco-editor/react";
import { FC, useEffect, useState } from "react";
import { Stack, FormHelperText } from "@mui/material";
import { InputLabel } from "../input-label";

interface JsonEditorProps {
  value: object;
  onChange: (value: object) => void;
  width?: number | string;
  height?: number | string;
  disabled?: boolean;
  label?: string;
  helperText?: string;
  error?: boolean;
  errorMsg?: string;
}

export const JsonEditor: FC<JsonEditorProps> = ({
  value,
  onChange,
  width,
  height,
  disabled,
  label,
  error,
  helperText,
  errorMsg,
}) => {
  const [stringifyValue, setStringifyValue] = useState<string>("");
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      try {
        setStringifyValue(JSON.stringify(value, null, 2));
      } catch (e) {
        setIsInvalid(true);
        setStringifyValue("");
      }
    }
  }, [value]);

  const handleSchemaChange = (newValue: string | undefined) => {
    if (disabled) {
      setStringifyValue((prev) => prev);
    }
    if (newValue) {
      try {
        const parsedValue = JSON.parse(newValue) as object;
        onChange(parsedValue);
        setStringifyValue(newValue ?? "");
      } catch (e) {
        setIsInvalid(true);
      }
    } else {
      setStringifyValue("");
    }
  };

  return (
    <Stack spacing={1}>
      <InputLabel error={error}>{label}</InputLabel>
      <Editor
        height={height}
        width={width}
        defaultLanguage="json"
        language="json"
        theme="vs-dark"
        defaultValue={stringifyValue}
        onChange={handleSchemaChange}
        options={{
          codeLens: false,
        }}
        onValidate={(markers) => setIsInvalid(markers.length > 0)}
      />
      {error && <FormHelperText error>{errorMsg}</FormHelperText>}
      {isInvalid && <FormHelperText error>Invalid JSON</FormHelperText>}
      <FormHelperText>{helperText}</FormHelperText>
    </Stack>
  );
};
