import { ValueCardProps } from "@frontend/ui";
import MultiValueCard from "src/components/multi-values-card/multi-values-card";
import { PegKeeper } from "src/pages/ccar-lending-page/generated";

type Props = {
  pegKeeper: PegKeeper;
};

const PegKeeperOverviewCard = ({ pegKeeper }: Props) => {
  const cardValues: ValueCardProps[] = [
    {
      title: "Provided",
      value: pegKeeper.totalProvided,
    },
    {
      title: "Withdrawn",
      value: pegKeeper.totalWithdrawn,
    },
    {
      title: "Debt",
      value: pegKeeper.debt,
    },
  ];
  return <MultiValueCard values={cardValues} />;
};
export default PegKeeperOverviewCard;
