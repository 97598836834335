import { useLocation } from "react-router-dom";
import { useMarketListedAssetsContext, useMarketAssetsRecommendationsContext } from "../context";

export const useAssetsDropdownOptionsByTab = () => {
  const location = useLocation();

  const { listedAssets, isLoading: isLoadingListedAssets } = useMarketListedAssetsContext();

  const { assetsRecommendations, isLoading: isLoadingAssetsRecommendations } = useMarketAssetsRecommendationsContext();

  const isRecommendationsTab = location.pathname.includes("recommendations");

  const isLoading = isLoadingListedAssets || isLoadingAssetsRecommendations;

  const listedAssetsNames = listedAssets?.map((a) => a.asset);
  const recommendedAssetsNames = assetsRecommendations?.map((d) => d.assetName);

  if (isRecommendationsTab) {
    return { isLoading, assets: recommendedAssetsNames };
  }

  return { isLoading, assets: listedAssetsNames };
};
