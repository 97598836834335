export const getDeltaAsRatio = (value?: number, previousValue?: number, negativeMetric?: boolean) => {
  const hasBoth = value !== undefined && previousValue !== undefined;

  if (hasBoth) {
    const delta = previousValue === 0 ? 0 : Math.abs(value - previousValue) / previousValue;
    const deltaTrend = Math.sign(value - previousValue);

    return {
      isDeltaPercent: true,
      delta,
      deltaTrend,
      deltaColor: negativeMetric ? -1 * deltaTrend : deltaTrend,
    };
  }

  return {};
};

export const getDeltaAsValue = (value?: number, previousValue?: number, negativeMetric?: boolean) => {
  const hasBoth = value !== undefined && previousValue !== undefined;

  if (hasBoth) {
    const delta = Math.abs(value - previousValue);
    const deltaTrend = Math.sign(value - previousValue);

    return {
      delta,
      deltaTrend,
      deltaColor: negativeMetric ? -1 * deltaTrend : deltaTrend,
    };
  }

  return {};
};
