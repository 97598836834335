import { Box } from "@frontend/ui";
import { useFeatureFlag } from "src/utils/feature-flags";
import { useState } from "react";
import { TimeSpan } from "@frontend/ui/echarts/types";
import TimeSpanPicker, {
  defaultTimeSpan,
} from "src/pages/ccar-lending-page/components/time-span-picker/time-span-picker";
import { Chain } from "src/pages/ccar-lending-page/generated";
import { useParams } from "react-router-dom";
import Cards from "./cards";
import { useChartStats } from "../../../overview/use-chart-stats";
import { ChainHistoryStats } from "../../../overview/components";

const ChainOverview = () => {
  const { chain } = useParams<{ chain: Chain }>();
  const hasLongHistory = useFeatureFlag("long_history");
  const [selectedTimeSpan, setSelectedTimeSpan] = useState<TimeSpan>(defaultTimeSpan);
  const timeSpan = hasLongHistory ? selectedTimeSpan : undefined;
  const { chartsStats, isLoading } = useChartStats(timeSpan, chain);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Cards />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {timeSpan && <TimeSpanPicker selectedTimeSpan={timeSpan} onSelectTimeSpan={setSelectedTimeSpan} />}
        <ChainHistoryStats data={chartsStats} isLoading={isLoading} timeSpan={timeSpan} chain={chain} />
      </Box>
    </Box>
  );
};

export default ChainOverview;
