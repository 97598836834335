import { Box } from "@frontend/ui";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { RoutePath } from "../../../config/routes";
import { RouteParams } from "../../../config/route-params";
import { CommunityDashboardLayout } from "../../../components/layouts";
import ChainDropdownHeader from "./chain-dropdown-header";

type Props = {
  children: ReactNode;
};

const GearboxTabsLayout = ({ children }: Props) => {
  const { clientName } = useParams();
  const replaceParams = (path: string) => path.replace(RouteParams.ClientName, clientName!);
  return (
    <CommunityDashboardLayout
      showClientToolsDropdown={false}
      tabs={[
        {
          label: "Overview",
          path: replaceParams(RoutePath.Risk.Overview),
        },
        {
          label: "Chains",
          path: replaceParams(RoutePath.Risk.Chains),
        },
        {
          label: "Pools",
          path: replaceParams(RoutePath.Risk.Pools),
        },
        {
          label: "Credit Accounts",
          path: replaceParams(RoutePath.Risk.CreditAccounts),
        },
      ]}
    >
      {children}
    </CommunityDashboardLayout>
  );
};

export const renderTabsLayout = (children: ReactNode) => <GearboxTabsLayout>{children}</GearboxTabsLayout>;

export const renderChainPageLayout = (children: ReactNode) => (
  <GearboxTabsLayout>
    <Box display="flex" flexDirection="column">
      <ChainDropdownHeader />
      {children}
    </Box>
  </GearboxTabsLayout>
);
export default GearboxTabsLayout;
