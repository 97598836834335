import { useState, useEffect, useCallback } from "react";

interface RefProps {
  current: HTMLDivElement | null;
}

interface SizeProps {
  width: number | undefined;
  height: number | undefined;
}

export const useElementSize = (elRef: RefProps): SizeProps => {
  const [size, setSize] = useState<SizeProps>({
    width: undefined,
    height: undefined,
  });

  const handleResize = useCallback(() => {
    if (elRef?.current) {
      const { width, height } = elRef.current.getBoundingClientRect();
      setSize({ width, height });
    }
  }, [elRef]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return size;
};
