import type { EChartsOption } from "echarts";
import merge from "lodash/merge";
import { ReactNode } from "react";
import { Box } from "../../../box";
import ECharts from "../../echarts";
import { ShimmerLoader } from "../../../shimmer-loader";
import { StringChartProps } from "../../types";
import { getStackedBarOptions } from "./get-stacked-bar-chart-option";
import { downloadCsv } from "../../../utils/csv";
import { transformToCSV } from "../export-utils";
import { ChartHeader } from "../chart-header";
import { ChartWrapper } from "../chart-wrapper";

type Props = {
  title: string;
  description?: string | ReactNode;
  headerSuffix?: ReactNode;
  chartHeight?: number;
  emptyState?: boolean;
  onClick?: (xValue: number) => void;
  option?: EChartsOption;
  hideHeader?: boolean;
} & StringChartProps;

export const StackedBarChart = ({
  title,
  description,
  headerSuffix,
  chartHeight = 420,
  emptyState,
  isLoading,
  onClick,
  option = {},
  hideHeader,
  tooltip,
  ...restProps
}: Props) => {
  const exportToCsv = () => {
    downloadCsv(
      [restProps.xAxisLabel || title, ...restProps.series.map((serie) => serie.label || "")],
      transformToCSV(restProps.series),
      title || "chart_data",
    );
  };
  return (
    <ChartWrapper id={title?.toLowerCase().trim().split(" ").join("-")}>
      {!hideHeader && (
        <ChartHeader title={title} description={description} tooltip={tooltip} exportToCsv={exportToCsv}>
          {headerSuffix}
        </ChartHeader>
      )}
      <Box height={chartHeight}>
        {isLoading ? (
          <ShimmerLoader sx={{ borderRadius: 1 }} />
        ) : (
          <ECharts emptyState={emptyState} option={merge(getStackedBarOptions(restProps), option)} onClick={onClick} />
        )}
      </Box>
    </ChartWrapper>
  );
};
