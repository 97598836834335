import { useState } from "react";
import * as Yup from "yup";
import {
  AssetWithSentiment,
  LpStrategyAssetsQueryResult,
  PriceSentiment,
  UniswapChain,
  useLpStrategyAssetsQuery,
} from "src/pages/uniswap/generated";
import { useFormik, FormikProvider } from "formik";
import { Box, Button, CustomIcon, EmptyState, Paper, Tabs, Typography } from "@frontend/ui";
import { Loader } from "@frontend/ui/loader";
import { FormValues, GenericAssetsExposure } from "../../types";
import AssetExposureSpecificSelect from "./asset-exposure-specific-select";
import AssetExposureGenericSelect from "./asset-exposure-generic-select";
import StrategySelect from "./strategy-select";

type Props = {
  onSubmit: (values: FormValues, showSpecificAssetsExposure: boolean) => Promise<void>;
};

const InputForm = ({ onSubmit }: Props) => {
  const [tab, setTab] = useState(0);
  const showSpecificAssetsExposure = tab === 0;
  const lpStrategyAssetsQueryResult: LpStrategyAssetsQueryResult = useLpStrategyAssetsQuery();
  const initialGenericAssetsExposure: GenericAssetsExposure = {
    marketCap: [],
    volatility: [],
  };

  const initialSpecificAssetsExposure: AssetWithSentiment[] = [
    {
      chain: lpStrategyAssetsQueryResult.data?.lpStrategyAssets[0].chain ?? UniswapChain.Ethereum,
      token: lpStrategyAssetsQueryResult.data?.lpStrategyAssets[0].tokens[0] ?? "-",
      price_sentiment: PriceSentiment.Neutral,
    },
  ];
  const initialValues: FormValues = {
    specificAssetsExposure: showSpecificAssetsExposure ? initialSpecificAssetsExposure : undefined,
    genericAssetsExposure: showSpecificAssetsExposure ? undefined : initialGenericAssetsExposure,
    strategy: {
      costOfCapital: 0,
      positionManagement: false,
    },
  };

  const validationSchema = Yup.object().shape({
    genericAssetsExposure: Yup.object().shape({
      volatility: Yup.array().min(1, "You must choose at least one option of Volatility"),
      marketCap: Yup.array().min(1, "You must choose at least one option of Market Cap"),
    }),
    strategy: Yup.object().shape({
      riskProfile: Yup.string().required("You must select Risk Profile"),
      timeHorizon: Yup.string().required("You must select Time Horizon"),
      costOfCapital: Yup.string().required("You must select Cost Of Capital"),
    }),
  });
  const formik = useFormik<FormValues>({
    onSubmit: (values) => onSubmit(values, showSpecificAssetsExposure),
    initialValues,
    enableReinitialize: true,
    validationSchema,
  });
  const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);

  const {
    isSubmitting,
    isValid,
    errors,
    setFieldValue,
    handleSubmit,
    values: { specificAssetsExposure: assetSentimentValues, strategy },
  } = formik;

  return (
    <Paper
      variant="card"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {lpStrategyAssetsQueryResult.loading ? (
        <Loader />
      ) : (
        <Box>
          {!lpStrategyAssetsQueryResult.data ? (
            <EmptyState />
          ) : (
            <Box display="flex" flexDirection="column" gap={3}>
              <Box display="flex" justifyContent="space-between" gap={2}>
                <Box data-testid="input-header">
                  <Typography variant="h2">Uniswap V3 LP Strategies</Typography>
                  <Typography variant="h6">
                    A simulation-based system, which helps LPs find relevant pools and strategies based on preferences
                    and market sentiments.
                  </Typography>
                  <Typography variant="h6">
                    To get started specify preferences for either specific assets exposure or overall asset preferences
                    and click Calculate.
                  </Typography>
                </Box>
                <Box>
                  <Button
                    sx={{ mr: -1 }}
                    href="https://chaoslabs.xyz/page/chaos-labs-uniswap-v3-backtester-guide"
                    target="_blank"
                    endIcon={<CustomIcon icon="external-link" size="small" />}
                  >
                    View Guide
                  </Button>
                </Box>
              </Box>
              <FormikProvider value={formik}>
                <Box display="flex" flexDirection="column" alignItems="flex-start" gap="inherit">
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    gap={1}
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Tabs
                      value={tab}
                      tabs={[{ label: "Specific Asset Exposure" }, { label: "Asset Preference" }]}
                      onChange={(_, i) => {
                        setIsSubmittedOnce(false);
                        setTab(i);
                      }}
                      data-testid="input-tabs"
                    />
                    <Box data-testid="input-calculate-button" />
                  </Box>
                  {showSpecificAssetsExposure ? (
                    <Box width="100%" data-testid="input-specific-asset-dropdowns">
                      <AssetExposureSpecificSelect
                        lpStrategyAssets={lpStrategyAssetsQueryResult.data.lpStrategyAssets}
                        assetSentimentValues={assetSentimentValues}
                        setFieldValue={setFieldValue}
                      />
                    </Box>
                  ) : (
                    <Box width="100%" data-testid="input-generic-asset-dropdowns">
                      <AssetExposureGenericSelect
                        setFieldValue={setFieldValue}
                        errors={
                          isSubmittedOnce
                            ? (errors.genericAssetsExposure as {
                                volatility?: string;
                                marketCap?: string;
                              })
                            : undefined
                        }
                      />
                    </Box>
                  )}
                  <Box width="100%" data-testid="input-strategy-dropdowns">
                    <StrategySelect
                      strategy={strategy}
                      setFieldValue={setFieldValue}
                      errors={
                        isSubmittedOnce
                          ? (errors.strategy as {
                              riskProfile?: string;
                              timeHorizon?: string;
                              costOfCapital?: string;
                            })
                          : undefined
                      }
                      submitDisabled={isSubmittedOnce && (!isValid || isSubmitting)}
                      onSubmit={() => {
                        setIsSubmittedOnce(true);
                        if (!isValid || isSubmitting) return;
                        handleSubmit();
                      }}
                    />
                  </Box>
                </Box>
              </FormikProvider>
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default InputForm;
