import { Box, CustomIcon, CustomReactSelect, Grid, InputLabel, TextField, Tooltip, Typography } from "@frontend/ui";
import { Controller, useFormContext } from "react-hook-form";
import { Stack, Switch } from "@mui/material";
import { alertTypeLabels } from "../../../../../utils/consts";
import { AlertSettingsForm } from "./types";
import { useAlertSettings } from "../use-settings-table-data";

interface GeneralInformationSectionProps {
  submitting: boolean;
  isNewAlert?: boolean;
}

export const GeneralInformationSection = ({ submitting, isNewAlert }: GeneralInformationSectionProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AlertSettingsForm>();

  const { allAlertIds } = useAlertSettings();

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="alertId"
          control={control}
          rules={{
            required: "Please enter a unique alert ID",
            pattern: {
              value: /^[a-zA-Z][a-zA-Z0-9_-]*$/,
              message: "Alert ID must start with a letter and contain only letters, numbers, and underscores",
            },
            validate: (value) => {
              if (allAlertIds.includes(value) && isNewAlert) {
                return "Alert ID must be unique";
              }
              return true;
            },
          }}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Alert ID"
              error={!!errors.alertId}
              helperText={errors.alertId?.message}
              {...field}
              disabled={!isNewAlert || submitting}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: "Please enter a unique name for the webhook",
            maxLength: {
              value: 100,
              message: "Name must be less than 100 characters",
            },
            minLength: {
              value: 2,
              message: "Name must be at least 2 characters",
            },
          }}
          disabled={submitting}
          render={({ field }) => (
            <TextField fullWidth label="Name" error={!!errors.name} helperText={errors.name?.message} {...field} />
          )}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="level"
          control={control}
          disabled={submitting}
          rules={{
            required: "Please select a level",
          }}
          render={({ field }) => (
            <CustomReactSelect
              controlShouldRenderValue
              label="Level"
              placeholder="Select level"
              options={[
                {
                  label: "Global",
                  value: "GLOBAL",
                },
                {
                  label: "Protocol",
                  value: "PROTOCOL",
                },
              ]}
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="severity"
          control={control}
          disabled={submitting}
          rules={{
            required: "Please select a severity",
          }}
          render={({ field }) => (
            <CustomReactSelect
              controlShouldRenderValue
              label="Severity"
              placeholder="Select sevirity"
              options={[
                {
                  label: "High",
                  value: "HIGH",
                },
                {
                  label: "Info",
                  value: "INFO",
                },
              ]}
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Controller
          name="alertType"
          control={control}
          disabled={submitting}
          rules={{
            required: "Please select an alert type",
          }}
          render={({ field }) => (
            <CustomReactSelect
              controlShouldRenderValue
              label="Type"
              placeholder="Select type"
              options={Object.entries(alertTypeLabels).map(([value, label]) => ({
                value,
                label,
              }))}
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={4} xs={12}>
        <Stack spacing={2}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{
              pt: {
                lg: 4,
                md: 4,
              },
            }}
            gap={1}
          >
            <Controller
              name="isActionable"
              control={control}
              disabled={submitting}
              render={({ field }) => <Switch {...field} checked={field.value} onClick={(e) => e.stopPropagation()} />}
            />
            <Typography variant="body2" color="almostWhite.main">
              Mark this alert as actionable
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="description"
          control={control}
          rules={{
            required: "Please enter a description",
            maxLength: {
              value: 500,
              message: "Name must be less than 500 characters",
            },
          }}
          disabled={submitting}
          render={({ field }) => (
            <Box>
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <InputLabel>Description</InputLabel>
                <Tooltip
                  title="Provide a brief explanation of why this alert is important. Include details such as its purpose, the conditions it monitors, and how it helps in decision-making. Additionally, specify the data sources used for evaluating the alert and highlight any assumptions made about the data."
                  arrow
                >
                  <CustomIcon
                    icon="info"
                    sx={{
                      color: "greys.500",
                      "& path": { fill: "currentColor" },
                      svg: { height: "24px", width: "24px" },
                    }}
                  />
                </Tooltip>
              </Box>
              <TextField
                fullWidth
                multiline
                rows={4}
                error={!!errors.description}
                helperText={errors.description?.message}
                {...field}
              />
            </Box>
          )}
        />
      </Grid>
    </Grid>
  );
};
