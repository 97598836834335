import { useMemo } from "react";
import { CompositionOverTimeChart, Paper } from "@frontend/ui";
import { SeriesType } from "@frontend/ui/echarts/types";
import { GhoGsmAvailableLiquidityQuery, useGhoGsmAvailableLiquidityQuery } from "../../../generated";
import { useStablecoinDataContext } from "../provider";

type GroupedData = Record<string, Array<[number, number]>>;

const useChartSeries = (data: GhoGsmAvailableLiquidityQuery | undefined, isLoading: boolean) =>
  useMemo(() => {
    if (isLoading || !data?.ghoGsmAvailableLiquidity) {
      return [];
    }

    const rawData = data.ghoGsmAvailableLiquidity;

    const groupedData: GroupedData = rawData.reduce((acc, item) => {
      item.gsmAvailableLiquidity.forEach((liquidity) => {
        const { underlyingAsset, availableLiquidityUsd } = liquidity;
        const { timestamp } = item;

        if (!acc[underlyingAsset]) {
          acc[underlyingAsset] = [];
        }

        acc[underlyingAsset].push([timestamp, availableLiquidityUsd]);
      });
      return acc;
    }, {} as GroupedData);

    const series = Object.entries(groupedData).map(([underlyingAsset, dataPoints]) => {
      const sortedData = dataPoints.sort((a, b) => a[0] - b[0]);

      return {
        name: underlyingAsset,
        label: underlyingAsset,
        type: "bar" as SeriesType,
        data: sortedData,
        stack: "total",
      };
    });

    return series;
  }, [data, isLoading]);

export const GhoGsmAvailableLiquidityChart = () => {
  const { timeSpan } = useStablecoinDataContext();
  const { data, loading } = useGhoGsmAvailableLiquidityQuery({
    variables: {
      timeSpan,
    },
  });

  const series = useChartSeries(data, loading);
  return (
    <CompositionOverTimeChart
      title="GSM Liquidity Over Time"
      isLoading={loading}
      yAxisLabel="Liquidity in USD"
      series={series}
    />
  );
};
