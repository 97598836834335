import { FC } from "react";
import { Checkbox, CheckboxProps } from "../checkbox";
import { CustomIcon } from "../custom-icon";

export const CustomCheckbox: FC<CheckboxProps> = (props): JSX.Element => (
  <Checkbox
    icon={<CustomIcon icon="checkbox-off" />}
    checkedIcon={<CustomIcon icon="check-square" />}
    indeterminateIcon={<CustomIcon icon="checkbox-indeterminate" />}
    {...props}
  />
);
