import { FC } from "react";
import { Popover } from "../popover";
import { Paper } from "../paper";

interface CustomPopoverProps {
  anchorEl: HTMLElement | null;
  definition: React.ReactNode;
  onClose?: () => void;
}

export const CustomPopover: FC<CustomPopoverProps> = ({ anchorEl, definition, onClose }) => (
  <Popover
    disableRestoreFocus
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    sx={{ pointerEvents: "none" }}
  >
    <Paper
      variant="card"
      sx={{
        padding: 2,
        maxWidth: 208,
        wordBreak: "break-word",
      }}
    >
      {definition}
    </Paper>
  </Popover>
);
