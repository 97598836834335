import { MarketType } from "../../clients-config/types";
import { BorrowCollateralAssetMarket, ChainAssetMarket, Market } from "../../generated";

export const CHAIN_ASSET_SEPARATOR = "#";
export const BORROW_COLLATERAL_SEPARATOR = "&";

export const createMarketCanonicalName = (market: Partial<Market>, marketType: MarketType) => {
  if (marketType === "BorrowCollateralAssetMarket") {
    const { borrowAsset, collateralAsset } = market as BorrowCollateralAssetMarket;
    return `${borrowAsset}${BORROW_COLLATERAL_SEPARATOR}${collateralAsset.replace(/t/i, "")}`;
  }

  if (marketType === "ChainAssetMarket") {
    const { chain, asset } = market as ChainAssetMarket;
    return `${chain}${CHAIN_ASSET_SEPARATOR}${asset}`;
  }

  return undefined;
};
