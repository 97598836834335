import { formatAmount } from "@frontend/ui/utils/formatters";
import groupBy from "lodash/groupBy";
import { PriceImpactHistoryDataPointFragment } from "src/pages/ccar-lending-page/generated";

export const formatOnChainLiquidityData = (history: PriceImpactHistoryDataPointFragment[] = []) => {
  const grouped = groupBy(history, (price) => price.priceImpact);
  const series = Object.entries(grouped)
    .sort((a, b) => (Number(a[0]) > Number(b[0]) ? 1 : -1))
    .map(([percent, data]) => ({
      label: formatAmount(Number(percent) / 100, { isPercent: true, minimumFractionDigits: 1 }),
      data: data.map((d) => [d.date, d.usdAmount] as [number, number]),
    }));
  return series;
};
