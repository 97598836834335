const validTokens = [
  "mkr",
  "matic",
  "maticlpm",
  "metis",
  "tmaticlpm",
  "win",
  "savax",
  "seamless",
  "susd",
  "shib",
  "stkbnb",
  "stusdt",
  "sdai",
  "manta",
  "slisbnb",
  "woo",
  "scroll",
  "milady",
  "milady_blast",
  "monke_wbtc_blast",
];

export const wrappedSymbolToIconSymbol = (symbol: string) => {
  // metis
  if (symbol.startsWith("m.")) {
    return symbol.substring(2).toLocaleLowerCase();
  }
  const cleanSymbol =
    symbol.indexOf(".") > -1 ? symbol.substring(0, symbol.indexOf(".")).toLocaleLowerCase() : symbol.toLowerCase();

  // moonwell or avalance or benqi
  if (
    (symbol.startsWith("1") ||
      symbol.startsWith("m") ||
      (symbol.startsWith("W") &&
        symbol !== "WAVES" &&
        symbol !== "WLD" &&
        cleanSymbol !== "weth" &&
        cleanSymbol !== "wbtc") ||
      (symbol.startsWith("s") && !symbol.match(/sushi/g) && !symbol.match(/sfrx/g) && !symbol.match(/stETH/g))) &&
    !validTokens.includes(symbol.toLowerCase())
  ) {
    return cleanSymbol.substring(1, symbol.length);
  }

  // benqi
  if (cleanSymbol.startsWith("qi") && cleanSymbol.length > 2 && cleanSymbol !== "qieth") {
    if (cleanSymbol.endsWith("n")) {
      return cleanSymbol.substring(2, symbol.length - 1);
    }

    return cleanSymbol.substring(2, symbol.length);
  }

  return cleanSymbol;
};
