import { FC, useState, ReactElement } from "react";
import { v4 as uuidv4 } from "uuid";
import { Nullable } from "@frontend/types";

import { Box } from "@frontend/ui/box";
import { Paper } from "@frontend/ui/paper";
import { Button } from "@frontend/ui/button";
import { TextField } from "@frontend/ui/text-field";
import { Typography } from "@frontend/ui/typography";
import { ReactComponent as WIcon } from "src/assets/img/equations/w.svg";
import { ReactComponent as RIcon } from "src/assets/img/equations/r.svg";
import { ReactComponent as SumIcon } from "src/assets/img/equations/sum.svg";
import { ReactComponent as EqualIcon } from "src/assets/img/equations/equal.svg";

import { Tooltip } from "@frontend/ui/tooltip";
import { Formula } from "./formula";
import type { CalculatorInitialData, DataTerm } from "./dydx-calculator-page";

interface DyDxEquationData {
  term: DataTerm;
  defaultValue: string;
  definition: string;
  details?: string;
  readOnly?: boolean;
  icon?: ReactElement;
  tooltip?: string;
}

const headers = [
  { name: "Term", flexBasis: "10%" },
  { name: "Definition", flexBasis: "40%" },
  { name: "Details", flexBasis: "40%" },
  { name: "Value", flexBasis: "10%" },
];

const tableData: DyDxEquationData[] = [
  {
    term: "r",
    defaultValue: "",
    definition: "Reward for a specific trader.",
    readOnly: true,
  },
  {
    term: "R",
    defaultValue: "1582192 ",
    definition: "Total reward to be split between all traders in the pool for the epoch.",
    details:
      "1,582,192 $DYDX will be distributed per epoch over five years and is not subject to any vesting or lockups",
  },
  {
    term: "w",
    defaultValue: "",
    definition: "Individual trader score.",
    details:
      "In DIP-13, the dYdX Community voted to simplify the trader score to be based on total fees paid in a given epoch.",
    readOnly: true,
  },
  {
    term: "Sum",
    defaultValue: "",
    definition: "Sum of all trader scores.",
    details:
      "In DIP-13, the dYdX Community voted to simplify the formula to be based on total fees paid in a given epoch.",
    icon: <SumIcon />,
  },
  {
    term: "f",
    defaultValue: "",
    definition: "Total fees paid by a trader in this epoch.",
    details: "Total trader fees paid during current epoch can be viewed on https://trade.dydx.exchange/rewards",
  },
];

interface EquationWithDataProps {
  isHidden: boolean;
  onCreate: () => void;
  onEdit: () => void;
  nextCalculationNumber: number;
  currentData: CalculatorInitialData;
  onDataChange: React.Dispatch<React.SetStateAction<CalculatorInitialData[]>>;
}

export const EquationWithData: FC<EquationWithDataProps> = ({
  isHidden,
  onCreate,
  onEdit,
  currentData,
  onDataChange,
  nextCalculationNumber,
}) => {
  const [errors, setErrors] = useState<DataTerm[]>([]);
  const [focusedElement, setFocusedElement] = useState<Nullable<DataTerm>>(null);
  const [isTouched, setIsTouched] = useState(false);
  const scrollTop = () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, term: DataTerm) => {
    const errorIndex = errors.indexOf(term);
    if (errorIndex > -1) {
      errors.splice(errorIndex, 1);
    }

    onDataChange((prev) =>
      prev.map((p) => {
        if (p.id === currentData.id) {
          return { ...p, values: { ...p.values, [term]: e.target.value.replace(/[^0-9.]/g, "") } };
        }
        return p;
      }),
    );
  };

  const calculateResult = () => {
    const inputsWithError: DataTerm[] = [];
    const arrayWithIntegers = Object.entries(currentData.values).map(([key, value]) => {
      if (key in ["R", "f", "Sum"] && !value) {
        inputsWithError.push(key as DataTerm);
      }

      return [key, Number(value)];
    });

    const { R, f, Sum } = Object.fromEntries(arrayWithIntegers) as Record<DataTerm, number>;

    const w = f;
    const r = R * (w / Sum);

    const formatter = new Intl.NumberFormat();
    setErrors(inputsWithError);

    if (!inputsWithError.length) {
      onDataChange((prev) =>
        prev.map((p) => {
          if (p.id === currentData.id) {
            return {
              ...p,
              calculationResults: [formatter.format(w), formatter.format(r)],
            };
          }
          return p;
        }),
      );

      scrollTop();
    }
  };

  const createNewCalculation = () => {
    onDataChange((prev) => [
      ...prev,
      {
        id: uuidv4(),
        name: `Calculcation ${nextCalculationNumber}`,
        calculationResults: null,
        values: {
          R: "1582192",
          Sum: "",
          f: "",
        },
      },
    ]);
    onCreate();
    scrollTop();
  };

  const duplicateCurrentCalculation = () => {
    onDataChange((prev) => [...prev, { ...currentData, id: uuidv4(), name: `Calculcation ${nextCalculationNumber}` }]);
    onCreate();
    scrollTop();
  };

  return !isHidden ? (
    <Box sx={{ pt: 5 }}>
      {!!currentData.calculationResults && (
        <Paper
          variant="card"
          sx={{
            mb: 5,
            gap: 1,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>{`${currentData.name} Results`}</Typography>
          <Typography variant="h1" sx={{ display: "flex", alignItems: "center" }}>
            <WIcon />
            <EqualIcon style={{ marginRight: "8px", marginLeft: "4px" }} />
            {currentData.calculationResults[0]}
          </Typography>
          <Typography variant="h1" sx={{ display: "flex", alignItems: "center" }}>
            <RIcon />
            <EqualIcon style={{ marginRight: "8px" }} />
            {currentData.calculationResults[1]}
          </Typography>
        </Paper>
      )}

      <Formula focusedElement={focusedElement} />

      <Box
        display="flex"
        justifyContent="flex-end"
        gap={3}
        flexWrap="wrap"
        mb={5}
        sx={{
          "& button": { width: { xs: "100%", sm: "initial" } },
        }}
      >
        <Button color="secondary" onClick={onEdit}>
          Edit
        </Button>
        <Button disabled={!isTouched} color="secondary" onClick={duplicateCurrentCalculation}>
          Create Duplicate
        </Button>
        <Button color="secondary" onClick={createNewCalculation}>
          New Calculation
        </Button>
        <Button disabled={!isTouched} color="primary" onClick={calculateResult}>
          Calculate
        </Button>
      </Box>

      <Paper variant="card" sx={{ overflow: "auto" }}>
        <Box minWidth={480} paddingLeft={1} paddingBottom={2} gap={5} display="flex" alignItems="center">
          {headers.map((title) => (
            <Box flexBasis={title.flexBasis} sx={{ fontWeight: "bold" }}>
              {title.name}
            </Box>
          ))}
        </Box>
        <Box maxHeight={480} minWidth={480} borderRadius={4} overflow="auto">
          {tableData.map(({ term, definition, details, readOnly, icon, defaultValue, tooltip }, i) => (
            <Box
              key={term}
              p={2}
              gap={5}
              display="flex"
              alignItems="center"
              bgcolor={i % 2 ? undefined : "background.default"}
              sx={{
                "& svg": {
                  height: 48,
                  width: "auto",
                  ml: -1,
                  "& rect": { fill: "none", stroke: "none" },
                },
              }}
            >
              <Box flexBasis="10%" pl={1}>
                {icon || term}
              </Box>
              <Box flexBasis="40%">{definition}</Box>
              <Box flexBasis="40%">{details}</Box>
              {readOnly ? (
                <Box flexBasis="10%">{defaultValue}</Box>
              ) : (
                <Box flexBasis="10%">
                  <Tooltip title={tooltip || ""}>
                    <TextField
                      placeholder={term}
                      value={currentData.values[term]}
                      error={errors.includes(term)}
                      onFocus={() => {
                        setFocusedElement(term);
                        setIsTouched(true);
                      }}
                      onBlur={() => setFocusedElement(null)}
                      onChange={(e) => onInputChange(e, term)}
                    />
                  </Tooltip>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Paper>
    </Box>
  ) : (
    <Box />
  );
};
