import { RouteParams } from "./route-params";

const OraclePortalHome = `/oracle-portal`;

const PriceFeeds = `${OraclePortalHome}/price-feeds`;
const Providers = `${OraclePortalHome}/providers`;
const Research = `${OraclePortalHome}/research`;

const PriceFeedDetails = `${PriceFeeds}/${RouteParams.feedId}`;

export const OracleDashboardRoutes = {
  Home: OraclePortalHome,
  PriceFeeds,
  PriceFeedDetails,
  Providers,
  Research,
};
