import { StackedBarChart } from "@frontend/ui";
import { WalletHealthHistogramItem } from "../../../generated";

type Props = {
  walletsHealth?: WalletHealthHistogramItem[];
  tokenSymbol: string;
  marketName?: string;
};

export const BorrowHealthHistogram = ({ walletsHealth, tokenSymbol, marketName }: Props) => (
  <StackedBarChart
    title="Borrowers Market Wallets Health"
    description={`Count of wallets that have open borrow positions for ${tokenSymbol ?? "this asset"}
        ${marketName ? ` on the ${marketName} market` : ""}
        , bucketed by their respective health scores.`}
    series={[
      {
        label: "Number of wallets",
        data: (walletsHealth || []).map(({ bucket: { start, end }, numberOfWallets }, i) => {
          const isLastItem = i === (walletsHealth?.length || 0) - 1;
          return [isLastItem ? `>= ${start}` : `${start} - ${end}`, numberOfWallets];
        }),
      },
    ]}
    showXAxisLine={false}
    currency=""
    chartHeight={348}
    isLoading={!walletsHealth}
  />
);

export const SupplyHealthHistogram = ({ walletsHealth, tokenSymbol, marketName }: Props) => (
  <StackedBarChart
    title="Suppliers Market Wallets Health"
    description={`Count of wallets that have open supply positions for ${tokenSymbol ?? "this asset"}
                  ${marketName ? ` on the ${marketName} market` : ""}
                  , bucketed by their respective health scores.`}
    series={[
      {
        label: "Number of wallets",
        data: (walletsHealth || []).map(({ bucket: { start, end }, numberOfWallets }, i) => {
          const isLastItem = i === (walletsHealth?.length || 0) - 1;
          return [isLastItem ? `>= ${start}` : `${start} - ${end}`, numberOfWallets];
        }),
      },
    ]}
    showXAxisLine={false}
    currency=""
    chartHeight={348}
    isLoading={!walletsHealth}
  />
);
