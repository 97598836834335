import { useEffect, useMemo, useState } from "react";
import { CustomReactSelect, ValueOverTimeChart } from "@frontend/ui";
import { Chain, useGhoVolumePerDexQuery } from "../../../generated";
import { useStablecoinDataContext } from "../provider";

export const GhoVolumePerDexChart = () => {
  const { timeSpan } = useStablecoinDataContext();
  const { data, loading } = useGhoVolumePerDexQuery({
    variables: {
      timeSpan,
    },
  });

  const uniqueChains = useMemo(() => {
    if (!data?.ghoVolumePerDex) return [];
    return Array.from(new Set(data.ghoVolumePerDex.map((item) => item.chain)));
  }, [data]);

  const chainMenuItems = useMemo(() => uniqueChains.map((chain) => ({ value: chain, label: chain })), [uniqueChains]);

  const [selectedChain, setSelectedChain] = useState<string>();

  useEffect(() => {
    setSelectedChain(uniqueChains.includes(Chain.Ethereum) ? Chain.Ethereum : uniqueChains[0] || "");
  }, [uniqueChains]);

  const series = useMemo(() => {
    if (!data?.ghoVolumePerDex || !selectedChain) return [];

    const dexData = data.ghoVolumePerDex
      .filter((item) => item.chain === selectedChain)
      .reduce<Record<string, [number, number][]>>((acc, item) => {
        item.dexVolumes.forEach((dexVolume) => {
          if (!acc[dexVolume.name]) {
            acc[dexVolume.name] = [];
          }

          acc[dexVolume.name].push([item.timestamp, dexVolume.volumeUsd]);
        });
        return acc;
      }, {});

    return Object.entries(dexData).map(([dexName, dataPoints]) => ({
      label: dexName,
      data: dataPoints,
    }));
  }, [data, selectedChain]);

  const options = uniqueChains.map((chain) => ({ value: chain, label: chain }));
  return (
    <ValueOverTimeChart
      headerSuffix={
        <CustomReactSelect
          options={options}
          value={options.find((op) => op.value === selectedChain)}
          onChange={(op) => {
            if (!op) {
              return;
            }
            setSelectedChain(op?.value);
          }}
        />
      }
      title="GHO DEX Volume Over Time"
      description="Volume of Gho per DEX over time"
      series={series}
      isLoading={loading}
      emptyState={!loading && series.length === 0}
      emptyStateText="No data available for the selected chain."
      hideTotal={false}
    />
  );
};
