import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addressCell,
  Box,
  ChaosTable,
  chipCell,
  CustomIcon,
  CustomReactSelect,
  Grid,
  iconsCell,
  Link,
  textCell,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { formatAmount, formatDateAndTime } from "@frontend/ui/utils/formatters";
import { RouteParams, RoutePath } from "src/config/routes";
import { PageHeader } from "@frontend/ui/page-header";
import {
  IncentivesRanking,
  LeaderboardLeague,
  LeaderboardQuery,
  LeaderboardType,
  SortOrder,
  useTradingSeasonsQuery,
  useVolumeLeaderboardCsvLazyQuery,
  useVolumeLeaderboardLazyQuery,
} from "../../generated";
import { AccountCard } from "./components/account-card";
import { LeagueCard } from "./components/league-card";

type Tab = "trading" | "market-making" | "league" | "rewards";

const pageSize = 10;
const initialQuery = {
  type: LeaderboardType.Volume,
  skip: 0,
  limit: 100,
  sort: "takerFeesRank",
  order: SortOrder.Ascending,
  search: null,
  league: null,
  season: null,
};

const getTabQueryFields = (tab: Tab, seasonNumber: number, sortByPoints?: boolean) => {
  const isAfterSeasonSix = seasonNumber > 6;
  const makerPointsSort = isAfterSeasonSix ? "makerPoints" : "rewardVolume";
  const takerPointsSort = isAfterSeasonSix ? "takerPoints" : "takerFees";

  switch (tab) {
    case "market-making":
      return {
        type: LeaderboardType.Rewards,
        sort: sortByPoints ? makerPointsSort : "rewardVolumeRank",
      };
    case "league":
      return {
        type: LeaderboardType.League,
        sort: "leagueRank",
      };
    case "rewards":
      return {
        type: LeaderboardType.DydxRewards,
        sort: sortByPoints ? "dydxReward" : "dydxRewardsRank",
      };
    default:
      return {
        type: LeaderboardType.Volume,
        sort: sortByPoints ? takerPointsSort : "takerFeesRank",
      };
  }
};

export const Leaderboard = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const [seasonNumber, setSeasonNumber] = useState<number>(7);
  const { data: tradingSeasons } = useTradingSeasonsQuery();
  const [tab, setTab] = useState<Tab>("trading");
  const [league, setLeague] = useState<LeaderboardLeague>(LeaderboardLeague.Platinum);
  const [query, setQuery] = useState<LeaderboardQuery>(initialQuery);
  const [fetchLeaderboard, { loading }] = useVolumeLeaderboardLazyQuery();
  const [fetchLeaderboardCsv] = useVolumeLeaderboardCsvLazyQuery();
  const [leaderboard, setLeaderboard] = useState<IncentivesRanking[]>([]);
  const [topThree, setTopThree] = useState<IncentivesRanking[]>();
  const tabColor = tab === "trading" ? "green.opacity50" : "orange.opacity50";

  const currentSeason = tradingSeasons?.tradingSeasons.find((ts) => ts.seasonNumber === seasonNumber);
  const latestSeason = tradingSeasons?.tradingSeasons[tradingSeasons.tradingSeasons.length - 1];

  const showLeagueTab = seasonNumber !== 1 && seasonNumber < 7;
  const showRewardsTab = seasonNumber === 1;

  useEffect(() => {
    let isCanceled = false;
    void fetchLeaderboard({ variables: { query } }).then(({ data }) => {
      if (!isCanceled && data?.incentivesLeaderboard) {
        setLeaderboard((l) => (query.skip ? [...l, ...data.incentivesLeaderboard] : data.incentivesLeaderboard));
      }
    });

    return () => {
      isCanceled = true;
    };
  }, [query, fetchLeaderboard]);

  useEffect(() => {
    if (currentSeason || (tradingSeasons && tradingSeasons.tradingSeasons.length <= 1)) {
      void fetchLeaderboard({
        variables: {
          query: {
            ...initialQuery,
            ...getTabQueryFields(tab, currentSeason?.seasonNumber || 1),
            limit: 3,
            league: tab === "league" && showLeagueTab ? league : null,
            season: currentSeason?.startTimestamp || null,
          },
        },
      }).then(({ data }) => {
        setTopThree(data?.incentivesLeaderboard);
      });
    }
  }, [tab, fetchLeaderboard, currentSeason, showLeagueTab, league, tradingSeasons]);

  useEffect(() => {
    if (currentSeason || (tradingSeasons && tradingSeasons.tradingSeasons.length <= 1)) {
      setQuery({
        ...initialQuery,
        ...getTabQueryFields(tab, currentSeason?.seasonNumber || 1),
        season: currentSeason?.startTimestamp || null,
        league: tab === "league" && currentSeason?.label !== "1" ? league : null,
      });
    }
  }, [tab, currentSeason, league, tradingSeasons]);

  useEffect(() => setTab("trading"), [currentSeason]);

  useEffect(() => {
    if (latestSeason) {
      setSeasonNumber(latestSeason.seasonNumber);
    }
  }, [latestSeason]);

  const tableHeaders: Header[] = useMemo(
    () => [
      {
        renderType: "TEXT",
        text: "Rank",
        field: getTabQueryFields(tab, seasonNumber).sort,
      },
      {
        renderType: "TEXT",
        text: "Trader",
        field: "address",
      },
      {
        renderType: "ICONS",
        text: "Markets",
        nonSortable: true,
      },
      ...(tab !== "league"
        ? ([
            {
              renderType: "TEXT",
              text: tab === "rewards" ? "dYdX Rewards" : "Reward Points",
              field: getTabQueryFields(tab, seasonNumber, true).sort,
            },
          ] as Header[])
        : ([
            {
              renderType: "TEXT",
              text: "ROI",
              field: "roi",
            },
            {
              renderType: "TEXT",
              text: "PnL",
              field: "resultPnl",
            },
          ] as Header[])),
    ],
    [tab, seasonNumber],
  );

  const data = useMemo(
    () =>
      leaderboard.map<RenderData[]>((ranking) => [
        chipCell(ranking.rank, undefined, "text.primary"),
        addressCell(ranking.account, ranking.accountLabel || undefined, true),
        iconsCell(ranking.markets),
        ...(tab !== "league"
          ? [
              ...(tab === "rewards"
                ? [textCell(formatAmount(ranking.incentivePoints), ranking.incentivePoints)]
                : [chipCell(ranking.incentivePoints, tabColor)]),
            ]
          : ([
              {
                renderType: "CHIP",
                text: formatAmount(ranking.roi || 0, { isPercent: true }),
                value: ranking.roi,
              },
              {
                renderType: "TEXT",
                text: formatAmount(ranking.pnl || 0, { currency: "USD" }),
                value: ranking.pnl,
                textColor: (ranking.pnl || 0) >= 0 ? "success.main" : "error.main",
              },
            ] as RenderData[])),
      ]) || [],
    [leaderboard, tabColor, tab],
  );
  const onSortChange = useCallback((sortBy: string, order: number) => {
    setQuery((q) => ({
      ...q,
      skip: 0,
      sort: sortBy,
      order: order === 1 ? SortOrder.Ascending : SortOrder.Descending,
    }));
  }, []);
  const onSearch = useCallback((text: string) => {
    setQuery((q) => ({
      ...q,
      skip: 0,
      search: text || null,
    }));
  }, []);
  const rowHref = useCallback(
    (rowId: number) => {
      const ranking = leaderboard[rowId];
      return RoutePath.Risk.SubAccountPositions.replace(RouteParams.ClientName, clientName!)
        .replace(RouteParams.Address, ranking?.account || "")
        .replace(RouteParams.SubAccount, "0");
    },
    [leaderboard, clientName],
  );
  const onPageChange = useCallback(
    (page) => {
      if (pageSize * page === leaderboard.length) {
        setQuery((q) => ({ ...q, skip: leaderboard.length }));
      }
    },
    [leaderboard],
  );

  return (
    <Box>
      <PageHeader showBackButton={false} pageTitle="Leaderboard" />
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={4} px={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center">
            <Typography variant="h3">Launch Incentives Reward Points</Typography>
            <Typography variant="subtitle1" ml={2}>
              Last Updated:
            </Typography>
            {leaderboard[0] && (
              <Typography variant="subtitle1" ml={0.5}>
                {formatDateAndTime(new Date(leaderboard[0].updatedAt), true)}
              </Typography>
            )}
          </Box>
          {tradingSeasons && tradingSeasons.tradingSeasons.length > 1 && (
            <Box ml="auto" display="flex" alignItems="center">
              <Typography variant="caption" mr={1}>
                Trading Season
              </Typography>
              <Box data-testid="trading-season-select">
                <CustomReactSelect
                  options={
                    [...(tradingSeasons?.tradingSeasons || [])]
                      .sort((a, b) => b.seasonNumber - a.seasonNumber)
                      .map((ts) => ({
                        value: ts.startTimestamp.toString(),
                        label: ts.label,
                      })) || []
                  }
                  onChange={(o) => {
                    const season = tradingSeasons?.tradingSeasons.find((ts) => ts.startTimestamp === Number(o?.value));
                    if (season) {
                      setSeasonNumber(season.seasonNumber);
                    }
                  }}
                  value={
                    currentSeason
                      ? { value: currentSeason.startTimestamp.toString(), label: currentSeason.label }
                      : undefined
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant="body2" pb={1}>
            {seasonNumber > 6
              ? `The dYdX Chain Re-Launch Incentives Program is backed by a $6 million liquidity fund. Rewards are allocated in
          4 monthly trading seasons. Points can be earned by using the dYdX Chain. At the close of each season, you'll
          receive rewards in proportion to the points you've accumulated.`
              : `The dYdX Chain Launch Incentives Program is backed by a $20 million liquidity fund. Rewards are allocated in 4
          trading seasons over the course of six-months. Points can be earned by using the dYdX Chain. At the close of
          each season, you'll receive rewards in proportion to the points you've accumulated.`}
          </Typography>
          <Typography variant="body2" display="inline">
            Please note that the Trading Activity and Market Making rewards are independently computed, each
            contributing to their respective reward allocations. Moreover, both are subject to wash trading filtering to
            ensure fair and accurate reward distribution.
          </Typography>
          <Link
            href={
              seasonNumber > 6
                ? "https://chaoslabs.xyz/posts/dydx-re-launch-rewards-explainers"
                : "https://chaoslabs.xyz/posts/dydx-v4-launch-incentives"
            }
            target="_blank"
            ml={1}
          >
            <CustomIcon icon="external-link" sx={{ svg: { path: { fill: "#24B3D0" } } }} />
            <Typography variant="h5" color="aqua.main" display="inline" ml={1}>
              Learn More
            </Typography>
          </Link>
        </Box>
        <Grid container display="flex" gap={4}>
          <ToggleButtonGroup
            onChange={(_, value) => {
              if (value) {
                setTab(value as Tab);
              }
            }}
            value={tab}
            exclusive
          >
            <ToggleButton data-testid="trading-activity-tab" value="trading">
              Trading Activity
            </ToggleButton>
            <ToggleButton data-testid="market-making-tab" value="market-making">
              Market Making
            </ToggleButton>
            {showLeagueTab && (
              <ToggleButton data-testid="league-tab" value="league">
                League
              </ToggleButton>
            )}
            {showRewardsTab && (
              <ToggleButton data-testid="rewards-tab" value="rewards">
                Rewards
              </ToggleButton>
            )}
          </ToggleButtonGroup>
          {tab === "league" && (
            <ToggleButtonGroup
              onChange={(_, value) => {
                if (value) {
                  setLeague(value as LeaderboardLeague);
                  setQuery((q) => ({ ...q, league: value as LeaderboardLeague }));
                }
              }}
              value={league}
              exclusive
            >
              <ToggleButton value={LeaderboardLeague.Platinum} sx={{ py: 0.5 }}>
                <CustomIcon icon="platinum-league" sx={{ svg: { width: 40, height: 32 }, mr: 1 }} />
                Platinum
              </ToggleButton>
              <ToggleButton value={LeaderboardLeague.Gold} sx={{ py: 0.5 }}>
                <CustomIcon icon="gold-league" sx={{ svg: { width: 48, height: 32 }, mr: 1 }} />
                Gold
              </ToggleButton>
              <ToggleButton value={LeaderboardLeague.Silver} sx={{ py: 0.5 }}>
                <CustomIcon icon="silver-league" sx={{ svg: { width: 32, height: 32 }, mr: 1 }} />
                Silver
              </ToggleButton>
              <ToggleButton value={LeaderboardLeague.Bronze} sx={{ py: 0.5 }}>
                <CustomIcon icon="bronze-league" sx={{ svg: { width: 32, height: 32 }, mr: 1 }} />
                Bronze
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Grid>
        <Grid container spacing={2}>
          {tab === "league" && (
            <Grid item xs={12} mb={1}>
              <LeagueCard type={league} />
            </Grid>
          )}
          {topThree?.map((ranking) => (
            <Grid key={`${ranking.account}-${tab}-${league}`} item xs={6} md={4}>
              <AccountCard
                address={ranking?.account}
                addressLabel={ranking?.accountLabel || undefined}
                rank={ranking?.rank}
                values={
                  tab !== "league"
                    ? [
                        {
                          label: tab === "rewards" ? "dYdX Rewards" : "Reward Points",
                          value: !loading ? ranking?.incentivePoints : undefined,
                          color: tabColor,
                        },
                      ]
                    : [
                        {
                          label: "ROI",
                          value: !loading ? ranking?.roi || 0 : undefined,
                          isPercent: true,
                        },
                        {
                          label: "PnL",
                          value: !loading ? ranking?.pnl || 0 : undefined,
                          currency: "USD",
                        },
                      ]
                }
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <ChaosTable
        title="Leaderboard"
        headers={tableHeaders}
        isLoading={loading}
        data={data}
        serchbarPlaceholder="Search for Trader"
        resetPagination={`${tab}-${league}-${seasonNumber}`}
        onSortChange={onSortChange}
        onSearch={onSearch}
        showSearch
        isFilterHidden
        rowHeight={70}
        pageSize={pageSize}
        rowHref={rowHref}
        onPageChange={onPageChange}
        isFullHeight
        onExport={() => {
          void fetchLeaderboardCsv({ variables: { query: { ...query, limit: 0, skip: 0 } } }).then((csv) => {
            if (csv.data?.incentivesLeaderboardCsv) {
              const csvContent = `data:text/csv;charset=utf-8,${csv.data.incentivesLeaderboardCsv}`;
              const link = document.createElement("a");
              link.setAttribute("href", encodeURI(csvContent));
              link.setAttribute("download", "leaderboard.csv");
              link.click();
            }
          });
        }}
      />
    </Box>
  );
};
