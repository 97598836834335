import { Box, Paper, Grid, ValueOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { Chain, useHistoryStatsQuery } from "src/pages/ccar-lending-page/generated";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";

const AssetBorrowAndSupply = () => {
  const { loading, data } = useHistoryStatsQuery({
    variables: {
      chain: Chain.Avalanche,
      tokenSymbol: null,
      marketId: null,
      timeSpan: null,
      // TODO: remove once we migrate to use marketId instead of market (canonical name)
      market: null,
    },
  });

  const assetsSeriesDatapoints = data?.chainHistoryStats.data || [];
  const seriesDatapointsPerAsset: Record<string, NumberChartSeries[]> = assetsSeriesDatapoints.reduce(
    (map: Record<string, NumberChartSeries[]>, obj) => {
      const { date, result } = obj;
      (result[0].assets ?? []).forEach((asset) => {
        const supplyDatapoint: [number, number] = [date * 1000, asset.totalSupplyToken || 0];
        const borrowDatapoint: [number, number] = [date * 1000, asset.totalBorrowToken || 0];

        if (Object.keys(map).includes(asset.symbol)) {
          map[asset.symbol][0].data.push(supplyDatapoint);
          map[asset.symbol][1].data.push(borrowDatapoint);
        } else {
          const chartSeries: NumberChartSeries[] = [
            {
              type: "line",
              label: "Supply",
              data: [supplyDatapoint],
            },
            {
              type: "line",
              label: "Borrow",
              data: [borrowDatapoint],
            },
          ];

          // eslint-disable-next-line no-param-reassign
          map[asset.symbol] = chartSeries;
        }
      });

      return map;
    },
    {},
  );

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      {loading && (
        <Paper variant="card" sx={{ mb: 3 }}>
          <ShimmerLoader sx={{ height: "376px" }} />
        </Paper>
      )}
      {data && (
        <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Grid container spacing={2}>
            {Object.keys(seriesDatapointsPerAsset).map((tokenSymbol) => (
              <Grid item xs={12} lg={6} key={`borrow-supply-card-${tokenSymbol}`}>
                <Paper variant="widget" key={`${tokenSymbol}-supply-borrow--single-chart`}>
                  <ValueOverTimeChart
                    isLoading={loading}
                    emptyState={seriesDatapointsPerAsset[tokenSymbol]?.length === 0}
                    title={tokenSymbol}
                    series={seriesDatapointsPerAsset[tokenSymbol]}
                    yAxisMin
                    yAxisMax
                    currency=""
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

export default AssetBorrowAndSupply;
