import { ToolItemProps } from "@frontend/types";
import aaveHeaderLogo from "src/assets/img/aave-logo.svg";
import osmosisHeaderLogo from "src/assets/img/osmosis-logo.svg";
import radiantHeaderLogo from "src/assets/img/logos/radiant-header.svg";
import { publicDashboards } from "@frontend/ui/utils/dashboards";
import gmxLogo from "../../assets/img/logos/gmx.svg";
import aaveLogo from "../../assets/img/logos/aave.svg";
import osmosisLogo from "../../assets/img/logos/osmosis.svg";
import uniswapLogo from "../../assets/img/logos/uniswap.svg";
import dydxLogo from "../../assets/img/logos/dydx.svg";
import makerLogo from "../../assets/img/logos/maker.svg";
import benqiLogo from "../../assets/img/logos/benqi.svg";
import radiantLogo from "../../assets/img/logos/radiant.svg";
import chainlinkLogo from "../../assets/img/logos/chainlink.svg";
import venusLogo from "../../assets/img/logos/venus.svg";
import crvUSDLogo from "../../assets/img/logos/crvUSD.png";
import ostiumLogo from "../../assets/img/logos/ostium.svg";
import seamlessLogo from "../../assets/img/logos/seamless.svg";
import bluefinLogo from "../../assets/img/logos/bluefin.svg";
import zerolendLogo from "../../assets/img/logos/zerolend.svg";
import avantisLogo from "../../assets/img/logos/avantis.svg";
import usdvLogo from "../../assets/img/logos/usdv.svg";
import ethenaLogo from "../../assets/img/logos/ethena.svg";
import nftperpLogo from "../../assets/img/logos/nftperp.svg";
import jupiterLogo from "../../assets/img/logos/jupiter-logo.svg";
import synfuturesLogo from "../../assets/img/logos/synfutures.svg";
import aptosLogo from "../../assets/img/logos/aptos.svg";
import gearboxLogo from "../../assets/img/logos/gearbox.svg";
import etherfiLogo from "../../assets/img/logos/etherfi-logo.svg";

import { PostItemProps } from "./components/post-item";

export type ClientViewProps = {
  clientLogo: string;
  title: string;
  tools: ToolItemProps[];
  homePath?: string;
  resources?: PostItemProps[];
  headerProps: {
    clientLogo: string;
    logoChip?: string;
    getBanner?: (pathname: string) =>
      | {
          text: string;
          icon: string;
        }
      | undefined;
  };
};

type ClientsViewPropsMappingType = {
  [clientName: string]: ClientViewProps;
};

export const clientsViewPropsMapping: ClientsViewPropsMappingType = {
  aave: {
    clientLogo: aaveLogo,
    title: "Aave",
    tools: publicDashboards.aave,
    headerProps: {
      clientLogo: aaveHeaderLogo,
      logoChip: "V3",
    },
  },
  "aave-v2": {
    clientLogo: aaveLogo,
    title: "Aave",
    homePath: "aave",
    tools: publicDashboards["aave-v2"],
    headerProps: {
      clientLogo: aaveHeaderLogo,
      logoChip: "V2",
    },
  },
  maker: {
    clientLogo: makerLogo,
    title: "Maker",
    tools: publicDashboards.maker,
    headerProps: {
      clientLogo: makerLogo,
    },
  },
  benqi: {
    clientLogo: benqiLogo,
    title: "BENQI",
    tools: publicDashboards.benqi,
    headerProps: {
      clientLogo: benqiLogo,
    },
  },
  dydx: {
    clientLogo: dydxLogo,
    title: "dYdX",
    tools: publicDashboards.dydx,
    headerProps: {
      clientLogo: dydxLogo,
    },
  },
  "dydx-v4": {
    clientLogo: dydxLogo,
    title: "dYdX",
    tools: publicDashboards.dydx,
    homePath: "dydx",
    headerProps: {
      clientLogo: dydxLogo,
      getBanner(pathname) {
        if (pathname.includes("risk/positions") || pathname.includes("risk/accounts")) {
          return {
            icon: "warning",
            text: "dYdX data pipelines may not accurately represent the current on-chain state. We apologize for any inconvenience this may cause and appreciate your patience as we work to resolve the issue. Please note that this situation does not affect the reward points system.",
          };
        }

        return undefined;
      },
    },
  },
  bluefin: {
    clientLogo: bluefinLogo,
    title: "Bluefin",
    tools: publicDashboards.bluefin,
    headerProps: {
      clientLogo: bluefinLogo,
    },
  },
  "ostium-testnet": {
    clientLogo: ostiumLogo,
    title: "Ostium",
    homePath: "ostium",
    tools: [],
    headerProps: {
      clientLogo: ostiumLogo,
    },
  },
  ostium: {
    clientLogo: ostiumLogo,
    title: "Ostium",
    tools: [],
    headerProps: {
      clientLogo: ostiumLogo,
    },
  },
  chainlink: {
    clientLogo: chainlinkLogo,
    title: "Chainlink",
    tools: publicDashboards.chainlink,
    headerProps: {
      clientLogo: aaveLogo,
    },
  },
  uniswap: {
    clientLogo: uniswapLogo,
    title: "Uniswap",
    tools: publicDashboards.uniswap,
    resources: [
      {
        title: "Uniswap V3 LP Simulation Platform: A Case Study",
        excerpt:
          "Examining the economic feasibility of liquidity provisioning into Version 3 (V3) pools employing advanced capabilities of the Chaos Labs V3 Simulation platform.",
        coverImage:
          "https://chaoslabs.xyz/_next/image?url=%2Fresources%2Fchaos_uniswap_v3_lp_simulation_platform_case_study.png&w=828&q=100",
        date: "2023-08-10T05:35:07.322Z",
        link: "https://chaoslabs.xyz/resources/chaos_uniswap_v3_lp_simulation_platform_case_study.pdf",
      },
    ],
    headerProps: {
      clientLogo: uniswapLogo,
    },
  },
  osmosis: {
    clientLogo: osmosisLogo,
    title: "Osmosis",
    tools: publicDashboards.osmosis,
    headerProps: {
      clientLogo: osmosisHeaderLogo,
    },
  },
  "gmx-arbitrum": {
    clientLogo: gmxLogo,
    title: "GMX",
    homePath: "gmx",
    tools: publicDashboards["gmx-arbitrum"],
    headerProps: {
      clientLogo: gmxLogo,
      logoChip: "Arbitrum",
      getBanner() {
        return {
          icon: "warning",
          text: "GMX V1 data pipelines may not reflect the current on-chain state. We apologize for any inconvenience caused and appreciate your patience while this is being resolved.",
        };
      },
    },
  },
  "gmx-avalanche": {
    clientLogo: gmxLogo,
    title: "GMX",
    homePath: "gmx",
    tools: publicDashboards["gmx-avalanche"],
    headerProps: {
      clientLogo: gmxLogo,
      logoChip: "Avalanche",
      getBanner() {
        return {
          icon: "warning",
          text: "GMX V1 data pipelines may not reflect the current on-chain state. We apologize for any inconvenience caused and appreciate your patience while this is being resolved.",
        };
      },
    },
  },
  "gmx-v2-arbitrum": {
    clientLogo: gmxLogo,
    title: "GMX",
    homePath: "gmx",
    tools: publicDashboards["gmx-v2-arbitrum"],
    headerProps: {
      clientLogo: gmxLogo,
      logoChip: "Arbitrum",
    },
  },
  "gmx-v2-avalanche": {
    clientLogo: gmxLogo,
    title: "GMX",
    homePath: "gmx",
    tools: publicDashboards["gmx-v2-avalanche"],
    headerProps: {
      clientLogo: gmxLogo,
      logoChip: "Avalanche",
    },
  },
  nftperp: {
    clientLogo: nftperpLogo,
    title: "NftPerp",
    tools: publicDashboards.nftperp,
    headerProps: {
      clientLogo: nftperpLogo,
    },
  },
  jupiter: {
    clientLogo: jupiterLogo,
    title: "Jupiter",
    tools: publicDashboards.jupiter,
    headerProps: {
      clientLogo: jupiterLogo,
    },
  },
  synfutures: {
    clientLogo: synfuturesLogo,
    title: "SynFutures",
    tools: publicDashboards.synfutures,
    headerProps: {
      clientLogo: synfuturesLogo,
    },
  },
  gmx: {
    clientLogo: gmxLogo,
    title: "GMX",
    tools: [...publicDashboards["gmx-arbitrum"], ...publicDashboards["gmx-v2-arbitrum"]],
    resources: [
      {
        title: "GMX V2 Genesis Risk Framework and Methodologies",
        excerpt:
          "A rigorous analysis of GMX V2, focusing on market risk, protocol mechanism design, and risk parameter analysis on a per market basis.",
        coverImage:
          "https://chaoslabs.xyz/_next/image?url=%2Fresources%2Fchaos_gmx_genesis_risk_framework_methodology.png&w=828&q=100",
        date: "2023-07-26T09:06:02.132Z",
        link: "https://chaoslabs.xyz/resources/chaos_gmx_genesis_risk_framework_methodology.pdf",
      },
    ],
    headerProps: {
      clientLogo: gmxLogo,
      logoChip: "Arbitrum",
    },
  },
  radiant: {
    clientLogo: radiantLogo,
    title: "RADIANT",
    tools: publicDashboards.radiant,
    headerProps: {
      clientLogo: radiantHeaderLogo,
      logoChip: "V2",
      getBanner() {
        return {
          icon: "warning",
          text: "Radiant data pipelines may not reflect the current on-chain state. We apologize for any inconvenience caused and appreciate your patience while this is being resolved.",
        };
      },
    },
  },
  venus: {
    clientLogo: venusLogo,
    title: "Venus",
    tools: publicDashboards.venus,
    headerProps: {
      clientLogo: venusLogo,
    },
  },
  "crv-usd": {
    clientLogo: crvUSDLogo,
    title: "crvUSD",
    tools: publicDashboards["crv-usd"],
    headerProps: {
      clientLogo: crvUSDLogo,
    },
  },
  seamless: {
    clientLogo: seamlessLogo,
    title: "Seamless",
    tools: publicDashboards.seamless,
    headerProps: {
      clientLogo: seamlessLogo,
    },
  },
  zerolend: {
    clientLogo: zerolendLogo,
    title: "ZeroLend",
    tools: publicDashboards.zerolend,
    headerProps: {
      clientLogo: zerolendLogo,
      getBanner() {
        return {
          icon: "warning",
          text: "ZeroLend data pipelines may not reflect the current on-chain state. We apologize for any inconvenience caused and appreciate your patience while this is being resolved.",
        };
      },
    },
  },
  avantis: {
    clientLogo: avantisLogo,
    title: "Avantis",
    tools: [],
    resources: [
      {
        title: "Avantis Genesis Risk Framework and Methodologies",
        excerpt:
          "A rigorous analysis of Avantis, focusing on market risk, protocol mechanics, and risk parameter analysis on a per market basis.",
        coverImage:
          "https://chaoslabs.xyz/_next/image?url=%2Fresources%2Fchaos_avantis_genesis_risk_framework_methodology.png&w=828&q=100",
        date: "2024-01-20T00:00:00.000Z",
        link: "https://chaoslabs.xyz/resources/chaos_avantis_genesis_risk_framework_methodology.pdf",
      },
    ],
    headerProps: {
      clientLogo: avantisLogo,
    },
  },
  usdv: {
    clientLogo: usdvLogo,
    title: "USDV",
    tools: [],
    resources: [
      {
        title: "USDV Risk and Economic Security Assessment",
        excerpt:
          "A rigorous analysis of USDV, a stablecoin backed by STBT, detailing mechanisms, associated risk vectors, and featuring recommendations for risk mitigation and secure asset onboarding.",
        coverImage:
          "https://chaoslabs.xyz/_next/image?url=%2Fresources%2Fchaos_labs_USDV_risk_and_economic_security_assessment.png&w=828&q=100",
        date: "2023-11-09T00:00:00.000Z",
        link: "https://chaoslabs.xyz/resources/chaos_labs_USDV_risk_and_economic_security_assessment.pdf",
      },
    ],
    headerProps: {
      clientLogo: usdvLogo,
    },
  },
  ethena: {
    clientLogo: ethenaLogo,
    title: "Ethena",
    tools: [],
    resources: [
      {
        title: "Ethena On Chain Stablecoin Liquidity Report",
        excerpt:
          "A series of case studies on successful liquidity strategies used by pegged tokens, offering a framework for efficient liquidity incentivization.",
        coverImage:
          "https://chaoslabs.xyz/_next/image?url=%2Fresources%2Fchaos-labs-on-chain-stablecoin-liquidity-analysis.png&w=828&q=100",
        date: "2023-11-09T00:00:00.000Z",
        link: "https://chaoslabs.xyz/resources/chaos-labs-on-chain-stablecoin-liquidity-analysis.pdf",
      },
      {
        title: "LST Market Risks on Ethena",
        excerpt:
          "A methodology for quantifing the liquidity risks of Liquid Staking Tokens (LSTs) used as collateral in the Ethena protocol, offering key metrics for ongoing risk assessment.",
        coverImage:
          "https://chaoslabs.xyz/_next/image?url=%2Fresources%2Fchaos-labs-ethena-lst-market-risk.png&w=828&q=100",
        date: "2023-11-03T00:00:00.000Z",
        link: "https://chaoslabs.xyz/resources/chaos-labs-ethena-lst-market-risk.pdf",
      },
      {
        title: "Ethena Perpetual Assessment Risk Report",
        excerpt:
          "An exploration of risks to the Ethena protocol emanating from all aspects of perpetual futures. Particular focus is on funding rate dynamics, futures liquidity, and estimated slippage.",
        coverImage:
          "https://chaoslabs.xyz/_next/image?url=%2Fresources%2Fchaos_Labs_ethena_perpetual_assessment_risk_report.png&w=828&q=100",
        date: "2023-11-09T00:00:00.000Z",
        link: "https://chaoslabs.xyz/resources/chaos_Labs_ethena_perpetual_assessment_risk_report.pdf",
      },
    ],
    headerProps: {
      clientLogo: usdvLogo,
    },
  },
  aptos: {
    clientLogo: aptosLogo,
    title: "Aptos",
    tools: [],
    headerProps: {
      clientLogo: aptosLogo,
    },
  },
  gearbox: {
    clientLogo: gearboxLogo,
    title: "Gearbox",
    tools: [],
    headerProps: {
      clientLogo: gearboxLogo,
    },
  },
  etherfi: {
    clientLogo: etherfiLogo,
    title: "Etherfi",
    tools: [],
    headerProps: {
      clientLogo: etherfiLogo,
    },
  },
};
