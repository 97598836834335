import { CellStatusType, Header, RenderData } from "@frontend/types";
import { Box, ChaosTable, Tabs } from "@frontend/ui";
import { Alert, AlertFilters } from "@frontend/ui/alert-filters";
import { formatDateAndTime } from "@frontend/ui/utils/formatters";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useRiskAlertsQuery, AlertSeverity, AlertsQuery, SortOrder } from "../generated";
import { LinkCell, NotificationChannelLinks, TitleCell } from "./components";
import { NotificationsChannelConfig } from "../../pages/ccar-lending-page/types";

const alertStatus: Record<AlertSeverity, CellStatusType> = {
  HIGH: "ERROR",
  INFO: "INFO",
};

const alertLabels: Record<AlertSeverity, string> = {
  HIGH: "High",
  INFO: "Info",
};

const alertsHeaders: Header[] = [
  {
    renderType: "TEXT",
    text: "Date",
    width: "180px",
  },
  {
    renderType: "TEXT",
    text: "Severity",
    width: "90px",
  },
  {
    renderType: "TEXT",
    text: "Name",
  },
  {
    renderType: "TEXT",
    text: "Description",
    width: "45%",
  },
  {
    renderType: "TEXT",
    text: "Link",
    nonSortable: true,
    width: "160px",
  },
];

const mapAlerts = (alert: Alert): RenderData[] => {
  const formattedDate = `${formatDateAndTime(dayjs.unix(alert.createdDate).toDate())}`;
  const status = alertStatus[alert.severity as AlertSeverity];
  const statusLabel = alertLabels[alert.severity as AlertSeverity];

  return [
    {
      renderType: "TEXT",
      text: formattedDate,
      value: alert.createdDate,
    },
    {
      text: statusLabel,
      renderType: "LABELS",
      status,
    },
    {
      renderType: "CUSTOM",
      text: alert.title,
      component: <TitleCell title={alert.title} isOpen={alert.open} />,
    },
    {
      renderType: "TEXT",
      text: alert.description,
      tooltipText: alert.description,
    },
    {
      renderType: "CUSTOM",
      text: alert.link?.title || "",
      component: <LinkCell href={alert.link?.href} title={alert.link?.title} />,
    },
  ];
};

type Props = {
  notificationsChannels?: NotificationsChannelConfig[];
};

const AlertsTable = ({ notificationsChannels }: Props) => {
  const [query] = useState<AlertsQuery>({
    limit: 100,
    filter: null,
    order: SortOrder.Descending,
    skip: 0,
    sort: null,
  });
  const { data, loading } = useRiskAlertsQuery({ variables: { query } });
  const [tab, setTab] = useState(0);
  const showRecent = tab === 0;
  const [tableData, setTableData] = useState(
    data?.riskAlerts.filter((alert) => (showRecent ? alert.open : true)).map((alert) => mapAlerts(alert as Alert)) ||
      [],
  );
  const applyFilters = useCallback((newAlerts: Alert[]) => {
    setTableData(newAlerts.map(mapAlerts));
  }, []);

  useEffect(() => {
    if (data?.riskAlerts) {
      applyFilters(data.riskAlerts as Alert[]);
    }
  }, [data?.riskAlerts, applyFilters]);

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={3}>
      <AlertFilters
        alerts={(data?.riskAlerts as Alert[]) || []}
        onChange={applyFilters}
        alertTypeLabels={(s) => s}
        alertSeverities={["High", "Info"]}
      />
      <ChaosTable
        pageSize={10}
        isFullHeight
        isLoading={loading}
        isSettingsHidden
        title="Monitored Events"
        headers={alertsHeaders}
        data={tableData}
        headerSuffixComponent={<NotificationChannelLinks notificationsChannels={notificationsChannels} />}
        isFilterHidden
        customFilter={
          <Box width={260}>
            <Tabs
              value={tab}
              tabs={[{ label: "Active Alerts" }, { label: "All Alerts" }]}
              fullWidth
              onChange={(_, i) => {
                setTab(i);
              }}
            />
          </Box>
        }
        emptyState={{
          icon: "check-circle",
          title: "No Recent Alerts",
        }}
      />
    </Box>
  );
};

export default AlertsTable;
