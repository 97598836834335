import { Box, Button, CustomIcon, CustomReactSelect, CustomSwitch, InputLabel, NumberInput } from "@frontend/ui";
import { useMemo } from "react";
import { Strategy, riskProfileTypeNames, timeHorizonIntervalTypeNames } from "../../types";
import { InputTooltip } from "./input-tooltip";

type Props = {
  strategy: Strategy;
  setFieldValue: (field: string, value: any) => Promise<any>;
  errors?: { riskProfile?: string; timeHorizon?: string; costOfCapital?: string };
  submitDisabled: boolean;
  onSubmit: () => void;
};

const StrategySelect = ({ strategy, setFieldValue, errors, submitDisabled, onSubmit }: Props) => {
  const keys = useMemo(
    () => ({
      riskProfile: "strategy.riskProfile",
      timeHorizon: "strategy.timeHorizon",
      costOfCapital: "strategy.costOfCapital",
      positionManagement: "strategy.positionManagement",
    }),
    [],
  );

  const riskProfileOptions = riskProfileTypeNames.map((rp) => ({
    value: rp,
    label: rp.split(/(?=[A-Z])/).join(" "),
  }));

  const timeHorizonOptions = timeHorizonIntervalTypeNames.map((th) => ({
    value: th,
    label: th,
  }));

  return (
    <Box>
      <Box display="flex" flexWrap="wrap" gap={3}>
        <Box flex="1" minWidth="max-content" data-testid="risk-Profile-input">
          <InputLabel>
            Risk Profile{" "}
            <InputTooltip title="Your level of aggressiveness determines how willing you are to take risks. In the optimal scenario, higher risk can lead to greater rewards. However, it can also result in larger losses during unfavorable situations. Your risk appetite is reflected in both the range of your position and the percentage of time during which liquidity is deployed in the pool throughout the simulation. If you have a higher risk appetite, your position bounds will be narrower, and you will provide liquidity for a greater duration of time." />
          </InputLabel>
          <CustomReactSelect
            name="riskProfile"
            placeholder="Select Risk Profile"
            isSearchable={false}
            options={riskProfileOptions}
            value={riskProfileOptions.find((option) => option.value === strategy?.riskProfile) ?? null}
            onChange={(selected) => {
              if (!selected) return;
              const newSelectedRiskProfile = selected.value;
              void setFieldValue(keys.riskProfile, newSelectedRiskProfile);
            }}
            error={!!errors?.riskProfile}
            helperText={errors?.riskProfile}
          />
        </Box>
        <Box flex="1" minWidth="max-content" data-testid="time-horizon-input">
          <InputLabel>
            Time Horizon{" "}
            <InputTooltip title="The timeframe for which you plan to deploy the funds to a specific pool" />
          </InputLabel>
          <CustomReactSelect
            name="timeHorizon"
            placeholder="Select Time Horizon"
            isSearchable={false}
            options={timeHorizonOptions}
            value={timeHorizonOptions.find((option) => option.value === strategy?.timeHorizon) ?? null}
            onChange={(selected) => {
              if (!selected) return;
              const newSelectedTimeHorizon = selected.value;
              void setFieldValue(keys.timeHorizon, newSelectedTimeHorizon);
            }}
            error={!!errors?.timeHorizon}
            helperText={errors?.timeHorizon}
          />
        </Box>
        <Box flex="1" minWidth="max-content" data-testid="cost-of-capital-input">
          <InputLabel>
            Opportunity Cost{" "}
            <InputTooltip title="The Annual Percentage Rate (APR) represents the return on investment of the best alternative investment option for the funds, instead of deploying them to a Uniswap pool. The Profit and Loss (PnL) is calculated by measuring the additional yield generated above the opportunity cost. This calculation specifically focuses on strategies that have the potential to be profitable, considering the opportunity cost you face." />
          </InputLabel>
          <NumberInput
            placeholder="Enter Opportunity Cost"
            value={strategy.costOfCapital}
            isPercent
            onChange={async (cost) => {
              void setFieldValue(keys.costOfCapital, cost);
            }}
            error={errors?.costOfCapital}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" gap={2} alignItems="flex-end">
        <Box data-testid="input-toggle-position-mgmt" flex="1" minWidth="max-content" marginTop={3}>
          <InputLabel>
            Position Management{" "}
            <InputTooltip title="A passive strategy involves a one-time deployment of liquidity, while actively managed strategies require ongoing rebalancing. In the case of actively managed strategies, users are required to employ keepers to oversee and manage the strategy." />
          </InputLabel>
          <CustomSwitch
            onChange={() => {
              void setFieldValue(keys.positionManagement, !strategy.positionManagement);
            }}
            checked={strategy.positionManagement}
            checkedLabel="Active"
            uncheckedLabel="Passive"
            labelsVariant="h6"
          />
        </Box>
        <Box>
          <Button
            type="submit"
            color="primary"
            sx={{ width: { xs: "auto", sm: "150px" } }}
            disabled={submitDisabled}
            onClick={onSubmit}
            startIcon={<CustomIcon icon="play" sx={{ svg: { width: 16, height: 16 } }} />}
          >
            Calculate
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default StrategySelect;
