import { Filter, makePickerFilter, makeRangeFilter } from "@frontend/ui";
import { getIconSrc } from "@frontend/ui/crypto-icon/get-icon-src";
import { RangeFilter, SortWalletsEligibleForLiquidationBy } from "../../../../generated";
import { MarketType } from "../../../../clients-config/types";

type Props = {
  clientMarketType: MarketType;
  markets: { name: string; id: string }[];
  assets: string[];
};

export const getWalletsFilters = ({ assets, markets, clientMarketType }: Props): Filter[] =>
  [
    makePickerFilter({
      title: "Markets",
      fieldName: SortWalletsEligibleForLiquidationBy.MarketId,
      isAutocomplete: true,
      options: markets.map(({ id, name }) => ({
        name,
        cryptoIcon: clientMarketType !== "GeneralMarket" && getIconSrc(name) ? name.toLowerCase() : undefined,
        value: id,
      })),
    }),
    makePickerFilter({
      title: "Borrow Assets",
      fieldName: SortWalletsEligibleForLiquidationBy.BorrowAssets,
      isAutocomplete: true,
      options: assets.map((asset) => ({
        name: asset,
        cryptoIcon: asset.toLowerCase(),
        value: asset,
      })),
    }),
    makePickerFilter({
      title: "Collateral Assets",
      fieldName: SortWalletsEligibleForLiquidationBy.CollateralAssets,
      isAutocomplete: true,
      options: assets.map((asset) => ({
        name: asset,
        cryptoIcon: asset.toLowerCase(),
        value: asset,
      })),
    }),
    makeRangeFilter({
      title: "Health Factor",
      fieldName: SortWalletsEligibleForLiquidationBy.HealthFactor,
      range: [0, 1],
      min: 0,
      max: 1,
    }),
  ].map((filter, i) => ({ ...filter, fieldIndex: i }));

export const getRangeFilterValue = (filters: Filter[], key: SortWalletsEligibleForLiquidationBy) => {
  const filter = filters.find((f) => f.fieldName === key);

  if (filter) {
    const range: RangeFilter = { min: null, max: null };
    if (filter?.type === "range") {
      const [start, end] = filter.range;
      range.min = start;
      range.max = end;
    }
    return range;
  }

  return null;
};

export const getOptionsFilterValue = (filters: Filter[], key: SortWalletsEligibleForLiquidationBy) => {
  const filter = filters.find((f) => f.fieldName === key);

  if (filter) {
    const values: string[] = [];
    if (filter.type === "options") {
      filter.options.forEach((option) => {
        if (!option.isHidden && option.value) {
          values.push(option.value);
        }
      });
    }
    return values;
  }

  return null;
};
