import axios from "axios";

export const getOraclePortalApiUrl = () => {
  const hostName = window.location.hostname;
  if (hostName.includes("staging") || ["localhost", "127.0.0.1"].includes(hostName)) {
    return "https://oracle-staging.chaoslabs.co/oracle-monitor";
  }

  return "https://oracle.chaoslabs.co/oracle-monitor";
};

export default axios.create({
  baseURL: getOraclePortalApiUrl(),
});
