import { Tooltip } from "@frontend/ui";
import { CustomIcon } from "../custom-icon";
import { Paper } from "../paper";
import { Typography } from "../typography";

type Props = {
  message: string;
  bgcolor?: string;
  icon?: string;
  tooltip?: string;
};

const NotificationBanner = ({ message, bgcolor = "orange.opacity50", icon = "info", tooltip }: Props) => (
  <Paper
    sx={{
      bgcolor,
      display: "flex",
      p: [1, 2],
      alignItems: "center",
      gap: 2,
      borderRadius: 0,
      minHeight: 64,
    }}
  >
    <Tooltip title={tooltip ?? ""}>
      <CustomIcon icon={icon} sx={{ "svg path": { fill: "currentColor" } }} />
    </Tooltip>
    <Typography>{message}</Typography>
  </Paper>
);

export default NotificationBanner;
