import { TimeSpan } from "../../generated";

export type TimedData<T> = {
  7: T;
  30: T;
  90: T;
};

export type LiquidationsOverTimeData = {
  buckets: { [date: string]: { [token: string]: string } };
};

export type LiquidationsHistogramDataType = {
  [asset: string]: { x: string | number; y: string | number }[];
};

export const deltaTimes = [7, 30, 90] as const;
type DeltaTime = (typeof deltaTimes)[number];

export const timeSpans = [TimeSpan.Week, TimeSpan.Month, TimeSpan.Quarter] as const;
export type LiquidationsTimeSpans = (typeof timeSpans)[number];

export const deltaTimeByTimeSpan: Record<LiquidationsTimeSpans, DeltaTime> = {
  [TimeSpan.Week]: 7,
  [TimeSpan.Month]: 30,
  [TimeSpan.Quarter]: 90,
};

export type DeltaTimeType = (typeof deltaTimes)[number];

export type LiquidationsDataCutType = {
  daysAgo: DeltaTimeType;
  marketIds: string[] | null;
  assets: string[] | null;
};
