import { Box, Page404 } from "@frontend/ui";
import { toTitleCase } from "@frontend/ui/utils/formatters";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageTracker } from "src/components/page-tracker";
import { RouteParams, RoutePath } from "src/config/routes";
import { Loader } from "@frontend/ui/loader";
import { PoolHistoryQueryVariables, UniswapChain } from "../../../generated";
import { Header, Cards, PoolCharts, ManipulationCostCharts } from "./sections";
import { PoolDataResponse, usePoolData } from "./use-pool-data";

const PoolPage = PageTracker(() => {
  const { clientName, chain, poolId } = useParams<{
    clientName: string;
    chain: string;
    poolId: string;
  }>();
  const navigate = useNavigate();

  const isValidChain = Object.keys(UniswapChain).includes(toTitleCase(chain ?? ""));
  const isValidPoolId = poolId && poolId.length === 42;

  if (!isValidChain || !isValidPoolId) {
    useEffect(() => {
      navigate(RoutePath.Twap.Home.replace(RouteParams.ClientName, clientName || ""));
    }, [navigate, clientName]);
  }

  const variables: PoolHistoryQueryVariables = {
    chain: toTitleCase(chain!) as UniswapChain,
    address: poolId!,
  };

  const response: PoolDataResponse = usePoolData({ variables });

  const isLoading: boolean = response.singlePool.loading || response.currentLiquidity.loading;

  if (isLoading) return <Loader />;

  const singlePoolData = response.singlePool.data?.liquidityManipulations[0];
  const poolCurrentLiquidityData = response.currentLiquidity.data?.poolCurrentLiquidityData;
  const historyData = response.history.data?.poolHistoryData;
  if (!singlePoolData || !poolCurrentLiquidityData) return <Page404 />;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Header pool={singlePoolData} />
      <Cards pool={singlePoolData} />
      <ManipulationCostCharts pool={singlePoolData} />
      <PoolCharts
        isLoadingHistoryData={response.history.loading}
        historyData={historyData}
        poolCurrentLiquidityData={poolCurrentLiquidityData}
      />
    </Box>
  );
});

export default PoolPage;
