import { TimeSpan } from "../../generated";

export const getLabelByTimeSpan = (timeSpan: TimeSpan) => {
  switch (timeSpan) {
    case TimeSpan.Day:
      return "24H";
    case TimeSpan.Week:
      return "7D";
    case TimeSpan.Month:
      return "30D";
    case TimeSpan.Quarter:
      return "90D";
    case TimeSpan.Year:
      return "1 Year";

    default:
      return "All Time";
  }
};
