import { useState } from "react";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { Box, Grid } from "@frontend/ui";
import TvlChart from "./tvl-chart";
import CollateralBreakdownChart from "./collateral-breakdown-chart";
import CollateralBreakdownPieChart from "./collateral-breakdown-pie-chart";
import CollateralInflowOutflowChart from "./collateral-inflow-outflow-chart";
import AprChart from "./apr-chart";
import { TimeSpanPicker } from "../../../../../ccar-lending-page/components/time-span-picker";
import EethPegChart from "./eeth-peg-chart";

const OverviewCharts = () => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan.Quarter);

  return (
    <Box>
      <Box
        sx={{
          float: "right",
        }}
      >
        <TimeSpanPicker selectedTimeSpan={timeSpan} onSelectTimeSpan={setTimeSpan} />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <TvlChart timeSpan={timeSpan} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CollateralBreakdownPieChart />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CollateralBreakdownChart timeSpan={timeSpan} />
        </Grid>
        <Grid item xs={12}>
          <AprChart timeSpan={timeSpan} />
        </Grid>
        <Grid item xs={12}>
          <EethPegChart timeSpan={timeSpan} />
        </Grid>
        <Grid item xs={12}>
          <CollateralInflowOutflowChart timeSpan={timeSpan} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewCharts;
