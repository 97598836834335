import { Header, RenderData } from "@frontend/types";
import { formatDuration } from "@frontend/ui/utils/formatters";
import { ChaosTable, chipCell, numberCell, textCell } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { PageHeader } from "@frontend/ui/page-header";
import { useDefiAppApiRequest } from "../api/use-api-request";
import { DefiAppApiEndpoint } from "../api/types";
import { RouteParams, RoutePath } from "../../../config/routes";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Rank",
  },
  {
    renderType: "TEXT",
    text: "Faction Name",
  },
  {
    renderType: "TEXT",
    text: "Faction Age",
    isDuration: true,
  },
  {
    renderType: "TEXT",
    text: "Number of Members",
  },
  {
    renderType: "TEXT",
    text: "Total Points",
  },
];

const FactionLeaderboard = () => {
  const { clientName } = useParams();
  const { isLoading, response } = useDefiAppApiRequest(DefiAppApiEndpoint.Factions);

  const data: RenderData[][] = (response || []).map((faction, i) => [
    numberCell(faction.ranking),
    textCell(faction.factionName, undefined, undefined, undefined, undefined, faction.image || undefined),
    textCell(
      formatDuration({
        milliseconds: new Date().getTime() - faction.createdAtTimestamp,
        compactHours: 24,
      }),
      new Date().getTime() - faction.createdAtTimestamp,
    ),
    numberCell(faction.numberOfDegens),
    chipCell(Number(faction.totalPoints)),
  ]);

  const getRowHref = (rowId: number) => {
    const faction = (response || [])[rowId];

    if (!faction) {
      return "";
    }

    return RoutePath.Risk.FactionDetails.replace(RouteParams.FactionId, faction.factionId).replace(
      RouteParams.ClientName,
      clientName!,
    );
  };

  return (
    <>
      <PageHeader showBackButton={false} pageTitle="Faction Leaderboard" />
      <ChaosTable
        title="Faction Leaderboard"
        initialSortBy={0}
        isLoading={isLoading}
        headers={headers}
        data={data}
        showRowChevron
        rowHref={getRowHref}
      />
    </>
  );
};

export default FactionLeaderboard;
