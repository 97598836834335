import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Account,
  AccountAggregations,
  IncentivesRankingFragment,
  LeaderboardType,
  useAccountAggregationsQuery,
  useAccountQuery,
} from "src/pages/ccar-perpetuals-page/generated";

type ReturnType = {
  address: string;
  accountId: string;
  subAccount?: string;
  subAccounts?: number[];
  account?: Account;
  accountAggregations?: AccountAggregations;
  marketMakingRank?: IncentivesRankingFragment[];
  volumeRank?: IncentivesRankingFragment[];
  loadingAggregations: boolean;
};

export const useAccountData = () => {
  const { address = "", subAccount } = useParams();
  const accountId = address.toLowerCase();
  const [subAccounts, setSubAccounts] = useState<number[]>();

  const leaderboardQuery = useMemo(
    () => ({
      skip: 0,
      limit: 1,
      sort: null,
      order: null,
      search: accountId,
      season: null,
      league: null,
    }),
    [accountId],
  );
  const volumeLeaderboardQuery = useMemo(
    () => ({
      ...leaderboardQuery,
      type: LeaderboardType.Volume,
    }),
    [leaderboardQuery],
  );
  const mmLeaderboardQuery = useMemo(
    () => ({
      ...leaderboardQuery,
      type: LeaderboardType.Rewards,
    }),
    [leaderboardQuery],
  );
  const { data: accountData } = useAccountQuery({
    fetchPolicy: "cache-first",
    variables: {
      accountId,
      subAccountId: subAccount !== undefined ? Number(subAccount) : null,
      volumeLeaderboardQuery,
      mmLeaderboardQuery,
    },
  });

  const { loading: loadingAggregations, data: accountAggregations } = useAccountAggregationsQuery({
    variables: { accountId: address, subAccountId: Number(subAccount) },
    skip: !subAccount,
  });

  useEffect(() => {
    if (accountData?.account?.subAccounts && !subAccounts) {
      setSubAccounts(accountData.account.subAccounts);
    }
  }, [accountData, subAccounts]);

  const returnObj: ReturnType = useMemo(
    () => ({
      address,
      accountId,
      account: accountData?.account || undefined,
      subAccount,
      subAccounts,
      accountAggregations: accountAggregations?.accountAggregations || undefined,
      marketMakingRank: accountData?.marketMakingRank || undefined,
      volumeRank: accountData?.volumeRank || undefined,
      loadingAggregations,
    }),
    [address, accountAggregations, accountData, accountId, loadingAggregations, subAccount, subAccounts],
  );

  return returnObj;
};
