import { useParams } from "react-router-dom";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, currencyCell, textCell } from "@frontend/ui";
import { useGlvsQuery } from "../../../generated";
import { RouteParams, RoutePath } from "../../../../../config/routes";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Pool",
  },
  {
    renderType: "TEXT",
    text: "Price",
  },
  {
    renderType: "TEXT",
    text: "Total Supply",
    suffix: "$",
  },
];

const GlvsTable = () => {
  const { clientName } = useParams();
  const { data, loading } = useGlvsQuery();

  const tableData: RenderData[][] = (data?.glvs || []).map((glv) => [
    textCell(glv.glvName, undefined, "glv"),
    currencyCell(glv.glvPrice),
    currencyCell(glv.totalSupplyUsd),
  ]);

  const getRowHref = (rowId: number) => {
    const glv = (data?.glvs || [])[rowId];

    if (!glv) {
      return "";
    }

    return RoutePath.Risk.GlvDetails.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.Address,
      glv.glvAddress,
    );
  };

  return (
    <ChaosTable
      title="GLVs"
      headers={headers}
      data={tableData}
      isLoading={loading}
      rowHref={getRowHref}
      showRowChevron
    />
  );
};

export default GlvsTable;
