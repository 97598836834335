import { Box } from "@frontend/ui";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";
import { Loader } from "@frontend/ui/loader";
import { PageTracker } from "src/components/page-tracker";
import MethodologyPageHeader from "./methodology-page-header";
import { Methodology } from "./types";

type Props = {
  methodology?: Methodology;
};

const MethodologyPage = PageTracker(({ methodology }: Props) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (methodology && methodology.methodologyFileName) {
      void import(`./content/${methodology.methodologyFileName}`).then((mdContent: { default: string }) => {
        if (mdContent) {
          void fetch(mdContent.default)
            .then((res) => res.text())
            .then((text) => setContent(text));
        }
      });
    }
  }, [methodology]);

  return (
    <Box>
      {methodology && (
        <Box mb={5} display="flex" flexDirection="column" gap={3}>
          <MethodologyPageHeader methodology={methodology} />
          {content.length === 0 ? (
            <Loader />
          ) : (
            <ReactMarkdown
              components={{
                img: ({ alt, src, title }: { alt?: string; src?: string; title?: string }) => (
                  <img
                    alt={alt}
                    src={src}
                    title={title}
                    className="center"
                    style={{ maxWidth: "80%", paddingLeft: "10%" }}
                  />
                ),
              }}
            >
              {content}
            </ReactMarkdown>
          )}
        </Box>
      )}
    </Box>
  );
});

export default MethodologyPage;
