import { formatDuration } from "@frontend/ui/utils/formatters";
import { Box, CryptoIcon, CustomIcon, Grid, Typography } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { PositionFragment, PositionSide } from "../../../../../generated";
import { useClientConfig } from "../../../../../clients-config";

type Props = {
  position?: PositionFragment;
};

const PositionCards = ({ position }: Props) => {
  const {
    positions: { useBasePnl },
  } = useClientConfig();
  const cards: ValueCardProps[] = [
    {
      title: "Duration",
      value: position
        ? formatDuration({
            milliseconds:
              (position.timeClosed ? new Date(position.timeClosed) : new Date()).getTime() -
              new Date(position.timeOpened).getTime(),
          })
        : undefined,
    },
    {
      title: "",
      value: position
        ? [
            {
              title: "Market",
              value: (
                <Box display="flex" gap={1} mt={1}>
                  <CryptoIcon icon={position.assetSymbol} />
                  <Typography variant="h4">{position.assetSymbol}</Typography>
                </Box>
              ),
            },
            {
              title: "Side",
              value: (
                <Box display="flex" gap={1} mt={1}>
                  <CustomIcon icon={position.side === PositionSide.Long ? "line-up" : "line-down"} />
                  <Typography variant="h4">{position.side}</Typography>
                </Box>
              ),
            },
          ]
        : undefined,
    },
    {
      title: "Open Price",
      value: position?.openPriceUsd,
      currency: "USD",
      valueMaximumFractionDigits: 2,
    },
    {
      title: position?.timeClosed ? "Close Price" : "Current Price",
      value: position?.lastPriceUsd,
      currency: "USD",
      valueMaximumFractionDigits: 2,
    },
    {
      title: "Collateral",
      value: position ? position.collateralBalanceUsd || "-" : undefined,
      currency: "USD",
      valueMaximumFractionDigits: 2,
    },
    {
      title: "Leverage",
      value: position?.leverage,
    },
    {
      title: "Size",
      value: position?.balanceUsd,
      currency: "USD",
      valueMaximumFractionDigits: 2,
    },
    ...(useBasePnl
      ? [
          {
            title: "Gross RPnL",
            value: position?.totalBasePnlUsd,
            currency: "USD",
            valueMaximumFractionDigits: 2,
            showSign: true,
          },
          {
            title: "Unrealized PnL",
            value: position?.unrealizedPnl,
            currency: "USD",
            valueMaximumFractionDigits: 2,
            showSign: true,
          },
        ]
      : [
          {
            title: "PnL",
            value: position?.pnl,
            currency: "USD",
            valueMaximumFractionDigits: 2,
            showSign: true,
          },
        ]),
  ];

  return (
    <Grid container>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={12} md={4} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PositionCards;
