import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Typography } from "@frontend/ui";
import { Stack } from "@mui/material";
import { JsonSchemaInput } from "./json-schema-input";
import { TriggerStepConfiguration } from "../utils";
import { AlertSettingsForm } from "./types";

interface TriggersConfigurationSectionProps {
  submitting: boolean;
}

export const TriggersConfigurationSection = ({ submitting }: TriggersConfigurationSectionProps) => {
  const { control } = useFormContext<AlertSettingsForm>();

  const [evaluateStep, filterStep] = useWatch({
    control,
    name: ["evaluateStep", "filterStep"],
  }) as [TriggerStepConfiguration, TriggerStepConfiguration];

  return (
    <Stack spacing={2}>
      <Typography variant="caption">
        Configure the parameters for the evaluation of the alert. This will be used to determine when the alert should
        be triggered.
      </Typography>
      {evaluateStep && (
        <Controller
          name="evaluateStep.defaultArguments"
          control={control}
          disabled={submitting}
          render={({ field }) => (
            <JsonSchemaInput
              title="Evaluation Parameters"
              schema={evaluateStep.argumentsSchema}
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
            />
          )}
        />
      )}
      {filterStep && (
        <Controller
          name="filterStep.defaultArguments"
          control={control}
          disabled={submitting}
          render={({ field }) => (
            <JsonSchemaInput
              title="Filter Parameters"
              schema={filterStep?.argumentsSchema}
              value={field.value}
              onChange={field.onChange}
              disabled={field.disabled}
            />
          )}
        />
      )}
    </Stack>
  );
};
