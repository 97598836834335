import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useIsInUnifiedProduct = () => {
  const location = useLocation();

  const result = useMemo(() => {
    const isInUnifiedProduct = location.pathname.includes("/risk/");

    return {
      isInUnifiedProduct,
      isInMarketsTab: isInUnifiedProduct && location.pathname.includes("/markets/"),
    };
  }, [location]);

  return result;
};
