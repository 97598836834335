import { FC } from "react";
import { Axis } from "@frontend/types";
import { Typography } from "../typography";
import { Box } from "../box";
import { ScatterChart, ScatterChartData, ScatterChartProps } from "./scatter-chart";

export type ScatterChartRowProps = {
  data: ScatterChartData[];
  xAxis: Axis;
  yAxis: Axis;
  title?: string;
  description?: string;
  onClick?: (datum: ScatterChartData) => void;
} & ScatterChartProps;

export const ScatterChartRow: FC<ScatterChartRowProps> = ({
  title,
  xAxis,
  yAxis,
  data,
  description,
  onClick,
  ...restProps
}) => (
  <>
    {(title || description) && (
      <Box position="relative" mb={2}>
        <Typography fontSize="24px" fontWeight={600} lineHeight="32px">
          {title}
        </Typography>
        <Typography fontSize="16px" fontWeight={400} lineHeight="24px">
          {description}
        </Typography>
      </Box>
    )}
    <Box sx={{ mt: { sm: 2, md: 4 }, height: 320 }}>
      <ScatterChart data={data} xAxis={xAxis} yAxis={yAxis} onClick={onClick} {...restProps} />
    </Box>
  </>
);
