import { StackedBarChart } from "@frontend/ui";
import { StringChartSeries } from "@frontend/ui/echarts/types";
import { useNodeOperatorsQuery } from "../../../../generated";

const OperatorsBreakdownByAvs = () => {
  const { data, loading } = useNodeOperatorsQuery();

  const series = (data?.nodeOperators || []).map<StringChartSeries>((op) => ({
    label: op.name,
    data: op.avs.map((a) => [a.name, a.tvrEth]),
  }));

  const tvrByAvsName = (data?.nodeOperators || [])
    .flatMap((a) => a.avs)
    .reduce<Record<string, number>>(
      (acc, a) => ({
        ...acc,
        [a.name]: (acc[a.name] || 0) + a.tvrEth,
      }),
      {},
    );

  const xAxisData = Object.entries(tvrByAvsName)
    .sort((a, b) => b[1] - a[1])
    .map(([name]) => name);

  return (
    <StackedBarChart
      chartHeight={325}
      showXAxisLine={false}
      title="AVSs Secured By Operators"
      isLoading={loading}
      series={series}
      cryptoCurrency="ETH"
      tooltipShowTotal
      xAxisData={xAxisData}
    />
  );
};

export default OperatorsBreakdownByAvs;
