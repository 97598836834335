import dayjs from "dayjs";

export function formatBadDebtDuration(durationInSeconds?: number | null) {
  if (!durationInSeconds) {
    return `> 30 Days`;
  }
  const diffDuration = dayjs.duration(durationInSeconds, "seconds");

  const days = diffDuration.days();

  if (days > 30) {
    return `> 30 Days`;
  }

  if (days > 1) {
    return `${days} Days`;
  }

  const hours = diffDuration.hours();
  if (hours > 1) {
    return `${hours} Hours`;
  }
  return "< 1 Hour";
}
